import React from 'react';

import {styled} from '@mui/material';

interface IImagePlaceholder {
    width?: string;
    height?: string;
    children?: React.ReactNode;
}

const ImagePlaceholder = styled('span')<IImagePlaceholder>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width ? props.width + 'px' : '32px'};
  height: ${props => props.height ? props.height + 'px' : '32px'};
  border-radius: 50%;
  background-image: ${props => !!props.children
        ? null
        : 'url(\'data:image/svg+xml,%3Csvg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="34" cy="34" r="33" fill="white" stroke="%23E3E3E3" stroke-width="2"/%3E%3Cmask id="mask0_10024_28754" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="68" height="68"%3E%3Ccircle cx="34" cy="34" r="33.5" fill="white" stroke="%23E3E3E3"/%3E%3C/mask%3E%3Cg mask="url(%23mask0_10024_28754)"%3E%3Ccircle cx="26" cy="58" r="24" fill="%23E3E3E3"/%3E%3Ccircle cx="54" cy="49" r="24" fill="%23E3E3E3"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M45.8025 71.5637C36.5843 68.2138 30 59.3759 30 49.0001C30 43.9667 31.5495 39.2952 34.1975 35.4365C43.4157 38.7863 50 47.6243 50 58.0001C50 63.0335 48.4505 67.705 45.8025 71.5637Z" fill="%23D1D1D1"/%3E%3Cg style="mix-blend-mode:multiply"%3E%3Ccircle cx="24.5" cy="17.5" r="5.5" fill="%23999999"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A\')'
};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
`;

export default React.memo(ImagePlaceholder);
