import React from 'react';
import isNil from 'lodash/isNil';

import {Typography} from '@mui/material';

import theme from '../../../theme';

interface IProps {
    counter?: number;
    isError: boolean;
    maxLength?: number;
}

export const Counter: React.FC<IProps> = ({counter, isError, maxLength}) => {
    if (isNil(counter)) return null;

    return (
        <Typography
            align="right"
            className="counter"
            sx={{
                position: 'absolute',
                fontSize: 12,
                color: theme.palette.gray.main,
                bottom: isError ? '-2px' : '-22px',
                right: 0,
            }}
            variant="caption"
        >
            {counter}{maxLength ? `/${maxLength}` : null}
        </Typography>
    );
};
