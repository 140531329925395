import React from 'react';

import {Box, Stack} from '@mui/material';

import {InputCaption, Radio} from '../Forms';


interface BudgetWeeklyHoursProps {
    value?: number;
    onSelect: React.Dispatch<React.SetStateAction<number[] | null>>;
}

export const BudgetWeeklyHours: React.FC<BudgetWeeklyHoursProps> = React.memo(({value, onSelect}) => {
    return (
        <Stack
            direction="row"
            sx={{
                gap: '12px',
                rowGap: '14px',
                flexWrap: 'wrap',
                alignItems: 'center',
                margin: '0 -9px'
            }}
        >
            <Box sx={{mt: 5, mb: '-14px', width: '100%'}}>
                <InputCaption>Select expected <span>number of hours</span> weekly</InputCaption>
            </Box>
            <Radio
                color={value === 0 ? 'success' : 'primary'}
                checked={value === 0}
                label="Less than 5 hours / week"
                onClick={() => onSelect([0, 5])}
            />
            <Radio
                color={value === 5 ? 'success' : 'primary'}
                checked={value === 5}
                label="5 - 10 hours / week"
                onClick={() => onSelect([5, 10])}
            />
            <Radio
                color={value === 10 ? 'success' : 'primary'}
                checked={value === 10}
                label="10 - 15 hours / week"
                onClick={() => onSelect([10, 15])}
            />
            <Radio
                color={value === 15 ? 'success' : 'primary'}
                checked={value === 15}
                label="15 - 20 hours / week"
                onClick={() => onSelect([15, 20])}
            />
            <Radio
                color={value === 20 ? 'success' : 'primary'}
                checked={value === 20}
                label="20 - 30 hours / week"
                onClick={() => onSelect([20, 30])}
            />
            <Radio
                color={value === 30 ? 'success' : 'primary'}
                checked={value === 30}
                label="30 + hours / week"
                onClick={() => onSelect([30])}
            />
        </Stack>
    );
});
