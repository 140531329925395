import React from 'react';
import {AxiosError} from 'axios';

import {Box, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {clientService} from '../../../../../../../api';
import {BlackBorderButton, DialogEmoji, RedFilledButton, showToastError} from '../../../../../../../components';
import {IEngagementClient} from '../../../../../../../models';
import theme from '../../../../../../../theme';

type DialogConfirmRequestProps = {
    data: IEngagementClient;
    open: boolean;
    onClose: (isNeedUpdate: boolean) => void;
};

const DialogConfirmRequest: React.FC<DialogConfirmRequestProps> = ({data, open, onClose}) => {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const {mutate, isLoading: isSubmitting} = useMutation(
        () => clientService.closureSubmit(data.id),
        {
            onSuccess() {
                onClose(true);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    return (
        <DialogEmoji
            actions={(
                <>
                    <BlackBorderButton
                        disabled={isSubmitting}
                        sx={{
                            width: mdDown ? '50%' : 'auto'
                        }}
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </BlackBorderButton>
                    <RedFilledButton
                        disabled={isSubmitting}
                        sx={{
                            width: mdDown ? '50%' : 'auto'
                        }}
                        onClick={() => mutate()}
                    >
                        Confirm
                    </RedFilledButton>
                </>
            )}
            maxWidth="sm"
            open={open}
            title={(
                <>
                    <Box justifyContent="center">
                        <img src="/assets/images/pit-stop-icon.png" width="48" height="48" alt="ok icon"/>
                    </Box>
                    Are you sure you want to close the engagement?
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.textGray.dark
                        }}
                    >
                        Please make sure all tasks are finished before closing the engagement.
                        It may take the client up to 2 business days to approve your request.
                    </Typography>
                </>
            )}
            onClose={() => onClose(false)}
        >
        </DialogEmoji>
    );
};

export default React.memo(DialogConfirmRequest);
