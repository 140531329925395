import React from 'react';
import type {RouteObject} from 'react-router-dom';

import {RouteCreateApplicantAccount, SUB_CHANNELS,} from '../constants';
import {ERole} from '../models';
import {RequireUser} from '../modules/Auth';
import {
    ApplicantConfirmationCodePage,
    Channels,
    CreateAccountLayout,
    Experience,
    Expertise,
    OtherDetails,
    VerticalsAndBrands,
    WorkTypes
} from '../modules/CreateApplicant';


export const createApplicantRoutes: RouteObject = {
    path: '/',
    element: <CreateAccountLayout/>,
    children: [
        {
            path: '',
            element: <RequireUser allowedRoles={[ERole.APPLICANT]}/>,
            children: [
                {
                    path: RouteCreateApplicantAccount.experience,
                    element: <Experience/>,
                },
                {
                    path: RouteCreateApplicantAccount.channels,
                    element: <Channels/>,
                },
                {
                    path: `${RouteCreateApplicantAccount.channels}/${SUB_CHANNELS}`,
                    element: <Channels/>,
                },
                {
                    path: RouteCreateApplicantAccount.expertise,
                    element: <Expertise/>,
                },
                {
                    path: RouteCreateApplicantAccount.verticalsAndBrands,
                    element: <VerticalsAndBrands/>,
                },
                {
                    path: RouteCreateApplicantAccount.workTypes,
                    element: <WorkTypes/>,
                },
                {
                    path: RouteCreateApplicantAccount.otherDetails,
                    element: <OtherDetails/>
                },
                {
                    path: RouteCreateApplicantAccount.confirmationCode,
                    element: <ApplicantConfirmationCodePage/>
                },
                {
                    path: `${RouteCreateApplicantAccount.confirmationCode}/:mode`,
                    element: <ApplicantConfirmationCodePage/>
                }
            ],
        }
    ],
};
