import React from 'react';
import type {RouteObject} from 'react-router-dom';

import {RouteWidget} from '../constants';
import SignUpForm from '../modules/Auth/SignUpClient/SignUpForm';
import {ClientThankYou} from '../modules/CreateClient';
import {WidgetBudget, WidgetChannels, WidgetConfirmationCode, WidgetHome, WidgetLocationTimezone, WidgetProject, WidgetVerticals} from '../modules/Widget';
import WidgetLayout from '../modules/Widget/Layout/Layout';
import WidgetTools from '../modules/Widget/WidgetTools/WidgetTools';

export const widgetRoutes: RouteObject = {
    path: '/',
    element: <WidgetLayout/>,
    children: [
        {
            path: '',
            children: [
                {
                    path: RouteWidget.default,
                    element: <WidgetHome/>,
                },
                {
                    path: RouteWidget.channels,
                    element: <WidgetChannels/>,
                },
                {
                    path: `${RouteWidget.channels}/:channelId`,
                    element: <WidgetChannels/>,
                },
                {
                    path: RouteWidget.verticals,
                    element: <WidgetVerticals/>,
                },
                {
                    path: RouteWidget.tools,
                    element: <WidgetTools/>,
                },
                {
                    path: RouteWidget.location,
                    element: <WidgetLocationTimezone/>,
                },
                {
                    path: RouteWidget.budget,
                    element: <WidgetBudget/>,
                },
                {
                    path: RouteWidget.project,
                    element: <WidgetProject/>,
                },
                {
                    path: RouteWidget.signup,
                    element: <SignUpForm/>,
                },
                {
                    path: RouteWidget.confirmationCode,
                    element: <WidgetConfirmationCode/>,
                },
                {
                    path: `${RouteWidget.confirmationCode}/:mode`,
                    element: <WidgetConfirmationCode/>,
                },
                {
                    path: RouteWidget.completed,
                    element: <ClientThankYou/>
                }
            ],
        }
    ],
};
