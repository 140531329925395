import React from 'react';

const HeartsIcon = () => {
    return (
        <svg width="270" height="92" viewBox="0 0 270 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4"
                d="M212.162 0C200.347 0 189.514 3.73975 179.966 11.1156C170.811 18.1869 164.717 27.1935 161.129 33.7427C157.541 27.1931 151.446 18.1869 142.292 11.1156C132.744 3.73975 121.911 0 110.096 0C77.1229 0 52.2578 26.9382 52.2578 62.6608C52.2578 101.254 83.2788 127.658 130.24 167.631C138.215 174.42 147.255 182.114 156.65 190.32C157.888 191.404 159.479 192 161.129 192C162.779 192 164.369 191.404 165.608 190.321C175.004 182.113 184.043 174.419 192.022 167.627C238.979 127.658 270 101.254 270 62.6608C270 26.9382 245.135 0 212.162 0Z"
                fill="#7E5FFF"/>
            <path
                d="M113.532 54.5459C105.143 54.5459 97.4518 57.2232 90.6725 62.5037C84.1731 67.5661 79.8459 74.014 77.2984 78.7026C74.7509 74.0137 70.4237 67.5661 63.9243 62.5037C57.1449 57.2232 49.4538 54.5459 41.0648 54.5459C17.6542 54.5459 0 73.8312 0 99.4053C0 127.034 22.0249 145.938 55.3677 174.555C61.0298 179.415 67.4477 184.923 74.1183 190.798C74.9976 191.573 76.1268 192 77.2984 192C78.4699 192 79.5992 191.573 80.4785 190.798C87.1497 184.922 93.5673 179.414 99.2327 174.552C132.572 145.938 154.597 127.034 154.597 99.4053C154.597 73.8312 136.943 54.5459 113.532 54.5459Z"
                fill="#7E5FFF"/>
        </svg>
    );
};

export default HeartsIcon;
