import React from 'react';

const CheckInCircleIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M0.00195312 40.0007C0.00195312 62.0552 17.9442 79.9994 40.0007 79.9994C62.0571 79.9994 79.9994 62.0552 79.9994 40.0007C79.9994 38.9493 79.1461 38.096 78.0947 38.096C77.0433 38.096 76.19 38.9493 76.19 40.0007C76.19 59.9562 59.9562 76.19 40.0007 76.19C20.0451 76.19 3.81136 59.9562 3.81136 40.0007C3.81136 20.0451 20.0451 3.81136 40.0007 3.81136C49.6328 3.81136 58.6972 7.55219 65.5237 14.3463C66.2703 15.0872 67.476 15.0853 68.2188 14.3405C68.9617 13.5939 68.9579 12.3882 68.2131 11.6454C60.6667 4.13706 50.6461 0.00195312 40.0007 0.00195312C17.9442 0.00195312 0.00195312 17.9442 0.00195312 40.0007ZM38.6545 60.3948C39.026 60.7662 39.5136 60.9529 40.0012 60.9529C40.4888 60.9529 40.9764 60.7662 41.3478 60.3948L79.4418 22.3008C80.1866 21.556 80.1866 20.3523 79.4418 19.6075C78.6971 18.8628 77.4933 18.8628 76.7486 19.6075L40.0012 56.3549L22.3008 38.6545C21.556 37.9098 20.3523 37.9098 19.6075 38.6545C18.8628 39.3993 18.8628 40.6031 19.6075 41.3478L38.6545 60.3948Z"
                fill="url(#paint0_linear_1631_5417)"/>
            <defs>
                <linearGradient id="paint0_linear_1631_5417" x1="0.00195312" y1="18.6221" x2="83.2179" y2="26.057"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7E5FFF"/>
                    <stop offset="0.333333" stopColor="#3C94F7"/>
                    <stop offset="0.666667" stopColor="#7CF2D0"/>
                    <stop offset="1" stopColor="#67DD42"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default CheckInCircleIcon;
