import React, {ReactNode} from 'react';

import {Box, Theme, Typography, useMediaQuery} from '@mui/material';

import theme from '../../theme';
import {ContinueButton, DialogEmoji} from '..';

type DialogSuccessProps = {
    label?: string;
    open: boolean;
    subtitle?: ReactNode;
    title: ReactNode;
    onClose: (isNeedUpdate: boolean) => void;
};

const DialogSuccess: React.FC<DialogSuccessProps> = ({label, open, title, subtitle, onClose}) => {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <DialogEmoji
            actions={(
                <ContinueButton
                    variant="contained"
                    sx={{
                        width: 'auto !important',
                        minWidth: mdDown ? '161px' : 'unset',
                        'div': {
                            justifyContent: 'center'
                        }
                    }}
                    onClick={() => onClose(true)}
                >
                    {label || 'Got it'}
                </ContinueButton>
            )}
            maxWidth="sm"
            open={open}
            title={(
                <>
                    <Box justifyContent="center">
                        <img src="/assets/images/done-icon.png" width="48" height="48" alt="ok icon"/>
                    </Box>
                    {title}
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.textGray.dark
                        }}
                    >
                        {subtitle}
                    </Typography>
                </>
            )}
            onClose={() => onClose(false)}
        >
        </DialogEmoji>
    );
};

export default React.memo(DialogSuccess);
