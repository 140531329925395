import React from 'react';

interface IPillIcon {
    filled?: boolean;
}

const PinIcon: React.FC<IPillIcon> = ({filled}) => {
    return (
        filled ? (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.1243 8.38139L13.554 12.9517C13.9055 13.9439 14.054 15.6001 12.5227 17.6392C12.4142 17.7823 12.2767 17.9008 12.1191 17.987C11.9616 18.0731 11.7876 18.125 11.6086 18.1392H11.5227C11.1916 18.1378 10.8744 18.0058 10.6399 17.772L6.87426 14.0064L4.19458 16.6939C4.07511 16.8094 3.91544 16.874 3.74926 16.874C3.58309 16.874 3.42341 16.8094 3.30395 16.6939C3.18659 16.5754 3.12075 16.4154 3.12075 16.2486C3.12075 16.0818 3.18659 15.9218 3.30395 15.8033L5.99145 13.1236L2.2102 9.34233C2.08477 9.21874 1.98704 9.06989 1.92351 8.90566C1.85998 8.74143 1.83209 8.56557 1.84169 8.38974C1.8513 8.21391 1.89817 8.04213 1.97922 7.8858C2.06026 7.72946 2.17361 7.59214 2.31176 7.48295C4.29614 5.88139 6.19458 6.2017 7.03051 6.45951L11.6165 1.87358C11.8518 1.6417 12.1689 1.51172 12.4993 1.51172C12.8296 1.51172 13.1467 1.6417 13.3821 1.87358L18.1243 6.61576C18.241 6.73127 18.3336 6.86877 18.3968 7.0203C18.46 7.17183 18.4926 7.33439 18.4926 7.49858C18.4926 7.66277 18.46 7.82533 18.3968 7.97686C18.3336 8.12839 18.241 8.26588 18.1243 8.38139Z"
                    fill="#121416"/>
            </svg>

        ) : (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.8044 7.49959C18.8049 7.29453 18.7643 7.09145 18.6852 6.90228C18.606 6.71311 18.4899 6.54167 18.3435 6.39802L13.6013 1.65584C13.4569 1.5108 13.2852 1.39572 13.0962 1.31719C12.9071 1.23867 12.7045 1.19824 12.4998 1.19824C12.2951 1.19824 12.0924 1.23867 11.9033 1.31719C11.7143 1.39572 11.5426 1.5108 11.3982 1.65584L6.93726 6.10896C5.96851 5.85115 4.07007 5.66365 2.11695 7.24177C1.94422 7.37785 1.80249 7.54919 1.7012 7.74437C1.59992 7.93955 1.54141 8.15408 1.52958 8.37365C1.51775 8.59323 1.55288 8.8128 1.6326 9.01773C1.71233 9.22266 1.83484 9.40824 1.99195 9.56209L5.54663 13.1246L3.0857 15.5855C2.9983 15.6726 2.92895 15.7761 2.88163 15.8901C2.83431 16.004 2.80996 16.1262 2.80996 16.2496C2.80996 16.373 2.83431 16.4951 2.88163 16.6091C2.92895 16.7231 2.9983 16.8266 3.0857 16.9136C3.26261 17.0883 3.50118 17.1862 3.74976 17.1862C3.99834 17.1862 4.23691 17.0883 4.41382 16.9136L6.87476 14.4527L10.4216 17.9918C10.5653 18.1381 10.7367 18.2543 10.9259 18.3334C11.1151 18.4126 11.3181 18.4531 11.5232 18.4527H11.6326C11.856 18.4385 12.0738 18.376 12.2707 18.2694C12.4676 18.1629 12.6391 18.0148 12.7732 17.8355C13.2758 17.1823 13.6569 16.444 13.8982 15.6558C14.1638 14.7418 14.1716 13.8668 13.9138 13.0465L18.3435 8.60896C18.4908 8.4644 18.6075 8.29169 18.6867 8.1011C18.7659 7.91052 18.8059 7.70596 18.8044 7.49959ZM12.1482 12.148C12.0109 12.2891 11.9207 12.4693 11.8899 12.6637C11.8591 12.8582 11.8893 13.0574 11.9763 13.234C12.2419 13.7652 12.5154 14.8668 11.4841 16.4058L7.53882 12.4605L3.57007 8.49177C5.21851 7.35115 6.65601 7.97615 6.7732 8.03084C6.94828 8.12103 7.14781 8.15227 7.34207 8.11989C7.53634 8.08751 7.71496 7.99325 7.85132 7.85115L12.4998 3.20271L16.7966 7.49959L12.1482 12.148Z"
                    fill="#999999"/>
            </svg>
        )
    );
};

export default PinIcon;
