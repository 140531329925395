import {any, object, string} from 'zod';

import {ACCEPTED_DOCUMENTS_TYPES, MAX_FILE_SIZE, MAX_FILE_SIZE_MB} from '../constants';

import {getAcceptedExtentions} from './file.utils';
import {urlRegex} from './utils';

const ACCEPTED_EXTENSIONS = getAcceptedExtentions(ACCEPTED_DOCUMENTS_TYPES);

const defaultValues = {
    file: false,
    name: '',
    url: '',
};

const certificateSchema = object({
    name: string()
        .trim()
        .min(1, 'Name is required')
        .max(100, 'Name is too long'),
    file: any()
        .refine((files) => files?.length ? files?.[0]?.size <= MAX_FILE_SIZE_MB : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        )
        .refine(
            (files) => files?.length && files?.[0]?.size ? !!ACCEPTED_EXTENSIONS.find(ext => files?.[0]?.name?.toLowerCase().endsWith(ext)) : true,
            '.jpg, .jpeg, .png and .pdf files are accepted.'
        ),
    url: any()
}).refine((data) => data.file?.length || data.url, {
    path: ['file'],
    message: 'Upload file or paste a link',
}).refine((data) => data.file?.length || data.url, {
    path: ['url'],
    message: 'Upload file or paste a link',
}).refine((data) => data.file?.length ? data.file?.[0]?.size <= MAX_FILE_SIZE_MB : true, {
    path: ['file'],
    message: `Max file size is ${MAX_FILE_SIZE}MB.`,
}).refine((data) => data.url ? data.url.match(urlRegex) : true, {
    path: ['url'],
    message: 'Url is invalid.',
});

export const ValidationCertificate = {
    defaultValues,
    certificateSchema
};
