import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsClient, EngagementInfoBox} from '../../../../../../../components';
import {EClosureStatus, EEngagementStatus, IEngagementClient} from '../../../../../../../models';

type CloseRequestProps = {
    data: IEngagementClient;
};

const CloseRequestBox: React.FC<CloseRequestProps> = ({data}) => {
    if (data.status === EEngagementStatus.CLOSED || data.closureStatus !== EClosureStatus.CLIENT_REQUESTED) {
        return null;
    }

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/pending-icon.png"
                title="Close engagement request pending"
            >
                {data.freelancer.firstName} has received your close engagement request.
                In the meantime, billing for this engagement will not occur unless you cancel the engagement closure request.

                <Box
                    sx={{
                        mt: '16px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <EngagementActionsClient.CloseCancelBtn data={data}/>
                </Box>

            </EngagementInfoBox>

        </>
    );
};

export default React.memo(CloseRequestBox);
