import React, {FC} from 'react';

import {Avatar, Box, styled, Typography} from '@mui/material';

import {CheckCurvyIcon, LocationIcon} from '../../../../../assets/icons';
import {CardTitle} from '../../../../../components';
import {MD} from '../../../../../constants';
import {IClientInfo, IMatchMetaStrings} from '../../../../../models';
import theme from '../../../../../theme';
import {getNameLetters, stringToColor} from '../../../../../utils';

const Wrp = styled('div')`
  @media (min-width: ${MD}) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

interface IAboutClient {
    client: IClientInfo;
    roleCountries: IMatchMetaStrings;
}

const MyMatchesAboutClient: FC<IAboutClient> = ({client: {avatar, name, registeredAt}, roleCountries}) => {
    return (
        <Wrp>
            <CardTitle sx={{mb: '16px'}}>About the client</CardTitle>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    mb: '16px'
                }}
            >
                <Avatar
                    alt={name}
                    src={avatar}
                    sx={{
                        flexShrink: 0,
                        width: '48px',
                        height: '48px',
                        background: stringToColor(getNameLetters(name)),
                        fontSize: '21px',
                        lineHeight: 1.5,
                        fontWeight: 500,
                        'img': {
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            aspectRatio: '1/1'
                        }
                    }}
                >
                    {getNameLetters(name)}
                </Avatar>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.black.main,
                        'span': {
                            display: 'block',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: theme.palette.gray.main
                        }
                    }}
                >
                    {name}
                    <span>
                        Member since {new Date(registeredAt).getFullYear()}
                    </span>
                </Typography>
            </Box>
            <Box
                sx={{
                    [theme.breakpoints.up('md')]: {
                        m: 'auto 0'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '24px',
                        color: theme.palette.purple.main,
                        '& > div': {
                            width: '24px',
                            height: '24px',
                        },
                        'svg': {
                            width: '24px',
                            height: '24px',
                        },
                        'path:first-of-type': {
                            fill: 'currentColor'
                        },
                        'path:last-of-type': {
                            fill: theme.palette.white.main
                        }
                    }}
                >
                    <CheckCurvyIcon/>
                    Verified payment method
                </Box>
                {roleCountries.common.length > 0 && (
                    <Typography>
                        <LocationIcon/>
                        {roleCountries.common.join(', ')}
                    </Typography>
                )}
            </Box>
        </Wrp>
    );
};

export default React.memo(MyMatchesAboutClient);
