import {
    CLIENT_REGISTRATION,
    REGISTRATION
} from '../constants';
import {IClientRole, IRegisterClient, IRegisterWidget} from '../models';

import axiosInstance from './base.api';
import {IRegisterApplicantRequest} from './types';

const forgotPassword = async (email: string) => {
    return await axiosInstance.post<null>(`forgot-password/${email}/restore-link`);
};

const resetPassword = async (payload: {email: string; newPassword: string; token: string}) => {
    return await axiosInstance.post<null>(`forgot-password/${payload.email}/reset`, payload);
};

const signUpApplicant = async (user: IRegisterApplicantRequest) => {
    return await axiosInstance.post<null>(REGISTRATION, user);
};

const signUpClient = async (user: IRegisterClient) => {
    return await axiosInstance.post<IClientRole>(CLIENT_REGISTRATION, user);
};

const signUpWidget = async (payload: IRegisterWidget) => {
    return await axiosInstance.post<IClientRole>(`${CLIENT_REGISTRATION}/widget`, payload);
};

export const userService = {
    forgotPassword,
    resetPassword,
    signUpApplicant,
    signUpClient,
    signUpWidget,
};
