import React, {useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {formatDistanceToNow} from 'date-fns';
import {useNavigate} from 'react-router-dom';

import {Box, styled, Typography} from '@mui/material';

import {freelancerService} from '../../../../../api';
import {MessageBubbleIcon, SingleHeartIcon} from '../../../../../assets/icons';
import {BlackBorderButton, ContinueButton, ImagePlaceholder, showToastError} from '../../../../../components';
import {LG, MD, RouteFreelancerPortal} from '../../../../../constants';
import {useTwilio} from '../../../../../hooks';
import {EWorkTypeDisplay, IFreelancerMatchList} from '../../../../../models';
import theme from '../../../../../theme';
import {formatCurrency} from '../../../../../utils';

const ContentBox = styled('div')<{ hasLogo?: boolean; isActive?: boolean }>`
  padding: 24px;
  position: relative;
  border-radius: 24px;
  background-color: ${theme.palette.white.main};
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);
  display: grid;
  grid-template-areas: 'title title title'
  'rateRange rateRange rateRange'
  'logo subtitle publishTime'
  'buttons buttons buttons';
  grid-template-columns: 24px auto auto;
  column-gap: 8px;
  margin-bottom: 8px;
  color: ${props => !props.isActive && theme.palette.gray.main};

  p, span {
    color: ${props => !props.isActive && 'currentColor !important'};
  }

  @media (min-width: ${MD}) {
    grid-template-areas: 'logo title rateRange'
    'logo subtitle publishTime'
    'buttons buttons buttons'
  ;
    grid-template-columns: 64px auto auto;
    column-gap: 24px;
    row-gap: 8px;
    align-items: center;
  }

  @media (min-width: ${LG}) {
    position: relative;
    min-height: 112px;
    padding-bottom: 16px;

    &:hover {
      .buttons-box {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`;

const BoldText = styled('p')<{ color: string; gridArea: string; alignRight?: boolean }>`
  grid-area: ${props => props.gridArea};
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  &:last-of-type {
    margin-bottom: 12px;
  }

  span {
    font: inherit;
    font-weight: 400;
    line-height: inherit;
  }

  @media (min-width: ${MD}) {
    font-size: 16px;
    margin-bottom: 0;
    justify-self: ${props => props.alignRight ? 'flex-end' : 'unset'};
    text-align: ${props => props.alignRight ? 'right' : 'left'};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Caption = styled('span')<{ bolder?: boolean; gridArea?: string; visible?: boolean; alignRight?: boolean }>`
  grid-area: ${props => props.gridArea ? props.gridArea : 'unset'};
  display: ${props => (props.bolder || props.visible) ? 'inline-flex' : 'none'};
  align-items: center;
  gap: 4px;
  justify-self: ${props => props.alignRight ? 'flex-end' : 'unset'};
  font-weight: ${props => props.bolder ? '600' : '400'};
  font-size: 12px;
  line-height: 18px;
  text-align: ${props => props.alignRight ? 'right' : 'left'};
  color: ${props => props.bolder ? theme.palette.black.main : theme.palette.gray.main};
  position: relative;

  &::before {
    content: '';
    display: ${props => (props.bolder || props.visible) ? 'none' : 'block'};
    width: 4px;
    height: 4px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: currentColor;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  @media (min-width: ${MD}) {
    display: inline-flex;
    font-size: 13px;
  }
`;

interface MatchedItemProps {
    item: IFreelancerMatchList;
    onClick: (roleId: number) => void;
}

const MatchItem: React.FC<MatchedItemProps> = ({item, onClick}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const twilioClient = useTwilio();

    const navigate = useNavigate();

    const handleMessageClick = async () => {
        setIsSubmitting(true);

        try {
            const conversationId = await freelancerService.getChatRoom(item.id);

            twilioClient.invalidateQueries();   // Contact list can miss new room
            navigate(`${RouteFreelancerPortal.inbox}/${conversationId}`);
        } catch (error) {
            showToastError(error as AxiosError);
        }

        setIsSubmitting(false);
    };

    const workTypes = useMemo(() => item.workTypes.map(workType => EWorkTypeDisplay[workType]).join(', '), [item]);

    return (
        <Box>
            <ContentBox hasLogo={!!item.companyLogo} isActive={item.isActive}>
                <BoldText color={theme.palette.black.main} gridArea="title">
                    {item.projectName}
                </BoldText>
                <ImagePlaceholder
                    sx={{
                        width: '24px',
                        height: '24px',
                        gridArea: 'logo',
                        [theme.breakpoints.up('md')]: {
                            width: '64px',
                            height: '64px'
                        }
                    }}
                >
                    {item.companyLogo && (
                        <img src={item.companyLogo} width="64" height="64" alt={item.companyName}/>
                    )}
                </ImagePlaceholder>
                <Box
                    sx={{
                        gridArea: 'subtitle',
                        [theme.breakpoints.up('md')]: {
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center'
                        }
                    }}
                >
                    <Caption bolder>{item.companyName}</Caption>
                    {/* <Caption><LocationIcon/> {item.country}</Caption> */}
                    <Caption>{workTypes}</Caption>
                    <Caption>Executive</Caption> {/*todo: ask what is it?*/}
                    <Caption>{item.languages?.join(', ')}</Caption>
                </Box>
                <BoldText color={theme.palette.purple.main} gridArea="rateRange" alignRight>
                    {item.rateRange.map(it => formatCurrency(it)).join(' - ')}<span>&nbsp;/hr</span>
                </BoldText>
                <Caption gridArea="publishTime" visible alignRight>
                    {item.publishedAt && formatDistanceToNow(new Date(item.publishedAt)) + ' ago'}
                </Caption>

                {
                    item.isActive && (
                        <Box
                            className="buttons-box"
                            sx={{
                                gridArea: 'buttons',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '16px',
                                mt: '12px',
                                [theme.breakpoints.up('sm')]: {
                                    justifyContent: 'flex-end'
                                },
                                [theme.breakpoints.up('lg')]: {
                                    position: 'absolute',
                                    top: '-84px',
                                    right: '-24px',
                                    height: '100px',
                                    width: '274px',
                                    mt: 0,
                                    p: '24px',
                                    borderRadius: '0 24px 24px 0',
                                    backgroundColor: theme.palette.white.main,
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    transition: 'opacity .3s'
                                }
                            }}
                        >
                            <BlackBorderButton
                                disabled={isSubmitting}
                                size="small"
                                onClick={() => onClick(item.id)}
                            >
                                Role details
                            </BlackBorderButton>
                            <ContinueButton
                                disabled={isSubmitting}
                                size="small"
                                sx={{width: 'auto !important'}}
                                variant="contained"
                                onClick={handleMessageClick}
                            >
                                Message
                            </ContinueButton>
                        </Box>
                    )
                }
            </ContentBox>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    'svg': {
                        width: '12px',
                        height: '12px',
                    }
                }}
            >
                {/*
                    Case1: Freelancer shortlisted and messaged → “Shortlisted by the client“
                    Case2: Freelancer shortlisted only → “Shortlisted by the client“
                    Case3: Freelancer messaged only → “Messaged by the client“
                */}
                {
                    item.isLiked && (
                        <>
                            <SingleHeartIcon color={theme.palette.purple.main}/>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: theme.palette.gray.main,
                                    mr: 4
                                }}
                            >
                                Shortlisted by the client
                            </Typography>
                        </>
                    )
                }
                {
                    item.isMessaged && !item.isLiked && (
                        (
                            <>
                                <MessageBubbleIcon color={theme.palette.disabledBtnColor.dark}/>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: theme.palette.gray.main
                                    }}
                                >
                                    Messaged by the client
                                </Typography>
                            </>
                        )
                    )
                }
            </Box>
        </Box>
    );
};

export default React.memo(MatchItem);
