import React from 'react';

const ArrowRight = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 5.75H0.25V7.25H1V5.75ZM25.5303 7.03033C25.8232 6.73744 25.8232 6.26256 25.5303 5.96967L20.7574 1.1967C20.4645 0.903806 19.9896 0.903806 19.6967 1.1967C19.4038 1.48959 19.4038 1.96447 19.6967 2.25736L23.9393 6.5L19.6967 10.7426C19.4038 11.0355 19.4038 11.5104 19.6967 11.8033C19.9896 12.0962 20.4645 12.0962 20.7574 11.8033L25.5303 7.03033ZM1 7.25H7.89503V5.75H1V7.25ZM7.89503 7.25H13.8619V5.75H7.89503V7.25ZM13.8619 7.25H19.5635V5.75H13.8619V7.25ZM19.5635 7.25H25V5.75H19.5635V7.25Z"
                fill="#7E5FFF"/>
        </svg>
    );
};

export default ArrowRight;
