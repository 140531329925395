import React from 'react';

import PageWrapper, {Subtitle, Text, Title} from './components/PageWrapper';

const TermsOfService = () => {
    return (
        <PageWrapper title="Terms of Service">
            <Text>
                Welcome to Growth Collective, a website located at www.growthcollective.com, which may include managed
                subdomains (collectively the “Site”) and operated by Growth Collective Solutions, Inc. (“Growth
                Collective”, “us”, “our”, and “we”). Growth Collective provides the Site and services provided through
                the Site (“Services”) including an online marketplace connecting businesses with freelance marketing
                experts (“Platform”).
            </Text>

            <Text>
                These Terms of Service together with our Dispute Procedures, Non-Circumvention Policy, Work Order Terms
                and Community Guidelines (collectively, the “Terms”) set forth the legally binding terms for your use of
                the Services. By accessing or using the Services, you are accepting these Terms and you represent and
                warrant that you have the right, authority, and capacity to enter into these Terms. If you do not agree
                with all of the provisions of these Terms, do not access and/or use the Services. You may not access or
                use the Services or accept the Terms if you are not at least 18 years old.
            </Text>

            <Title>
                Privacy Policy
            </Title>

            <Text>
                The Company respects the privacy of its users. Please refer to our Privacy Policy
                (https://www.growthcollective.com/privacy-policy) which explains how we collect, use, and disclose
                information that pertains to your privacy. When you access or use the Service, you signify your
                agreement to this Privacy Policy.
            </Text>

            <Title>
                Types of users
            </Title>

            <Text>
                “The User,” “You” and “Your” refer to the person, company, or organization (including any employee of
                such company or organization) that has visited or is using the Site and/or the Service. A User may be a
                Client, Consultant, both or none, depending on the context of its use within these Terms.
            </Text>

            <Text>
                “Consultant” refers to users of the Platform, whether an individual or business who offer to provide
                freelance marketing services to Clients (“Consulting Services”). Consultants are not the employees or
                agents of Growth Collective.
            </Text>

            <Text>
                “Client” refers to users of the Platform, whether an individual or business who requests and/or
                purchases Consulting Services from a Consultant.
            </Text>

            <Title>
                Accounts
            </Title>

            <Subtitle>
                Account Creation
            </Subtitle>

            <Text>
                In order to use certain features of the Services, you must register for an account with us (“your
                Account”) and provide certain information about yourself as prompted by the registration form. You
                represent and warrant that: (a) all required registration information you submit is truthful and
                accurate; (b) you will maintain the accuracy of such information. You may delete your Account at any
                time, for any reason, by emailing support@growthcollective.com.
                We may suspend or terminate your Account
                in accordance with the Terms and Termination.
            </Text>

            <Subtitle>
                Account Responsibilities
            </Subtitle>

            <Text>
                You are responsible for maintaining the confidentiality of your Account login information and are fully
                responsible for all activities that occur under your Account. You agree to immediately notify us of any
                unauthorized use, or suspected unauthorized use, of your Account or any other breach of security. We
                cannot and will not be liable for any loss or damage arising from your failure to comply with the above
                requirements.
            </Text>

            <Title>
                How it works
            </Title>

            <Text>
                Growth Collective is an online Platform that provides Clients access to a virtual community of
                Consultants for collaboration and communication between Consultants and Users seeking Consulting
                Services. Client posts a proposed project with specifications using a Work Order on the Platform.
                Through the Platform, Consultants can submit bids for the Work Order. The Client may accept the
                Consultants proposal, or the parties can come to agreeable terms, and form a legally binding agreement
                with one another. All Work Orders are governed by our Work Order Terms which can be found here:
                https://www.growthcollective.com/work-order-terms. Our Work Order Terms are provided as a way to make it
                simple for Clients and Consultants to work together and should not be construed as legal advice.
            </Text>

            <Title>
                Your relationship with Growth Collective
            </Title>

            <Text>
                Growth Collective does not provide consulting services but merely provides Client access to Consultants
                and a convenient place to manage the relationship between Client and Consultant and enable online
                payment and delivery of Consulting Services.
            </Text>

            <Subtitle>
                Growth Collective is Not a Party to Contracts
            </Subtitle>

            <Text>
                The consulting relationship exists exclusively between Client and Consultant and the Client pays the
                Consultant for Consulting Services. Consultants are not the employees or agents of Growth Collective.
                Growth Collective is not involved in agreements between Users or in the representation of Users. At no
                point may Growth Collective be held liable for the actions or omissions of any Consultant performing
                Consulting Services for Clients or for Clients breach of any obligation to Consultants.
            </Text>

            <Subtitle>
                Growth Collective Does Not Guarantee Results
            </Subtitle>

            <Text>
                Growth Collective takes pride in our process to make high quality Consultants available to our Clients.
                While we make reasonable efforts to vet the Consultants that use our Platform, we cannot guarantee the
                fitness of any Consultants for a Client’s specific needs or guarantee the quality, accuracy, and
                timeliness of any Consulting Services provided to Clients. Growth Collective does not direct, has no
                control over delivery of any Consulting Services. Growth Collective does not sanction statements a
                Consultant may post on the Platform. We encourage Clients to research any Consultant before accepting
                professional advice.
            </Text>

            <Subtitle>
                All Fees Are Paid to Consultants
            </Subtitle>

            <Text>
                Growth Collective does neither provide nor charge for consulting related services. Growth Collective
                merely facilitates the collection of payment to Consultants. Growth Collective may charge a transaction
                fee for each order facilitated through the Platform in exchange for use of the Platform. Consultants
                will be notified of any changes in the transaction fee.
            </Text>

            <Subtitle>
                Reviews
            </Subtitle>

            <Text>
                From time to time, Clients may submit reviews of Consultants; these reviews do not constitute a
                guarantee, warranty, or prediction regarding the outcome of any future matter. Growth Collective will
                have no responsibility or liability of any kind for any User Content (defined below) or advice you
                encounter on or through the Platform, and any use or reliance on User Content or advice is solely at
                your own risk.
            </Text>

            <Title>
                Payment terms and transactions
            </Title>

            <Subtitle>
                Payment Process
            </Subtitle>

            <Text>
                Payment will be processed for the Consulting Services in the schedule set forth in the Work Order and
                the Work Order Terms. Growth Collective will have the right to charge the Client{'\''}s credit card,
                bank account, or PayPal account for the full amount of the agreed-upon fee, in addition to Growth
                Collective’s processing fee, if any. Any change to our pricing or payment terms shall become effective
                in the billing cycle following notice of such change to you as provided in these Terms. All payments are
                processed through Growth Collective’s Platform in order to secure the transaction and facilitate dispute
                resolution procedures.
            </Text>

            <Subtitle>
                Payment Information; Taxes
            </Subtitle>

            <Text>
                All information that Client provides in connection with a purchase or transaction or other monetary
                transaction interaction with the Services must be accurate, complete, and current. Client agrees to pay
                all charges incurred by users of your credit card, debit card, or other payment method used in
                connection with a purchase or transaction or other monetary transaction interaction with the Services at
                the prices in effect when such charges are incurred. Client will pay any applicable taxes, if any,
                relating to any such purchases, transactions or other monetary transaction interactions.
            </Text>

            <Subtitle>
                Responsibility for Payment
            </Subtitle>

            <Text>
                Client is responsible for all fees, including taxes, associated with your use of the Service. You are
                responsible for providing us with a valid means of payment. Client must keep current payment information
                on file with Growth Collective and Client’s account must be adequately funded to pay for any services
                rendered. By agreeing to these terms, Client is giving Growth Collective permission to charge your
                on-file credit card, PayPal account, or other approved methods of payment for fees that you authorize
                Growth Collective to satisfy.
            </Text>

            <Subtitle>
                Notification of Payment
            </Subtitle>

            <Text>
                Growth Collective will email Client a receipt when your card has been charged. If Client’s card cannot
                be charged, your access to the Services may be suspended and you will need to update your card
                information in order to resume use. Growth Collective may terminate a Client’s Account if Client fails
                to make any payments owed to a Consulting for Consulting Services performed on the Platform.
            </Text>

            <Subtitle>
                Chargebacks
            </Subtitle>

            <Text>
                Client acknowledges that Growth Collective has a dispute resolution process in the event you have a
                dispute concerning the services provided to you. If you have a dispute concerning any payment
                transaction, please contact our billing department. If, at any time, you contact your bank or credit
                card company to reject the charge of any payable Fees (“Chargeback”), this act will be considered a
                breach of your payment obligations, and we reserve the right to automatically terminate your use of the
                Services. We reserve the right to dispute any Chargeback and take all reasonable action to authorize the
                transaction. In the event of a Chargeback, your Account may be terminated and any files, data, or
                content contained in your Account may be subject to cancellation. We will not be liable for any loss of
                files, data or content as a result of a Chargeback. Without limiting other available remedies, Client
                must pay Growth Collective upon demand for amounts owed under this Agreement, plus interest on the
                outstanding amount at the lesser of one percent (1%) per month or the maximum interest allowed by
                applicable law, plus attorneys’ fees and other costs of collection to the extent permitted by applicable
                law.
            </Text>

            <Title>
                Consultants
            </Title>

            <Text>
                Consultants are independent professionals who offer to perform Consulting Services for prospective
                Clients. They are not employees of Growth Collective.
            </Text>

            <Subtitle>
                Consultant Responsibilities
            </Subtitle>

            <Text>
                Consultants are solely responsible for ensuring that any information, solicitations, or advertisements
                they post or place on the Platform, including without limitation User Content, and any communications
                they may have with prospective clients through the Platform, fully comply with all applicable laws.
                Consultants must have the appropriate rights and licenses for all work created for a Client.
            </Text>

            <Subtitle>
                Payments to Consultants
            </Subtitle>

            <Text>
                Growth Collective facilitates the payment from Clients to Consultants through our third-party payment
                provider. As such, Growth Collective charges a transaction fee for each payment made through the
                Platform (“the transaction fee” or “fee”). Consultants are responsible for determining the price of any
                Consulting Services sold through the Platform and should factor the Growth Collective transaction fee
                into their pricing determinations. Consultants are responsible for keeping their Accounts and profiles
                up-to-date with your payment details. Consultant acknowledges and understands that it can take days or
                sometimes weeks from when a payment is collected on the Platform before dispersing to Consultant’s bank
                account. If a Client submits a Dispute Notice (as defined in our Dispute Procedures), Growth Collective
                may withhold disbursement of those funds until the dispute is resolved. If a Client fails to pay a
                weekly invoice, Consultant should cease all work for the Client until such payment is made. Growth
                Collective is not responsible to Consultant if Client fails to make payment for any Consulting Services
                performed by Consultant and Consultant agrees to hold Growth Collective harmless for a Client’s breach
                of their payment obligations.
            </Text>

            <Subtitle>
                Independent Contractor
            </Subtitle>

            <Text>
                Consultants are independent contractors and are responsible for keeping track of all income earned on
                the Platform, reporting their income to the IRS, and paying taxes appropriately. Taxes will not be
                withheld out of any payments made to Consultants and neither Clients nor Growth Collective are required
                to provide Consultants with a 1099 or other applicable tax-related documentation. Consultants will
                perform the Consulting Services as an independent contractor and nothing in these Terms will be deemed
                to create a partnership, joint venture, agency, or employer-employee relationship between Growth
                Collective and any Consultants or any Clients. Growth Collective has no liability to the Consultants or
                any Clients if a determination legal or otherwise is made by a federal, state or local agency or
                authority that any Client is a joint employer of the Consultants, or that the Consultants are employees
                of Growth Collective and/or of the Client.
            </Text>

            <Title>
                Site Content
            </Title>

            <Text>
                Our Site may provide informational and educational content. All content on this Site is for
                informational and educational purposes only, is general in nature, and under no circumstance does the
                content on our Site undertake to give advice for your particular situation. The content on our Site is
                not meant to be relied upon by Site visitors in reliance on making any decisions. In the event that you
                use the information provided through our Site or Services, we assume no responsibility.
            </Text>

            <Title>
                Contact Form
            </Title>

            <Text>
                We may include a contact form on our Site that allows you to get in touch with us to inquire about our
                Services, provide feedback, or request collaborations. Our contact form may ask for your personal
                information such as your name, email address, phone number, and reason for your inquiry.
            </Text>

            <Title>
                Rights & Licenses
            </Title>

            <Subtitle>
                License to Use Site
            </Subtitle>

            <Text>
                We grant you a non-transferable, non-exclusive, right to access and use the Services for your personal
                use.
            </Text>

            <Subtitle>
                Certain Restrictions
            </Subtitle>

            <Text>
                The rights granted to you in these Terms are subject to the following restrictions: (a) you will not
                license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the
                Services; (b) you will not modify, make derivative works of, disassemble, reverse compile or reverse
                engineer any part of the Services; (c) you will not access the Services in order to build a similar or
                competitive service; and (d) except as expressly stated in these terms, no part of the Services may be
                copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form
                or by any means. Any future release, update, or other addition to functionality of the Services will be
                subject to these Terms. All copyright and other proprietary notices on any Services content must be
                retained on all copies.
            </Text>

            <Subtitle>
                Modification
            </Subtitle>

            <Text>
                We reserve the right, at any time, to modify, suspend, or discontinue the Services with or without
                notice. You agree that we will not be liable to you or to any third party for any modification,
                suspension, or discontinuance of the Services, except and if otherwise expressly set forth in these
                Terms.
            </Text>

            <Subtitle>
                Ownership of the Services
            </Subtitle>

            <Text>
                Excluding your User Content (defined below), you acknowledge that all the intellectual property rights,
                including copyrights, patents, trademarks, and trade secrets, in the Services, including the Platform,
                are owned by us or our licensors. The provision of the Services does not transfer to you or any third
                party any rights, title or interest in or to the intellectual property rights. We reserve all rights not
                granted in these Terms.
            </Text>

            <Title>
                User Content
            </Title>

            <Subtitle>
                User Content
            </Subtitle>

            <Text>
                “User Content” means any and all information and content that a user submits to or posts on: (a) the
                Services and (b) on social networking sites where we have a page or presence. You will own your User
                Content, with the understanding that you agree that we may use and reproduce the User Content you make
                available on our social networking sites and on the Services. You are solely responsible for the User
                Content that you post, upload, link to or otherwise make available via the Service. We reserve the right
                to remove any User Content from the Service at our discretion.
            </Text>

            <Text>
                The following rules pertain to User Content. By transmitting and submitting any User Content while using
                the Service, you agree as follows:
            </Text>

            <Text>
                – You are solely responsible for your account and the activity that occurs while signed in to or while
                using your account;
            </Text>
            <Text>
                – You will not submit content that is copyrighted or subject to third party proprietary rights,
                including privacy, publicity, trade secret, etc., unless you are the owner of such rights or have the
                appropriate permission from their rightful owner to specifically submit such content;
            </Text>
            <Text>
                – You will abide by our Acceptable Use Policy below; and
            </Text>
            <Text>
                – You affirm we have the right to determine whether any of your User Content submissions are appropriate
                and comply with these Terms of Use, remove any and/or all of your submissions, and terminate your
                account with or without prior notice.
            </Text>
            <Text>
                – You understand and agree that any liability, loss or damage that occurs as a result of the use of any
                User Content that you make available or access through your use of the Service is solely your
                responsibility. We are not responsible for any public display or misuse of your User Content. We do not,
                and cannot, pre-screen or monitor all User Content. However, at our discretion, we, or technology we
                employ, may monitor and/or record your interactions with the Service.
            </Text>

            <Subtitle>
                License
            </Subtitle>

            <Text>
                You grant, and you represent and warrant that you have the right to grant, to us an irrevocable,
                nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display
                and perform, prepare derivative works of, incorporate into other works, and otherwise use your User
                Content, and to grant sublicenses of the foregoing, solely for the purposes of including your User
                Content in the Site and Services. You agree to irrevocably waive (and cause to be waived) any claims and
                assertions of moral rights or attribution with respect to your User Content.
            </Text>

            <Subtitle>
                Acceptable Use Policy
            </Subtitle>

            <Text>
                Your permission to use the Services is conditioned upon the following Use Restrictions and Conduct
                Restrictions: You agree that you will not under any circumstances:
            </Text>
            <Text>
                – post any information that is abusive, threatening, obscene, defamatory, libelous, or racially,
                sexually, religiously, or otherwise objectionable and offensive;
            </Text>
            <Text>
                – use the service for any unlawful purpose or for the promotion of illegal activities;
            </Text>
            <Text>
                – attempt to, or harass, abuse or harm another person or group;
            </Text>
            <Text>
                – use another user’s account without permission;
            </Text>
            <Text>
                – provide false or inaccurate information or impersonate another person when registering an account;
            </Text>
            <Text>
                – interfere or attempt to interfere with the proper functioning of the Service;
            </Text>
            <Text>
                – make any automated use of the system, or take any action that we deem to impose or to potentially
                impose an unreasonable or disproportionately large load on our servers or network infrastructure;
            </Text>
            <Text>
                – use the Site or any of its contents to advertise or solicit, for any commercial purpose or to compete,
                directly or indirectly, with our Service;
            </Text>
            <Text>
                – bypass any robot exclusion headers or other measures we take to restrict access to the Service or use
                any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate
                data; or
            </Text>
            <Text>
                – publish or link to malicious content intended to damage or disrupt another user’s browser or computer.
            </Text>

            <Subtitle>
                Feedback
            </Subtitle>

            <Text>
                If you provide us any feedback or suggestions regarding the Services (“Feedback”), you assign to us all
                rights in the Feedback and agree that we will have the right to use the Feedback and related information
                in any manner we deem appropriate. We will treat any Feedback you provide to us as non-confidential and
                non-proprietary. You agree that you will not submit to us any information or ideas that you consider to
                be confidential or proprietary.
            </Text>

            <Title>
                Indemnity
            </Title>

            <Text>
                You agree to indemnify and hold us (and our officers, employees, and agents) harmless, including costs
                and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your
                use of the Services, (b) your User Content, (c) your violation of these Terms; or (d) your violation of
                applicable laws or regulations. We reserve the right, at your expense, to assume the exclusive defense
                and control of any matter for which you are required to indemnify us and you agree to cooperate with our
                defense of these claims. You agree not to settle any matter without our prior written consent. We will
                use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
            </Text>

            <Title>
                Confidentiality
            </Title>

            <Text>
                As used in these Terms, “Confidential Information” means all confidential or proprietary information of
                a party (“Disclosing Party”) disclosed to the other party (“Receiving Party”), whether orally or in
                writing, that is either marked or designated as confidential or that a reasonable person would deem
                confidential or proprietary given the nature of the information and the circumstances under which it is
                disclosed. Confidential Information will include, but is not limited to, pricing and other terms
                reflected in agreements between the parties, and the Disclosing Party’s business and marketing plans,
                technology and technical information, product designs, business processes, and any other nonpublic or
                proprietary information of Disclosing Party. Confidential Information does not include any information
                that a Receiving Party can show (i) is or becomes generally known to the public without breach of any
                obligation owed to the Disclosing Party; (ii) was known to the Receiving Party prior to its disclosure
                by the Disclosing Party; (iii) was independently developed by the Receiving Party without breach of any
                obligation owed to the Disclosing Party; or (iv) is received from a third party without any duty or
                confidentiality obligation owed to the Disclosing Party. To the extent Confidential Information is
                shared, the Receiving Party will protect the secrecy of the Disclosing Party’s Confidential Information
                with the same degree of care as it uses to protect its own Confidential Information, but in no event
                with less than due care, and will: (a) not disclose or permit others to disclose Disclosing Party’s
                Confidential Information to anyone without first obtaining the express written consent of the Disclosing
                Party; (b) not use or permit the use of Disclosing Party’s Confidential Information, except as necessary
                for the performance of its obligations under these Terms; and (c) limit access to Disclosing Party’s
                Confidential Information to Receiving Party’s personnel who need to know such information for the
                performance of the Receiving Party’s obligations under these Terms.
            </Text>

            <Title>
                Links to Other Sites and/or Materials
            </Title>

            <Subtitle>
                Third Party Sites, and Third-Party Content
            </Subtitle>

            <Text>
                As part of the Service, we may provide you with convenient links to third party website(s) (“Third-Party
                Sites”) as well as content or items belonging to or originating from third parties such as applications,
                software, or content (collectively the “Third-Party Content”). Users may also include links to their
                website or other Third-Party Sites on their listings. These links are provided as a courtesy to Users.
                We have no control over Third-Party Sites and Third-Party Content or the promotions, materials,
                information, goods or services available on these Third-Party Sites or Third-Party Content. If you
                decide to leave the Site and access the Third-Party Sites or to use or install any Third-Party Content,
                you do so at your own risk and you should be aware that our terms and policies no longer govern. You
                should review the applicable terms and policies, including privacy and data gathering practices, of any
                site to which you navigate from the Site or relating to any applications you use or install from the
                site.
            </Text>

            <Subtitle>
                Release
            </Subtitle>

            <Text>
                You release and forever discharge us (and our officers, employees, agents, successors, and assigns)
                from, and waive and relinquish, each and every past, present and future dispute, claim, controversy,
                demand, right, obligation, liability, action and cause of action of every kind and nature (including
                personal injury, death, and property damage), that has arisen or arises directly or indirectly out of,
                or relates directly or indirectly to, any interactions with, or act or omission of, other Service users
                or Third Party Sites & Ads. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION
                1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH
                THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE,
                WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
            </Text>

            <Title>
                Disclaimers
            </Title>

            <Text>
                THE SERVICES, INCLUDING THE SITE, ARE PROVIDED “AS-IS” AND “AS AVAILABLE” AND WE EXPRESSLY DISCLAIM ANY
                WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR
                CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
                NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE SERVICES: (a) WILL MEET YOUR REQUIREMENTS; (b) WILL BE
                AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (c) WILL BE ACCURATE, RELIABLE, FREE
                OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE OR (d) THAT THE SERVICES WILL BE TO YOUR
                SATISFACTION. <br/>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
                APPLY TO YOU.
            </Text>

            <Title>
                Limitations on Liability
            </Title>

            <Text>
                IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE
                TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL
                BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM.
            </Text>
            <Text>
                IN NO EVENT WILL GROWTH COLLECTIVE’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF
                ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE EXCEED THE
                AMOUNTS YOU’VE PAID GROWTH COLLECTIVE IN THE PRIOR 12 MONTHS (IF ANY). THE EXISTENCE OF MORE THAN ONE
                CLAIM WILL NOT ENLARGE THIS LIMIT.
            </Text>
            <Text>
                SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL
                DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
            </Text>

            <Title>
                Term & Termination
            </Title>

            <Text>
                Subject to this Section, these Terms will remain in full force and effect while you use the Services. We
                may (a) suspend your rights to use the Site and/or Services (including your Account) or (b) terminate
                these Terms, at any time for any reason at our sole discretion, including for any use of the Services in
                violation of these Terms. Upon termination of these Terms, your Account and right to access and use the
                Services will terminate immediately. You understand that any termination of your Account involves
                deletion of your User Content from our live databases. We will not have any liability whatsoever to you
                for any termination of these Terms, including for termination of your Account or deletion of your User
                Content. Upon termination of these Terms, all of the provisions will terminate except those that by
                their nature should survive, including but not limited to any indemnification obligations,
                confidentiality provisions, including those set forth in the Work Order Terms, and adherence to Growth
                Collective’s Non-Circumvention Policy.
            </Text>

            <Title>
                Copyright Policy
            </Title>

            <Text>
                We respect the intellectual property of others and ask that users of our Site and Services do the same.
                In connection with our Site and Services and in accordance with the Digital Millennium Copyright Act’s
                (“DMCA”), we have adopted and implemented a policy respecting copyright laws that provide for the
                removal of any infringing materials and for the termination, in appropriate circumstances, of users of
                our online Services who are repeat infringers of intellectual property rights, including copyrights. If
                you believe that one of our users is, through the use of our Services, unlawfully infringing the
                copyright(s) in a work, and wish to have the allegedly infringing material removed, the following
                information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to
                our designated Copyright Agent:
            </Text>
            <Text>
                – your physical or electronic signature;
            </Text>
            <Text>
                – identification of the copyrighted work(s) that you claim to have been infringed;
            </Text>
            <Text>
                – identification of the material on our Services that you claim is infringing and that you request us to
                remove;
            </Text>
            <Text>
                – sufficient information to permit us to locate such material;
            </Text>
            <Text>
                – your address, telephone number, and e-mail address;
            </Text>
            <Text>
                – a statement that you have a good faith belief that use of the objectionable material is not authorized
                by the copyright owner, its agent, or under the law; and
            </Text>
            <Text>
                – a statement that the information in the notification is accurate, and under penalty of perjury, that
                you are either the owner of the copyright that has allegedly been infringed or that you are authorized
                to act on behalf of the copyright owner.
            </Text>
            <Text>
                Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in
                a written notification automatically subjects the complaining party to liability for any damages, costs
                and attorney’s fees incurred by us in connection with the written notification and allegation of
                copyright infringement.
            </Text>
            <Text>
                Our designated Copyright Agent is:
            </Text>
            <Text>
                Growth CollectiveAttn: Copyright Compliance DepartmentAddress: 988 Ormewood Ave SE, Atlanta, GA
                30316Email: support@growthcollective.com
            </Text>

            <Title>
                Counter Notice Procedures
            </Title>

            <Text>
                If you receive a notification from Growth Collective that material made available by you on or through
                the Site has been the subject of a Notification of Claimed Infringement, then you will have the right to
                provide Growth Collective with what is called a &quot;Counter Notification.&quot; To be effective, a
                Counter Notification must be in writing, provided to Company{'\''}s Designated Agent through one of the
                methods identified above, and include substantially the following information:
            </Text>
            <Text>
                – A physical or electronic signature of the subscriber;
            </Text>
            <Text>
                – Identification of the material that has been removed or to which access has been disabled and the
                location at which the material appeared before it was removed or access to it was disabled;
            </Text>
            <Text>
                – A statement under penalty of perjury that the subscriber has a good faith belief that the material was
                removed or disabled as a result of mistake or misidentification of the material to be removed or
                disabled; and
            </Text>
            <Text>
                – The subscriber{'\''}s name, address, and telephone number, and a statement that the subscriber
                consents to the jurisdiction of Federal District Court for the judicial district in which the address is
                located, or if the subscriber{'\''}s address is outside of the United States, for any judicial district
                in which Company may be found, and that the subscriber will accept service of process from the person
                who provided notification above or an agent of such person
            </Text>

            <Title>
                Legal Disputes
            </Title>

            <Text bold>
                PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL HAVE A SUBSTANTIAL IMPACT ON HOW
                CLAIMS YOU AND GROWTH COLLECTIVE HAVE AGAINST EACH OTHER ARE RESOLVED.
            </Text>

            <Text bold>
                You and Growth Collective agree that any claim or dispute at law or equity that has arisen or may arise
                between us relating in any way to or arising out of this or previous versions of our these Terms, your
                use of or access to the Services, or any products or services sold or purchased through the Services,
                will be resolved in accordance with the provisions set forth in this Legal Disputes Section.
            </Text>

            <Subtitle>
                Choice of Law
            </Subtitle>

            <Text>
                These Terms are made under and will be governed by and construed in accordance with the laws of the
                State of Delaware, without giving effect to any principles that provide for the application of the law
                of another jurisdiction.
            </Text>

            <Subtitle>
                Claim Limitations
            </Subtitle>

            <Text>
                You agree that any cause of action arising out of or related to the Services must commence within one
                (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
            </Text>

            <Subtitle>
                Attempt to Mediate
            </Subtitle>

            <Text>
                You and Growth Collective each agree that the parties will attempt in good faith to resolve any dispute
                arising out of or relating to these Terms promptly by negotiation between the parties by individuals
                designated by each party who have authority to settle the controversy. Any party may give the other
                party written notice of any dispute not resolved in the normal course of business. If the dispute has
                not been resolved by negotiation as provided in this section, then within thirty (30) days after
                delivery of the initial notice, either party may commence arbitration in accordance with these Terms.
            </Text>

            <Subtitle>
                Agreement to Arbitrate
            </Subtitle>

            <Text>
                You and Growth Collective each agree that any and all disputes or claims that have arisen or may arise
                between you and Growth Collective relating in any way to or arising out of this or previous versions of
                these Terms, your use of or access to Growth Collective{'\''}s Services, or any products or services
                sold, offered, or purchased through our Services will be resolved exclusively through final and binding
                arbitration, rather than in court. Alternatively, you may assert your claims in small claims court in
                Atlanta Georgia, if your claims qualify and so long as the matter remains in such court and advances
                only on an individual (non-class, non-representative) basis. The Federal Arbitration Act governs the
                interpretation and enforcement of this agreement to Arbitrate.
            </Text>
            <Text>
                The arbitration will be conducted by American Arbitration Association (AAA) under its applicable rules
                and procedures, as modified by this agreement to Arbitrate at a venue located in Atlanta, Georgia. The
                arbitration will be conducted before one commercial arbitrator with substantial experience in resolving
                commercial contract disputes.
            </Text>
            <Text>
                Your rights will be determined by a neutral arbitrator and not a judge or jury. You understand that
                arbitration procedures can be more limited than rules applicable in court. Arbitrator decisions are as
                enforceable as any court order and are subject to very limited review in court.
            </Text>
            <Text>
                You and we must abide by the following rules: (a) ANY CLAIMS BROUGHT BY YOU OR US MUST BE BROUGHT IN THE
                PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING; (b) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, MAY NOT
                OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE
                RELIEF, (c) the arbitrator will honor claims of privilege and privacy recognized at law; (d) the
                arbitration will be confidential, and neither you nor we may disclose the existence, content or results
                of any arbitration, except as may be required by law or for purposes of enforcement of the arbitration
                award; (e) the arbitrator may award any individual relief or individual remedies that are permitted by
                applicable law; and (f) each side pays its own attorneys’ fees and expenses unless there is a statutory
                provision that requires the prevailing party to be paid its fees and litigation expenses, and then in
                such instance, the fees and costs awarded will be determined by the applicable law.
            </Text>
            <Text>
                With the exception of subparts (a) and (b) in the paragraph above (prohibiting arbitration on a class or
                collective basis), if any part of this arbitration provision is deemed to be invalid, unenforceable or
                illegal, or otherwise conflicts with the AAA rules and procedures, then the balance of this arbitration
                provision will remain in effect and will be construed in accordance with its terms as if the invalid,
                unenforceable, illegal or conflicting provision were not contained herein. If, however, either subpart
                (a) or (b) is found to be invalid, unenforceable or illegal, then the entirety of this arbitration
                provision will be null and void, and neither you nor we will be entitled to arbitration. If for any
                reason a claim proceeds in court rather than in arbitration, the dispute will be exclusively brought in
                state or federal court in Atlanta, Georgia.
            </Text>

            <Title>
                General
            </Title>

            <Subtitle>
                Changes to Terms
            </Subtitle>

            <Text>
                These Terms are subject to occasional revision, and if we make any substantial changes, we may notify
                you by prominently posting notice of the changes on our Site. Any significant changes to these Terms
                will be effective 30 days after posting such notice. Continued use of our Site or Services following
                notice of such changes will indicate your acknowledgement of such changes and agreement to be bound by
                the terms and conditions of such changes.
            </Text>
            <Text>
                Copyright/Trademark Information. Copyright &copy; 2020 Growth Collective Solutions, Inc. All rights
                reserved. All trademarks, logos and service marks (“Marks”) displayed on the Site are our property or
                the property of other third parties. You are not permitted to use these Marks without our prior written
                consent or the consent of such third party which may own the Marks.
            </Text>
            <Text>
                Contact Information:
            </Text>
            <Text>
                Growth Collective <br/>
                Email: support@growthcollective.com <br/>
                Updated November 19, 2020
            </Text>

            <Title>
                Dispute Procedures
            </Title>

            <Text>
                PLEASE REVIEW THESE DISPUTE PROCEDURES IN THEIR ENTIRETY. YOU WILL BE LEGALLY BOUND BY THESE TERMS WHEN
                YOU CREATE AN ACCOUNT ON OUR PLATFORM AND USE THE SERVICES. CAPITALIZED TERMS USED HERE HAVE THE SAME
                MEANING GIVEN TO THEM IN THE TERMS OF SERVICE UNLESS OTHERWISE DEFINED IN THESE PROCEDURES. THESE
                DISPUTE PROCEDURES ARE FULLY INCORPORATED INTO OUR TERMS OF SERVICE.
            </Text>
            <Text>
                Our Platform provides a mechanism for Clients and Consultants to resolve certain disputes, with the
                limitations set forth below. Our dispute procedures are for the benefit of our Users to help facilitate
                the transaction between Clients and Consultants and to protect the integrity of our Platform so our
                Users have an enjoyable experience. In no way is the provision of these dispute procedures or
                determinations made by Growth Collective under these procedures an admission of any liability of Growth
                Collective. Both Client and Consultant agree to hold Growth Collective harmless for any decisions made
                by Growth Collective during the dispute procedure process.
            </Text>
            <Text>
                In the event that a Client has a good faith dispute over hours logged by Consultant, Client will be
                permitted to dispute the hours logged subject to the following terms and conditions:
            </Text>

            <Subtitle>
                Notice Period
            </Subtitle>

            <Text>
                Within four (4) business days of the date the Client is invoiced for a charge (the “Dispute Notice
                Period”) and prior to Growth Collective disbursing funds to the Consultant, the Client will provide
                written notice to Growth Collective by submitting a support ticket through the Platform setting forth in
                reasonable detail the facts and circumstances which are the basis of the Disputed Matter (each, a
                “Dispute Notice”). The Client’s failure to submit a Dispute Notice within the Dispute Notice Period will
                constitute Client’s permanent waiver of Client’s right to dispute the invoice.
            </Text>

            <Subtitle>
                Mediation Period
            </Subtitle>

            <Text>
                In the event that the Client does submit a Dispute Notice within the Dispute Notice Period, Growth
                Collective will attempt in good faith to work with the Client and Services Consultant for a period of up
                to fourteen (14) calendar days from the date of the Dispute Notice (the “Disputed Matter Mediation
                Period”) to resolve the Disputed Matter. In the event that the Disputed Matter is successfully resolved
                within the Disputed Matter Mediation Period, each of the Client, Services Consultant and, if relevant,
                Growth Collective will take the agreed upon steps to execute the agreed-upon resolution.
            </Text>

            <Subtitle>
                Final Determination
            </Subtitle>

            <Text>
                In the event that the Disputed Matter remains unresolved at the conclusion of the Disputed Matter
                Mediation Period, by no later than the fourteenth (14th) calendar day after the end of the Disputed
                Matter Mediation Period, Growth Collective will make a determination in its sole and absolute discretion
                (the “Disputed Matter Decision”), and based upon the information provided by the Client and Consultant.
                If Growth Collective decides a refund of any amount if warranted, Growth Collective will promptly issue
                a Client a refund. Should Growth Collective decide the Disputed Matter in favor of the Consultant,
                Growth Collective will disburse funds to the Consultant.
            </Text>

            <Text>
                Last Updated February 11th, 2021
            </Text>

            <Title>
                Non-Circumvention Policy
            </Title>

            <Text>
                PLEASE REVIEW OUR NON-CIRCUMVENTION POLICY IN ITS ENTIRETY. YOU WILL BE LEGALLY BOUND BY THESE TERMS
                WHEN YOU CREATE AN ACCOUNT ON OUR PLATFORM AND USE THE SERVICES. CAPITALIZED TERMS USED HERE HAVE THE
                SAME MEANING GIVEN TO THEM IN THE TERMS OF SERVICE UNLESS OTHERWISE DEFINED IN THESE TERMS. THIS
                NON-CIRCUMVENTION POLICY IS FULLY INCORPORATED INTO OUR TERMS OF SERVICE.
            </Text>
            <Text>
                We expend tremendous resources to provide our Platform and services, and to connect Clients and
                Consultants. You are prohibited from making side-agreements, exchanging payment off our Platform, or
                engaging in activities that can be deemed competitive to Growth Collective with any user outside of our
                Platform.
            </Text>
            <Text>
                At all times during your use of our Platform and for a period of two (2) years thereafter, once
                introduced to a User on our Platform, you are prohibited from entering into any business transactions,
                agreements, or offers for employment with any User outside of our Platform for Consulting Services that
                are being provided on our Platform. Clients agree not to make direct payments to any Consultant and
                Consultants agree not to accept any direct payments for the services provided to Clients. We will
                terminate a User’s access to and use of the Platform, and enforce all available legal remedies,
                including inspection of a User’s books and records if such User is found to be making or accepting
                payment outside the Service to any User found through the Service.
            </Text>

            <Subtitle>
                Buyout
            </Subtitle>

            <Text>
                Client may directly engage or employ a Consultant off the Platform by paying Growth Collective a buyout
                fee (the “Buyout Fee”) equal to the lesser of $20,000 or a fee equal to 15% of the projected first year
                full-time salary to be paid to Consultant by Client (if GC Consultant is hired by Client on a part-time
                basis, then the projected part-time salary shall be calculated proportionately to a full-time salary and
                the Buyout Fee shall be calculated on the pro rata projected full-time salary; if Consultant is hired by
                Client as an independent contractor, then that projected remuneration shall be calculated
                proportionately to full-time remuneration and the Buyout Fee shall be calculated on the pro rata
                projected full-time remuneration). Client agrees to inform Growth Collective in writing prior to
                employing or engaging Consultant and agrees to pay Growth Collective the Buyout Fee within three (3)
                days of such notice. Client agree to provide Growth Collective a right of inspection to Client’s books
                and records with regard to the verification of the remuneration paid to Consultant by Client for the
                first year and if such remuneration is greater than the amount used to calculate the Buyout Fee, then
                Client shall pay Growth Collective the difference within 30 days of the one year anniversary of the
                payment of the Buyout Fee.
            </Text>

            <Subtitle>
                Books and Records
            </Subtitle>

            <Text>
                At all times during your use of our Platform and for a period of two (2) years thereafter, both Clients
                and Consultants will keep complete and accurate books and records sufficient to verify compliance or
                non-compliance with this Non-Circumvention Policy. If Growth Collective has reason to believe any User
                is in violation of its Non-Circumvention Policy, Growth Collective will provide thirty (30) days written
                notice of its intent to inspect such books and records. You agree to provide Growth Collective with all
                relevant records and to cooperate with reasonable cooperation to the extent necessary to verify the
                accuracy of the amounts owed and charged pursuant to this Agreement.
            </Text>

            <Subtitle>
                Remedies for Violation of this Section
            </Subtitle>

            <Text>
                You recognize and agree that any violation of the disintermediation provisions set forth above, either
                during the term or for two years after the termination of this Agreement, will cause irreparable harm to
                Growth Collective. In the event that you violate or threaten to violate any of these provisions, at any
                time during this Agreement or after, Growth Collective will have the right to enforce these terms by
                means of injunctive relief, in addition to money damages and other available legal remedy. You agree to
                pay Growth Collective the cost of pursuing any enforcement, judicial or otherwise, of this Agreement,
                including attorney’s fees and costs.
            </Text>
            <Text>
                Last Updated November 19, 2020
            </Text>

            <Title>
                Standard Work Order Terms
            </Title>

            <Text>
                PLEASE REVIEW THESE TERMS AND CONDITIONS IN THEIR ENTIRETY. YOU WILL BE LEGALLY BOUND BY THESE TERMS
                WHEN YOU SUBMIT OR ACCEPT A WORK ORDER. YOU ACKNOWLEDGE YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS
                UPON SUBMITTING OR ACCEPTING A WORK ORDER. DO NOT SUBMIT OR ACCEPT A WORK ORDER IF YOU DO NOT ACCEPT ANY
                OF THE TERMS AND CONDITIONS.
            </Text>
            <Text>
                These Standard Work Order Terms (the “Work Order Terms”) govern all work orders (“Work Orders”) between
                Consultants Clients using the Growth Collective Platform. The Clients and Consultants who use the
                Platform to purchase and offer services have agreed, as a condition of using Growth Collectives
                Services, to abide by the Work Order Terms and Growth Collective’s Terms of Service. Capitalized terms
                used in these Work Order Terms have the same meaning given to them in Growth Collecitve’s Terms of
                Service, unless otherwise defined herein. These Work Order Terms are incorporated into each Work Order
                that is submitted by a Client and accepted by a Consultant and are a binding contract between the Client
                and Consultant.
            </Text>

            <Title>
                Work Order Process
            </Title>

            <Subtitle>
                Information
            </Subtitle>

            <Text>
                Each Work Order posted by a Client or Consultant will state: a) the identity of the Client or
                Consultant; b) a description of the services; c) the work schedule; d) the fees being paid for the
                services; and e) any other specific terms included by the Client or Consultant.
            </Text>

            <Subtitle>
                Acceptance and Binding Agreement
            </Subtitle>

            <Text>
                Upon acceptance of a Work Order by a Consultant or Client, a legally binding agreement exists between
                such Consultant and Client, and the terms of that Work Order as modified and these Work Order Terms will
                govern the provision of services between the Client and Consultant.
            </Text>

            <Title>
                Payment Terms
            </Title>

            <Text>
                Consultant will be paid for the services at the rates stated in the Work Order and in accordance with
                Growth Collective’s Terms, subject to deduction of fees payable to Growth Collective for use of the
                Platform. Any upfront payments for flat rate projects or retainers will be collected upon acceptance or
                confirmation of the Work Order. For hourly rate projects, Consultant will only be paid after submitting
                a timesheet and the Dispute Notice Period described in the Dispute Procedures has elapsed.
            </Text>

            <Subtitle>
                Acceptance of Services
            </Subtitle>

            <Text>
                Growth Collective may automatically charge Client’s on-file payment method weekly for any timesheets or
                charges submitted by the Consultant. Client will have four (4) days from the invoice date to dispute the
                Consultant’s timesheet. If Client takes no action and fails to dispute the timesheet in that time
                period, Growth Collective will disburse such funds to Consultant. Once payment is disbursed to the
                Consultant, Client waives the right to dispute the invoice. All payments to a Consultant for services
                performed will be facilitated by Growth Collective on behalf of a Client.
            </Text>

            <Subtitle>
                Books and Records
            </Subtitle>

            <Text>
                At all times during your use of our Platform and for a period of two (2) years thereafter, both Clients
                and Consultants will keep complete and accurate books and records sufficient to verify compliance or
                non-compliance with this Non-Circumvention Policy. If Growth Collective has reason to believe any User
                is in violation of its Non-Circumvention Policy, Growth Collective will provide thirty (30) days written
                notice of its intent to inspect such books and records. You agree to provide Growth Collective with all
                relevant records and to cooperate with reasonable cooperation to the extent necessary to verify the
                accuracy of the amounts owed and charged pursuant to this Agreement.
            </Text>

            <Subtitle>
                Remedies for Violation of this Section
            </Subtitle>

            <Text>
                You recognize and agree that any violation of the disintermediation provisions set forth above, either
                during the term or for two years after the termination of this Agreement, will cause irreparable harm to
                Growth Collective. In the event that you violate or threaten to violate any of these provisions, at any
                time during this Agreement or after, Growth Collective will have the right to enforce these terms by
                means of injunctive relief, in addition to money damages and other available legal remedy. You agree to
                pay Growth Collective the cost of pursuing any enforcement, judicial or otherwise, of this Agreement,
                including attorney’s fees and costs.
            </Text>
            <Text>
                Last Updated November 19, 2020
            </Text>

            <Title>
                Independent Contractor Status
            </Title>

            <Text>
                Consultant’s relationship with the Client will be that of an independent contractor and not that of an
                employee. Consultant will be solely responsible for determining the method, details and means of
                performing the Consulting Services within the scope defined in the Work Order. Consultant will have full
                responsibility for applicable withholding taxes for all compensation paid to Consultant under a Work
                Order.
            </Text>

            <Title>
                Proprietary Rights
            </Title>

            <Text>
                As used in these Work Order Terms, Intellectual Property means discoveries, developments, concepts,
                designs, ideas, know-how, improvements, trade secrets and/or original works of authorship, whether or
                not patentable, copyrightable or otherwise legally protectable. This includes, but is not limited to,
                any product, method, procedure, process, technique, use, system, design or configuration or improvement
                of any kind.
            </Text>
            <Text>
                All right, title and interest, including copyright and other intellectual property rights in and to any
                data, information, ideas, concepts or material provided by a Client to a Consultant for use in
                performing services are and will remain a Client’s property (“Client IP”). Each Client grants each
                Consultant who performs services a non-exclusive, non-transferable license during the term of a Work
                Order to reproduce, display and perform Client IP solely in connection with the performance of the
                services under such Work Order. Client IP will be considered Confidential Information of a Client.
            </Text>
            <Text>
                Any Intellectual Property that is made by Consultant (solely or jointly with others) and paid for by
                Client under these Work Order Terms or for the benefit of the Client’s actual or proposed businesses,
                products, or services, will be owned by Client (to the greatest extent permitted by applicable law) and
                are compensated by the fees set forth in these Work Order Terms. Any assignment of Intellectual Property
                includes all rights of attribution, paternity, integrity, modification, disclosure and withdrawal, and
                any other rights throughout the world that may be known as or referred to as “moral rights,” “artist’s
                rights,” “droit moral,” or the like (collectively, “Moral Rights”). To the extent that Moral Rights
                cannot be assigned under applicable law, Consultant waives and agrees not to enforce any and all Moral
                Rights, including, without limitation, any limitation on subsequent modification.
            </Text>
            <Text>
                Consultant may own or hold ideas, inventions, improvements, discoveries, software design, software
                coding, charts, drawings, specifications, notebooks, tracings, photographs, negatives, draft or final
                reports, findings, recommendations, data and memoranda or other works (“Consultant IP”) developed prior
                to the commencement of services under a Work Order. All Consultant IP or Intellectual Property developed
                outside the scope of the services under a Work will remain the property Consultant. To the extent that
                Consultant IP has been incorporated into or is embedded in the Work Product, the Consultant grants to
                the Client perpetual, nonexclusive, world-wide, sublicensable, royalty-free right to use, license,
                execute, modify, reproduce, display, perform, and prepare derivative works based on such Consultant IP.
            </Text>

            <Title>
                Confidentiality
            </Title>

            <Text>
                As used in these Terms, “Confidential Information” means all confidential or proprietary information of
                a party (“Disclosing Party”) disclosed to the other party (“Receiving Party”), whether orally or in
                writing, that is either marked or designated as confidential or that a reasonable person would deem
                confidential or proprietary given the nature of the information and the circumstances under which it is
                disclosed. Confidential Information will include, but is not limited to, pricing and other terms
                reflected in agreements between the parties, and the Disclosing Party’s business and marketing plans,
                technology and technical information, product designs, business processes, and any other nonpublic or
                proprietary information of Disclosing Party. Confidential Information does not include any information
                that a Receiving Party can show (i) is or becomes generally known to the public without breach of any
                obligation owed to the Disclosing Party; (ii) was known to the Receiving Party prior to its disclosure
                by the Disclosing Party; (iii) was independently developed by the Receiving Party without breach of any
                obligation owed to the Disclosing Party; or (iv) is received from a third party without any duty or
                confidentiality obligation owed to the Disclosing Party.
            </Text>
            <Text>
                To the extent Confidential Information is shared, the Receiving Party will protect the secrecy of the
                Disclosing Party’s Confidential Information with the same degree of care as it uses to protect its own
                Confidential Information, but in no event with less than due care, and will: (a) not disclose or permit
                others to disclose Disclosing Party’s Confidential Information to anyone without first obtaining the
                express written consent of the Disclosing Party; (b) not use or permit the use of Disclosing Party’s
                Confidential Information, except as necessary for the performance of its obligations under these Terms;
                and (c) limit access to Disclosing Party’s Confidential Information to Receiving Party’s personnel who
                need to know such information for the performance of the Receiving Party’s obligations under these
                Terms.
            </Text>

            <Title>
                Warranties and Representations
            </Title>

            <Text>
                Both Client and Consultant warrant and represent that:
            </Text>
            <Text>
                Neither party is under any pre-existing obligation in conflict or in any way inconsistent with the
                provisions of these Work Order Terms.
            </Text>
            <Text>
                Performance of the Work Order will not breach any agreement to keep in confidence proprietary
                information acquired by either party in confidence or in trust prior to commencement of this Work Order.
            </Text>
            <Text>
                Neither party will not knowingly infringe upon any copyright, patent, trade secret or other property
                right of any former Client, employer or third party in the performance of the Work Order.
            </Text>
            <Text>
                Each party has authority to enter into these Work Order Terms and to perform all of such party’s
                obligations hereunder.
            </Text>

            <Title>
                Dispute Resolution
            </Title>

            <Text>
                If at any time a dispute arises between a Client and a Consultant over Consultant’s timesheet or failure
                of Client to make payment, such as a dispute will be governed by Growth Collectives Dispute Resolution
                Procedures.
            </Text>

            <Title>
                General
            </Title>

            <Subtitle>
                Entire Agreement
            </Subtitle>

            <Text>
                The Work Order, together with these Work Order Terms, and Growth Collective’s Terms of Service
                (including all accompanying terms and policies) contains the entire agreement of the parties regarding
                the subject matter and may not be modified or amended, except in writing signed and dated by both
                parties. Any prior agreements between Consultant and Client concerning the subject matter of the Work
                Order are superseded by these Work Order Terms, and are void and without force and effect.
            </Text>

            <Subtitle>
                Governing Law
            </Subtitle>

            <Text>
                These Work Order Terms will be governed by the laws of the jurisdiction in which the Client resides.
            </Text>

            <Subtitle>
                Binding Nature
            </Subtitle>

            <Text>
                These Work Order Terms are binding upon the parties and their heirs, personal representatives,
                fiduciaries, successors and assigns.
            </Text>

            <Subtitle>
                Non-Waiver
            </Subtitle>

            <Text>
                A waiver by Client with respect to any breach or violation of these Work Order Terms or of any provision
                of the Agreement by Consultant will not be deemed as or operate as a waiver of any subsequent breach or
                violation.
            </Text>

            <Subtitle>
                Assignment
            </Subtitle>

            <Text>
                The Client may assign any of its rights and obligations under these Work Order Terms. No other party to
                these Work Order Terms may assign, whether voluntarily or by operation of law, any of its rights and
                obligations under these Work Order Terms, except with the prior written consent of the Client.
            </Text>
            <Text>
                Last modified: February 11th, 2021
            </Text>
            <Text>
                Copyright © 2020 Growth Collective Solutions, Inc, Inc. All rights reserved.
            </Text>
        </PageWrapper>
    );
};

export default TermsOfService;
