import React from 'react';

interface IWeeklyHoursRange {
    hoursRange: number[];
}

const WeeklyHoursRange = ({hoursRange}: IWeeklyHoursRange) => {
    const [from, to] = hoursRange;

    if (from && to) {
        return <>{from} - {to} hours / week</>;
    } else if (from === 0) {
        return <>Less then {to} hours / week</>;
    } else if (from) {
        return <>From {from} hours / week</>;
    } else if (to) {
        return <>To {from} hours / week</>;
    }

    return <>n/a</>;
};

export default React.memo(WeeklyHoursRange);
