import {Link} from 'react-router-dom';

import {styled, Typography} from '@mui/material';

import {MD, SM, XL} from '../../constants';
import theme from '../../theme';

export const AsideLetsConnect = styled('h2')<{ hasGradient?: boolean }>`
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  position: relative;
  color: ${theme.palette.white.main};
  background-color: ${theme.palette.black.main};

  @media (min-width: ${SM}) {
    margin-bottom: ${props => props.hasGradient ? '22px' : '0'};
    padding-bottom: ${props => props.hasGradient ? '16px' : '8px'};
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: ${theme.palette.black.main};
    background-color: unset;
  }

  @media (min-width: ${MD}) {
    font-size: 40px;
    line-height: 60px;
  }

  @media (min-width: ${XL}) {
    font-size: 45px;
    line-height: 68px;
  }

  &::after {
    @media (min-width: ${SM}) {
      content: ${props => props.hasGradient ? '""' : null};
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 276px;
      height: 14px;
      transform: translateX(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='276' height='14' viewBox='0 0 276 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 9.36842C37.7172 4.63158 85.3401 1.99944 139.374 2C193.407 2.00056 217.219 4.10526 274 12' stroke='url(%23paint0_linear_983_7793)' stroke-width='3' stroke-linecap='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_983_7793' x1='2' y1='4.32759' x2='43.299' y2='104.691' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237E5FFF'/%3E%3Cstop offset='0.333333' stop-color='%233C94F7'/%3E%3Cstop offset='0.666667' stop-color='%237CF2D0'/%3E%3Cstop offset='1' stop-color='%2367DD42'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }

    @media (min-width: ${MD}) {
      width: 312px;
      height: 16px;
    }
  }
`;

export const AsideCaption = styled(Typography)`
  margin: 0 -8px 23px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${theme.palette.gray.main};
  background-color: ${theme.palette.black.main};

  @media (min-width: ${SM}) {
    margin-bottom: 36px;
    color: ${theme.palette.gray.main};
    background-color: unset;
  }
`;

export const LoginLink = styled(Link)`
  color: ${theme.palette.white.main};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  position: relative;
  padding-bottom: 6px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 2px;
    transform: translateX(-50%);
    background-color: currentColor;
    transition: width .3s;
  }

  &:hover {
    &::after {
      width: 0;
    }
  }

  &:active {
    color: ${theme.palette.purple.main};

    &::after {
      width: 0;
    }
  }

  @media (min-width: ${SM}) {
    color: ${theme.palette.black.main};
  }
`;

export const darkBgInputStyles = {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    '.MuiInputBase-input': {
        color: theme.palette.white.main,
        backgroundColor: theme.palette.black.main,
        [theme.breakpoints.up('sm')]: {
            color: theme.palette.black.main,
            backgroundColor: theme.palette.white.main,
        },
        '&:autofill, &:autofill:hover, &:autofill:focus, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
            color: theme.palette.white.main,
            backgroundColor: theme.palette.black.main,
            WebkitTextFillColor: theme.palette.white.main,
            WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.black.main} inset`,
            [theme.breakpoints.up('sm')]: {
                color: theme.palette.black.main,
                backgroundColor: theme.palette.white.main,
                WebkitTextFillColor: theme.palette.black.main,
                WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.white.main} inset`
            }
        }
    },
    '& .MuiInputBase-root': {
        color: theme.palette.white.main,
        '&::before': {
            borderBottomColor: theme.palette.progressBarBlack.main,
            [theme.breakpoints.up('sm')]: {
                borderBottomColor: theme.palette.inputDisabledBorderColor.main
            }
        },
        'input.MuiInputBase-input.MuiInput-input': {
            '&::placeholder, &::-webkit-input-placeholder': {
                opacity: '1 !important',
                color: theme.palette.gray.main
            },
        },
        [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.palette.white.main,
            color: theme.palette.black.main,
            'input::placeholder, input::-webkit-input-placeholder': {
                color: theme.palette.inputDisabledBorderColor.main,
            },
        },
    },
    '&.MuiFormControl-root': {
        marginBottom: '15px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '19px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '19px'
        },
    },
    [theme.breakpoints.up('sm')]: {
        color: theme.palette.black.main,
        backgroundColor: theme.palette.white.main,
    }
};
