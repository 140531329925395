import React, {FC, useCallback} from 'react';
import capitalize from 'lodash/capitalize';
import {Link, useNavigate} from 'react-router-dom';

import {Avatar, Box, styled, Typography} from '@mui/material';

import {ArrowDown} from '../../../../../assets/icons';
import {CardTitle} from '../../../../../components';
import {DETAILS, RouteFreelancerPortal} from '../../../../../constants';
import {IOtherMatch} from '../../../../../models';
import theme from '../../../../../theme';

const Wrp = styled('div')``;

const RecommendationCard = styled('div')`
  margin-bottom: 8px;
  padding: 16px;
  border: 1px solid ${theme.palette.lightGray.main};
  border-radius: 16px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const OtherMatchItem = styled('p')`
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.palette.gray.main};

  span {
    font: inherit;
    line-height: inherit;
    color: ${theme.palette.black.main};
  }

  &:last-of-type {
    margin-bottom: 8px;
  }
`;

const avatarStyles = {
    width: '32px',
    height: '32px',
    fontSize: '30px',
    lineHeight: 1.2,
    fontWeight: 500,
    mb: 2,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    }
};

interface IMyMatchesOtherMatches {
    items: IOtherMatch[];
}

const MyMatchesOtherMatches: FC<IMyMatchesOtherMatches> = ({items}) => {
    const navigate = useNavigate();
    const seniorityLevelPrettified = useCallback((rateRange: number[]) => {
        const [from, to] = rateRange.map(it => it / 100);  // format without cents;

        if (from === 50 && to === 100) {
            return `${from} - ${to} / hour`;
        } else if (from === 100 && to === 150) {
            return `${from} - ${to} / hour`;
        } else if (from === 150) {
            return `${from}+ / hour`;
        }
    }, []);


    return (
        <Wrp>
            <Box
                sx={{
                    mb: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    'a': {
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '4px',
                        lineHeight: '24px',
                        fontWeight: 500,
                        fontSize: '13px'
                    },
                    'svg': {
                        transform: 'rotate(-90deg)'
                    },
                    'path': {
                        fill: 'currentColor'
                    }
                }}
            >
                <CardTitle sx={{mb: 0}}>
                    You might also like
                </CardTitle>
                <Link to={RouteFreelancerPortal.myMatches}>
                    View all <ArrowDown/>
                </Link>
            </Box>
            {items.map(item =>
                <RecommendationCard
                    key={item.id}
                    onClick={() => navigate(`${RouteFreelancerPortal.myMatches}/${DETAILS}/${item.id}`)}
                >
                    {item.companyLogo && (
                        <Avatar
                            alt={item.companyName}
                            className="avatar"
                            src={item.companyLogo}
                            sx={{
                                ...avatarStyles,
                            }}
                        >
                        </Avatar>
                    )}
                    <Typography
                        sx={{
                            mb: '8px',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '20px'
                        }}
                    >
                        {item.projectName}
                    </Typography>
                    <OtherMatchItem>
                        Channel: <span>{item.channel?.name}</span>
                    </OtherMatchItem>
                    <OtherMatchItem>
                        Budget type: <span>{item.workTypes?.map(workType => capitalize(workType)).join(', ')}</span>
                    </OtherMatchItem>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '20px',
                            color: theme.palette.purple.main
                        }}
                    >
                        {seniorityLevelPrettified(item.rateRange)}
                    </Typography>
                </RecommendationCard>)}
        </Wrp>
    );
};

export default React.memo(MyMatchesOtherMatches);
