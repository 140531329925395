import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, Grid, Link, Typography} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {applicationService, authService, clientService} from '../../api';
import {MailIcon} from '../../assets/icons';
import {useAuth} from '../../hooks';
import theme from '../../theme';
import {AnimatePageWrp, Button, ContinueButton, Input, PageTopTitle, Portal, showToastError} from '..';

import {Digits} from './components';

enum EDisplayMode {
    Code = 'default',
    EmailNeedUpdate = 'email',
    EmailChanged = 'email-changed'
}

interface IProp {
    routeConfirmationCode: string;
    routeNextStep: string;
    routeUpdateEmail: string;
    service: typeof applicationService | typeof clientService;
    title: string;
    onRefetch: () => void;
}

const ConfirmationCode: React.FC<IProp> = ({
    routeConfirmationCode,
    routeNextStep,
    routeUpdateEmail,
    service,
    title,
    onRefetch
}) => {
    const {mode} = useParams();
    const [displayMode, setDisplayMode] = useState(mode || EDisplayMode.Code);
    const {isClientUnverified, user, refreshUser} = useAuth();
    const [isCodeResent, setResended] = useState(false);
    const [isSubmittingEmail, setSubmittingEmail] = useState(false);

    const navigate = useNavigate();

    const methods = useForm<any>({});
    const {clearErrors, handleSubmit, setError, setValue, watch} = methods;

    const valueDigit1 = watch('digit1');
    const valueDigit2 = watch('digit2');
    const valueDigit3 = watch('digit3');
    const valueDigit4 = watch('digit4');
    const valueDigit5 = watch('digit5');
    const newEmail = watch('newEmail', '');

    const currentEmail = user?.email;

    console.log('currentEmail', currentEmail);

    const isValidNewEmail = newEmail?.match(/^\S+@\S+\.\S+$/) && newEmail !== currentEmail;
    const isValid = valueDigit1 && valueDigit2 && valueDigit3 && valueDigit4 && valueDigit5;

    useEffect(() => {
        if (!newEmail) {
            clearErrors('newEmail');

            return;
        }

        if (newEmail === currentEmail) {
            setError('newEmail', {
                type: 'custom',
                message: 'This email address is already used. Please provide diffrent one.'
            });
        } else if (!newEmail.match(/^\S+@\S+\.\S+$/)) {
            setError('newEmail', {type: 'custom', message: 'Email Address is invalid'});
        } else {
            clearErrors('newEmail');
        }
    }, [newEmail, currentEmail, clearErrors, setError]);

    useEffect(() => {
        setDisplayMode(mode || EDisplayMode.Code);
    }, [mode]);

    const {mutate, isLoading: isSubmitting} = useMutation(service.confimationCodeSubmit,
        {
            onSuccess: async () => {
                // security role will be changed. CLIENT_UNVERIFIED -> CLIENT
                if (isClientUnverified) {
                    await authService.refreshAccessToken();
                    await onRefetch();
                    refreshUser();

                    // wait while auth context update token
                    setTimeout(() => {
                        navigate(routeNextStep);
                    }, 200);
                } else {
                    onRefetch();
                    navigate(routeNextStep);
                }
            },
            onError(error: AxiosError) {
                console.log('error', error);
                showToastError(error);
            }
        }
    );

    const handleClickUpdateEmail = async () => {
        setSubmittingEmail(true);
        clearForm();
        try {
            await service.updateEmail(newEmail);
            await authService.refreshAccessToken();     // user updated we need new Access Token
            await service.resendCode();                 // try resend new code to the new email

            // setDisplayMode(EDisplayMode.EmailChanged);
            navigate(`${routeConfirmationCode}/${EDisplayMode.EmailChanged}`);
        } catch (error: any) {
            showToastError(error);
        }
        setSubmittingEmail(false);
    };

    const clearForm = useCallback(() => {
        setValue('digit1', '');
        setValue('digit2', '');
        setValue('digit3', '');
        setValue('digit4', '');
        setValue('digit5', '');
    }, [setValue]);

    const handleResendCode = useCallback(() => {
        setResended(true);
        clearForm();
        service.resendCode();
    }, [service, clearForm]);

    const onSubmitHandler: SubmitHandler<any> = (values) => {
        const payload = {...values};

        delete payload.newEmail;
        const code = Object.values(payload).join('');

        mutate(code);
    };

    const showUpdateEmail = () => {
        clearForm();
        setResended(false);
        navigate(routeUpdateEmail);
    };

    return (
        <AnimatePageWrp maxWidth={{md: 840}}>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmitHandler)}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={0} rowSpacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '40px',
                                [theme.breakpoints.up('xl')]: {
                                    mb: '57px'
                                }
                            }}
                        >
                            <MailIcon/>
                        </Grid>
                        <Grid item xs={12}>

                            {
                                displayMode === EDisplayMode.Code && (
                                    <PageTopTitle
                                        title={title}
                                        subtitle={`We’ve sent a confirmation code to your email address ${currentEmail}. Please enter the code below`}
                                        titleMaxWidth={{md: 503, lg: 840}}
                                        subtitleMaxWidth={{md: 503, lg: 703}}
                                    />
                                )
                            }

                            {
                                displayMode === EDisplayMode.EmailNeedUpdate && (
                                    <PageTopTitle
                                        title="Enter a new email for sending a verification code"
                                    />
                                )
                            }

                            {
                                displayMode === EDisplayMode.EmailChanged && (
                                    <PageTopTitle
                                        title="Email changed successfully"
                                        subtitle={`We’ve sent a confirmation code to your email address ${newEmail}. Please enter the code below`}
                                    />
                                )
                            }

                        </Grid>
                        <Grid item xs={12}>
                            {
                                displayMode === EDisplayMode.Code || displayMode === EDisplayMode.EmailChanged ? (
                                    <Digits
                                        isCodeResent={isCodeResent}
                                        isSubmitting={isSubmitting}
                                        onResendCode={handleResendCode}
                                    />
                                ) : (
                                    <Input
                                        disabled={isSubmittingEmail}
                                        fullWidth
                                        name="newEmail"
                                        placeholder="Email"
                                        sx={{
                                            display: 'flex',
                                            mx: 'auto',
                                            maxWidth: '391px'
                                        }}
                                    />
                                )
                            }
                        </Grid>
                    </Grid>

                    {
                        (displayMode === EDisplayMode.Code || displayMode === EDisplayMode.EmailChanged) && (
                            <>
                                <Box
                                    sx={{
                                        display: 'none',
                                        my: 5,
                                        [theme.breakpoints.up('sm')]: {
                                            display: 'block',
                                            mt: '40px'
                                        }
                                    }}
                                    textAlign="center"
                                >
                                    <ContinueButton
                                        disabled={!isValid || isSubmitting}
                                        hasArrow
                                        type="submit"
                                        variant="contained"
                                    >
                                        Continue
                                    </ContinueButton>
                                </Box>
                                <Portal order={2}>
                                    <ContinueButton
                                        disabled={!isValid || isSubmitting}
                                        hasArrow
                                        sx={{
                                            [theme.breakpoints.up('sm')]: {
                                                display: 'none'
                                            }
                                        }}
                                        variant="contained"
                                        onClick={handleSubmit(onSubmitHandler)}
                                    >
                                        Continue
                                    </ContinueButton>
                                </Portal>
                            </>
                        )
                    }

                    {
                        (displayMode === EDisplayMode.EmailNeedUpdate) && (
                            <Box
                                sx={{
                                    my: 5,
                                    [theme.breakpoints.up('lg')]: {
                                        mt: '40px',
                                        mb: 0
                                    }
                                }}
                                textAlign="center">
                                <Button
                                    disabled={!isValidNewEmail || isSubmittingEmail}
                                    variant="contained"
                                    sx={{
                                        [theme.breakpoints.up('lg')]: {
                                            minWidth: '219px'
                                        }
                                    }}
                                    onClick={handleClickUpdateEmail}
                                >
                                    Confirm email
                                </Button>
                            </Box>
                        )
                    }

                    {
                        displayMode === EDisplayMode.Code && (
                            <Typography
                                textAlign="center"
                                variant="body2"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                }}
                            >
                                Forgot your email or entered the wrong one? <Link underline="hover"
                                    onClick={showUpdateEmail}>Update
                                email</Link>
                            </Typography>
                        )
                    }

                </form>
            </FormProvider>
        </AnimatePageWrp>
    );
};

export default React.memo(ConfirmationCode);
