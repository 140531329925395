import React, {useCallback, useContext} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import {Avatar, Box, IconButton, Stack, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {SxProps} from '@mui/system';

import {ArrowDown, MicOffIcon, MicOnIcon, VideoCallIcon} from '../../../../assets/icons';
import {LG, RouteClientPortal, RouteFreelancerPortal, VIDEO_CALL} from '../../../../constants';
import {TwilioContext} from '../../../../contexts';
import {useAuth, useConversation} from '../../../../hooks';
import {ECallType, EMeetingStatus, IEngagement} from '../../../../models';
import theme from '../../../../theme';
import {getNameLetters, stringToColor} from '../../../../utils';
import {Button} from '../../../';
import DarkBlueButton from '../../../Button/DarkBlueButton';
import ChatTopButtons from '../ChatTopButtons';

import EngagementInfo from './EngagementInfo';
import ScheduledMeeting from './ScheduledMeeting';

const Wrp = styled('div')`
  padding: 20px 24px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  border-top: 1px solid ${theme.palette.lightGray.main};
  border-bottom: 1px solid ${theme.palette.lightGray.main};
  height: 88px;
  position: relative;

  @media (min-width: ${LG}) {
    padding-right: 77px;
    border-top: unset;
  }

  @media (min-width: 1400px) {
    padding-right: 24px;
  }
`;

const Pin = styled('div')`
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  z-index: 10;
`;

const OngoingCallWrapper = styled('div')`
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 24px;
  background-color: #292d30;
  z-index: 10;
`;

interface IProps {
    conversationId: string;
    engagement?: IEngagement;
    sx?: SxProps<Theme>;
    onUserInfoClick: (isVisible: boolean) => void;
}

const ChatMainTop: React.FC<IProps> = ({
    conversationId,
    engagement,
    sx,
    onUserInfoClick,
}) => {
    const {isClient} = useAuth();
    const {videoCallRoomId} = useParams();
    const {attributes: {call, meetings}, meta} = useConversation(conversationId || '');
    const {
        activeCall,
        duration,
        isCompact,
        isMicrophoneOn,
        finishCall,
        setIsCompact,
        toggleMicrophone,
    } = useContext(TwilioContext);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const {pathname} = useLocation();
    const navigate = useNavigate();

    const {avatar, name} = meta?.remoteParticipant || {};

    const handleMoveBackToList = useCallback(() => {
        navigate(isClient ? RouteClientPortal.inbox : RouteFreelancerPortal.inbox);
    }, [isClient, navigate]);

    const handleDisconnectClick = async () => {
        await finishCall(conversationId);
        navigate(-1);
    };

    const handleCallStart = () => {
        if (call === ECallType.CALLING_VIDEO) {
            navigate(VIDEO_CALL);
        } else {
            navigate(`${VIDEO_CALL}/audio`);
        }
    };

    const isIncomingCall = [ECallType.CALLING_VIDEO, ECallType.CALLING_AUDIO].includes(call) && !videoCallRoomId;

    return (
        <Wrp
            sx={sx}
        >
            {!lgUp && (
                <IconButton
                    className="back-chat-btn"
                    sx={{
                        ml: '-8px',
                        mr: '-8px',
                        'svg': {
                            transform: 'rotate(90deg)'
                        }
                    }}
                    onClick={handleMoveBackToList}
                >
                    <ArrowDown/>
                </IconButton>
            )}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    mr: 'auto',
                    cursor: 'pointer',
                    [theme.breakpoints.up('lg')]: {
                        cursor: 'auto',
                        maxWidth: '306px'
                    }
                }}
                onClick={() => !lgUp && onUserInfoClick(true)}
            >
                <Avatar
                    alt={name || ''}
                    src={avatar || ''}
                    sx={{
                        width: '48px',
                        height: '48px',
                        backgroundColor: stringToColor(name || ''),
                        fontSize: '16px',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        'img': {
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            aspectRatio: '1/1'
                        }
                    }}
                >
                    {getNameLetters(name || '')}
                </Avatar>
                <Box sx={{ml: '4px'}}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '21px',
                            color: theme.palette.black.main
                        }}
                    >
                        {name}
                    </Typography>

                    {/* Unknown what timezone to use. Freelancer can have multiply */}
                    {/* <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: theme.palette.gray.main
                        }}
                    >
                        Current local time: 4:30 PM CET
                    </Typography> */}
                </Box>
            </Box>
            {lgUp && <ChatTopButtons conversationId={conversationId} engagementStatus={engagement?.status}/>}

            {/* pin under top: Scheduled Meeting, Engagement info */}
            <Pin>

                {/* show compact version of info boxes. OnHold, Resume Request, Cancel Resume... etc. */}
                <EngagementInfo data={engagement}/>

                {/* if meeting scheduled */}
                {meetings?.filter(it => it.status !== EMeetingStatus.PAST).map(meeting => (
                    <ScheduledMeeting conversationId={conversationId} key={meeting.id} data={meeting}/>
                ))}

            </Pin>

            {/* Incoming Call */}
            {
                isIncomingCall && !activeCall && (
                    <OngoingCallWrapper>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: 1.5,
                                color: theme.palette.white.main,
                                textAlign: 'center'
                            }}
                        >
                            Incoming {ECallType.CALLING_VIDEO ? 'video' : 'audio'} call
                        </Typography>

                        <Stack direction="row" spacing={2}>
                            {call === ECallType.CALLING_VIDEO && (
                                <Button
                                    size="small"
                                    sx={{border: '2px solid white', color: 'white!important'}}
                                    onClick={() => navigate(VIDEO_CALL, {state: {isCameraOff: true}})}    // FIXME it should be added to the same room, but with disabled video
                                >
                                    Join without video
                                </Button>
                            )}

                            <DarkBlueButton
                                size="small"
                                onClick={handleCallStart}
                            >
                                {call === ECallType.CALLING_VIDEO ? <VideoCallIcon/> : <CallIcon/>} &nbsp; Join
                            </DarkBlueButton>
                        </Stack>

                    </OngoingCallWrapper>
                )
            }

            {/* minimized Active Call */}
            {
                isCompact && activeCall?.pathname === pathname && (
                    <OngoingCallWrapper>
                        <Box
                            sx={{
                                display: 'inherit',
                                gap: 'inherit',
                                alignItems: 'inherit',
                                mr: 'auto',
                                cursor: 'pointer'
                            }}
                            onClick={() => setIsCompact(false)}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    lineHeight: 1.5,
                                    color: theme.palette.white.main,
                                    textAlign: 'center'
                                }}
                            >
                                Video Call with {meta?.remoteParticipant?.name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: 300,
                                    p: '4px 8px',
                                    width: 'max-content',
                                    borderRadius: '30px',
                                    textAlign: 'center',
                                    color: theme.palette.white.main,
                                    backgroundColor: theme.palette.black.main
                                }}
                            >
                                {duration}
                            </Typography>
                        </Box>
                        <IconButton
                            sx={{
                                p: '10px',
                                color: theme.palette.white.main,
                                boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                                backgroundColor: isMicrophoneOn ? 'rgba(255, 255, 255, 0.1)' : theme.palette.error.main,
                                'svg': {
                                    width: '20px',
                                    height: '20px',
                                },
                                'path': {
                                    fill: 'currentColor'
                                },
                                '&:hover': {
                                    backgroundColor: isMicrophoneOn ? theme.palette.textGray.dark : theme.palette.error.light
                                }
                            }}
                            onClick={() => toggleMicrophone()}
                        >
                            {isMicrophoneOn ? <MicOnIcon/> : <MicOffIcon/>}
                        </IconButton>
                        <IconButton
                            sx={{
                                p: '10px',
                                color: theme.palette.white.main,
                                boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                                backgroundColor: theme.palette.error.main,
                                'svg': {
                                    width: '20px',
                                    height: '20px',
                                },
                                'path': {
                                    fill: 'currentColor'
                                },
                                '&:hover': {
                                    backgroundColor: theme.palette.error.light
                                }
                            }}
                            onClick={handleDisconnectClick}
                        >
                            <CallEndIcon/>
                        </IconButton>
                    </OngoingCallWrapper>
                )
            }
        </Wrp>
    );
};

export default React.memo(ChatMainTop);

