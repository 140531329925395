import React from 'react';

const AccountNoOutlineIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.7826 9.53272C12.5179 8.95421 13.0546 8.16091 13.318 7.26317C13.5815 6.36544 13.5586 5.40791 13.2525 4.52382C12.9464 3.63972 12.3723 2.87301 11.6102 2.33036C10.848 1.7877 9.93571 1.49609 9.00012 1.49609C8.06453 1.49609 7.1522 1.7877 6.39007 2.33036C5.62793 2.87301 5.05388 3.63972 4.74778 4.52382C4.44168 5.40791 4.41875 6.36544 4.68219 7.26317C4.94563 8.16091 5.48233 8.95421 6.21762 9.53272C4.95768 10.0375 3.85834 10.8747 3.03679 11.9552C2.21524 13.0356 1.70229 14.3187 1.55262 15.6677C1.54179 15.7662 1.55046 15.8659 1.57814 15.961C1.60582 16.0561 1.65197 16.1449 1.71395 16.2222C1.83913 16.3783 2.02121 16.4783 2.22012 16.5002C2.41903 16.5221 2.61849 16.4641 2.77461 16.3389C2.93074 16.2137 3.03074 16.0316 3.05262 15.8327C3.21731 14.3666 3.91638 13.0126 5.01628 12.0293C6.11618 11.046 7.53979 10.5025 9.01512 10.5025C10.4904 10.5025 11.9141 11.046 13.014 12.0293C14.1139 13.0126 14.8129 14.3666 14.9776 15.8327C14.998 16.017 15.0859 16.1872 15.2244 16.3105C15.363 16.4337 15.5422 16.5013 15.7276 16.5002H15.8101C16.0067 16.4776 16.1864 16.3782 16.31 16.2237C16.4337 16.0691 16.4912 15.872 16.4701 15.6752C16.3197 14.3224 15.804 13.036 14.9783 11.9539C14.1525 10.8718 13.0478 10.0348 11.7826 9.53272ZM9.00012 9.00022C8.40678 9.00022 7.82676 8.82427 7.33341 8.49462C6.84006 8.16498 6.45554 7.69644 6.22848 7.14827C6.00142 6.60009 5.94201 5.99689 6.05776 5.41494C6.17352 4.833 6.45924 4.29845 6.8788 3.8789C7.29836 3.45934 7.83291 3.17362 8.41485 3.05786C8.99679 2.9421 9.59999 3.00151 10.1482 3.22858C10.6963 3.45564 11.1649 3.84016 11.4945 4.3335C11.8242 4.82685 12.0001 5.40687 12.0001 6.00022C12.0001 6.79587 11.684 7.55893 11.1214 8.12154C10.5588 8.68415 9.79577 9.00022 9.00012 9.00022Z"
                fill="#535A62"/>
        </svg>
    );
};

export default AccountNoOutlineIcon;
