import React from 'react';
import isBoolean from 'lodash/isBoolean';

import {StopWatchIcon} from '../../../../assets/icons';
import {Pill, Tooltip} from '../../..';

interface PendingPillProps {
    isAuthor?: boolean;
}

const PendingPill: React.FC<PendingPillProps> = ({isAuthor}) => {
    const isTitle = isBoolean(isAuthor);

    const content = (
        <Pill
            icon={<StopWatchIcon/>}
            hasIcon
            hasOutline
            iconPosition="start"
            variant="gray"
        >
            Pending proposal
        </Pill>
    );

    if (isTitle) {
        return (
            <Tooltip title={isAuthor ? 'Your proposal is being reviewed' : 'The proposal is waiting to be reviewed'}>
                <span>
                    {content}
                </span>
            </Tooltip>
        );
    }

    return (
        content
    );
};

export default React.memo(PendingPill);
