const initial = {opacity: 0, width: 0};
const animate = {opacity: 1, width: 'auto'};
const exit = {opacity: 0, width: 0};
const whileTap = {scale: 1.1};

export const animationBase = {
    initial: {...initial},
    animate: {...animate},
    exit: {...exit},
    whileTap: {...whileTap}
};

export const animationChips = (i: number) => ({
    ...animationBase,
    transition: {
        delay: 0.05 * i,
        duration: 0.3,
        ease: 'easeInOut',
    }
});
