import {
    FREELANCER,
    FREELANCER_ENGAGEMENT,
    TIMESHEETS,
} from '../constants';
import {
    IReportDate,
    IReportRole,
    IReportSearchParams,
    IWorkWeek} from '../models';
import {IEarningReport} from '../models/earning.model';
import {formatQueryStringParams} from '../utils/request.utils';

import axiosInstance from './base.api';

const getEarnings = async (params: IReportSearchParams) => {
    const response = await axiosInstance.get<IEarningReport>(`${FREELANCER}/payouts`, {
        params: formatQueryStringParams(params)
    });

    return response.data;
};

const getTimesheets = (engagementId: number) => async () => {
    const response = await axiosInstance.get<{timesheets: IWorkWeek[]}>(`${FREELANCER_ENGAGEMENT}/${engagementId}/${TIMESHEETS}`);

    return response.data.timesheets;
};

const getTimesheetsDate = async (params: IReportSearchParams) => {
    const response = await axiosInstance.get<IReportDate>(`${FREELANCER}/${TIMESHEETS}/report/dates`, {
        params: formatQueryStringParams(params)
    });

    return response.data;
};

const getTimesheetsRole = async (params: IReportSearchParams) => {
    const response = await axiosInstance.get<IReportRole>(`${FREELANCER}/${TIMESHEETS}/report/roles`, {
        params: formatQueryStringParams(params)
    });

    return response.data;
};

const submitHours = async (timesheetId: number, payload: Partial<IWorkWeek>) => {
    return await axiosInstance.put<null>(`${FREELANCER}/timesheets/${timesheetId}`, payload);
};

const submitWeek = async (timesheetId: number) => {
    return await axiosInstance.patch<null>(`${FREELANCER}/timesheets/${timesheetId}`);
};


export const timesheetsService = {
    getEarnings,
    getTimesheets,
    getTimesheetsDate,
    getTimesheetsRole,
    submitHours,
    submitWeek,
};
