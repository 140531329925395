import React, {useCallback, useEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../../api';
import {LoadingMask, showToastError, StepsLayout} from '../../../../components';
import {IStepNav} from '../../../../components/StepsNavBar/StepsNavBar';
import {CLIENT_ROLES, COMPLETED, CONFIRMATION_CODE, PROJECT, RouteClientPortal} from '../../../../constants';
import {stepsOrderClient, stepsOrderRole, stepsOrderRoleDisplay, urlByClientView} from '../../../../routes/routes.utils';
import theme from '../../../../theme';
import {RolesContextProvider} from '../../contexts/roles.context';

const EditRolePage = () => {
    const {roleId} = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const {
        data: role,
        isLoading
    } = useQuery(
        [CLIENT_ROLES, Number(roleId)],
        clientService.getRoleById(parseInt(roleId || '')), {
            enabled: !!roleId,
            staleTime: 60 * 1000,
            onError: (error) => showToastError(error as AxiosError)
        }
    );

    const currentStep = useMemo(() => {
        const routes = location.pathname.split('/').reverse();

        const step = routes.find(route => stepsOrderRole.includes(route));

        return step ? stepsOrderRole.indexOf(step) + 1 : 1;
    }, [location.pathname]);

    const steps: IStepNav[] = useMemo(() => {
        if (!role) return [];
        const completedIndex = stepsOrderClient.indexOf(role.view);

        return stepsOrderRole.map((item, index) => ({
            active: currentStep === index + 1,
            disabled: currentStep !== index + 1 && completedIndex + 1 < index,
            text: stepsOrderRoleDisplay[index],
            link: `${RouteClientPortal.myRoleDetails}/${roleId}/edit/${stepsOrderRole[index]}`
        }));
    }, [currentStep, role, roleId]);

    const handleCancelClick = useCallback(() => navigate(`${RouteClientPortal.myRoleDetails}/${roleId}`), [roleId, navigate]);

    useEffect(() => {
        if (!role) return;

        // we should redirect at the current step only first time
        if (location.pathname !== `${RouteClientPortal.myRoleDetails}/${role.id}/edit`) return;

        let step = urlByClientView(role.view).split('/').pop() || stepsOrderRole[0];

        // no ThankYou page in this flow. PROJECT is the last page.
        if (step === CONFIRMATION_CODE || step === COMPLETED) {
            step = PROJECT;
        }

        navigate(step, {replace: true});
    }, [location.pathname, role, navigate, currentStep]);

    if (isLoading) {
        return <LoadingMask/>;
    }

    if (!role) {
        return <Typography>Role not found</Typography>;
    }

    return (
        <RolesContextProvider>
            <Box
                sx={{
                    pt: '20px',
                    [theme.breakpoints.up('sm')]: {
                        padding: '20px 20px 0'
                    }
                }}
            ></Box>

            <StepsLayout
                currentStep={currentStep}
                steps={steps}
                onCancel={handleCancelClick}
            />

        </RolesContextProvider>
    );
};

export default EditRolePage;
