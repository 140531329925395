export const ROOT_ROUTE = '/';

const APPLICATION = 'application';
const CLIENT = 'client';
const CLIENT_PORTAL = 'client-portal';
const FREELANCER_PORTAL = 'freelancer-portal';

const LOGIN = 'login';
const SIGNUP = 'signup';
const UNAUTHORIZED = 'unauthorized';
const FORGOT_PASSWORD = 'forgot-password';
const NEW_PASSWORD = 'new-password';
const WORK_ORDER_TERMS = 'work-order-terms';
const PRIVACY_POLICY = 'privacy-policy';
const WIDGET = 'widget';

export const BUDGET = 'budget';
export const CHANNEL = 'channel';
export const CHANNELS = 'channels';
export const SUB_CHANNELS = 'sub-channels';
export const COMPLETED = 'completed';
export const CONFIRMATION_CODE = 'confirmation-code';
export const EXPERIENCE = 'experience';
export const EXPERTISE = 'expertise';
export const INBOX = 'inbox';
export const LOCATION = 'location';
export const OTHER_DETAILS = 'other-details';
export const PROJECT = 'project';
export const TOOLS = 'tools';
export const VERTICALS_AND_BRANDS = 'verticals-and-brands';
export const VERTICALS = 'verticals';
export const WORK_TYPES = 'work-types';
export const THANK_YOU = 'thank-you';

export const ADD_CERTIFICATE = 'add-certificate';
export const EDIT_CERTIFICATE = 'edit-certificate';
export const ADD_REFERENCE = 'add-reference';
export const EDIT_REFERENCE = 'edit-reference';

// Client portal
export const DYNAMIC_PROFILES = 'dynamic-profiles';
export const PUBLIC_PROFILE = 'public-profile';
export const EARNINGS = 'earnings';
export const HIRES = 'hires';
export const MATCHES = 'matches';
export const MATCHE_WITH_ROLE = 'matches-with-roles';
export const PROFILE = 'profile';
export const ROLES = 'roles';
export const REPORTS = 'reports';
export const SPENDINGS = 'spendings';
export const TIMESHEETS = 'timesheets';
export const WORK = 'work';

// Freelancer portal
export const ACCOUNT = 'account';
export const BILLING = 'billing';
export const DETAILS = 'details';
export const CASE_STUDIES = 'case-studies';
export const REFERENCES = 'references';
export const CERTIFICATES = 'certificates';
export const SETTINGS = 'settings';
export const VIDEO_CALL = 'video-call';

// help module
export const HELP = 'help';
export const GENERAL = 'general';
export const PAYMENT = 'payment';
export const PROCESS = 'process';
export const SUPPORT = 'support';
export const PRICING_AND_BILLING = 'pricing-and-billing';


export const COMPONENTS = 'components';

export const RouteAuth = {
    default: '/',
    joinAs: '/join',
    login: `/${LOGIN}`,

    forgotPassword: `/${FORGOT_PASSWORD}`,
    impersonal: '/impersonal',
    newPassword: `/${NEW_PASSWORD}`,
    privacyPolicy: `/${PRIVACY_POLICY}`,
    terms: `/${WORK_ORDER_TERMS}`,
    unauthorized: `/${UNAUTHORIZED}`,
};

export const RouteCreateApplicantAccount = {
    signup: `/${SIGNUP}`,

    experience: `/${EXPERIENCE}`,
    channels: `/${CHANNELS}`,
    expertise: `/${EXPERTISE}`,
    verticalsAndBrands: `/${VERTICALS_AND_BRANDS}`,
    workTypes: `/${WORK_TYPES}`,
    otherDetails: `/${OTHER_DETAILS}`,
    confirmationCode: `/${CONFIRMATION_CODE}`,
    updateEmail: `/${CONFIRMATION_CODE}/email`,
};

export const RouteCreateClientAccount = {
    signup: `/${CLIENT}/${SIGNUP}`,
    channels: `/${CLIENT}/${CHANNEL}`,
    verticals: `/${CLIENT}/${VERTICALS}`,
    tools: `/${CLIENT}/${TOOLS}`,
    location: `/${CLIENT}/${LOCATION}`,
    budget: `/${CLIENT}/${BUDGET}`,
    project: `/${CLIENT}/${PROJECT}`,
    confirmationCode: `/${CLIENT}/${CONFIRMATION_CODE}`,
    updateEmail: `/${CLIENT}/${CONFIRMATION_CODE}/email`,
    completed: `/${CLIENT}/${COMPLETED}`
};

export const RouteApplication = {
    addCertificate: `${APPLICATION}/${ADD_CERTIFICATE}`,
    editCertificate: `${APPLICATION}/${EDIT_CERTIFICATE}`,
    addReference: `${APPLICATION}/${ADD_REFERENCE}`,
    editReference: `${APPLICATION}/${EDIT_REFERENCE}`,
    application: `/${APPLICATION}`,
    caseStudy: `/${APPLICATION}/${CASE_STUDIES}`,
    completed: `/${APPLICATION}/completed`,
};

export const RouteClientPortal = {
    default: `/${CLIENT_PORTAL}`,
    myRoles: `/${CLIENT_PORTAL}/${ROLES}`,
    myRoleDetails: `/${CLIENT_PORTAL}/${ROLES}/${DETAILS}`,
    myRolesNew: `/${CLIENT_PORTAL}/${ROLES}/new`,
    myMatches: `/${CLIENT_PORTAL}/${MATCHES}`,
    myHires: `/${CLIENT_PORTAL}/${HIRES}`,
    myHireDetails: `/${CLIENT_PORTAL}/${HIRES}/${DETAILS}`,
    mySpendings: `/${CLIENT_PORTAL}/${SPENDINGS}`,
    myProfile: `/${CLIENT_PORTAL}/${PROFILE}`,

    // others
    caseStudy: `/${CLIENT_PORTAL}/${CASE_STUDIES}`,
    inbox: `/${CLIENT_PORTAL}/${INBOX}`,
    profileSettings: `/${CLIENT_PORTAL}/${PROFILE}-settings`,
    publicFreelancerProfile: `/${CLIENT_PORTAL}/${PUBLIC_PROFILE}`,
    reports: `/${CLIENT_PORTAL}/${SPENDINGS}/${REPORTS}`,
    help: `/${CLIENT_PORTAL}/${HELP}`,
    contactUs: `/${CLIENT_PORTAL}/${HELP}/contact-us`,
};

export const RouteFreelancerPortal = {
    default: `/${FREELANCER_PORTAL}`,
    myWorks: `/${FREELANCER_PORTAL}/${WORK}`,
    myWorkDetails: `/${FREELANCER_PORTAL}/${WORK}/${DETAILS}`,
    myMatches: `/${FREELANCER_PORTAL}/${MATCHES}`,
    myProfile: `/${FREELANCER_PORTAL}/${PROFILE}`,
    myEarnings: `/${FREELANCER_PORTAL}/${EARNINGS}`,

    // others
    caseStudy: `/${FREELANCER_PORTAL}/${CASE_STUDIES}`,
    inbox: `/${FREELANCER_PORTAL}/${INBOX}`,
    profileSettings: `/${FREELANCER_PORTAL}/${PROFILE}-settings`,
    publicFreelancerProfile: `/${FREELANCER_PORTAL}/${PUBLIC_PROFILE}`,
    reports: `/${FREELANCER_PORTAL}/${EARNINGS}/${REPORTS}`,
    help: `/${FREELANCER_PORTAL}/${HELP}`,
    contactUs: `/${FREELANCER_PORTAL}/${HELP}/contact-us`,
};

// Widget - Create Client Account
export const RouteWidget = {
    default: `/${WIDGET}`,

    channels: `/${WIDGET}/${CHANNEL}`,
    verticals: `/${WIDGET}/${VERTICALS}`,
    tools: `/${WIDGET}/${TOOLS}`,
    location: `/${WIDGET}/${LOCATION}`,
    budget: `/${WIDGET}/${BUDGET}`,
    project: `/${WIDGET}/${PROJECT}`,

    signup: `/${WIDGET}/${CLIENT}/${SIGNUP}`,
    confirmationCode: `/${WIDGET}/${CLIENT}/${CONFIRMATION_CODE}`,
    updateEmail: `/${WIDGET}/${CLIENT}/${CONFIRMATION_CODE}/email`,
    completed: `/${WIDGET}/${CLIENT}/${COMPLETED}`
};

export const RoutePublic = {
    components: `/${COMPONENTS}`,
};
