import React, {useEffect, useMemo, useRef} from 'react';
import {AxiosError} from 'axios';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {LockOpenIcon, PurseOutlineIcon} from '../../../assets/icons';
import {
    AnimatePageWrp,
    AsideMenu,
    ClientPortalTitle,
    LoadingMask,
    showToastError,
    Tab,
    Tabs
} from '../../../components';
import {IAsideMenuItem} from '../../../components/AsideMenu/AsideMenu';
import {ACCOUNT, BILLING, CLIENT, MD, RouteClientPortal, SETTINGS, XL} from '../../../constants';
import theme from '../../../theme';

const tabs = [ACCOUNT, BILLING, SETTINGS];

const ContentWrp = styled('div')`
  @media (min-width: ${MD}) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 365px auto;
    gap: 24px;

    form {
      margin: 0 auto;
    }
  }

  @media (min-width: ${XL}) {
    grid-template-columns: 365px auto 365px;
    gap: 119px;
  }
`;

const desktopTabs: IAsideMenuItem[] = [
    {
        link: `${RouteClientPortal.profileSettings}/${ACCOUNT}`,
        title: 'Account',
        icon: <LockOpenIcon/>
    },
    {
        link: `${RouteClientPortal.profileSettings}/${BILLING}`,
        title: 'Billing',
        icon: <PurseOutlineIcon/>
    },
    // {
    //     link: `${RouteClientPortal.profileSettings}/${SETTINGS}`,
    //     title: 'Settings',
    //     icon: <SettingsIcon/>
    // }
];

const ClientProfilePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const {
        isLoading,
        data
    } = useQuery([CLIENT], clientService.getClient, {
        staleTime: 5 * 60 * 1000,
        onError: (error) => showToastError(error as AxiosError)
    });

    const activeTab = useMemo(() => location.pathname.replace(`${RouteClientPortal.profileSettings}/`, ''), [location.pathname]);

    // activate first tab if no one selected
    useEffect(() => {
        if (location.pathname === RouteClientPortal.myProfile) {
            navigate(tabs[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!activeTab || isLoading) return;

        ref.current?.focus();
    }, [activeTab, isLoading]);

    return (
        <Box
            sx={{
                pt: '38px',
                [theme.breakpoints.up('md')]: {
                    pt: '56px'
                }
            }}
        >
            <Box
                sx={{
                    mb: '22px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: '64px',
                    }
                }}
            >
                <ClientPortalTitle>
                    Profile <br/>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px !important',
                            color: theme.palette.gray.main
                        }}
                        variant="caption"
                    >
                        Here you can edit your profile and manage your account & billing settings
                    </Typography>
                </ClientPortalTitle>

            </Box>
            <ContentWrp>
                {
                    (!isLoading && mdDown) ? (
                        <Tabs hasScroll fullWidth>
                            {tabs.map(item => (
                                <Tab
                                    isActive={activeTab === item}
                                    key={item}
                                    tabIndex={activeTab === item ? 0 : undefined}
                                    ref={activeTab === item ? ref : null}
                                    onClick={() => navigate(item)}
                                >
                                    {getTabNameByRoute(item)}
                                </Tab>
                            ))}
                        </Tabs>
                    ) : (
                        <Box
                            sx={{
                                position: 'relative'
                            }}
                        >
                            <AsideMenu list={desktopTabs}/>
                        </Box>
                    )
                }
                {
                    isLoading && (
                        <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <LoadingMask/>
                        </Box>
                    )
                }
                {/* Route for current tab */}
                {data && <AnimatePageWrp><Outlet/></AnimatePageWrp>}
            </ContentWrp>
        </Box>
    );
};

function getTabNameByRoute(route: string) {
    switch (route) {
        case ACCOUNT:
            return 'Account';
        case BILLING:
            return 'Billing';
        case SETTINGS:
            return 'Settings';
        default:
            return '';
    }
}

export default React.memo(ClientProfilePage);
