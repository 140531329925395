import React from 'react';

const UnionIcon = () => {
    return (
        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M1.91585 9.18489C2.84784 9.94175 4.01476 10.3555 5.2189 10.3561C5.91698 10.3573 6.60809 10.2186 7.25089 9.94839C8.52149 9.41028 9.52541 8.39418 10.0428 7.12272C10.5601 5.85126 10.5487 4.42809 10.011 3.16498C9.47331 1.90187 8.45319 0.901801 7.17408 0.383863C5.89498 -0.134074 4.46126 -0.127649 3.18693 0.401729C2.07799 0.867549 1.16538 1.69958 0.603702 2.75687C0.0420286 3.81416 -0.134168 5.03168 0.104965 6.20314C0.344098 7.37464 0.983854 8.42802 1.91585 9.18489ZM3.45981 3.06922C3.95114 2.66484 4.56865 2.44227 5.20685 2.43952C5.93282 2.43443 6.63106 2.71577 7.14809 3.22164C7.60388 3.66516 7.89042 4.25197 7.95884 4.88188C8.02726 5.51179 7.87329 6.14574 7.52324 6.67549C7.1732 7.20524 6.64875 7.59799 6.03944 7.78663C5.43017 7.97527 4.77379 7.94815 4.18237 7.70987C3.591 7.47163 3.10126 7.03696 2.79678 6.48017C2.4923 5.92334 2.39195 5.27891 2.51288 4.6568C2.63379 4.03473 2.96849 3.47361 3.45981 3.06922ZM3.22151 15.6059C3.85495 15.8661 4.53386 15.9998 5.21937 15.9994V15.9998C5.90492 16.0002 6.58382 15.8665 7.21727 15.6063C7.85072 15.346 8.42626 14.9644 8.91101 14.4832C9.39577 14.0021 9.78019 13.4307 10.0424 12.8019C10.3045 12.1732 10.4392 11.4993 10.4388 10.8188H9.25361C8.93688 10.8188 8.62757 10.9137 8.3661 11.0911C8.10466 11.2685 7.90326 11.5202 7.78826 11.8131C7.58609 12.3255 7.2331 12.7656 6.7753 13.076C6.31751 13.3863 5.77614 13.5526 5.22175 13.553C4.66736 13.5535 4.12571 13.3882 3.66737 13.0786C3.20903 12.769 2.85528 12.3295 2.65224 11.8175C2.41524 11.2164 1.83508 10.8184 1.18517 10.8184H9.92369e-07C-0.000421858 11.4989 0.134292 12.1728 0.396441 12.8016C0.658588 13.4304 1.04303 14.0017 1.52778 14.4829C2.01253 14.964 2.58808 15.3456 3.22151 15.6059Z"
                fill="white"/>
        </svg>
    );
};

export default UnionIcon;
