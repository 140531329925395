import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AxiosError} from 'axios';
import {parse} from 'duration-fns';
import {FormProvider, SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {any, object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Stack, Typography} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {timesheetsService} from '../../../../../../api';
import {ChatIcon, ExclamationMarkIcon} from '../../../../../../assets/icons';
import {
    CollapsibleBox,
    ContinueButton,
    DialogConfirm,
    Input,
    showToastError,
    showToastSuccess,
    TimesheetHeading
} from '../../../../../../components';
import {useAuth} from '../../../../../../hooks';
import {EWorkWeekStatus, IWorkWeek} from '../../../../../../models';
import theme from '../../../../../../theme';
import {formatDate, formatHoursFromDuration} from '../../../../../../utils';

import Day from './Day';

const MAX_DESCRIPTION_LENGTH = 255;
const EMPTY_DURATIONS: string[] = []; // ['P0D', 'PT0S']; allow enter 00:00

const isEmptyDuration = (duration: string) => !duration || EMPTY_DURATIONS.includes(duration);

const weekSchema = object({
    id: any(),
    fromDate: string(),
    toDate: string(),
    status: any(),
    rejectionReasons: any(),
    workDescription: any()
        .refine((value) => !value || value.length <= MAX_DESCRIPTION_LENGTH,
            `Max length is ${MAX_DESCRIPTION_LENGTH}.`),
    workDays: object({
        id: any(),
        date: string()
            .min(1),
        workDescription: string()
            .min(0)
            .max(255),
        workTime: any()
            .refine((duration) => {
                try {
                    const {hours, minutes} = parse(duration);   // can be invalid

                    return isEmptyDuration(duration) || hours || minutes || (hours === 0 && minutes === 0 && duration);
                } catch (error) {
                    return false;
                }
            })
    })
        .refine(values => {
            return !values.workDescription || (values.workDescription && !isEmptyDuration(values.workTime));
        }, {
            path: ['workTime'],
            message: ' '
        })
        .refine(values => !values.workDescription || (values.workDescription && !isEmptyDuration(values.workTime)), {
            path: ['workDescription'],
            message: 'Either fill in hours or remove description'
        })
        .array(),
    workTime: string()
});

type WeekInput = TypeOf<typeof weekSchema>;

type WeekFormProps = {
    data: IWorkWeek;
    isAutoFocus?: boolean;
    onRefetch: () => void;
};

const WeekForm: React.FC<WeekFormProps> = ({data, isAutoFocus, onRefetch}) => {
    const {isImpersonal} = useAuth();
    const [collapsed, setCollapsed] = useState(false);
    const [open, setOpen] = useState(false);

    const elementRef = useRef<HTMLDivElement | null>(null);

    const methods = useForm<WeekInput>({
        resolver: zodResolver(weekSchema)
    });

    const {
        control,
        formState: {isDirty, isValid},
        handleSubmit,
        getValues,
        reset,
        trigger,
        watch
    } = methods;

    const workDays = watch('workDays');
    const isNotEmpty = !!workDays?.find(item => !isEmptyDuration(item.workTime));

    const {fields} = useFieldArray({
        control,
        name: 'workDays'
    });

    // save Hours
    const {mutate, isLoading: isSubmittingHours} = useMutation(
        (payload: Partial<IWorkWeek>) => timesheetsService.submitHours(data.id || 0, payload),
        {
            onSuccess: async () => {
                showToastSuccess('Timesheets has been saved successfully!');
                onRefetch();
            },
            onError(error: AxiosError) {
                showToastError(error);
            }
        }
    );

    // submit this week for Client approving
    const {mutate: mutateWeek, isLoading: isSubmittingWeek} = useMutation(
        timesheetsService.submitWeek,
        {
            onSuccess: async () => {
                showToastSuccess('Timesheets has been submitted successfully!');
                onRefetch();
            },
            onError(error: AxiosError) {
                showToastError(error);
            }
        }
    );

    const isSubmitting = isSubmittingHours || isSubmittingWeek;

    const onSubmitHandler: SubmitHandler<WeekInput> = () => {
        const {id, workDays, workDescription} = getValues();
        const payload = {
            id,
            workDays,
            workDescription
        };

        mutate(payload);
    };

    const handleConfirm = useCallback((reason: boolean) => {
        if (reason) {
            mutateWeek(data.id || 0);
        }
        setOpen(false);
    }, [data.id, mutateWeek]);

    const handleSubmitClick = () => {
        if (isValid) {
            setOpen(true);
        } else {
            trigger();
        }

        handleSubmit(onSubmitHandler)();
    };

    const renderReason = useMemo(() => {
        const {length, [length - 1]: last} = data.rejectionReasons as string[];

        // 2023-03-29T08:39:00Z: because i ...
        // substring without date
        return last?.substring(22) || '';
    }, [data]);

    useEffect(() => {
        reset(data);
    }, [data, reset]);

    useEffect(() => {
        if (!isAutoFocus || !elementRef.current) return;

        setCollapsed(false);

        setTimeout(() => {
            elementRef.current?.scrollIntoView
                ? elementRef.current.scrollIntoView({behavior: 'smooth'})
                : elementRef.current?.focus();
        }, 1000);
    }, [isAutoFocus]);

    return (
        <CollapsibleBox
            titleContent={
                <TimesheetHeading
                    actions={
                        <ContinueButton
                            disabled={data.status === EWorkWeekStatus.REJECTED || isDirty || !isNotEmpty || isImpersonal} // don't allow re-submit the same
                            size="small"
                            sx={{
                                width: '100% !important',
                                'div': {
                                    justifyContent: 'center'
                                },
                                [theme.breakpoints.up('md')]: {
                                    width: 'auto !important'
                                }
                            }}
                            variant="contained"
                            onClick={handleSubmitClick}
                        >
                            {data.status === EWorkWeekStatus.DRAFT ? 'Submit' : 'Re-submit'}
                        </ContinueButton>
                    }
                    endDate={formatDate(data.toDate)}
                    hoursSum={formatHoursFromDuration(data.workTime)}
                    isCollapsed={collapsed}
                    startDate={formatDate(data.fromDate)}
                    status={data.status as EWorkWeekStatus}
                />
            }
            onCollapseClick={() => setCollapsed(!collapsed)}
        >

            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <Box sx={{mt: '8px'}}>
                        {
                            data.status === EWorkWeekStatus.REJECTED && renderReason && (
                                <>
                                    <Box
                                        sx={{
                                            mb: '8px',
                                            display: 'flex',
                                            gap: '8px',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <ExclamationMarkIcon/>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '13px',
                                                lineHeight: 1.5,
                                                color: theme.palette.error.main
                                            }}
                                        >
                                            The client rejected some of the dates you submitted. Please re-log this time
                                            and
                                            re-submit for another review.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: '16px',
                                            mb: '8px',
                                            borderRadius: '8px',
                                            backgroundColor: theme.palette.lightGray.light
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                mb: '4px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                lineHeight: 1.5,
                                                color: theme.palette.gray.main
                                            }}
                                        >
                                            <ChatIcon/>
                                            Comment from the client:
                                        </Typography>
                                        <Typography
                                            component="q"
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '13px',
                                                lineHeight: 1.5
                                            }}
                                        >
                                            {renderReason}
                                        </Typography>
                                    </Box>
                                </>
                            )
                        }
                        <Input
                            label={<Typography
                                sx={{
                                    mb: '4px',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    lineHeight: 1.5,
                                    color: theme.palette.black.main,
                                    'span': {
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        color: theme.palette.gray.main
                                    }
                                }}
                            >
                                Describe what has been done this week <span>(optional)</span>
                            </Typography>}
                            name="workDescription"
                            placeholder="e.g. Identified new target audience"
                            variant="outlined"
                        />
                    </Box>
                    <Stack
                        direction="column"
                        sx={{
                            backgroundColor: theme.palette.blue.light,
                            p: '24px',
                            borderRadius: '24px'
                        }}
                    >
                        {fields.map((item, index) => (
                            <Day key={item.id} name={`workDays.${index}`} disabled={isSubmitting}/>
                        ))}

                        <Box
                            ref={isAutoFocus ? elementRef : null}
                            sx={{
                                mt: '24px'
                            }}
                        >
                            <ContinueButton
                                disabled={isSubmitting || !isDirty || !isNotEmpty || isImpersonal}
                                sx={{width: 'auto !important'}}
                                size="small"
                                type="submit"
                                variant="contained"
                            >
                                Save changes
                            </ContinueButton>
                        </Box>
                    </Stack>
                </form>
            </FormProvider>

            {/* confirm submitting a hours to the Client */}
            <DialogConfirm
                iconUrl="/assets/images/clock-red-icon.png"
                labelBtn="Yes, submit"
                open={open}
                subtitle={(
                    <>
                        You are about to submit the timesheet for the following billing period:<br/>
                        {formatDate(data.fromDate)} – {formatDate(data.toDate)}.
                        The client has up to 2 business days to review it before it gets automatically approved.
                    </>
                )}
                title="Are you sure you want to submit this timesheet?"
                onClose={handleConfirm}
            />
        </CollapsibleBox>
    );
};

export default React.memo(WeekForm);
