import React from 'react';

import {Box, styled, Typography} from '@mui/material';

import theme from '../../../../theme';
import {CardSubTitle, CardTitle} from '../../../Card/Card';


const ChatEmpty = styled('div')`
  margin: auto;
  padding: 12px;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
`;
const ChatEmptyPlaceholder = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit'
            }}
        >
            <Typography
                sx={{
                    p: '24px 12px',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: 1.5,
                    [theme.breakpoints.up('md')]: {
                        p: '40px 24px',
                        fontSize: '24px',
                        lineHeight: 1
                    }
                }}
            >
                Inbox
            </Typography>
            <ChatEmpty>
                <div>
                    <img
                        src="/assets/images/chat-bubble.png"
                        width="64"
                        height="64"
                        alt="chat bubble icon"
                    />
                </div>
                <CardTitle
                    sx={{
                        mb: '2px',
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    You have no active chats yet
                </CardTitle>
                <CardSubTitle>
                    Messages will appear here once you start interacting with freelancers
                </CardSubTitle>
            </ChatEmpty>
        </Box>
    );
};

export default React.memo(ChatEmptyPlaceholder);
