import React from 'react';
import {useParams} from 'react-router';

import {styled, Typography} from '@mui/material';
import {Message} from '@twilio/conversations';

import {PhoneIcon, VideoCallIcon} from '../../../../../assets/icons';
import {useAuth, useConversation} from '../../../../../hooks';
import {EParticipantType, ICallMessageAttributes} from '../../../../../models';
import theme from '../../../../../theme';
import {formatTime} from '../../../../../utils';

import AvatarListItem from './AvatarListItem';
import {MessageRowWrapper} from './MessageRow';

const CallMsg = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  grid-area: message;

  svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;

  }
`;

interface CallRowProps {
    data: Message;
}

const CallRow: React.FC<CallRowProps> = ({data}) => {
    const {conversationId} = useParams();
    const {meta} = useConversation(conversationId || '');
    const {isClient, isFreelancer} = useAuth();

    const {author, duration, isAudioOnly, isInitiator} = data.attributes as ICallMessageAttributes;

    // Author of the massage couldn't be an Initiator
    // Author is who leave a call first
    // But initiator is who create a room and waiting for participant

    // {
    //     author: "CLIENT"
    //     duration: "3 sec"
    //     isAudioOnly: false
    //     isInitiator: false
    // }

    let isAuthor;

    if (isInitiator) {
        isAuthor = (isClient && author === EParticipantType.CLIENT) || (isFreelancer && author === EParticipantType.FREELANCER);
    } else {
        isAuthor = (isClient && author !== EParticipantType.CLIENT) || (isFreelancer && author !== EParticipantType.FREELANCER);
    }

    const avatar = isAuthor ? meta?.localParticipant?.avatar : meta?.remoteParticipant?.avatar;
    const name = isAuthor ? (meta?.localParticipant?.name || '') : (meta?.remoteParticipant?.name || '');

    return (
        <>
            {/* Call Message */}
            <MessageRowWrapper>
                <AvatarListItem avatar={avatar || ''} isAuthor={isAuthor} name={name}/>
                <CallMsg>
                    {isAudioOnly ? <PhoneIcon/> : <VideoCallIcon/>}

                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '21px',
                            color: theme.palette.black.main,
                            'span': {
                                display: 'block',
                                font: 'inherit',
                                lineHeight: 'inherit',
                                color: theme.palette.gray.main
                            }
                        }}
                    >
                        {isAuthor ? 'Outgoing Call' : 'Incoming Call'}

                        <span>
                            {duration}
                        </span>
                    </Typography>
                </CallMsg>
                {data.dateUpdated && (
                    <span className="time">{formatTime(data.dateUpdated)}</span>
                )}
            </MessageRowWrapper>
        </>
    );
};

export default React.memo(CallRow);
