import React from 'react';
import type {RouteObject} from 'react-router-dom';

import {Page404, Page500} from '../components';


export const errorsRoutes: RouteObject[] = [
    {
        path: 'failed',
        element: <Page500/>,
    },
    {
        path: '*',
        element: <Page404/>,
    }
];
