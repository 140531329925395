import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import {styled} from '@mui/material';

import {COMPLETED, LG, MD, SM, XL} from '../../constants';
import {GRADIENT, WAVY_BG_GRAY, WAVY_BG_GREEN, WAVY_BG_PURPLE} from '../../constants/BackgroundImages';
import {useStateContext} from '../../contexts';
import {useAuth} from '../../hooks';
import {stepsOrderClient} from '../../routes/routes.utils';
import theme from '../../theme';
import {utm} from '../../utils';

import {applicantStepsList, clientStepsList} from './nav.model';

interface IProgressBarProps {
    displaySmUp?: string;
    height?: number;
    isLight?: boolean;
    progress: number;
}

export const ProgressBar = styled('div')<IProgressBarProps>`
  width: 100%;
  height: ${props => props.height ? props.height + 'px' : '6px'};
  margin-bottom: 8px;
  border-radius: 10px;
  background-color: ${props => props.isLight ? theme.palette.lightGray.main : theme.palette.progressBarBlack.main};
  position: relative;
  overflow: hidden;
  will-change: contents;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${props => props.progress}%;
    border-radius: 10px;
    background-image: ${GRADIENT};
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    transition: width 1s linear;
  }

  @media (min-width: ${SM}) {
    display: ${props => props.displaySmUp ? props.displaySmUp : 'none'};
  }
`;

const StepList = styled('ol')`
  list-style: none;
  counter-reset: step-counter;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;

  @media (min-width: ${SM}) {
    counter-reset: step-counter;
  }
`;

const Step = styled('li')`
  counter-increment: step-counter;

  @media (min-width: ${SM}) {
    padding: 9px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  @media (min-width: ${LG}) {
    padding: 12px 0;
  }

  a {
    display: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #A6A6A6;

    @media (min-width: ${SM}) {
      display: flex;
      align-items: center;
      gap: 20px;
      font-size: 14px;
      line-height: 130%;
    }

    @media (min-width: ${XL}) {
      gap: 24px;
    }

    &::before {
      @media (min-width: ${SM}) {
        content: '0' counter(step-counter) ' ';
        flex-shrink: 0;
        padding: 16px;
        width: 50px;
        height: 50px;
        background-image: ${WAVY_BG_GRAY};
        background-position: center;
        background-repeat: no-repeat;
        background-size: 88%;
        font-size: 14px;
        text-align: center;
      }

      @media (min-width: ${XL}) {
        width: 64px;
        height: 64px;
        padding: 22px;
      }
    }

    &::after {
      @media (min-width: ${MD}) {
        content: '';
        margin-left: auto;
        margin-right: -15px;
        flex-shrink: 0;
        width: 90px;
        height: 5px;
        border-radius: 5px;
        background-image: linear-gradient(to right, transparent, #7E5FFF);
        background-position: 0 center;
        background-size: 0;
        background-repeat: no-repeat;
        opacity: 0;
        transition: opacity .3s, background-size .7s;
      }

      @media (min-width: ${LG}) {
        width: 115px;
      }

      @media (min-width: ${XL}) {
        margin-right: 37px;
      }
    }
  }

  & {
    a.active {
      display: block;
      color: ${theme.palette.white.main};

      @media (min-width: ${SM}) {
        display: flex;
        font-weight: 500;
        font-size: 16px;
      }

      &::before {
        @media (min-width: ${SM}) {
          background-image: ${WAVY_BG_PURPLE};
        }
      }

      &::after {
        @media (min-width: ${MD}) {
          opacity: 1;
          background-size: 100%;
        }
      }
    }

    &.completed a {
      color: ${theme.palette.white.main};

      @media (min-width: ${SM}) {
        font-weight: 500;
        font-size: 16px;
      }

      &::before {
        @media (min-width: ${SM}) {
          content: '';
          background-image: ${WAVY_BG_GREEN};
          background-size: 100%;
        }
      }
    }
  }

  .step-counter {
    @media (min-width: ${SM}) {
      display: none;
    }
  }
`;

const CreateAccountSteps = (): JSX.Element => {
    const {state: {application, clientRole}} = useStateContext();
    const {isApplicant, isClient, isClientUnverified} = useAuth();
    const [currentStep, setCurrentStep] = useState(1);
    const [stepPercent, setStepPercent] = useState(0);
    const location = useLocation();

    const stepsList = useMemo(() => {
        if (isApplicant) return applicantStepsList;
        if (isClient || isClientUnverified) return clientStepsList; // at the last step ClientUnverified -> Client

        return null;
    }, [isApplicant, isClient, isClientUnverified]);

    const checkCurrentLocation = useCallback(() => {
        if (!stepsList) return;

        let currentIndex = 0;

        stepsList.forEach((step, i) => {
            if (location.pathname.startsWith(step.link)) {
                currentIndex = i + 1;
            }
        });

        // if all steps filled and now user should confirm code
        // lets mark all menu items as completed
        if (currentIndex) {
            setCurrentStep(currentIndex);
        } else {
            setCurrentStep(stepsList.length + 1);
        }
    }, [location.pathname, stepsList]);

    const getPercentForStep = (currentStepNumber: number, stepsLength: number) => {
        setCurrentStep(currentStepNumber);
        setStepPercent((100 / stepsLength) * currentStepNumber);
    };

    const handleClick = (nextStep: number) => (event: SyntheticEvent) => {
        let enabledStep: number = 0;

        if (isApplicant && application) {
            enabledStep = application.step;
        }

        // disable Sidebar menu at the ThankYou page
        if (isClientUnverified && clientRole && clientRole.view !== COMPLETED) {
            enabledStep = stepsOrderClient.indexOf(clientRole?.view || '') + 2;
        }

        if (nextStep > enabledStep) {
            event.preventDefault();

            return;
        }
    };

    useEffect(() => {
        getPercentForStep(currentStep, stepsList?.length || 0);
        checkCurrentLocation();
    }, [currentStep, stepsList?.length, checkCurrentLocation]);

    return (
        <>
            <ProgressBar progress={stepPercent}/>
            <StepList>
                {
                    stepsList?.map((step, i) => (
                        <Step
                            key={step.title}
                            className={currentStep > i + 1 ? 'completed' : ''}
                        >
                            <NavLink
                                to={utm.patchUri(step.link)}
                                onClick={handleClick(i + 1)}
                            >
                                <span className="step-counter">
                                    {i + 1}/{stepsList.length}:&nbsp;
                                </span>

                                {step.title}
                            </NavLink>
                        </Step>)
                    )
                }
            </StepList>
        </>
    );
};

export default CreateAccountSteps;
