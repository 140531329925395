import React, {FC, ReactNode, useCallback, useRef} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Autocomplete, Box, FormHelperText, TextField} from '@mui/material';

// import {useLocations} from '../../hooks';
import {ISelectOption} from '../../../models';
import theme from '../../../theme';
import {TIMEZONES_OPTIONS} from '../../../utils/timezones';
import {AnimateHeight} from '../..';

interface IInputTimezoneProps {
    disabled?: boolean;
    helperText?: string;
    label?: ReactNode;
    name: string;
}

const InputTimezone: FC<IInputTimezoneProps> = ({disabled, helperText, label, name, ...props}): JSX.Element => {
    const {
        control,
        formState: {errors},
        trigger
    } = useFormContext();

    const selectedRef = useRef<HTMLDivElement | null>();

    const {field: {value, onChange}} = useController({control, name});

    const handleChange = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: ISelectOption | null) => {
        onChange(newValue);
        trigger(name);
    }, [name, onChange, trigger]);

    const isSelected = useCallback((option: string) => value ? option === value?.value : false, [value]);

    const localTime = (timeZone: string) => {
        try {
            const arr = new Date().toLocaleTimeString('en-GB', {timeZone}).split(':');

            return `${arr[0]}:${arr[1]}`;
        } catch (e) {
            return '';
        }
    };

    const handleOpen = useCallback(() => {
        setTimeout(() => {
            selectedRef.current?.scrollIntoView();
        }, 100);
    }, []);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref, ...field}}) => (
                <>
                    <Autocomplete
                        {...props}
                        {...field}
                        disabled={disabled}
                        id={name + '-id'}
                        filterSelectedOptions
                        fullWidth
                        getOptionLabel={option => option.text || ''}
                        noOptionsText=""
                        options={TIMEZONES_OPTIONS}
                        isOptionEqualToValue={(option, value) => option.value == value?.value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={ref}
                                fullWidth
                                placeholder="Select time zone"
                                variant="standard"
                                value={value || ''}
                            />
                        )}
                        renderOption={(props, option) => (
                            <Box
                                {...props}
                                component="li"
                                sx={{
                                    '& > span': {flexGrow: 1},
                                    color: isSelected(option.value) ? 'primary.main' : 'default',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                                ref={isSelected(option.value) ? selectedRef : null}
                            >
                                <span>{option.text}</span> {localTime(option.value)}
                            </Box>
                        )}
                        onChange={handleChange}
                        onOpen={handleOpen}
                    />
                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </>
            )}
        />
    );
};

export default React.memo(InputTimezone);
