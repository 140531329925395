import React from 'react';

import PageTopTitle from '../PageTopTitle';

interface ClientTopTitleProps {
    desktopFontSize?: number;
    isSubChannelsView: boolean;
    subChannelsName?: string;
}

export const ClientTopTitle: React.FC<ClientTopTitleProps> = React.memo(({
    desktopFontSize,
    isSubChannelsView,
    subChannelsName
}) => {
    return (
        <PageTopTitle
            desktopFontSize={desktopFontSize}
            title={
                isSubChannelsView
                    ? <>Please specify sub-channels for <span>{subChannelsName}</span> your expert should be
                        good at</>
                    : <>What <span>marketing channel</span> does your expert need to specialize in?</>
            }
            subtitle={
                isSubChannelsView
                    ? 'Select as many sub-channels as you want'
                    : 'Select one channel. If you need help in multiple channels, we recommend creating an additional role.'
            }
            subtitleMaxWidth={{md: 545, lg: 545}}
        />
    );
});
