import React from 'react';

import {Avatar} from '@mui/material';

import {getNameLetters, stringToColor} from '../../../../../utils';

const avatarStyles = {
    width: '32px',
    height: '32px',
    backgroundColor: stringToColor('You'),
    fontSize: '12px',
    lineHeight: 1.2,
    fontWeight: 500,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    }
};

interface AvatarListItemProps {
    avatar: string;
    isAuthor: boolean;
    name: string;
}

const AvatarListItem: React.FC<AvatarListItemProps> = ({avatar, isAuthor, name}) => {
    return (
        <>
            <Avatar
                alt={name}
                className="avatar"
                src={avatar}
                sx={{...avatarStyles, backgroundColor: stringToColor(name)}}
            >
                {getNameLetters(name)}
            </Avatar>
            <p className="author">{isAuthor ? 'You' : name}</p>
        </>
    );
};

export default AvatarListItem;
