// Single select for Client flow

import React from 'react';

import {Autocomplete, Box, FilterOptionsState, TextField, useTheme} from '@mui/material';

import {CheckboxIcon} from '../../assets/icons';
import {OptionType} from '../../models';
import {InputCaption} from '..';

interface IProps {
    options: OptionType[];
    placeholder: string;
    value: OptionType;
    onChange: (newValue: OptionType | null) => void;
    onDelete: (item: OptionType) => void;
}

const Verticals: React.FC<IProps> = ({options, placeholder, value, onChange, onDelete}) => {
    const theme = useTheme();

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptionType | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onDelete(value);
        }
    };

    const handleFilter = (options: OptionType[], params: FilterOptionsState<OptionType>) => {
        return options.filter((opt) => opt.name.toLowerCase().includes(params.inputValue.toLowerCase()));
    };

    const handleOptionLabel = (option: OptionType) => {
        return option.name;
    };

    const isSelected = (id?: number) => value?.id === id;

    return (
        <>
            <InputCaption>
                What <span>vertical</span> is your industry in?
            </InputCaption>

            <Autocomplete
                clearOnBlur
                blurOnSelect
                handleHomeEndKeys
                id="verticals"
                filterOptions={handleFilter}
                fullWidth
                getOptionLabel={handleOptionLabel}
                options={options || []}
                noOptionsText=""
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        placeholder={placeholder}
                        variant="standard"
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} style={{display: 'block'}}>
                        {
                            isSelected(option?.id) ? (
                                <Box
                                    color="primary.main"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <span>{option.name}</span> <CheckboxIcon fill={theme.palette.primary.main}
                                        height={22} width={22}/>
                                </Box>
                            ) : (
                                <span>{option.name}</span>
                            )
                        }
                    </li>
                )}
                selectOnFocus
                sx={{mb: 3}}
                value={value}
                onChange={handleChange}
            />

        </>
    );
};

export default React.memo(Verticals);
