import React from 'react';

const LinkCertificateIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3987_6118)">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M21.3022 2.69809C20.2041 1.60001 18.4238 1.60001 17.3257 2.69809L12.6396 7.3842C12.2736 7.75022 11.6801 7.75022 11.3141 7.3842C10.9481 7.01817 10.9481 6.42473 11.3141 6.0587L16.0002 1.3726C17.8303 -0.457533 20.7976 -0.457533 22.6277 1.3726C24.4578 3.20273 24.4578 6.16995 22.6277 8.00008L16.6161 14.0117L15.9534 13.349L16.6161 14.0117C14.786 15.8418 11.8188 15.8418 9.98863 14.0117C9.6226 13.6457 9.6226 13.0522 9.98863 12.6862C10.3547 12.3202 10.9481 12.3202 11.3141 12.6862C12.4122 13.7843 14.1925 13.7843 15.2906 12.6862L21.3022 6.67458L21.9649 7.33733L21.3022 6.67458C22.4003 5.5765 22.4003 3.79617 21.3022 2.69809Z"
                    fill="#7E5FFF"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M12.6393 11.3612C11.5412 10.2631 9.7609 10.2631 8.66282 11.3612L2.69809 17.3259C1.60001 18.424 1.60001 20.2043 2.69809 21.3024C3.79617 22.4005 5.5765 22.4005 6.67458 21.3024L11.3138 16.6631C11.6798 16.2971 12.2733 16.2971 12.6393 16.6631C13.0053 17.0292 13.0053 17.6226 12.6393 17.9886L8.00008 22.6279C6.16995 24.458 3.20273 24.458 1.3726 22.6279C-0.457533 20.7978 -0.457533 17.8305 1.3726 16.0004L7.33732 10.0357C9.16745 8.20555 12.1347 8.20555 13.9648 10.0357C14.3308 10.4017 14.3308 10.9952 13.9648 11.3612C13.5988 11.7272 13.0053 11.7272 12.6393 11.3612Z"
                    fill="#7E5FFF"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M2.6326 7.66638C2.85137 7.19724 3.40902 6.99427 3.87816 7.21304L5.57705 8.00527C6.04619 8.22404 6.24915 8.78169 6.03039 9.25083C5.81162 9.71997 5.25396 9.92293 4.78482 9.70417L3.08593 8.91194C2.61679 8.69317 2.41383 8.13551 2.6326 7.66638Z"
                    fill="#7E5FFF"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M17.9225 14.7972C18.1413 14.3281 18.6989 14.1251 19.1681 14.3439L20.867 15.1361C21.3361 15.3549 21.5391 15.9126 21.3203 16.3817C21.1015 16.8508 20.5439 17.0538 20.0747 16.835L18.3758 16.0428C17.9067 15.824 17.7037 15.2664 17.9225 14.7972Z"
                    fill="#7E5FFF"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M7.61938 2.67984C8.08852 2.46107 8.64617 2.66404 8.86494 3.13317L9.65717 4.83206C9.87594 5.3012 9.67297 5.85886 9.20383 6.07763C8.7347 6.2964 8.17704 6.09343 7.95827 5.62429L7.16605 3.9254C6.94728 3.45626 7.15024 2.89861 7.61938 2.67984Z"
                    fill="#7E5FFF"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M14.7489 17.9699C15.218 17.7511 15.7757 17.9541 15.9945 18.4232L16.7867 20.1221C17.0055 20.5912 16.8025 21.1489 16.3334 21.3677C15.8642 21.5864 15.3066 21.3835 15.0878 20.9143L14.2956 19.2154C14.0768 18.7463 14.2798 18.1886 14.7489 17.9699Z"
                    fill="#7E5FFF"/>
            </g>
            <defs>
                <clipPath id="clip0_3987_6118">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default LinkCertificateIcon;
