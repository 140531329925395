import React, {useCallback, useEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm, useWatch} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Theme, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {useAuth} from '../../../../hooks';
import {ECaseStudySteps, ICaseStudy, ICoverImage} from '../../../../models';
import theme from '../../../../theme';
import {descriptionSchema, getTextLength} from '../../../../utils';
import {
    AnimatePageWrp,
    ContinueButton,
    GrayButton,
    Input,
    Portal,
    showToastError,
    showToastSuccess,
    useCaseStudy,
} from '../../..';

import CoverImagePicker from './components/CoverImagePicker';
import {InputCaption, InputSubCaption} from './styles';

const defaultCoverImage = JSON.stringify({name: 'black', variant: 1} as ICoverImage);

type DescriptionInput = TypeOf<typeof descriptionSchema>;

export const Step1Description = () => {
    const {isImpersonal} = useAuth();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const {state: {cancelUrl, data, isCanBePublished}, onRefetch, publish, updateCaseStudy} = useCaseStudy();

    const location = useLocation();
    const navigate = useNavigate();

    let nextStep = location.pathname.replace(ECaseStudySteps.description, ECaseStudySteps.company);

    const methods = useForm<DescriptionInput>({
        resolver: zodResolver(descriptionSchema),
        defaultValues: {
            coverImage: defaultCoverImage
        }
    });

    const {
        control,
        formState: {isDirty, isValid, errors},
        handleSubmit,
        getValues,
        reset,
        setError,
        setValue,
        watch
    } = methods;

    const {brief, name} = useWatch({control});
    const coverImage = watch('coverImage', '') || defaultCoverImage;
    const coverImageData = useMemo(() => JSON.parse(coverImage), [coverImage]);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (values: Partial<ICaseStudy>) => updateCaseStudy({...data?.draftVersion, ...values, id: data?.id}),
        {
            onSuccess(response: any) {
                showToastSuccess('Title & Description were saved successfully!');

                if (nextStep === 'publish') {
                    publish(response.data.id);
                } else {
                    navigate(nextStep.replace('/new/', `/${response.data.id}/`), {replace: true});
                    onRefetch();
                }
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleCoverImageChange = useCallback((value: ICoverImage) => {
        setValue('coverImage', JSON.stringify(value), {shouldDirty: true, shouldTouch: true, shouldValidate: true});
        setError('coverImageVariant' as any, {type: 'custom', message: ''});
    }, [setError, setValue]);

    const handlePublishClick = () => {
        const values = getValues();

        nextStep = 'publish';
        mutate(values);
    };

    const handleSaveAndExit = () => {
        const values = getValues();

        nextStep = cancelUrl;
        mutate(values);
    };

    const onSubmitHandler: SubmitHandler<DescriptionInput> = (values: DescriptionInput) => {
        if (isDirty && !isImpersonal) {
            mutate(values);
        } else {
            navigate(nextStep);
        }
    };

    useEffect(() => {
        if (!data) return;

        const {brief, coverImage, name} = data.draftVersion;

        reset({
            brief,
            coverImage: coverImage || defaultCoverImage,
            name
        });
    }, [data, reset]);

    return (
        <AnimatePageWrp>

            <FormProvider {...methods}>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <Box
                        sx={{
                            pb: '100px',
                            '@media (min-height: 800px)': {
                                pb: 0
                            }
                        }}
                    >
                        <Box sx={{mb: '30px'}}>
                            <InputCaption sx={{mb: '24px'}}>Case study name</InputCaption>
                            <Input
                                autoFocus={!name}
                                counter={120 - (name?.length || 0)}
                                disabled={isSubmitting}
                                inputProps={{maxLength: 120}}
                                name="name"
                                placeholder="Your case study title goes here. Be creative and bold!"
                            />
                        </Box>
                        <Box sx={{mb: '30px'}}>
                            <InputCaption sx={{mb: '4px'}}>Briefly describe your case study</InputCaption>
                            <InputSubCaption sx={{mb: '8px'}}>
                                Briefly introduce the subject of the case study and provide context for the problem you
                                were
                                facing
                            </InputSubCaption>
                            <Input
                                counter={100 - (getTextLength(brief || ''))}
                                disabled={isSubmitting}
                                inputProps={{maxLength: 100}}
                                minRows={3}
                                multiline
                                name="brief"
                                placeholder="Add some description to give us more details of what exactly has been done"
                                variant="outlined"
                            />
                        </Box>

                        <InputCaption sx={{mb: '24px !important'}}>Add a cover image</InputCaption>
                        <CoverImagePicker
                            error={(errors as any).coverImageVariant?.message || ''}
                            name={coverImageData.name}
                            variant={coverImageData.variant}
                            onChange={handleCoverImageChange}
                        />

                    </Box>
                </form>
            </FormProvider>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        mt: '40px',
                        // mb: '40px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '16px',
                        flexWrap: 'wrap'
                    },
                    '@media(max-height: 900px)': {
                        pb: '60px'
                    },
                    'button': {
                        width: 'auto'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isSubmitting}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
                <GrayButton
                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>
                {isCanBePublished && (
                    <GrayButton
                        disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                display: 'none'
                            }
                        }}
                        variant="contained"
                        onClick={handlePublishClick}
                    >
                        Publish
                    </GrayButton>
                )}

            </Box>
            {
                isSmallScreen && (
                    <>
                        <Portal
                            order={1}
                            innerPages
                        >
                            <ContinueButton
                                disabled={isSubmitting}
                                hasArrow
                                variant="contained"
                                sx={{
                                    'div': {
                                        justifyContent: 'center !important'
                                    }
                                }}
                                onClick={handleSubmit(onSubmitHandler)}
                            >
                                Continue
                            </ContinueButton>
                        </Portal>
                        <Portal order={2} innerPages>
                            <GrayButton
                                disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                fullWidth
                                variant="contained"
                                onClick={handleSaveAndExit}
                            >
                                Save & exit
                            </GrayButton>
                        </Portal>

                        {isCanBePublished && (
                            <Portal order={3} innerPages>
                                <GrayButton
                                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                    variant="contained"
                                    onClick={handlePublishClick}
                                >
                                    Publish
                                </GrayButton>
                            </Portal>
                        )}

                    </>
                )
            }
        </AnimatePageWrp>
    );
};
