import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';

import {Box} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    BudgetFixedRate,
    BudgetRateRange,
    BudgetTopTitle,
    BudgetType,
    BudgetWeeklyHours,
    ContinueButton,
    LoadingMask,
    Portal,
    showToastError,
    showToastSuccess
} from '../../../components';
import {MAX_SAFE_INTEGER, RouteCreateClientAccount} from '../../../constants';
import {useClientRole, useNavigateUTM} from '../../../hooks';
import {EWorkType, IBudgetRequest} from '../../../models';
import theme from '../../../theme';

export const Budget = () => {
    const {clientRole, isClientLoading, refetchClient} = useClientRole();
    const [workType, setWorkType] = useState<EWorkType | null>(null);
    const [expectedEngagementHours, setExpectedEngagementHours] = useState<number[] | null>(null);
    const [rateRange, setRateRange] = useState<number[] | null>(null);

    const fixedHoursRef = useRef<HTMLInputElement>();

    const navigate = useNavigateUTM();

    const {mutate, isLoading} = useMutation(
        (payload: IBudgetRequest) => clientService.budgetSubmit(clientRole?.id || 0, payload),
        {
            onSuccess() {
                showToastSuccess('Budget was saved successfully!');
                navigate(RouteCreateClientAccount.project);
                refetchClient();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleChangeEngagementHours = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);

        if (typeof value === 'number') {
            if (value > MAX_SAFE_INTEGER / 100) {
                const max = parseInt((MAX_SAFE_INTEGER / 100).toString(), 10);

                setExpectedEngagementHours([max]);
            } else {
                setExpectedEngagementHours([value]);
            }
        }
    }, []);

    const handleClickType = useCallback((type: EWorkType) => {
        // clear by second click
        if (type === workType) {
            setWorkType(null);
            setRateRange(null);
        } else {
            setWorkType(type);
        }

        if (type === EWorkType.fixed) {
            fixedHoursRef.current?.focus();
        }

        // clean related fields
        if (type === EWorkType.fixed) {
            setExpectedEngagementHours(null);
        }
    }, [workType]);

    const handleChangeRateRange = useCallback((range: number[]) => {
        if (range[0] === rateRange?.[0]) {
            setRateRange(null);
        } else {
            setRateRange(range);
        }
    }, [rateRange]);

    const handleSubmit = () => {
        const isDirty = workType !== clientRole?.workType
            || !isEqual(expectedEngagementHours, clientRole?.expectedEngagementHours)
            || !isEqual(rateRange, clientRole?.rateRange);


        if (!workType) {
            showToastError('Please add a work type.');
        } else if (!expectedEngagementHours?.length) {
            showToastError('Please select a number of hours weekly');
        } else if (!rateRange?.length) {
            showToastError('Please select your hourly rate');
            // check is dirty or not
        } else if (workType === EWorkType.fixed && expectedEngagementHours[0] > MAX_SAFE_INTEGER / 100) {  // // convert MAX_SAFE_INTEGER: 2147483647 without cents
            showToastError('Please enter a valid hours value.');
            // check is dirty or not
        } else if (isDirty) {
            mutate({
                workType,
                expectedEngagementHours,
                rateRange: rateRange.map(it => it * 100) || []  // convert to cents
            });
        } else {
            navigate(RouteCreateClientAccount.project);
        }
    };

    useEffect(() => {
        if (!clientRole) {
            return;
        }
        setRateRange(clientRole.rateRange.map(it => it / 100)); // convert without cents
        setExpectedEngagementHours(clientRole.expectedEngagementHours);
        setWorkType(clientRole.workType);
    }, [clientRole]);

    const selectedMinWeeklyHours = useMemo(() => expectedEngagementHours?.[0], [expectedEngagementHours]);

    return (
        <AnimatePageWrp maxWidth={{md: 600, lg: 871}}>
            <BudgetTopTitle/>

            {
                isClientLoading
                    ? (
                        <LoadingMask/>
                    ) : (
                        <Box sx={{
                            margin: '0 auto',
                            maxWidth: '602px'
                        }}>

                            <BudgetType value={workType} onType={handleClickType}/>

                            <AnimateHeight isOverflowHidden={false}
                                isVisible={workType === EWorkType.hourly || workType === EWorkType.retainer}>

                                <BudgetWeeklyHours value={selectedMinWeeklyHours} onSelect={setExpectedEngagementHours}/>

                            </AnimateHeight>

                            <AnimateHeight isOverflowHidden={false} isVisible={workType === EWorkType.fixed}>
                                <BudgetFixedRate
                                    ref={fixedHoursRef}
                                    value={expectedEngagementHours?.length ? expectedEngagementHours[0] : null}
                                    onChange={handleChangeEngagementHours}
                                />
                            </AnimateHeight>
                            <AnimateHeight isOverflowHidden={false} isVisible={!!workType}>

                                <BudgetRateRange value={rateRange?.[0]} onChange={handleChangeRateRange}/>

                            </AnimateHeight>
                        </Box>
                    )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '72px'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '63px'
                    },
                    '@media(max-height: 900px)': {
                        pb: '60px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isClientLoading || isLoading || !workType}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isClientLoading || isLoading || !workType}
                    hasArrow
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};
