import React, {FC} from 'react';
import capitalize from 'lodash/capitalize';

import {Box, Stack, styled} from '@mui/material';

import {LocationIcon} from '../../../../../assets/icons';
import {SeniorityLevelRange} from '../../../../../components';
import {MD} from '../../../../../constants';
import {IMatchMetaStrings, IMatchWorkType} from '../../../../../models';
import theme from '../../../../../theme';

const Wrp = styled('div')`
  @media (min-width: ${MD}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
`;

const BoldText = styled('p')<{ color?: string }>`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
  color: ${props => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${MD}) {
    margin-bottom: 0;
  }
`;

const Caption = styled('span')<{ bolder?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-weight: ${props => props.bolder ? '600' : '400'};
  font-size: 13px;
  line-height: 20px;
  color: ${props => props.bolder ? theme.palette.black.main : theme.palette.gray.main};
  position: relative;

  &::before {
    content: '';
    display: ${props => props.bolder ? 'none' : 'block'};
    width: 4px;
    height: 4px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: currentColor;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  @media (min-width: ${MD}) {
    display: inline-flex;
    font-size: 13px;
  }
`;

interface IProps {
    children?: React.ReactNode;
    companyLogo?: string;
    companyName: string;
    countries: IMatchMetaStrings;
    languages: IMatchMetaStrings;
    projectName: string;
    rateRange?: number[];
    workTypes: IMatchWorkType;
}

const MyMatchesTopInfo: FC<IProps> = ({
    children,
    companyLogo,
    companyName,
    countries,
    languages,
    projectName,
    rateRange,
    workTypes
}) => {
    return (
        <Wrp>
            <Stack direction="row" spacing={3} sx={{overflow: 'hidden'}}>


                {
                    companyLogo && (
                        <Box
                            sx={{
                                width: '64px',
                                height: '64px',
                                borderRadius: '50%',
                                border: '1px solid',
                                borderColor: theme.palette.lightGray.main,
                                overflow: 'hidden',
                                mb: '12px',
                                flexShrink: 0,
                                [theme.breakpoints.up('md')]: {
                                    mb: 0
                                }
                            }}
                        >
                            <img src={companyLogo} width="64" height="64" alt={companyName}/>
                        </Box>
                    )
                }
                <Box sx={{minWidth: 0}}>
                    <Box>
                        <BoldText>
                            {projectName}
                        </BoldText>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            columnGap: '8px'
                        }}
                    >
                        <Caption bolder>
                            {companyName}
                        </Caption>
                        {
                            countries && (
                                <Caption>
                                    <LocationIcon/>
                                    {[...countries.common, ...countries.additional].join(', ')}
                                </Caption>
                            )
                        }
                        <Caption>
                            {capitalize([...workTypes.common, ...workTypes.additional].join(', '))}
                        </Caption>
                        {
                            rateRange && (
                                <Caption>
                                    <SeniorityLevelRange rateRange={rateRange}/>
                                </Caption>
                            )
                        }
                        <Caption>
                            {[...languages.common, ...languages.additional].join(', ')}
                        </Caption>
                    </Box>
                </Box>
            </Stack>
            {children}
        </Wrp>
    );
};

export default React.memo(MyMatchesTopInfo);
