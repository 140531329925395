import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {CaseStudyContextProvider, CaseStudyEdit, LoadingMask, useCaseStudy} from '../../../components';
import {RouteApplication} from '../../../constants';
import {useApplication} from '../../../hooks';
import {ICaseStudyVersion} from '../../../models';

const ApplicationCaseStudyWithContext = () => {
    const {application, isApplicationLoading} = useApplication();
    const {setData, setCancelUrl} = useCaseStudy();
    const {caseStudyId} = useParams();

    useEffect(() => {
        setCancelUrl(RouteApplication.application);
    }, [setCancelUrl]);

    useEffect(() => {
        const caseStudy = application?.caseStudies.find(it => it.id === Number(caseStudyId));

        if (caseStudy) {
            setData({
                id: caseStudy.id,
                draftVersion: {...caseStudy, isDraft: true},
            } as ICaseStudyVersion);
        }
    }, [caseStudyId, application?.caseStudies, setData]);

    return (
        <>
            {isApplicationLoading ? <LoadingMask/> : <CaseStudyEdit/>}
        </>
    );
};

const ApplicationCaseStudyPage = () => {
    return (
        <CaseStudyContextProvider>
            <ApplicationCaseStudyWithContext/>
        </CaseStudyContextProvider>
    );
};

export default ApplicationCaseStudyPage;
