import React from 'react';

const PdfCertificateIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.9995 17.501H4.5V18.5015H4.9995C5.28 18.5015 5.499 18.281 5.499 18.002C5.5005 17.72 5.28 17.501 4.9995 17.501Z"
                fill="#7E5FFF"/>
            <path
                d="M10.2495 17.501H9.49951V20.501H10.2495C10.389 20.501 10.5 20.3915 10.5 20.2505V17.75C10.5 17.6105 10.3905 17.501 10.2495 17.501Z"
                fill="#7E5FFF"/>
            <path
                d="M17.25 15.4995C12.4305 15.4995 8.5005 11.5695 8.5005 6.75C8.5005 4.0395 9.741 1.6095 11.691 0H2.751C1.23 0 0 1.23 0 2.7495V21.249C0 22.77 1.23 24 2.7495 24H17.25C18.7695 24 19.9995 22.77 19.9995 21.2505V15.06C19.14 15.3405 18.21 15.4995 17.25 15.4995ZM4.9995 19.9995H4.5V21.249C4.5 21.6585 4.1595 21.999 3.75 21.999C3.3405 21.999 3 21.6585 3 21.249V16.749C3 16.3395 3.3405 15.999 3.75 15.999H4.9995C6.099 15.999 6.999 16.899 6.999 17.9985C7.0005 19.0995 6.1005 19.9995 4.9995 19.9995ZM12 20.25C12 21.21 11.2095 22.0005 10.2495 22.0005H8.7495C8.34 22.0005 7.9995 21.66 7.9995 21.2505V16.7505C7.9995 16.341 8.34 16.0005 8.7495 16.0005H10.2495C11.2095 16.0005 12 16.791 12 17.751V20.25ZM16.2495 18.4995C16.6635 18.4995 16.9995 18.8355 16.9995 19.2495C16.9995 19.6635 16.6635 19.9995 16.2495 19.9995H14.499V21.249C14.499 21.663 14.163 21.999 13.749 21.999C13.335 21.999 12.999 21.663 12.999 21.249V17.7495C12.999 16.785 13.7835 15.999 14.7495 15.999H16.2495C16.6635 15.999 16.9995 16.335 16.9995 16.749C16.9995 17.163 16.6635 17.499 16.2495 17.499H14.7495C14.6115 17.499 14.499 17.6115 14.499 17.7495V18.4995H16.2495Z"
                fill="#7E5FFF"/>
            <path
                d="M17.25 0C13.5285 0 10.5 3.0285 10.5 6.75C10.5 10.4715 13.5285 13.5 17.25 13.5C20.9715 13.5 24 10.4715 24 6.75C24 3.0285 20.9715 0 17.25 0ZM20.502 5.409L17.0025 9.4095C16.821 9.618 16.56 9.741 16.284 9.75C16.2735 9.75 16.2615 9.75 16.251 9.75C15.987 9.75 15.732 9.645 15.5445 9.4575L13.545 7.458C13.155 7.068 13.155 6.435 13.545 6.0435C13.935 5.6535 14.568 5.6535 14.9595 6.0435L16.203 7.287L18.999 4.0905C19.3635 3.6765 19.9935 3.633 20.4105 3.9975C20.8245 4.362 20.8665 4.992 20.502 5.409Z"
                fill="#67DD42"/>
        </svg>
    );
};

export default PdfCertificateIcon;
