import React, {useCallback, useEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useNavigate, useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';

import {clientService, GetListResult} from '../../../api';
import {ClientPortalTitle, LoadingMask, showToastError, TabsStatusFilter} from '../../../components';
import {CLIENT_ROLES, RouteClientPortal} from '../../../constants';
import {ERoleStatusFilter, IClientRole} from '../../../models';
import theme from '../../../theme';
import {filterByStatus, statusesByFilter} from '../../../utils';
import {RolesContextProvider} from '../contexts/roles.context';
import {RolesEmpty} from '../index';

import {RoleItemDetails} from './components/RoleItemDetails';
import RoleList from './components/RoleList';

const PAGE_SIZE = 10;

const optionsFilter = [
    ERoleStatusFilter.All,
    ERoleStatusFilter.Active,
    ERoleStatusFilter.Drafts,
    ERoleStatusFilter.Archive
].map(value => ({text: value, value}));


const MyRolesPage = () => {
    const {filterBy, roleId} = useParams();

    const navigate = useNavigate();

    const {
        data: selectedRole,
        isLoading: isLoadingRole
    } = useQuery(
        [CLIENT_ROLES, Number(roleId)],
        clientService.getRoleById(Number(roleId)), {
            enabled: !!roleId,
            staleTime: 15 * 60 * 1000,
            onError: (error) => showToastError(error as AxiosError)
        }
    );

    const {data, hasNextPage, isLoading: isLoadingList, fetchNextPage} = useInfiniteQuery(
        [CLIENT_ROLES, filterBy],
        clientService.getRoles(statusesByFilter(filterBy as ERoleStatusFilter), 'id', 'DESC', PAGE_SIZE),
        {
            enabled: !!filterBy,
            getNextPageParam: (lastPage: GetListResult<IClientRole>) => {
                return lastPage.last ? false : lastPage.number + 1;
            },
        }
    );

    const isLoading = isLoadingList || isLoadingRole;

    // collect all pages results
    const items = useMemo(() => {
        const result: IClientRole[] = [];

        data?.pages.forEach(it => result.push(...it.data));

        return result;
    }, [data]);

    const handleFilterChange = useCallback((filter: string) => {
        navigate(`${RouteClientPortal.myRoles}/${filter}`);
    }, [navigate]);

    // if Role details then set filter by status
    useEffect(() => {
        if (!selectedRole || filterBy) return;

        const filter = filterByStatus(selectedRole.status);

        navigate(`${RouteClientPortal.myRoles}/${filter}/${roleId}`);
    }, [filterBy, roleId, selectedRole, navigate]);

    // by default set 'ALL' filter active
    useEffect(() => {
        if (!filterBy) {
            navigate(`${RouteClientPortal.myRoles}/${ERoleStatusFilter.Active}${roleId ? '/' + roleId : ''}`, {replace: true});
        }
    }, [filterBy, roleId, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!filterBy) {    //  fix glitches
        return null;
    }

    return (
        <RolesContextProvider>
            <Box
                sx={{
                    pt: '38px',
                    [theme.breakpoints.up('md')]: {
                        pt: '56px'
                    }
                }}
            >
                <Box
                    sx={{
                        mb: '22px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        [theme.breakpoints.up('md')]: {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }
                    }}
                >
                    <ClientPortalTitle>
                        My Roles
                    </ClientPortalTitle>

                    <TabsStatusFilter
                        items={optionsFilter}
                        value={filterBy || ERoleStatusFilter.Active}
                        onChange={handleFilterChange}
                    />
                </Box>
            </Box>

            {isLoading && !filterBy ? (
                <LoadingMask/>
            ) : (
                <Box sx={{
                    width: '100%',
                    [theme.breakpoints.up('lg')]: {
                        display: 'flex',
                        gap: '24px',
                        alignItems: 'flex-start'
                    }
                }}>
                    <Box
                        sx={{width: '100%', flexGrow: 0, minWidth: 0}}
                    >
                        <InfiniteScroll
                            dataLength={items.length}
                            hasMore={hasNextPage || false}
                            loader={<LoadingMask/>}
                            next={fetchNextPage}
                        >
                            <RoleList items={items}/>
                        </InfiniteScroll>
                    </Box>
                    {roleId && (
                        <RoleItemDetails roleId={Number(roleId)}/>
                    )}
                </Box>
            )}

            {
                data?.pages[0]?.empty && <RolesEmpty status={filterBy as ERoleStatusFilter}/>
            }
        </RolesContextProvider>
    );
};

export default React.memo(MyRolesPage);
