import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {Avatar, Box, styled, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../../api';
import {ArrowDown, LocationIcon} from '../../../assets/icons';
import {
    ACCOUNT,
    CASE_STUDIES,
    CERTIFICATES,
    CLIENT,
    DETAILS,
    FREELANCER,
    FREELANCER_PROFILE,
    RouteClientPortal,
    RouteFreelancerPortal
} from '../../../constants';
import {useAuth} from '../../../hooks';
import theme from '../../../theme';
import {getNameLetters, stringToColor} from '../../../utils';
import {BlackBorderButton} from '../../Button';
import {GradientBorderBox} from '../../index';
import ProgressBox from '../../ProgressBox';

const Wrp = styled('div')`
  padding: 40px 24px 24px;
  background-color: ${theme.palette.white.main};
  border-radius: 24px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 88px;
    display: block;
    background-image: linear-gradient(90deg, #CBDAEE 0%, #E2E6F5 26.04%, #E5E7F4 52.08%, #DEDCE8 71.87%, #E1D1E1 86.46%, #E2C7DC 100%);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const avatarStyles = {
    m: '0 auto 16px',
    width: '100px',
    height: '100px',
    backgroundColor: stringToColor('You'),
    fontSize: '24px',
    lineHeight: 1.2,
    fontWeight: 500,
    border: '5px solid',
    borderColor: theme.palette.white.main,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    }
};

const ProfileCard = () => {
    const navigate = useNavigate();
    const {isClient, isFreelancer, user} = useAuth();

    const {
        data: info,
    } = useQuery([FREELANCER_PROFILE], freelancerService.getProfile, {
        enabled: isFreelancer,
        staleTime: 60 * 1000,
        select: ((data) => data)
    });

    const {
        data: freelancer,
    } = useQuery([FREELANCER], freelancerService.getFleelancer, {
        enabled: isFreelancer,
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const {
        data: client,
    } = useQuery([CLIENT], clientService.getClient, {
        enabled: isClient,
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const caseStudies = useMemo(() => info?.caseStudies.filter(it => it.publicVersion) || [], [info]);

    const fullName = useMemo(() => {
        if (isClient && client) {
            return `${client?.firstName} ${client?.lastName}`;
        }

        if (isFreelancer && freelancer) {
            return `${freelancer?.firstName} ${freelancer?.lastName}`;
        }

        return `${user?.given_name} ${user?.family_name}`;
    }, [client, isClient, isFreelancer, freelancer, user]);

    const renderProgressCaption = useMemo(() => {
        const result: string[] = [];

        if (!caseStudies.length) {
            result.push('case studies');
        }

        if (caseStudies.length === 1) {
            result.push('case study');
        }

        if (!info?.certificates?.length) {
            result.push('certificate');
        }

        if (!info?.bio) {
            result.push('short bio');
        }

        if (!freelancer?.avatar) {
            result.push('photo');
        }

        if (!result.length) {
            return '';
        } else if (result.length === 1) {
            return `Add ${result[0]} to make it 100%`;
        } else {
            const last = result.pop();

            return `Add ${result.join(', ')} and ${last} to make it 100%`;
        }
    }, [freelancer?.avatar, info?.bio, caseStudies, info?.certificates]);

    const renderProgress = useMemo(() => {
        let result = 50;

        if (caseStudies.length === 1) {
            result += 15;
        } else if (caseStudies.length > 1) {
            result += 30;
        }

        if (info?.certificates?.length) {
            result += 10;
        }

        if (info?.bio) {
            result += 5;
        }

        if (freelancer?.avatar) {
            result += 5;
        }

        return result;
    }, [freelancer?.avatar, info?.bio, caseStudies, info?.certificates]);

    const handleCaseStudies = useCallback(() => navigate(`${RouteFreelancerPortal.profileSettings}/${CASE_STUDIES}`), [navigate]);
    const handleCertificates = useCallback(() => navigate(`${RouteFreelancerPortal.profileSettings}/${CERTIFICATES}`), [navigate]);

    const handleEditProfile = useCallback(() => {
        if (isFreelancer) {
            navigate(`${RouteFreelancerPortal.profileSettings}/${DETAILS}`);
        } else {
            navigate(`${RouteClientPortal.profileSettings}/${ACCOUNT}`);
        }
    }, [isFreelancer, navigate]);

    return (
        <Wrp>
            <Avatar
                alt={fullName}
                className="avatar"
                src={(isClient ? client : freelancer)?.avatar}
                sx={{
                    ...avatarStyles,
                    backgroundColor: stringToColor(fullName),
                    gridArea: 'avatar'
                }}
            >
                {getNameLetters(fullName)}
            </Avatar>
            <Typography
                sx={{
                    mb: '4px',
                    fontWeight: 600,
                    fontSize: '24px',
                    lineHeight: 1.5,
                    textAlign: 'center',
                    color: theme.palette.black.main
                }}
            >
                {fullName}
            </Typography>
            {
                !isClient && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <Typography
                            sx={{
                                gridArea: 'location',
                                display: 'flex',
                                alignItems: 'center',
                                pr: '8px',
                                gap: '4px',
                                fontWeight: 400,
                                fontSize: '13px',
                                color: theme.palette.gray.main,
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '0',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    width: '1px',
                                    height: '16px',
                                    backgroundColor: theme.palette.lightGray.main
                                }
                            }}
                        >
                            <LocationIcon/>
                            {info?.country}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '13px',
                                color: theme.palette.black.main,
                                'span': {
                                    font: 'inherit',
                                    color: theme.palette.gray.main
                                },
                            }}
                        >
                            {info?.yearsOfExperience} years <span>of experience in marketing</span>
                        </Typography>
                    </Box>
                )
            }
            <Box
                sx={{
                    mt: '16px',
                    mb: !isClient ? '24px' : null,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <BlackBorderButton
                    size="small"
                    sx={{
                        width: '100%',
                        [theme.breakpoints.up('md')]: {
                            width: 'auto'
                        }
                    }}
                    onClick={handleEditProfile}
                >
                    Edit profile
                </BlackBorderButton>
            </Box>
            {
                !isClient && (
                    <>
                        <Box sx={{mb: '16px'}}>
                            <ProgressBox
                                caption={renderProgressCaption || ''}
                                sx={{
                                    'h2': {
                                        mb: '16px',
                                        fontSize: '14px'
                                    },
                                    'img': {
                                        width: '16px',
                                        height: '16px'
                                    }
                                }}
                                value={renderProgress}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'grid',
                                gap: '8px'
                            }}
                        >
                            {caseStudies?.length < 2 && (<GradientBorderBox
                                animateOnHover
                                aria-role="button"
                                onClick={handleCaseStudies}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '16px',
                                        'svg': {
                                            rotate: '-90deg'
                                        }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            mr: 'auto',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                        }}
                                    >
                                        {caseStudies?.length === 1 ? 'Add case study' : 'Add case studies (2)'}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            color: theme.palette.purple.main
                                        }}
                                    >
                                        {caseStudies?.length === 1 ? '+15%' : '+30%'}
                                    </Typography>
                                    <ArrowDown/>
                                </Box>
                            </GradientBorderBox>)}
                            {!info?.certificates?.length && (<GradientBorderBox
                                animateOnHover
                                aria-role="button"
                                onClick={handleCertificates}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '16px',
                                        'svg': {
                                            rotate: '-90deg'
                                        }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            mr: 'auto',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                        }}
                                    >
                                        Add certificate
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            color: theme.palette.purple.main
                                        }}
                                    >
                                        +10%
                                    </Typography>
                                    <ArrowDown/>
                                </Box>
                            </GradientBorderBox>)}
                            {!freelancer?.avatar && (<GradientBorderBox
                                animateOnHover
                                aria-role="button"
                                onClick={handleEditProfile}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '16px',
                                        'svg': {
                                            rotate: '-90deg'
                                        }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            mr: 'auto',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                        }}
                                    >
                                        Add profile photo
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            color: theme.palette.purple.main
                                        }}
                                    >
                                        +5%
                                    </Typography>
                                    <ArrowDown/>
                                </Box>
                            </GradientBorderBox>)}
                            {!info?.bio && (<GradientBorderBox
                                animateOnHover
                                aria-role="button"
                                onClick={handleEditProfile}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '16px',
                                        'svg': {
                                            rotate: '-90deg'
                                        }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            mr: 'auto',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                        }}
                                    >
                                        Add bio
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            color: theme.palette.purple.main
                                        }}
                                    >
                                        +5%
                                    </Typography>
                                    <ArrowDown/>
                                </Box>
                            </GradientBorderBox>)}
                        </Box>
                    </>
                )
            }
        </Wrp>
    );
};

export default React.memo(ProfileCard);
