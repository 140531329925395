import React, {useCallback, useState} from 'react';
import {AxiosError} from 'axios';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, styled, Theme, useMediaQuery} from '@mui/material';
import {useQuery, useQueryClient} from '@tanstack/react-query';

import {freelancerService} from '../../../api';
import {ArrowDown} from '../../../assets/icons';
import {BackButton, BlackBorderButton, ContinueButton, LoadingMask, Portal, showToastError} from '../../../components';
import {FreelancerRateInfo} from '../../../components/PublicFreelancerProfile/components';
import {Item} from '../../../components/PublicFreelancerProfile/PublicFreelancerProfilePageForClient';
import {DETAILS, FREELANCER, FREELANCER_MATCHES, LG, MATCHES, MD, RouteFreelancerPortal} from '../../../constants';
import {useTwilio} from '../../../hooks';
import {EMatchStatus} from '../../../models';
import theme from '../../../theme';

import MyMatchesTopInfo from './components/MyMatchesTopInfo/MyMatchesTopInfo';
import {
    MyMatchesAboutClient,
    MyMatchesList,
    MyMatchesModalReject,
    MyMatchesOtherMatches,
    MyMatchesRoleDescription,
    MyMatchesWhyThisMatch
} from './components';

const Wrp = styled('div')`
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${MD}) {
    display: grid;
    grid-template-areas: 
            'buttonsRow buttonsRow'
    'topInfo topInfo'
    'rateBox aboutClient'
    'matchReasonBox matchReasonBox'
    'roleDescription roleDescription'
    'matchesBox otherMatches'
  ;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1400px) {
    display: grid;
    grid-template-areas: 
            'buttonsRow buttonsRow buttonsRow'
    'topInfo topInfo rateBox'
    'matchReasonBox matchReasonBox aboutClient'
    'matchesBox roleDescription otherMatches'
  ;
    grid-template-columns: 540px auto 293px;
  }
`;

const MyMatchesDetailsPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const navigate = useNavigate();
    const params = useParams();
    const matchId = parseInt(params.matchId || '', 10);

    const queryClient = useQueryClient();
    const twilioClient = useTwilio();

    const {
        isLoading,
        data,
        refetch,
    } = useQuery([FREELANCER, matchId, MATCHES], () => freelancerService.getMatch(matchId), {
        enabled: !!matchId,
        staleTime: 60 * 1000,
        select: ((response) => response.data),
        onError: (error) => showToastError(error as AxiosError)
    });

    console.log('details', data);

    const handleBackMove = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const handleMessageClick = async () => {
        setIsSubmitting(true);
        try {
            const conversationId = await freelancerService.getChatRoom(matchId);

            twilioClient.invalidateQueries();   // Contact list can miss new room
            navigate(`${RouteFreelancerPortal.inbox}/${conversationId}`);
        } catch (error) {
            showToastError(error as AxiosError);
        }
        setIsSubmitting(false);
    };

    const handleReject = useCallback(() => {
        refetch();
        queryClient.invalidateQueries([FREELANCER_MATCHES]);       // refetch needed for My Matches list
        // fixme: the following comment should be the invalidation for "My Work" screen when available
        // queryClient.invalidateQueries([CLIENT_ROLES, data?.roleId, MATCHES]); // refetch needed for My Matches list
        handleBackMove();
    }, [handleBackMove, queryClient, refetch]);

    return (
        <Box
            sx={{
                pt: '38px',
                [theme.breakpoints.up('md')]: {
                    pt: '56px'
                }
            }}
        >
            <Box sx={{
                gridArea: 'buttonsRow',
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                mb: '16px'
            }}>
                <BackButton
                    sx={{
                        mr: 'auto'
                    }}
                    onClick={handleBackMove}
                />
                {data?.previousMatchId && (
                    <BlackBorderButton
                        size="small"
                        sx={{
                            'svg': {
                                transform: 'rotate(90deg)'
                            }
                        }}
                        onClick={() => navigate(`${RouteFreelancerPortal.myMatches}/${DETAILS}/${data.previousMatchId}`)}
                    >
                        <ArrowDown/>
                        Prev {smUp && 'match'}
                    </BlackBorderButton>
                )}
                {data?.nextMatchId && (
                    <ContinueButton
                        variant="contained"
                        size="small"
                        sx={{
                            width: 'auto !important',
                            gap: '8px',
                            'div': {
                                gap: '8px !important',
                            },
                            'svg': {
                                transform: 'rotate(-90deg) !important'
                            },
                            'path': {
                                fill: theme.palette.white.main
                            }
                        }}
                        onClick={() => navigate(`${RouteFreelancerPortal.myMatches}/${DETAILS}/${data.nextMatchId}`)}
                    >
                        Next {smUp && 'match'}
                        <ArrowDown/>
                    </ContinueButton>
                )}
            </Box>
            {isLoading && <LoadingMask/>}
            {
                !isLoading && data && (
                    <Wrp>
                        <Item gridArea="topInfo">
                            <MyMatchesTopInfo
                                companyLogo={data.companyLogo}
                                companyName={data.companyName}
                                countries={data.countries}
                                languages={data.languages}
                                projectName={data.projectName}
                                rateRange={data.rateRange}
                                workTypes={data.workTypes}
                            >
                                {
                                    mdUp ? (
                                        <Box
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '16px',
                                                    flexShrink: 0
                                                }
                                            }}
                                        >
                                            {
                                                ![
                                                    EMatchStatus.REJECTED_BY_CLIENT,
                                                    EMatchStatus.REJECTED_BY_FREELANCER,
                                                    EMatchStatus.REJECTED_IN_TOUCH,
                                                    EMatchStatus.REJECTED_LIKED
                                                ].includes(data.status) && (
                                                    <MyMatchesModalReject
                                                        matchId={Number(matchId)}
                                                        onReject={handleReject}
                                                    />
                                                )
                                            }
                                            <ContinueButton
                                                disabled={isSubmitting}
                                                sx={{
                                                    width: 'auto !important',
                                                    flexShrink: 0,
                                                    'div': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                                variant="contained"
                                                onClick={handleMessageClick}
                                            >
                                                Message client
                                            </ContinueButton>
                                        </Box>
                                    ) : (
                                        <Portal order={1} visibleUntil={LG}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: '12px',
                                                    m: '-40px -20px',
                                                    p: '16px 20px 40px 20px',
                                                    backgroundColor: theme.palette.white.main,
                                                    borderTop: `1px solid ${theme.palette.lightGray.main}`,
                                                    'button': {
                                                        width: 'calc(50% - 6px) !important',
                                                        [theme.breakpoints.up('md')]: {
                                                            width: 'calc(33% - 8px) !important'
                                                        },
                                                    },
                                                }}
                                            >
                                                {
                                                    ![
                                                        EMatchStatus.REJECTED_BY_CLIENT,
                                                        EMatchStatus.REJECTED_BY_FREELANCER,
                                                        EMatchStatus.REJECTED_IN_TOUCH,
                                                        EMatchStatus.REJECTED_LIKED
                                                    ].includes(data.status) && (
                                                        <MyMatchesModalReject
                                                            matchId={Number(matchId)}
                                                            onReject={handleReject}
                                                        />
                                                    )
                                                }
                                                <ContinueButton
                                                    sx={{
                                                        width: 'auto !important',
                                                        'div': {
                                                            justifyContent: 'center'
                                                        }
                                                    }}
                                                    variant="contained"
                                                    onClick={handleMessageClick}
                                                >
                                                    Message client
                                                </ContinueButton>
                                            </Box>
                                        </Portal>
                                    )
                                }
                            </MyMatchesTopInfo>
                        </Item>
                        <Item
                            gridArea="rateBox"
                            backgroundColor={theme.palette.purple.main}
                            widthXl="293"
                        >
                            <FreelancerRateInfo
                                rate={data.rateRange}
                                title="Salary range"
                                workTypes={data.workTypes.common}
                            />
                        </Item>

                        <Item
                            gridArea="matchReasonBox"
                            borderColor={theme.palette.green.dark}
                            boxShadow="0px 0px 50px rgba(103, 221, 66, 0.15)"
                        >
                            <MyMatchesWhyThisMatch data={data}/>
                        </Item>

                        <Item
                            gridArea="aboutClient"
                            widthXl="293"
                        >
                            <MyMatchesAboutClient
                                client={data.client}
                                roleCountries={data.countries}
                            />
                        </Item>

                        <Item
                            gridArea="matchesBox"
                            widthXl="540"
                        >
                            <MyMatchesList data={data}/>
                        </Item>

                        <Item gridArea="roleDescription">
                            <MyMatchesRoleDescription description={data.projectDescription}/>
                        </Item>

                        <Item
                            gridArea="otherMatches"
                            widthXl="293"
                        >
                            <MyMatchesOtherMatches items={data.otherMatches}/>
                        </Item>
                    </Wrp>
                )
            }
        </Box>
    );
};

export default MyMatchesDetailsPage;
