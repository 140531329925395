import React, {lazy} from 'react';
import {Outlet, RouteObject} from 'react-router-dom';

import {HAS_ACCOUNT_KEY} from '../api';
import {AnimatePageWrp, LazyLoader, LoginPageWrp} from '../components';
import {RouteAuth, RouteCreateApplicantAccount, RouteCreateClientAccount, RoutePublic} from '../constants';
import {
    ForgotPassword,
    Impersonal,
    JoinAsClientOrFreelancer,
    Login,
    NewPassword,
    PrivacyPolicy,
    SignUpApplicant,
    SignUpClient,
    TermsOfService,
} from '../modules/Auth';

const UIToolboxPage = LazyLoader(lazy(() => import('../modules/UIToolbox/UIToolbox')));
const hasAccount = localStorage.getItem(HAS_ACCOUNT_KEY);

export const authRoutes: RouteObject = {
    path: '/',
    children: [
        {
            path: '',
            element: <AnimatePageWrp isUpAnimation={false}><Outlet/></AnimatePageWrp>,
            children: [
                {
                    index: true,    // default page
                    element: hasAccount ? <Login/> : <JoinAsClientOrFreelancer/>,
                },
                {
                    path: RouteAuth.login,
                    element: <Login/>,
                },
                {
                    path: RouteAuth.impersonal,
                    element: <Impersonal/>,
                },
                {
                    path: RouteAuth.joinAs,
                    element: <JoinAsClientOrFreelancer/>,
                },
                {
                    path: RoutePublic.components,
                    element: <UIToolboxPage/>,
                },
                {
                    path: RouteAuth.unauthorized,
                    element: <LoginPageWrp>Unauthorized</LoginPageWrp>
                },
                {
                    path: RouteAuth.forgotPassword,
                    element: <ForgotPassword/>
                },
                {
                    path: RouteAuth.newPassword,
                    element: <NewPassword/>
                },
                {
                    path: RouteAuth.terms,
                    element: <TermsOfService/>
                },
                {
                    path: RouteAuth.privacyPolicy,
                    element: <PrivacyPolicy/>
                }
            ]
        },

        {
            path: RouteCreateApplicantAccount.signup,
            element: <SignUpApplicant/>,
        },
        {
            path: RouteCreateClientAccount.signup,
            element: <SignUpClient/>,
        },
        {
            path: `${RouteCreateClientAccount.signup}/wdec`,
            element: <SignUpClient/>,
        },
    ],
};
