import React, {useState} from 'react';
import {AxiosError} from 'axios';
import {motion} from 'framer-motion';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {object, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Stack, Typography} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {userService} from '../../../api';
import {ContinueButton, Input, LoginPageWrp, Logo, showToastError, WhiteButton} from '../../../components';
import {animationAlertBox} from '../../../components/Animations';
import {RouteAuth} from '../../../constants';
import {useNavigateUTM} from '../../../hooks';
import theme from '../../../theme';
import {emailSchema, findFieldError} from '../../../utils';

const forgotSchema = object({
    ...emailSchema,
});

export type ForgotInput = TypeOf<typeof forgotSchema>;

const ForgotPassword = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const navigate = useNavigateUTM();

    const methods = useForm<ForgotInput>({
        resolver: zodResolver(forgotSchema),
    });

    const {
        handleSubmit,
        setError,
    } = methods;


    const {mutate} = useMutation(
        userService.forgotPassword,
        {
            onSuccess() {
                // showToastSuccess('Reset password email sent!');
                setIsSubmitted(true);
            },
            onError(error: AxiosError) {
                const email = findFieldError(error, 'email');

                if (email) {
                    setError('email', {type: 'custom', message: email.message});
                } else if (error.response?.status === 409 && (error.response?.data as any)?.error === 'Email not exists') {
                    setError('email', {
                        type: 'custom',
                        message: "We couldn't find your email address in our database, maybe you made a typo? If you don't have an account with us yet, you can create one at any time"
                    });
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const onSubmitHandler: SubmitHandler<ForgotInput> = (values) => {
        // 👇 Execute the Mutation
        mutate(values.email);
    };

    return (
        <LoginPageWrp>
            {!isSubmitted ? (
                <>
                    <Box textAlign="center" onClick={() => navigate(RouteAuth.login)}>
                        <Logo color="#121416"/>
                    </Box>
                    <Typography
                        align="center"
                        variant="h1"
                        sx={{
                            mt: '40px',
                            mb: '8px',
                            fontWeight: '600 !important',
                            fontSize: '24px !important'
                        }}
                    >
                        Forgot password?
                    </Typography>
                    <Typography
                        sx={{
                            mb: '32px',
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.gray.main,
                        }}
                    >
                        To reset your password, please provide us with your email address, and we will send you a link.
                    </Typography>
                    <FormProvider {...methods}>
                        <form
                            autoComplete="off"
                            noValidate
                            style={{width: '100%'}}
                            onSubmit={handleSubmit(onSubmitHandler)}
                        >
                            <Stack
                                spacing="8px"
                            >
                                <Input
                                    autoFocus
                                    name="email"
                                    placeholder="Email"
                                    sx={{
                                        '.MuiInputBase-input': {
                                            '&:autofill, &:autofill:hover, &:autofill:focus, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
                                                color: theme.palette.black.main,
                                                backgroundColor: theme.palette.white.main,
                                                WebkitTextFillColor: theme.palette.black.main,
                                                WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.white.main} inset`
                                            }
                                        }
                                    }}
                                />

                                <ContinueButton
                                    fullWidth
                                    sx={{
                                        'div': {
                                            justifyContent: 'center'
                                        }
                                    }}
                                    type="submit"
                                    variant="contained"
                                >
                                    Confirm
                                </ContinueButton>

                                <WhiteButton onClick={() => navigate(RouteAuth.login)}>
                                    Back
                                </WhiteButton>
                            </Stack>
                        </form>
                    </FormProvider>
                </>
            ) : (
                <Box
                    component={motion.div}
                    {...animationAlertBox(0)}
                    sx={{
                        textAlign: 'center',
                        'img': {
                            display: 'block',
                            width: '48px',
                            height: '48px',
                            m: '0 auto 40px'
                        }
                    }}
                >
                    <img src="/assets/images/done-icon.png" width="48" height="48" alt="done icon"/>
                    <Typography
                        sx={{
                            mb: '8px',
                            fontWeight: 600,
                            fontSize: '24px',
                        }}
                    >
                        We{'\''}ve sent you a password reset link!
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.gray.main
                        }}
                    >
                        Please check your email and follow the instructions to reset your password.
                    </Typography>
                </Box>
            )}
        </LoginPageWrp>
    );
};

export default React.memo(ForgotPassword);
