import React, {Suspense} from 'react';

import LoadingMask from '../LoadingMask';

const LazyLoader = (Component: React.LazyExoticComponent<any>, showLoader = true) =>
    (props: Record<string, unknown>): JSX.Element => {
        return (
            <Suspense
                fallback={
                    showLoader
                        ? <LoadingMask/>
                        : null
                }>
                <Component {...props} />
            </Suspense>
        );
    };

export default LazyLoader;
