import React from 'react';

import {Box} from '@mui/material';

import {BlackBorderButton, EngagementActionsClient, EngagementInfoBox} from '../../../../../../../components';
import {EEngagementStatus, EPauseStatus, EResumeStatus, IEngagement} from '../../../../../../../models';

interface ResumeCanceledProps {
    data: IEngagement;
    onMessage: () => void;
}

const ResumeCanceled: React.FC<ResumeCanceledProps> = ({data, onMessage}) => {
    const resumeStatus = data.resume?.resumeStatus as EResumeStatus;
    const isCanceled = [EResumeStatus.FREELANCER_CANCELLED, EResumeStatus.CLIENT_CANCELLED].includes(resumeStatus);

    const isYouInitiator = data.pause?.status === EPauseStatus.CLIENT_DECIDED;
    const isInitiatorFreelancer = data.pause?.status === EPauseStatus.CLIENT_ACCEPTED;
    const isYouCanceled = resumeStatus === EResumeStatus.CLIENT_CANCELLED;
    const isFreelancerCanceled = resumeStatus === EResumeStatus.FREELANCER_CANCELLED;

    if (data.status !== EEngagementStatus.PAUSED || !isCanceled) {
        return null;
    }

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/cross-icon.png"
                isError
                title="Engagement resume has been cancelled"
            >
                {isYouCanceled && isYouInitiator && (
                    <>
                        You have cancelled the engagement resume.
                        It is now on hold, until you manually resume it.
                        Jump into conversation to negotiate the details.
                    </>
                )}

                {isYouCanceled && isInitiatorFreelancer && (
                    <>
                        You have cancelled the engagement resume.
                        It is now on hold, until {data.freelancerName.split(' ')[0]} manually resumes it.
                        Jump into conversation to negotiate the details.
                    </>
                )}

                {isFreelancerCanceled && isYouInitiator && (
                    <>
                        {data.freelancerName.split(' ')[0]} has cancelled the engagement resume.
                        It is now on hold, until you manually resume it.
                        Jump into conversation to negotiate the details.
                    </>
                )}

                {isFreelancerCanceled && isInitiatorFreelancer && (
                    <>
                        {data.freelancerName.split(' ')[0]} has cancelled the engagement resume.
                        It is now on hold, until {data.freelancerName.split(' ')[0]} manually resumes it.
                        Jump into conversation to negotiate the details.
                    </>
                )}


                <Box
                    sx={{
                        mt: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        gap: '16px'
                    }}
                >
                    {isYouInitiator && (
                        <EngagementActionsClient.ResumeEngagementBtn
                            data={data}
                        />
                    )}

                    <BlackBorderButton
                        size="small"
                        onClick={onMessage}
                    >
                        Message {data.freelancerName.split(' ')[0]}
                    </BlackBorderButton>
                </Box>

            </EngagementInfoBox>
        </>
    );
};

export default React.memo(ResumeCanceled);
