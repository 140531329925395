import React from 'react';

import {Box, styled} from '@mui/material';

import {CardSubTitle, CardTitle} from '../../../Card/Card';


const ChatEmpty = styled('div')`
  margin: auto;
  padding: 12px;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
`;
const ArchiveListEmpty = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit'
            }}
        >
            <ChatEmpty>
                <div>
                    <img src="/assets/images/glancing-eyes-emoji.png" width="64" height="64" alt="glancing eyes emoji"/>
                </div>
                <CardTitle
                    sx={{
                        mb: '2px',
                        mt: '32px',
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    You have no archived chats yet
                </CardTitle>
                <CardSubTitle>
                    Archived chats will appear here once<br/>the role is not relevant anymore
                </CardSubTitle>
            </ChatEmpty>
        </Box>
    );
};

export default React.memo(ArchiveListEmpty);
