import React from 'react';

import {Typography} from '@mui/material';

import theme from '../../theme';

const ClientPortalTitle: React.FC<{ children: React.ReactNode }> = ({children}) => {
    return (
        <Typography
            variant="h1"
            sx={{
                fontSize: '16px !important',
                fontWeight: 600,
                lineHeight: '1.5',
                color: theme.palette.black.main,
                [theme.breakpoints.up('md')]: {
                    fontSize: '24px !important'
                }
            }}
        >
            {children}
        </Typography>
    );
};

export default React.memo(ClientPortalTitle);
