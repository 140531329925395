import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {SubmitHandler} from 'react-hook-form';

import {useMutation, useQuery} from '@tanstack/react-query';

import {freelancerService} from '../../api';
import {FREELANCER_PROFILE} from '../../constants';
import {useAuth} from '../../hooks';
import {IProfile} from '../../models';
import Switch from '../Forms/Switch';
import {showToastError, showToastSuccess} from '../ToastError/toast.service';

const AvailableForHireSwitch = () => {
    const {isImpersonal} = useAuth();
    const [openForHire, setOpenForHire] = useState(false);

    const {
        data,
        isLoading,
        refetch,
    } = useQuery([FREELANCER_PROFILE], freelancerService.getProfile, {
        staleTime: 60 * 1000,
        select: ((data) => data)
    });

    const {mutate, isLoading: isSubmitted} = useMutation(
        freelancerService.submitProfile,
        {
            onSuccess() {
                showToastSuccess('Your availability status is successfully updated!');
                refetch();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const onSubmitHandler: SubmitHandler<Partial<IProfile>> = useCallback((values) => {
        if (!data) return;

        mutate({...data, ...values});
    }, [data, mutate]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setOpenForHire(e.target.checked);

        onSubmitHandler({[e.target.name]: e.target.checked});
    }, [onSubmitHandler]);

    useEffect(() => {
        if (!data) return;

        setOpenForHire(data.isOpenToHireRole);
    }, [data]);

    return (
        <Switch
            checked={openForHire}
            disabled={isImpersonal}
            label={openForHire ? 'Available for instant hire' : 'Not available for instant hire'}
            name="isOpenToHireRole"
            onChange={handleChange}
            sx={{
                pointerEvents: (isLoading || isSubmitted) ? 'none' : 'auto'
            }}
        />
    );
};

export default React.memo(AvailableForHireSwitch);
