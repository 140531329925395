import React, {useCallback, useEffect, useState} from 'react';
import uniqBy from 'lodash/uniqBy';

import {Box, Grid, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {metaService, WIDGET_PAYLOAD} from '../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    BusinessModelsList,
    ChipsList,
    ClientTopTitleVerticals,
    ContinueButton,
    InputCaption,
    LoadingMask,
    Portal,
    showToastError,
    Verticals
} from '../../../components';
import {GET_BUSINESS_MODELS, GET_VERTICALS, RouteWidget} from '../../../constants';
import {useNavigateUTM} from '../../../hooks';
import {IVertical, IVerticalsResponse, OptionType} from '../../../models';
import theme from '../../../theme';
import {utm} from '../../../utils';

const WidgetVerticals = () => {
    const widgetId = utm.campaign;
    const [businessModels, setBusinessModels] = useState<string[]>([]);
    const [verticals, setVerticals] = useState<IVertical[]>([]);

    const navigate = useNavigateUTM();

    const {
        isLoading: isLoadingVerticals,
        data: verticalsAndRecommendedData
    } = useQuery([GET_VERTICALS], metaService.getVerticals, {
        select: ((response: IVerticalsResponse) => ({
            recommended: uniqBy(response.recommended, 'name'),
            verticals: uniqBy(response.verticals, 'name')
        }))
    });

    const verticalsData = verticalsAndRecommendedData?.verticals || [];
    const verticalsRecommendedData = verticalsAndRecommendedData?.recommended || [];

    const {
        isLoading: isLoadingBusinessModels,
        data: businessModelsData
    } = useQuery([GET_BUSINESS_MODELS], metaService.getBusinessModels, {
        select: (response: { businessModels: string[] }) => response.businessModels,
    });

    const handleClickBusinessModel = useCallback((model: string) => {
        const result = businessModels?.filter(str => str !== model);

        // unselect by second click
        if (businessModels && result.length !== businessModels.length) {
            setBusinessModels(result);
        } else {
            setBusinessModels([model]);
        }
    }, [businessModels]);

    const handleChangeVertical = useCallback((newValue: OptionType | null) => {
        const isAlreadyExists = verticals.find(item => item.name === newValue?.name);

        if (newValue?.name && !isAlreadyExists) {
            setVerticals([newValue as IVertical]);
        }
    }, [verticals]);

    const handleDeleteVertical = useCallback((option: OptionType) => {
        const newVerticals = verticals.filter(v => v !== option);

        setVerticals(newVerticals);
    }, [verticals]);

    const handleAddVertical = useCallback((option: OptionType) => {
        const isAlreadyExists = verticals.find(item => item.id === option.id);

        if (!isAlreadyExists) {
            setVerticals([option as IVertical]);
        }
    }, [verticals]);

    const handleSubmit = () => {
        if (!verticals?.length) {
            showToastError('Please add a Vertical.');
        } else if (!businessModels?.length) {
            showToastError('Please select Business Model.');
        } else {
            const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
            const data = widgetPayload ? JSON.parse(widgetPayload) : {};

            localStorage.setItem(WIDGET_PAYLOAD + widgetId, JSON.stringify({...data, businessModels, verticals}));
            navigate(RouteWidget.tools + location.search);
        }
    };

    useEffect(() => {
        if (!widgetId) return;
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
        const data = widgetPayload ? JSON.parse(widgetPayload) : {};
        const {businessModels, verticals} = data;

        if (businessModels && verticals) {
            setBusinessModels(businessModels);
            setVerticals(verticals);
        }
    }, [widgetId]);

    const isLoading = isLoadingVerticals || isLoadingBusinessModels;

    return (
        <AnimatePageWrp maxWidth={{md: 600, lg: 621}}>
            <ClientTopTitleVerticals/>

            {
                isLoading ? (
                    <LoadingMask/>
                ) : (
                    <Grid
                        container
                        maxWidth={611}
                        spacing={2}
                        rowSpacing={0}
                        sx={{
                            pb: '120px',
                            m: '0 auto',
                            [theme.breakpoints.up('sm')]: {
                                pb: 0,
                                mt: '-8px'
                            },
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: '40px',
                                [theme.breakpoints.up('lg')]: {
                                    mb: '56px'
                                }
                            }}
                        >

                            <Verticals
                                options={verticalsData}
                                placeholder="Ex. Health & Wellness, Campaigns, SaaS"
                                value={verticals[0] || null}
                                onChange={handleChangeVertical}
                                onDelete={handleDeleteVertical}
                            />

                            <Typography
                                sx={{
                                    display: 'inline-block',
                                    marginTop: '24px',
                                    marginBottom: '12px',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: theme.palette.gray.main
                                }}
                                variant="caption"
                            >
                                Suggested verticals
                            </Typography>
                            <AnimateHeight isVisible={true}>
                                <ChipsList
                                    items={verticalsRecommendedData}
                                    selectedVerticals={verticals}
                                    onAdd={handleAddVertical}
                                />
                            </AnimateHeight>

                        </Grid>
                        <Grid item xs={12} sx={{mb: 2}}>
                            <InputCaption>
                                What is your <span>business model</span>?
                            </InputCaption>

                            <BusinessModelsList
                                items={businessModelsData || []}
                                selectedIds={businessModels}
                                onClick={handleClickBusinessModel}
                            />
                        </Grid>
                    </Grid>
                )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '50px'
                    },
                    [theme.breakpoints.up('lg')]: {
                        mt: '56px'
                    },
                    '@media(max-height: 1400px)': {
                        pb: '60px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    hasArrow
                    variant="contained"
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};

export default WidgetVerticals;
