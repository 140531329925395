export interface IMeeting {
    from: string;
    id?: number;
    offset?: string;
    status?: EMeetingStatus;
    timezone: string;
    to: string;
}

export interface IMeetingRequest extends IMeeting {
    engagementId?: number;
}

export enum EMeetingStatus {
    ACCEPTED = 'ACCEPTED',
    CLIENT_PROPOSED = 'CLIENT_PROPOSED',
    CLIENT_REJECTED = 'CLIENT_REJECTED',
    FREELANCER_PROPOSED = 'FREELANCER_PROPOSED',
    FREELANCER_REJECTED = 'FREELANCER_REJECTED',
    PAST = 'PAST'
}
