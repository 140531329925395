import React, {useEffect, useMemo, useRef} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {
    AccountIcon,
    BookmarkIcon,
    ClipboardIcon,
    LockOpenIcon,
    PurseOutlineIcon,
    // SettingsIcon
} from '../../../assets/icons';
import {AsideMenu, ClientPortalTitle, LoadingMask, Tab, Tabs} from '../../../components';
import {IAsideMenuItem} from '../../../components/AsideMenu/AsideMenu';
import {
    ACCOUNT,
    BILLING,
    CASE_STUDIES,
    CERTIFICATES,
    DETAILS,
    MD,
    RouteFreelancerPortal,
    SETTINGS,
    XL
} from '../../../constants';
import theme from '../../../theme';
import {useFreelancer} from '../contexts';

const tabs = [ACCOUNT, DETAILS, CASE_STUDIES, CERTIFICATES, BILLING, SETTINGS];

const ContentWrp = styled('div')`
  @media (min-width: ${MD}) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 365px auto;
    gap: 24px;

    form {
      margin: 0 auto;
    }
  }

  @media (min-width: ${XL}) {
    grid-template-columns: 365px auto 365px;
    gap: 119px;
  }
`;

const desktopTabs: IAsideMenuItem[] = [
    {
        link: `${RouteFreelancerPortal.profileSettings}/${ACCOUNT}`,
        title: 'Account',
        icon: <LockOpenIcon/>
    },
    {
        link: `${RouteFreelancerPortal.profileSettings}/${DETAILS}`,
        title: 'Profile',
        icon: <AccountIcon/>
    },
    {
        link: `${RouteFreelancerPortal.profileSettings}/${CASE_STUDIES}`,
        title: 'Case studies',
        icon: <ClipboardIcon/>
    },
    {
        link: `${RouteFreelancerPortal.profileSettings}/${CERTIFICATES}`,
        title: 'Certificates',
        icon: <BookmarkIcon/>
    },
    {
        link: `${RouteFreelancerPortal.profileSettings}/${BILLING}`,
        title: 'Payouts',
        icon: <PurseOutlineIcon/>
    },
    // {
    //     link: `${RouteFreelancerPortal.profileSettings}/${SETTINGS}`,
    //     title: 'Settings',
    //     icon: <SettingsIcon/>
    // }
];

const ProfilePage = () => {
    const {state: {data, isLoading}} = useFreelancer();
    const location = useLocation();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const activeTab = useMemo(() => location.pathname.replace(`${RouteFreelancerPortal.profileSettings}/`, ''), [location.pathname]);

    // activate first tab if no one selected
    useEffect(() => {
        if (location.pathname === RouteFreelancerPortal.profileSettings) {
            navigate(tabs[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!activeTab || isLoading) return;

        ref.current?.focus();
    }, [activeTab, isLoading]);

    return (
        <>
            <Box
                sx={{
                    pt: '38px',
                    [theme.breakpoints.up('md')]: {
                        pt: '56px'
                    }
                }}
            >
                <Box
                    sx={{
                        mb: '22px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        [theme.breakpoints.up('md')]: {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: '64px',
                        }
                    }}
                >
                    <ClientPortalTitle>
                        Profile <br/>
                        <Typography
                            variant="caption"
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px !important',
                                color: theme.palette.gray.main
                            }}
                        >
                            Here you can edit your profile, add more case studies, references & certificates, and manage
                            your account settings.
                        </Typography>
                    </ClientPortalTitle>

                </Box>
            </Box>
            <ContentWrp>
                {
                    (!isLoading && mdDown) ? (
                        <Tabs hasScroll fullWidth>
                            {tabs.map(item => (
                                <Tab
                                    isActive={activeTab === item}
                                    key={item}
                                    tabIndex={activeTab === item ? 0 : undefined}
                                    ref={activeTab === item ? ref : null}
                                    onClick={() => navigate(item)}
                                >
                                    {getTabNameByRoute(item)}
                                </Tab>
                            ))}
                        </Tabs>
                    ) : (
                        <Box
                            sx={{
                                position: 'relative'
                            }}
                        >
                            <AsideMenu list={desktopTabs}/>
                        </Box>
                    )
                }
                {
                    isLoading && (
                        <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <LoadingMask/>
                        </Box>
                    )
                }

                {/* Route for current tab */}
                {/*{data && <AnimatePageWrp><Outlet/></AnimatePageWrp>}*/}
                {data && <Outlet/>}
            </ContentWrp>
        </>
    );
};

function getTabNameByRoute(route: string) {
    switch (route) {
        case ACCOUNT:
            return 'Account';
        case DETAILS:
            return 'Profile';
        case CASE_STUDIES:
            return 'Case Studies';
        case CERTIFICATES:
            return 'Certificates';
        case SETTINGS:
            return 'Settings';
        case BILLING:
            return 'Payouts';
        default:
            return '';
    }
}

export default React.memo(ProfilePage);
