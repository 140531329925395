import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import reduce from 'lodash/reduce';

import {darken} from '@mui/material';
import {Message} from '@twilio/conversations';

import {ICallMessageAttributes, IMessageAttributes} from '../models';

import {getFileName} from './file.utils';

export const replaceSymbolsInPhone = (phone: string) => {
    return phone.replace(/[^+0-9]/ig, '');
};

export const urlRegex = new RegExp('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?', 'ig');

function createCopy(textArea: HTMLInputElement) {
    const copy = document.createElement('div');

    copy.textContent = textArea.value;
    const style = getComputedStyle(textArea);

    [
        'fontFamily',
        'fontSize',
        'fontWeight',
        'wordWrap',
        'whiteSpace',
        'borderLeftWidth',
        'borderTopWidth',
        'borderRightWidth',
        'borderBottomWidth',
    ].forEach(function (key: any) {
        copy.style[key] = style[key];
    });
    copy.style.overflow = 'auto';
    copy.style.width = textArea.offsetWidth + 'px';
    copy.style.height = textArea.offsetHeight + 'px';
    copy.style.position = 'absolute';
    copy.style.left = textArea.offsetLeft + 'px';
    copy.style.top = textArea.offsetTop + 'px';
    document.body.appendChild(copy);

    return copy;
}

export function getCaretPosition(textArea: HTMLInputElement, wrapper: HTMLDivElement) {
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const copy = createCopy(textArea);
    const range = document.createRange();
    const selection = document.getSelection();

    if (!copy || !copy.firstChild || !selection) return;

    range.setStart(copy.firstChild, start || 0);
    range.setEnd(copy.firstChild, end || 0);

    selection.removeAllRanges();
    selection.addRange(range);
    const rect = range.getBoundingClientRect();

    document.body.removeChild(copy);
    textArea.selectionStart = start;
    textArea.selectionEnd = end;
    textArea.focus();

    return {
        x: rect.left - textArea.scrollLeft + 100,
        y: rect.top - textArea.scrollTop + wrapper.offsetTop - 30
    };
}

export const getLastMessage = (messages: Message[]) => {
    const {length, [length - 1]: last} = messages;
    const isProposal = (last?.attributes as IMessageAttributes)?.proposalId;
    const isCall = (last?.attributes as ICallMessageAttributes)?.roomSid;
    const isFile = last?.type === 'media';
    let body = last?.body;

    if (isProposal) {
        body = 'proposal';
    } else if (isCall) {
        body = 'call';
    } else if (isFile) {
        body = last?.attachedMedia?.length ? getFileName(last?.attachedMedia[0].filename || '', 21) : 'file';
    }

    return {
        body,
        dateUpdated: last?.dateUpdated,
    };
};

export const getTextLength = (str: string) => str.replace(/(\r\n|\n|\r)/g, ' ').length;

export const getNameLetters = (name: string) => {
    const nameAsArr = name.split(' ');

    if (!name) return '';

    return (nameAsArr[0][0] + nameAsArr[nameAsArr.length - 1][0]).toUpperCase();
};

const baseCurrencyOtions = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
};
const formatterWithoutCents = new Intl.NumberFormat('en-US', {
    ...baseCurrencyOtions,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const formatter = new Intl.NumberFormat('en-US', {
    ...baseCurrencyOtions,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

// BE store money as integer with cents
// 1000 -> $10.00
export const formatCurrency = (amount: number, isCents = false) => {
    return isCents
        ? formatter.format(amount / 100)
        : formatterWithoutCents.format(amount / 100);
};

// replace plain URLs with links
export const linkify = (inputText: string) => {
    //URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

    //Change email addresses to mailto:: links.
    const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;

    let replacedText;

    replacedText = inputText.replace(replacePattern1, '<a href="$1">link</a>');

    replacedText = replacedText.replace(replacePattern2, '$1<a href="https://$2">link</a>');

    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
};

export const stringToColor = (string: string): string => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;

        color += `00${value.toString(16)}`.slice(-2);
    }

    // do not return black color
    if (!string || color === '#000000') {
        return '#ECF4FE';
    }

    return darken(isString(color) ? color : 'white', .2);
};

// React.memo can be checked with:
// React.memo(InputAdornment, equalMemo(['inputRef'], 'InputAdornment'));
export const equalMemo = (ignoreList: string[] = [], consoleLogName?: string) => (prev: any, next: any) => {
    const diffProps = diff(prev, next);
    const result = !Object.keys(diffProps).filter(key => !ignoreList.includes(key)).length;

    if (consoleLogName) {
        if (Object.keys(diffProps).length) {
            console.log('bad memomized props', consoleLogName, diffProps);
        } else {
            console.log('good memomized!', consoleLogName);
        }

        if (ignoreList.length) {
            console.log('ignored', ignoreList);
        }
        console.log('isEqual:', result);
    }

    return result;
};

export const diff = function (obj1: any, obj2: any) {
    return reduce(obj1, function (result: any, value, key: any) {
        if (isPlainObject(value)) {
            const r = diff(value, obj2[key]);

            if (Object.keys(r).length) {
                result[key] = r;
            }
        } else if (!isEqual(value, obj2[key])) {
            result[key] = value;
        }

        return result;
    }, {});
};
