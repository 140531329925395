import React, {useCallback, useState} from 'react';

import {useQueryClient} from '@tanstack/react-query';

import {ContinueButton, DialogSuccess, EngagementInfoBox} from '../../../../../../../components';
import {CLIENT_ENGAGEMENT} from '../../../../../../../constants';
import {EPauseStatus, IEngagementClient} from '../../../../../../../models';
import {formatDate} from '../../../../../../../utils';

import DialogConfirmRequest from './DialogConfirmRequest';

type PauseIncomingProps = {
    data: IEngagementClient;
};

const PauseRequestBox: React.FC<PauseIncomingProps> = ({data}) => {
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const handleRejectClose = useCallback((isNeedUpdate: boolean) => {
        setOpen(false);
        // play modal show/hide animation
        setTimeout(() => {
            setOpenSuccess(isNeedUpdate);
        }, 500);
    }, []);

    const handleSuccessClose = useCallback((isNeedUpdate: boolean) => {
        setOpenSuccess(false);
        queryClient.invalidateQueries([CLIENT_ENGAGEMENT, data.id]);
    }, [data.id, queryClient]);

    if (!data?.pause || data.pause?.status !== EPauseStatus.FREELANCER_REQUESTED) {
        return null;
    }

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/pending-icon.png"
                title="Engagement pause request"
            >
                {data.freelancer.firstName} has submitted the engagement pause request.&nbsp;
                Reason: {data.pause.reason}.&nbsp;
                The engagement is set to be on hold from {formatDate(data.pause?.startDate)}{data.pause?.endDate ? ` - ${formatDate(data.pause?.endDate)}` : ''}.&nbsp;
                The billing for that period will be stopped.

                <br/>
                <br/>

                <ContinueButton
                    size="small"
                    sx={{
                        width: 'auto!important'
                    }}
                    variant="contained"
                    onClick={() => setOpen(true)}
                >
                    Respond
                </ContinueButton>

            </EngagementInfoBox>

            <DialogConfirmRequest data={data} open={open} onClose={handleRejectClose}/>

            <DialogSuccess
                open={openSuccess}
                subtitle="Wishing you two productive work!"
                title="The engagement pause has been successfully cancelled."
                onClose={handleSuccessClose}
            />

        </>
    );
};

export default React.memo(PauseRequestBox);
