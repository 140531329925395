import React from 'react';
import {motion} from 'framer-motion';

import {styled} from '@mui/material';

import {animationFade} from '../../../../../components/Animations';
import {IDynamicProfiles} from '../../../../../models';

import {MatchedCard} from './components';


const MatchesList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type DynamicProfilesProps = {
    data?: IDynamicProfiles[];
};

const DynamicProfiles: React.FC<DynamicProfilesProps> = ({data}) => {
    return (
        <MatchesList>
            {data?.map((item, index) => (
                <motion.div
                    {...animationFade(index)}
                    key={item.id}
                    layout
                >
                    <MatchedCard data={item} />
                </motion.div>
            ))}
        </MatchesList>
    );
};

export default React.memo(DynamicProfiles);
