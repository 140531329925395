import type {RouteObject} from 'react-router-dom';

import {applicationRoutes, applicationRouteThankYou} from './application.router';
import {authRoutes} from './auth.router';
import {clientPortalRoutes} from './client-portal.router';
import {createApplicantRoutes} from './create-applicant.router';
import {createClientRoutes} from './create-client.router';
import {errorsRoutes} from './errors.router';
import {freelancerPortalRoutes} from './freelancer-portal.router';
import {widgetRoutes} from './widget-client.router';


const routes: RouteObject[] = [
    // Auth
    authRoutes,

    // On-boarding flow  // TODO add lazy-loading
    createApplicantRoutes,
    createClientRoutes,
    applicationRoutes,
    applicationRouteThankYou,

    // Portals          // TODO add lazy-loading
    clientPortalRoutes,
    freelancerPortalRoutes,

    //Widget            // TODO add lazy-loading
    widgetRoutes,

    // 404
    ...errorsRoutes

];

export default routes;
