import React, {ReactNode, useContext, useEffect, useMemo} from 'react';
import {Link, NavLink, useNavigate} from 'react-router-dom';

import {Avatar, Badge, IconButton, styled, Theme, useMediaQuery} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../api';
import {ArrowDown, BurgerIcon, EmailIcon} from '../../assets/icons';
import {AvailableForHireSwitch, AvailableForWorkSwitch, Button, Logo, Notifications} from '../../components';
import {CLIENT, FREELANCER, LG, RouteAuth, RouteClientPortal, RouteFreelancerPortal} from '../../constants';
import {ActionTypes, TwilioContext, useStateContext} from '../../contexts';
import {useAuth, useTwilio} from '../../hooks';
import theme from '../../theme';
import {getNameLetters, stringToColor} from '../../utils';

import HeaderDropdownMenu from './HeaderDropdownMenu';


const Container = styled('div')`
  max-width: 1672px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Header = styled('header')<{ menuOpen?: boolean }>`
  position: fixed;
  background-color: ${theme.palette.white.main};
  top: 0;
  left: ${props => props.menuOpen ? 'calc(-100% + 67px)' : 0};
  width: 100%;
  height: auto;
  transition: left .3s;
  z-index: 100;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.03);

  @media (min-width: ${LG}) {
    top: 17px;
    left: 0;
    background-color: unset;
    box-shadow: unset;

    &::before {
      content: '';
      position: absolute;
      top: -17px;
      left: 0;
      width: 100%;
      height: 50px;
      z-index: -1;
      background-image: linear-gradient(to bottom, ${theme.palette.lightGray.light} 82%, transparent 100%);
    }
  }
`;

const HeaderContent = styled('div')`
  display: flex;
  align-items: center;
  padding: 16px 0 11px;

  @media (min-width: ${LG}) {
    padding: 12px 24px;
    min-height: 66px;
    background-color: ${theme.palette.white.main};
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.03);
    border-radius: 100px;
  }

  .logo {
    width: 102px;
    height: 30px;
    flex-shrink: 0;

    @media (min-width: ${LG}) {
      order: 1;
    }
  }
`;

const HeaderList = styled('ul')`
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;

  & + & {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #282828;
  }

  &:last-of-type {
    padding-top: 40px;
  }

  @media (min-width: ${LG}) {
    order: 2;
    display: flex;
    align-items: center;
    margin-left: 44px;

    &:last-of-type {
      padding-top: 0;
    }
  }
`;

const HeaderListItem = styled('li')`
  a {
    display: flex;
    align-items: center;
    padding: 24px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.gray.main};
    transition: color .3s;

    &::after {
      content: '';
      margin-left: auto;
      flex-shrink: 0;
      min-width: 90px;
      width: 50%;
      height: 5px;
      border-radius: 5px;
      background-image: linear-gradient(to right, transparent, ${theme.palette.purple.main});
      background-position: 0 center;
      background-size: 0;
      background-repeat: no-repeat;
      opacity: 0;
      transition: opacity .3s, background-size .7s;
    }

    &.active {
      color: ${theme.palette.white.main};

      &::after {
        opacity: 1;
        background-size: 100%;
      }
    }

    @media (min-width: ${LG}) {
      position: relative;
      padding: 0 20px;

      &::after {
        position: absolute;
        bottom: -22px;
        left: 50%;
        transform: translateX(-50%);
        min-width: unset;
        width: calc(100% - 40px);
        height: 2px;
        background-color: ${theme.palette.black.main};
        background-image: none;
      }

      &.active {
        color: ${theme.palette.black.main};
      }

      &:hover {
        color: ${theme.palette.black.main};
      }
    }
  }

  button {
    padding: 24px 0;
    background-color: unset;
    min-width: unset;
    color: ${theme.palette.gray.main};

    &:hover {
      background-color: unset;
    }
  }
`;

const HeaderNotifList = styled('div')`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;

  @media (min-width: ${LG}) {
    order: 3;
  }
`;

const HeaderNotifItem = styled('div')`
  .MuiBadge-badge {
    top: 2px;
    right: 2px;
  }

  .mail-badge {
    .MuiBadge-badge {
      top: 8px;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 22px;
      height: 22px;
      padding: 0 2px;
      border-radius: 50px;
      border: 3px solid ${theme.palette.white.main};
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
    }
  }
`;

const ProfileWrp = styled('div')`
  button {
    padding: 0;
  }

  @media (min-width: ${LG}) {
    order: 4;
    margin-left: 24px;
  }
`;

const BurgerMenuWrp = styled('nav')<{ open?: boolean }>`
  margin-left: 24px;
  flex-shrink: 0;
`;

const BurgerMenuBtn = styled('div')`
  margin: 0 -8px;
`;

const BurgerMenuContent = styled('div')<{ menuOpen?: boolean }>`
  position: fixed;
  left: ${props => props.menuOpen ? '67px' : '100%'};
  top: 0;
  width: calc(100vw - 67px);
  height: 100vh;
  padding: 0 40px 48px;
  background-color: ${theme.palette.black.main};
  overflow-y: auto;
  transition: left .3s;
`;

const ArrowIconWrp = styled('div')<{ rotated?: boolean }>`
  transform: ${props => props.rotated ? 'scaleY(-1)' : 'scaleY(1)'};
  display: flex;
  height: 40px;
  width: 20px;
  align-items: center;
  transition: transform .3s;
`;


export interface IMenuItem {
    action?: string;
    icon?: React.ReactNode;
    link: string;
    text: string;
}

interface IProps {
    chatLink: string;
    children: ReactNode;
    headerListItems: IMenuItem[];
    profileListItems: IMenuItem[];
    onAction?: (item: IMenuItem) => void;
}

export const PortalHeader: React.FC<IProps> = React.memo(({
    chatLink,
    children,
    headerListItems,
    profileListItems,
    onAction
}) => {
    const {isClient, isFreelancer, user, logout} = useAuth();
    const {state: {openMobileMenu}, dispatch} = useStateContext();
    const {connect} = useTwilio();
    const {allUnreadMessagesCount} = useContext(TwilioContext);

    const navigate = useNavigate();

    const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const avatarMenuOpen = Boolean(avatarMenuAnchorEl);
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const betweenLgAnd1400 = useMediaQuery((theme: Theme) => theme.breakpoints.between('lg', 1400));

    const {
        data: freelancer,
    } = useQuery([FREELANCER], freelancerService.getFleelancer, {
        enabled: isFreelancer,
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const {
        data: client,
    } = useQuery([CLIENT], clientService.getClient, {
        enabled: isClient,
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const headerLink = useMemo(() => {
        if (isClient) {
            return RouteClientPortal.default;
        } else if (isFreelancer) {
            return RouteFreelancerPortal.default;
        } else {
            return RouteAuth.login;
        }
    }, [isFreelancer, isClient]);

    const handleAvatarMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAvatarMenuAnchorEl(event.currentTarget);
    };
    const handleAvatarMenuClose = () => {
        setAvatarMenuAnchorEl(null);
    };

    const handleItemClick = (event: React.MouseEvent<HTMLElement>, item: IMenuItem) => {
        if (item.action && onAction) {
            onAction(item);
        } else if (item.link) {
            navigate(item.link);
        }
        setAvatarMenuAnchorEl(null);
    };

    const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (openMobileMenu) {
            handleToggleMenu();
        }
        logout();
        navigate(RouteAuth.login, {replace: true});
    };

    const handleToggleMenu = () => {
        document.body.classList.toggle('header-menu-open');
        dispatch({type: ActionTypes.OPEN_MOBILE_MENU, payload: !openMobileMenu});
    };

    const fullName = useMemo(() => {
        if (isClient) {
            return `${client?.firstName} ${client?.lastName}`;
        }

        if (isFreelancer) {
            return `${freelancer?.firstName} ${freelancer?.lastName}`;
        }

        return `${user?.given_name} ${user?.family_name}`;
    }, [client, isClient, isFreelancer, freelancer, user]);

    const shortName = useMemo(() => {
        if (isClient) {
            return getNameLetters(`${client?.firstName} ${client?.lastName}`);
        }

        if (isFreelancer) {
            return getNameLetters(`${freelancer?.firstName} ${freelancer?.lastName}`);
        }

        return getNameLetters(`${user?.given_name} ${user?.family_name}`);
    }, [client, isClient, isFreelancer, freelancer, user]);

    useEffect(() => {
        // This function will connect to twilio using your token and set a default conversation to pass to useConversation hook
        connect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Header menuOpen={openMobileMenu}>
            <Container>
                <HeaderContent>
                    <Link to={headerLink}>
                        <Logo color="black"/>
                    </Link>

                    <HeaderNotifList>
                        {!lgDown && children}
                        <HeaderNotifItem>
                            <Notifications/>
                        </HeaderNotifItem>
                        <HeaderNotifItem>
                            <Badge
                                color="error"
                                invisible={false}
                                badgeContent={allUnreadMessagesCount}
                                className="mail-badge"
                            >
                                <IconButton onClick={() => navigate(chatLink)}>
                                    <EmailIcon/>
                                </IconButton>
                            </Badge>
                        </HeaderNotifItem>
                    </HeaderNotifList>

                    {
                        lgDown
                            ? (
                                <BurgerMenuWrp open={openMobileMenu}>
                                    <BurgerMenuBtn>
                                        <IconButton onClick={handleToggleMenu}>
                                            <BurgerIcon/>
                                        </IconButton>
                                    </BurgerMenuBtn>
                                    <BurgerMenuContent menuOpen={openMobileMenu}>
                                        <HeaderList>
                                            {
                                                headerListItems.map(item =>
                                                    <HeaderListItem key={item.text}>
                                                        <NavLink
                                                            onClick={handleToggleMenu}
                                                            to={item.link}
                                                        >
                                                            {item.text}
                                                        </NavLink>
                                                    </HeaderListItem>
                                                )
                                            }
                                        </HeaderList>
                                        <HeaderList>
                                            {
                                                profileListItems.map(item =>
                                                    <HeaderListItem key={item.text}>
                                                        <NavLink
                                                            onClick={handleToggleMenu}
                                                            to={item.link}
                                                        >
                                                            {item.text}
                                                        </NavLink>
                                                    </HeaderListItem>
                                                )
                                            }

                                            <HeaderListItem>
                                                <Button variant="text" onClick={handleLogout}>
                                                    Logout
                                                </Button>
                                            </HeaderListItem>
                                        </HeaderList>
                                        <HeaderList>{children}</HeaderList>
                                    </BurgerMenuContent>
                                </BurgerMenuWrp>
                            ) : (
                                <>
                                    <HeaderList>
                                        {
                                            headerListItems.map(item =>
                                                <HeaderListItem key={item.text}>
                                                    <NavLink to={item.link}>{item.text}</NavLink>
                                                </HeaderListItem>
                                            )
                                        }
                                    </HeaderList>

                                    <ProfileWrp>
                                        <IconButton
                                            onClick={handleAvatarMenuClick}
                                        >
                                            <Avatar
                                                alt={fullName}
                                                src={(isClient ? client : freelancer)?.avatar}
                                                sx={{
                                                    width: '40px',
                                                    height: '40px',
                                                    background: stringToColor(fullName),
                                                    fontSize: '16px',
                                                    lineHeight: '21px',
                                                    fontWeight: 500,
                                                    'img': {
                                                        display: 'block',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        aspectRatio: '1/1'
                                                    }
                                                }}
                                            >
                                                {shortName}
                                            </Avatar>
                                            <ArrowIconWrp rotated={avatarMenuOpen}>
                                                <ArrowDown/>
                                            </ArrowIconWrp>
                                        </IconButton>
                                        <HeaderDropdownMenu
                                            anchorEl={avatarMenuAnchorEl}
                                            avatarSrc={(isClient ? client : freelancer)?.avatar}
                                            email={user?.email}
                                            fullName={fullName}
                                            open={avatarMenuOpen}
                                            profileListItems={profileListItems}
                                            shortName={shortName}
                                            switchItems={isFreelancer && betweenLgAnd1400 && [
                                                <AvailableForWorkSwitch key="workAvailability"/>,
                                                <AvailableForHireSwitch key="hireAvailability"/>
                                            ] || undefined}
                                            onClose={handleAvatarMenuClose}
                                            onItemClick={handleItemClick}
                                            onLogout={handleLogout}
                                        />
                                    </ProfileWrp>
                                </>
                            )
                    }
                </HeaderContent>
            </Container>
        </Header>
    );
});

export const HeaderPortal = {
    ArrowIconWrp,
    BurgerMenuBtn,
    BurgerMenuContent,
    BurgerMenuWrp,
    Container,
    Header,
    HeaderContent,
    HeaderList,
    HeaderListItem,
    HeaderNotifItem,
    HeaderNotifList,
    ProfileWrp,
};
