import React from 'react';

import theme from '../../../theme';
import {InputAdornment,} from '../index';
import {IInputAdornment} from '../InputAdornment/InputAdornment';

const InputAdornmentFilled = ({...props}: IInputAdornment): JSX.Element => {
    return (
        <InputAdornment
            {...props}
            variant="filled"
            sx={{
                '.MuiInputAdornment-root': {
                    mt: '0 !important',
                    mr: '12px',
                    zIndex: 5
                },
                '&.MuiInputBase-root': {
                    height: '48px',
                    backgroundColor: theme.palette.lightGray.light,
                    borderRadius: '8px',
                    padding: '14px 16px'
                },
                '&::before': {
                    borderBottom: 'none !important'
                },
                '&::after': {
                    borderBottom: 'none'
                },
                '.MuiInputBase-input': {
                    padding: 0,
                    backgroundColor: 'inherit',
                    '&::placeholder': {
                        color: theme.palette.gray.main + '!important',
                        opacity: 1
                    }
                }
            }}
        />
    );
};

export default InputAdornmentFilled;
