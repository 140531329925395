import React from 'react';

import {Box} from '@mui/material';

import {useAuth} from '../../../hooks';
import {CardSubTitle, CardTitle} from '../../Card/Card';

export const MyCurrentMatchesEmpty = React.memo(() => {
    const {isClient} = useAuth();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <div>
                <img src="/assets/images/glancing-eyes-emoji.png" width="64" height="64" alt="glancing eyes emoji"/>
            </div>
            <CardTitle
                sx={{
                    mb: '2px',
                    mt: '32px',
                    fontSize: '16px',
                    lineHeight: 1.5
                }}
            >
                No matches yet
            </CardTitle>
            <CardSubTitle>
                {isClient
                    ? 'As soon as you create a role, your current matches will show up here.'
                    : 'We are working on finding some great clients for you'
                }
            </CardSubTitle>
        </Box>
    );
});
