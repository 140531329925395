import React, {FC, useState} from 'react';

import {Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {ArrowDown, QuotesIcon} from '../../../assets/icons';
import {LG, MD} from '../../../constants';
import {ICaseStudy} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight} from '../../AnimateHeight/AnimateHeight';
import {ImagePlaceholder} from '../../index';

const Row = styled('div')`
  margin: 0 -24px;
  padding: 32px 24px;
  border-top: 1px solid ${theme.palette.lightGray.main};

  @media (min-width: ${MD}) {
    display: grid;
    column-gap: 40px;
    grid-template-columns: auto 72px;
    grid-template-areas: 
            'title companyBox'
            'description companyBox'
            'projectGoals .'
            'feedback .'
            'lessMoreBtn .'
  ;
  }

  @media (min-width: ${LG}) {
    column-gap: 148px;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;

  @media (min-width: ${MD}) {
    grid-area: title;
    font-size: 24px;
  }
`;

const Subtitle = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  svg {
    width: 24px;
    height: 24px;
  }

  path {
    fill: ${theme.palette.purple.main};
  }

  @media (min-width: ${MD}) {
    grid-area: subtitle;
  }
`;

const Description = styled(Typography)`
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.palette.textGray.dark};

  @media (min-width: ${MD}) {
    margin-bottom: 32px;
    grid-area: description;
  }
`;

/*const ProjectGoals = styled('p')`
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.palette.textGray.dark};
  white-space: pre-wrap;
`;*/

const Quote = styled('q')`
  display: block;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.palette.textGray.dark};
`;

const LessMoreBtn = styled('button')<{ isExpanded: boolean }>`
  background-color: unset;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  font: inherit;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.purple.main};
  cursor: pointer;

  svg {
    transform: ${props => props.isExpanded ? 'scaleY(-1)' : 'scaleY(1)'};
    transition: transform .3s;
  }

  path {
    fill: currentColor;
  }

  @media (min-width: ${MD}) {
    grid-area: lessMoreBtn;
  }
`;

interface IFreelancerCaseStudy {
    data: ICaseStudy;
}

const FreelancerCaseStudy: FC<IFreelancerCaseStudy> = ({data}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const handleExpandToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Row>
            <Title>
                {data.name}
            </Title>
            <Description>
                {data.brief}
            </Description>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    mb: '24px',
                    'img': {
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        [theme.breakpoints.up('md')]: {
                            width: '40px',
                            height: '40px',
                        }
                    },
                    [theme.breakpoints.up('md')]: {
                        flexDirection: 'column',
                        justifySelf: 'flex-end',
                        mt: '16px',
                        gridArea: 'companyBox'
                    }
                }}
            >
                <ImagePlaceholder width={mdUp ? '40' : ''} height={mdUp ? '40' : ''}>
                    {
                        data.companyLogoUrl && (
                            <img src={data.companyLogoUrl} width="40" height="40" alt={data.companyName}/>
                        )
                    }
                </ImagePlaceholder>
                <Typography
                    align="center"
                    sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: theme.palette.textGray.dark,
                    }}
                >
                    {data.companyName}
                </Typography>
            </Box>

            {/*<Box sx={{gridArea: 'projectGoals'}}>
                <Subtitle>
                    <CheckStatusIcon/> Project goals
                </Subtitle>
                <ProjectGoals>{data.goals}</ProjectGoals>
            </Box>*/}


            <Box sx={{gridArea: 'feedback'}}>
                <AnimateHeight isVisible={isExpanded}>
                    <Subtitle>
                        <QuotesIcon/> Client’s feedback
                    </Subtitle>
                    <Quote>{data.clientsReview}</Quote>

                    {
                        data.clientsName && (
                            <Typography
                                sx={{
                                    pb: '24px',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    color: theme.palette.blue.dark,
                                    'span': {
                                        display: 'block',
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        lineHeight: '20px',
                                        color: theme.palette.gray.main
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        gridArea: 'feedback'
                                    }
                                }}
                            >
                                {data.clientsName}
                                {data.clientsPosition && (
                                    <span>
                                        {data.clientsPosition}
                                    </span>
                                )}

                            </Typography>
                        )
                    }

                </AnimateHeight>
            </Box>
            {
                data.clientsReview && (
                    <LessMoreBtn isExpanded={isExpanded} onClick={handleExpandToggle}>
                        {isExpanded ? 'Less' : 'More'}
                        <ArrowDown/>
                    </LessMoreBtn>
                )
            }
        </Row>
    );
};

export default React.memo(FreelancerCaseStudy);
