import React, {useCallback, useState} from 'react';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import {DateRange} from 'mui-daterange-picker';

import {styled} from '@mui/material';

import {ArrowDown, CalendarIcon} from '../../assets/icons';
import {MD} from '../../constants';
import theme from '../../theme';
import {formatDate} from '../../utils';
import {Button} from '../Button';
import {InputDateRange} from '../Forms';

const DateButtonGroup = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 auto;
  padding: 12px 16px;
  background-color: ${theme.palette.white.main};
  border-radius: 50px;

  @media (min-width: ${MD}) {
    width: fit-content;
  }
`;

const DatePickerButton = styled(Button)`
  padding: 8px !important;
  min-width: 36px;
  min-height: 36px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.black.main};
  background-color: ${theme.palette.lightGray.light};
  border-radius: 8px;

  &:hover {
    color: ${theme.palette.black.main};
    background-color: ${theme.palette.lightGray.main};
  }

  path {
    fill: currentColor;
  }
`;

const MAX_DATE = new Date();

interface IDateRangeCalendarPicker {
    dateRange?: DateRange;
    endDate?: Date;
    maxDate?: Date;
    startDate?: Date;
    onDateRange: (dateRange?: DateRange) => void;
}

const DateRangeCalendarPicker: React.FC<IDateRangeCalendarPicker> = ({
    dateRange,
    endDate,
    maxDate,
    startDate,
    onDateRange,
}) => {
    const [open, setOpen] = useState(false);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleNextClick = useCallback(() => {
        const {startDate, endDate} = dateRange || {};

        if (!startDate || !endDate) return;

        const diff = differenceInDays(endDate, startDate);
        const newStartDate = addDays(endDate, 1);
        const newEndDate = addDays(endDate, diff + 1);
        const today = new Date();

        const isNextStart = differenceInDays(today, newStartDate) > 0;  // if in the future then we should stop
        const isNextEnd = differenceInDays(today, newEndDate) > 0;      // if in the future then we will set end date as today

        // the future is not available
        if (!isNextStart) {
            return;
        } else {
            const newDateRange: DateRange = {
                startDate: newStartDate,
                endDate: isNextEnd ? newEndDate : today,
            };

            onDateRange(newDateRange);
        }
    }, [dateRange, onDateRange]);

    const handlePrevClick = useCallback(() => {
        if (!startDate || !endDate) return;

        const diff = differenceInDays(endDate, startDate);

        const newDateRange: DateRange = {
            startDate: addDays(startDate, -1 * diff - 1),
            endDate: addDays(startDate, -1),
        };

        onDateRange(newDateRange);
    }, [endDate, onDateRange, startDate]);

    return (
        <DateButtonGroup>
            <DatePickerButton
                sx={{
                    color: theme.palette.gray.main,
                    'svg': {
                        transform: 'rotate(90deg)'
                    }
                }}
                onClick={handlePrevClick}
            >
                <ArrowDown/>
            </DatePickerButton>
            <DatePickerButton
                sx={{
                    width: '100%',
                    minWidth: '96px',
                    p: '8px 12px',
                    [theme.breakpoints.up('md')]: {
                        width: 'auto'
                    }
                }}
                onClick={() => setOpen(!open)}
            >
                {startDate && endDate
                    ? `${formatDate(startDate)} – ${formatDate(endDate)}`
                    : 'All time'
                }
                <CalendarIcon/>
            </DatePickerButton>
            <InputDateRange
                initialDateRange={dateRange}
                open={open}
                maxDate={maxDate ? maxDate : MAX_DATE}
                onChange={onDateRange}
                onClose={handleClose}
            />
            <DatePickerButton
                sx={{
                    color: theme.palette.gray.main,
                    'svg': {
                        transform: 'rotate(-90deg)'
                    }
                }}
                onClick={handleNextClick}
            >
                <ArrowDown/>
            </DatePickerButton>
        </DateButtonGroup>
    );
};

export default React.memo(DateRangeCalendarPicker);
