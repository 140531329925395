import React, {useCallback} from 'react';

import {
    Autocomplete,
    Box,
    CircularProgress,
    FilterOptionsState,
    Grid,
    styled,
    TextField,
    useTheme
} from '@mui/material';

import {CheckboxIcon, EnterIcon} from '../../assets/icons';
import {DELAY_API_CALL, LG} from '../../constants';
import {useDebounce} from '../../hooks';
import {OptionType as BaseOptionType} from '../../models';
import theme from '../../theme';
import {equalMemo} from '../../utils/utils';

import {useOptions} from './options.hook';

const MINIMUM_CHARACTERS = 3;

interface OptionType extends BaseOptionType {
    inputValue?: string;
    logo?: string;
}

export const PressEnterCaption = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.palette.gray.main};

  svg {
    flex-shrink: 0;
  }

  span {
    span {
      display: none;

      @media (min-width: ${LG}) {
        display: inline;
      }
    }
  }
`;

interface IProp {
    id?: string;
    isLogo?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    freeSolo?: boolean;
    placeholder: string;
    pressCaption?: string;
    value: OptionType[];
    apiMethod: (q: string) => Promise<OptionType[]>;
    onChange: (value: OptionType | null) => void;
}

const AutocompleteAsync: React.FC<IProp> = ({
    id,
    isLogo,
    freeSolo,
    placeholder,
    pressCaption,
    apiMethod,
    onChange,
    ...props
}) => {
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState<OptionType | null | string>('');

    const debouncedSearch = useDebounce(inputValue, DELAY_API_CALL, MINIMUM_CHARACTERS);
    const theme = useTheme();

    const {isFetching, data: options, /* isError, error */} = useOptions(apiMethod, id, debouncedSearch);

    const handleChange = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: string | OptionType | null) => {
        if (!newValue) {
            return;
        }

        if (freeSolo) {
            // add new freeSolo item
            if (typeof newValue === 'string') {
                onChange({name: newValue});

                // select freeSolo item from the list
            } else if (!newValue.id) {
                onChange({name: newValue.inputValue as string});

                // select item from the list
            } else {
                const {id, name, logo} = newValue;

                onChange({id, name, logo});
            }
        } else {
            const {id, name} = newValue as OptionType;

            onChange({id, name});
        }

        setInputValue('');

        // fix clear TextField if user don't type nothing for search
        if (!inputValue) {
            setValue(Date.now() + '');
            setTimeout(() => {
                setValue('');
            }, 0);
        } else {
            setValue(null);
        }
    }, [freeSolo, inputValue, onChange]);

    const handleFilter = useCallback((options: OptionType[], params: FilterOptionsState<OptionType>) => {
        const filtered = [...options];

        const {inputValue} = params;
        // Suggest the creation of a new value
        const isExisting = options?.some((option) => inputValue === option.name);

        if (freeSolo && inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
            });
        }

        return filtered;
    }, [freeSolo]);

    const handleOptionLabel = useCallback((option: string | OptionType) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "***" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }

        // Regular option
        return option.name + option.id;
    }, []);

    const isSelected = useCallback((id?: number) => props.value?.find(option => option.id === id), [props.value]);

    return (
        <Autocomplete
            blurOnSelect
            clearOnBlur
            handleHomeEndKeys
            id={id}
            filterOptions={handleFilter}
            freeSolo={freeSolo}
            fullWidth
            getOptionLabel={handleOptionLabel}
            noOptionsText=""
            options={options || []}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isFetching ? (
                                    <CircularProgress color="inherit" size={20} sx={{opacity: 0.5}}/>
                                ) : params.InputProps.endAdornment}
                                {pressCaption && <PressEnterCaption>
                                    <EnterIcon/>
                                    <span>Press Enter <span>to add a {pressCaption}</span></span>
                                </PressEnterCaption>}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                    sx={{
                        '.MuiInput-root': {
                            '&::before': {
                                borderBottom: '2px solid',
                                borderBottomColor: theme.palette.inputDisabledBorderColor.main
                            },
                            '&:hover': {
                                '&::before': {
                                    borderBottomColor: theme.palette.primary.main
                                }
                            },
                            '.MuiInput-input': {
                                padding: '0px 4px 8px 0px'
                            }
                        }
                    }}
                    placeholder={placeholder}
                    variant="standard"
                />
            )}
            renderOption={(props, option) => (
                <Box
                    {...props}
                    component="li"
                    sx={{
                        '& > span': {flexGrow: 1},
                        '& img': {maxHeight: '100%', maxWidth: '100%'},
                        color: isSelected(option.id) ? 'primary.main' : 'default',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    {isLogo && option.id ? (
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1} sx={{display: 'flex'}}>
                                {option.logo && (
                                    <img
                                        loading="lazy"
                                        src={option.logo}
                                        alt={'brand logo ' + option.name}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                {option.name}
                            </Grid>
                        </Grid>
                    ) : (
                        <span>
                            {option.name}
                        </span>
                    )}

                    {isSelected(option.id) ?
                        <CheckboxIcon fill={theme.palette.primary.main} height={22} width={22}/> : null}
                </Box>
            )}
            selectOnFocus
            sx={{mb: 3}}
            value={value}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            onChange={handleChange}
        />
    );
};

export default React.memo(AutocompleteAsync, equalMemo(['inputRef']));
