import React, {RefObject, useContext, useEffect, useRef} from 'react';
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone';

import {Box, styled} from '@mui/material';

import {UploadColorfulIcon} from '../../../../assets/icons';
import {ACCEPTED_FILES_TYPES} from '../../../../constants';
import {TwilioContext} from '../../../../contexts';
import {useMeasure} from '../../../../hooks';
import theme from '../../../../theme';
import {DropArea} from '../../../Forms/InputFile/InputFile';

const Wrapper = styled(DropArea)<{dragover: string; ref: RefObject<HTMLDivElement | null>}>`
    position: absolute;
    background: white;
    display: ${props => props.dragover === 'true' ? 'flex' : 'none'};

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: ${theme.palette.gray.main};
    }
`;

type DragDropAreaProps = {
    onDrop: (files: File[]) => void;
    onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => void;
};

const DragDropArea: React.FC<DragDropAreaProps> = ({onDrop, onDropRejected}) => {
    const {isDragActive} = useContext(TwilioContext);
    const ref = useRef<HTMLDivElement | null>(null);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const bounds = useMeasure(parentRef);

    const {getRootProps} = useDropzone({
        onDrop,
        onDropRejected,
        accept: ACCEPTED_FILES_TYPES
    });

    useEffect(() => {
        const el = ref.current;

        if (!el) return;

        const parent = el.parentElement;
        const [header, chat, bottom] = parent?.children as unknown as HTMLDivElement[];

        parentRef.current = parent as HTMLDivElement;

        el.style.top = `${header.offsetHeight}px`;
        el.style.height = `${chat.offsetHeight + bottom.offsetHeight}px`;
        el.style.width = `${header.offsetWidth}px`;
    }, [bounds]);

    return (
        <Wrapper
            {...getRootProps()}
            dragover={isDragActive.toString()}
            ref={ref}
        >
            <Box sx={{mb: 4}}>
                <UploadColorfulIcon/>
            </Box>

            Drag and drop the file you want to share here
            <span>
                JPEG, PNG, DOC, XSL and PDF files with max size of 5 MB
            </span>
        </Wrapper>
    );
};

export default React.memo(DragDropArea);
