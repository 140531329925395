import {CLIENT, CLIENT_MATCHES, GET_PROPOSALS, MEETINGS, PROPOSALS} from '../constants';
import {IMatchWithRecommendation, IMatchWithRole, IProposal, IProposalRequest} from '../models';
import {IMeeting} from '../models/meeting.model';

import axiosInstance from './base.api';


// set contacted flag
const chatContacted = async (conversationId: string) => {
    return await axiosInstance.patch<null>(`${CLIENT_MATCHES}/chat/${conversationId}/contacted`);
};

// you finish call
const chatFinishCall = async (conversationId: string) => {
    return await axiosInstance.delete<{conversationId: string}>(`${CLIENT_MATCHES}/chat/${conversationId}/call`);
};

// you join call
const chatJoinCall = async (conversationId: string) => {
    return await axiosInstance.patch<{conversationId: string}>(`${CLIENT_MATCHES}/chat/${conversationId}/call`);
};

// 5 minutes lapses (heart beat)
const chatHeartBeat = async (conversationId: string) => {
    return await axiosInstance.get<{conversationId: string}>(`${CLIENT_MATCHES}/chat/${conversationId}/call`);
};

// start new call (differentiated by audio/video)
const chatStartCall = async (conversationId: string, isVideo: boolean) => {
    return await axiosInstance.post<{conversationId: string}>(`${CLIENT_MATCHES}/chat/${conversationId}/call?video=${isVideo}`);
};

// init twillio conversation
// add 2 participants. Client and Freelancer
const getChatRoom = async (matchId: number) => {
    const response = await axiosInstance.get<{conversationId: string}>(`${CLIENT_MATCHES}/${matchId}/chat`);

    return response.data.conversationId;
};

const getMatch = async (matchId: number) => {
    return await axiosInstance.get<IMatchWithRecommendation>(`${CLIENT_MATCHES}/${matchId}`);
};

const getMatchWithRole = async (matchId: number) => {
    return await axiosInstance.get<IMatchWithRole>(`${CLIENT_MATCHES}/chat/profile/${matchId}`);
};

const getProposal = async (proposalId: number) => {
    return await axiosInstance.get<IProposal>(`${GET_PROPOSALS}/${proposalId}`);
};

const meetingAccept = async (meetingId: number) => {
    return await axiosInstance.patch<{id: number}>(`${CLIENT}/${MEETINGS}/${meetingId}`);
};

const meetingReject = async (meetingId: number) => {
    return await axiosInstance.delete<{id: number}>(`${CLIENT}/${MEETINGS}/${meetingId}`);
};

const meetingSubmit = async (conversationId: string, payload: Partial<IMeeting>) => {
    return await axiosInstance.post<{id: number}>(`${CLIENT_MATCHES}/chat/${conversationId}/${MEETINGS}`, payload);
};

const meetingUpdate = async (meetingId: number, payload: Partial<IMeeting>) => {
    return await axiosInstance.put<{id: number}>(`${CLIENT}/${MEETINGS}/${meetingId}`, payload);
};

const proposalAccept = async (proposalId: number) => {
    return await axiosInstance.patch<IProposal>(`${GET_PROPOSALS}/${proposalId}`);
};

const proposalReject = async (proposalId: number) => {
    return await axiosInstance.delete<IProposal>(`${GET_PROPOSALS}/${proposalId}`);
};

const proposalSubmit = async (matchId: number, payload: IProposalRequest) => {
    return await axiosInstance.post<{id: number}>(`${CLIENT_MATCHES}/${matchId}/${PROPOSALS}`, payload);
};

const rejectMatch = async (matchId: number, reason: string) => {
    return await axiosInstance.delete<null>(`${CLIENT_MATCHES}/${matchId}`, {data: {reason}});
};

const setMatcheLike = async (matchId: number) => {
    return await axiosInstance.post<null>(`${CLIENT_MATCHES}/${matchId}/like`);
};

const setMatcheUnLike = async (matchId: number) => {
    return await axiosInstance.delete<null>(`${CLIENT_MATCHES}/${matchId}/like`);
};

export const matchesService = {
    chatContacted,
    chatFinishCall,
    chatJoinCall,
    chatHeartBeat,
    chatStartCall,
    getChatRoom,
    getMatch,
    getMatchWithRole,
    getProposal,
    meetingAccept,
    meetingReject,
    meetingSubmit,
    meetingUpdate,
    proposalAccept,
    proposalReject,
    proposalSubmit,
    rejectMatch,
    setMatcheLike,
    setMatcheUnLike,
};
