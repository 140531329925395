import React, {useEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import {useLocation} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';

import {notificationService} from '../api';
import {showToastError} from '../components';
import {NOTIFICATIONS, NOTIFICATIONS_BADGE, RouteClientPortal, RouteFreelancerPortal} from '../constants';
import {INotification} from '../models';
import {linkify} from '../utils';

export const PAGE_SIZE = 15;

export enum ActionNotifacations {
    ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS',
    SET_NOTIFICATION_READ = 'SET_NOTIFICATION_READ',

    SET_BADGE = 'SET_BADGE',
    SET_OPEN = 'SET_OPEN',
    SET_NEXT_PAGE = 'SET_NEXT_PAGE',
}

type State = {
    badge: number;
    items: INotification[];
    openAnchorEl: HTMLElement | null;
    page: number;
    total: number;
};

type IActionAddNotifications = {
    type: ActionNotifacations.ADD_NOTIFICATIONS;
    payload: {
        items: INotification[];
        total: number;
    };
};

type IActionReadNotification = {
    type: ActionNotifacations.SET_NOTIFICATION_READ;
    payload: INotification;
};

type IActionSetBadge = {
    type: ActionNotifacations.SET_BADGE;
    payload: number;
};

type IActionSetOpen = {
    type: ActionNotifacations.SET_OPEN;
    payload: HTMLElement | null;
};

type IActionSetNextPage = {
    type: ActionNotifacations.SET_NEXT_PAGE;
};


type Action = IActionAddNotifications | IActionReadNotification | IActionSetBadge | IActionSetOpen | IActionSetNextPage;

type Dispatch = (action: Action) => void;

const initialState: State = {
    badge: 0,
    items: [],
    openAnchorEl: null,
    page: 0,
    total: 0,
};

type NotificationContextProviderProps = { children: React.ReactNode };

const NotificationContext = React.createContext<
{ state: State; dispatch: Dispatch } | undefined>(undefined);

const stateReducer = (state: State, action: Action): State => {
    // console.log(action, state);
    switch (action.type) {
        case ActionNotifacations.ADD_NOTIFICATIONS: {
            const {items, total} = action.payload;

            items.forEach(it => {
                const notification = state.items.find(prev => prev.id === it.id);

                it.body = linkify(it.body); // replace plain URLs with links

                if (notification) {
                    Object.assign(notification, it);
                } else {
                    state.items.push(it);
                }
            });

            return {
                ...state,
                items: [...state.items],
                total
            };
        }

        case ActionNotifacations.SET_NOTIFICATION_READ: {
            const items = state.items.map(it => it.id === action.payload.id ? {...it, read: true} : it);

            return {
                ...state,
                items,
            };
        }

        case ActionNotifacations.SET_BADGE: {
            return {
                ...state,
                badge: action.payload
            };
        }

        case ActionNotifacations.SET_NEXT_PAGE: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case ActionNotifacations.SET_OPEN: {
            return {
                ...state,
                openAnchorEl: action.payload,
                items: [],
                page: 0
            };
        }

        default: {
            throw new Error('Unhandled action type');
        }
    }
};

const NotificationContextProvider = ({children}: NotificationContextProviderProps) => {
    const [state, dispatch] = React.useReducer(stateReducer, initialState);
    const value = {state, dispatch};
    const {pathname} = useLocation();

    const isBadge = useMemo(() => pathname.startsWith(RouteClientPortal.default) || pathname.startsWith(RouteFreelancerPortal.default), [pathname]);

    // polling every 10 sec
    /*const {data, total, isLoading, error} = */ useQuery(
        [NOTIFICATIONS_BADGE],
        notificationService.getBadge,
        {
            enabled: isBadge,
            refetchInterval: 10 * 1000,
            select: (data: {data: {badge: number}}) => data.data.badge,
            onSuccess: (badge) => {
                if (badge !== state.badge) {
                    dispatch({type: ActionNotifacations.SET_BADGE, payload: badge});
                }
            },
            onError: (error) => showToastError(error as AxiosError)
        }
    );

    const {refetch /* data, total, isLoading, error*/} = useQuery(
        [NOTIFICATIONS],
        () => notificationService.getNotifications({
            pagination: {page: state.page + 1, perPage: PAGE_SIZE},
            sort: {field: 'id', order: 'DESC'}
        }),
        {
            staleTime: 0,
            enabled: !!state.openAnchorEl,
            onSuccess: (response) => {
                const {data, total} = response;

                dispatch({type: ActionNotifacations.ADD_NOTIFICATIONS, payload: {items: data, total: total || 0}});
            }
        }
    );

    useEffect(() => {
        if (!state.page) return;
        refetch();
    }, [state.page, refetch]);

    return (
        <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
    );
};


const useNotification = () => {
    const context = React.useContext(NotificationContext);

    if (context) {
        return context;
    }

    throw new Error('useStateContext must be used within a StateContextProvider');
};

export {NotificationContextProvider, useNotification};
