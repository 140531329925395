import React, {FC, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {any, object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Theme, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {authService, emailService} from '../../api';
import {BlackBorderButton, ContinueButton, DialogEmoji, Input, showToastError} from '../../components';
import {useAuth} from '../../hooks';
import {EVerificationStatus, IEmailVerificationProcess} from '../../models/verification-proccess';
import {findFieldError} from '../../utils';
import {Digits} from '../ConfirmationCode/components';

const emailSchema = object({
    email: string().email()
});

type EmailInput = TypeOf<typeof emailSchema>;

const codeSchema = object({
    digit1: string().min(1, ' '),
    digit2: string().min(1, ' '),
    digit3: string().min(1, ' '),
    digit4: string().min(1, ' '),
    digit5: string().min(1, ' '),
    serverSideError: any(),
});

type DigitsInput = TypeOf<typeof codeSchema>;

interface IProps {
    disabled: boolean;
    verification?: IEmailVerificationProcess;
    onChange: () => void;
}

const ChangeEmailBtn: FC<IProps> = ({disabled, verification, onChange}) => {
    const [isCodeResent, setResent] = useState(false);
    const [open, setOpen] = useState(false);
    const [openVerify, setOpenVerify] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const {refreshUser} = useAuth();

    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const methods = useForm<EmailInput>({
        resolver: zodResolver(emailSchema),
    });

    const {
        handleSubmit,
        setError,
    } = methods;

    const methodsCode = useForm<DigitsInput>({
        resolver: zodResolver(codeSchema),
    });

    const {
        handleSubmit: handleSubmitCode,
        reset: resetCode,
        setError: setErrorCode,
    } = methodsCode;

    const {mutate: mutateEmail, isLoading: isSubmittingEmail} = useMutation(emailService.updateEmail,
        {
            onSuccess: async () => {
                setOpen(false);
                setTimeout(() => {
                    setOpenVerify(true);
                }, 500);
                onChange();   // refetch User for getting new verifiaction process id
            },
            onError(error: AxiosError) {
                const email = findFieldError(error, 'email');

                console.log('error', error);
                if (email) {
                    setError('email' as any, {type: 'custom', message: email.message});
                } else {
                    showToastError(error);
                }
            }
        }
    );

    const {mutate: mutateCode, isLoading: isSubmittingCode} = useMutation(
        (code: string) => emailService.verifyCode(verification?.id || 0, code),
        {
            onSuccess: async () => {
                setOpenVerify(false);
                setTimeout(() => {
                    setOpenSuccess(true);
                }, 500);

                await authService.refreshAccessToken();
                refreshUser();
            },
            onError(error: AxiosError) {
                console.log('error', error);
                if (error.response?.status === 409) {
                    setErrorCode('serverSideError', {type: 'custom', message: 'Verification code doesn’t match. Please try again'});
                    setErrorCode('digit1', {type: 'custom', message: ' '});
                    setErrorCode('digit2', {type: 'custom', message: ' '});
                    setErrorCode('digit3', {type: 'custom', message: ' '});
                    setErrorCode('digit4', {type: 'custom', message: ' '});
                    setErrorCode('digit5', {type: 'custom', message: ' '});
                } else {
                    showToastError(error);
                }
            }
        }
    );

    const isSubmitting = isSubmittingEmail || isSubmittingCode;

    const handleClose = () => {
        setOpen(false);
    };

    // if code not expired skip email step
    // and asked for code
    const handleOpenClick = () => {
        console.log('open');
        const isCode = [EVerificationStatus.EMAIL_SENT].includes(verification?.status as EVerificationStatus);

        if (!verification || !isCode) {
            setOpen(true);
        } else {
            setOpenVerify(true);
        }
    };

    const handleSuccessClose = () => {
        setOpenSuccess(false);
        onChange();
    };

    const handleResendCode = () => {
        setResent(true);
        emailService.resendCode(verification?.id || 0);
    };

    const onSubmitHandler: SubmitHandler<EmailInput> = ({email}) => {
        mutateEmail(email);
    };

    const onSubmitCodeHandler: SubmitHandler<any> = (values) => {
        const code = Object.values(values).join('');

        console.log('submit', code);
        mutateCode(code);
    };

    useEffect(() => {
        resetCode({});
    }, [openVerify, resetCode]);

    return (
        <>
            <BlackBorderButton
                disabled={disabled}
                size="small"
                onClick={handleOpenClick}
            >
                Change
            </BlackBorderButton>

            {/* Input new EMAIL */}
            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            disabled={isSubmitting}
                            fullWidth={mdDown}
                            onClick={handleClose}
                        >
                            Cancel
                        </BlackBorderButton>

                        <ContinueButton
                            fullWidth={mdDown}
                            disabled={isSubmitting}
                            sx={{width: mdDown ? '100%' : 'auto !important'}}
                            variant="contained"
                            onClick={handleSubmit(onSubmitHandler)}
                        >
                            Change email
                        </ContinueButton>
                    </>
                )}
                maxWidth="sm"
                open={open}
                subtitle="Enter a new email below. We will send you a verification code to confirm new email address"
                title={(
                    <>
                        <img src="/assets/images/envelope-icon.png" width="48" height="48" alt="sad icon"/>
                        Change email
                    </>
                )}
                onClose={handleClose}
            >
                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                    >
                        <Input
                            name="email"
                            placeholder="Email"
                        />
                    </form>
                </FormProvider>

            </DialogEmoji>

            {/* Input 5 digits verification code */}
            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            disabled={isSubmitting}
                            fullWidth={mdDown}
                            onClick={() => setOpenVerify(false)}
                        >
                            Cancel
                        </BlackBorderButton>

                        <ContinueButton
                            fullWidth={mdDown}
                            disabled={isSubmitting}
                            sx={{width: mdDown ? '100%' : 'auto !important'}}
                            variant="contained"
                            onClick={handleSubmitCode(onSubmitCodeHandler)}
                        >
                            Verify email
                        </ContinueButton>
                    </>
                )}
                maxWidth="sm"
                open={openVerify}
                subtitle={`We’ve sent a confirmation code to your email address ${verification?.newEmail}. Please enter the code below`}
                title={(
                    <>
                        <img src="/assets/images/envelope-icon.png" width="48" height="48" alt="sad icon"/>
                        Please verify your email
                    </>
                )}
                onClose={() => setOpenVerify(false)}
            >
                <FormProvider {...methodsCode}>
                    <form
                        noValidate
                        autoComplete="off"
                    >
                        <Box
                            sx={{mb: 4}}
                        >
                            <Digits
                                isCodeResent={isCodeResent}
                                isSubmitting={isSubmitting}
                                onResendCode={handleResendCode}
                            />

                        </Box>
                    </form>
                </FormProvider>
            </DialogEmoji>

            {/* Show SUCCESS message */}
            <DialogEmoji
                actions={(
                    <>
                        <ContinueButton
                            fullWidth={mdDown}
                            sx={{width: mdDown ? '161px !important' : 'auto !important'}}
                            variant="contained"
                            onClick={handleSuccessClose}
                        >
                            Done
                        </ContinueButton>
                    </>
                )}
                maxWidth="sm"
                open={openSuccess}
                subtitle={`Your new email is ${verification?.newEmail}`}
                title={(
                    <>
                        <img src="/assets/images/envelope-icon.png" width="48" height="48" alt="sad icon"/>
                        Email changed successfully
                    </>
                )}
                onClose={handleSuccessClose}
            >
            </DialogEmoji>
        </>
    );
};

export default ChangeEmailBtn;
