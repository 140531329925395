import React, {useMemo} from 'react';
import {motion} from 'framer-motion';

import {Box} from '@mui/material';

import {useAuth} from '../../hooks';
import {EPaymentMethod, EPaymentMethodStatus, IPaymentPreview} from '../../models';
import LoadingMask from '../LoadingMask';

import PaymentMethodRow from './PaymentMethodRow';

type PaymentMethodsListProps = {
    isLoading: boolean;
    items?: IPaymentPreview[];
};

const PaymentMethodsList: React.FC<PaymentMethodsListProps> = ({isLoading, items}) => {
    const {isImpersonal} = useAuth();

    // Pay by Invoce set by SuperAdmin in the Admin panel
    // so don't allow Client delete it by himself
    const verifiedMethods = useMemo(() => items
        ? items.filter(item => item.status === EPaymentMethodStatus.VERIFIED && item.method !== EPaymentMethod.ManualInvoicing)
        : []
    , [items]);

    if (isLoading) {
        return (
            <LoadingMask/>
        );
    }

    return (
        <Box
            sx={{
                display: 'grid',
                gap: '8px',
                mb: '16px'
            }}
        >
            {items?.map(item => (
                <motion.div
                    key={item.id}
                    layout
                >
                    <PaymentMethodRow
                        data={item}
                        isDeleteBtn={verifiedMethods?.length >= 2 && item.method !== EPaymentMethod.ManualInvoicing && !isImpersonal}
                    />
                </motion.div>

            ))}

        </Box>
    );
};

export default React.memo(PaymentMethodsList);
