import React from 'react';

const MicOffIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8017 8.19992C15.6701 8.06795 15.5136 8.00195 15.333 8.00195C15.1526 8.00195 14.9964 8.06795 14.8643 8.19992C14.7325 8.33184 14.6665 8.48807 14.6665 8.66855V10.0019C14.6665 11.2867 14.2098 12.3855 13.2966 13.2987C12.3837 14.2119 11.2847 14.6685 9.99991 14.6685C8.71516 14.6685 7.61626 14.2119 6.70303 13.2987C5.78991 12.3858 5.33338 11.2867 5.33338 10.0019V8.66855C5.33338 8.48807 5.26738 8.33184 5.13549 8.19992C5.00353 8.06795 4.84745 8.00195 4.66678 8.00195C4.48611 8.00195 4.32985 8.06795 4.19796 8.19992C4.06596 8.33184 4 8.48807 4 8.66855V10.0019C4 11.5366 4.51224 12.8717 5.53642 14.007C6.56063 15.1423 7.82623 15.7933 9.33324 15.9599V17.335H6.66665C6.48609 17.335 6.32987 17.401 6.19794 17.533C6.06598 17.6649 5.99998 17.8212 5.99998 18.0017C5.99998 18.182 6.06598 18.3386 6.19794 18.4704C6.32987 18.6023 6.48609 18.6685 6.66665 18.6685H13.333C13.5135 18.6685 13.6699 18.6023 13.8017 18.4704C13.9338 18.3386 13.9998 18.182 13.9998 18.0017C13.9998 17.8212 13.9338 17.6649 13.8017 17.533C13.67 17.401 13.5135 17.335 13.333 17.335H10.6667V15.9599C12.1735 15.7933 13.439 15.1423 14.4633 14.007C15.4876 12.8717 16 11.5366 16 10.0019V8.66855C16 8.4881 15.9339 8.33199 15.8017 8.19992Z"
                fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M6.66669 5.49252V9.9995C6.66669 10.9162 6.99319 11.7011 7.64585 12.3536C8.29852 13.0061 9.08329 13.3325 9.99995 13.3325C10.9167 13.3325 11.7013 13.0061 12.354 12.3536C12.5593 12.1484 12.7322 11.9301 12.8729 11.6988L6.66669 5.49252ZM13.333 9.50716V4.66637C13.333 3.74967 13.0069 2.96505 12.354 2.31224C11.7013 1.65958 10.9167 1.33301 9.99995 1.33301C9.08329 1.33301 8.29863 1.65958 7.64585 2.31224C7.37819 2.57986 7.16541 2.86967 7.0075 3.18168L13.333 9.50716Z"
                fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M3.08709 3.08709C3.4532 2.72097 4.0468 2.72097 4.41291 3.08709L16.9129 15.5871C17.279 15.9532 17.279 16.5468 16.9129 16.9129C16.5468 17.279 15.9532 17.279 15.5871 16.9129L3.08709 4.41291C2.72097 4.0468 2.72097 3.4532 3.08709 3.08709Z"
                fill="white"/>
        </svg>
    );
};

export default MicOffIcon;
