import React from 'react';

const InfoIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2849_3493)">
                <path
                    d="M13.6039 16.9089C13.3585 16.9318 13.1122 16.8765 12.9002 16.7507C12.7454 16.5917 12.6711 16.371 12.6984 16.1507C12.7042 15.9674 12.726 15.7848 12.7639 15.6053C12.8005 15.3993 12.8478 15.1954 12.9057 14.9944L13.5493 12.7798C13.6157 12.5612 13.6596 12.3364 13.6802 12.1089C13.6802 11.8635 13.7129 11.6943 13.7129 11.5962C13.7266 11.1587 13.5398 10.7389 13.2057 10.4562C12.7946 10.1406 12.2827 9.98551 11.7657 10.0198C11.3951 10.0254 11.0274 10.0861 10.6747 10.1998C10.2893 10.3198 9.88382 10.4634 9.45839 10.6307L9.27295 11.3507C9.39839 11.3071 9.55115 11.258 9.72567 11.2035C9.89217 11.1541 10.0648 11.1284 10.2384 11.1271C10.4819 11.1007 10.7271 11.1605 10.9311 11.2962C11.0697 11.4615 11.1347 11.6763 11.1111 11.8907C11.1105 12.0742 11.0904 12.257 11.0511 12.4362C11.0129 12.6271 10.9638 12.8289 10.9038 13.0416L10.2547 15.2671C10.2024 15.4739 10.1606 15.6833 10.1293 15.8943C10.1038 16.075 10.0911 16.2573 10.0911 16.4398C10.0884 16.8803 10.2899 17.2972 10.6365 17.5689C11.0539 17.8893 11.5731 18.0482 12.0983 18.0161C12.4682 18.0237 12.8368 17.9703 13.1893 17.8579C13.4984 17.7525 13.9111 17.6016 14.4275 17.4052L14.602 16.7179C14.4622 16.776 14.3181 16.8234 14.1711 16.8597C13.9852 16.9022 13.7943 16.9187 13.6039 16.9089Z"
                    fill="#EF462C"/>
                <path
                    d="M14.2855 6.40909C13.9887 6.13647 13.5976 5.98979 13.1946 6.00001C12.7919 5.99092 12.4012 6.13745 12.1037 6.40909C11.5585 6.87925 11.4976 7.70242 11.9678 8.24772C12.0097 8.29632 12.0551 8.34175 12.1037 8.38365C12.7249 8.93926 13.6644 8.93926 14.2855 8.38365C14.8307 7.90886 14.8879 7.08198 14.4131 6.53673C14.3736 6.49131 14.331 6.44865 14.2855 6.40909Z"
                    fill="#EF462C"/>
                <path
                    d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 22.9091C5.97506 22.9091 1.09092 18.0249 1.09092 12C1.09092 5.97506 5.97506 1.09092 12 1.09092C18.0249 1.09092 22.9091 5.97506 22.9091 12C22.9091 18.0249 18.0249 22.9091 12 22.9091Z"
                    fill="#EF462C"/>
            </g>
            <defs>
                <clipPath id="clip0_2849_3493">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default InfoIcon;
