import React from 'react';

import {FormControlLabel, Switch as MUISwitch, SwitchProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import theme from '../../../theme';

interface ISwitch {
    label: React.ReactNode;
    labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
    sx?: SxProps<Theme>;
}


const Switch = ({label, labelPlacement = 'start', sx, ...props}: ISwitch & SwitchProps) => {
    return (
        <FormControlLabel
            control={
                <MUISwitch
                    {...props}
                    disableRipple
                    sx={{
                        width: 42,
                        height: 26,
                        padding: 0,
                        '& .MuiSwitch-switchBase': {
                            padding: 0,
                            margin: '2px',
                            transitionDuration: '300ms',
                            '&.Mui-checked': {
                                transform: 'translateX(16px)',
                                color: '#fff',
                                '& + .MuiSwitch-track': {
                                    backgroundColor: theme.palette.purple.main,
                                    opacity: 1,
                                    border: 0,
                                },
                                '&.Mui-disabled + .MuiSwitch-track': {
                                    opacity: 0.5,
                                },
                            },
                            '&.Mui-focusVisible .MuiSwitch-thumb': {
                                color: theme.palette.purple.main,
                                border: '6px solid #fff',
                            },
                            '&.Mui-disabled .MuiSwitch-thumb': {
                                color:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[600],
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                            },
                        },
                        '& .MuiSwitch-thumb': {
                            boxSizing: 'border-box',
                            width: 22,
                            height: 22,
                        },
                        '& .MuiSwitch-track': {
                            borderRadius: 26 / 2,
                            backgroundColor: theme.palette.gray.light,
                            opacity: 1,
                            transition: theme.transitions.create(['background-color'], {
                                duration: 500,
                            }),
                        },
                    }}
                />
            }
            label={label}
            labelPlacement={labelPlacement || 'start'}
            sx={{
                '.MuiTypography-root': {
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                },
                '.Mui-disabled.MuiFormControlLabel-label': {
                    color: theme.palette.gray.main
                },
                ...sx
            }}
        />
    );
};

export default Switch;
