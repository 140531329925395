import React, {FC, useCallback} from 'react';
import {AxiosError} from 'axios';
import {motion} from 'framer-motion';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, IconButton, styled, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../../../api';
import {CloseIconBig} from '../../../../../assets/icons';
import {
    BlackBorderButton,
    LoadingMask,
    SeniorityLevelRange,
    showToastError,
    showToastSuccess,
    WeeklyHoursRange,
} from '../../../../../components';
import {animationFade} from '../../../../../components/Animations';
import {CLIENT_ROLES, COMPLETED, LG, PROJECT, RouteClientPortal} from '../../../../../constants';
import {useAuth} from '../../../../../hooks';
import {ERoleStatus} from '../../../../../models';
import theme from '../../../../../theme';
import {formatDate} from '../../../../../utils';
import {useRoles} from '../../../contexts/roles.context';
import RoleStatus from '../RoleItem/RoleStatus';

const OuterWrp = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 12px;
  z-index: 110;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, .3);

  @media (min-width: ${LG}) {
    position: sticky;
    top: 100px;
    max-width: 528px;
    flex-shrink: 0;
    padding: 0;
    z-index: 0;
    background-color: unset;
    overflow: hidden;
  }
`;

const Wrp = styled(motion.div)`
  margin: auto;
  max-width: 528px;
  border-radius: 24px;
  width: 100%;
  position: relative;
  background-color: ${theme.palette.white.main};
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);

  @media (min-width: ${LG}) {
    margin: 0;
    max-height: calc(100vh - 124px);
    overflow-y: auto;
  }
`;

const ListItem = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 16px 24px;
  border-top: 1px solid ${theme.palette.lightGray.main};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.palette.textGray.dark};
  text-align: right;

  span {
    flex-shrink: 0;
    font-weight: 600;
    color: ${theme.palette.black.main};
    text-align: left;
  }
`;

interface IProps {
    roleId: number;
}

const RoleItemDetails: FC<IProps> = ({roleId}) => {
    const {isImpersonal} = useAuth();
    const {filterBy} = useParams();
    const {publishRole} = useRoles();
    const navigate = useNavigate();

    const {
        data,
        isLoading
    } = useQuery(
        [CLIENT_ROLES, roleId],
        clientService.getRoleById(roleId), {
            staleTime: 60 * 1000,
            onError: (error) => showToastError(error as AxiosError)
        }
    );

    const prettifyList = useCallback((list: any[]) => {
        return list ? list.map(it => it.name).join(', ') : [];
    }, []);

    const handlePublish = async () => {
        try {
            await publishRole(roleId);
            showToastSuccess('Role was published successfully!');
            navigate(RouteClientPortal.myRoles);
        } catch (error) {
            showToastError(error as AxiosError);
        }
    };

    if (isLoading) {
        return (
            <OuterWrp>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '70vh',
                    width: '100%',
                    opacity: 0.2
                }}>
                    <LoadingMask/>
                </Box>
            </OuterWrp>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <OuterWrp>
            <Wrp
                {...animationFade(0)}
            >
                <Box
                    sx={{
                        p: '24px'
                    }}
                >
                    <IconButton
                        onClick={() => navigate(`${RouteClientPortal.myRoles}/${filterBy}`)}
                        sx={{
                            position: 'absolute',
                            top: '24px',
                            right: '24px',
                            width: '24px',
                            height: '24px'
                        }}
                    >
                        <CloseIconBig/>
                    </IconButton>
                    <Typography
                        sx={{
                            mb: '4px',
                            pr: '48px',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: 1.5,
                            color: theme.palette.black.main
                        }}
                    >
                        {data.projectName}
                    </Typography>
                    <Typography
                        sx={{
                            mb: '8px',
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.gray.main
                        }}
                    >
                        {data.status === ERoleStatus.Draft ? (
                            <>Created: {data.created && formatDate(data.created) || 'n/a'}</>
                        ) : (
                            <>Submitted: {data.submitted && formatDate(data.submitted) || 'n/a'}</>
                        )}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            gap: '16px',
                            cursor: 'pointer',
                        }}
                    >
                        <RoleStatus status={data.status} engagementId={data.engagementId}/>

                        {/* if it is Draft and all 6 steps are filled */}
                        {data.status === ERoleStatus.Draft && [COMPLETED, PROJECT].includes(data.view) && (
                            <BlackBorderButton
                                disabled={isImpersonal}
                                size="small"
                                variant="contained"
                                onClick={handlePublish}
                            >
                                Publish role
                            </BlackBorderButton>
                        )}
                    </Box>

                    <Typography
                        sx={{
                            mt: '24px',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: 1.7,
                            color: theme.palette.textGray.dark,
                            whiteSpace: 'pre-wrap'
                        }}
                    >
                        {data.projectDescription}
                    </Typography>
                </Box>
                <ListItem>
                    <span>
                        Channel
                    </span>
                    {data.channel?.name}
                </ListItem>
                <ListItem>
                    <span>
                        Sub-channels
                    </span>
                    {prettifyList(data.channel?.subchannels)}
                </ListItem>
                <ListItem>
                    <span>
                        Vertical
                    </span>
                    {prettifyList(data.verticals)}
                </ListItem>
                <ListItem>
                    <span>
                        Business Model
                    </span>
                    {data.businessModels?.join(', ') || ''}
                </ListItem>
                <ListItem>
                    <span>
                        Tools
                    </span>
                    {prettifyList(data.tools)}
                </ListItem>
                <ListItem>
                    <span>
                        Country
                    </span>
                    {data.countries?.join(', ') || ''}
                </ListItem>
                <ListItem>
                    <span>
                        Time Zone
                    </span>
                    {data.timezone}
                </ListItem>
                <ListItem>
                    <span>
                        Language
                    </span>
                    {data.languages?.join(', ') || ''}
                </ListItem>
                <ListItem>
                    <span>
                        Budget Type
                    </span>
                    {(data.workType?.charAt(0).toUpperCase() + data.workType?.substring(1).toLowerCase()) || ''}
                </ListItem>
                <ListItem>
                    <span>
                        Number of Hours
                    </span>
                    <WeeklyHoursRange hoursRange={data.expectedEngagementHours}/>
                </ListItem>
                <ListItem>
                    <span>
                        Seniority Level
                    </span>
                    <SeniorityLevelRange rateRange={data.rateRange}/>
                </ListItem>
            </Wrp>
        </OuterWrp>
    );
};

export default React.memo(RoleItemDetails);
