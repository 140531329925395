import React from 'react';

import PageTopTitle from '../PageTopTitle';

interface IProps {
    desktopFontSize?: number;
}

export const ClientTopTitleVerticals: React.FC<IProps> = React.memo(({desktopFontSize}) => {
    return (
        <PageTopTitle
            desktopFontSize={desktopFontSize}
            title={<>What <span>vertical</span> does a new hire need to specialize in</>}
            titleMaxWidth={{md: 358, lg: 621}}
        />
    );
});
