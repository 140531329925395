import React, {useCallback, useEffect, useMemo} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useNavigate, useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useInfiniteQuery} from '@tanstack/react-query';

import {clientService, GetListResult} from '../../../api';
import {ClientPortalTitle, LoadingMask, SelectSortBy, TabsStatusFilter} from '../../../components';
import {CLIENT_ENGAGEMENT, RouteClientPortal} from '../../../constants';
import {EEngagementFilter, EEngagementFilterDisplay, EEngagementSort, IEngagement} from '../../../models';
import theme from '../../../theme';

import {HiresList} from './components';

const PAGE_SIZE = 10;

const optionsFilter = [
    EEngagementFilter.All,
    EEngagementFilter.Active,
    EEngagementFilter.Archive
].map(option => ({text: EEngagementFilterDisplay[option], value: option}));

const MyHiresPage = () => {
    const {filterBy, sortBy} = useParams();
    const navigate = useNavigate();

    const {data, hasNextPage, isLoading, fetchNextPage} = useInfiniteQuery(
        [CLIENT_ENGAGEMENT, sortBy, filterBy],
        clientService.getHires('startDate', sortBy, filterBy || '', PAGE_SIZE),
        {
            enabled: !!sortBy && !!filterBy,
            getNextPageParam: (lastPage: GetListResult<IEngagement>) => {
                return lastPage.last ? false : lastPage.number + 1;
            },
        }
    );

    // collect all pages results
    const items = useMemo(() => {
        const result: IEngagement[] = [];

        data?.pages.forEach(it => result.push(...it.data));

        return result;
    }, [data]);

    const handleFilterChange = useCallback((filter: string) => {
        navigate(`${RouteClientPortal.myHires}/${sortBy}/${filter}`);
    }, [sortBy, navigate]);

    const handleSortChange = useCallback((sort: string) => {
        navigate(`${RouteClientPortal.myHires}/${sort}/${filterBy}`);
    }, [filterBy, navigate]);

    // by default set 'ALL' filter active
    useEffect(() => {
        if (!sortBy || !filterBy) {
            navigate(`${RouteClientPortal.myHires}/${EEngagementSort.DESC}/${EEngagementFilter.Active}`, {replace: true});
        }
    }, [filterBy, sortBy, navigate]);

    return (
        <>
            <Box
                sx={{
                    pt: '38px',
                    [theme.breakpoints.up('md')]: {
                        pt: '56px'
                    }
                }}
            >
                <Box
                    sx={{
                        mb: '22px',
                        display: 'grid',
                        gap: '12px',
                        gridTemplateColumns: 'repeat(2, auto)',
                        gridAutoRows: 'auto',
                        alignContent: 'center',
                        [theme.breakpoints.up('md')]: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        },
                        'h1': {
                            alignSelf: 'center'
                        }
                    }}
                >
                    <ClientPortalTitle>
                        My Hires
                    </ClientPortalTitle>

                    <Box
                        sx={{
                            ml: 'auto',
                            [theme.breakpoints.up('md')]: {
                                mr: 'auto',
                                ml: 0
                            }
                        }}
                    >
                        <SelectSortBy value={sortBy || ''} onChange={handleSortChange}/>
                    </Box>

                    <TabsStatusFilter
                        items={optionsFilter}
                        value={filterBy || EEngagementFilter.All}
                        onChange={handleFilterChange}
                    />
                </Box>

            </Box>

            {isLoading && !data ? (
                <LoadingMask/>
            ) : (
                <Box sx={{
                    width: '100%',
                    [theme.breakpoints.up('lg')]: {
                        display: 'flex',
                        gap: '24px',
                        alignItems: 'flex-start'
                    }
                }}>
                    <Box
                        sx={{width: '100%', flexGrow: 0}}
                    >
                        <InfiniteScroll
                            dataLength={items.length}
                            hasMore={hasNextPage || false}
                            loader={<LoadingMask/>}
                            next={fetchNextPage}
                        >
                            <HiresList items={items}/>
                        </InfiniteScroll>
                    </Box>
                </Box>
            )}

        </>
    );
};

export default React.memo(MyHiresPage);
