import {useCallback, useState} from 'react';

export function useCallbackRef() {
    const [ref, setRef] = useState<any>();
    const fn = useCallback((node: React.MutableRefObject<HTMLDivElement>) => {
        setRef(node);
    }, []);

    return [ref, fn];
}
