import React, {useCallback, useLayoutEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import {useNavigate} from 'react-router-dom';

import {Box, styled, Theme, useMediaQuery} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {freelancerService} from '../../api';
import {EditIcon} from '../../assets/icons';
import {DETAILS, FREELANCER, FREELANCER_PROFILE, LG, RouteFreelancerPortal, SM} from '../../constants';
import {EWorkType, IName} from '../../models';
import theme from '../../theme';
import {BackButton, BlackBorderButton, Portal, showToastError} from '../index';
import LoadingMask from '../LoadingMask';

import FreelancerCertifications from './components/FreelancerCertifications';
import {
    FreelancerAboutInfo,
    FreelancerCaseStudies,
    FreelancerFitsBudget,
    FreelancerMatchesList,
    FreelancerRateInfo,
    FreelancerTopInfo
} from './components';

const Wrp = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 40px;
  padding-bottom: 170px;
  padding-right: 20px;
  margin-right: -20px;
  overflow: hidden;

  @media (min-width: ${SM}) {
    display: grid;
    grid-template-areas: 
    'backButton .'
    'nameBox nameBox'
    'rateBox fitBudgetBox'
    'aboutBox aboutBox '
    'matchesBox matchesBox '
    'certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
  ;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${LG}) {
    padding-bottom: 48px;
    grid-template-areas: 
    'backButton . .'
    'nameBox nameBox nameBox'
    'rateBox fitBudgetBox fitBudgetBox'
    'aboutBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
    'matchesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
  ;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1400px) {
    grid-template-areas: 
    'backButton . . .'
    'nameBox nameBox nameBox rateBox'
    'nameBox nameBox nameBox fitBudgetBox'
    'aboutBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
    'matchesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
  ;
    grid-template-columns: 540px auto 223px 293px;
    gap: 24px;
  }

  @media (min-width: ${LG}) {
    padding-top: 56px;
  }

  @media (min-width: 1728px) {
    overflow: visible;
  }
`;

export const Item = styled('div')<{
    gridArea: string;
    padding?: string;
    backgroundColor?: string;
    backgroundImage?: string;
    boxShadow?: string;
    borderColor?: string;
    overflowVisible?: boolean;
    widthXl?: string;
}>`
  grid-area: ${props => props.gridArea};
  padding: ${props => props.padding ? props.padding : '24px'};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : theme.palette.white.main};
  background-image: ${props => props.backgroundImage ? props.backgroundImage : 'none'};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  border: ${props => props.borderColor ? `2px solid ${props.borderColor}` : 'unset'};
  box-shadow: ${props => props.boxShadow ? props.boxShadow : 'unset'};
  overflow: ${props => props.overflowVisible ? 'visible' : 'hidden'};

  @media (min-width: 1400px) {
    width: ${props => props.widthXl ? props.widthXl + 'px' : 'auto'};
  }
`;

const PublicFreelancerProfilePageForFreelancer = () => {
    const navigate = useNavigate();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const {
        isLoading,
        data,
    } = useQuery([FREELANCER_PROFILE], freelancerService.getProfile, {
        staleTime: 60 * 1000,
        select: ((data) => data),
        onError: (error) => showToastError(error as AxiosError)
    });

    const {
        data: freelancer,
    } = useQuery([FREELANCER], freelancerService.getFleelancer, {
        // enabled: isFreelancer,
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });


    const handleBackMove = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    console.log('Public FreelancerProfile', data);

    const handleEditProfileMove = useCallback(() => {
        navigate(`${RouteFreelancerPortal.profileSettings}/${DETAILS}`);
    }, [navigate]);

    // Wrong screen position when opening freelancer detailed profile second time
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const publishCaseStudies = useMemo(() => data?.caseStudies.filter(it => !!it.publicVersion).map(it => ({
        ...it.publicVersion,
        id: it.id
    })), [data]);

    return (
        <div>
            {isLoading && <LoadingMask/>}

            {!isLoading && data && (
                <Wrp>
                    <BackButton
                        sx={{
                            gridArea: 'backButton',
                            alignSelf: 'flex-start',
                            justifySelf: 'flex-start',
                            [theme.breakpoints.up('lg')]: {
                                mb: '8px'
                            }
                        }}
                        onClick={handleBackMove}
                    />
                    <Item gridArea="nameBox">
                        {freelancer && (
                            <FreelancerTopInfo
                                data={{
                                    avatar: freelancer.avatar,
                                    country: data.country || '',
                                    businessModels: {common: data.businessModels, additional: []},
                                    isAvailableForNewProject: !!data.isAvailableForNewProject,
                                    isOpenToHireRole: !!data.isOpenToHireRole,
                                    firstName: freelancer.firstName,
                                    lastName: freelancer.lastName,
                                    yearsOfExperience: data.yearsOfExperience,
                                }}
                            >
                                {
                                    lgUp ? (
                                        <Box
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    gridArea: 'buttons',
                                                    justifySelf: 'flex-end',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    gap: '16px'
                                                },
                                                [theme.breakpoints.up(1400)]: {
                                                    flexWrap: 'nowrap'
                                                }
                                            }}
                                        >
                                            <BlackBorderButton
                                                size="small"
                                                sx={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    gap: '8px'
                                                }}
                                                onClick={handleEditProfileMove}
                                            >
                                                <EditIcon/>
                                                Edit profile
                                            </BlackBorderButton>
                                        </Box>
                                    ) : (
                                        <Portal fullWidth order={1} visibleUntil={LG}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: '12px',
                                                    'button': {
                                                        width: 'calc(50% - 6px) !important',
                                                        [theme.breakpoints.up('md')]: {
                                                            width: 'calc(33% - 8px) !important'
                                                        },
                                                    },
                                                }}
                                            >
                                                <BlackBorderButton
                                                    size="small"
                                                    sx={{
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        gap: '8px'
                                                    }}
                                                    onClick={handleEditProfileMove}
                                                >
                                                    <EditIcon/>
                                                    Edit profile
                                                </BlackBorderButton>
                                            </Box>
                                        </Portal>
                                    )
                                }
                            </FreelancerTopInfo>
                        )}
                    </Item>
                    <Item
                        gridArea="rateBox"
                        backgroundColor={theme.palette.purple.main}
                        widthXl="293"
                    >
                        <FreelancerRateInfo
                            rate={data.fixedRate || data.rateRange}
                            workTypes={data.workTypes}
                        />
                    </Item>
                    <Item
                        gridArea="fitBudgetBox"
                        boxShadow="0 0 50px rgba(126, 95, 255, 0.2)"
                        overflowVisible
                        widthXl="293"
                    >
                        <FreelancerFitsBudget/>
                    </Item>

                    <Item gridArea="aboutBox" widthXl="540">
                        <FreelancerAboutInfo
                            bio={data.bio || ''}
                            brands={data.brands}
                        />
                    </Item>
                    <Item gridArea="matchesBox" widthXl="540">
                        <FreelancerMatchesList
                            data={{
                                channels: {common: data.channels, additional: []},
                                verticals: {common: data.verticals as unknown as IName[], additional: []},
                                languages: {common: data.languages, additional: []},
                                tools: {common: data.tools as unknown as IName[], additional: []},
                                workTypes: {common: data.workTypes as unknown as EWorkType[], additional: []}
                            }}
                        />
                    </Item>
                    <Box
                        sx={{
                            gridArea: 'certificationsAndCaseStudiesBox',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            [theme.breakpoints.up(1400)]: {
                                gap: '24px'
                            }
                        }}
                    >
                        <Item gridArea="caseStudiesBox">
                            <FreelancerCaseStudies
                                items={publishCaseStudies || []}
                            />
                        </Item>
                        <Item gridArea="certificationsBox">
                            <FreelancerCertifications
                                items={data.certificates}
                            />
                        </Item>
                    </Box>
                </Wrp>
            )}

        </div>
    );
};

export default React.memo(PublicFreelancerProfilePageForFreelancer);
