import axios, {AxiosRequestConfig} from 'axios';

import {showToastError} from '../components';
import {RouteAuth, RouteCreateApplicantAccount, RouteCreateClientAccount, RouteWidget} from '../constants/Router';
import {IUser} from '../models';

import {authService, REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY} from './auth.api';

const baseApiConfig = {
    baseURL: location.hostname === 'localhost' ? '/' : process.env.REACT_APP_BASE_URL,
    withCredentials: true,
};

let authTokens = localStorage.getItem(TOKEN_KEY) || null;

const formatBearerStr = () => authTokens ? `Bearer ${authTokens}` : null;
const loadBaerer = () => authTokens = localStorage.getItem(TOKEN_KEY) || null;

const axiosInstance = axios.create({
    ...baseApiConfig,
    headers:{Authorization: formatBearerStr()}
});

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

axiosInstance.interceptors.request.use(async (req: AxiosRequestConfig) => {
    const user = localStorage.getItem(USER_KEY);
    let isExpired: boolean = false;

    loadBaerer();

    if (!req.headers) req.headers = {};
    req.headers.Authorization = formatBearerStr();

    if (user) {
        const data: IUser = JSON.parse(user);
        const expiredInSeconds = (data.exp * 1000 - Date.now()) / 1000;

        // console.log('token for:', data.email, data.roles);
        // console.log('token will expired in seconds:', expiredInSeconds);
        isExpired = expiredInSeconds < 15;  // refresh if 15 sec left
    }

    if (!isExpired) return req;

    await authService.refreshAccessToken();
    loadBaerer();
    req.headers.Authorization = formatBearerStr();

    return req;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const refreshAccessToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        const signUpPages = [
            RouteAuth.login,
            RouteAuth.forgotPassword,
            RouteCreateApplicantAccount.signup,
            RouteCreateClientAccount.signup,
            RouteWidget.default,
            RouteWidget.signup,
        ];

        if (error.response?.status === 401 && refreshAccessToken && !originalRequest._retry) {
            originalRequest._retry = true;
            await authService.refreshAccessToken();

            return axiosInstance(originalRequest);
        } else if (!refreshAccessToken && !signUpPages.includes(document.location.pathname)) {
            document.location.href = RouteAuth.login;
        } else if (error.response?.status === 500) {
            showToastError(error);
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;