import React, {useCallback, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, styled} from '@mui/material';

import {ExclamationMarkIcon, ExclamationWarnMarkIcon} from '../../../../../assets/icons';
import {TabItem, TabsStatusFilter} from '../../../../../components';
import {RouteFreelancerPortal} from '../../../../../constants';
import {useConversation} from '../../../../../hooks';
import {EWorkType, IEngagement} from '../../../../../models';
import theme from '../../../../../theme';
import {EDisplayMode} from '../../MyWorkDetailsPage';

const BadgeCounter = styled('span')`
  display: inline-block;
  padding: 2px 6px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.palette.white.main};
  background-color: ${theme.palette.error.main};
  margin-left: 8px;
`;

interface TabsProps {
    data: IEngagement;
}

const Tabs: React.FC<TabsProps> = ({data}) => {
    const {engagementId, tab} = useParams();
    const {meta} = useConversation(data.conversationSid);
    const navigate = useNavigate();

    const tabs = useMemo(() => {
        const result: TabItem[] = [
            {
                text: 'Overview',
                value: EDisplayMode.Overview
            },
            {
                badge: meta?.unreadMessagesCount ? <BadgeCounter>{meta?.unreadMessagesCount} new</BadgeCounter> : null,
                text: 'Messages',
                value: EDisplayMode.Messages,
            },
            // {
            //     text: 'Reports',
            //     value: EDisplayMode.Reports
            // }
        ];

        // show second tab Timesheets for hourly only
        if (data?.budgetType === EWorkType.hourly) {
            result.splice(1, 0, {
                icon: (
                    <>
                        {data.hasOverdueTimesheets && (
                            <Box component="span" sx={{mr: 2, verticalAlign: 'middle'}} title="Overdue time card(s) exists">
                                <ExclamationWarnMarkIcon/>
                            </Box>
                        )}
                        {data.hasRejectedTimesheets && (
                            <Box component="span" sx={{mr: 2, verticalAlign: 'middle'}} title="Rejected time card(s) exists">
                                <ExclamationMarkIcon/>
                            </Box>
                        )}
                    </>
                ),
                text: 'Timesheets',
                value: EDisplayMode.Timesheets
            });
        }

        return result;
    }, [data, meta?.unreadMessagesCount]);

    const handleTabChange = useCallback((tab: string) => {
        navigate(`${RouteFreelancerPortal.myWorkDetails}/${engagementId}/${tab}`);
    }, [engagementId, navigate]);

    return (
        <TabsStatusFilter
            items={tabs}
            value={tab || EDisplayMode.Overview}
            onChange={handleTabChange}
        />
    );
};

export default React.memo(Tabs);
