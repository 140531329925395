import {IClientRole} from './client-role.model';
import {IFreelancer} from './freelancer.model';
import {IProfile} from './profile.model';
import {EWorkType} from './work.model';

export interface IEngagement {
    budgetType: EWorkType;
    clientName: string;
    clientCompanyLogo?: string;
    clientCompanyName: string;
    closureStatus?: EClosureStatus;
    conversationSid: string;
    description: string;
    endDate: string;
    freelancerAvatar: string;
    freelancerName: string;
    hasOverdueTimesheets: boolean;
    hasRejectedTimesheets: boolean;
    id: number;
    isMonthly: boolean;
    matchId: number;
    name: string;
    nextBillingDate: string;
    paid: number;
    pause: IPause;
    pinned: boolean;
    rate: number;
    resume?: IEngagementVersion;
    startDate: string;
    status: EEngagementStatus;
    weeklyEngagement?: number;
}

interface IFeelancerWithProfile extends IFreelancer {
    profile: IProfile;
}

export interface IEngagementClient extends IEngagement {
    freelancer: IFeelancerWithProfile;
}

export interface IEngagementFreelancer extends IEngagement {
    role: IClientRole;
}

export enum EEngagementStatus {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    PAUSED = 'PAUSED',
    PENDING = 'PENDING',
}

export enum EEngagementStatusDisplay {
    ACTIVE = 'Engagement active',
    CLOSED = 'Engagement closed',
    PAUSED = 'Engagement paused',
    PENDING = 'Engagement pending',
}

export enum EEngagementSort {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum EEngagementFilter {
    All = 'ALL',
    Active = 'ACTIVE',
    Archive = 'ARCHIVED'
}

export enum EEngagementFilterDisplay {
    ALL = 'All',
    ACTIVE = 'Active',
    ARCHIVED = 'Archive'
}

export interface IPause {
    id?: number;
    reason: string;
    status?: string;

    startDate: string;
    endDate?: string;
}

export interface IPauseClientRequest {
    startDate: string;
    endDate?: string;
}

export enum EPauseStatus {
    FREELANCER_REQUESTED = 'FREELANCER_REQUESTED',
    CANCELLED = 'CANCELLED',                        // Initiator cancel future Pause
    CLIENT_ACCEPTED = 'CLIENT_ACCEPTED',            // Initiator Freelancer
    CLIENT_DECIDED = 'CLIENT_DECIDED',              // Initiator Client
    CLIENT_REJECTED = 'CLIENT_REJECTED',            // client rejected Freelancer's request
}

export enum EClosureStatus {
    CLIENT_ACCEPTED = 'CLIENT_ACCEPTED',
    CLIENT_REJECTED = 'CLIENT_REJECTED',
    CLIENT_REQUESTED = 'CLIENT_REQUESTED',
    FREELANCER_ACCEPTED = 'FREELANCER_ACCEPTED',
    FREELANCER_REJECTED = 'FREELANCER_REJECTED',
    FREELANCER_REQUESTED = 'FREELANCER_REQUESTED',
}

export enum EResumeStatus {
    CLIENT_ACCEPTED = 'CLIENT_ACCEPTED',
    CLIENT_CANCELLED = 'CLIENT_CANCELLED',
    CLIENT_REJECTED = 'CLIENT_REJECTED',
    CLIENT_REQUESTED = 'CLIENT_REQUESTED',
    FREELANCER_ACCEPTED = 'FREELANCER_ACCEPTED',
    FREELANCER_CANCELLED = 'FREELANCER_CANCELLED',
    FREELANCER_REJECTED = 'FREELANCER_REJECTED',
    FREELANCER_REQUESTED = 'FREELANCER_REQUESTED',
}

export interface IEngagementVersion {
    id?: number;
    startDate: string;
    endDate?: string;
    nextBillingDate?: string;
    rate: number;
    budgetType: string;
    isMonthly: boolean;
    resumeStatus?: EResumeStatus;
    weeklyEngagement?: number;
}
