import {useCallback, useContext, useEffect} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {Message} from '@twilio/conversations';

import {freelancerService, matchesService} from '../api';
import {GET_MATCHED} from '../constants';
import {TwilioContext} from '../contexts';
import {EParticipantType, ICall, IConversationAttributes, IConversationMeta} from '../models';

import {useAuth} from './auth.hook';
import {useTwilio} from './twilio.hook';

interface IProps {
    attributes: IConversationAttributes;
    calls: ICall[];
    messages: Message[];
    meta: IConversationMeta | null;
    typing: string;
    sendFiles(files: File[]): Promise<void>;
    sendMessage(message: string): Promise<void>;
    sendProposal(proposalId: number): Promise<void>;
    sendTyping(): Promise<void>;
}

export const useConversation = (id: string): IProps => {
    const {isClient} = useAuth();
    const {calls, conversationActive, messages, meta, typing} = useContext(TwilioContext);
    const {selectConversationById} = useTwilio();

    const queryClient = useQueryClient();

    // 5 minutes lapses (heart beat)
    // useQuery(
    //     [activeCall],
    //     () => (isClient ? matchesService : freelancerService).chatHeartBeat(matchId),
    //     {
    //         enabled: !!activeCall,
    //         refetchInterval: 15 * 1000,
    //         onSuccess:() => console.log('heart beat')
    //     }
    // );


    // set last read message index of the conversation
    const updateLastReadMessageIndex = useCallback((index?: number) => {
        if (index) {
            conversationActive?.updateLastReadMessageIndex(index);
            ((conversationActive as any).meta as IConversationMeta).unreadMessagesCount = 0;
        }

        // set contacted flag
        if (index === 1 && conversationActive) {
            const apiService = isClient ? matchesService : freelancerService;

            apiService.chatContacted(conversationActive.sid);
            queryClient.invalidateQueries([GET_MATCHED]);       // refetch for showing is contacted flag at the MyMatches page
        }
    }, [conversationActive, isClient, queryClient]);

    // i can not send multiple files in one message
    // twilio will return only one of them
    // so each file send as a new message
    const sendFiles = useCallback(async (files: File[]): Promise<void> => {
        const requests = files.map(file => {
            const formData = new FormData();

            formData.append(file.name || 'file', file);

            return conversationActive?.sendMessage(formData);
        });

        const responses = await Promise.all(requests);
        const {length, [length - 1]: last} = responses;

        // set last read message index of the conversation
        if (last) {
            updateLastReadMessageIndex(last);
        }
    }, [conversationActive, updateLastReadMessageIndex]);

    const sendMessage = useCallback(async (message: string): Promise<void> => {
        if (!message.trim().length) return;

        // add first empy message
        // it will init history horizont and set participant readed index
        // because null don't return properly getUnreadMessageCount()
        if (!messages.length) {
            await conversationActive?.sendMessage('');
        }

        const index = await conversationActive?.sendMessage(message);

        updateLastReadMessageIndex(index);
    }, [conversationActive, messages, updateLastReadMessageIndex]);

    const sendProposal = useCallback(async (proposalId: number): Promise<void> => {
        // add first empy message
        // it will init history horizont and set participant readed index
        // because null don't return properly getUnreadMessageCount()
        if (!messages.length) {
            await conversationActive?.sendMessage('');
        }

        await conversationActive?.prepareMessage()
            .setBody(`Proposal: ${proposalId}`)
            .setAttributes({
                author: isClient ? EParticipantType.CLIENT : EParticipantType.FREELANCER,
                proposalId
            })
            .build()
            .send();

        queryClient.invalidateQueries([GET_MATCHED]);       // refetch for showing is proposal flag at the MyMatches page
    }, [conversationActive, isClient, messages, queryClient]);

    const sendTyping = useCallback(async (): Promise<void> => {
        if (conversationActive) {
            await conversationActive.typing();
        }
    }, [conversationActive]);

    useEffect(() => {
        selectConversationById(id);
    }, [id, selectConversationById]);

    return {
        attributes: (conversationActive?.attributes || {}) as unknown as IConversationAttributes,
        calls: calls?.filter(call => call.conversationId !== id) || [],     // new incoming calls collection
        messages,
        meta,
        typing,
        sendFiles,
        sendMessage,
        sendProposal,
        sendTyping,
    };
};
