export enum EFilter {
    All = 'ALL',
    Active = 'ACTIVE',
    Archive = 'ARCHIVED'
}

export enum EFilterDisplay {
    ALL = 'All',
    ACTIVE = 'Active',
    ARCHIVED = 'Archive'
}
