import {object, string} from 'zod';

export const CLIENT_PROJECT_MAX_LENGTH = 1000;

export const projectName = string()
    .trim()
    .min(1, 'Project Name is required')
    .max(200, 'Project Name is too long');

export const projectDescription = string()
    .trim()
    .min(1, 'Project Description is required')
    .max(CLIENT_PROJECT_MAX_LENGTH, `Project Description must be less than ${CLIENT_PROJECT_MAX_LENGTH} characters`);

export const clientProjectSchema = object({
    projectName,
    projectDescription,
});
