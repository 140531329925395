import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import {styled} from '@mui/material';

import {WIDGET_PAYLOAD} from '../../../../api';
import {COMPLETED, LG, MD, RouteWidget, SM, XL} from '../../../../constants';
import {GRADIENT, WAVY_BG_GRAY, WAVY_BG_GREEN, WAVY_BG_PURPLE} from '../../../../constants/BackgroundImages';
import theme from '../../../../theme';
import {utm} from '../../../../utils';

import {getStep, stepsOrderWidget, widgetStepsList} from './nav.model';

interface IProgressBarProps {
    displaySmUp?: string;
    height?: number;
    isLight?: boolean;
    progress: number;
}

export const ProgressBar = styled('div')<IProgressBarProps>`
    width: 100%;
    height: ${props => props.height ? props.height + 'px' : '6px'};
    margin-bottom: 8px;
    border-radius: 10px;
    background-color: ${props => props.isLight ? theme.palette.lightGray.main : theme.palette.progressBarBlack.main};
    position: relative;
    overflow: hidden;
    will-change: contents;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: ${props => props.progress}%;
        border-radius: 10px;
        background-image: ${GRADIENT};
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        transition: width 1s linear;
    }

    @media (min-width: ${SM}) {
        display: ${props => props.displaySmUp ? props.displaySmUp : 'none'};
    }
`;

const StepList = styled('ol')`
    list-style: none;
    counter-reset: step-counter;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;

    @media (min-width: ${SM}) {
        counter-reset: step-counter;
    }
`;

const Step = styled('li')`
    counter-increment: step-counter;

    @media (min-width: ${SM}) {
        padding: 9px 0;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    @media (min-width: ${LG}) {
        padding: 12px 0;
    }

    a {
        display: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #A6A6A6;

        @media (min-width: ${SM}) {
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 14px;
            line-height: 130%;
        }

        @media (min-width: ${XL}) {
            gap: 24px;
        }

        &::before {
            @media (min-width: ${SM}) {
                content: '0' counter(step-counter) ' ';
                flex-shrink: 0;
                padding: 16px;
                width: 50px;
                height: 50px;
                background-image: ${WAVY_BG_GRAY};
                background-position: center;
                background-repeat: no-repeat;
                background-size: 88%;
                font-size: 14px;
                text-align: center;
            }

            @media (min-width: ${XL}) {
                width: 64px;
                height: 64px;
                padding: 22px;
            }
        }

        &::after {
            @media (min-width: ${MD}) {
                content: '';
                margin-left: auto;
                margin-right: -15px;
                flex-shrink: 0;
                width: 90px;
                height: 5px;
                border-radius: 5px;
                background-image: linear-gradient(to right, transparent, #7E5FFF);
                background-position: 0 center;
                background-size: 0;
                background-repeat: no-repeat;
                opacity: 0;
                transition: opacity .3s, background-size .7s;
            }

            @media (min-width: ${LG}) {
                width: 115px;
            }

            @media (min-width: ${XL}) {
                margin-right: 37px;
            }
        }
    }

    & {
        a.active {
            display: block;
            color: ${theme.palette.white.main};

            @media (min-width: ${SM}) {
                display: flex;
                font-weight: 500;
                font-size: 16px;
            }

            &::before {
                @media (min-width: ${SM}) {
                    background-image: ${WAVY_BG_PURPLE};
                }
            }

            &::after {
                @media (min-width: ${MD}) {
                    opacity: 1;
                    background-size: 100%;
                }
            }
        }

        &.completed a {
            color: ${theme.palette.white.main};

            @media (min-width: ${SM}) {
                font-weight: 500;
                font-size: 16px;
            }

            &::before {
                @media (min-width: ${SM}) {
                    content: '';
                    background-image: ${WAVY_BG_GREEN};
                    background-size: 100%;
                }
            }
        }
    }

    .step-counter {
        @media (min-width: ${SM}) {
            display: none;
        }
    }
`;

const MenuSteps = (): JSX.Element => {
    const widgetId = utm.campaign;
    const location = useLocation();
    const [currentStep, setCurrentStep] = useState(1);
    const [stepPercent, setStepPercent] = useState(0);

    const stepsList = useMemo(() => {
        // can use presets here for hidding some steps
        return widgetStepsList;
    }, []);

    const handleClick = (nextStep: number) => (event: SyntheticEvent) => {
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
        const data = widgetPayload ? JSON.parse(widgetPayload) : {};

        if (data.view === COMPLETED) {
            event.preventDefault();

            return;
        }

        let enabledStep: number = 0;

        const filledStep = getStep(data);

        if (filledStep !== RouteWidget.completed) {
            enabledStep = stepsOrderWidget.indexOf(filledStep || '') + 1;
        }

        if (nextStep > enabledStep) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
        const data = widgetPayload ? JSON.parse(widgetPayload) : {};

        if (!stepsList || !data) return;
        const filledStep = getStep(data);
        const currentIndex = stepsOrderWidget.indexOf(filledStep);

        setCurrentStep(currentIndex + 1);
        setStepPercent((100 / stepsList.length) * currentIndex);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, stepsList?.length]);

    return (
        <>
            <ProgressBar progress={stepPercent}/>
            <StepList>
                {
                    stepsList?.map((step, i) => (
                        <Step
                            key={step.title}
                            className={currentStep > i + 1 ? 'completed' : ''}
                        >
                            <NavLink
                                to={step.link + location.search}
                                onClick={handleClick(i + 1)}
                            >
                                <span className="step-counter">
                                    {i + 1}/{stepsList.length}:&nbsp;
                                </span>

                                {step.title}
                            </NavLink>
                        </Step>)
                    )
                }
            </StepList>
        </>
    );
};

export default MenuSteps;
