import React from 'react';
import type {RouteObject} from 'react-router-dom';

import {RouteCreateClientAccount,} from '../constants';
import {ERole} from '../models';
import {RequireUser} from '../modules/Auth';
import {CreateAccountLayout} from '../modules/CreateApplicant';
import {
    Budget,
    ClientChannels,
    ClientConfirmationCodePage,
    ClientThankYou,
    LocationTimezone,
    Project,
    Tools,
    VerticalsAndBusinessModel
} from '../modules/CreateClient';

export const createClientRoutes: RouteObject = {
    path: '/',
    element: <CreateAccountLayout/>,
    children: [
        {
            path: '',
            // CLIENT_UNVERIFIED - can jump to the any step.
            // CLIENT - will see disabled menu and Thank you screen only.
            element: <RequireUser allowedRoles={[ERole.CLIENT_UNVERIFIED, ERole.CLIENT]}/>,
            children: [
                {
                    path: RouteCreateClientAccount.channels,
                    element: <ClientChannels/>,
                },
                {
                    path: `${RouteCreateClientAccount.channels}/:channelId`,
                    element: <ClientChannels/>,
                },
                {
                    path: RouteCreateClientAccount.verticals,
                    element: <VerticalsAndBusinessModel/>,
                },
                {
                    path: RouteCreateClientAccount.tools,
                    element: <Tools/>,
                },
                {
                    path: RouteCreateClientAccount.location,
                    element: <LocationTimezone/>,
                },
                {
                    path: RouteCreateClientAccount.budget,
                    element: <Budget/>,
                },
                {
                    path: RouteCreateClientAccount.project,
                    element: <Project/>,
                },
                {
                    path: RouteCreateClientAccount.confirmationCode,
                    element: <ClientConfirmationCodePage/>,
                },
                {
                    path: `${RouteCreateClientAccount.confirmationCode}/:mode`,
                    element: <ClientConfirmationCodePage/>,
                },
                {
                    path: RouteCreateClientAccount.completed,
                    element: <ClientThankYou/>
                }
            ],
        }
    ],
};
