import React from 'react';

const CrossInStarIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.77324 2.9367C10.8915 1.46194 13.1085 1.46194 14.2268 2.9367C14.8369 3.74138 15.8337 4.15428 16.8342 4.01672C18.6677 3.76463 20.2354 5.33231 19.9833 7.16584C19.8457 8.16627 20.2586 9.16309 21.0633 9.77324C22.5381 10.8915 22.5381 13.1085 21.0633 14.2268C20.2586 14.8369 19.8457 15.8337 19.9833 16.8342C20.2354 18.6677 18.6677 20.2354 16.8342 19.9833C15.8337 19.8457 14.8369 20.2586 14.2268 21.0633C13.1085 22.5381 10.8915 22.5381 9.77324 21.0633C9.16309 20.2586 8.16627 19.8457 7.16584 19.9833C5.33231 20.2354 3.76463 18.6677 4.01672 16.8342C4.15428 15.8337 3.74138 14.8369 2.9367 14.2268C1.46194 13.1085 1.46194 10.8915 2.9367 9.77324C3.74138 9.16309 4.15428 8.16627 4.01672 7.16584C3.76463 5.33231 5.33231 3.76463 7.16584 4.01672C8.16627 4.15428 9.16309 3.74138 9.77324 2.9367Z"
                fill="#EF462C"/>
            <path
                d="M12.9477 12.1545L15.3645 9.74341C15.4703 9.63758 15.5298 9.49404 15.5298 9.34437C15.5298 9.19469 15.4703 9.05115 15.3645 8.94532C15.2587 8.83949 15.1151 8.78003 14.9654 8.78003C14.8158 8.78003 14.6722 8.83949 14.5664 8.94532L12.1553 11.3621L9.74413 8.94532C9.63829 8.83949 9.49475 8.78003 9.34508 8.78003C9.19541 8.78003 9.05187 8.83949 8.94603 8.94532C8.8402 9.05115 8.78074 9.19469 8.78074 9.34437C8.78074 9.49404 8.8402 9.63758 8.94603 9.74341L11.3628 12.1545L8.94603 14.5657C8.89336 14.6179 8.85154 14.6801 8.82301 14.7486C8.79448 14.8171 8.77979 14.8905 8.77979 14.9647C8.77979 15.0389 8.79448 15.1124 8.82301 15.1809C8.85154 15.2494 8.89336 15.3115 8.94603 15.3638C8.99828 15.4165 9.06045 15.4583 9.12893 15.4868C9.19742 15.5153 9.27089 15.53 9.34508 15.53C9.41928 15.53 9.49274 15.5153 9.56123 15.4868C9.62972 15.4583 9.69188 15.4165 9.74413 15.3638L12.1553 12.947L14.5664 15.3638C14.6187 15.4165 14.6808 15.4583 14.7493 15.4868C14.8178 15.5153 14.8913 15.53 14.9654 15.53C15.0396 15.53 15.1131 15.5153 15.1816 15.4868C15.2501 15.4583 15.3122 15.4165 15.3645 15.3638C15.4172 15.3115 15.459 15.2494 15.4875 15.1809C15.5161 15.1124 15.5307 15.0389 15.5307 14.9647C15.5307 14.8905 15.5161 14.8171 15.4875 14.7486C15.459 14.6801 15.4172 14.6179 15.3645 14.5657L12.9477 12.1545Z"
                fill="white"/>
        </svg>
    );
};

export default CrossInStarIcon;
