import React from 'react';

import {Box} from '@mui/system';

import {IWorkWeek} from '../../../models';
import theme from '../../../theme';
import {formatDate, formatHoursFromDuration} from '../../../utils';
import {TimesheetEmptyPlaceholder, TimesheetHeading} from '../..';

type WeekEmptyProps = {
    data: IWorkWeek;
    isPaused?: boolean;
    isPending?: boolean;
};

const WeekEmpty: React.FC<WeekEmptyProps> = ({data, isPaused, isPending}) => {
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    p: '24px',
                    mb: '16px',
                    backgroundColor: theme.palette.white.main,
                    borderRadius: '24px',
                    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                    '&:last-of-type': {
                        mb: 0
                    }
                }}
            >
                <TimesheetHeading
                    endDate={data.toDate ? formatDate(data.toDate) : 'n/a'}
                    hoursSum={data.workTime ? formatHoursFromDuration(data.workTime) : ''}
                    startDate={data.fromDate ? formatDate(data.fromDate) : ''}
                />

                <TimesheetEmptyPlaceholder isPaused={isPaused} isPending={isPending}/>
            </Box>
        </>
    );
};

export default React.memo(WeekEmpty);
