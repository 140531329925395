import React from 'react';

import {ButtonProps} from '@mui/material';

import theme from '../../../theme';
import {Button} from '../index';

const TextButton = ({children, ...props}: ButtonProps) => {
    return (
        <Button
            {...props}
            sx={{
                p: '0 !important',
                backgroundColor: 'transparent',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: 1.2,
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.palette.purple.main,
                    opacity: 0.9
                },
                ...props.sx
            }}
        >
            {children}
        </Button>
    );
};

export default React.memo(TextButton);
