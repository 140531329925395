import React, {useCallback, useState} from 'react';

import {useQueryClient} from '@tanstack/react-query';

import {CrossOutlineIcon} from '../../../assets/icons';
import {CLIENT_ENGAGEMENT, FREELANCER_ENGAGEMENT} from '../../../constants';
import {useAuth} from '../../../hooks';
import {EClosureStatus, EEngagementStatus, IEngagement} from '../../../models';
import {DialogEngagementClose} from '../../../modules/FreelancerPortal';
import {DialogSuccess, RedFilledButton} from '../..';

type CloseCancelBtnProps = {
    data: IEngagement;
};

const CloseCancelBtn: React.FC<CloseCancelBtnProps> = ({data}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const handleRejectClose = useCallback((isNeedUpdate: boolean) => {
        setOpen(false);
        // play modal show/hide animation
        setTimeout(() => {
            setOpenSuccess(isNeedUpdate);
        }, 500);
    }, []);

    const handleSuccessClose = useCallback((isNeedUpdate: boolean) => {
        setOpenSuccess(false);
        queryClient.invalidateQueries({queryKey: [CLIENT_ENGAGEMENT]});     // don't remove. used for Dashboard refresh
        queryClient.invalidateQueries({queryKey: [FREELANCER_ENGAGEMENT]});
    }, [queryClient]);

    if (data.status === EEngagementStatus.CLOSED || data.closureStatus !== EClosureStatus.FREELANCER_REQUESTED) {
        return null;
    }

    return (
        <>
            <RedFilledButton
                disabled={isImpersonal}
                size="small"
                sx={{
                    width: 'auto!important'
                }}
                onClick={() => setOpen(true)}
            >
                <CrossOutlineIcon/> Cancel engagement closure
            </RedFilledButton>

            <DialogEngagementClose data={data} open={open} onClose={handleRejectClose}/>

            <DialogSuccess
                open={openSuccess}
                subtitle="Wishing you two productive work!"
                title="The engagement close has been successfully cancelled."
                onClose={handleSuccessClose}
            />
        </>
    );
};

export default React.memo(CloseCancelBtn);
