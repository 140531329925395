import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm, useWatch} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, InputAdornment, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {applicationService} from '../../../../api';
import {HyperlinkIcon} from '../../../../assets/icons';
import {
    BackButton,
    BlackBorderButton,
    CardTitle,
    ConfirmDialog,
    ContinueButton,
    Input,
    InputFile,
    Portal,
    showToastError,
    showToastSuccess
} from '../../../../components';
import {ACCEPTED_DOCUMENTS_TYPES, MD, RouteApplication} from '../../../../constants';
import {useApplication} from '../../../../hooks';
import {ICertificate} from '../../../../models';
import theme from '../../../../theme';
import {ValidationCertificate} from '../../../../utils';

const Wrp = styled('div')`
  padding-top: 12px;

  @media (min-width: ${MD}) {
    padding-top: 32px;
  }
`;

type CertificateInput = TypeOf<typeof ValidationCertificate.certificateSchema>;

const AddCertificate: React.FC = () => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const {refetchApplication, application} = useApplication();
    const params = useParams();

    const navigate = useNavigate();

    const item = useMemo(() => application?.certificates?.find((certificate: ICertificate) => certificate.id.toString() === params?.certificateId), [application?.certificates, params?.certificateId]);
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const {mutate, isLoading: isSubmitting} = useMutation(applicationService.certificateSubmit,
        {
            onSuccess() {
                showToastSuccess('Certificate was successfully ' + (item?.id ? 'updated!' : ' added!'));
                refetchApplication();
                navigate(RouteApplication.application);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const methods = useForm<CertificateInput>({
        resolver: zodResolver(ValidationCertificate.certificateSchema),
        defaultValues: ValidationCertificate.defaultValues,
        mode: 'onTouched',
    });

    const {
        control,
        formState,
        clearErrors,
        handleSubmit,
        setValue,
        reset,
        trigger,
    } = methods;

    const {file, name, url} = useWatch({control});

    const handleClose = useCallback((force = false) => {
        if (!force && formState.isDirty) {
            setIsConfirmOpen(true);
        } else {
            setIsConfirmOpen(false);
            navigate(RouteApplication.application);
        }
    }, [formState.isDirty, navigate]);

    const onSubmitHandler: SubmitHandler<CertificateInput> = useCallback((values) => {
        // Update request
        if (item) {
            const file = values?.file?.[0];

            mutate({
                ...values,
                id: item.id,
                file: file?.size ? [file] : []
            });
        } else {
            mutate(values);
        }
    }, [item, mutate]);

    // erase File errors
    useEffect(() => {
        if (url) {
            trigger('file');
        }
    }, [url, trigger]);

    const f = file?.[0];

    // erase URL errors
    useEffect(() => {
        if (file) {
            trigger('url');
        }
    }, [f, file, trigger]);

    // clean URL if File exists
    useEffect(() => {
        if (url) {
            setValue('file', false);
        }
    }, [url, setValue]);

    // clean File if URL exists
    useEffect(() => {
        if (file) {
            setValue('url', '');
        }
    }, [file, setValue]);

    useEffect(() => {
        if (item) {
            const {id, fileName, name, url} = item;
            const data: CertificateInput = {name, url};

            if (id && fileName) {
                data.file = [new File([], fileName)];
            }
            reset(data);
        } else {
            reset(ValidationCertificate.defaultValues);
        }

        return () => {
            clearErrors();
            reset(ValidationCertificate.defaultValues, {keepDirty: false, keepErrors: false, keepTouched: false});
        };
    }, [clearErrors, item, reset, setValue]);

    // Show confirmation
    if (isConfirmOpen) {
        return (
            <ConfirmDialog
                ariaDescribedby="dialog-comfirmation"
                confirmationText="Changes that you made may not be saved."
                open
                title="Are you sure you want to leave this page?"
                onNo={() => setIsConfirmOpen(false)}
                onYes={() => handleClose(true)}
            />
        );
    }

    return (
        <Wrp>
            <Box>
                <Box
                    sx={{
                        mb: '24px',
                        [theme.breakpoints.up('sm')]: {
                            display: 'grid',
                            gridTemplateColumns: '1fr auto 1fr',
                            alignItems: 'center'
                        },
                        [theme.breakpoints.up('md')]: {
                            mb: '40px',
                        }
                    }}
                >
                    <BackButton
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                justifySelf: 'flex-start',
                            }
                        }}
                        onClick={() => handleClose()}
                    />
                    <Box>
                        <Typography
                            sx={{
                                mb: '8px',
                                fontWeight: 600,
                                fontSize: '16px',
                                textAlign: 'center',
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '24px'
                                }
                            }}
                        >
                            {item ? 'Edit' : 'Add'} a certificate
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                textAlign: 'center',
                                color: theme.palette.gray.main
                            }}
                        >
                            Add evidence at least one marketing certification
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        p: '16px',
                        pb: '80px',
                        maxWidth: '696px',
                        m: '0 auto',
                        backgroundColor: theme.palette.white.main,
                        borderRadius: '16px',
                        [theme.breakpoints.up('md')]: {
                            p: '40px'
                        }
                    }}
                >
                    <FormProvider {...methods}>
                        <form
                            noValidate
                            autoComplete="off"
                        >
                            <Box>
                                <CardTitle sx={{mb: '24px'}}>
                                    Name Your Certificate
                                </CardTitle>
                                <Input
                                    autoFocus={!name}
                                    counter={100 - (name?.length || 0)}
                                    inputProps={{maxLength: 100}}
                                    disabled={isSubmitting}
                                    name="name"
                                    placeholder="Name"
                                    sx={{
                                        mb: '40px !important'
                                    }}
                                />

                                <InputFile
                                    accept={ACCEPTED_DOCUMENTS_TYPES}
                                    disabled={isSubmitting || !!url}
                                    isPreviewThumbs={false}
                                    placeholder={<>Drag your certificate here or click to browse <span>PDF, JPEG and PNG files with max size of 5 MB</span></>}
                                    name="file"
                                    mode="update"
                                    multiple={false}
                                />

                                <Typography
                                    textAlign="center"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '25px',
                                        mt: '40px',
                                        mb: '40px',
                                        textTransform: 'uppercase',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        '&::before, &::after': {
                                            content: '""',
                                            width: '100%',
                                            height: '1px',
                                            backgroundColor: theme.palette.lightGray.main
                                        }
                                    }}
                                >
                                    or
                                </Typography>

                                <Typography
                                    textAlign="center"
                                    sx={{
                                        mb: '24px',
                                        fontWeight: 600,
                                        fontSize: '14px'
                                    }}
                                >
                                    Paste a URL link to your certificate
                                </Typography>

                                <Input
                                    disabled={isSubmitting || !!file?.length}
                                    name="url"
                                    placeholder="https://www..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HyperlinkIcon/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            {
                                mdUp ? (
                                    <Box
                                        sx={{
                                            mt: '21px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '16px'
                                        }}
                                    >
                                        <BlackBorderButton
                                            onClick={() => handleClose()}
                                        >
                                            Cancel
                                        </BlackBorderButton>
                                        <ContinueButton
                                            disabled={isSubmitting}
                                            variant="contained"
                                            sx={{
                                                p: '20px 17px',
                                                width: 'auto !important',
                                                'div': {
                                                    justifyContent: 'center'
                                                }
                                            }}
                                            onClick={handleSubmit(onSubmitHandler)}
                                        >
                                            {item?.id ? 'Save certificate' : 'Upload certificate'}
                                        </ContinueButton>
                                    </Box>
                                ) : (
                                    <>
                                        <Portal
                                            order={1}
                                            innerPages
                                            visibleUntil={MD}
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <BlackBorderButton
                                                sx={{
                                                    width: '100%',
                                                }}
                                                onClick={() => handleClose()}
                                            >
                                                Cancel
                                            </BlackBorderButton>
                                        </Portal>
                                        <Portal order={2} innerPages visibleUntil={MD} sx={{
                                            [theme.breakpoints.up('md')]: {
                                                display: 'none'
                                            }
                                        }}>
                                            <ContinueButton
                                                disabled={isSubmitting}
                                                variant="contained"
                                                sx={{
                                                    p: '20px 17px',
                                                    width: '100% !important',
                                                    'div': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                                onClick={handleSubmit(onSubmitHandler)}
                                            >
                                                {item?.id ? 'Save certificate' : 'Upload certificate'}
                                            </ContinueButton>
                                        </Portal>
                                    </>
                                )
                            }
                        </form>
                    </FormProvider>
                </Box>
            </Box>
        </Wrp>
    );
};

export default React.memo(AddCertificate);
