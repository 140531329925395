import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, Theme, useMediaQuery} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    BudgetFixedRate,
    BudgetRateRange,
    BudgetTopTitle,
    BudgetType,
    BudgetWeeklyHours,
    ContinueButton,
    GrayButton,
    LoadingMask,
    Portal,
    showToastError,
    showToastSuccess,
    WhiteButton
} from '../../../../../../components';
import {CLIENT_ROLES, LOCATION, MAX_SAFE_INTEGER, PROJECT, RouteClientPortal} from '../../../../../../constants';
import {useClientRole} from '../../../../../../hooks';
import {ERoleStatusFilter, EWorkType, IBudgetRequest} from '../../../../../../models';
import theme from '../../../../../../theme';

export const Budget = () => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const queryClient = useQueryClient();
    const {roleId} = useParams();
    const {clientRole, isClientLoading, refetchClient} = useClientRole(Number(roleId));

    const [expectedEngagementHours, setExpectedEngagementHours] = useState<number[] | null>(null);
    const [rateRange, setRateRange] = useState<number[] | null>(null);
    const [workType, setWorkType] = useState<EWorkType | null>(null);

    let nextStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${PROJECT}`;
    const prevStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${LOCATION}`;

    const fixedHoursRef = useRef<HTMLInputElement>();

    const navigate = useNavigate();

    const isDirty = workType !== clientRole?.workType
        || !isEqual(expectedEngagementHours, clientRole?.expectedEngagementHours)
        || !isEqual(rateRange, clientRole?.rateRange);
    const isValid = workType && expectedEngagementHours?.length && rateRange?.length;

    const {mutate, isLoading} = useMutation(
        (payload: IBudgetRequest) => clientService.budgetSubmit(clientRole?.id || 0, payload),
        {
            onSuccess() {
                showToastSuccess('Budget was saved successfully!');
                navigate(nextStep);
                refetchClient();
                queryClient.invalidateQueries([CLIENT_ROLES]);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleChangeEngagementHours = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);

        if (typeof value === 'number') {
            if (value > MAX_SAFE_INTEGER / 100) {
                const max = parseInt((MAX_SAFE_INTEGER / 100).toString(), 10);

                setExpectedEngagementHours([max]);
            } else {
                setExpectedEngagementHours([value]);
            }
        }
    }, []);

    const handleClickType = useCallback((type: EWorkType) => {
        setExpectedEngagementHours(null);

        // clear by second click
        if (type === workType) {
            setWorkType(null);
            setRateRange(null);
        } else {
            setWorkType(type);
        }

        if (type === EWorkType.fixed) {
            fixedHoursRef.current?.focus();
        }
    }, [workType]);

    const handleChangeRateRange = useCallback((range: number[]) => {
        if (range[0] === rateRange?.[0]) {
            setRateRange(null);
        } else {
            setRateRange(range);
        }
    }, [rateRange]);

    const handleSaveAndExit = () => {
        if (isValid) {
            const payload = {...prepareData(), saveAndExit: true}; // fix GC-1172

            nextStep = `${RouteClientPortal.myRoles}/${ERoleStatusFilter.Drafts}/${roleId}`;
            mutate(payload as IBudgetRequest);
        }
    };

    const handleSubmit = () => {
        if (!workType) {
            showToastError('Please add a work type.');
        } else if (!expectedEngagementHours?.length) {
            showToastError('Please select a number of hours weekly');
        } else if (!rateRange?.length) {
            showToastError('Please select your hourly rate');
            // check is dirty or not
        } else if (isDirty) {
            const payload = prepareData();

            mutate(payload as IBudgetRequest);
        } else {
            navigate(nextStep);
        }
    };

    const prepareData = () => {
        return {
            workType,
            expectedEngagementHours,
            rateRange: rateRange?.map(it => it * 100) || []  // convert to cents
        };
    };

    useEffect(() => {
        if (!clientRole) {
            return;
        }
        setRateRange(clientRole.rateRange.map(it => it / 100));  // convert without cents
        setExpectedEngagementHours(clientRole.expectedEngagementHours);
        setWorkType(clientRole.workType);
    }, [clientRole]);

    const selectedMinWeeklyHours = useMemo(() => expectedEngagementHours?.[0], [expectedEngagementHours]);

    return (
        <AnimatePageWrp maxWidth={{md: 600, lg: 871}}>
            <BudgetTopTitle desktopFontSize={24}/>

            {
                isClientLoading
                    ? (
                        <LoadingMask/>
                    ) : (
                        <Box sx={{
                            margin: '0 auto',
                            maxWidth: '602px'
                        }}>

                            <BudgetType value={workType} onType={handleClickType}/>

                            <AnimateHeight isOverflowHidden={false}
                                isVisible={workType === EWorkType.hourly || workType === EWorkType.retainer}>

                                <BudgetWeeklyHours value={selectedMinWeeklyHours} onSelect={setExpectedEngagementHours}/>

                            </AnimateHeight>

                            <AnimateHeight isOverflowHidden={false} isVisible={workType === EWorkType.fixed}>
                                <BudgetFixedRate
                                    ref={fixedHoursRef}
                                    value={expectedEngagementHours?.length ? expectedEngagementHours[0] : null}
                                    onChange={handleChangeEngagementHours}
                                />
                            </AnimateHeight>
                            <AnimateHeight isOverflowHidden={false} isVisible={!!workType}>

                                <BudgetRateRange value={rateRange?.[0]} onChange={handleChangeRateRange}/>

                            </AnimateHeight>
                        </Box>
                    )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        mt: '72px',
                        mb: '40px',
                        gap: '16px',
                        justifyContent: 'center'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '63px'
                    },
                    '@media(max-height: 900px)': {
                        pb: '60px'
                    }
                }}
                textAlign="center"
            >
                <WhiteButton
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={() => navigate(prevStep)}
                >
                    Back
                </WhiteButton>
                <ContinueButton
                    disabled={isClientLoading || isLoading || !workType}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
                <GrayButton
                    disabled={!isValid || !isDirty}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>
            </Box>
            {
                isSmallScreen && (
                    <>
                        <Portal fullWidth order={1}>
                            <ContinueButton
                                disabled={isClientLoading || isLoading || !workType}
                                hasArrow
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Continue
                            </ContinueButton>
                        </Portal>
                        <Portal order={2}>
                            <WhiteButton
                                onClick={() => navigate(prevStep)}
                            >
                                Back
                            </WhiteButton>
                        </Portal>
                        <Portal order={3}>
                            <GrayButton
                                disabled={!isValid || !isDirty}
                                fullWidth
                                variant="contained"
                                onClick={handleSaveAndExit}
                            >
                                Save & exit
                            </GrayButton>
                        </Portal>
                    </>
                )

            }
        </AnimatePageWrp>
    );
};
