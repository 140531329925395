import React, {ReactNode} from 'react';

import {Box, Typography} from '@mui/material';

import {InfoIcon} from '../../assets/icons';
import theme from '../../theme';

interface NewToolAlertBoxProps {
    text?: ReactNode;
}

export const NewToolAlertBox: React.FC<NewToolAlertBoxProps> = React.memo(({text}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                mt: '24px',
                padding: '18px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '24px',
                border: `2px dashed ${theme.palette.lightGray.main}`,
                borderRadius: '16px',
                [theme.breakpoints.up('sm')]: {
                    padding: '24px',
                    paddingRight: '18px',
                    flexDirection: 'row'
                },
                'svg': {
                    flexShrink: 0
                }
            }}
        >
            <InfoIcon/>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: theme.palette.gray.main
                }}
            >
                {text || (
                    <>
                        We noticed that you added tools that are not yet approved by us. We’ll add them to your role &
                        matching after review
                    </>
                )}
            </Typography>
        </Box>
    );
});
