import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsClient, EngagementInfoBox} from '../../../../../../../components';
import {EEngagementStatus, EResumeStatus, IEngagementClient} from '../../../../../../../models';

type ResumeRequestProps = {
    data: IEngagementClient;
};

const ResumeRequest: React.FC<ResumeRequestProps> = ({data}) => {
    if (data.status !== EEngagementStatus.PAUSED || data.resume?.resumeStatus !== EResumeStatus.FREELANCER_REQUESTED) {
        return null;
    }

    const freelancerName = data.freelancerName.split(' ')[0];

    return (
        <EngagementInfoBox
            iconUrl="/assets/images/proposal-icon.png"
            title={`Resume engagement request from ${freelancerName}`}
        >
            {freelancerName} has sent you a resume engagement request.
                Please review it and decide if you want to continue cooperation.

            <Box
                sx={{
                    mt: '16px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <EngagementActionsClient.ViewProposalBtn data={data}/>
            </Box>

        </EngagementInfoBox>
    );
};

export default React.memo(ResumeRequest);
