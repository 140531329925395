import React, {useCallback, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {Box, IconButton} from '@mui/material';

import {applicationService} from '../../api';
import {EditIcon, TrashIcon} from '../../assets/icons';
import {RouteApplication, XL} from '../../constants';
import {useApplication} from '../../hooks';
import {ECaseStudySteps} from '../../models';
import theme from '../../theme';
import {getCaseStudyStep} from '../../utils';
import {ContinueButton, DeleteCaseStudyModal, LoadingMask, Portal, showToastError, WhiteButton} from '..';

import CaseStudyPreview from './CaseStudyPreview';

const iconsStyles = {
    backgroundColor: theme.palette.white.main,
    p: '14px',
    '&:hover': {
        backgroundColor: theme.palette.lightGray.main,
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.lightGray.main,
    },
    [theme.breakpoints.up('md')]: {
        p: '22px',
    }
};

const CaseStudyPreviewApplication = () => {
    const {application, isApplicationLoading, refetchApplication} = useApplication();
    const {caseStudyId} = useParams();
    const [openDelete, setOpenDelete] = useState(false);
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const isDraftMode = !!searchParams.get('draft');
    const isDeletable = useMemo(() => {
        const valids = application?.caseStudies.filter(it => getCaseStudyStep(it).view === ECaseStudySteps.testimonial);

        return valids ? valids.length > 2 : false;
    }, [application?.caseStudies]);

    const handleDeleteModalClose = useCallback(async (isDelete: boolean) => {
        setOpenDelete(false);

        if (!isDelete || !caseStudyId) return;

        try {
            await applicationService.caseStudyDelete(Number(caseStudyId));
            refetchApplication();
            navigate(RouteApplication.application);
        } catch (error) {
            showToastError(error as AxiosError);
        }
    }, [caseStudyId, navigate, refetchApplication]);

    const caseStudy = useMemo(() => application?.caseStudies.find(it => it.id === Number(caseStudyId)), [application, caseStudyId]);

    if (isApplicationLoading) {
        return (
            <LoadingMask/>
        );
    }

    const isValid = isDraftMode && caseStudy && getCaseStudyStep(caseStudy).view === ECaseStudySteps.testimonial;

    return (
        <>
            {/* Freelancer has Ediit, Delete and Save options */}
            <Box
                sx={{
                    position: 'fixed',
                    zIndex: 5,
                    top: '100px',
                    right: '20px',
                    display: 'grid',
                    gap: '16px',
                    [theme.breakpoints.up('md')]: {
                        top: '200px'
                    }
                }}
            >
                <IconButton
                    sx={iconsStyles}
                    onClick={() => navigate(`${RouteApplication.caseStudy}/edit/${caseStudyId}`)}
                >
                    <EditIcon/>
                </IconButton>
                <IconButton
                    disabled={!isDraftMode && !isDeletable}
                    sx={{
                        ...iconsStyles,
                        'path': {
                            fill: (isDraftMode || isDeletable) ? theme.palette.error.main : theme.palette.gray.main
                        }
                    }}
                    onClick={() => setOpenDelete(true)}
                >
                    <TrashIcon/>
                </IconButton>
            </Box>

            {caseStudy && (
                <CaseStudyPreview data={caseStudy}/>
            )}

            <Portal fullWidth order={1} visibleUntil={XL}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '12px',
                        m: '-40px -20px',
                        p: '16px 20px 40px 20px',
                        backgroundColor: theme.palette.white.main,
                        borderTop: `1px solid ${theme.palette.lightGray.main}`,
                        'button': {
                            width: 'calc(50% - 6px) !important',
                            'div': {
                                justifyContent: 'center'
                            },
                            [theme.breakpoints.up('md')]: {
                                width: 'auto !important'
                            },
                        },
                    }}
                >
                    <WhiteButton
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </WhiteButton>
                    {isDraftMode && (
                        <ContinueButton
                            disabled={!isValid}
                            variant="contained"
                            onClick={() => navigate(RouteApplication.application)}
                        >
                            Add case study
                        </ContinueButton>
                    )}

                </Box>
            </Portal>

            <DeleteCaseStudyModal isOpen={openDelete} onClose={handleDeleteModalClose}/>

        </>
    );
};

export default React.memo(CaseStudyPreviewApplication);
