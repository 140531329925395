import React from 'react';
import {AnimatePresence} from 'framer-motion';

import {Box} from '@mui/material';

import {IRoleWithMatching} from '../../../../models';
import {MyMatchesEmpty} from '../../../FreelancerPortal/MyMatches/components';

import MatchedItem from './MatchedItem';
import MatchedItemEmpty from './MatchedItemEmpty';

interface MatchedListProps {
    items?: IRoleWithMatching[];
}

const MatchedList: React.FC<MatchedListProps> = ({items}) => {
    if (!items?.length) {
        return <MyMatchesEmpty isClient/>;
    }

    return (
        <Box
            sx={{
                display: 'grid',
                gap: '24px'
            }}
        >
            <AnimatePresence mode="wait">
                {items.map((item, index) => {
                    return item.matching.resultsCount > 0
                        ? <MatchedItem key={item.id} index={index} item={item}/>
                        : <MatchedItemEmpty key={item.id} item={item}/>;
                })}
            </AnimatePresence>
        </Box>
    );
};

export default React.memo(MatchedList);
