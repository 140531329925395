import React from 'react';

import {ButtonProps} from '@mui/material';

import theme from '../../../theme';
import {Button} from '../../index';

const WhiteButton = ({children, ...props}: ButtonProps): JSX.Element => {
    return (
        <Button
            {...props}
            variant="contained"
            sx={{
                padding: '17px 20px 18px',
                backgroundColor: theme.palette.white.main,
                color: theme.palette.black.main,
                border: '2px solid',
                borderColor: theme.palette.lightGray.main,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.lightGray.main,
                },
                ...props.sx
            }}
        >
            {children}
        </Button>
    );
};

export default WhiteButton;
