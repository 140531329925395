import React, {useEffect, useRef} from 'react';
import {VideoTrack as TwilioVideoTrack} from 'twilio-video';

interface VideoTrackProps {
    track: TwilioVideoTrack;
}

const VideoTrack: React.FC<VideoTrackProps> = ({track}) => {
    const ref = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (track) {
            const el = ref.current;

            if (!el) return;

            track.attach(el);

            return () => {
                track.detach(el);
            };
        }
    }, [track]);

    return <video style={{maxWidth: '100%'}} ref={ref}/>;
};

export default VideoTrack;
