import React, {FC} from 'react';

import {Box, Theme, Typography, useMediaQuery} from '@mui/material';

import {BlackBorderButton, ContinueButton, DialogEmoji} from '../index';

export interface ConfirmDialogProps {
    ariaDescribedby: string;
    confirmationText: string;
    open: boolean;
    title: string;
    noBtnText?: string;
    yesBtnText?: string;
    onNo: () => void;
    onYes: () => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
    ariaDescribedby,
    title,
    confirmationText,
    noBtnText,
    yesBtnText,
    open,
    onYes,
    onNo
}) => {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <DialogEmoji
            open={open}
            aria-describedby={ariaDescribedby}
            actions={(
                <>
                    <ContinueButton
                        sx={{
                            width: mdDown ? '50% !important' : 'auto !important',
                            'div': {
                                justifyContent: 'center'
                            }
                        }}
                        variant="contained"
                        onClick={onYes}
                    >
                        {yesBtnText || 'Yes'}
                    </ContinueButton>
                    <BlackBorderButton
                        sx={{
                            width: mdDown ? '50%' : 'auto'
                        }}
                        onClick={onNo}
                    >
                        {noBtnText || 'No'}
                    </BlackBorderButton>
                </>
            )}
            title={(
                <>
                    <Box justifyContent="center">
                        <img src="/assets/images/cross-icon.png" width="48" height="48" alt="ok icon"/>
                    </Box>
                    {title}
                </>
            )}
            onClose={onNo}
            maxWidth="sm"
        >
            <Typography
                align="center"
                sx={{
                    mb: '32px'
                }}
            >
                {confirmationText}
            </Typography>
        </DialogEmoji>
    );
};

export default React.memo(ConfirmDialog);
