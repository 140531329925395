import React, {useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, Theme, useMediaQuery} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {
    AnimatePageWrp,
    ContinueButton,
    GrayButton,
    Languages,
    LoadingMask,
    LocationsMulti,
    PageTopTitle,
    Portal,
    showToastError,
    showToastSuccess,
    TimeZone,
    WhiteButton
} from '../../../../../../components';
import {BUDGET, CLIENT_ROLES, RouteClientPortal, TOOLS} from '../../../../../../constants';
import {useClientRole, useLocations} from '../../../../../../hooks';
import {ERoleStatusFilter, ILocationRequest, ISelectOption} from '../../../../../../models';
import theme from '../../../../../../theme';
import {TIMEZONES_OPTIONS} from '../../../../../../utils/timezones';

export const LocationTimezone = () => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const queryClient = useQueryClient();
    const {roleId} = useParams();
    const {clientRole, isClientLoading, refetchClient} = useClientRole(Number(roleId));
    const {
        countries: dataCountries,
        isLocationLoading,
        languages: dataLanguages,
    } = useLocations();
    const [countries, setCountries] = useState<ISelectOption[] | null>(null);
    const [languages, setLanguages] = useState<ISelectOption[] | null>(null);
    const [timezone, setTimezone] = useState<ISelectOption | null>(null);

    const navigate = useNavigate();

    let nextStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${BUDGET}`;
    const prevStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${TOOLS}`;


    const prepareData = () => {
        const payload: Partial<ILocationRequest> = {
            countries: countries?.map((item: ISelectOption) => item.text),   // FIXME BE do not support CountryCode but should!!!
            languages: languages?.map((item: ISelectOption) => item.text),
            timezone: timezone?.value
        };

        return payload;
    };

    const isDirty = clientRole ? !isEqual(prepareData(), {
        countries: clientRole.countries,
        languages: clientRole.languages,
        timezone: clientRole.timezone
    }) : false;
    const isValid = languages?.length;

    const {mutate, isLoading: isSubmitting} = useMutation(
        (payload: Partial<ILocationRequest>) => clientService.locationSubmit(clientRole?.id || 0, payload as ILocationRequest),
        {
            onSuccess() {
                showToastSuccess('Location was saved successfully!');
                navigate(nextStep);
                refetchClient();
                queryClient.invalidateQueries([CLIENT_ROLES]);
            },
            onError(error: AxiosError) {
                showToastError(error);
            }
        }
    );

    const optionsCountry = useMemo(() => dataCountries?.map(country => ({
        code: country.code,
        text: country.name,
        value: country.code
    })), [dataCountries]);

    const optionsLanguage = useMemo(() => dataLanguages?.map(lang => ({text: lang, value: lang})), [dataLanguages]);

    const handleSaveAndExit = () => {
        const payload = {...prepareData(), saveAndExit: true}; // fix GC-1172

        nextStep = `${RouteClientPortal.myRoles}/${ERoleStatusFilter.Drafts}/${roleId}`;
        mutate(payload);
    };

    // populate form
    useEffect(() => {
        if (!clientRole || !optionsCountry) {
            return;
        }

        const {countries, languages, timezone} = clientRole;

        if (countries?.length) {
            const values = optionsCountry?.filter(item => countries.includes(item.text));    // FIXME BE do not support CountryCode but should!!!

            setCountries(values);
        }

        if (languages) {
            const value = optionsLanguage?.filter(item => languages.includes(item.value)) || [];

            setLanguages(value);
        }

        if (timezone) {
            const value = TIMEZONES_OPTIONS.find(item => item.value === timezone) || null;

            setTimezone(value);
        }
    }, [clientRole, optionsCountry, optionsLanguage]);

    const handleSubmit = () => {
        if (!clientRole) return;

        const payload = prepareData();
        const prevValues = {
            countries: clientRole.countries,
            languages: clientRole.languages,
            timezone: clientRole.timezone
        };

        if (!languages?.length) {
            showToastError('Please select a language');
            // update if isDirty or first time there or redirect to the next step
        } else if (isEqual(payload, prevValues) && clientRole.view !== 'tools') {
            navigate(nextStep);
        } else {
            mutate(payload);
        }
    };

    const isSaveBtnDisabled = isSubmitting || isClientLoading || !isDirty || !isValid;

    return (
        <AnimatePageWrp maxWidth={{md: 700, lg: 800}}>
            <PageTopTitle
                desktopFontSize={24}
                title={<>
                    Do you require that the work is done in a specific <span>country</span> and <span>timezone</span>?
                </>}
                titleMaxWidth={{md: 464, lg: 464}}
            />

            {
                (isClientLoading || isLocationLoading) ? (
                    <LoadingMask/>
                ) : (
                    <>

                        <Box sx={{mb: 5}}>
                            <LocationsMulti
                                options={optionsCountry || []}
                                values={countries || []}
                                onChange={setCountries}
                            />
                        </Box>

                        <Box sx={{pb: 5}}>
                            <TimeZone
                                value={timezone}
                                onChange={setTimezone}
                            />
                        </Box>

                        <Languages
                            options={optionsLanguage || []}
                            values={languages || []}
                            onChange={setLanguages}
                        />

                    </>
                )
            }

            <Box
                textAlign="center"
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        mt: '40px',
                        mb: '40px',
                        justifyContent: 'center',
                        gap: '16px'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '51px'
                    }
                }}
            >
                <WhiteButton
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={() => navigate(prevStep)}
                >
                    Back
                </WhiteButton>
                <ContinueButton
                    disabled={isSubmitting || isClientLoading}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
                <GrayButton
                    disabled={isSaveBtnDisabled}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>
            </Box>

            {
                isSmallScreen &&
              <>
                  <Portal fullWidth order={1}>
                      <ContinueButton
                          disabled={isSubmitting || isClientLoading}
                          hasArrow
                          variant="contained"
                          onClick={handleSubmit}
                      >
                    Continue
                      </ContinueButton>
                  </Portal>
                  <Portal order={2}>
                      <WhiteButton
                          onClick={() => navigate(prevStep)}
                      >
                    Back
                      </WhiteButton>
                  </Portal>
                  <Portal order={3}>
                      <GrayButton
                          disabled={isSaveBtnDisabled}
                          fullWidth
                          variant="contained"
                          onClick={handleSaveAndExit}
                      >
                    Save & exit
                      </GrayButton>
                  </Portal>
              </>
            }

        </AnimatePageWrp>
    );
};
