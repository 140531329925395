import React, {useEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';

import {Box, Grid} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {applicationService} from '../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    Checkbox,
    ContinueButton,
    InputCaption,
    InputLanguages,
    LoadingMask,
    Location,
    PageTopTitle,
    Portal,
    showToastError,
    showToastSuccess,
    TimeZoneMulti
} from '../../../components';
import {RouteCreateApplicantAccount} from '../../../constants';
import {useApplication, useLocations, useNavigateUTM} from '../../../hooks';
import {IOthersRequest, ISelectOption} from '../../../models';
import theme from '../../../theme';
import {TIMEZONES_OPTIONS} from '../../../utils/timezones';

const OtherDetails = () => {
    const {application, isApplicationLoading, refetchApplication} = useApplication();
    const {
        countries: dataCountries,
        isLocationLoading,
        languages: dataLanguages,
    } = useLocations();

    const methods = useForm({});
    const navigate = useNavigateUTM();

    const {
        handleSubmit,
        setValue,
        // getValues,
        watch
    } = methods;

    const country = watch('country', '');
    const languages = watch('languages', []);
    const timezones = watch('timezones', []);

    const {mutate, isLoading: isSubmitting} = useMutation(applicationService.othersSubmit,
        {
            onSuccess() {
                showToastSuccess('Location was saved successfully!');
                navigate(RouteCreateApplicantAccount.confirmationCode);
                refetchApplication();
            },
            onError(error: AxiosError) {
                showToastError(error);
            }
        }
    );

    const optionsCountry = useMemo(() => dataCountries?.map(country => ({
        code: country.code,
        text: country.name,
        value: country.code,
    })), [dataCountries]);

    const optionsLanguage = useMemo(() => dataLanguages?.map(lang => ({text: lang, value: lang})), [dataLanguages]);

    useEffect(() => {
        if (!country) {
            setValue('languages', null);
            setValue('timezones', null);
            setValue('isAvailableForNewProject', false);
            setValue('isOpenToHireRole', false);
        } else if (!timezones?.length) {
            setValue('languages', null);
            setValue('isAvailableForNewProject', false);
            setValue('isOpenToHireRole', false);
        } else if (!languages?.length) {
            setValue('isAvailableForNewProject', false);
            setValue('isOpenToHireRole', false);
        }
    }, [country, languages, timezones, setValue]);

    useEffect(() => {
        if (!application || !optionsCountry) {
            return;
        }

        const {country, isAvailableForNewProject, isOpenToHireRole, languages, timezones} = application;

        if (country) {
            const value = optionsCountry?.find(item => item.text === country);    // FIXME BE do not support CountryCode but should!!!

            setValue('country', value);
        }

        if (timezones) {
            const value = TIMEZONES_OPTIONS.filter(item => timezones.includes(item.value));

            setValue('timezones', value);
        }

        if (languages) {
            const value = optionsLanguage?.filter(item => languages.includes(item.value));

            setValue('languages', value);
        }
        setValue('isAvailableForNewProject', !!isAvailableForNewProject);
        setValue('isOpenToHireRole', !!isOpenToHireRole);
    }, [application, optionsCountry, optionsLanguage, setValue]);

    const isValid = country && timezones?.length && languages?.length;

    const onSubmitHandler: SubmitHandler<any> = (values) => {
        if (!isValid || !application) return;

        const {country, isAvailableForNewProject, isOpenToHireRole, timezones} = application;
        const payload: IOthersRequest = {
            country: values.country.text,   // FIXME BE do not support CountryCode but should!!!
            isAvailableForNewProject: values.isAvailableForNewProject,
            isOpenToHireRole: values.isOpenToHireRole,
            languages: values.languages.map((item: ISelectOption) => item.value),
            timezones: values.timezones.map((item: ISelectOption) => item.value),
        };

        // update if isDirty or redirect to the next step
        if (isEqual(payload, {country, isAvailableForNewProject, isOpenToHireRole, languages, timezones})) {
            navigate(RouteCreateApplicantAccount.confirmationCode);
        } else {
            mutate(payload);
        }
    };

    return (
        <AnimatePageWrp maxWidth={{md: 600, lg: 821}}>
            <PageTopTitle
                title={<>
                    Finally, please select your location, etc...
                </>}
                titleMaxWidth={{md: 490, lg: 700}}
            />

            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmitHandler)}
                    noValidate
                    autoComplete="off"
                >
                    <Box maxWidth={600} margin="0 auto">
                        {
                            (isApplicationLoading || isLocationLoading) ? (
                                <LoadingMask/>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            mt: '44px',
                                            mb: '40px',
                                            [theme.breakpoints.up('md')]: {
                                                mb: '64px'
                                            }
                                        }}
                                    >
                                        <Location
                                            label={(
                                                <InputCaption>
                                                    Select your <span>location</span>
                                                </InputCaption>
                                            )}
                                            options={optionsCountry || []}
                                        />
                                    </Box>

                                    <AnimateHeight isVisible={!!country}>
                                        <TimeZoneMulti
                                            label={(
                                                <InputCaption>
                                                    Select a <span>time zone</span>
                                                </InputCaption>
                                            )}
                                            subTitle="Please select any time zones you are comfortable working in. Choosing more time zones will enable to you work remotely on projects from other regions."
                                        />
                                    </AnimateHeight>

                                    <AnimateHeight isVisible={!!country && !!timezones?.length}>
                                        <Box
                                            sx={{
                                                py: '40px',
                                                [theme.breakpoints.up('md')]: {
                                                    pb: '64px'
                                                }
                                            }}
                                        >
                                            <InputLanguages
                                                label={(
                                                    <InputCaption>
                                                        Select a <span>language</span>
                                                    </InputCaption>
                                                )}
                                                options={optionsLanguage || []}
                                                subTitle="Please select language(s) you&apos;re good at"
                                            />
                                        </Box>
                                    </AnimateHeight>

                                    <AnimateHeight isVisible={isValid} isOverflowHidden={false}>
                                        <Grid
                                            container
                                            spacing={2}
                                            rowSpacing={0}
                                            sx={{
                                                mt: '14px',
                                                [theme.breakpoints.up('lg')]: {
                                                    mt: '23px'
                                                }
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={7}
                                                sx={{
                                                    mb: '-10px',
                                                    [theme.breakpoints.up('lg')]:
                                                        {mb: '-2px'}
                                                }}
                                            >
                                                <Checkbox
                                                    label="I'm open to working with Agencies"
                                                    name="isAvailableForNewProject"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={7}>
                                                <Checkbox
                                                    label="I’m open to contract-to-hire roles"
                                                    name="isOpenToHireRole"
                                                />
                                            </Grid>
                                        </Grid>
                                    </AnimateHeight>
                                </>
                            )
                        }

                        <Box
                            textAlign="center"
                            sx={{
                                display: 'none',
                                [theme.breakpoints.up('sm')]: {
                                    display: 'block',
                                    mt: '54px',
                                    pb: '48px'
                                }
                            }}
                        >
                            <ContinueButton
                                disabled={isApplicationLoading || !isValid || isSubmitting}
                                hasArrow
                                type="submit"
                                variant="contained"
                            >
                                Continue
                            </ContinueButton>
                        </Box>

                        <Portal order={2}>
                            <ContinueButton
                                disabled={isApplicationLoading || !isValid || isSubmitting}
                                hasArrow
                                sx={{
                                    [theme.breakpoints.up('sm')]: {
                                        display: 'none'
                                    }
                                }}
                                variant="contained"
                                onClick={handleSubmit(onSubmitHandler)}
                            >
                                Continue
                            </ContinueButton>
                        </Portal>
                    </Box>
                </form>
            </FormProvider>
        </AnimatePageWrp>
    );
};

export default OtherDetails;
