import React, {ReactNode, useEffect} from 'react';
import clsx from 'clsx';
import {createPortal} from 'react-dom';

import {Box, Theme} from '@mui/material';
import {SxProps} from '@mui/system';

import {LG, MD, SM, XL} from '../../constants';

interface IProps {
    children: ReactNode;
    fullWidth?: boolean;
    order: number;
    innerPages?: boolean;
    sx?: SxProps<Theme>;
    visibleUntil?: typeof SM | typeof MD | typeof LG | typeof XL;
}

const Portal: React.FC<IProps> = ({children, fullWidth, order, innerPages, sx, visibleUntil}) => {
    const mount = document.getElementById('buttons-portal');

    visibleUntil && mount?.classList.add(`visible-${visibleUntil}`);
    innerPages && mount?.classList.add('inner-pages-style');

    useEffect(() => {
        return () => {
            visibleUntil && mount?.classList.remove(`visible-${visibleUntil}`);
            mount?.classList.remove('inner-pages-style');
        };
    }, [mount?.classList, visibleUntil]);

    if (!mount) return null;

    return createPortal(
        <Box
            className={clsx({btn: true, fullWidth})}
            sx={{
                order,
                ...sx
            }}
        >
            {children}
        </Box>, mount);
};

export default Portal;
