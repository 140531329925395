import React, {ReactNode} from 'react';

import {Box} from '@mui/material';
import {Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system';

interface Props {
    children: ReactNode;
    sx?: SxProps<Theme>;
}

export const Card: React.FC<Props> = React.memo(({children, sx}) => {
    return (
        <Box sx={{
            background: 'white',
            borderRadius: 4,
            px: '24px',
            py: '40px',
            ...sx
        }}>
            {children}
        </Box>
    );
});

export const CardTitle: React.FC<Props> = React.memo(({children, sx}) => {
    return (
        <Typography sx={{
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '21px',
            mb: '4px',
            ...sx
        }}>
            {children}
        </Typography>
    );
});

export const CardSubTitle: React.FC<Props> = React.memo(({children, sx}) => {
    return (
        <Typography sx={{
            color: 'gray.main',
            fontSize: 14,
            lineHeight: '21px',
            ...sx
        }}>
            {children}
        </Typography>
    );
});

export const CardContent: React.FC<Props> = React.memo(({children, sx}) => {
    return (
        <Box sx={{
            pt: 3,
            pb: 4,
            '&:first-of-type': {
                pt: 0
            },
            '&:last-of-type': {
                pb: 0
            },
            ...sx
        }}>
            {children}
        </Box>
    );
});
