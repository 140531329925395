import React from 'react';

import PageTopTitle from '../PageTopTitle';

interface IProps {
    desktopFontSize?: number;
}

export const ClientTopTitleTools: React.FC<IProps> = React.memo(({desktopFontSize}) => {
    return (
        <PageTopTitle
            desktopFontSize={desktopFontSize}
            title={<>What <span>tools</span> expertise does your expert need?</>}
            subtitle="Enter as many tools as you want, no limitation!"
            titleMaxWidth={{
                md: 358,
                lg: 600
            }}
        />
    );
});
