import React, {useMemo} from 'react';
import {AxiosError} from 'axios';
import {useNavigate, useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {matchesService} from '../../api';
import {CLIENT_MATCHES} from '../../constants';
import {BackButton, LoadingMask, showToastError} from '..';

import CaseStudyPreview from './CaseStudyPreview';

const CaseStudyPreviewClient = () => {
    const {caseStudyId, matchId} = useParams();

    const {
        isLoading,
        data,
    } = useQuery([CLIENT_MATCHES, Number(matchId)], () => matchesService.getMatch(Number(matchId)), {
        enabled: !!matchId,
        staleTime: 15 * 60 * 1000,
        select: ((response) => response.data),
        onError: (error) => showToastError(error as AxiosError)
    });

    const caseStudy = useMemo(() => data?.dynamicProfile.caseStudies.find(it => it.id === Number(caseStudyId)), [caseStudyId, data]);

    console.log('data', data, caseStudy);

    const navigate = useNavigate();

    if (isLoading) {
        return (
            <LoadingMask/>
        );
    }

    return (
        <>
            <Box
                sx={{mt: 4}}
            >
                <BackButton onClick={() => navigate(-1)}/>
            </Box>

            {caseStudy && (
                <CaseStudyPreview data={caseStudy}/>
            )}

        </>
    );
};

export default React.memo(CaseStudyPreviewClient);
