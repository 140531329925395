import React from 'react';

const CheckCurvyIconDisabled = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0158 5.25441C22.0166 2.61574 25.9834 2.61574 27.9842 5.25441L28.6617 6.14799C29.7534 7.58774 31.5369 8.3265 33.3269 8.08039L34.4379 7.92764C37.7185 7.47658 40.5234 10.2815 40.0724 13.5621L39.9196 14.6731C39.6735 16.4631 40.4123 18.2466 41.852 19.3383L42.7456 20.0158C45.3843 22.0166 45.3843 25.9834 42.7456 27.9842L41.852 28.6617C40.4123 29.7534 39.6735 31.5369 39.9196 33.3269L40.0724 34.4379C40.5234 37.7185 37.7185 40.5234 34.4379 40.0724L33.3269 39.9196C31.5369 39.6735 29.7534 40.4123 28.6617 41.852L27.9842 42.7456C25.9834 45.3843 22.0166 45.3843 20.0158 42.7456L19.3383 41.852C18.2466 40.4123 16.4631 39.6735 14.6731 39.9196L13.5621 40.0724C10.2815 40.5234 7.47658 37.7185 7.92764 34.4379L8.08039 33.3269C8.3265 31.5369 7.58774 29.7534 6.14799 28.6617L5.25441 27.9842C2.61574 25.9834 2.61574 22.0166 5.25441 20.0158L6.14799 19.3383C7.58774 18.2466 8.3265 16.4631 8.08039 14.6731L7.92764 13.5621C7.47658 10.2815 10.2815 7.47658 13.5621 7.92764L14.6731 8.08039C16.4631 8.3265 18.2466 7.58774 19.3383 6.14799L20.0158 5.25441Z" fill="#E3E3E3"/>
            <path d="M29.75 23.25H19.25C19.0511 23.25 18.8603 23.329 18.7197 23.4697C18.579 23.6103 18.5 23.8011 18.5 24C18.5 24.1989 18.579 24.3897 18.7197 24.5303C18.8603 24.671 19.0511 24.75 19.25 24.75H29.75C29.9489 24.75 30.1397 24.671 30.2803 24.5303C30.421 24.3897 30.5 24.1989 30.5 24C30.5 23.8011 30.421 23.6103 30.2803 23.4697C30.1397 23.329 29.9489 23.25 29.75 23.25Z" fill="#999999"/>
        </svg>
    );
};

export default CheckCurvyIconDisabled;
