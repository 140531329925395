import React, {FC, useCallback} from 'react';

import {Autocomplete, Box, TextField} from '@mui/material';

import {ISelectOption} from '../../models';
import {TIMEZONES_OPTIONS} from '../../utils/timezones';
import {InputCaption} from '../Forms';

interface ITimeZone {
    value: ISelectOption | null;
    onChange: (value: ISelectOption | null) => void;
}

const TimeZone: FC<ITimeZone> = (props): JSX.Element => {
    const handleOptionLabel = (option: ISelectOption) => {
        return option.text;
    };

    const isSelected = useCallback((value: string) => props.value?.value === value, [props]);

    const localTime = (timeZone: string) => {
        try {
            const arr = new Date().toLocaleTimeString('en-GB', {timeZone}).split(':');

            return `${arr[0]}:${arr[1]}`;
        } catch (e) {
            return '';
        }
    };

    return (
        <>
            <InputCaption>
                Select a <span>time zone</span> (optional)
            </InputCaption>

            <Autocomplete
                id="timezone"
                fullWidth
                getOptionLabel={handleOptionLabel}
                noOptionsText=""
                options={TIMEZONES_OPTIONS}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        placeholder="Select time zone"
                        variant="standard"
                    />
                )}
                renderOption={(props, option) => (
                    <Box
                        {...props}
                        component="li"
                        sx={{
                            '& > span': {flexGrow: 1},
                            color: isSelected(option.value) ? 'primary.main' : 'default',
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <span>{option.text}</span> {localTime(option.value)}
                    </Box>
                )}
                value={props.value}
                onChange={(event, value) => props.onChange(value)}
            />
        </>
    );
};

export default React.memo(TimeZone);
