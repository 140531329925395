import React from 'react';

const EditIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.3307 6.0327C18.3314 5.92302 18.3104 5.81431 18.2689 5.71277C18.2274 5.61124 18.1663 5.5189 18.0891 5.44103L14.5557 1.9077C14.4779 1.83046 14.3855 1.76936 14.284 1.72789C14.1825 1.68642 14.0737 1.6654 13.9641 1.66603C13.8544 1.6654 13.7457 1.68642 13.6442 1.72789C13.5426 1.76936 13.4503 1.83046 13.3724 1.9077L11.0141 4.26603L1.90574 13.3744C1.82851 13.4522 1.7674 13.5446 1.72593 13.6461C1.68446 13.7476 1.66344 13.8564 1.66408 13.966V17.4994C1.66408 17.7204 1.75187 17.9323 1.90815 18.0886C2.06443 18.2449 2.2764 18.3327 2.49741 18.3327H6.03074C6.14735 18.339 6.26399 18.3208 6.37309 18.2792C6.4822 18.2375 6.58134 18.1734 6.66408 18.091L15.7224 8.9827L18.0891 6.66603C18.1651 6.58526 18.2271 6.49231 18.2724 6.39103C18.2804 6.3246 18.2804 6.25745 18.2724 6.19103C18.2763 6.15224 18.2763 6.11315 18.2724 6.07436L18.3307 6.0327ZM5.68908 16.666H3.33074V14.3077L11.6057 6.0327L13.9641 8.39103L5.68908 16.666ZM15.1391 7.21603L12.7807 4.8577L13.9641 3.6827L16.3141 6.0327L15.1391 7.21603Z"
                fill="black"/>
        </svg>
    );
};

export default EditIcon;
