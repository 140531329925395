import React from 'react';

const StripeLogo = () => {
    return (
        <svg width="92" height="38" viewBox="0 0 92 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M91.9995 19.6285C91.9995 13.1953 88.8306 8.11914 82.774 8.11914C76.6917 8.11914 73.0117 13.1953 73.0117 19.5782C73.0117 27.1422 77.3562 30.9619 83.5917 30.9619C86.6329 30.9619 88.9329 30.2834 90.6706 29.3285V24.3026C88.9329 25.157 86.9395 25.6847 84.4095 25.6847C81.9306 25.6847 79.7328 24.8303 79.4517 21.865H91.9484C91.9484 21.5383 91.9995 20.2316 91.9995 19.6285ZM79.3751 17.2412C79.3751 14.4015 81.1384 13.2204 82.7484 13.2204C84.3073 13.2204 85.9684 14.4015 85.9684 17.2412H79.3751Z"
                fill="#7E5FFF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M63.1509 8.11914C60.6464 8.11914 59.0364 9.2751 58.142 10.0792L57.8097 8.52121H52.1875V37.8223L58.5764 36.4904L58.6019 29.3787C59.522 30.0321 60.8764 30.9619 63.1253 30.9619C67.6997 30.9619 71.8653 27.3432 71.8653 19.3772C71.8398 12.0896 67.6231 8.11914 63.1509 8.11914ZM61.6175 25.4334C60.1097 25.4334 59.2153 24.9057 58.6019 24.2523L58.5764 14.9292C59.2408 14.2005 60.1608 13.6979 61.6175 13.6979C63.9431 13.6979 65.5531 16.2611 65.5531 19.5531C65.5531 22.9204 63.9686 25.4334 61.6175 25.4334Z"
                fill="#7E5FFF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M43.3945 6.637L49.809 5.28001V0.178711L43.3945 1.51058V6.637Z" fill="#7E5FFF"/>
            <path d="M49.809 8.54688H43.3945V30.5352H49.809V8.54688Z" fill="#7E5FFF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M36.5187 10.4055L36.1099 8.54589H30.5898V30.5342H36.9787V15.6324C38.4865 13.6974 41.0421 14.0493 41.8343 14.3257V8.54589C41.0165 8.24433 38.0265 7.69148 36.5187 10.4055Z"
                fill="#7E5FFF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M23.7416 3.09277L17.506 4.39951L17.4805 24.5283C17.4805 28.2474 20.3171 30.9866 24.0994 30.9866C26.1949 30.9866 27.7283 30.6096 28.5716 30.1573V25.056C27.7538 25.3827 23.716 26.5386 23.716 22.8195V13.8985H28.5716V8.54588H23.716L23.7416 3.09277Z"
                fill="#7E5FFF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M6.46557 14.9292C6.46557 13.9492 7.28335 13.5723 8.63779 13.5723C10.58 13.5723 13.0334 14.1502 14.9756 15.1805V9.2751C12.8545 8.44582 10.7589 8.11914 8.63779 8.11914C3.45001 8.11914 0 10.7829 0 15.2308C0 22.1666 9.71113 21.0609 9.71113 24.0513C9.71113 25.2072 8.6889 25.5842 7.25779 25.5842C5.13668 25.5842 2.42778 24.7298 0.281111 23.5738V29.5546C2.65778 30.5598 5.06001 30.987 7.25779 30.987C12.5734 30.987 16.2278 28.3987 16.2278 23.9005C16.2022 16.4119 6.46557 17.7438 6.46557 14.9292Z"
                fill="#7E5FFF"/>
        </svg>
    );
};

export default StripeLogo;
