import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Tab, Tabs} from '../../../../../components';
import {RouteFreelancerPortal} from '../../../../../constants';

import {EFilter, EFilterDisplay} from './filter.model';


const filterItemsList = [
    EFilter.All,
    EFilter.Active,
    EFilter.Archive
];

const StatusFilter = () => {
    const {filterBy} = useParams();
    const navigate = useNavigate();

    return (
        <Tabs>
            {
                filterItemsList.map(item =>
                    <Tab
                        isActive={filterBy?.toLowerCase() === item.toLowerCase()}
                        key={item}
                        onClick={() => navigate(`${RouteFreelancerPortal.myMatches}/${item}`)}
                    >
                        {EFilterDisplay[item]}
                    </Tab>
                )
            }
        </Tabs>
    );
};

export default React.memo(StatusFilter);
