import React, {FC} from 'react';
import {object, string} from 'zod';

import {Grid} from '@mui/material';

import {Input} from '../../../../../../components';
import {ISelectOption} from '../../../../../../models';

import BillingAddressForm, {billingAddressSchema} from './BillingAddressForm';
import {stylesInput} from './DialogPaymentMethod';

export const sepaSchema = object({
    number: string()
        .trim()
        .min(18, 'Number is required')
        .max(33, 'Value is too long'),
    ...billingAddressSchema
});

interface IProps {
    optionsCountry?: ISelectOption[];
}

const PaymentSEPAForm: FC<IProps> = ({optionsCountry}) => {
    return (
        <>
            <Grid container spacing="24px" sx={{mt: '16px'}}>
                <Grid item xs={12}>
                    <Input
                        label="IBAN"
                        name="number"
                        placeholder="AA00000000000000000000"
                        sx={stylesInput}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <BillingAddressForm optionsCountry={optionsCountry}/>
        </>
    );
};

export default React.memo(PaymentSEPAForm);
