import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import orderBy from 'lodash/orderBy';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import {useMutation, useQuery} from '@tanstack/react-query';

import {freelancerService} from '../../../../api';
import {ClockIcon} from '../../../../assets/icons';
import {
    BlackBorderButton,
    CardSubTitle,
    CardTitle,
    HistoryBoxTitle,
    LoadingMask,
    PaymentMethodsList,
    Radio,
    showToastError,
    showToastSuccess
} from '../../../../components';
import {useAuth} from '../../../../hooks';
import {LegalDocType} from '../../../../models';
import theme from '../../../../theme';
import {applicantSchema, findFieldError, replaceSymbolsInPhone} from '../../../../utils';
import {useFreelancer} from '../../contexts';

import DialogPandaDoc from './Billings/DialogPandaDoc';
import HistoryItem from './Billings/HistoryItem';

export type AccountInput = TypeOf<typeof applicantSchema>;

export const BillingTab = React.memo(() => {
    const {isImpersonal} = useAuth();
    const {state: {freelancer}, refetchFleelancer} = useFreelancer();
    const [citizenship, setCitizenship] = useState('us');
    const [isLoadingLink, setIsLoadingLink] = useState(false);
    const [openForm, setOpenForm] = useState<LegalDocType | null>(null);

    const {
        isLoading,
        data: paymentMethodsData,
    } = useQuery(['payouts'], freelancerService.paymentMethods, {
        staleTime: 15 * 60 * 1000,
        select: ((response) => response.data)
    });

    const methods = useForm<AccountInput>({
        resolver: zodResolver(applicantSchema),
    });

    const {
        handleSubmit,
        // formState: {isDirty},
        setError,
        setValue,
        reset
    } = methods;

    const {mutate, /*isLoading: isSubmitting*/} = useMutation(
        ({phone, ...values}: AccountInput) => {
            const updatedPhone = replaceSymbolsInPhone(phone);

            // FIXME use update endpoint
            return freelancerService.submitFleelancer({...values, phone: updatedPhone});
        },
        {
            // Create new account
            onSuccess() {
                showToastSuccess('Account was successfully updated!');
                refetchFleelancer();
            },
            onError(error: AxiosError) {
                const phone = findFieldError(error, 'phone');

                if (phone) {
                    showToastError('Phone number is invalid');
                    setError('phone', {type: 'custom', message: phone.message});
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const handlePandaDocClose = useCallback(async (id?: string) => {
        setOpenForm(null);

        if (id) {
            try {
                await freelancerService.legalDocSubmit(id);

                refetchFleelancer();
            } catch (error) {
                showToastError(error as AxiosError);
            }
        }
    }, [refetchFleelancer]);

    const handleRadioChange = useCallback((citizenship: string) => {
        setCitizenship(citizenship);
    }, []);

    const handleStripeLinkClick = async () => {
        setIsLoadingLink(true);
        try {
            const response = await freelancerService.paymentMethodsLink();

            window.open(response.data.onboardingUrl, '_self');
        } catch (error) {
            showToastError(error as AxiosError);
        }
        setIsLoadingLink(false);
    };

    const onSubmitHandler: SubmitHandler<AccountInput> = (values) => {
        // 👇 Execute the Mutation
        mutate(values);
    };

    useEffect(() => {
        if (!freelancer) return;

        const {firstName, lastName, email, phone} = freelancer;

        reset({firstName, lastName, email, phone});
    }, [freelancer, reset]);

    useEffect(() => {
        if (freelancer?.email) {
            setValue('email', freelancer.email);
        }
    }, [freelancer?.email, setValue]);

    const currentYearItem = useMemo(() => {
        const type = citizenship === 'us' ? 'W9' : 'W8';
        const year = new Date().getFullYear();

        return freelancer?.legalDocuments?.find(it => it.taxYear === year && it.type === type);
    }, [citizenship, freelancer?.legalDocuments]);

    const legalDocuments = useMemo(() => orderBy(freelancer?.legalDocuments || [], 'taxYear', 'desc'), [freelancer?.legalDocuments]);

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmitHandler)}
                    noValidate
                    autoComplete="off"
                >
                    <Box sx={{maxWidth: 664}}>
                        <Typography
                            sx={{
                                mt: '32px',
                                mb: '20px',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: 1.5,
                                [theme.breakpoints.up('md')]: {
                                    mt: 0,
                                    mb: '32px',
                                    fontSize: '16px'
                                }
                            }}
                        >
                            Billing
                        </Typography>

                        <Box
                            sx={{
                                mb: '48px',
                                [theme.breakpoints.up('md')]: {
                                    mb: '64px'
                                }
                            }}
                        >
                            <CardTitle>Payout details</CardTitle>
                            <CardSubTitle sx={{mb: '16px'}}>
                                We partner with Stripe for payouts. Click below to use Stripe’s secure to enter your
                                payout details.
                            </CardSubTitle>

                            <PaymentMethodsList
                                isLoading={isLoading}
                                items={paymentMethodsData?.payoutMethods}
                            />

                            {/* init Stripe onboarding */}
                            {!isLoading && (
                                <BlackBorderButton
                                    disabled={isLoadingLink || isImpersonal}
                                    size="small"
                                    sx={{
                                        mb: '16px'
                                    }}
                                    onClick={() => handleStripeLinkClick()}
                                >
                                    {isLoadingLink ? (
                                        <>
                                            <LoadingMask hasNoSpace size={10}/>
                                            &nbsp;
                                        </>
                                    ) : null} {paymentMethodsData?.onboardingFinished ? 'Edit your Stripe informations' : 'Initialize stripe account'}
                                </BlackBorderButton>
                            )}

                        </Box>

                        <Box
                            sx={{
                                mb: '48px',
                                [theme.breakpoints.up('md')]: {
                                    mb: '64px'
                                }
                            }}
                        >
                            <CardTitle>Legal documents</CardTitle>
                            <CardSubTitle sx={{mb: '16px'}}>
                                Upload a W-9/W-8 form to be able to start getting matched
                            </CardSubTitle>
                            <Box
                                sx={{
                                    p: '24px',
                                    mb: '24px',
                                    backgroundColor: theme.palette.white.main,
                                    borderRadius: '24px',
                                    border: `1px solid ${theme.palette.lightGray.main}`
                                }}
                            >
                                <CardSubTitle sx={{mb: '8px'}}>I’m a</CardSubTitle>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        mb: '24px'
                                    }}
                                >
                                    <Radio
                                        label="US-citizen"
                                        color={citizenship === 'us' ? 'success' : 'primary'}
                                        checked={citizenship === 'us'}
                                        onClick={() => handleRadioChange('us')}
                                    />
                                    <Radio
                                        label="Non-US-citizen"
                                        color={citizenship === 'non-us' ? 'success' : 'primary'}
                                        checked={citizenship === 'non-us'}
                                        onClick={() => handleRadioChange('non-us')}
                                    />
                                </Box>
                                {
                                    citizenship === 'us' ? (
                                        <>
                                            <CardTitle>Fill in a W9 Form</CardTitle>
                                            <CardSubTitle sx={{mb: '16px'}}>
                                                The W9 tax form also called the {'\"'}Request for Taxpayer
                                                Identification
                                                Number and Certification{'\"'} form, is a document in the US income tax
                                                system used by a third party who must file an information return with
                                                the
                                                IRS.
                                            </CardSubTitle>
                                            <BlackBorderButton
                                                disabled={!!currentYearItem?.completedAt || isImpersonal}
                                                size="small"
                                                onClick={() => setOpenForm('W9')}
                                            >
                                                + Create a W9 Form
                                            </BlackBorderButton>
                                        </>
                                    ) : (
                                        <>
                                            <CardTitle>Fill in a W8-BEN Form</CardTitle>
                                            <CardSubTitle sx={{mb: '16px'}}>
                                                The W-8 and W-8 BEN are forms that the IRS requires for foreign
                                                individuals or foreign entities that lack U.S. citizenship or residency,
                                                but have worked in the U.S. or have earned income in the U.S.
                                            </CardSubTitle>
                                            <BlackBorderButton
                                                disabled={!!currentYearItem?.completedAt || isImpersonal}
                                                size="small"
                                                onClick={() => setOpenForm('W8')}
                                            >
                                                + Create a W8-BEN Form
                                            </BlackBorderButton>
                                        </>
                                    )
                                }
                            </Box>

                            {legalDocuments?.length ? (
                                <Box>
                                    <HistoryBoxTitle
                                        statusContent={(
                                            <>
                                                <ClockIcon/> Previously uploaded
                                            </>
                                        )}
                                        sx={{
                                            mb: '8px',
                                            'path': {
                                                fill: theme.palette.gray.main
                                            }
                                        }}
                                    />

                                    {legalDocuments.map(item => (
                                        <HistoryItem data={item} key={item.id}/>
                                    ))}

                                </Box>
                            ) : null}

                        </Box>
                    </Box>
                </form>
            </FormProvider>

            <DialogPandaDoc
                id={currentYearItem?.completedAt ? currentYearItem?.id : ''}
                type={openForm}
                onClose={handlePandaDocClose}
            />
        </>
    );
});
