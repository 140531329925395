import React from 'react';
import {AnimatePresence} from 'framer-motion';
import {useRoutes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import {CssBaseline} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {NotificationContextProvider} from './contexts';
import routes from './routes';

import 'react-toastify/dist/ReactToastify.css';

function App() {
    const content = useRoutes(routes);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <NotificationContextProvider>
                <CssBaseline/>
                <ToastContainer/>
                <AnimatePresence mode="wait" initial={false}>
                    {content}
                </AnimatePresence>
            </NotificationContextProvider>
        </LocalizationProvider>
    );
}

export default App;
