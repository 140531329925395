import React from 'react';
import {Outlet} from 'react-router-dom';

import {Grid} from '@mui/material';

import {AsideBlock, ErrorBoundary, MainContent} from '../../../components';
import theme from '../../../theme';
import SidebarMenu from '../SidebarMenu';


const WidgetLayout = () => {
    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'column',
                [theme.breakpoints.up('sm')]: {
                    flexDirection: 'row'
                }
            }}
        >
            <AsideBlock>
                <SidebarMenu/>
            </AsideBlock>
            <MainContent>
                <ErrorBoundary>
                    <Outlet/>
                </ErrorBoundary>
            </MainContent>
        </Grid>
    );
};

export default WidgetLayout;
