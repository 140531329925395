import React from 'react';
import isBoolean from 'lodash/isBoolean';

import {Pill, Tooltip} from '../../..';

interface AcceptedPillProps {
    isAuthor?: boolean;
}

const AcceptedPill: React.FC<AcceptedPillProps> = ({isAuthor}) => {
    const isTitle = isBoolean(isAuthor);

    const content = (
        <Pill
            hasIcon
            hasOutline
            iconPosition="start"
            variant="green"
        >
            Accepted proposal
        </Pill>
    );

    if (isTitle) {
        return (
            <Tooltip title={isAuthor ? 'Your proposal has been accepted' : 'The proposal has been accepted'}>
                <span>
                    {content}
                </span>
            </Tooltip>
        );
    }

    return (
        content
    );
};

export default React.memo(AcceptedPill);
