import {styled} from '@mui/material';

import theme from '../../theme';

export const Tabs = styled('div')<{ hasScroll?: boolean; fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  gap: 48px;
  border-bottom: ${props => props.fullWidth ? '1px solid #E3E3E3' : ''};
  overflow-x: ${props => props.hasScroll ? 'auto' : 'unset'};
`;

export const Tab = styled('div')<{ disabled?: boolean; isActive?: boolean }>`
  flex-shrink: 0;
  padding: 8px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.isActive
        ? theme.palette.black.main
        : props => props.disabled
            ? theme.palette.gray.light
            : theme.palette.gray.main};
  position: relative;
  transition: color .3s;
  cursor: pointer;

  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.isActive ? theme.palette.black.main : 'transparent'};
    transition: background-color .3s;
  }

  &:focus {
    outline: none;
  }
`;
