import React from 'react';

const RadioCheckedCircleIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#7E5FFF"/>
            <circle cx="12" cy="12" r="4" fill="white"/>
        </svg>
    );
};

export default RadioCheckedCircleIcon;
