import CaseStudyEdit from './CaseStudyEdit/CaseStudyEdit';
import {Step1Description} from './CaseStudyEdit/components/Step1Description';
import {Step2Company} from './CaseStudyEdit/components/Step2Company';
import {Step3Challenge} from './CaseStudyEdit/components/Step3Challenge';
import {Step4Outcomes} from './CaseStudyEdit/components/Step4Outcomes';
import {Step5Testimonial} from './CaseStudyEdit/components/Step5Testimonial';
import CaseStudyAnimatedGadient from './CaseStudyAnimatedGadient';
import CaseStudyItem from './CaseStudyItem';
import CaseStudyPreviewApplication from './CaseStudyPreviewApplication';
import CaseStudyPreviewClient from './CaseStudyPreviewClient';
import CaseStudyPreviewFreelancer from './CaseStudyPreviewFreelancer';
import {DeleteCaseStudyModal} from './DeleteCaseStudyModal';

export * from './context/case-study.context';

export {
    CaseStudyAnimatedGadient,
    CaseStudyEdit,
    CaseStudyItem,
    CaseStudyPreviewApplication,
    CaseStudyPreviewClient,
    CaseStudyPreviewFreelancer,
    DeleteCaseStudyModal,
};

export const CaseStudy = {
    Step1Description,
    Step2Company,
    Step3Challenge,
    Step4Outcomes,
    Step5Testimonial,
};
