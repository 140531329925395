import React, {useCallback} from 'react';

import {Box} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../../api';
import {BlackBorderButton, EngagementInfoBox} from '../../../../../../../components';
import {CLIENT_ENGAGEMENT} from '../../../../../../../constants';
import {EResumeStatus, IEngagementClient} from '../../../../../../../models';

type ResumeRequestRejectedProps = {
    data: IEngagementClient;
    onMessage: () => void;
};

const ResumeRequestRejected: React.FC<ResumeRequestRejectedProps> = ({data, onMessage}) => {
    const queryClient = useQueryClient();

    // hide this info box by Message button
    const handleMessage = useCallback(async () => {
        await clientService.resumeReject(data.id);
        queryClient.invalidateQueries([CLIENT_ENGAGEMENT, data.id]);
        onMessage();
    }, [data.id, queryClient, onMessage]);

    if (data.resume?.resumeStatus !== EResumeStatus.FREELANCER_REJECTED) {
        return null;
    }

    const freelancerName = data.freelancerName.split(' ')[0];

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/cross-icon.png"
                isError={true}
                title="Resume engagement request rejected"
            >
                {freelancerName} has rejected your resume engagement request.
                Jump into conversation to negotiate the details.

                <Box
                    sx={{
                        mt: '16px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >

                    <BlackBorderButton
                        size="small"
                        onClick={handleMessage}
                    >
                        Message {freelancerName}
                    </BlackBorderButton>
                </Box>

            </EngagementInfoBox>

        </>
    );
};

export default React.memo(ResumeRequestRejected);
