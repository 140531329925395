import React from 'react';

const RemoveIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.125 11H5.875C5.64294 11 5.42038 11.0922 5.25628 11.2563C5.09219 11.4204 5 11.6429 5 11.875C5 12.1071 5.09219 12.3296 5.25628 12.4937C5.42038 12.6578 5.64294 12.75 5.875 12.75H18.125C18.3571 12.75 18.5796 12.6578 18.7437 12.4937C18.9078 12.3296 19 12.1071 19 11.875C19 11.6429 18.9078 11.4204 18.7437 11.2563C18.5796 11.0922 18.3571 11 18.125 11Z"
                fill="#121416"/>
        </svg>
    );
};

export default RemoveIcon;
