import React from 'react';

import {Box, styled} from '@mui/material';

import {EWorkType, EWorkTypeDisplay, IProposal} from '../../../../models';
import theme from '../../../../theme';
import {formatCurrency, formatDate} from '../../../../utils';
import {FoldingText, InputCaption, Pill} from '../../../index';

const Row = styled('div')`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${theme.palette.lightGray.main};
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: unset;
  }

  .MuiBox-root {
    min-width: 150px;
  }

  .green {
    display: inline-flex;
  }
`;

const Text = styled('p')`
  color: ${theme.palette.textGray.dark};
  font-size: 14px;

  &.primary {
    color: ${theme.palette.primary.main};
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
  }
`;

interface IProps {
    data: IProposal;
}

const ChatProposalSentView: React.FC<IProps> = ({data}) => {
    return (
        <>
            <Row>
                <Box>
                    <InputCaption
                        sx={{
                            textAlign: 'left !important',
                            mb: '8px !important'
                        }}
                    >
                        Role name
                    </InputCaption>
                    <Text>{data.name}</Text>
                </Box>
            </Row>
            <Row>
                <Box>
                    <InputCaption
                        sx={{
                            textAlign: 'left !important',
                            mb: '8px !important'
                        }}
                    >
                        Budget Type
                    </InputCaption>
                    <Box>
                        <Pill
                            hasIcon
                            hasOutline
                            variant="green"
                        >
                            {EWorkTypeDisplay[data.workType]}
                        </Pill>
                    </Box>
                </Box>
                {
                    data.workType === EWorkType.retainer && (
                        <Box>
                            <InputCaption
                                sx={{
                                    textAlign: 'left !important',
                                    mb: '8px !important'
                                }}
                            >
                                Retainer Type
                            </InputCaption>
                            <Pill
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {data.isMonthly ? 'Monthly' : 'Weekly'}
                            </Pill>
                        </Box>
                    )
                }
            </Row>
            <Row>
                <Box>
                    <InputCaption
                        sx={{
                            textAlign: 'left !important',
                            mb: '8px !important'
                        }}
                    >
                        {data.workType === EWorkType.fixed && 'Precise budget'}
                        {data.workType === EWorkType.retainer && 'Budget'}
                        {data.workType === EWorkType.hourly && 'Precise rate'}
                    </InputCaption>
                    <Text
                        className="primary"
                    >
                        {formatCurrency(data.rate || 0)}
                        {data.workType === EWorkType.fixed || data.workType === EWorkType.retainer ? '' : '/hr'}
                    </Text>
                </Box>
                {
                    data.weeklyEngagementHours && (
                        <Box>
                            <InputCaption
                                sx={{
                                    textAlign: 'left !important',
                                    mb: '8px !important'
                                }}
                            >
                                Availability
                            </InputCaption>
                            <Text
                                className="primary"
                            >
                                {data.weeklyEngagementHours} hours/week
                            </Text>
                        </Box>
                    )
                }
                <Box>
                    <InputCaption
                        sx={{
                            textAlign: 'left !important',
                            mb: '8px !important'
                        }}
                    >
                        Start date
                    </InputCaption>
                    <Text
                        className="primary"
                    >
                        {data.startDate ? formatDate(data.startDate, true) : ''}
                    </Text>
                </Box>
            </Row>
            <Row>
                <Box>
                    <InputCaption
                        sx={{
                            mb: '8px !important',
                            textAlign: 'left !important',
                        }}
                    >
                        Role description
                    </InputCaption>
                    <FoldingText
                        numberOfVisibleLines={3}
                        text={data.description}
                    />
                </Box>
            </Row>
        </>
    );
};

export default React.memo(ChatProposalSentView);
