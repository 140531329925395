import {AxiosError} from 'axios';
import {useLocation} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';

import {applicationService, authService} from '../api';
import {showToastError} from '../components';
import {GET_APPLICATION, RouteApplication, RouteFreelancerPortal} from '../constants';
import {ActionTypes, useStateContext} from '../contexts';
import {EApplicationStatus} from '../models';
import {urlByApplicationStep} from '../routes/routes.utils';

import {useAuth} from './auth.hook';
import {useNavigateUTM} from './navigate-utm';

export const useApplication = () => {
    const {refreshUser} = useAuth();
    const location = useLocation();
    const navigate = useNavigateUTM();
    const globalContext = useStateContext();
    const {data, isLoading, isFetching, refetch} = useQuery([GET_APPLICATION], applicationService.getApplication, {
        refetchOnWindowFocus: location.pathname === RouteApplication.completed,     // reload only at the ThankYou page
        select: (data) => data,
        onSuccess: (data) => {
            globalContext.dispatch({type: ActionTypes.SET_APPLICATION, payload: data});

            // force redirect at the first time
            // if step < 8 it is Create Account
            // if step > 7 it is Process Application
            if (!globalContext.state.application) {
                const pathname = urlByApplicationStep(data.step);

                navigate(pathname);

                // Application was accepted in the Admin panel
                // relogin silenly as Freelancer and redirect to the Freelancer Portal
            } else if (data.status === EApplicationStatus.Accepted) {
                silentlyRelogin();
            }
        },
        onError: (error) => showToastError(error as AxiosError)
    });

    const silentlyRelogin = async () => {
        await authService.refreshAccessToken();

        setTimeout(() => {
            refreshUser();
        }, 150);

        // wait while auth context update token
        setTimeout(() => {
            navigate(RouteFreelancerPortal.default);
        }, 300);
    };

    return {
        application: data,
        isApplicationLoading: isLoading || isFetching,
        refetchApplication: refetch
    };
};
