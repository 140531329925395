import React from 'react';

import {clientService} from '../../../api';
import {ConfirmationCode} from '../../../components';
import {RouteWidget} from '../../../constants';
import {useClientRole} from '../../../hooks';

const WidgetConfirmationCode = () => {
    const {refetchClient} = useClientRole();

    return (
        <ConfirmationCode
            title="Thanks for creating the role, you’re almost there!"
            routeConfirmationCode={RouteWidget.confirmationCode}
            routeNextStep={RouteWidget.completed}
            routeUpdateEmail={RouteWidget.updateEmail}
            service={clientService}
            onRefetch={refetchClient}
        />
    );
};

export default WidgetConfirmationCode;
