import React, {useCallback} from 'react';

import {Box} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {freelancerService} from '../../../../../../../api';
import {BlackBorderButton, EngagementInfoBox} from '../../../../../../../components';
import {FREELANCER_ENGAGEMENT} from '../../../../../../../constants';
import {EEngagementStatus, EPauseStatus, IEngagementFreelancer} from '../../../../../../../models';

type PauseRequestRejectedProps = {
    data: IEngagementFreelancer;
    onMessage: () => void;
};

const PauseRequestRejected: React.FC<PauseRequestRejectedProps> = ({data, onMessage}) => {
    const queryClient = useQueryClient();

    // hide this info box by Message button
    const handleMessage = useCallback(async () => {
        await freelancerService.pauseReject(data.id);
        queryClient.invalidateQueries([FREELANCER_ENGAGEMENT, data.id]);
        onMessage();
    }, [data.id, queryClient, onMessage]);

    if (data.status === EEngagementStatus.CLOSED || data?.pause?.status !== EPauseStatus.CLIENT_REJECTED) {
        return null;
    }

    const clientName = data.clientName.split(' ')[0];

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/cross-icon.png"
                isError={true}
                title="Pause engagement request rejected"
            >
                {clientName} has rejected your pause engagement request.
                Jump into conversation to negotiate the details.

                <Box sx={{mb: '16px'}}/>

                <BlackBorderButton
                    size="small"
                    onClick={handleMessage}
                >
                    Message {clientName}
                </BlackBorderButton>


            </EngagementInfoBox>

        </>
    );
};

export default React.memo(PauseRequestRejected);
