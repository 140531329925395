import {useCallback, useContext} from 'react';

import {Conversation} from '@twilio/conversations';

import {emailService} from '../api';
import {TwilioContext} from '../contexts';
import {formatMeta} from '../models';
import {getLastMessage} from '../utils';

import {useAuth} from './auth.hook';

interface UseTwilio {
    conversations: Conversation[];
    loading: boolean;
    connect(conversationId?: string): Promise<void>;
    invalidateQueries(): void;
    finishConversation: () => void;
    selectConversationById(conversationId?: string): void;
    setAllMessageRead: () => void;
}

export const useTwilio = (): UseTwilio => {
    const {isImpersonal, user} = useAuth();

    const {
        conversations: conversationsAll,
        conversationActive,
        loading,
        clean,
        initClient,
        setActiveConversation,
        setLoading,
    } = useContext(TwilioContext);

    const connect = useCallback(async (conversationId?: string): Promise<void> => {
        setLoading(true);

        const response = await initClient();

        // for Inbox page process all Conversations
        // for Chat in the Message Tab find only one by id
        const conversations = conversationId ? response.filter(it => it.sid === conversationId) : response;

        const arrParticipants = conversations.map(it => it.getParticipants());
        const responsesParticipants = await Promise.all(arrParticipants);

        const arrUnreadMessages = conversations.map(it => it.getUnreadMessagesCount());
        const responsesUnreadMessages = await Promise.all(arrUnreadMessages);

        conversations.forEach((conversation, index) => {
            const participants = responsesParticipants[index];
            const localParticipant = participants.find(it => it.identity === user?.email);
            const remoteParticipant = participants.find(it => it.identity !== user?.email && !(it.attributes as any)?.isAdmin);  // Admin could be added to Conversation too for the admin panel

            (conversation as any).meta = formatMeta({
                isInitByFreelancer: false,
                localParticipant,
                remoteParticipant,
                unreadMessagesCount: responsesUnreadMessages[index] || 0
            });
        });

        // FIXME - not good solution to show lastMessage at the List
        const arrMessages = conversations.map((it) => it.getMessages());
        const responsesMessages = await Promise.all(arrMessages);

        responsesMessages.forEach((it, index: number) => {
            if (it.items.length) {
                (conversations[index] as any).meta.lastMessage = getLastMessage(it.items);
            }
        });

        setLoading(false);
    }, [user, initClient, setLoading]);

    // go away and start listining unread messages
    const finishConversation = useCallback(() => {
        setActiveConversation(null);
    }, [setActiveConversation]);

    const invalidateQueries = useCallback(() => {
        clean();
        connect();
    }, [clean, connect]);

    const selectConversationById = useCallback((sid: string) => {
        if (!sid || conversationActive?.sid === sid) return;

        const conversation = conversationsAll?.find((conversation: Conversation) => conversation.sid === sid);

        setActiveConversation(conversation || null);
    }, [conversationActive?.sid, conversationsAll, setActiveConversation]);

    const setAllMessageRead = useCallback(async () => {
        if (!(conversationActive as any)?.meta || isImpersonal) return;

        // erase unread badge for selected Conversation
        (conversationActive as any).meta.unreadMessagesCount = 0;
        (conversationActive as any).setAllMessagesRead();

        const all = await conversationActive?.getMessages();
        const lastMessage = all?.items?.at(-1);

        if (lastMessage) {
            emailService.readMessage(lastMessage.conversation.sid, (lastMessage as any).state.sid);
        }
    }, [conversationActive, isImpersonal]);

    return {
        conversations: conversationsAll,
        loading,
        connect,
        invalidateQueries,
        finishConversation,
        selectConversationById,
        setAllMessageRead,
    };
};
