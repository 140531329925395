import {RefObject, useEffect, useState} from 'react';

import {useCallbackRef} from './callback-ref.hook';

export function useMeasure(ref: RefObject<HTMLDivElement>) {
    const [element, attachRef] = useCallbackRef();
    const [bounds, setBounds] = useState<DOMRect>();

    useEffect(() => {
        if (!element) return;
        const measure = () => {
            if (!element.current) return;
            const rect = element.current.getBoundingClientRect();

            setBounds(rect);
        };

        const observer = new ResizeObserver(measure);

        observer.observe(element.current);

        return () => {
            observer.disconnect();
        };
    }, [element]);

    useEffect(() => {
        attachRef(ref);
    }, [attachRef, ref]);

    return bounds;
}
