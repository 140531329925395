import React from 'react';

import {Theme, useMediaQuery} from '@mui/material';

import {BlackBorderButton, ContinueButton, DialogEmoji} from '../../../../../components';

interface IProps {
    isOpen: boolean;
    onClose: (closeEvent: boolean) => void;
}

export const DeleteCertificateModal: React.FC<IProps> = ({isOpen, onClose}) => {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));


    return (
        <DialogEmoji
            actions={
                (<>
                    <BlackBorderButton
                        sx={{
                            width: mdDown ? '50% !important' : 'auto !important'
                        }}
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </BlackBorderButton>
                    <ContinueButton
                        variant="contained"
                        sx={{
                            width: mdDown ? '50% !important' : 'auto !important',
                            'div': {
                                justifyContent: 'center'
                            }
                        }}
                        onClick={() => onClose(true)}
                    >
                        Delete certificate
                    </ContinueButton>
                </>)
            }
            maxWidth="sm"
            maxWidthPx={673}
            open={isOpen}
            subtitle="Are you sure you want to delete this certificate? This action can’t be undone."
            title={(
                <>
                    <img src="/assets/images/trash-bin-icon.png" width="48" height="48" alt="sad icon"/>
                    Delete a certificate?
                </>
            )}
            onClose={() => onClose(false)}
        />
    );
};
