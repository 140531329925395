import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsFrelancer, EngagementInfoBox} from '../../../../../../../components';
import {EEngagementStatus, EPauseStatus, IEngagementFreelancer} from '../../../../../../../models';
import {formatDate} from '../../../../../../../utils';

type PauseIncomingProps = {
    data: IEngagementFreelancer;
};

const PauseIncomingBox: React.FC<PauseIncomingProps> = ({data}) => {
    if (
        data.status !== EEngagementStatus.ACTIVE
        || !data?.pause
        || ![EPauseStatus.CLIENT_ACCEPTED, EPauseStatus.CLIENT_DECIDED].includes(data.pause?.status as EPauseStatus)
    ) {
        return null;
    }

    const isInitiator = data.pause?.status === EPauseStatus.CLIENT_ACCEPTED;

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/waiting-icon.png"
                title="Engagement pause incoming"
            >
                {isInitiator ? (
                    <>
                    You have submitted the engagement pause request.
                    The engagement will be on hold from {formatDate(data.pause?.startDate)}{data.pause?.endDate ? ` - ${formatDate(data.pause?.endDate)}` : ', until you manually resume it'}.&nbsp;
                    The billing for that period will be stopped.
                    </>
                ) : (
                    <>
                        {data.clientName.split(' ')[0]} has paused the engagement.
                    It is set be on hold from {formatDate(data.pause?.startDate)}{data.pause?.endDate ? ` - ${formatDate(data.pause?.endDate)}` : ', until they manually resume it'}.
                    The billing for that period will be stopped.
                    </>
                )}

                {isInitiator && (
                    <Box
                        sx={{
                            mt: '16px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <EngagementActionsFrelancer.PauseCancelBtn data={data}/>
                    </Box>
                )}

            </EngagementInfoBox>

        </>
    );
};

export default React.memo(PauseIncomingBox);
