import React from 'react';

import {Box, Typography} from '@mui/material';

import {EWorkType, EWorkTypeDisplay} from '../../models';
import {formatCurrency} from '../../utils';
import {ImagePlaceholder, InputCaption, Pill} from '..';

type BudgetTypeRowProps = {
    budgetType: EWorkType;
    clientCompanyLogo?: string;
    clientCompanyName?: string;
    isFreelancerView: boolean;
    isMonthly: boolean;
    hourlyRate: number;
};

export const BudgetTypeRow: React.FC<BudgetTypeRowProps> = React.memo((
    {
        budgetType,
        clientCompanyLogo,
        clientCompanyName,
        isFreelancerView,
        isMonthly,
        hourlyRate,
    }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '24px',
                flexWrap: 'wrap'
            }}
        >
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }}
            >
                <InputCaption
                    sx={{
                        mb: '8px!important',
                        textAlign: 'left !important',
                    }}
                >
                    Budget type
                </InputCaption>
                <Pill
                    variant="gray"
                >
                    {EWorkTypeDisplay[budgetType]}
                </Pill>
            </Box>

            {/* Monthly/Weekly (for retainer work only) */}
            {budgetType === EWorkType.retainer && (
                <Box
                    sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                    }}
                >
                    <InputCaption
                        sx={{
                            mb: '8px!important',
                            textAlign: 'left !important',
                        }}
                    >
                        Retainer type
                    </InputCaption>
                    <Pill
                        variant="gray"
                    >
                        {isMonthly ? 'Monthly' : 'Weekly'}
                    </Pill>
                </Box>
            )}

            {[EWorkType.hourly, EWorkType.retainer].includes(budgetType) && (
                <Box
                    sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                    }}
                >
                    <InputCaption
                        sx={{
                            mb: '8px!important',
                            textAlign: 'left !important',
                        }}
                    >
                        {isFreelancerView ? 'Your rate' : 'Rate'}
                    </InputCaption>
                    <Pill
                        variant="lilac-text"
                    >
                        {formatCurrency(hourlyRate)}{budgetType === EWorkType.hourly ? '/hour' : ''}
                    </Pill>
                </Box>
            )}

            {
                clientCompanyName && (
                    <Box
                        sx={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <InputCaption
                            sx={{
                                mb: '8px!important',
                                textAlign: 'left !important',
                            }}
                        >
                            Company
                        </InputCaption>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            <ImagePlaceholder>
                                {clientCompanyLogo &&
                                  <img src={clientCompanyLogo} width="40" height="40" alt={clientCompanyName}/>}
                            </ImagePlaceholder>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '13px'
                                }}
                            >
                                {clientCompanyName}
                            </Typography>
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
});
