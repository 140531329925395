import React, {SyntheticEvent, useMemo, useState} from 'react';
import {AxiosError} from 'axios';

import {Box, Link, Stack, styled, Typography} from '@mui/material';

import {freelancerService} from '../../../../../api';
import {DocIcon} from '../../../../../assets/icons';
import {DialogConfirm, LoadingMask, showToastError} from '../../../../../components';
import {FREELANCER_LEGAL_DOCS} from '../../../../../constants';
import {useAuth} from '../../../../../hooks';
import {ILegalDoc} from '../../../../../models';
import theme from '../../../../../theme';
import {downloadFileWithToken, formatDate} from '../../../../../utils';
import {useFreelancer} from '../../../contexts';

const ItemWrapper = styled('div')`
  margin-bottom: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${theme.palette.white.main};
  border: 1px solid ${theme.palette.lightGray.main};
  border-radius: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > div:first-of-type {
    width: 24px;
    height: 24px;
  }

  path {
    fill: ${theme.palette.purple.main};
    fill-opacity: 1;
  }
`;

type HistoryItemProps = {
    data: ILegalDoc;
};

const HistoryItem: React.FC<HistoryItemProps> = ({data}) => {
    const {isImpersonal} = useAuth();
    const {refetchFleelancer} = useFreelancer();
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteing, setIsDeleting] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const fileName = useMemo(() => `${data.type} form ${data.taxYear}.pdf`, [data]);

    const handleDownloadClick = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (isLoading) return;

        setIsLoading(true);

        try {
            const url = `${process.env.REACT_APP_BASE_URL}/${FREELANCER_LEGAL_DOCS}/${data.id}`;

            await downloadFileWithToken(url, fileName);
        } catch (error) {
            showToastError(error as AxiosError);
        }

        setIsLoading(false);
    };

    const handleDeleteClick = async () => {
        if (isDeleteing) return;

        setOpenConfirm(false);
        setIsDeleting(true);

        try {
            await freelancerService.legalDocDelete(data.id);
            refetchFleelancer();
        } catch (error) {
            showToastError(error as AxiosError);
        }

        setIsDeleting(false);
    };

    const handleConfirm = (isNeedUpdate: boolean) => {
        if (isNeedUpdate) {
            handleDeleteClick();
        } else {
            setOpenConfirm(false);
        }
    };

    return (
        <>
            <ItemWrapper>
                <DocIcon/>
                <Box
                    sx={{
                        mr: 'auto'
                    }}
                >
                    <Typography
                        sx={{
                            mb: '2px',
                            fontWeight: 400,
                            fontSize: '14px'
                        }}
                    >
                        {fileName}
                    </Typography>
                    <Typography
                        sx={{
                            mb: '2px',
                            fontWeight: 400,
                            fontSize: '12px',
                            color: theme.palette.gray.main
                        }}
                    >
                        {/* 1,4 MB */}
                    </Typography>
                    <Stack direction="row" spacing={3}>
                        <Link
                            sx={{
                                display: 'block',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: isLoading ? theme.palette.gray.main : theme.palette.purple.main,
                                cursor: 'pointer',
                                textDecoration: 'none',
                                wordBreak: 'break-word',
                                pointerEvents: isLoading ? 'none' : 'auto'
                            }}
                            onClick={handleDownloadClick}
                        >
                            <Stack direction="row" spacing={2}>
                                <span>Download</span>
                                {isLoading && (
                                    <LoadingMask hasNoSpace size={12}/>
                                )}
                            </Stack>
                        </Link>

                        <Link
                            sx={{
                                display: 'block',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: isDeleteing ? theme.palette.gray.main : theme.palette.error.main,
                                cursor: 'pointer',
                                textDecoration: 'none',
                                wordBreak: 'break-word',
                                pointerEvents: isDeleteing ? 'none' : 'auto'
                            }}
                            onClick={(event) => {
                                if (!isImpersonal) {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setOpenConfirm(true);
                                }
                            }}
                        >
                            <Stack direction="row" spacing={2}>
                                <span>Delete</span>
                                {isDeleteing && (
                                    <LoadingMask hasNoSpace size={12}/>
                                )}
                            </Stack>
                        </Link>
                    </Stack>

                </Box>
                <Typography
                    sx={{
                        mt: 'auto',
                        mb: 0,
                        fontWeight: 400,
                        fontSize: '12px',
                        color: theme.palette.gray.main,
                        flexShrink: 0
                    }}
                >
                    {data.completedAt ? formatDate(data.completedAt) : null}
                </Typography>
            </ItemWrapper>

            <DialogConfirm
                error
                iconUrl="/assets/images/cross-icon.png"
                labelBtn="Yes, delete"
                open={openConfirm}
                title={<>
                    Are you sure you want to delete this form?
                </>}
                onClose={handleConfirm}
            />

        </>
    );
};

export default React.memo(HistoryItem);
