import React from 'react';

import {FaqList} from '../../../../components';

const paymentQuestions = [
    {
        title: 'How much does GC cost?',
        text: 'We offer hourly or retainer pricing and rates vary significantly based on the type of work, level, location, and a number of other factors. \n' +
            '\n' +
            'Most rates fall into these ranges: \n' +
            '\n' +
            'Mid-Level ($50-$100/hr)\n' +
            'Senior ($100-$150/hr)\n' +
            'Executive ($150+/hr)\n' +
            'When you create a role, you’ll see an instant estimate of the weekly cost based on the desired level and time commitment. \n' +
            '\n' +
            'Our team of in-house marketers is available anytime to help you think through a hiring strategy or prioritize channels based on your business model.'
    },
    {
        title: 'How do billing and payments work?',
        text: 'When kicking off your first Growth Collective project, you\'ll add a payment method to your account. Hourly work and retainers are automatically charged weekly. For flat-rate projects, we hold the entire sum in escrow until the project is successfully completed.\n' +
            '\n' +
            'Growth Collective handles all payments to freelancers including relevant tax forms.\n' +
            '\n' +
            'Enterprise invoicing solutions are available upon request at an additional cost.'
    }
];

const PricingAndBillingTab = () => {
    return (
        <>
            <FaqList list={paymentQuestions}/>
        </>
    );
};

export default React.memo(PricingAndBillingTab);
