import {ERoleStatus, ERoleStatusFilter} from '../models';

// Active [SUBMITTED, MATCHING_IN_PROGRESS, MATCHING_FINISHED, IN_REVIEW, ENGAGEMENT_ACTIVE, ENGAGEMENT_PAUSED],
// Draft [DRAFT],
// Archived [CLOSED, CANCELLED]

export const filterByStatus = (status: ERoleStatus): ERoleStatusFilter => {
    switch (status) {
        case ERoleStatus.InReview:
        case ERoleStatus.Submitted:
        case ERoleStatus.MatchingInProgress:
        case ERoleStatus.MatchingFinished:
        case ERoleStatus.EngagementActive:
        case ERoleStatus.EngagementPaused:
        case ERoleStatus.EngagementPending:
            return ERoleStatusFilter.Active;

        case ERoleStatus.Draft:
            return ERoleStatusFilter.Drafts;

        case ERoleStatus.Cancelled:
        case ERoleStatus.Closed:
            return ERoleStatusFilter.Archive;

        default:
            return ERoleStatusFilter.Active;
    }
};

export const statusesByFilter = (filter: ERoleStatusFilter): ERoleStatus[] => {
    switch (filter) {
        case ERoleStatusFilter.Active:
            return [
                ERoleStatus.InReview,
                ERoleStatus.Submitted,
                ERoleStatus.MatchingInProgress,
                ERoleStatus.MatchingFinished,
                ERoleStatus.EngagementActive,
                ERoleStatus.EngagementPaused,
                ERoleStatus.EngagementPending,
            ];

        case ERoleStatusFilter.Drafts:
            return [
                ERoleStatus.Draft,
            ];

        case ERoleStatusFilter.Archive:
            return [
                ERoleStatus.Cancelled,
                ERoleStatus.Closed,
            ];

        default:
            return [];
    }
};
