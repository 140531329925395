import {styled} from '@mui/material';

import {GRADIENT} from '../../constants/BackgroundImages';
import theme from '../../theme';

export default styled('div')<{ progress: number }>`
  height: 24px;
  margin: 0 -20px 24px;
  border-radius: 24px 24px 0 0;
  background-color: ${theme.palette.lightGray.main};
  position: relative;
  overflow: hidden;
  will-change: contents;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${props => props.progress}%;
    background-image: ${GRADIENT};
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    transition: width 1s linear;
  }

`;
