import React from 'react';

const CaseIcon = () => {
    return (
        <svg width="274" height="88" viewBox="0 0 274 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4"
                d="M78.7 206.15C72.7325 206.15 67.6222 204.023 63.369 199.77C59.1158 195.517 56.9928 190.41 57 184.45V65.1C57 59.1325 59.1266 54.0222 63.3798 49.769C67.633 45.5158 72.7397 43.3928 78.7 43.4H118.1C120.309 43.4 122.1 41.6092 122.1 39.4V21.7C122.1 15.7325 124.227 10.6222 128.48 6.36897C132.733 2.11577 137.84 -0.00721491 143.8 1.84211e-05H187.2C193.167 1.84211e-05 198.278 2.12662 202.531 6.37982C206.784 10.633 208.907 15.7397 208.9 21.7V39.4C208.9 41.6092 210.691 43.4 212.9 43.4H252.3C258.267 43.4 263.378 45.5266 267.631 49.7798C271.884 54.033 274.007 59.1397 274 65.1V184.45C274 190.417 271.873 195.528 267.62 199.781C263.367 204.034 258.26 206.157 252.3 206.15H78.7ZM143.8 39.4C143.8 41.6092 145.591 43.4 147.8 43.4H183.2C185.409 43.4 187.2 41.6092 187.2 39.4V25.7C187.2 23.4909 185.409 21.7 183.2 21.7H147.8C145.591 21.7 143.8 23.4909 143.8 25.7V39.4Z"
                fill="#7E5FFF"/>
            <path
                d="M17.2105 186C12.7526 186 8.93506 184.411 5.7578 181.234C2.58054 178.057 0.99461 174.242 1.00001 169.789V80.6316C1.00001 76.1737 2.58865 72.3561 5.76591 69.1789C8.94317 66.0016 12.758 64.4157 17.2105 64.4211H45.6316C47.8407 64.4211 49.6316 62.6302 49.6316 60.4211V48.2105C49.6316 43.7526 51.2202 39.9351 54.3975 36.7578C57.5747 33.5805 61.3896 31.9946 65.8421 32H98.2631C102.721 32 106.539 33.5886 109.716 36.7659C112.893 39.9432 114.479 43.758 114.474 48.2105V60.4211C114.474 62.6302 116.265 64.4211 118.474 64.4211H146.895C151.353 64.4211 155.17 66.0097 158.347 69.187C161.525 72.3642 163.111 76.1791 163.105 80.6316V169.789C163.105 174.247 161.517 178.065 158.339 181.242C155.162 184.419 151.347 186.005 146.895 186H17.2105ZM65.8421 60.4211C65.8421 62.6302 67.633 64.4211 69.8421 64.4211H94.2631C96.4723 64.4211 98.2631 62.6302 98.2631 60.4211V52.2105C98.2631 50.0014 96.4723 48.2105 94.2631 48.2105H69.8421C67.633 48.2105 65.8421 50.0014 65.8421 52.2105V60.4211Z"
                fill="#7E5FFF"/>
        </svg>
    );
};

export default CaseIcon;
