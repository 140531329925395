import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsClient, EngagementInfoBox} from '../../../../../../../components';
import {EEngagementStatus, EPauseStatus, EResumeStatus, IEngagement} from '../../../../../../../models';
import {formatDate} from '../../../../../../../utils';

interface ResumeEngagementProps {
    data: IEngagement;
}

const ResumeEngagement: React.FC<ResumeEngagementProps> = ({data}) => {
    const isResumNotExists = !data.resume || data.resume?.resumeStatus === EResumeStatus.FREELANCER_REJECTED;

    if (
        data.status !== EEngagementStatus.PAUSED
        || !(data.pause?.status === EPauseStatus.CLIENT_DECIDED && isResumNotExists)
    ) {
        return null;
    }

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/play-icon.png"
                title="Resume engagement"
            >
                {data.pause.endDate
                    ? (
                        <>
                            The engagement is currently on hold.
                            It will automatically be resumed on {formatDate(data.pause.endDate)}.
                            However, you can still resume it manually whenever you want.
                            We will notify the freelancer to get back to you as soon as possible.
                        </>
                    ) : (
                        <>
                            When you are ready to resume engagement & billing, please click the button below.
                            We will notify the freelancer to get back to you as soon as possible.
                        </>
                    )}

                <Box
                    sx={{
                        mt: '16px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <EngagementActionsClient.ResumeEngagementBtn
                        data={data}
                    />
                </Box>

            </EngagementInfoBox>
        </>
    );
};

export default React.memo(ResumeEngagement);
