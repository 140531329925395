import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, InputAdornment, Link} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../api';
import {ExternalLinkIcon} from '../../../../assets/icons';
import {
    Card,
    CardContent,
    ChangeEmailBtn,
    ChangePasswordBtn,
    ContinueButton,
    Input,
    InputLogoNoBg,
    InputPhone,
    InputPhoto,
    showToastError,
    showToastSuccess
} from '../../../../components';
import {ACCEPTED_PHOTO_TYPES, CLIENT} from '../../../../constants';
import {useAuth} from '../../../../hooks';
import theme from '../../../../theme';
import {clientSchema, findFieldError, getNameLetters, replaceSymbolsInPhone} from '../../../../utils';

export type AccountInput = TypeOf<typeof clientSchema>;

export const AccountTab = React.memo(() => {
    const {isImpersonal} = useAuth();
    const queryClient = useQueryClient();

    const {
        data,
        refetch,
    } = useQuery([CLIENT], clientService.getClient, {
        staleTime: 5 * 60 * 1000,
    });

    const methods = useForm<AccountInput>({
        resolver: zodResolver(clientSchema),
    });

    const {
        handleSubmit,
        formState: {isDirty},
        getValues,
        setError,
        setValue,
        reset,
        watch
    } = methods;

    const {mutate, isLoading: isSubmitting} = useMutation(
        ({
            companyName,
            companyWebsite,
            phone,
            fileAvatar,
            fileCompanyLogo,
            firstName,
            lastName,
            position
        }: AccountInput) => {
            const payload = {
                companyName,
                companyWebsite,
                firstName,
                lastName,
                phone: replaceSymbolsInPhone(phone),
                position,
            };
            const requests = [];

            // Update Avatar if new file selected
            if (Array.isArray(fileAvatar) && fileAvatar.length) {
                requests.push(clientService.submitAvatar(fileAvatar[0]));

                // file Deleted
            } else if (fileAvatar === false) {
                requests.push(clientService.submitAvatar());
            }

            // Update Company Logo if new file selected
            if (Array.isArray(fileCompanyLogo) && fileCompanyLogo.length) {
                requests.push(clientService.submitCompanyLogo(fileCompanyLogo[0]));

                // file Deleted
            } else if (fileCompanyLogo === false) {
                requests.push(clientService.submitCompanyLogo());
            }

            requests.push(clientService.submitClient(payload));

            return Promise.all(requests);
        },
        {
            // Create new account
            onSuccess() {
                showToastSuccess('Account was successfully updated!');
                refetch();
                queryClient.invalidateQueries(['dashboard']);
            },
            onError(error: AxiosError) {
                const phone = findFieldError(error, 'phone');

                if (phone) {
                    showToastError('Phone number is invalid');
                    setError('phone', {type: 'custom', message: phone.message});
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const companyWebsite = watch('companyWebsite', '');
    const fileAvatar = watch('fileAvatar', '');
    // const fileCompanyLogo = watch('fileCompanyLogo', '');

    const onSubmitHandler: SubmitHandler<AccountInput> = () => {
        const values = getValues();

        mutate(values);
    };

    useEffect(() => {
        if (!data) return;

        const {firstName, lastName, email, phone, companyName, position, companyWebsite, companyLogo} = data;

        reset({firstName, lastName, email, phone, companyName, position, companyWebsite, companyLogo});
    }, [data, reset]);

    useEffect(() => {
        if (data?.email) {
            setValue('email', data.email);
        }
    }, [data?.email, setValue]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(onSubmitHandler)}
                noValidate
                autoComplete="off"
                style={{width: '100%'}}
            >
                <Box
                    sx={{
                        [theme.breakpoints.up('lg')]: {
                            maxWidth: 664
                        }
                    }}
                >
                    <Typography
                        sx={{
                            mt: '32px',
                            mb: '20px',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: 1.5,
                            [theme.breakpoints.up('md')]: {
                                mt: 0,
                                mb: '32px',
                                fontSize: '16px'
                            }
                        }}
                    >
                        Account Settings
                    </Typography>

                    <Grid
                        container
                        spacing="24px"
                        sx={{
                            '.MuiFormControl-root': {
                                mb: 0
                            }
                        }}
                    >
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <InputPhoto
                                        accept={ACCEPTED_PHOTO_TYPES}
                                        alt={`${data?.firstName} ${data?.lastName}`}
                                        altShort={getNameLetters(`${data?.firstName} ${data?.lastName}`)}
                                        disabled={isSubmitting}
                                        helperText="We recommend a photo that is 100x100 (max size: 5MB)"
                                        name="fileAvatar"
                                        previewURL={fileAvatar === false || !data?.avatar ? '' : data?.avatar}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                disabled={isSubmitting}
                                label="First Name"
                                name="firstName"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                disabled={isSubmitting}
                                label="Last Name"
                                name="lastName"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                gap: '24px'
                            }}
                        >
                            <Input
                                disabled
                                label="Email"
                                name="email"
                                variant="outlined"
                            />

                            <ChangeEmailBtn
                                disabled={isSubmitting || isImpersonal}
                                verification={data?.emailVerificationProcess}
                                onChange={refetch}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                gap: '24px'
                            }}
                        >
                            <Input
                                disabled
                                label="Password"
                                placeholder="**********"
                                type="password"
                                variant="outlined"
                                sx={{
                                    'svg': {
                                        display: 'none !important'
                                    }
                                }}
                            />

                            <ChangePasswordBtn disabled={isSubmitting || isImpersonal}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputPhone
                                disabled={isSubmitting}
                                label="Phone"
                                name="phone"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                disabled={isSubmitting}
                                label="Company name"
                                name="companyName"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                disabled={isSubmitting}
                                label="Position"
                                name="position"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={data?.companyLogo ? 12 : 6} lg={6}>
                            <Input
                                disabled={isSubmitting}
                                label="Company website"
                                name="companyWebsite"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Link
                                                href={`${companyWebsite.startsWith('https://') ? '' : 'https://'}${companyWebsite}`}
                                                target="_blank" rel="noopener noreferrer nofollow"
                                                sx={{
                                                    display: 'block'
                                                }}
                                            >
                                                <ExternalLinkIcon/>
                                            </Link>
                                        </InputAdornment>
                                    )
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={data?.companyLogo ? 12 : 6}
                            lg={6}
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    mt: '32px'
                                }
                            }}
                        >
                            <InputLogoNoBg
                                accept={ACCEPTED_PHOTO_TYPES}
                                disabled={isSubmitting}
                                name="fileCompanyLogo"
                                mode="update"
                                previewURL={data?.companyLogo || ''} // from S3
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ContinueButton
                                disabled={isSubmitting || !isDirty || isImpersonal}
                                type="submit"
                                variant="contained"
                                sx={{width: 'auto !important'}}
                            >
                                Save changes
                            </ContinueButton>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </FormProvider>
    );
});
