const names = ['source', 'medium', 'campaign', 'term', 'content'] as const;

class UTM {
    public readonly source?: string;
    public readonly medium?: string;
    public readonly campaign?: string;
    public readonly term?: string;
    public readonly content?: string;

    public get empty(): boolean {
        return !names.find(key => !!this[key]);
    }

    public constructor() {
        const urlParams = new URLSearchParams(window.location.search);

        for (const key of names) {
            this[key] = urlParams.get(`utm_${key}`) || undefined;
        }
    }

    public addParameters(params: URLSearchParams): URLSearchParams {
        return names.reduce(
            (agg, key) => {
                const value = this[key];

                if (value) {
                    agg.set(`utm_${key}`, value);
                }

                return agg;
            },
            params
        );
    }

    public patchUri(uri: string): string {
        const idx = uri.indexOf('?');
        const base = idx < 0 ? uri : uri.substring(0, idx);
        const searchParams = utm.addParameters(new URLSearchParams(idx < 0 ? undefined : uri.substring(idx)));
        const queryString = searchParams.toString();

        return base + (queryString ? '?' + searchParams.toString() : '');
    }
}

export const utm: Readonly<UTM> = new UTM();
