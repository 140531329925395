import React, {FC} from 'react';
import {AnimatePresence, motion} from 'framer-motion';

interface IProps {
    active: boolean;
    isAlwaysVisible: boolean; // hide unchecked state
}

const ApprovePrimaryIcon: FC<IProps> = ({active, isAlwaysVisible}) => {
    return (
        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">

            {!active && isAlwaysVisible && (
                <circle cx="14" cy="14.5" r="13" fill={active ? '#7E5FFF' : ''} stroke="white" strokeWidth="2"/>
            )}

            <motion.circle
                animate={{fill: active ? '#7E5FFF' : '', scale: active ? 1 : 0}}
                cx="14"
                cy="14.5"
                r="13"
                stroke="white"
                strokeWidth="2"
            />

            <AnimatePresence>
                {active && (
                    <motion.path
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        d="M18.473 11.3066C18.411 11.2441 18.3373 11.1945 18.256 11.1606C18.1748 11.1268 18.0876 11.1094 17.9996 11.1094C17.9116 11.1094 17.8245 11.1268 17.7433 11.1606C17.662 11.1945 17.5883 11.2441 17.5263 11.3066L12.5596 16.2799L10.473 14.1866C10.4086 14.1244 10.3327 14.0755 10.2494 14.0427C10.1662 14.0099 10.0773 13.9938 9.98786 13.9954C9.8984 13.9969 9.81013 14.0161 9.72808 14.0518C9.64602 14.0874 9.5718 14.1389 9.50964 14.2032C9.44748 14.2676 9.39861 14.3435 9.3658 14.4268C9.333 14.51 9.31691 14.5989 9.31846 14.6884C9.32001 14.7778 9.33916 14.8661 9.37482 14.9481C9.41049 15.0302 9.46196 15.1044 9.52631 15.1666L12.0863 17.7266C12.1483 17.7891 12.222 17.8387 12.3033 17.8725C12.3845 17.9063 12.4716 17.9238 12.5596 17.9238C12.6476 17.9238 12.7348 17.9063 12.816 17.8725C12.8973 17.8387 12.971 17.7891 13.033 17.7266L18.473 12.2866C18.5406 12.2241 18.5946 12.1484 18.6316 12.064C18.6685 11.9797 18.6876 11.8886 18.6876 11.7966C18.6876 11.7045 18.6685 11.6134 18.6316 11.5291C18.5946 11.4448 18.5406 11.369 18.473 11.3066Z"
                        fill="white"
                    />
                )}
            </AnimatePresence>

        </svg>
    );
};

export default ApprovePrimaryIcon;
