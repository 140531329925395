import React, {useCallback, useEffect, useState} from 'react';

import {Media, Message} from '@twilio/conversations';

import {useAuth, useConversation} from '../../../../../../hooks';
import {equalMemo, formatTime, getFileTypeIcon, isImage} from '../../../../../../utils';
import {DialogPreviewImage} from '../../../../../Dialog';
import LoadingMask from '../../../../../LoadingMask';
import AvatarListItem from '../AvatarListItem';
import {MessageRowWrapper} from '../MessageRow';

import FileInfo from './FileInfo';
import {FilePreview} from './FilePreview';

export type FileWithLink = Media & {filename: string; url: string | null};

interface FileRowProps {
    conversationId: string;
    data: Message;
    isNewAuthor?: boolean;
}

const FileRow: React.FC<FileRowProps> = ({conversationId, data, isNewAuthor}) => {
    const {user} = useAuth();
    const {meta} = useConversation(conversationId);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<FileWithLink[]>();
    const [preview, setPreview] = useState<{url: string; isOpen: boolean} | null>(null);

    const isAuthor = user?.email === data.author;

    const avatar = isAuthor ? meta?.localParticipant?.avatar : meta?.remoteParticipant?.avatar;
    const name = isAuthor ? (meta?.localParticipant?.name || '') : (meta?.remoteParticipant?.name || '');

    const getTemporaryUrl = useCallback(async () => {
        if (data.attachedMedia) {
            setIsLoading(true);
            try {
                const mediaImages = await Promise.all(
                    data.attachedMedia.map((media) => isImage(media.filename || '') ? media.getContentTemporaryUrl() : null),
                );

                data.attachedMedia.forEach((media, index) => (media as FileWithLink).url = mediaImages[index]);

                setFiles(data.attachedMedia as FileWithLink[]);
            } catch (error) {

            }
            setIsLoading(false);
        }
    }, [data.attachedMedia]);

    const handleZoomClick = async (media: Media) => {
        setPreview({isOpen: true, url: ''});

        const url = await media.getContentTemporaryUrl();

        if (url) {
            setPreview({isOpen: true, url});
        }
    };

    const renderFile = useCallback((media: FileWithLink, index: number) => {
        // for images render preview with Zoom option by click
        if (isImage(media.filename)) {
            return (
                <FilePreview className="file-image" key={index}>
                    <img src={media.url || ''} alt={media.filename} onClick={() => handleZoomClick(media)}/>
                    <FileInfo media={media}/>
                </FilePreview>
            );
        } else {
            return (
                <FilePreview key={index}>
                    {getFileTypeIcon(media.filename)}
                    <FileInfo media={media}/>
                </FilePreview>
            );
        }
    }, []);

    useEffect(() => {
        if (!isLoading && !files) {
            getTemporaryUrl();
        }
    }, [data, files, isLoading, getTemporaryUrl]);

    return (
        <>
            <MessageRowWrapper>
                {/* hide 'Avatar + Name' if author the same for the previous message */}
                {isNewAuthor && (
                    <AvatarListItem avatar={avatar || ''} isAuthor={isAuthor} name={name || ''}/>
                )}

                <div className="message">
                    {isLoading && <LoadingMask hasNoSpace/>}
                    {files?.map((it, index) => renderFile(it, index))}
                </div>
                {data.dateUpdated && (
                    <span className="time">{formatTime(data.dateUpdated)}</span>
                )}
            </MessageRowWrapper>

            {/* Full screen preview image */}
            <DialogPreviewImage
                open={!!preview?.isOpen}
                url={preview?.url || ''}
                onClose={() => setPreview({isOpen: false, url: preview?.url || ''})}
            />
        </>
    );
};

export default React.memo(FileRow, equalMemo([]));
