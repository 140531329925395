import React, {FC} from 'react';

import {styled, Typography} from '@mui/material';

import {CardTitle} from '../../../../../components';
import theme from '../../../../../theme';

const Wrp = styled('div')``;

interface IMyMatchesRoleDescription {
    description: string;
}

const MyMatchesRoleDescription: FC<IMyMatchesRoleDescription> = ({description}) => {
    return (
        <Wrp>
            <CardTitle sx={{mb: '24px'}}>Role description</CardTitle>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.7,
                    color: theme.palette.textGray.dark,
                    whiteSpace: 'pre-wrap'
                }}
            >
                {description}
            </Typography>
        </Wrp>
    );
};

export default React.memo(MyMatchesRoleDescription);
