import {styled} from '@mui/material';

import theme from '../../../../../../theme';

export const FilePreview = styled('div')`
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 12px;

    width: 280px;
    height: 85px;

    border: 1px solid ${theme.palette.lightGray.main};
    border-radius: 16px;

    font-weight: 400;

    &.file-image {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        height: auto;

        img {
            max-width: 100%;
            border-radius: 24px;
            background-color: ${theme.palette.lightGray.light};
            cursor: zoom-in;
        }
    }

    .file-name {
        font-size: 14px;
        color: ${theme.palette.black.main};
    }
    .file-size {
        font-size: 12px;
        color: ${theme.palette.gray.main};
    }
    .file-link {
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    & svg {
        path {
            fill: ${theme.palette.purple.main};
            fill-opacity: 1;
        }
    }
`;
