import React from 'react';

import {FaqList} from '../../../../components';

const processQuestions = [
    {
        title: 'I just expressed interest in a project. When will I hear back?',
        text: 'Once you express interest in a role, we’ll share your profile and calendar link with the client. If the client is interested in working together, they’ll book an introductory call. This typically happens within a week.'
    },
    {
        title: 'When should I send the client a proposal?',
        text: 'We typically recommend waiting until after you’ve connected with the client for an intro call. On that call, you can iron out the scope and pricing which will be included in your proposal.\n' +
            '\n' +
            'Once a proposal is accepted, you’re free to kickoff the project! Growth Collective handles all the logistics regarding time-tracking, billing and payments. We’ve also eliminated legal back-and-forth by implementing standardized terms. Our terms are based on input from hundreds of clients and freelancers. They include the important provisions for a freelance engagement, including mutual confidentiality and client ownership of the work product.'
    },
    {
        title: 'Are there any other benefits to becoming a member of Growth Collective?',
        text: 'Yes! You’ll also gain access to exclusive discounts on services designed for freelancers. We’re adding new deals to the expert portal all the time.'
    },
];

const ProcessTab = () => {
    return (
        <>
            <FaqList list={processQuestions}/>
        </>
    );
};

export default React.memo(ProcessTab);
