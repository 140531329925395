import React, {ReactNode} from 'react';
import {motion} from 'framer-motion';

import {styled} from '@mui/material';

import {LG, XL} from '../../constants';

const Content = styled('div')<{ maxWidth?: IMaxWidthValues; minWidth?: IMaxWidthValues }>`
  max-width: ${props => props.maxWidth?.md ? `${props.maxWidth.md}px` : 'unset'};
  margin: 0 auto;

  @media (min-width: ${LG}) {
    min-width: ${props => props.minWidth?.md ? `${props.minWidth.md}px` : 'unset'};
    max-width: ${props => props.maxWidth?.md ? `${props.maxWidth.md}px` : 'unset'};
  }

  @media (min-width: ${XL}) {
    min-width: ${props => props.minWidth?.lg ? `${props.minWidth.lg}px` : `${props.minWidth?.md}px`};
    max-width: ${props => props.maxWidth?.lg ? `${props.maxWidth.lg}px` : `${props.maxWidth?.md}px`};
  }
`;

interface IMaxWidthValues {
    md?: number;
    lg?: number;
}

interface IProps {
    minWidth?: IMaxWidthValues;
    maxWidth?: IMaxWidthValues;
    children: ReactNode;
}

export const AnimatePageWrpFadeIn: React.FC<IProps> = ({children, minWidth, maxWidth}) => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 1}}
            style={{width: '100%', willChange: 'transform', transformStyle: 'preserve-3d'}}
        >
            <Content minWidth={minWidth} maxWidth={maxWidth}>
                {children}
            </Content>
        </motion.div>
    );
};
