import React from 'react';

import {Box, Typography} from '@mui/material';

import {useAuth} from '../../../hooks';
import {EWorkWeekStatusDisplay, IReportRole} from '../../../models';
import theme from '../../../theme';
import {formatCurrency, formatDate, formatHoursFromDuration} from '../../../utils';
import {CollapsibleBox, ImagePlaceholder, MoneyEarnedSpent, Pill, WorkDayGrid} from '../../index';
import {getVariantByStatus} from '../../Timesheets';

type ReportRoleProps = {
    data: IReportRole;
};

const ReportRole: React.FC<ReportRoleProps> = ({data}) => {
    const {isClient} = useAuth();

    return (
        <>
            {data.roles.map(item => (
                <CollapsibleBox
                    key={item.id + item.roleName}
                    titleContent={
                        <>
                            <Typography
                                sx={{
                                    gridArea: 'date',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    order: 1
                                }}
                            >
                                <img src="/assets/images/bag-icon.png" width="24" height="24" alt="bag icon"/>
                                {item.roleName}
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px',
                                    order: 3,
                                    width: '100%',
                                    [theme.breakpoints.up(850)]: {
                                        order: 2,
                                        width: 'auto',
                                        ml: 'auto'
                                    }
                                }}
                            >
                                <ImagePlaceholder>
                                    {isClient ? (
                                        item.freelancerAvatar && (
                                            <img
                                                src={item.freelancerAvatar}
                                                width="32"
                                                height="32"
                                                alt={item.freelancerName}
                                            />
                                        )
                                    ) : (
                                        item.clientCompanyLogo && (
                                            <img
                                                src={item.clientCompanyLogo}
                                                width="32"
                                                height="32"
                                                alt={item.clientCompanyName}
                                            />
                                        )
                                    )}
                                </ImagePlaceholder>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        color: theme.palette.gray.main
                                    }}
                                >
                                    {isClient ? item.freelancerName : item.clientCompanyName}
                                </Typography>
                            </Box>
                        </>
                    }
                >
                    <Box sx={{pb: '16px'}}/>
                    {item.timesheets.map((timesheet) =>
                        <Box
                            key={timesheet.id}
                            sx={{
                                pt: '24px',
                                borderTop: `1px solid ${theme.palette.lightGray.main}`
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    rowGap: '8px',
                                    columnGap: '16px',
                                    mb: '16px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        width: '100%',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        order: 1,
                                        [theme.breakpoints.up('sm')]: {
                                            width: 'auto',
                                            mr: 'auto'
                                        }
                                    }}
                                >
                                    {formatDate(timesheet.fromDate)} - {formatDate(timesheet.toDate)}
                                </Typography>
                                {
                                    timesheet.status && (
                                        <Box
                                            sx={{
                                                order: 4
                                            }}
                                        >
                                            <Pill
                                                variant={getVariantByStatus(timesheet.status)}
                                            >
                                                {EWorkWeekStatusDisplay[timesheet.status]}
                                            </Pill>
                                        </Box>
                                    )
                                }
                                {timesheet.money > 0
                                    ? (
                                        <MoneyEarnedSpent
                                            isClient={isClient}
                                            moneyAmount={formatCurrency(timesheet.money, true)}
                                        />
                                    ) : null
                                }
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        order: 5,
                                    }}
                                >
                                    {(timesheet.workTime && timesheet.workTime !== 'PT0S') ? formatHoursFromDuration(timesheet.workTime) : '0'}h
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    p: '24px',
                                    mb: '16px',
                                    backgroundColor: theme.palette.blue.light,
                                    borderRadius: '24px'
                                }}
                            >
                                {
                                    timesheet.workDays.map(workDay => (
                                        <WorkDayGrid
                                            key={workDay.id}
                                            date={workDay.date}
                                            workDescription={workDay.workDescription}
                                            workTime={workDay.workTime}
                                        />
                                    ))
                                }
                            </Box>
                        </Box>
                    )}
                </CollapsibleBox>
            ))}
        </>
    );
};

export default React.memo(ReportRole);
