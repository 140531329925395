import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {Box} from '@mui/material';

import {StopWatchIcon} from '../../../../../../assets/icons';
import {InputCaption, Pill, TextButton} from '../../../../../../components';
import {EWorkType, EWorkTypeDisplay} from '../../../../../../models';
import {EDisplayMode} from '../../../MyHiresDetailsPage';

type BudgetTypeProps = {
    budgetType: EWorkType;
    isMonthly: boolean;
};

const BudgetType: React.FC<BudgetTypeProps> = ({budgetType, isMonthly}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleShowTimesheetClick = () => {
        const paths = location.pathname.split('/');

        paths.pop();
        navigate(`${paths.join('/')}/${EDisplayMode.Timesheets}`);
    };


    return (
        <Box
            sx={{
                display: 'flex',
                gap: '24px'
            }}
        >
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                }}
            >
                <InputCaption
                    sx={{
                        mb: '8px!important',
                        textAlign: 'left !important',
                    }}
                >
                    Budget type
                </InputCaption>
                <Pill
                    variant="gray"
                >
                    {EWorkTypeDisplay[budgetType]}
                </Pill>
            </Box>

            {/* Monthly/Weekly (for retainer work only) */}
            {budgetType === EWorkType.retainer && (
                <Box
                    sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start'
                    }}
                >
                    <InputCaption
                        sx={{
                            mb: '8px!important',
                            textAlign: 'left !important',
                        }}
                    >
                        Retainer type
                    </InputCaption>
                    <Pill
                        variant="gray"
                    >
                        {isMonthly ? 'Monthly' : 'Weekly'}
                    </Pill>
                </Box>
            )}

            {/* Link to timesheets (for hourly work only) */}
            {budgetType === EWorkType.hourly && (
                <Box
                    sx={{
                        mt: 'auto',
                        mb: '8px',
                        ml: 'auto'
                    }}
                >
                    <TextButton
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '8px',
                            fontSize: '13px',
                            'svg': {
                                width: '16px',
                                height: '16px'
                            },
                            'path': {
                                fill: 'currentColor'
                            }
                        }}
                        onClick={handleShowTimesheetClick}
                    >
                        <StopWatchIcon/>
                        Show timesheet
                    </TextButton>
                </Box>
            )}

        </Box>
    );
};

export default React.memo(BudgetType);
