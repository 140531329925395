import React, {useMemo} from 'react';
import {AnimatePresence, LayoutGroup} from 'framer-motion';
import {useNavigate} from 'react-router-dom';

import {Box, styled} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';

import {AddNewItemButton, CaseStudyItem} from '../../../../components';
import {animationAlertBox} from '../../../../components/Animations';
import {MD, RouteFreelancerPortal} from '../../../../constants';
import {useAuth} from '../../../../hooks';
import theme from '../../../../theme';
import {useFreelancer} from '../../contexts';

export const ListWrapper = styled('div')`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  min-width: 0;
  transition: width .3s;
  padding: 0;

  @media (min-width: ${MD}) {
    gap: 24px;
  }
`;

export const CaseStudiesTab = React.memo(() => {
    const {isImpersonal} = useAuth();
    const {state: {data}, refetchProfile} = useFreelancer();

    const navigate = useNavigate();

    const caseStudies = useMemo(() => {
        const caseStudiesDraft = data?.caseStudies
            .filter(it => it.draftVersion)
            .map(it => ({...it.draftVersion, id: it.id, isDraft: true})) || [];
        const caseStudiesPublished = data?.caseStudies
            .filter(it => it.publicVersion)
            .map(it => ({...it.publicVersion, id: it.id, isDraft: false})) || [];

        return [...caseStudiesPublished, ...caseStudiesDraft];
    }, [data?.caseStudies]);

    // Allow Freelancers to delete case studies if at least 2 finished case study is still available.
    const isDeletable = useMemo(() => caseStudies.filter(it => !it.isDraft).length > 2, [caseStudies]);

    return (
        <Box
            sx={{
                [theme.breakpoints.up('lg')]: {
                    gridColumn: '2 / span 3'
                }
            }}
        >
            <Typography
                sx={{
                    mt: '32px',
                    mb: '20px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: 1.5,
                    [theme.breakpoints.up('md')]: {
                        mt: 0,
                        mb: '32px',
                        fontSize: '16px'
                    }
                }}
            >
                My Case Studies
            </Typography>

            <ListWrapper>
                <LayoutGroup>
                    <AddNewItemButton
                        sx={{

                            [theme.breakpoints.up('sm')]: {
                                width: '366px'
                            }
                        }}
                        value="add_btn"
                        onClick={() => isImpersonal ? null : navigate(`${RouteFreelancerPortal.caseStudy}/edit/new`)}
                    >
                        Add a new case study
                    </AddNewItemButton>

                    <AnimatePresence>
                        {caseStudies?.map((caseStudy, i) => (
                            <CaseStudyItem
                                {...animationAlertBox(i)}
                                data={caseStudy}
                                isDeletable={(caseStudy.isDraft || isDeletable) && !isImpersonal}
                                isDraft={caseStudy.isDraft}
                                isMoreBtn={true}
                                key={`${caseStudy.id}-${caseStudy.isDraft ? 'draft' : ''}`}
                                layout
                                onDelete={refetchProfile}
                            />
                        ))}
                    </AnimatePresence>
                </LayoutGroup>
            </ListWrapper>
        </Box>
    );
});
