import {PROPOSALS} from './meta.api.routes';

export const FREELANCER = 'freelancer';

export const FREELANCER_DASHBOARD = `${FREELANCER}/dashboard`;

export const FREELANCER_ENGAGEMENT = `${FREELANCER}/engagements`;

export const FREELANCER_LEGAL_DOCS = `${FREELANCER}/legal/documents`;

export const FREELANCER_MATCHES = `${FREELANCER}/matches`;

export const FREELANCER_METHODS = `${FREELANCER}/payouts/methods`;

export const FREELANCER_PROFILE = `${FREELANCER}/profile`;
export const GET_FREELANCER_PROPOSALS = `${FREELANCER}/${PROPOSALS}`;

export const SET_AVATAR = `${FREELANCER}/avatar`;
