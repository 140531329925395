import React from 'react';
import {useParams} from 'react-router';
import {LocalParticipant, RemoteParticipant} from 'twilio-video';
import {useTrack} from 'use-twilio-video';

import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import {Avatar, Box, styled, Typography} from '@mui/material';

import {MicOffIcon} from '../../../../assets/icons';
import {useConversation} from '../../../../hooks';
import theme from '../../../../theme';
import {getNameLetters, stringToColor} from '../../../../utils';
import LoadingMask from '../../../LoadingMask';

import AudioTrack from './AudioTrack';
import VideoTrack from './VideoTrack';

const RoundWrapper = styled('div')`
  background-color: ${theme.palette.textGray.dark};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  path {
    fill: ${theme.palette.white.main};
  }
`;

interface CallParticipantProps {
    conversationId: string;
    isAuthor?: boolean;
    participant: LocalParticipant | RemoteParticipant;
}

const CallParticipant: React.FC<CallParticipantProps> = ({conversationId, isAuthor, participant}) => {
    const {isAudioOnly} = useParams();
    const {meta} = useConversation(conversationId || '');
    const {videoOn, audioOn, videoTrack, audioTrack} = useTrack({participant});

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            flexWrap="wrap"
            sx={{
                position: 'relative',
                background: theme.palette.black.main,
                width: '220px',
                height: '220px',
                border: isAuthor ? `2px solid ${theme.palette.purple.main}` : 'unset',
                borderRadius: '30px',
                boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                overflow: 'hidden',
                'video': {
                    aspectRatio: '1/1',
                    width: 'inherit',
                    height: 'inherit',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    transform: isAuthor ? 'scaleX(-1)' : 'none'
                },
                [theme.breakpoints.up('md')]: {
                    width: '240px',
                    height: '240px'
                }
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '16px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: 'max-content',
                    zIndex: 5
                }}
            >
                <Typography
                    sx={{
                        display: 'inline-block',
                        p: '4px 8px',
                        borderRadius: '8px',
                        backgroundColor: 'rgba(18, 20, 22, 0.9)',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '18px',
                        textAlign: 'center',
                        color: theme.palette.white.main,
                    }}
                    variant="caption"
                >
                    {isAuthor ? 'You' : meta?.remoteParticipant.name}
                </Typography>
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                    zIndex: 5,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}
            >
                {!videoOn && (
                    !isAudioOnly && <RoundWrapper>
                        <VideocamOffIcon/>
                    </RoundWrapper>
                )}
                {!audioOn && (
                    <RoundWrapper>
                        <MicOffIcon/>
                    </RoundWrapper>
                )}
            </Box>

            {/* Waiting for participant */}
            {!participant && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <LoadingMask color="secondary"/>
                </Box>
            )}

            {participant && !videoOn && (
                <Avatar
                    alt={isAuthor ? 'You' : meta?.remoteParticipant.name}
                    src={isAuthor ? meta?.localParticipant.avatar : meta?.remoteParticipant.avatar}
                    sx={{
                        width: '80px',
                        height: '80px',
                        background: stringToColor((isAuthor ? meta?.localParticipant.name : meta?.remoteParticipant.name) || ''),
                        fontSize: '30px',
                        lineHeight: '21px',
                        fontWeight: 500,
                        'img': {
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            aspectRatio: '1/1'
                        }
                    }}
                >
                    {getNameLetters((isAuthor ? meta?.localParticipant.name : meta?.remoteParticipant.name) || '')}
                </Avatar>
            )}
            {videoOn && <VideoTrack track={videoTrack}/>}
            {audioOn && <AudioTrack track={audioTrack}/>}
        </Box>
    );
};

export default CallParticipant;
