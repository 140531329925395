import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useParams} from 'react-router-dom';

import {Box, IconButton, styled, Theme, useMediaQuery} from '@mui/material';

import {AccountNoOutlineIcon, CloseIcon} from '../../../../../assets/icons';
import {
    ChatBottom,
    ChatMainMessages,
    ChatMainTop,
    ErrorBoundary,
    LoadingMask,
    TabContentWrapper,
    VideoRoom
} from '../../../../../components';
import {MD} from '../../../../../constants';
import {TwilioContext} from '../../../../../contexts';
import {IEngagementClient} from '../../../../../models';
import theme from '../../../../../theme';
import {Line} from '../MyHiresOverview/MyHiresOverview';

import ChatDetails from './ChatDetails';

const ChatMain = styled('div')`
  background-color: ${theme.palette.white.main};
  border-radius: 24px;
  display: grid;
  grid-auto-rows: 88px auto min-content;
  width: 100%;
  height: calc(100vh - 110px);

  @media (min-width: ${MD}) {
    height: 100%;
  }
`;

interface IProps {
    isLoading: boolean;
    data: IEngagementClient;
}

const MyHiresMessages: FC<IProps> = ({data, isLoading}) => {
    const {videoCallRoomId} = useParams();
    const [chatDetailsVisible, setChatDetailsVisible] = useState(false);
    const {activeCall, setIsDragActive} = useContext(TwilioContext);
    const {conversationSid} = data;

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(1400));

    const {isDragActive, getRootProps} = useDropzone({});

    const handleChatDetailsOpen = useCallback((isDetailsOpen: boolean) => {
        if (xlUp) {
            setChatDetailsVisible(true);
        } else {
            setChatDetailsVisible(isDetailsOpen);
        }
    }, [xlUp]);

    useEffect(() => {
        setIsDragActive(isDragActive);
    }, [isDragActive, setIsDragActive]);

    return (
        <TabContentWrapper>
            <Box
                {...getRootProps()}
                sx={{
                    height: 'inherit',
                    margin: '0 -20px',
                    position: 'relative',
                    overflow: 'hidden',
                    '.back-chat-btn': {
                        display: 'none'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '100%',
                        m: 0,
                        display: 'flex',
                        gap: '24px',
                        height: 'calc(100vh - 360px)',
                        minHeight: '550px'
                    }
                }}
                onClick={event => event.stopPropagation()}
            >
                {isLoading &&
                  <Box
                      sx={{
                          display: 'flex',
                          width: '100%',
                          height: 'calc(100vh - 420px)',
                          justifyContent: 'center',
                          alignItems: 'center'
                      }}
                  >
                      <LoadingMask/>
                  </Box>
                }
                {!isLoading && (
                    <>
                        <Box sx={{width: '100%'}}>
                            <Line/>
                            <ChatMain>
                                <ErrorBoundary>
                                    {
                                        (activeCall || videoCallRoomId) && (
                                            <VideoRoom conversationId={conversationSid}/>
                                        )
                                    }
                                    <ChatMainTop
                                        conversationId={conversationSid || ''}
                                        engagement={data}
                                        sx={{
                                            borderTop: 'none'
                                        }}
                                        onUserInfoClick={handleChatDetailsOpen}
                                    />
                                    <ChatMainMessages conversationId={conversationSid || ''}/>
                                    <ChatBottom conversationId={conversationSid || ''}/>
                                </ErrorBoundary>
                            </ChatMain>
                            {
                                lgUp && !xlUp && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '0',
                                            right: chatDetailsVisible ? '462px' : '0',
                                            width: '76px',
                                            height: '87px',
                                            p: '24px 24px 24px 12px',
                                            backgroundColor: theme.palette.white.main,
                                            // transition: 'right .3s linear',
                                            borderRadius: '0 24px 0 0',
                                            willChange: 'left'
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => handleChatDetailsOpen(!chatDetailsVisible)}
                                            sx={{
                                                border: '1px solid ' + theme.palette.lightGray.main,
                                                p: chatDetailsVisible ? '5px' : '10px',
                                                '.close-icon': {
                                                    width: '28px',
                                                    height: '28px',
                                                    'svg': {
                                                        width: 'inherit',
                                                        height: 'inherit'
                                                    },
                                                    'path': {
                                                        fill: theme.palette.textGray.dark
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                (chatDetailsVisible) ? (
                                                    <CloseIcon/>
                                                ) : (
                                                    <AccountNoOutlineIcon/>
                                                )
                                            }

                                        </IconButton>
                                    </Box>
                                )
                            }
                        </Box>
                        <ErrorBoundary>
                            <ChatDetails
                                isVisible={chatDetailsVisible || xlUp}
                                data={data}
                                onChatDetailsOpen={handleChatDetailsOpen}
                            />
                        </ErrorBoundary>
                    </>
                )}
            </Box>
        </TabContentWrapper>
    );
};

export default React.memo(MyHiresMessages);
