import React from 'react';

import {Box, Grid, styled} from '@mui/material';

import {CheckButton, ContinueButton, LoginLink} from '../../../components';
import {MD, RouteAuth, SM} from '../../../constants';
import {ERole} from '../../../models';
import theme from '../../../theme';
import {utm} from '../../../utils';

const Title = styled('h1')`
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    position: relative;

    @media (min-width: ${SM}) {
        padding-bottom: 28px;
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -0.02em;
        color: ${theme.palette.black.main};
        background-color: unset;
    }

    @media (min-width: ${MD}) {
        padding-bottom: 32px;
        font-size: 40px;
        line-height: 60px;
    }
`;

interface IProps {
    isClientRole: boolean;
    onRoleChange: (role: ERole) => void;
}

const JoinAsClientOrFreelancerLayout: React.FC<IProps> = ({isClientRole, onRoleChange}) => {
    // const navigate = useNavigateUTM();
    // const isProd = process.env.REACT_APP_ENV === 'prod';

    return (
        <Box sx={{
            backgroundColor: theme.palette.white.main,
            margin: '0 -20px',
            padding: '24px 20px 40px',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                width: '100%',
                m: 0,
            },
        }}>
            <Title>
        Join as a client or freelancer
            </Title>

            <Grid
                container
                spacing='16px'
                sx={{
                    maxWidth: '532px',
                    margin: '0 auto 24px',
                    width: '100%',
                    [theme.breakpoints.up('sm')]: {
                        mb: '36px',
                    },
                    [theme.breakpoints.up('md')]: {
                        mb: '48px',
                    },
                }}
            >
                <Grid item xs={12} md={6}>
                    <CheckButton
                        label='I’m a client, looking to hire'
                        selected={isClientRole}
                        sx={{
                            '.MuiTypography-root': {
                                whiteSpace: 'unset !important',
                            },
                        }
                        }
                        onClick={() => onRoleChange(ERole.CLIENT)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CheckButton
                        label='I’m a freelancer, looking for work'
                        selected={!isClientRole}
                        sx={{
                            '.MuiTypography-root': {
                                whiteSpace: 'unset !important',
                            },
                        }
                        }
                        onClick={() => onRoleChange(ERole.FREELANCER)}
                    />
                </Grid>
            </Grid>
            <Box
                sx={{
                    mb: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    [theme.breakpoints.up('md')]: {
                        mb: '48px',
                    },
                }}
            >
                <ContinueButton
                    hasArrow
                    label='Sign up'
                    sx={{
                        minWidth: '194px',
                        'div': {
                            justifyContent: 'center',
                        },
                    }}
                    variant='contained'

                    // GC-1213 stopped registration for new freelancers
                    //
                    // onClick={() => navigate(
                    //     isClientRole
                    //         ? RouteCreateClientAccount.signup
                    //         : RouteCreateApplicantAccount.signup
                    // )}
                    // onClick={() =>
                    //     isClientRole
                    //         ? navigate(RouteCreateClientAccount.signup)
                    //         : isProd
                    //             ? window.open('https://www.growthcollective.com/join', '_blank')
                    //             : navigate(RouteCreateApplicantAccount.signup)
                    // }
                    //

                    // per request from Alex Kucheruk on 25.07.2024 changing to hardcoded links
                    href={
                        isClientRole
                            ? 'https://www.toptal.com/hire?interested_in=marketing_experts&source=growth_collective'
                            : 'https://www.toptal.com/talent/apply'
                    }
                />
            </Box>

            <Box
                sx={{
                    textAlign: 'center',
                    'a': {
                        color: theme.palette.black.main,
                    },
                }}
            >
                <LoginLink
                    to={utm.patchUri(RouteAuth.login)}
                >
          I already have an account
                </LoginLink>
            </Box>
        </Box>
    );
};

export default React.memo(JoinAsClientOrFreelancerLayout);
