import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {AxiosError, AxiosResponse} from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';

import {useQueryClient} from '@tanstack/react-query';

import {applicationService, freelancerService} from '../../../api';
import {CASE_STUDIES, FREELANCER_PROFILE, GET_APPLICATION, RouteFreelancerPortal} from '../../../constants';
import {ECaseStudySteps, ICaseStudy, ICaseStudyVersion} from '../../../models';
import {getCaseStudyStep} from '../../../utils';
import {showToastError} from '../..';

interface State {
    cancelUrl: string;
    data: ICaseStudyVersion | null;
    isNew: boolean;
    isCanBePublished: boolean; // is all mandatory fields filled in
}

type CaseStudyContextProviderProps = { children: React.ReactNode };

const CaseStudyContext = React.createContext<
{
    state: State;
    onCancel: () => void;
    onRefetch: () => void;
    publish: (id: number) => void;
    setCancelUrl: Dispatch<SetStateAction<string>>;
    setData: Dispatch<SetStateAction<ICaseStudyVersion | null>>;
    updateCaseStudy: (values: Partial<ICaseStudy>) => Promise<AxiosResponse<null, any>>;
} | undefined>(undefined);

const CaseStudyContextProvider = ({children}: CaseStudyContextProviderProps) => {
    const [cancelUrl, setCancelUrl] = useState<string>('');
    const [data, setData] = useState<ICaseStudyVersion | null>(null);

    const location = useLocation();
    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const isCanBePublished: boolean = useMemo(() => {
        return !!data && (!!data.publicVersion || getCaseStudyStep(data.draftVersion).view === ECaseStudySteps.testimonial);
    }, [data]);

    const onCancel = useCallback(() => {
        navigate(cancelUrl);
    }, [cancelUrl, navigate]);

    const onRefetch = useCallback(() => {
        queryClient.invalidateQueries([GET_APPLICATION]);
        queryClient.invalidateQueries([FREELANCER_PROFILE]);
    }, [queryClient]);

    const publish = useCallback(async (id: number) => {
        try {
            await freelancerService.caseStudyPublish(id);
            queryClient.invalidateQueries([FREELANCER_PROFILE]);
            navigate(`${RouteFreelancerPortal.profileSettings}/${CASE_STUDIES}`);
        } catch (error) {
            showToastError(error as AxiosError);
        }
    }, [queryClient, navigate]);

    const updateCaseStudy = useCallback((values: Partial<ICaseStudy>) => {
        const formData = new FormData();
        const apiService = location.pathname.startsWith(RouteFreelancerPortal.default) ? freelancerService : applicationService;

        Object.entries(values).forEach(([key, value]) => {
            if (key === 'outcomes') {
                formData.append('outcomes', JSON.stringify(value));
            } else if (Array.isArray(value)) {
                value.forEach(it => formData.append(key, JSON.stringify(it)));
            } else if (typeof value === 'number' || typeof value === 'boolean') {
                formData.append(key, value.toString());

                // for the new - id will be empty
            } else if (key === 'id') {
                value && formData.append(key, value);
            } else {
                formData.append(key, value);
            }
        });

        return apiService.caseStudySubmit(formData); // FIXME - remove when we will have BE
    }, [location.pathname]);

    const state = {
        cancelUrl,
        data,
        isNew: !data?.publicVersion,
        isCanBePublished,
    };

    return (
        <CaseStudyContext.Provider
            value={{
                state,
                onCancel,
                onRefetch,
                publish,
                setCancelUrl,
                setData,
                updateCaseStudy,
            }}
        >{children}</CaseStudyContext.Provider>
    );
};

const useCaseStudy = () => {
    const context = React.useContext(CaseStudyContext);

    if (context) {
        return context;
    }

    throw new Error('useCaseStudy must be used within a CaseStudyContextProvider');
};

export {CaseStudyContextProvider, useCaseStudy};
