import React from 'react';

const EyeIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.92 12.1C19.9 7.41 16.1 4.5 12 4.5C7.90001 4.5 4.10001 7.41 2.08001 12.1C2.02494 12.2262 1.99652 12.3623 1.99652 12.5C1.99652 12.6377 2.02494 12.7738 2.08001 12.9C4.10001 17.59 7.90001 20.5 12 20.5C16.1 20.5 19.9 17.59 21.92 12.9C21.9751 12.7738 22.0035 12.6377 22.0035 12.5C22.0035 12.3623 21.9751 12.2262 21.92 12.1ZM12 18.5C8.83001 18.5 5.83001 16.21 4.10001 12.5C5.83001 8.79 8.83001 6.5 12 6.5C15.17 6.5 18.17 8.79 19.9 12.5C18.17 16.21 15.17 18.5 12 18.5ZM12 8.5C11.2089 8.5 10.4355 8.7346 9.77773 9.17412C9.11993 9.61365 8.60724 10.2384 8.30449 10.9693C8.00174 11.7002 7.92252 12.5044 8.07686 13.2804C8.2312 14.0563 8.61217 14.769 9.17158 15.3284C9.73099 15.8878 10.4437 16.2688 11.2196 16.4231C11.9956 16.5775 12.7998 16.4983 13.5307 16.1955C14.2616 15.8928 14.8864 15.3801 15.3259 14.7223C15.7654 14.0645 16 13.2911 16 12.5C16 11.4391 15.5786 10.4217 14.8284 9.67157C14.0783 8.92143 13.0609 8.5 12 8.5ZM12 14.5C11.6044 14.5 11.2178 14.3827 10.8889 14.1629C10.56 13.9432 10.3036 13.6308 10.1522 13.2654C10.0009 12.8999 9.96126 12.4978 10.0384 12.1098C10.1156 11.7219 10.3061 11.3655 10.5858 11.0858C10.8655 10.8061 11.2219 10.6156 11.6098 10.5384C11.9978 10.4613 12.3999 10.5009 12.7654 10.6522C13.1308 10.8036 13.4432 11.06 13.6629 11.3889C13.8827 11.7178 14 12.1044 14 12.5C14 13.0304 13.7893 13.5391 13.4142 13.9142C13.0391 14.2893 12.5304 14.5 12 14.5Z" fill="#7E5FFF"/>
        </svg>
    );
};

export default EyeIcon;
