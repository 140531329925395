import React, {FC, ReactNode, useCallback, useMemo} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Autocomplete, Box, FormHelperText, TextField, Typography} from '@mui/material';

import {ISelectOption} from '../../models';
import theme from '../../theme';
import {TIMEZONES_OPTIONS} from '../../utils/timezones';
import {AnimateHeight, ChipsList} from '..';

interface ITimeZoneMulti {
    helperText?: string;
    label?: ReactNode;
    name?: string;
    subTitle?: string;
}

const TimeZoneMulti: FC<ITimeZoneMulti> = ({helperText, label, name = 'timezones', subTitle}): JSX.Element => {
    const [searchValue, setSearchValue] = React.useState<ISelectOption | null>(null);

    const {
        control,
        formState: {errors}
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const timezones: ISelectOption[] = value;

    const values: any = useMemo(() => {
        return timezones ? timezones.map(option => ({id: option.value, name: option.text})) : [];
    }, [timezones]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: ISelectOption | null) => {
        if (newValue?.value) {
            const isExists = timezones?.find(item => item.value === newValue.value);

            if (!isExists) {
                const result = timezones?.length ? [...timezones, newValue] : [newValue];

                onChange(result);
            }

            setSearchValue(null);
        }
    };

    const handleDelete = useCallback((item: any) => {   // FIXME remove any
        const result = timezones ? timezones.filter(option => option.value !== item.id) : [];

        onChange(result);
    }, [timezones, onChange]);

    const handleOptionLabel = (option: ISelectOption) => {
        return option.text;
    };

    const isSelected = useCallback((id: string) => values.find((option: { id: string }) => option.id === id), [values]);

    const localTime = (timeZone: string) => {
        try {
            const arr = new Date().toLocaleTimeString('en-GB', {timeZone}).split(':');

            return `${arr[0]}:${arr[1]}`;
        } catch (e) {
            return '';
        }
    };

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <>
                    {label}

                    {
                        subTitle && (
                            <Typography
                                sx={{
                                    marginTop: '-8px',
                                    marginBottom: '16px',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: theme.palette.gray.main,
                                    [theme.breakpoints.up('lg')]: {
                                        textAlign: 'center',
                                        mt: '-20px',
                                        mb: '24px',
                                        fontSize: '14px',
                                        lineHeight: '21px',
                                    }
                                }}
                                variant="body1"
                            >
                                {subTitle}
                            </Typography>
                        )
                    }

                    <Autocomplete
                        clearOnBlur
                        blurOnSelect
                        handleHomeEndKeys
                        id="timezones"
                        fullWidth
                        getOptionLabel={handleOptionLabel}
                        noOptionsText=""
                        options={TIMEZONES_OPTIONS}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    fullWidth
                                    inputRef={ref}
                                    placeholder="Select time zone"
                                    variant="standard"
                                    sx={{
                                        '.MuiInput-root': {
                                            '&::before': {
                                                borderBottom: '2px solid',
                                                borderBottomColor: theme.palette.inputDisabledBorderColor.main
                                            },
                                            '&:hover': {
                                                '&::before': {
                                                    borderBottomColor: theme.palette.primary.main
                                                }
                                            }
                                        }
                                    }}
                                />
                                {/* Show/Hide validation errors with animation */}
                                <AnimateHeight isVisible={!!(errors[name]?.message || helperText)}>
                                    <FormHelperText id={`helper-text-${name}`} sx={{color: errors[name] ? theme.palette.error.main : ''}}>
                                        {errors[name]?.message?.toString() || helperText}
                                    </FormHelperText>
                                </AnimateHeight>
                            </>
                        )}
                        renderOption={(props, option) => <li {...props} style={{width: '100%', display: 'block'}}>
                            <Box
                                color={isSelected(option.value) ? 'primary.main' : 'default'}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <span>{option.text}</span> <span>{localTime(option.value)}</span>
                            </Box>
                        </li>}
                        selectOnFocus
                        sx={{mb: 3}}
                        value={searchValue}
                        onChange={handleChange}
                    />

                    <ChipsList items={values} onDelete={handleDelete}/>
                </>
            )}
        />
    );
};

export default React.memo(TimeZoneMulti);
