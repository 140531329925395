import React from 'react';

import {Button as MUIButton, ButtonProps} from '@mui/material';

interface IProps {
    hasGradient?: boolean;
}

const Button = ({children, hasGradient, ...props}: ButtonProps & IProps): JSX.Element => {
    return (
        <MUIButton
            {...props}
            disableElevation
            disableFocusRipple
        >
            {children}
        </MUIButton>
    );
};

export default Button;
