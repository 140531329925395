import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsFrelancer, EngagementInfoBox} from '../../../../../../../components';
import {EClosureStatus, EEngagementStatus, IEngagementFreelancer} from '../../../../../../../models';

type CloseRequestIncomingProps = {
    data: IEngagementFreelancer;
};

const CloseRequestIncomingBox: React.FC<CloseRequestIncomingProps> = ({data}) => {
    if (data.status === EEngagementStatus.CLOSED || data.closureStatus !== EClosureStatus.CLIENT_REQUESTED) {
        return null;
    }

    const clientName = data.clientName.split(' ')[0];

    return (
        <EngagementInfoBox
            iconUrl="/assets/images/pit-stop-icon.png"
            title={`Close engagement request from ${clientName}`}
        >
            {clientName} has submitted the engagement close request.
            Before responding, please ensure that all tasks and obligations have been completed.

            <Box
                sx={{
                    mt: '16px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <EngagementActionsFrelancer.CloseRespondBtn data={data}/>
            </Box>

        </EngagementInfoBox>
    );
};

export default React.memo(CloseRequestIncomingBox);
