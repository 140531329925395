import React from 'react';

import {styled} from '@mui/material';
import {Message} from '@twilio/conversations';

import {MD} from '../../../../../constants';
import {useAuth, useConversation} from '../../../../../hooks';
import theme from '../../../../../theme';
import {equalMemo, formatTime} from '../../../../../utils';

import AvatarListItem from './AvatarListItem';

export const MessageRowWrapper = styled('div')`
  display: grid;
  gap: 0 12px;
  grid-template-columns: 32px auto 54px;
  grid-template-areas: 
          'author author time'
          'message message time';

  @media (min-width: ${MD}) {
    grid-template-areas: 
          'avatar author .'
          '. message time';
  }

  &:last-of-type {
    margin-bottom: 24px;
  }

  .avatar {
    display: none;
    grid-area: avatar;

    @media (min-width: ${MD}) {
      display: flex;
    }
  }

  .author {
    grid-area: author;
    align-self: center;
    margin: 6px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.black.main};
  }

  .message {
    grid-area: message;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.black.main};
    white-space: pre-wrap;
  }

  .time {
    grid-area: time;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: ${theme.palette.gray.main};
    margin-top: 3px;

    @media (min-width: ${MD}) {
      margin-top: 0;
    }
  }
`;

interface MessageRowProps {
    conversationId: string;
    data: Message;
    isNewAuthor?: boolean;
}

const MessageRow: React.FC<MessageRowProps> = ({conversationId, data, isNewAuthor}) => {
    const {user} = useAuth();
    const {meta} = useConversation(conversationId);

    const isAuthor = user?.email === data.author;

    const avatar = isAuthor ? meta?.localParticipant?.avatar : meta?.remoteParticipant?.avatar;
    const name = isAuthor ? (meta?.localParticipant?.name || '') : (meta?.remoteParticipant?.name || '');

    return (
        <MessageRowWrapper>
            {/* hide 'Avatar + Name' if author the same for the previous message */}
            {isNewAuthor && (
                <AvatarListItem avatar={avatar || ''} isAuthor={isAuthor} name={name || ''}/>
            )}

            <div className="message">
                {data.body}
            </div>
            {data.dateUpdated && data.body && (
                <span className="time">{formatTime(data.dateUpdated)}</span>
            )}
        </MessageRowWrapper>
    );
};

export default React.memo(MessageRow, equalMemo([]));
