import React, {useEffect, useMemo} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useNavigate, useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useInfiniteQuery} from '@tanstack/react-query';

import {freelancerService, GetListResult} from '../../../api';
import {ClientPortalTitle, LoadingMask, TopBox} from '../../../components';
import {FREELANCER_MATCHES, RouteFreelancerPortal} from '../../../constants';
import {IFreelancerMatchList} from '../../../models';
import theme from '../../../theme';

import {EFilter, MatchList, MyMatchesEmpty, MyProfileShort, StatusFilter} from './components';

const PAGE_SIZE = 10;

const MyMatchesPage = () => {
    const {filterBy} = useParams();
    const navigate = useNavigate();

    const {data, fetchNextPage, hasNextPage, isLoading} = useInfiniteQuery(
        [FREELANCER_MATCHES, filterBy],
        freelancerService.getMatches(filterBy || '', PAGE_SIZE),
        {
            enabled: !!filterBy,
            getNextPageParam: (lastPage: GetListResult<IFreelancerMatchList>) => {
                return lastPage.last ? false : lastPage.number + 1;
            },
        }
    );

    // collect all pages results
    const items = useMemo(() => {
        const result: IFreelancerMatchList[] = [];

        data?.pages.forEach(it => result.push(...it.data));

        return result;
    }, [data]);

    // by default set 'Active' filter active
    useEffect(() => {
        if (!filterBy) {
            navigate(`${RouteFreelancerPortal.myMatches}/${EFilter.Active}`, {replace: true});
        }
    }, [filterBy, navigate]);

    if (!filterBy) {    //  fix glitches
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    pt: '38px',
                    [theme.breakpoints.up('md')]: {
                        pt: '56px'
                    }
                }}
            >
                <Box
                    sx={{
                        mb: '22px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        [theme.breakpoints.up('md')]: {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }
                    }}
                >
                    <ClientPortalTitle>
                        My Matches
                    </ClientPortalTitle>

                    <StatusFilter/>
                </Box>

                {
                    !data?.pages[0]?.empty && !isLoading && (
                        <TopBox
                            title="All interactions with your clients"
                            subtitle="Below you’ll find all the roles you were shortlisted by a client to, or have an outgoing conversation with them about."
                        />
                    )
                }
            </Box>

            {
                data?.pages[0]?.empty ? (
                    <MyMatchesEmpty/>
                ) : (
                    <Box sx={{
                        width: '100%',
                        [theme.breakpoints.up('lg')]: {
                            display: 'flex',
                            gap: '24px',
                            alignItems: 'flex-start'
                        }
                    }}>
                        <Box
                            sx={{width: '100%', flexGrow: 0}}
                        >
                            <InfiniteScroll
                                dataLength={items.length}
                                hasMore={hasNextPage || false}
                                loader={<LoadingMask/>}
                                next={fetchNextPage}
                            >
                                <MatchList isEmpty={!!data?.pages[0]?.empty} items={items}/>
                            </InfiniteScroll>
                        </Box>
                        {!isLoading && data && <MyProfileShort/>}
                    </Box>
                )
            }
        </>
    );
};

export default React.memo(MyMatchesPage);
