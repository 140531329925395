import React from 'react';

import {Box} from '@mui/material';

import {AnimatePageWrp} from './AnimatePageWrp';

export const Component404 = () => {
    return (
        <>
            <img src="/assets/images/404.svg" width="455"/>
        </>
    );
};

export const Page404 = () => {
    return (
        <AnimatePageWrp>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80vh'
                }}
            >
                <Component404/>
            </Box>
        </AnimatePageWrp>
    );
};


