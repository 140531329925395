import React, {FC, useCallback, useMemo} from 'react';

import {Autocomplete, Box, TextField, Typography} from '@mui/material';

import {CheckboxIcon} from '../../assets/icons';
import {ISelectOption} from '../../models';
import theme from '../../theme';
import {ChipsList, InputCaption} from '../';

interface ILanguages {
    options: ISelectOption[];
    values?: ISelectOption[];
    onChange: (values: ISelectOption[]) => void;
}

const Languages: FC<ILanguages> = (props): JSX.Element => {
    const [searchValue, setSearchValue] = React.useState<ISelectOption | null>(null);

    // const {setValue} = useFormContext();

    const values: any = useMemo(() => {
        return props.values ? props.values.map(option => ({id: option.value, name: option.text})) : [];
    }, [props.values]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: ISelectOption | null) => {
        if (newValue?.value) {
            const isExists = props.values?.find(item => item.value === newValue.value);

            if (!isExists) {
                const result = props.values?.length ? [...props.values, newValue] : [newValue];

                props.onChange(result);
            }

            setSearchValue(null);
        }
    };

    const handleDelete = useCallback((item: any) => {   // FIXME remove any
        const result = props.values ? props.values.filter(option => option.value !== item.id) : [];

        props.onChange(result);
    }, [props]);

    const handleOptionLabel = (option: ISelectOption) => {
        return option.text;
    };

    const isSelected = useCallback((id: string) => values.find((option: { id: string }) => option.id === id), [values]);

    return (
        <>
            <InputCaption>
                Select a <span>language</span>
            </InputCaption>

            <Typography
                sx={{
                    marginTop: '-8px',
                    marginBottom: '16px',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: theme.palette.gray.main,
                    [theme.breakpoints.up('lg')]: {
                        textAlign: 'center',
                        mt: '-20px',
                        mb: '24px',
                        fontSize: '14px',
                        lineHeight: '21px',
                    }
                }}
                variant="body1"
            >
                Please select language(s) you would like your future contractor to speak
            </Typography>

            <Autocomplete
                clearOnBlur
                blurOnSelect
                handleHomeEndKeys
                id="languages"
                fullWidth
                getOptionLabel={handleOptionLabel}
                noOptionsText=""
                options={props.options || []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        placeholder="Select a language"
                        variant="standard"
                        sx={{
                            '.MuiInput-root': {
                                '&::before': {
                                    borderBottom: '2px solid',
                                    borderBottomColor: theme.palette.inputDisabledBorderColor.main
                                },
                                '&:hover': {
                                    '&::before': {
                                        borderBottomColor: theme.palette.primary.main
                                    }
                                }
                            }
                        }}
                    />
                )}
                renderOption={(props, option) => (
                    <Box
                        {...props}
                        component="li"
                        sx={{
                            '& > span': {flexGrow: 1},
                            color: isSelected(option.value) ? 'primary.main' : 'default',
                            display: 'flex',
                            width: '100%'
                        }}
                    >
                        <span>{option.text}</span>
                        {isSelected(option.value) ? <CheckboxIcon fill={theme.palette.primary.main} /> : null}
                    </Box>
                )}
                selectOnFocus
                sx={{mb: 3}}
                value={searchValue}
                onChange={handleChange}
            />

            <ChipsList items={values} onDelete={handleDelete}/>
        </>
    );
};

export default React.memo(Languages);
