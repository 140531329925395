import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Typography} from '@mui/material';

import {RouteClientPortal, RouteFreelancerPortal} from '../../../constants';
import {useAuth} from '../../../hooks';
import {EEngagementStatus, EResumeStatus, EWorkType, IEngagement} from '../../../models';
import theme from '../../../theme';
import {formatDate} from '../../../utils';
import {
    BlackBorderButton,
    EngagementActionsClient,
    EngagementActionsFrelancer,
    ImagePlaceholder,
    RoleStatusPill
} from '../../index';

interface IProps {
    items: IEngagement[];
}

const EngagementsList: FC<IProps> = ({items}) => {
    const {isClient} = useAuth();
    const navigate = useNavigate();

    const renderButtons = (item: IEngagement) => {
        switch (item.status) {
            case EEngagementStatus.ACTIVE:
                return (
                    <>
                        <RoleStatusPill
                            status={EEngagementStatus.ACTIVE}
                        />
                        {
                            item.budgetType === EWorkType.hourly && (
                                <BlackBorderButton
                                    size="small"
                                    onClick={() => navigate(`${isClient
                                        ? RouteClientPortal.myHireDetails
                                        : RouteFreelancerPortal.myWorkDetails}/${item.id}/timesheets`)}
                                >
                                    {/* maybe 'Log you time' better to show only: item.hasOverdueTimesheets */}
                                    {isClient ? 'View timesheets' : 'Log your time'}
                                </BlackBorderButton>
                            )
                        }
                        {isClient
                            ? <EngagementActionsClient.PauseCancelBtn data={item}/>
                            : <EngagementActionsFrelancer.PauseCancelBtn data={item}/>
                        }
                        {isClient
                            ? <EngagementActionsClient.CloseCancelBtn data={item}/>
                            : <EngagementActionsFrelancer.CloseCancelBtn data={item}/>
                        }
                    </>
                );
            case EEngagementStatus.PAUSED:
                return (
                    <>
                        <RoleStatusPill
                            status={EEngagementStatus.PAUSED}
                        />
                        {isClient
                            ? <EngagementActionsClient.ResumeEngagementBtn data={item}/>
                            : <EngagementActionsFrelancer.ResumeEngagementBtn data={item}/>
                        }
                        {isClient
                            ? <EngagementActionsClient.ResumeCancelBtn data={item}/>
                            : <EngagementActionsFrelancer.ResumeCancelBtn data={item}/>
                        }

                    </>
                );
            default:
                return (
                    <RoleStatusPill
                        status={item.status}
                    />
                );
        }
    };

    return (
        <Box>
            {/*this box to be put in map*/}
            {items.map(item => (
                <Box
                    key={item.id}
                    sx={{
                        p: '16px 0',
                        borderTop: `1px solid ${theme.palette.lightGray.main}`
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            mb: '16px'
                        }}
                    >
                        <ImagePlaceholder
                            sx={{
                                width: '40px',
                                height: '40px',
                                flexShrink: 0,
                                border: `1px solid ${theme.palette.lightGray.main}`,
                                [theme.breakpoints.up('md')]: {
                                    width: '64px',
                                    height: '64px'
                                }
                            }}
                        >
                            {
                                (isClient ? item.freelancerAvatar : item.clientCompanyLogo) && (
                                    <img
                                        src={isClient ? item.freelancerAvatar : item.clientCompanyLogo}
                                        width="64"
                                        height="64"
                                        alt={isClient ? 'freelancer avatar' : 'company logo'}
                                    />
                                )
                            }
                        </ImagePlaceholder>
                        <Box
                            sx={{
                                minWidth: 0
                            }}
                        >
                            <Typography
                                sx={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    [theme.breakpoints.up('md')]: {
                                        overflow: 'auto',
                                        whiteSpace: 'unset'
                                    }
                                }}
                            >
                                {item.name}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px',
                            mb: '8px'
                        }}
                    >
                        {renderButtons(item)}
                    </Box>
                    {
                        item.status === EEngagementStatus.PAUSED && item.resume &&
                        [EResumeStatus.FREELANCER_ACCEPTED, EResumeStatus.CLIENT_ACCEPTED].includes(item.resume.resumeStatus as EResumeStatus) && (
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    color: theme.palette.gray.main
                                }}
                            >
                                Resume date: {formatDate(item.resume.startDate)}
                            </Typography>
                        )
                    }
                </Box>
            ))}
        </Box>
    );
};

export default React.memo(EngagementsList);
