import React, {useEffect} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box} from '@mui/material';

import {WIDGET_PAYLOAD} from '../../../api';
import {AnimatePageWrp, ContinueButton, Portal, ProjectForm, ProjectTopTitle,} from '../../../components';
import {RouteWidget} from '../../../constants';
import {useNavigateUTM} from '../../../hooks';
import {IProjectRequest} from '../../../models';
import theme from '../../../theme';
import {CLIENT_PROJECT_MAX_LENGTH, clientProjectSchema, utm} from '../../../utils';


type ProjectInput = TypeOf<typeof clientProjectSchema>;

const WidgetProject = () => {
    const widgetId = utm.campaign;
    const navigate = useNavigateUTM();

    const methods = useForm<ProjectInput>({
        resolver: zodResolver(clientProjectSchema),
    });

    const {
        handleSubmit,
        setValue,
        watch
    } = methods;

    const projectName = watch('projectName', '');
    const projectDescription = watch('projectDescription', '');

    const onSubmitHandler: SubmitHandler<ProjectInput> = (values: IProjectRequest) => {
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
        const data = widgetPayload ? JSON.parse(widgetPayload) : {};

        localStorage.setItem(WIDGET_PAYLOAD + widgetId, JSON.stringify({...data, ...values}));
        navigate(RouteWidget.signup + location.search);
    };

    useEffect(() => {
        if (!widgetId) return;
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
        const data = widgetPayload ? JSON.parse(widgetPayload) : {};

        const {projectName, projectDescription} = data;

        if (projectName) {
            setValue('projectName', projectName);
            setValue('projectDescription', projectDescription);
        }
    }, [widgetId, setValue]);

    useEffect(() => {
        if (!projectDescription) return;

        if (projectDescription.length > CLIENT_PROJECT_MAX_LENGTH) {
            setValue('projectDescription', projectDescription.substring(0, CLIENT_PROJECT_MAX_LENGTH));
        }
    }, [projectDescription, setValue]);

    return (
        <AnimatePageWrp maxWidth={{md: 500, lg: 746}}>
            <ProjectTopTitle/>

            <FormProvider {...methods}>
                <ProjectForm
                    description={projectDescription}
                    isSubmitting={false}
                    max={CLIENT_PROJECT_MAX_LENGTH}
                    name={projectName}
                />
            </FormProvider>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '72px'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '63px'
                    },
                    '@media(max-height: 900px)': {
                        pb: '60px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    hasArrow
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};

export default WidgetProject;
