import React from 'react';

import {Box, styled} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {LoadingMask} from '../../../components';
import ClientPortalTitle from '../../../components/ClientPortalTitle';
import {GET_MATCHED, MD} from '../../../constants';
import theme from '../../../theme';

import MatchedList from './components/MatchedList';

const PageTopWrp = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 22px;

  @media (min-width: ${MD}) {
    gap: 24px;
  }
`;

const MyMatchesPage = () => {
    const {
        isLoading,
        data
    } = useQuery([GET_MATCHED], clientService.getMatched, {
        staleTime: 60 * 1000,
        select: ((response) => response.data)
    });

    return (
        <Box
            sx={{
                pt: '38px',
                [theme.breakpoints.up('md')]: {
                    pt: '56px'
                }
            }}
        >
            <PageTopWrp>
                <ClientPortalTitle>My matches</ClientPortalTitle>
            </PageTopWrp>

            {isLoading
                ? <LoadingMask/>
                : <>
                    <MatchedList items={data}/>
                </>
            }
        </Box>
    );
};

export default React.memo(MyMatchesPage);
