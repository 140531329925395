import {getTimezoneOffset} from 'date-fns-tz';

import {ISelectOption} from '../models';

import data from './timezones.json';

export interface ITimezone {
    readonly id: string;
    readonly name: string;
    readonly aliases: string[];
}

export const TIMEZONES: ReadonlyArray<ITimezone> = data;

export function findTimezoneById(id: string): ITimezone | undefined {
    return TIMEZONES.find(tz => tz.id == id || tz.aliases?.includes(id));
}

export const TIMEZONES_OPTIONS: ReadonlyArray<Readonly<ISelectOption>> = TIMEZONES.map(timezone => {
    const name = timezone.id.replaceAll(/[^a-z]/gi, '').length == 3 ? timezone.id + ' - ' + timezone.name : timezone.id;
    const offset = getTimezoneOffset(timezone.id) / 1000 / 60;

    if (isNaN(offset)) {
        return {
            text: name,
            value: timezone.id
        };
    }
    const offsetHours = Math.abs(offset) / 60;
    const offsetMinutes = offset % 60;
    const strOffset = `${offset < 0 ? '-' : '+'}${(offsetHours < 10 ? '0' : '') + offsetHours}:${(offsetMinutes < 10 ? '0' : '') + offsetMinutes}`;

    return {
        text: `${name} (UTC ${strOffset})`,
        value: timezone.id
    };
});
