const initial = {opacity: 0, scale: 0.9};
const animate = {opacity: 1, scale: 1};
const exit = {opacity: 0, scale: 0};

const animationBase = {
    initial: {...initial},
    animate: {...animate},
    exit: {...exit},
};

export const animationAlertBox = (i: number) => ({
    ...animationBase,
    transition: {
        delay: 0.05 * i,
        duration: 0.3,
        ease: 'easeInOut',
    }
});
