import React, {useCallback, useState} from 'react';

import {Box} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {CrossOutlineIcon} from '../../../../../../../assets/icons';
import {DialogSuccess, EngagementInfoBox, RedFilledButton} from '../../../../../../../components';
import {FREELANCER_ENGAGEMENT} from '../../../../../../../constants';
import {EEngagementStatus, EPauseStatus, IEngagementFreelancer} from '../../../../../../../models';

import DialogConfirmPending from './DialogConfirmPending';

type PauseRequestPendingProps = {
    data: IEngagementFreelancer;
};

const PauseRequestPending: React.FC<PauseRequestPendingProps> = ({data}) => {
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const handleRejectClose = useCallback((isNeedUpdate: boolean) => {
        setOpen(false);
        // play modal show/hide animation
        setTimeout(() => {
            setOpenSuccess(isNeedUpdate);
        }, 500);
    }, []);

    const handleSuccessClose = useCallback((isNeedUpdate: boolean) => {
        setOpenSuccess(false);
        queryClient.invalidateQueries([FREELANCER_ENGAGEMENT, data.id]);
    }, [data.id, queryClient]);

    if (data.status !== EEngagementStatus.ACTIVE || data?.pause?.status !== EPauseStatus.FREELANCER_REQUESTED) {
        return null;
    }

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/pending-icon.png"
                title="Pause engagement request pending"
            >
                {data.clientName.split(' ')[0]} has received your pause engagement request.
                They have up to 2 business days to approve or reject it.
                Otherwise it will be approved automatically.

                <Box sx={{mb: '16px'}}/>

                <RedFilledButton
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    <CrossOutlineIcon/> Cancel engagement pause
                </RedFilledButton>
            </EngagementInfoBox>

            <DialogConfirmPending engagementId={data.id} open={open} onClose={handleRejectClose}/>

            <DialogSuccess
                open={openSuccess}
                subtitle="Wishing you two productive work!"
                title="The engagement pause has been successfully cancelled."
                onClose={handleSuccessClose}
            />

        </>
    );
};

export default React.memo(PauseRequestPending);
