import React, {useRef} from 'react';
import {motion} from 'framer-motion';
import cloneDeep from 'lodash/cloneDeep';

import styled from '@emotion/styled';

import {useMeasure} from '../../hooks';

const defaultVariants = (visibleWhenCollapsed?: boolean) => {
    return {
        ease: 'easeOut',
        variants: {
            open: {
                opacity: 1,
                height: 'auto'
            },
            collapsed: {
                opacity: visibleWhenCollapsed ? 1 : 0,
                height: 0
            }
        }
    };
};

const Container = styled(motion.div)``;

interface Props {
    children?: any;
    className?: string;
    duration?: number;
    ease?: string;
    isOverflowHidden?: boolean;     // it is good for animation, but bad working with shadows - they are cutted :(
    isVisible: boolean;
    minHeight?: number;
    variants?: {
        open: any;
        collapsed: any;
    };
}

export const AnimateHeight: React.FC<Props> = ({
    children,
    duration,
    ease = cloneDeep(defaultVariants().ease),
    isOverflowHidden = true,
    isVisible,
    minHeight,
    variants = cloneDeep(defaultVariants(!!minHeight).variants),
    ...other
}) => {
    const ref = useRef(null);
    const bounds = useMeasure(ref);

    variants.open.height = bounds?.height;

    return (
        <Container
            initial={isVisible ? 'open' : 'collapsed'}
            animate={isVisible ? 'open' : 'collapsed'}
            inherit={false}
            variants={variants}
            style={{
                overflow: isOverflowHidden ? 'hidden' : '',
                pointerEvents: isVisible ? 'auto' : 'none',
                minHeight: minHeight + 'px' || 'unset',
            }}
            transition={{
                ease,
                duration:
                    typeof duration === 'number'
                        ? duration
                        : getAutoHeightDuration(bounds?.height || 25) / 1000,
            }}
            {...other}
        >
            {typeof children === 'function' ? (
                children(ref)
            ) : (
                <div ref={ref}>{children}</div>
            )}
        </Container>
    );
};

/**
 * Get the duration of the animation depending upon
 * the height provided.
 * @param {number} height of container
 */

function getAutoHeightDuration(height: number) {
    if (!height) return 0;
    const constant = height / 36;

    return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}
