import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Theme, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {ACCEPTED_PHOTO_TYPES} from '../../../../constants';
import {useAuth} from '../../../../hooks';
import {ECaseStudySteps, ICaseStudy} from '../../../../models';
import theme from '../../../../theme';
import {companySchema} from '../../../../utils';
import {
    AnimatePageWrp,
    ContinueButton,
    GrayButton,
    Input,
    InputPhoto,
    Portal,
    showToastError,
    showToastSuccess,
    useCaseStudy,
    WhiteButton,
} from '../../..';

import {InputCaption} from './styles';

type CompanyInput = TypeOf<typeof companySchema>;

export const Step2Company = () => {
    const {isImpersonal} = useAuth();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const {state: {data, cancelUrl, isCanBePublished}, onRefetch, publish, updateCaseStudy} = useCaseStudy();

    const location = useLocation();
    const navigate = useNavigate();

    let nextStep = location.pathname.replace(ECaseStudySteps.company, ECaseStudySteps.challenge);
    const prevStep = location.pathname.replace(ECaseStudySteps.company, ECaseStudySteps.description);

    const methods = useForm<CompanyInput>({
        resolver: zodResolver(companySchema),
    });

    const {
        handleSubmit,
        formState: {isDirty, isValid},
        getValues,
        reset,
        watch
    } = methods;

    const companyName = watch('companyName', '');
    const fileCompanyLogo = watch('fileCompanyLogo', '');

    const {mutate, isLoading: isSubmitting} = useMutation(
        (values: CompanyInput) => {
            const {fileCompanyLogo, ...params} = values;
            const payload = {...data?.draftVersion, ...params, id: data?.id};

            if (fileCompanyLogo?.length) {
                (payload as Partial<ICaseStudy>).companyLogo = fileCompanyLogo[0];
            }

            if (data?.draftVersion.companyLogoUrl && values.fileCompanyLogo === false) {
                delete payload.companyLogoUrl;
            }

            return updateCaseStudy(payload);
        },
        {
            onSuccess(response: any) {
                showToastSuccess('Company information was saved successfully!');

                if (nextStep === 'publish') {
                    publish(response.data.id);
                } else {
                    navigate(nextStep);
                    onRefetch();
                }
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handlePublishClick = () => {
        const values = getValues();

        nextStep = 'publish';
        mutate(values);
    };

    const handleSaveAndExit = () => {
        const values = getValues();

        nextStep = cancelUrl;
        mutate(values);
    };

    const onSubmitHandler: SubmitHandler<CompanyInput> = (values: CompanyInput) => {
        if (isDirty && !isImpersonal) {
            mutate(values);
        } else {
            navigate(nextStep);
        }
    };

    useEffect(() => {
        if (!data) return;

        const {companyName} = data.draftVersion;

        reset({companyName});
    }, [data, reset]);

    return (
        <AnimatePageWrp>

            <FormProvider {...methods}>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <Box>
                        <Box sx={{mb: '30px'}}>
                            <InputCaption sx={{mb: '24px'}}>Company you completed work for</InputCaption>
                            <Input
                                autoFocus={!companyName}
                                counter={100 - (companyName.length || 0)}
                                disabled={isSubmitting}
                                inputProps={{maxLength: 100}}
                                name="companyName"
                                placeholder="Company name"
                            />
                        </Box>

                        <Box>
                            <InputCaption sx={{mb: '24px'}}>Upload a logo of this company (optional)</InputCaption>
                            <InputPhoto
                                accept={ACCEPTED_PHOTO_TYPES}
                                alt=""
                                altShort=''
                                disabled={isSubmitting}
                                helperText="We recommend a photo that is 100x100 (max size: 5MB)"
                                label="Upload logo"
                                name="fileCompanyLogo"
                                previewURL={fileCompanyLogo === false || !data?.draftVersion.companyLogoUrl ? '' : data?.draftVersion.companyLogoUrl}
                            />
                        </Box>
                    </Box>
                </form>
            </FormProvider>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        mt: '40px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '16px',
                        flexWrap: 'wrap'
                    },
                    'button': {
                        width: 'auto'
                    }
                }}
                textAlign="center"
            >
                <WhiteButton
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={() => navigate(prevStep)}
                >
                    Back
                </WhiteButton>
                <ContinueButton
                    disabled={isSubmitting}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
                <GrayButton
                    disabled={!isDirty || !isValid || isSubmitting}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>
                {isCanBePublished && (
                    <GrayButton
                        disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                display: 'none'
                            }
                        }}
                        variant="contained"
                        onClick={handlePublishClick}
                    >
                        Publish
                    </GrayButton>
                )}
            </Box>
            {
                isSmallScreen && (
                    <>
                        <Portal fullWidth order={1}>
                            <ContinueButton
                                disabled={isSubmitting}
                                hasArrow
                                variant="contained"
                                sx={{
                                    'div': {
                                        justifyContent: 'center !important'
                                    }
                                }}
                                onClick={handleSubmit(onSubmitHandler)}
                            >
                                Continue
                            </ContinueButton>

                        </Portal>

                        <Portal fullWidth order={2}>
                            <WhiteButton
                                onClick={() => navigate(prevStep)}
                            >
                                Back
                            </WhiteButton>
                        </Portal>

                        <Portal fullWidth={!isCanBePublished} order={3}>
                            <GrayButton
                                disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                fullWidth
                                variant="contained"
                                onClick={handleSaveAndExit}
                            >
                                Save & exit
                            </GrayButton>
                        </Portal>

                        {isCanBePublished && (
                            <Portal order={4} innerPages>
                                <GrayButton
                                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                    variant="contained"
                                    onClick={handlePublishClick}
                                >
                                    Publish
                                </GrayButton>
                            </Portal>
                        )}

                    </>
                )
            }
        </AnimatePageWrp>
    );
};
