import React, {useCallback, useState} from 'react';
import {AxiosError} from 'axios';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';

import {Box, IconButton, Menu, MenuItem, styled, Typography} from '@mui/material';

import {freelancerService} from '../../../../../api';
import {
    DeleteIcon,
    EditIcon,
    LinkCertificateIcon,
    MoreVerticalIcon,
    PdfCertificateIcon
} from '../../../../../assets/icons';
import {showToastError} from '../../../../../components';
import {CERTIFICATES, RouteFreelancerPortal, SM} from '../../../../../constants';
import {ICertificate} from '../../../../../models';
import theme from '../../../../../theme';
import {getFileName} from '../../../../../utils';

import {DeleteCertificateModal} from './DeleteCertificateModal';

const MAX_LENGTH = 25;

const ItemWrapper = styled('div')`
  width: 100%;
  padding: 16px;
  padding-bottom: 39px;
  background-color: ${theme.palette.white.main};
  border: 1px solid ${theme.palette.lightGray.main};
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);
  border-radius: 16px;

  @media (min-width: ${SM}) {
    width: 200px;
  }
`;

const styleMenuItem = {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    color: theme.palette.black.main,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
    transition: 'color .3s',
    backgroundColor: 'unset',
    minWidth: 'unset',
    '&:hover': {
        backgroundColor: 'unset',
    },
    'svg, div': {
        width: '18px',
        height: '18px',
    },
    'path': {
        fill: theme.palette.gray.main
    }
};

interface ICertificateItem {
    data: ICertificate;
    isDeletable: boolean;
    onDelete: () => void;
}

const CertificateItem: React.FC<ICertificateItem> = React.forwardRef(({
    data,
    isDeletable,
    onDelete
}, ref?: React.Ref<HTMLDivElement>) => {
    const [moreBtnAnchorEl, setMoreBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDelete, setOpenDelete] = useState(false);
    const navigate = useNavigate();

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMoreBtnAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMoreBtnAnchorEl(null);
    };

    const handleOpenDeleteModal = () => {
        handleMenuClose();

        // play modal show/hide animation
        setTimeout(() => {
            setOpenDelete(true);
        }, 100);
    };

    const handleDeleteModalClose = useCallback(async (isDelete: boolean) => {
        setOpenDelete(false);

        if (!isDelete) return;

        try {
            await freelancerService.certificateDelete(data.id);
            onDelete();
        } catch (error) {
            showToastError(error as AxiosError);
        }
    }, [data.id, onDelete]);

    const handleEditCertificate = () => {
        navigate(`${RouteFreelancerPortal.profileSettings}/${CERTIFICATES}/${data.id}`);
    };

    return (
        <>
            <ItemWrapper ref={ref}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <IconButton
                        sx={{
                            mt: '-8px',
                            mr: '-8px',
                            mb: '-8px'
                        }}
                        onClick={handleMenuOpen}
                    >
                        <MoreVerticalIcon/>
                    </IconButton>
                    <Menu
                        open={Boolean(moreBtnAnchorEl)}
                        anchorEl={moreBtnAnchorEl}
                        onClose={handleMenuClose}
                    >
                        <MenuItem
                            sx={styleMenuItem}
                            onClick={handleEditCertificate}
                        >
                            <EditIcon/> Edit
                        </MenuItem>

                        <MenuItem
                            disabled={!isDeletable}
                            sx={styleMenuItem}
                            onClick={handleOpenDeleteModal}
                        >
                            <DeleteIcon/> Delete
                        </MenuItem>
                    </Menu>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: '12px',
                            mb: '16px',
                            width: '64px',
                            height: '64px',
                            backgroundColor: theme.palette.lilac.main,
                            borderRadius: '8px',
                            'svg': {
                                height: '32px',
                                width: '32px'
                            }
                        }}
                    >
                        {data?.isFile ? <PdfCertificateIcon/> : <LinkCertificateIcon/>}
                    </Box>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: 1.5,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '100%'
                        }}
                    >
                        {data.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: 1.5,
                            color: theme.palette.gray.main,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '100%'
                        }}
                    >
                        {data.isFile
                            ? getFileName(data.fileName)
                            : data.url?.length > MAX_LENGTH ? (data.url.substring(0, MAX_LENGTH) + '...') : data.url
                        }
                    </Typography>
                </Box>
            </ItemWrapper>

            <DeleteCertificateModal isOpen={openDelete} onClose={handleDeleteModalClose}/>
        </>
    );
});

export default React.memo(motion(CertificateItem));
