import React, {useEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Stack, Theme, useMediaQuery} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import {useMutation} from '@tanstack/react-query';

import {freelancerService} from '../../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    BlackBorderButton,
    Button,
    Card,
    CardContent,
    CardSubTitle,
    CardTitle,
    Checkbox,
    Chip,
    Input,
    InputBrands,
    InputBusinessModels,
    InputChannels,
    InputExperience,
    InputFixedRate,
    InputLanguages,
    InputPhoto,
    InputRateRange,
    InputSubChannels,
    InputTools,
    InputVerticals,
    InputWorkTypes,
    LoadingMask,
    Location,
    showToastError,
    showToastSuccess,
    TimeZoneMulti,
} from '../../../../components';
import {ACCEPTED_PHOTO_TYPES, RouteFreelancerPortal} from '../../../../constants';
import {useAuth, useLocations} from '../../../../hooks';
import {EWorkType, IChannel, ISelectOption} from '../../../../models';
import theme from '../../../../theme';
import {getNameLetters} from '../../../../utils';
import {TIMEZONES_OPTIONS} from '../../../../utils/timezones';
import {useFreelancer} from '../../contexts';
import {Gamification} from '../../Layout/components';

import {ABOUT_ME_MAX_LENGTH, profileSchema} from './profile.schema';

export type ProfileInput = TypeOf<typeof profileSchema>;

export const ProfileTab = React.memo(() => {
    const {isImpersonal} = useAuth();
    const {state: {data: profile, freelancer}, refetchFleelancer, refetchProfile} = useFreelancer();
    const {
        countries: dataCountries,
        isLocationLoading,
        languages: dataLanguages,
    } = useLocations();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const methods = useForm<ProfileInput>({
        resolver: zodResolver(profileSchema),
        shouldUnregister: true      // after submit, reset a form with new values, clean watch('bio'), and {progress}% animation became broken
    });

    const {mutate, isLoading: isSubmitting} = useMutation(
        ({country, languages, timezones, file, fixedRate, rateRange, ...profile}: ProfileInput) => {
            const payload = {
                ...profile,
                fixedRate: fixedRate ? Number(fixedRate) * 100 : 0, // convert to cents
                rateRange: rateRange ? rateRange.map(it => it * 100) : [],

                country: country.text,
                languages: languages.map((item: ISelectOption) => item.value),
                timezones: timezones.map((item: ISelectOption) => item.value)
            };
            const requests = [];

            // Update Avatar if new file selected
            if (Array.isArray(file) && file.length) {
                requests.push(freelancerService.submitAvatar(file[0]));

                // file Deleted
            } else if (file === false) {
                requests.push(freelancerService.submitAvatar());
            }

            requests.push(freelancerService.submitProfile(payload));

            return Promise.all(requests);
        },
        {
            // Create new account
            onSuccess() {
                showToastSuccess('Profile was successfully updated!');
                refetchFleelancer();
                refetchProfile();
            },
            onError(error: AxiosError) {
                // const email = findFieldError(error, 'form.email');
                // setError('emailTaken' as any, {type: 'custom', message: 'email alredy taken'});

                showToastError(error);
            },
        }
    );

    const {
        handleSubmit,
        formState: {isDirty},
        // setError,
        reset,
        watch
    } = methods;

    const bio = watch('bio', '');
    const file = watch('file', '');
    const yearsOfExperience = watch('yearsOfExperience', 0);
    const workTypes = watch('workTypes', []);

    const isRetainer = workTypes?.includes(EWorkType.retainer);
    const isHourly = workTypes?.includes(EWorkType.hourly);
    const isFixed = workTypes?.includes(EWorkType.fixed);

    const formatRateTitle = useMemo(() => {
        if (isRetainer && !isFixed) {
            return 'Select your retainer rate range (per hour)';
        } else if (isFixed && !isRetainer) {
            return 'Select your fixed rate range (per hour)';
        } else if (isRetainer && isFixed) {
            return 'Select your retainer and fixed rate range (per hour)';
        } else {
            return '';
        }
    }, [isRetainer, isFixed]);

    const onSubmitHandler: SubmitHandler<ProfileInput> = (values) => {
        if (!profile) return;

        mutate({...profile, ...values});
    };

    const optionsCountry = useMemo(() => dataCountries?.map(country => ({
        code: country.code,
        text: country.name,
        value: country.code,
    })), [dataCountries]);

    const optionsLanguage = useMemo(() => dataLanguages?.map(lang => ({text: lang, value: lang})), [dataLanguages]);

    useEffect(() => {
        if (!profile || !freelancer || !optionsCountry?.length) return;

        const {avatar} = freelancer;
        const {
            bio, brands, businessModels,
            isAvailableForNewProject, isOpenToHireRole,
            tools, yearsOfExperience, verticals, workTypes
        } = profile;

        let channels;
        let country;
        let fixedRate;
        let languages;
        let rateRange;
        let timezones;

        if (profile.fixedRate) {
            fixedRate = profile.fixedRate / 100; // convert without cents
        }

        if (profile.channels) {
            channels = profile.channels.map((channel: IChannel) => ({
                ...channel,
                hasSubChannels: !!channel.subchannels.length
            }));
        }

        if (profile.country) {
            country = optionsCountry?.find(item => item.text === profile.country);    // FIXME BE do not support CountryCode but should!!!
        }

        if (profile.languages) {
            languages = optionsLanguage?.filter(item => profile.languages.includes(item.value));
        }

        if (profile.rateRange?.length) {
            rateRange = profile.rateRange.map(it => it / 100); // remove cents
        }

        if (profile.timezones) {
            timezones = TIMEZONES_OPTIONS.filter(item => profile.timezones.includes(item.value));
        }

        reset({
            avatar, bio, brands, businessModels, channels, country,
            isAvailableForNewProject, isOpenToHireRole,
            fixedRate, languages, rateRange,
            timezones, tools, yearsOfExperience, verticals, workTypes
        });
    }, [freelancer, optionsCountry, optionsLanguage, profile, reset]);

    const isPhotoDeleted = file === false;

    return (
        <>
            <AnimatePageWrp>
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        noValidate
                        onSubmit={handleSubmit(onSubmitHandler)}
                    >
                        <Box sx={{
                            [theme.breakpoints.up('lg')]: {
                                maxWidth: 664
                            }
                        }}>
                            <TopToolbar/>
                            <Stack spacing="24px">
                                <Card>
                                    <CardContent>
                                        <InputPhoto
                                            accept={ACCEPTED_PHOTO_TYPES}
                                            alt={`${freelancer?.firstName} ${freelancer?.lastName}`}
                                            altShort={getNameLetters(`${freelancer?.firstName} ${freelancer?.lastName}`)}
                                            disabled={isSubmitting || isImpersonal}
                                            helperText="We recommend a photo that is 100x100 (max size: 5MB)"
                                            name="file"
                                            previewURL={(isPhotoDeleted || !freelancer?.avatar) ? '' : freelancer?.avatar}
                                        />
                                    </CardContent>
                                </Card>

                                <Card>
                                    <CardTitle sx={{mb: '32px'}}>About Me</CardTitle>
                                    <CardSubTitle sx={{mb: '4px'}}>Short Bio</CardSubTitle>
                                    <CardContent>
                                        {/* <InputCaption>Short Bio</InputCaption> */}
                                        <Input
                                            counter={ABOUT_ME_MAX_LENGTH - (bio?.length || 0)}
                                            disabled={isSubmitting}
                                            inputProps={{maxLength: ABOUT_ME_MAX_LENGTH}}
                                            minRows={5}
                                            multiline
                                            name="bio"
                                            placeholder="Write some words about yourself..."
                                            variant="outlined"
                                        />
                                    </CardContent>
                                </Card>

                                <Card>
                                    <CardTitle sx={{mb: '24px'}}>Years of experience</CardTitle>
                                    <CardContent sx={{mb: '12px'}}>
                                        <Box sx={{p: '0 6px'}}>
                                            <InputExperience name="yearsOfExperience"/>
                                        </Box>
                                        <Chip label={`${yearsOfExperience} years`} variant="filled"/>
                                    </CardContent>

                                    <CardTitle>Channels</CardTitle>
                                    <CardSubTitle sx={{mb: '8px'}}>
                                        You can select up to four channels as not everybody can be an amazing
                                        at everything
                                    </CardSubTitle>
                                    <CardContent>
                                        <InputChannels name="channels"/>
                                    </CardContent>

                                    <InputSubChannels/>

                                    <CardTitle>Tools</CardTitle>
                                    <CardSubTitle>You can enter as many as you would like so don’t hold
                                        back</CardSubTitle>
                                    <CardContent>
                                        <InputTools name="tools"/>
                                    </CardContent>

                                    <CardTitle>Verticals</CardTitle>
                                    <CardContent>
                                        <InputVerticals name="verticals"/>
                                    </CardContent>

                                    <CardTitle>Brands</CardTitle>
                                    <CardSubTitle>List any well-known brands or start-ups you have done work
                                        for</CardSubTitle>
                                    <CardContent>
                                        <InputBrands name="brands"/>
                                    </CardContent>

                                    <CardTitle>Business models</CardTitle>
                                    <CardSubTitle>Please select the business models you’ve worked with. You can select
                                        both.</CardSubTitle>
                                    <CardContent>
                                        <InputBusinessModels name="businessModels"/>
                                    </CardContent>

                                </Card>

                                <Card>
                                    <CardTitle>Work Arrangement</CardTitle>
                                    <CardSubTitle sx={{mb: '24px'}}>
                                        You can select multiple work types that fit you best
                                    </CardSubTitle>
                                    <CardContent>
                                        <InputWorkTypes name="workTypes"/>
                                    </CardContent>

                                    <AnimateHeight isOverflowHidden={false} isVisible={isRetainer || isFixed}>
                                        <CardTitle sx={{mb: '24px'}}>{formatRateTitle}</CardTitle>
                                        <CardContent>
                                            <InputRateRange name="rateRange"/>
                                        </CardContent>
                                    </AnimateHeight>

                                    <AnimateHeight isOverflowHidden={false} isVisible={isHourly}>
                                        <Box sx={{mt: 4}}></Box>
                                        <CardTitle>Enter your rate for Hourly work type (in USD)</CardTitle>
                                        <CardContent>
                                            <InputFixedRate name="fixedRate"/>
                                        </CardContent>
                                    </AnimateHeight>
                                </Card>

                                <Card>
                                    <CardTitle sx={{mb: '24px'}}>Select your location</CardTitle>
                                    <CardContent>
                                        {isLocationLoading && <LoadingMask/>}
                                        <Location name="country" options={optionsCountry || []}/>
                                    </CardContent>

                                    <CardTitle>Select a time zone</CardTitle>
                                    <CardSubTitle sx={{mb: '8px'}}>
                                        Please select any time zones you are comfortable working in. Choosing more
                                        timezones will enable to you work remotely on projects from other
                                        regions.
                                    </CardSubTitle>
                                    <CardContent>
                                        <TimeZoneMulti name="timezones"/>
                                    </CardContent>

                                    <CardTitle>Select a language</CardTitle>
                                    <CardSubTitle>Please select language(s) you’re good at.</CardSubTitle>
                                    <CardContent>
                                        <InputLanguages name="languages" options={optionsLanguage || []}/>
                                    </CardContent>

                                </Card>

                                <Card sx={{mb: '16px !important'}}>
                                    <CardContent>
                                        <Stack spacing={2}>
                                            <Checkbox
                                                label="Open to working with Agencies"
                                                name="isAvailableForNewProject"
                                            />
                                            <Checkbox
                                                label="Open to contract-to-hire roles"
                                                name="isOpenToHireRole"
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>

                                <Box>
                                    <Button
                                        disabled={isSubmitting || !isDirty || isImpersonal}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Update my profile
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </form>
                </FormProvider>
            </AnimatePageWrp>
            {
                !lgDown && profile && (
                    <AnimatePageWrp>
                        <Gamification
                            data={profile}
                            bio={bio}
                            photo={file || ((isPhotoDeleted || !freelancer?.avatar) ? '' : freelancer?.avatar)}
                        />
                    </AnimatePageWrp>
                )
            }
        </>
    );
});

const TopToolbar = React.memo(() => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '24px',
                mt: '40px',
                mb: '40px',
                [theme.breakpoints.up('md')]: {
                    mt: 0
                }
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: 1.5,
                    color: theme.palette.black.main
                }}
            >
                My Profile
            </Typography>
            <BlackBorderButton
                size="small"
                onClick={() => navigate(RouteFreelancerPortal.publicFreelancerProfile)}
            >
                View public profile
            </BlackBorderButton>
        </Box>
    );
});
