export enum EMailType {
    ACCOUNT_ISSUES = 'ACCOUNT_ISSUES',
    BILLING_INQUIRY_OR_QUESTION = 'BILLING_INQUIRY_OR_QUESTION',
    BUG_REPORT = 'BUG_REPORT',
    FEEDBACK_AND_FEATURE_REQUEST = 'FEEDBACK_AND_FEATURE_REQUEST',
    INVOICE_DISCREPANCY = 'INVOICE_DISCREPANCY',
    OTHERS_MATCHING = 'OTHERS_MATCHING',
    OTHERS_BILLING = 'OTHERS_BILLING',
    OTHERS_PAYMENT = 'OTHERS_PAYMENT',
    OTHERS_SUPPORT = 'OTHERS_SUPPORT',
    PAYMENT_METHOD = 'PAYMENT_METHOD',
    PAYMENT_REFUND_REQUEST = 'PAYMENT_REFUND_REQUEST',
    PAYMENT_STATUS_OR_CONFIRMATION = 'PAYMENT_STATUS_OR_CONFIRMATION',
    WRONG_INACCURATE_MATCH_OR_MATCHING_ADJUSTMENTS = 'WRONG_INACCURATE_MATCH_OR_MATCHING_ADJUSTMENTS',
}

export interface IContactUs {
    attachments?: File[];
    email: string;
    name: string;
    subject: string;
    text: string;
}
