import React from 'react';

import {Grid, Typography} from '@mui/material';

import {BankIcon, CreditCardIcon} from '../../../../../../assets/icons';
import {AnimateHeight, CardTitle, CheckButtonOutlined} from '../../../../../../components';
import {EPaymentMethod} from '../../../../../../models';

const payments = [{
    icon: <BankIcon/>,
    label: 'ACH direct debit',
    method: EPaymentMethod.ACH,
}, {
    icon: <CreditCardIcon/>,
    label: 'Credit card',
    method: EPaymentMethod.CreditCard,
}, {
    icon: <BankIcon/>,
    label: 'SEPA direct debit',
    method: EPaymentMethod.SEPA,
}
// , {
//     icon: <BankIcon/>,
//     label: 'Pre-authorised debits',
//     method: EPaymentMethod.PreAuthorisedDebits,
// }
];

type SelectPaymentMethodProps = {
    selected: EPaymentMethod;
    onChange: (method: EPaymentMethod) => void;
};

const SelectPaymentMethod: React.FC<SelectPaymentMethodProps> = ({selected, onChange}) => {
    return (
        <Grid container spacing="16px">
            <Grid item xs={12}>
                <CardTitle>Choose a payment method</CardTitle>
            </Grid>

            {payments.map(({icon, label, method}) => (
                <Grid item key={method} xs={12} sm={4}>
                    <CheckButtonOutlined
                        label={(
                            <>
                                {icon}
                                {label}
                            </>
                        )}
                        selected={selected === method}
                        onClick={() => onChange(method)}
                    />
                </Grid>
            ))}

            <Grid item xs={12} sx={{marginTop: '-8px'}}>
                <AnimateHeight isVisible={selected === EPaymentMethod.CreditCard}>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 400,
                        }}
                        variant="body2"
                    >
                        Please note: An additional credit card fee applies to cover credit card transaction expenses
                    </Typography>
                </AnimateHeight>
            </Grid>

        </Grid>
    );
};

export default React.memo(SelectPaymentMethod);
