export enum EWorkType {
    fixed = 'FIXED',
    hourly = 'HOURLY',
    retainer = 'RETAINER',
}

export enum EWorkTypeDisplay {
    fixed = 'Fixed price',
    hourly = 'Hourly rate',
    retainer = 'Retainer',
    FIXED = 'Fixed price',
    HOURLY = 'Hourly rate',
    RETAINER = 'Retainer',
}

export interface IWorkPreference {
    workTypes: EWorkType[];
    fixedRate: number;
    rateRange: [number, number];
}
