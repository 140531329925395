import React, {useCallback, useState} from 'react';
import {AxiosError} from 'axios';

import {Box, Button, MenuItem, Select, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../api';
import {OfficeBagIcon} from '../../assets/icons';
import {useAuth} from '../../hooks';
import {EPaymentMethod, EPaymentStatus, EPaymentStatusDisplay, EPayoutStatusDisplay, EWorkTypePayment, IEarning, IPaymentPreview, ISpending} from '../../models';
import theme from '../../theme';
import {formatCurrency, formatDate} from '../../utils';
import {
    CollapsibleBox,
    ImagePlaceholder,
    MoneyEarnedSpent,
    Pill,
    showToastError,
    showToastSuccess,
    TextButton
} from '../index';

const stylesMenuItem = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    whiteSpace: 'pre-wrap'
};

interface IProps {
    data: ISpending[] | IEarning[];
    paymentMethods?: IPaymentPreview[];
}

const EarningsSpendingsList: React.FC<IProps> = ({data, paymentMethods}) => {
    const {isClient} = useAuth();
    const [paymentId, setPaymentId] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const queryClient = useQueryClient();

    function handlePaymentChange(event: SelectChangeEvent<any>) {
        setPaymentId(event.target.value);
    }

    const handleRetryClick = async (paymentId: number) => {
        if (!paymentMethods) return;
        const id = paymentId || paymentMethods[0].id;

        setIsSubmitting(true);
        try {
            const response = await clientService.paymentMethodRetry(paymentId, Number(id));

            if (response.data.status === EPaymentStatus.FAILED) {
                showToastError(response.data.problem);
            } else {
                showToastSuccess('New payment was sent');
                queryClient.invalidateQueries({queryKey: ['spendings']});
            }
        } catch (error) {
            showToastError(error as AxiosError);
        }
        setIsSubmitting(false);
    };

    const renderPeriod = useCallback((item: IEarning | ISpending) => {
        if (!item.startDate) return '';

        // switch (item.workType) {
        //     case EWorkType.fixed:

        //         // For fixed engagements payments, the client should see the start date only for active engagements
        //         // After fixed engagements payments have been closed, the client should see the contract close date as well
        //         // if (isClient) {

        //         // For fixed engagements payouts the freelancer should see the start and end date
        //         // } else {
        //         //     return `(for ${formatDate(item.startDate)} – ${formatDate(item.endDate)})`;
        //         // }

        //         return `(for ${formatDate(item.startDate)} – ${item.endDate ? formatDate(item.endDate) : 'End of the project'})`;

        //     case EWorkType.hourly:
        //     case EWorkType.retainer:
        //         return `(for ${formatDate(item.startDate)} – ${formatDate(item.endDate)})`;
        //     default:
        //         return '';
        // }

        return `(for ${formatDate(item.startDate)} – ${item.endDate ? formatDate(item.endDate) : 'End of the project'})`;
    }, []);

    return (
        <>
            {data.map((item) => (
                <CollapsibleBox
                    key={item.id}
                    hasCollapse={false}
                    titleContent={(
                        <>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '16px'
                                }}
                            >
                                {formatDate(item.concludedAt || item.createdAt)}
                            </Typography>

                            <Pill
                                size="small"
                                variant={
                                    item.status === EPaymentStatus.SUCCESS
                                        ? 'green'
                                        : item.status === EPaymentStatus.FAILED
                                            ? 'red'
                                            : item.status === EPaymentStatus.REFUNDED
                                                ? 'blue'
                                                : 'gray'
                                }
                            >
                                {isClient ? EPaymentStatusDisplay[item.status] : EPayoutStatusDisplay[item.status]}
                            </Pill>

                            {isClient && item.status === EPaymentStatus.FAILED && paymentMethods?.length && (
                                <Stack direction="row" spacing={2}>
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            width: 300,
                                            gap: '4px',
                                            alignItems: 'center',
                                            p: '10px 16px',
                                            backgroundColor: theme.palette.white.main,
                                            border: `1px solid ${theme.palette.lightGray.main}`,
                                            borderRadius: '8px',
                                            '.MuiInputBase-root': {
                                                width: '100%',
                                                '&::before, &::after': {
                                                    display: 'none'
                                                }
                                            },
                                            '.MuiSelect-select': {
                                                p: '0',
                                                pr: '24px',
                                                '&:focus': {
                                                    backgroundColor: 'unset'
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            disabled={isSubmitting}
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '21px',
                                                color: theme.palette.black.main,
                                            }}
                                            value={paymentId || (paymentMethods?.length ? paymentMethods[0].id.toString() : null)}
                                            variant="standard"
                                            onChange={handlePaymentChange}
                                        >
                                            {paymentMethods?.map(item => (
                                                <MenuItem
                                                    key={item.id}
                                                    sx={stylesMenuItem}
                                                    title={`${item.name} **${item.last4}`}
                                                    value={item.id.toString()}
                                                >
                                                    {item.name} **{item.last4}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </Box>

                                    <Button
                                        disabled={isSubmitting}
                                        size="small"
                                        variant="contained"
                                        onClick={() => handleRetryClick(item.id)}
                                    >Retry Payment</Button>
                                </Stack>

                            )}

                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    color: theme.palette.gray.main
                                }}
                                variant='caption'
                            >
                                {renderPeriod(item)}
                            </Typography>

                            <Box
                                sx={{
                                    ml: 'auto',
                                    [theme.breakpoints.up('md')]: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '16px'
                                    }
                                }}
                            >

                                {isClient && item?.method?.method !== EPaymentMethod.ManualInvoicing && (
                                    <TextButton
                                        sx={{
                                            fontSize: '14px',
                                        }}
                                    >
                                        <a
                                            download={item.invoiceName + '.pdf'}
                                            href={item.invoiceFile}
                                        >
                                            Get invoice
                                        </a>
                                    </TextButton>
                                )}

                                {
                                    !isClient && (
                                        <MoneyEarnedSpent
                                            moneyAmount={formatCurrency(item.amount, true)}
                                            sx={{
                                                display: 'none',
                                                [theme.breakpoints.up('md')]: {
                                                    display: 'flex',
                                                    // borderLeft: `1px solid ${theme.palette.lightGray.main}`,
                                                    pl: '16px'
                                                }
                                            }}
                                        />
                                    )
                                }
                            </Box>
                        </>
                    )}
                >
                    <Box
                        sx={{
                            mt: '16px',
                            pt: '16px',
                            // pb: '16px',
                            borderTop: `1px solid ${theme.palette.lightGray.main}`
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px'
                            }}
                        >
                            <ImagePlaceholder>
                                {(isClient ? (item as ISpending).freelancerAvatar : (item as IEarning).clientCompanyLogo) ?
                                    <img
                                        src={isClient ? (item as ISpending).freelancerAvatar : (item as IEarning).clientCompanyLogo}
                                        width="32"
                                        height="32"
                                        alt={isClient ? (item as ISpending).freelancerName : (item as IEarning).clientCompanyName}
                                    /> : null}
                            </ImagePlaceholder>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    color: theme.palette.gray.main
                                }}
                            >
                                {isClient ? (item as ISpending).freelancerName : (item as IEarning).clientCompanyName}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: '8px',
                                flexWrap: 'wrap',
                                mt: '8px',
                                [theme.breakpoints.up('md')]: {
                                    flexWrap: 'nowrap',
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '100%'
                                }}
                            >
                                {item.roleName}
                            </Typography>
                            <Pill
                                variant="light-blue"
                            >
                                <OfficeBagIcon/>
                                {EWorkTypePayment[item.workType]}
                            </Pill>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}
                            >
                                {isClient && '-'}{formatCurrency(item.amount, true)}
                            </Typography>
                        </Box>

                        {/* <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mt: '16px',
                                gap: '8px',
                                [theme.breakpoints.up('md')]: {
                                    mt: '8px'
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    '& > svg:first-of-type': {
                                        mr: '4px',
                                        flexShrink: 0
                                    }
                                }}
                            >
                                {!isClient && <LogoNoText/>}

                                {item?.method?.method !== EPaymentMethod.ManualInvoicing && (
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '12px',
                                            color: theme.palette.gray.main
                                        }}
                                    >
                                        {isClient ? 'Card Fee' : 'Growth Collective Fee'}
                                    </Typography>
                                )}

                                {!isClient && (
                                    <Tooltip
                                        title="Growth Collective earns 20% for handling lead generation, sales, legal, time-tracking, billing, payments and 1099 compliance.">
                                        <IconButton
                                            sx={{
                                                p: 0
                                            }}
                                        >
                                            <HelpIcon/>
                                        </IconButton>
                                    </Tooltip>
                                )}

                            </Box>
                            {item?.method?.method !== EPaymentMethod.ManualInvoicing && (
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        color: theme.palette.gray.main
                                    }}
                                >
                                    -{formatCurrency((isClient ? (item as ISpending).serviceFee : (item as IEarning).gcFee) || 0, true)}
                                </Typography>
                            )}

                        </Box> */}

                    </Box>
                </CollapsibleBox>
            ))}
        </>
    );
};

export default React.memo(EarningsSpendingsList);
