export enum EModerationStatus {
    APPROVED = 'APPROVED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}

export const moderationStatuses = [
    {name: EModerationStatus.NEW},
    {name: EModerationStatus.APPROVED},
    {name: EModerationStatus.REJECTED},
];
