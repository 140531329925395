import React from 'react';

const MicOnIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9999 15.999C13.1 15.999 14.0415 15.6073 14.8248 14.8244C15.6079 14.0414 15.9995 13.0994 15.9995 11.9994V5.59965C15.9995 4.49961 15.6082 3.55806 14.8248 2.77469C14.0415 1.99149 13.1 1.59961 11.9999 1.59961C10.8999 1.59961 9.95832 1.99149 9.17499 2.77469C8.39162 3.55793 8 4.49961 8 5.59965V11.9994C8 13.0994 8.39179 14.0414 9.17499 14.8244C9.95819 15.6073 10.8999 15.999 11.9999 15.999Z"
                fill="white"/>
            <path
                d="M18.9619 9.83765C18.804 9.6793 18.6161 9.6001 18.3994 9.6001C18.183 9.6001 17.9955 9.6793 17.8369 9.83765C17.6788 9.99596 17.5996 10.1834 17.5996 10.4V12.0001C17.5996 13.5418 17.0516 14.8604 15.9557 15.9562C14.8602 17.052 13.5414 17.6 11.9997 17.6C10.458 17.6 9.13932 17.052 8.04344 15.9562C6.94769 14.8607 6.39986 13.5418 6.39986 12.0001V10.4C6.39986 10.1834 6.32066 9.99596 6.1624 9.83765C6.00404 9.6793 5.81675 9.6001 5.59994 9.6001C5.38314 9.6001 5.19563 9.6793 5.03736 9.83765C4.87896 9.99596 4.7998 10.1834 4.7998 10.4V12.0001C4.7998 13.8417 5.41449 15.4438 6.6435 16.8062C7.87256 18.1685 9.39128 18.9497 11.1997 19.1496V20.7997H7.99979C7.78312 20.7997 7.59565 20.879 7.43734 21.0374C7.27898 21.1956 7.19978 21.3831 7.19978 21.5998C7.19978 21.8161 7.27898 22.004 7.43734 22.1623C7.59565 22.3205 7.78312 22.3999 7.99979 22.3999H15.9994C16.216 22.3999 16.4037 22.3205 16.5618 22.1623C16.7204 22.0041 16.7996 21.8162 16.7996 21.5998C16.7996 21.3832 16.7204 21.1956 16.5618 21.0374C16.4038 20.879 16.2161 20.7997 15.9994 20.7997H12.7999V19.1496C14.608 18.9497 16.1266 18.1685 17.3558 16.8062C18.5849 15.4438 19.1998 13.8417 19.1998 12.0001V10.4C19.1998 10.1835 19.1204 9.99614 18.9619 9.83765Z"
                fill="white"/>
        </svg>
    );
};

export default MicOnIcon;
