import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, Theme, useMediaQuery} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {
    AnimatePageWrp,
    ChannelsList,
    ClientTopTitle,
    ContinueButton,
    GrayButton,
    LoadingMask,
    Portal,
    showToastError,
    showToastSuccess,
    SubChannelsList,
    WhiteButton,
} from '../../../../../../components';
import {CHANNEL, CLIENT_ROLES, RouteClientPortal, VERTICALS} from '../../../../../../constants';
import {useChannels, useClientRole} from '../../../../../../hooks';
import {ERoleStatusFilter, IChannel, ISubChannel} from '../../../../../../models';
import theme from '../../../../../../theme';

export const ClientChannels = () => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {channelId, roleId} = useParams();

    const {clientRole, isClientLoading, refetchClient} = useClientRole(Number(roleId));
    const {channels, isChannelsLoading} = useChannels();
    const [selectedChannelIds, setSelectedChannelIds] = useState<number[]>([]);
    const [selectedSubChannelIds, setSelectedSubChannelIds] = useState<number[]>([]);

    let nextStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${VERTICALS}`;
    const prevStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${CHANNEL}`;

    const isSubChannelsView = !!channelId;
    const selectedChannel = channels?.find(channel => channel.id.toString() === channelId);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (channel: IChannel) => clientService.channelsSubmit(clientRole?.id || 0, channel),
        {
            onSuccess() {
                showToastSuccess('Channel was saved successfully!');
                navigate(nextStep);
                refetchClient();
                queryClient.invalidateQueries([CLIENT_ROLES]);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const selectedCount = useMemo(() => {
        return channels?.filter(channel => selectedChannelIds.includes(channel.id)).length || 0;
    }, [channels, selectedChannelIds]);

    const isSubChannelsExists = useMemo(() => {
        const result = channels?.find(channel => selectedChannelIds.includes(channel.id) && channel.subchannels.length);

        return !!result;
    }, [channels, selectedChannelIds]);

    const handleBackMove = () => {
        if (isSubChannelsView) {
            navigate(-1);
        } else {
            navigate(prevStep);
        }
    };

    const handleClickChannel = useCallback((channel: IChannel) => {
        const ids = selectedChannelIds.filter(id => id !== channel.id);

        // unselect by second click
        if (ids.length !== selectedChannelIds.length) {
            setSelectedChannelIds(ids);
        } else {
            setSelectedChannelIds([channel.id]);
        }
    }, [selectedChannelIds]);

    const handleClickSubChannelChange = useCallback((subChannel: ISubChannel) => {
        const ids = selectedSubChannelIds.filter(id => id !== subChannel.id);

        // if it is new then add into the selected arr
        if (ids.length === selectedSubChannelIds.length) {
            setSelectedSubChannelIds([...ids, subChannel.id]);
        } else {
            setSelectedSubChannelIds(ids);
        }
    }, [selectedSubChannelIds]);

    const handleSaveAndExit = () => {
        const payload = {...prepareData(), saveAndExit: true}; // fix GC-1172

        if (!isEmpty(payload) && !isEqual(payload, clientRole?.channel)) {
            nextStep = `${RouteClientPortal.myRoles}/${ERoleStatusFilter.Drafts}/${roleId}`;
            mutate(payload as IChannel);
        }
    };

    const handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
        (event.target as HTMLButtonElement).blur(); // fix remove focus for FireFox

        if (selectedCount < 1) {
            showToastError('Please select a channel.');

            // switch to SubChannels view
        } else if (isSubChannelsExists && !isSubChannelsView) {
            const firstChannel = channels?.find(channel => channel.id === selectedChannelIds[0]);

            if (!firstChannel) return;
            navigate(firstChannel.id.toString());
        } else {
            submit();
        }
    };

    const prepareData = () => {
        const channel = channels?.find(channel => channel.id === selectedChannelIds[0]);
        const payload = {
            ...channel,
            subchannels: channel ? channel.subchannels.filter(subChannel => selectedSubChannelIds.includes(subChannel.id)) : []
        };

        return payload;
    };

    const submit = () => {
        const payload = prepareData();

        if (!isEmpty(payload) && !isEqual(payload, clientRole?.channel)) {
            mutate(payload as IChannel);
        } else {
            navigate(nextStep);
        }
    };

    useEffect(() => {
        if (!clientRole) {
            return;
        }
        const channelsIds = clientRole.channel ? [clientRole.channel.id] : [];
        const subChannelsIds = clientRole.channel ? clientRole.channel.subchannels.map(item => item.id) : [];

        setSelectedChannelIds(channelsIds);
        setSelectedSubChannelIds(subChannelsIds);
    }, [clientRole]);

    const isValidSubChannel = () => {
        if (!channelId) {
            return true;
        }
        const ids = selectedChannel?.subchannels.map(item => item.id);
        const isExists = selectedSubChannelIds.some(item => ids?.includes(item));

        return isExists;
    };

    const isValid = selectedCount >= 1 && isValidSubChannel();

    const isSubmitBtnDisabled = !isValid || isClientLoading || isChannelsLoading || isSubmitting;

    return (
        <AnimatePageWrp maxWidth={{md: 729, lg: 792}}>
            <ClientTopTitle
                desktopFontSize={24}
                isSubChannelsView={isSubChannelsView}
                subChannelsName={selectedChannel?.name}
            />

            {
                (isChannelsLoading)
                    ? (
                        <LoadingMask/>
                    ) : (
                        isSubChannelsView && selectedChannel
                            ? <SubChannelsList
                                channel={selectedChannel}
                                selectedSubChannelIds={selectedSubChannelIds}
                                onClick={handleClickSubChannelChange}
                            />
                            : <ChannelsList
                                items={channels || []}
                                selectedIds={selectedChannelIds}
                                onClick={handleClickChannel}
                            />
                    )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        my: 5,
                        mb: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '16px'
                    }
                }}
                textAlign="center"
            >
                {
                    isSubChannelsView && (
                        <WhiteButton
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    display: 'none'
                                }
                            }}
                            onClick={handleBackMove}
                        >
                            Back
                        </WhiteButton>
                    )
                }

                <ContinueButton
                    disabled={isSubmitBtnDisabled}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>

                <GrayButton
                    disabled={!selectedCount || (isSubChannelsView && !selectedSubChannelIds?.length) || (!isSubChannelsView && isSubChannelsExists)}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>

            </Box>


            {
                isSmallScreen && (
                    <Portal
                        fullWidth
                        order={1}
                    >
                        <ContinueButton
                            disabled={isSubmitBtnDisabled}
                            hasArrow
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Continue
                        </ContinueButton>
                    </Portal>
                )
            }


            {
                isSmallScreen && isSubChannelsView && (
                    <Portal
                        order={2}
                    >
                        <WhiteButton
                            onClick={handleBackMove}
                        >
                            Back
                        </WhiteButton>
                    </Portal>
                )
            }

            {
                isSmallScreen && (

                    <Portal
                        fullWidth={!isSubChannelsView}
                        order={3}
                    >
                        <GrayButton
                            disabled={!selectedCount || (isSubChannelsView && !selectedSubChannelIds?.length) || (!isSubChannelsView && isSubChannelsExists)}
                            fullWidth
                            variant="contained"
                            onClick={handleSaveAndExit}
                        >
                            Save & exit
                        </GrayButton>
                    </Portal>
                )
            }

        </AnimatePageWrp>
    );
};
