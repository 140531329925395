import React from 'react';
import {useController, useFormContext} from 'react-hook-form';

import {Grid, Theme, useMediaQuery} from '@mui/material';

import {Input, InputHours} from '../../../../../../components';
import DayLabel from '../../../../../../components/Timesheets/DayLabel';
import theme from '../../../../../../theme';

type DayProps = {
    disabled: boolean;
    name: string;
};

const Day: React.FC<DayProps> = ({disabled, name}) => {
    const {
        control,
    } = useFormContext();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const {field: {value: date}} = useController({control, name: `${name}.date`});

    return (
        <Grid
            container
            spacing={mdUp ? '24px' : '32px'}
            alignItems="center"
            sx={{
                mt: 0,
                '&:first-of-type': {
                    mt: '-24px'
                },
                '& > div:nth-of-type(3)': {
                    pl: 0
                }
            }}
        >
            <Grid item xs={5} md={2} flexShrink={0}>
                <DayLabel date={date}/>
            </Grid>
            <Grid item xs={12} md={6} order={{xs: 3, md: 2}}>
                <Input
                    name={`${name}.workDescription`}
                    placeholder="Describe what has been done this day (optional)"
                    sx={{
                        mb: 0 + '!important',
                        mt: '-24px',
                        [theme.breakpoints.up('md')]: {
                            maxWidth: 400,
                            mt: 0
                        }
                    }}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={7} md={4} order={{xs: 2, md: 3}}>
                <InputHours disabled={disabled} name={name}/>
            </Grid>

        </Grid>
    );
};

export default React.memo(Day);
