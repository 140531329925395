import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm, useWatch} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {any, object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {applicationService} from '../../../../api';
import {
    BackButton,
    BlackBorderButton,
    CardSubTitle,
    CardTitle,
    ConfirmDialog,
    ContinueButton,
    Input,
    Portal,
    showToastError,
    showToastSuccess
} from '../../../../components';
import {MD, RouteApplication} from '../../../../constants';
import {useApplication} from '../../../../hooks';
import {IReference} from '../../../../models';
import theme from '../../../../theme';

const Wrp = styled('div')`
  padding-top: 12px;

  @media (min-width: ${MD}) {
    padding-top: 32px;
  }
`;

const defaultValues: Partial<IReference> = {
    companyName: '',
    description: '',
    email: '',
    position: '',
};

const referenceSchema = object({
    id: any(),
    type: any(),
    name: string()
        .min(1, 'Name is required')
        .max(100, 'Value is too long')
        .regex(/[a-zA-Z ]+/gm, 'Name can contain only latin characters'),
    companyName: string()
        .min(1, 'Company Name is required')
        .max(100, 'Value is too long'),
    email: string()
        .min(1, 'Email Address is required')
        .max(100, 'Value is too long')
        .email('Email Address is invalid'),
    description: string(),
    position: string().optional(),
});

type ReferenceInput = TypeOf<typeof referenceSchema>;

const AddReference: React.FC = () => {
    const {refetchApplication, application} = useApplication();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const item = useMemo(() => application?.references?.find((reference: IReference) => reference.id.toString() === params?.referenceId), [application, params?.referenceId]);

    const {mutate, isLoading: isSubmitting} = useMutation(applicationService.referenceSubmit,
        {
            // Create new account
            onSuccess() {
                showToastSuccess('Reference was successfully ' + (item?.id ? 'updated!' : ' added!'));
                refetchApplication();
                handleClose(true);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const methods = useForm<ReferenceInput>({
        resolver: zodResolver(referenceSchema),
        defaultValues,
    });

    const {
        control,
        formState,
        clearErrors,
        handleSubmit,
        reset,
    } = methods;

    const {description, name} = useWatch({control});

    const handleClose = useCallback((force = false) => {
        if (!force && formState.isDirty) {
            setIsConfirmOpen(true);
        } else {
            setIsConfirmOpen(false);
            navigate(RouteApplication.application);
        }
    }, [formState.isDirty, navigate]);

    useEffect(() => {
        const data = item ? {
            ...item,
        } : null;

        reset(data || defaultValues);

        return () => {
            clearErrors();
            reset(defaultValues, {keepDirty: false, keepErrors: false, keepTouched: false});
        };
    }, [item, clearErrors, reset]);

    const onSubmitHandler: SubmitHandler<ReferenceInput> = useCallback((values) => {
        const payload = {
            ...values,
        } as IReference;

        console.log('submit', payload);
        // Update request
        mutate(payload);
    }, [mutate]);

    // Show confirmation
    if (isConfirmOpen) {
        return (
            <ConfirmDialog
                ariaDescribedby="dialog-comfirmation"
                confirmationText="Changes that you made may not be saved."
                open
                title="Are you sure you want to leave this page?"
                onNo={() => setIsConfirmOpen(false)}
                onYes={() => handleClose(true)}
            />
        );
    }

    return (
        <Wrp>
            <Box>
                <Box
                    sx={{
                        mb: '24px',
                        [theme.breakpoints.up('sm')]: {
                            display: 'grid',
                            gridTemplateColumns: '1fr auto 1fr',
                            alignItems: 'center'
                        },
                        [theme.breakpoints.up('md')]: {
                            mb: '40px',
                        }
                    }}
                >
                    <BackButton
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                justifySelf: 'flex-start',
                            }
                        }}
                        onClick={() => handleClose()}
                    />
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            textAlign: 'center',
                            [theme.breakpoints.up('md')]: {
                                fontSize: '24px'
                            }
                        }}
                    >
                        {item ? 'Edit' : 'Add'} a reference
                    </Typography>
                </Box>
                <Box
                    sx={{
                        p: '40px 24px',
                        maxWidth: '696px',
                        m: '0 auto',
                        backgroundColor: theme.palette.white.main,
                        borderRadius: '16px',
                        [theme.breakpoints.up('md')]: {
                            p: '40px'
                        }
                    }}
                >
                    <FormProvider {...methods}>
                        <form
                            noValidate
                            autoComplete="off"
                        >
                            <Grid container rowSpacing="40px" columnSpacing="32px">
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <CardTitle sx={{mb: '24px'}}>Reference’s First and Last Name</CardTitle>
                                    <Input
                                        autoFocus={!name}
                                        counter={100 - (name?.length || 0)}
                                        inputProps={{maxLength: 100}}
                                        name="name"
                                        placeholder="Enter reference’s first and last name"
                                        sx={{
                                            mb: '0 !important'
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CardTitle sx={{mb: '24px'}}>Reference’s Company Name</CardTitle>
                                    <Input
                                        inputProps={{maxLength: 100}}
                                        name="companyName"
                                        placeholder="Enter reference’s company name"
                                        sx={{
                                            mb: '0 !important'
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CardTitle sx={{mb: '24px'}}>Reference’s Email Address</CardTitle>
                                    <Input
                                        inputProps={{maxLength: 100}}
                                        name="email"
                                        placeholder="Enter reference’s email address"
                                        sx={{
                                            mb: '0 !important'
                                        }}
                                        type="email"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <CardTitle sx={{mb: '24px'}}>Your Job Title (optional)</CardTitle>
                                    <Input
                                        inputProps={{maxLength: 100}}
                                        name="position"
                                        placeholder="Enter what was your job title"
                                        sx={{
                                            mb: '0 !important'
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <CardTitle sx={{mb: '4px'}}>Add a reference (optional)</CardTitle>
                                    <CardSubTitle sx={{mb: '24px'}}>
                                        We suggest letting your reference know what this is for… simply enter a note and
                                        we will send it to the reference with your request
                                    </CardSubTitle>
                                    <Input
                                        counter={2000 - (description?.length || 0)}
                                        inputProps={{maxLength: 2000}}
                                        name="description"
                                        minRows={5}
                                        multiline
                                        placeholder="Describe briefly what the reference is for"
                                        sx={{
                                            mb: '0px !important'
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>

                            {
                                mdUp ? (
                                    <Box
                                        sx={{
                                            mt: '40px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '16px'
                                        }}
                                    >
                                        <BlackBorderButton
                                            sx={{
                                                width: 'auto !important'
                                            }}
                                            onClick={() => handleClose()}
                                        >
                                            Cancel
                                        </BlackBorderButton>
                                        <ContinueButton
                                            disabled={isSubmitting}
                                            variant="contained"
                                            sx={{
                                                width: 'auto !important',
                                                'div': {
                                                    justifyContent: 'center'
                                                }
                                            }}
                                            onClick={handleSubmit(onSubmitHandler)}
                                        >
                                            {item?.id ? 'Save a reference' : 'Add a reference'}
                                        </ContinueButton>
                                    </Box>
                                ) : (
                                    <>
                                        <Portal
                                            order={1}
                                            innerPages
                                            visibleUntil={MD}
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <BlackBorderButton
                                                sx={{
                                                    width: '50% !important'
                                                }}
                                                onClick={() => handleClose()}
                                            >
                                                Cancel
                                            </BlackBorderButton>
                                        </Portal>
                                        <Portal
                                            order={2}
                                            innerPages
                                            visibleUntil={MD}
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <ContinueButton
                                                disabled={isSubmitting}
                                                variant="contained"
                                                sx={{
                                                    width: 'auto !important',
                                                    'div': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                                onClick={handleSubmit(onSubmitHandler)}
                                            >
                                                {item?.id ? 'Save a reference' : 'Add a reference'}
                                            </ContinueButton>
                                        </Portal>
                                    </>
                                )
                            }
                        </form>
                    </FormProvider>
                </Box>
            </Box>
        </Wrp>
    );
};

export default React.memo(AddReference);
