import React from 'react';
import {DateRange} from 'mui-daterange-picker';

import {Box, Grid} from '@mui/material';

import {CalendarIcon, LayersIcon} from '../../../assets/icons';
import theme from '../../../theme';
import {DateRangeCalendarPicker, WhiteSquareButton} from '../../index';
import {EDisplayMode} from '../ReportPage';

const MAX_DATE = new Date();

type FiltersProps = {
    dateRange?: DateRange;
    groupBy: EDisplayMode;
    onDateRange: (dateRange?: DateRange) => void;
    onGroup: (value: EDisplayMode) => void;
};

const Filters: React.FC<FiltersProps> = ({dateRange, groupBy, onDateRange, onGroup}) => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3} order={{xs: 2, md: 1}}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '8px'
                    }}
                >
                    <WhiteSquareButton
                        inactive={groupBy === EDisplayMode.Date}
                        sx={{
                            width: '100%',
                            [theme.breakpoints.up('md')]: {
                                width: 'auto'
                            }
                        }}
                        onClick={() => onGroup(EDisplayMode.Role)}
                    >
                        <LayersIcon/>
                        Role
                    </WhiteSquareButton>
                    <WhiteSquareButton
                        inactive={groupBy === EDisplayMode.Role}
                        sx={{
                            width: '100%',
                            [theme.breakpoints.up('md')]: {
                                width: 'auto'
                            }
                        }}
                        onClick={() => onGroup(EDisplayMode.Date)}
                    >
                        <CalendarIcon/>
                        Date
                    </WhiteSquareButton>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} order={{xs: 1, md: 2}}>
                <DateRangeCalendarPicker
                    dateRange={dateRange}
                    endDate={dateRange?.endDate}
                    maxDate={MAX_DATE}
                    startDate={dateRange?.startDate}
                    onDateRange={onDateRange}
                />
            </Grid>
            <Grid item xs={12} md={3} order={{xs: 3}} sx={{
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'block'
                }
            }}/>
        </Grid>
    );
};

export default React.memo(Filters);
