import React from 'react';

import {Link, Stack, Typography} from '@mui/material';
import {Media} from '@twilio/conversations';

import {downloadFile, getFileName, getReadableFileSizeString} from '../../../../../../utils';

type FileInfoProps = {
    media: Media;
};

const FileInfo: React.FC<FileInfoProps> = ({media}) => {
    const fileName = media.filename || 'file';

    const handleDownloadClick = async () => {
        const url = await media.getContentTemporaryUrl();

        if (url) {
            downloadFile(url, fileName);
        }
    };

    return (
        <Stack direction="column" spacing={1}>
            <Typography className="file-name" variant="body2">
                {getFileName(fileName, 18)}
            </Typography>
            <Typography className="file-size" variant="caption">
                {getReadableFileSizeString(media.size)}
            </Typography>

            <Link
                className="file-link"
                color="primary"
                underline="none"
                onClick={handleDownloadClick}
            >
                Download
            </Link>
        </Stack>
    );
};

export default React.memo(FileInfo);
