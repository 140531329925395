import React from 'react';

import {Container, styled} from '@mui/material';

const Page = styled('div')(
    () => `
    height: 100%;
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    `,
);


const PageWrp: React.FC<{children: React.ReactNode}> = ({children}) => (
    <Container maxWidth="xl" sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <Page>
            {children}
        </Page>
    </Container>
);

export default PageWrp;
