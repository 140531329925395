import axios from 'axios';
import jwt from 'jwt-decode';

import {RouteAuth, RouteWidget} from '../constants/Router';
import {IUser} from '../models';
import {isWidget} from '../utils';

import {keycloakParams, tokenURL} from './keycloak';
import {ITokenResponse} from './types';

export const TOKEN_KEY = '_gc_auth_token';
export const REFRESH_TOKEN_KEY = TOKEN_KEY + '_refresh';
export const USER_KEY = '_gc_user';
export const HAS_ACCOUNT_KEY = '_gc_user_exists';
// export const WIDGET_ID = '_gc_widget_id';
export const WIDGET_PAYLOAD = '_gc_widget_payload';


const keycloakApi = axios.create({
    baseURL: process.env.REACT_APP_KEYCLOAK_URL,
    withCredentials: true,
});

const clearToken = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(HAS_ACCOUNT_KEY, 'true');  // show Login page or SignUp Client or Freelancer
};

const getTokenFn = async (username: string, password: string) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    };
    const data = {
        ...keycloakParams,
        username,
        password
    };
    const response = await keycloakApi.post<ITokenResponse>(tokenURL, data, config);

    return response.data;
};

const isTokenExists = (): boolean => {
    // FIXME clean token if expired
    return !!localStorage.getItem(TOKEN_KEY);
};

const loadUser = () => {
    const user = localStorage.getItem(USER_KEY);

    return user ? JSON.parse(user) as IUser : null;
};

let timestamp = 0;

const refreshAccessToken = async () => {
    const config = {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    const data = {
        client_id: keycloakParams.client_id,
        refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
        grant_type: 'refresh_token'
    };

    // if at the page more then one api call it will send many refersh requests
    // so do single refresh request
    if (Date.now() - timestamp < 100) {
        return new Promise((res) => setTimeout(() => res(null), 1000));
    }

    timestamp = Date.now();

    try {
        const response = await keycloakApi.post<ITokenResponse>(tokenURL, data, config);

        // console.log('refresh token', response);

        saveToken(response.data);

        return response.data;
    } catch (error: any) {
        if (error.response?.data.error === 'invalid_grant') {
            clearToken();

            // if it is widget - you can not show login page
            if (isWidget()) {
                document.location.href = RouteWidget.default;
            } else {
                document.location.href = RouteAuth.login;
            }
        }

        return Promise.reject(error);
    }
};

const saveToken = ({access_token, refresh_token}: ITokenResponse) => {
    const user = jwt(access_token );

    localStorage.setItem(TOKEN_KEY, access_token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const authService = {
    clearToken,
    isTokenExists,
    getTokenFn,
    loadUser,
    refreshAccessToken,
    saveToken,
};
