import React from 'react';

import {ListSubheader, ListSubheaderProps} from '@mui/material';

import theme from '../../../theme';

function SelectGroup(props: ListSubheaderProps) {
    return <ListSubheader {...props} sx={{top: '-12px', color: theme.palette.gray.light}}/>;
}

SelectGroup.muiSkipListHighlight = true;

export default SelectGroup;
