import React from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {Link, useLocation} from 'react-router-dom';
import {object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Stack, Typography} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {emailService} from '../../../api';
import {ContinueButton, Input, LoginLink, LoginPageWrp, Logo, showToastError} from '../../../components';
import {RouteAuth} from '../../../constants';
import {useAuth, useNavigateUTM} from '../../../hooks';
import theme from '../../../theme';
import {emailSchema, utm} from '../../../utils';

const loginSchema = object({
    ...emailSchema,
    password: string()
        .trim()
        .min(1, 'Password is required')
});

export type LoginInput = TypeOf<typeof loginSchema>;

const Login = () => {
    const location = useLocation();
    const navigate = useNavigateUTM();

    const {login} = useAuth();

    const from = ((location.state as any)?.from?.pathname as string);

    const methods = useForm<LoginInput>({
        resolver: zodResolver(loginSchema),
    });

    const {
        handleSubmit,
        setError,
    } = methods;

    const {mutate} = useMutation(
        (payload: any) => login(payload.email, payload.password, from),
        {
            onError(error: any) {
                if (error.response?.data.error === 'invalid_grant') {
                    setError('password', {
                        type: 'custom',
                        message: 'Incorrect password. Please try again or you can reset your password.'
                    });
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const onSubmitHandler: SubmitHandler<LoginInput> = async (values) => {
        try {
            await emailService.check('email', values.email);
            mutate(values);
        } catch (error) {
            setError('email', {
                type: 'custom',
                message: "Sorry, we can't find an account with this email address. Please try again or create a new account."
            });
        }
    };

    return (
        <LoginPageWrp>

            <Box textAlign="center" onClick={() => navigate(RouteAuth.login)}>
                <Logo color="#121416"/>
            </Box>
            <Typography
                align="center"
                variant="h1"
                sx={{
                    mt: '40px',
                    mb: 4,
                    fontWeight: '600 !important',
                    fontSize: '24px !important'
                }}
            >
                Welcome to Growth Collective!
            </Typography>

            <FormProvider {...methods}>
                <form
                    // autoComplete="off"
                    noValidate
                    style={{width: '100%'}}
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <Stack
                        spacing="32px"
                    >
                        <Input
                            autoFocus
                            name="email"
                            placeholder="Email"
                            sx={{
                                mb: 0,
                                '.MuiInputBase-input': {
                                    '&:autofill, &:autofill:hover, &:autofill:focus, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
                                        color: theme.palette.black.main,
                                        backgroundColor: theme.palette.white.main,
                                        WebkitTextFillColor: theme.palette.black.main,
                                        WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.white.main} inset`
                                    }
                                }
                            }}
                        />

                        <Input
                            name="password"
                            placeholder="Password"
                            type="password"
                            sx={{
                                mb: 0,
                                '.MuiInputBase-input': {
                                    '&:autofill, &:autofill:hover, &:autofill:focus, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
                                        color: theme.palette.black.main,
                                        backgroundColor: theme.palette.white.main,
                                        WebkitTextFillColor: theme.palette.black.main,
                                        WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.white.main} inset`
                                    }
                                }
                            }}
                        />

                        <Box
                            sx={{
                                mt: '4px !important'
                            }}
                        >
                            <Link to={utm.patchUri(RouteAuth.forgotPassword)}>
                                <Typography
                                    align="right"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: theme.palette.black.main,
                                        '&:hover': {
                                            color: theme.palette.textGray.dark,
                                        }
                                    }}
                                    variant="body2"
                                >
                                    Forgot your password?
                                </Typography>
                            </Link>
                        </Box>

                        <Box>
                            <ContinueButton
                                fullWidth
                                hasArrow
                                label="Sign in"
                                sx={{
                                    mb: '12px',
                                    'div': {
                                        justifyContent: 'center'
                                    }
                                }}
                                type="submit"
                                variant="contained"
                            />
                        </Box>

                        <Box
                            textAlign="center"
                            sx={{
                                'a': {
                                    color: theme.palette.black.main + '!important',
                                }
                            }}
                        >
                            <LoginLink
                                to={utm.patchUri(RouteAuth.joinAs)}
                            >
                                I don’t have an account yet
                            </LoginLink>
                        </Box>

                    </Stack>
                </form>
            </FormProvider>


        </LoginPageWrp>
    );
};

export default Login;
