import React, {useEffect, useRef} from 'react';
import {AudioTrack as TwilioAudioTrack} from 'twilio-video';

interface AudioTrackProps {
    track: TwilioAudioTrack;
}

const AudioTrack: React.FC<AudioTrackProps> = ({track}) => {
    const ref = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (track) {
            const el = ref.current;

            if (!el) return;

            track.attach(el);

            return () => {
                track.detach(el);
            };
        }
    }, [track]);

    return <audio ref={ref}/>;
};

export default AudioTrack;
