import {useEffect, useState} from 'react';

// see https://usehooks.com/useDebounce/
export function useDebounce(str: string, delay: number, minimum: number = 1) {
    const [debouncedValue, setDebouncedValue] = useState(str);

    useEffect(
        () => {
            // search required minimum characters
            if (str.length < minimum) {
                return;
            }

            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(str);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [str, delay, minimum] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}
