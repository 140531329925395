import React, {SyntheticEvent, useRef} from 'react';
import {ClassValue} from 'clsx';
import * as durationUtil from 'duration-fns';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText, Input, Stack, TextFieldProps as MUITextFieldProps} from '@mui/material';

import theme from '../../../theme';
import {normilizeHours} from '../../../utils';
import {AnimateHeight} from '../..';

const stylesInput = {
    fontSize: 14,
    border: 'none',
    width: 35,
    padding: '0 7px',
    '.MuiInputBase-input': {
        padding: 0,
    },
    'input.MuiInputBase-input.MuiInput-input': {
        textAlign: 'center',
        '&::placeholder, &::-webkit-input-placeholder': {
            opacity: '.5 !important'
        },
    },
};

type TextFieldProps = MUITextFieldProps & {
    classNames?: ClassValue[];
}

const InputHours = React.forwardRef(({
    classNames,
    fullWidth = true,
    placeholder,
    variant,
    ...props
}: TextFieldProps, ref): JSX.Element => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const name = `${props.name}.workTime`;
    const {field: {value, onChange}} = useController({control, name});
    const duration = durationUtil.parse(value);

    const hoursRef = useRef<HTMLInputElement | null>(null);
    const minutesRef = useRef<HTMLInputElement | null>(null);

    const correctHours = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const maxHours = '24';
        let key = (event as any).key;
        let value = target.value;

        let typeKey;

        if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
            if (/(\d)/.test(key)) {
                typeKey = 'number';
            } else {
                typeKey = 'specSymbol';
            }

            const sel = window.getSelection()?.toString();

            if (sel) {
                if (typeKey !== 'specSymbol') {
                    value = value.replace(sel, key);
                    key = '';

                    // if user select text in the input and what to replace all with new digit
                    if (key === '' && Number(value) > 2) {
                        value = value.length === 1 ? '0' + value : value;
                    }

                    // by Backspace|Delete erase all
                } else {
                    value = '';
                    target.value = '';
                    event.preventDefault();
                    handleHoursChange(event);

                    return;
                }


                // ✅ Clear text selection
                window.getSelection()?.removeAllRanges();
            }

            // if 8h then move to the minutes
            if (value.length === 0 && Number(key) > 2) {
                target.value = '0' + key;
                event.preventDefault();
                handleHoursChange(event);
                minutesRef.current?.focus();

                return;
            }

            if (value.length == 2 && key === 'ArrowRight') { // if 2 digits and ArrowRight move to the next
                minutesRef.current?.focus();
                event.preventDefault();

                return;
            }

            if (Number(value + key) < 25 && typeKey !== 'specSymbol') { // max input 2 digits
                if (value.length === 2 && value[0] === '0') {
                    minutesRef.current?.focus();
                }
                target.value = value + key;
                event.preventDefault();
                handleHoursChange(event);

                return;
            }

            if (Number(value + key) > 24) { // 33 -> 24
                if (value.length === 2 && value[0] === '0') {
                    minutesRef.current?.focus();
                }
                target.value = maxHours;
                minutesRef.current?.focus();
                event.preventDefault();
                handleHoursChange(event);

                return;
            }

            if (value.length == 1 && typeKey !== 'specSymbol') { //12 focus next -> 00
                target.value = target.value + key;
                minutesRef.current?.focus();
                event.preventDefault();
                handleHoursChange(event);

                return;
            }
        } else {
            event.preventDefault();

            return;
        }
    };

    const correctMinutes = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const maxMinutes = '59';
        let key = (event as any).key;
        let value = target.value;

        let typeKey;

        if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
            if (/(\d)/.test(key)) {
                typeKey = 'number';
            } else {
                typeKey = 'specSymbol';
            }

            const sel = window.getSelection()?.toString();

            if (sel) {
                value = value.replace(sel, key);
                key = '';

                // ✅ Clear text selection
                window.getSelection()?.removeAllRanges();
            }

            if (value.length == 0 && (key === 'Backspace' || key === 'ArrowLeft')) { // if empty and backspace move to the previous
                hoursRef.current?.focus();
                event.preventDefault();

                return;
            }

            if (Number(value + key) < 60) { // 77 -> 59
                target.value = value + key;
                event.preventDefault();
                handleMinutesChange(event);

                return;
            }

            if (Number(value + key) > 59) { // 77 -> 59
                target.value = maxMinutes;
                event.preventDefault();
                handleMinutesChange(event);

                return;
            }

            if (value.length == 1 && typeKey !== 'specSymbol') { //12 backspace -> 1
                target.value = target.value + key;
                event.preventDefault();
                handleMinutesChange(event);

                return;
            }
        } else {
            event.preventDefault();

            return;
        }
    };

    const handleHoursChange = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const hours = Number(target.value);

        onChange(durationUtil.toString({hours, minutes: duration.minutes}));
        triggerRelated(name);
        triggerRelated(`${props.name}.workDescription`);
    };

    const handleMinutesChange = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const minutes = Number(target.value);

        onChange(durationUtil.toString({hours: duration.hours, minutes}));
        triggerRelated(name);
        triggerRelated(`${props.name}.workDescription`);
    };

    const triggerRelated = (name: string) => {
        if (get(errors, name)) {
            trigger(name);
        }
    };

    return (
        <Controller
            control={control}
            defaultValue=''
            name={name}
            render={({field}) => (
                <Box>
                    <Stack
                        direction="row"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 150,
                            height: 48,
                            ml: 'auto',
                            backgroundColor: theme.palette.white.main,
                            borderRadius: '8px',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: !!get(errors, name) ? theme.palette.error.main : theme.palette.lightGray.main,
                            padding: '5px',
                            '&:hover': {
                                borderColor: theme.palette.black.main
                            },
                            fontSize: 14,
                            fontWeight: 500,
                        }}
                    >
                        <Input
                            id="hours"
                            aria-describedby="hours"
                            disabled={props.disabled}
                            disableUnderline
                            inputProps={{
                                tabIndex: 0
                            }}
                            inputRef={hoursRef}
                            placeholder="00"
                            sx={stylesInput}
                            value={isNil(value) ? '' : normilizeHours(duration.hours)}
                            onChange={handleHoursChange}
                            onKeyDown={correctHours}
                        />
                        hh
                        <Box sx={{pl: 2}}>:</Box>
                        <Input
                            id="minutes"
                            aria-describedby="minutes"
                            disabled={props.disabled}
                            disableUnderline
                            inputProps={{
                                tabIndex: 0
                            }}
                            inputRef={minutesRef}
                            placeholder="00"
                            sx={stylesInput}
                            value={isNil(value) ? '' : normilizeHours(duration.minutes)}
                            onChange={handleMinutesChange}
                            onKeyDown={correctMinutes}
                        />
                        mm
                    </Stack>

                    <AnimateHeight isVisible={!!(get(errors, name)?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {get(errors, name)?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </Box>
            )}
        />
    );
});

export default React.memo(InputHours);
