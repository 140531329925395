import React, {Fragment, useCallback, useMemo} from 'react';
import {formatDistanceToNow} from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    Badge,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    styled,
    Typography
} from '@mui/material';

import {notificationService} from '../../api';
import {BellIcon, PlusIcon} from '../../assets/icons';
import UnionIcon from '../../assets/icons/UnionIcon';
import {ActionNotifacations, useNotification} from '../../contexts';
import {useAuth} from '../../hooks';
import theme from '../../theme';
import {showTodayOrLast} from '../../utils';

const NotificationIconWrapper = styled('div')<{ isNew?: boolean }>`
  padding: 8px 11px;
  width: 32px;
  height: 32px;
  border-radius: 10px;
  background-color: ${props => props.isNew ? theme.palette.purple.main : theme.palette.gray.main};
`;

export const Notifications = React.memo(() => {
    const {isImpersonal} = useAuth();
    const {state: {badge, items, openAnchorEl, total}, dispatch: dispatch} = useNotification();

    const itemsGrouped = useMemo(() => {
        const groups: Record<string, boolean> = {};

        return items.map(item => {
            const date = item.createdAt.split('T')[0];
            const createdAt = showTodayOrLast(date);

            if (!groups[createdAt]) {
                item.isNewGroup = true;
                groups[createdAt] = true;
            }

            return item;
        });
    }, [items]);

    const handleClose = async () => {
        dispatch({type: ActionNotifacations.SET_OPEN, payload: null});

        // set all as read = true
        if (badge && !isImpersonal) {
            await notificationService.setAllRead();
            dispatch({type: ActionNotifacations.SET_BADGE, payload: 0});
        }
    };

    const fetchMoreData = useCallback(() => {
        dispatch({type: ActionNotifacations.SET_NEXT_PAGE});
    }, [dispatch]);

    return (
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <Badge
                color="error"
                invisible={!badge}
                variant="dot"
            >
                <IconButton
                    sx={{
                        backgroundColor: !!openAnchorEl ? theme.palette.lightGray.main : 'transparent'
                    }}
                    onClick={e => dispatch({type: ActionNotifacations.SET_OPEN, payload: e.currentTarget})}
                >
                    <BellIcon/>
                </IconButton>
            </Badge>
            {
                openAnchorEl && (
                    <>
                        <Box
                            sx={{
                                position: 'fixed',
                                top: '80px',
                                right: '20px',
                                p: '24px',
                                width: 'calc(100vw - 40px)',
                                height: 'calc(100vh - 40px)',
                                maxWidth: '390px',
                                maxHeight: '498px',
                                borderRadius: '16px',
                                border: `1px solid ${theme.palette.lightGray.light}`,
                                boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                                backgroundColor: theme.palette.white.main,
                                zIndex: 1000,
                                [theme.breakpoints.up('sm')]: {
                                    position: 'absolute',
                                    top: 'calc(100% + 26px)',
                                    left: 'auto',
                                    right: '-12px',

                                }
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '-8px',
                                    right: '110px',
                                    width: 0,
                                    height: 0,
                                    borderStyle: 'solid',
                                    borderWidth: '0 6px 8px 6px',
                                    borderColor: `transparent transparent ${theme.palette.white.main} transparent`,
                                    [theme.breakpoints.up('sm')]: {
                                        right: '24px'
                                    }
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    mb: '16px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: '24px',
                                        lineHeight: 1.5,
                                        color: theme.palette.black.main
                                    }}
                                >
                                    Notifications
                                </Typography>
                                <IconButton
                                    sx={{
                                        mr: '-8px',
                                        'svg': {
                                            width: '24px',
                                            height: '24px',
                                            transform: 'rotate(45deg)'
                                        }
                                    }}
                                    onClick={handleClose}
                                >
                                    <PlusIcon/>
                                </IconButton>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    pb: '8px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: 1.5,
                                        color: theme.palette.gray.main
                                    }}
                                >
                                    {
                                        // items && items[0].createdAt
                                    }
                                </Typography>
                            </Box>

                            <Box
                                id="scrollable-body"
                                sx={{
                                    overflowY: 'auto',
                                    maxHeight: '386px',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={items.length}
                                    next={fetchMoreData}
                                    hasMore={total !== items.length}
                                    loader={
                                        <Typography
                                            align="center"
                                            component="p"
                                            paragraph
                                            variant="caption"
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                lineHeight: 1.4,
                                                color: theme.palette.gray.main
                                            }}
                                        >
                                            Loading...
                                        </Typography>
                                    }
                                    scrollableTarget="scrollable-body"
                                >
                                    <List sx={{pt: 0}}>
                                        {itemsGrouped.map((notification) => (
                                            <Fragment key={notification.id}>
                                                {notification.isNewGroup && (
                                                    <Typography variant="body2">{showTodayOrLast(notification.createdAt.split('T')[0])}</Typography>
                                                )}
                                                <ListItem
                                                    sx={{
                                                        backgroundColor: !notification.read ? theme.palette.lilac.main : 'transparent',
                                                        borderRadius: '16px',
                                                        mb: '8px',
                                                        p: '8px 24px',
                                                        '&:last-of-type': {
                                                            mb: 0
                                                        }
                                                    }}
                                                >
                                                    <ListItemAvatar color="primary">
                                                        <NotificationIconWrapper isNew={!notification.read}>
                                                            <UnionIcon/>
                                                        </NotificationIconWrapper>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={notification.title}
                                                        secondary={<>
                                                            <Typography
                                                                dangerouslySetInnerHTML={{__html: notification.body}}
                                                                sx={{
                                                                    whiteSpace: 'pre-wrap',
                                                                    fontWeight: 400,
                                                                    fontSize: '13px',
                                                                    lineHeight: 1.5,
                                                                    color: notification.read ? theme.palette.gray.main : theme.palette.textGray.dark
                                                                }}
                                                                variant="caption"
                                                            />
                                                            <Typography
                                                                sx={{
                                                                    display: 'block',
                                                                    mt: '2px',
                                                                    fontWeight: 400,
                                                                    fontSize: '12px',
                                                                    lineHeight: 1.5,
                                                                    color: theme.palette.gray.main
                                                                }}
                                                                variant="caption"
                                                            >
                                                                {formatDistanceToNow(new Date(notification.createdAt))} ago
                                                            </Typography>
                                                        </>}
                                                        sx={{
                                                            '& .MuiListItemText-primary': {
                                                                mb: '2px',
                                                                fontWeight: 600,
                                                                fontSize: '14px',
                                                                lineHeight: 1.5,
                                                                color: notification.read ? theme.palette.gray.main : theme.palette.black.main
                                                            },
                                                        }}
                                                    />
                                                </ListItem>
                                            </Fragment>
                                        ))}
                                    </List>
                                </InfiniteScroll>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 950
                            }}
                            onClick={handleClose}
                        />
                    </>
                )
            }
        </Box>
    );
});
