import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import {Controller, useFormContext} from 'react-hook-form';

import {FormControl, FormHelperText} from '@mui/material';

import theme from '../../../theme';
import {AnimateHeight} from '../..';

const InputPhone = ({name = '', sx = {}, ...props}): JSX.Element => {
    const {
        control,
        formState: {errors},
    } = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref, ...field}}) => (
                <FormControl
                    error={!!errors[name]}
                    fullWidth
                    sx={sx}
                >
                    <MuiPhoneNumber
                        inputProps={{
                            ref
                        }}
                        disableAreaCodes
                        defaultCountry="us"
                        autoComplete="nope"
                        dropdownClass="flags"
                        error={!!errors[name]}
                        fullWidth
                        sx={{
                            '.MuiInputBase-root': {
                                '&::before': {
                                    borderWidth: '2px'
                                },
                                '&:hover': {
                                    '&::before': {
                                        borderColor: theme.palette.primary.main
                                    },
                                },
                            },
                            '.MuiOutlinedInput-root': {
                                backgroundColor: theme.palette.white.main
                            }
                        }}
                        type="tel"
                        {...field}
                        {...props}
                    />
                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </FormControl>
            )}
        />
    );
};

export default InputPhone;
