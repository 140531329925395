import React, {FC, useCallback, useContext, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, IconButton, Theme, useMediaQuery} from '@mui/material';
import {SxProps} from '@mui/system';

import {ArchiveIcon, CalendarIcon, PhoneIcon, VideoCallIcon} from '../../../../assets/icons';
import {VIDEO_CALL} from '../../../../constants';
import {TwilioContext} from '../../../../contexts';
import {useAuth, useConversation} from '../../../../hooks';
import {EConversationStatus, EEngagementStatus} from '../../../../models';
import {EMeetingStatus} from '../../../../models/meeting.model';
import theme from '../../../../theme';
import {BlackBorderButton, BlueButton, Pill, RoleStatusPill, showToastInfo} from '../../../index';
import {ACTIVE_STATUSES, ARCHIVED_STATUSES} from '../../InboxPage';
import ChatScheduleMeeting from '../ChatScheduleMeeting';
import ChatSendProposal from '../ChatSendProposal';
import {AcceptedPill, PendingPill} from '../Pills';

const activeMeetingStatuses = [EMeetingStatus.ACCEPTED, EMeetingStatus.CLIENT_PROPOSED, EMeetingStatus.FREELANCER_PROPOSED];

const blueIconBtnStyles: SxProps<Theme> = {
    p: '12px',
    backgroundColor: theme.palette.blue.light,
    color: theme.palette.blue.dark,
    order: 1,
    '&:hover, &:focus': {
        backgroundColor: theme.palette.blue.main,
        borderColor: theme.palette.blue.main,
    },
    'svg': {
        width: '18px',
        height: '18px'
    },
    'path': {
        fill: 'currentColor'
    },
    [theme.breakpoints.up(1400)]: {
        order: 'unset'
    }
};

interface IChatTopButtons {
    conversationId: string;
    engagementStatus?: EEngagementStatus;
}

const ChatTopButtons: FC<IChatTopButtons> = ({conversationId, engagementStatus}) => {
    const {isImpersonal} = useAuth();
    const {activeCall} = useContext(TwilioContext);
    const {attributes: {meetings, status}, meta, sendProposal} = useConversation(conversationId);
    const [proposalModalOpen, setProposalModalOpen] = useState(false);
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
    const {videoCallRoomId} = useParams();

    const navigate = useNavigate();

    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(1400));
    const xxlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(1630));

    const isNewMeetingExists = meetings?.some(it => activeMeetingStatuses.includes(it.status as EMeetingStatus));

    const handleCallStart = (callType: 'video' | 'audio') => {
        switch (callType) {
            case 'video':
                navigate(VIDEO_CALL);
                break;
            case 'audio':
                navigate(`${VIDEO_CALL}/audio`);
                break;
            default:
                navigate(`${VIDEO_CALL}/audio`);
                break;
        }
    };

    const handleProposalModalClose = useCallback((proposalId: number | null) => {
        setProposalModalOpen(false);

        if (proposalId) {
            console.log('send proposalId', proposalId);
            sendProposal(proposalId);
        }
    }, [sendProposal]);

    const handleScheduleModalClose = useCallback((meetingId: number | null) => {
        setScheduleModalOpen(false);

        if (meetingId) {
            console.log('send meeting', meetingId);
            // sendProposal(proposalId);
        }
    }, []);

    // hide Schedule button if meeting already created
    const renderScheduleBtn = () => {
        if (isNewMeetingExists) return null;

        return (xlUp || lgDown) && !xxlUp ? (
            <IconButton
                disabled={!!activeCall || isImpersonal}
                sx={blueIconBtnStyles}
                onClick={() => setScheduleModalOpen(true)}
            >
                <CalendarIcon/>
            </IconButton>
        ) : (
            <BlueButton
                disabled={!!activeCall || isImpersonal}
                size="small"
                onClick={() => setScheduleModalOpen(true)}
            >
                <CalendarIcon/>
                Schedule a call
            </BlueButton>
        );
    };

    const showToptalNotification = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        showToastInfo('New engagements must be created in Toptal. Please log in to your Toptal account or contact your account manager.');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                flexShrink: 0
            }}
        >

            {status === EConversationStatus.New && !meta?.isInitByFreelancer && (
                <BlackBorderButton
                    disabled={isImpersonal}
                    size="small"
                    onClick={showToptalNotification}
                    sx={{
                        order: 2,
                        [theme.breakpoints.up('lg')]: {
                            order: 'unset'
                        }
                    }}
                >
                    Send a proposal
                </BlackBorderButton>
            )}

            {engagementStatus ? (
                <RoleStatusPill status={engagementStatus}/>
            ) : (
                <>
                    {status === EConversationStatus.Accepted && <AcceptedPill/>}
                    {status === EConversationStatus.Proposed && <PendingPill/>}
                </>
            )}

            {/* disable Call, Video and Schedule for not active chats */}
            {ACTIVE_STATUSES.includes(status) && !meta?.isInitByFreelancer && (
                <>
                    {renderScheduleBtn()}
                    <IconButton
                        disabled={!!activeCall || !!videoCallRoomId || isImpersonal}
                        sx={blueIconBtnStyles}
                        onClick={() => handleCallStart('audio')}
                    >
                        <PhoneIcon/>
                    </IconButton>
                    <IconButton
                        disabled={!!activeCall || !!videoCallRoomId || isImpersonal}
                        sx={blueIconBtnStyles}
                        onClick={() => handleCallStart('video')}
                    >
                        <VideoCallIcon/>
                    </IconButton>
                </>
            )}

            {/* Chat has been archived label */}
            {ARCHIVED_STATUSES.includes(status) && (
                <Pill icon={<ArchiveIcon/>} hasIcon iconPosition="start" variant="gray">
                    Chat has been archived
                </Pill>
            )}

            <ChatSendProposal
                conversationId={conversationId || ''}
                open={proposalModalOpen}
                onClose={handleProposalModalClose}
            />

            <ChatScheduleMeeting
                conversationId={conversationId}
                open={scheduleModalOpen}
                onClose={handleScheduleModalClose}
            />
        </Box>
    );
};

export default React.memo(ChatTopButtons);
