import React from 'react';

import {ButtonProps} from '@mui/material';

import theme from '../../../theme';
import Button from '../Button';


const BlueFilledButton = ({children, ...props}: ButtonProps): JSX.Element => {
    return (
        <Button
            {...props}
            variant="contained"
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                padding: '17px 20px 18px',
                backgroundColor: theme.palette.blue.dark,
                color: theme.palette.white.main,
                border: '2px solid',
                borderColor: theme.palette.blue.dark,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.blue.light,
                    color: theme.palette.blue.dark
                },
                'svg': {
                    flexShrink: 0
                },
                'path': {
                    fill: 'currentColor'
                },
                ...props.sx
            }}
        >
            {children}
        </Button>
    );
};

export default BlueFilledButton;
