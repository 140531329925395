import React from 'react';

import {FormControl, FormHelperText, InputLabel, MenuItem, Select as MUISelect, Theme} from '@mui/material';
import {SelectProps} from '@mui/material/Select/Select';
import {SxProps} from '@mui/system';

import theme from '../../../theme';
import {AnimateHeight, SelectGroup} from '../..';

interface ISelect extends SelectProps {
    error?: boolean;
    fullWidth?: boolean;
    helperText?: string;
    label: string;
    listItems: Array<{ value: string; text: string } | {groupName: string}>;
    labelStyles?: SxProps<Theme>;
}

const Select = ({error, fullWidth, helperText, label, listItems, labelStyles, ...props}: ISelect): JSX.Element => {
    return (
        <>
            <InputLabel sx={labelStyles}>{label}</InputLabel>
            <FormControl
                sx={{
                    m: 1,
                    minWidth: 120,
                    position: 'relative',
                    '& .MuiFormLabel-root': {
                        position: 'absolute !important',
                        top: '16px',
                        pointerEvents: 'none'
                    },
                    '& .MuiFormLabel-filled': {
                        display: 'none'
                    },
                    '& legend': {
                        visibility: 'visible',
                        overflow: 'visible',
                        fontWeight: 400,
                        fontSize: '13px',
                        lineHeight: '20px',
                        color: theme.palette.gray.main,
                        backgroundColor: 'transparent',
                        height: 'unset',
                        top: '-24px',
                        left: '-7px',
                        'span': {
                            opacity: 1 + '!important',
                            position: 'relative',
                            padding: 0
                        }
                    },
                    ...props.sx
                }}
                error={error}
                fullWidth={fullWidth}
            >
                <InputLabel>{label}</InputLabel>
                <MUISelect
                    label={label}
                    variant="standard"
                    sx={{
                        maxHeight: 230,
                    }}
                    {...props}
                    className="select"
                >
                    {
                        listItems.map((item: any, index) => item.text
                            ? <MenuItem key={`${item.value}-${index}`} value={item.value}>{item.text}</MenuItem>
                            : <SelectGroup key={`${item.groupName}-${index}`} >{item.groupName}</SelectGroup>)
                    }
                </MUISelect>

                {/* Show/Hide validation errors with animation */}
                <AnimateHeight isVisible={!!helperText}>
                    <FormHelperText id={'helper-text-select'}>
                        {helperText}
                    </FormHelperText>
                </AnimateHeight>
            </FormControl>
        </>
    );
};

export default Select;
