import React from 'react';

import {Box, styled} from '@mui/material';

import {CardSubTitle, CardTitle} from '../../../Card/Card';


const ChatEmpty = styled('div')`
  margin: auto;
  padding: 12px;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
`;
const SearchEmptyResult = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit'
            }}
        >
            <ChatEmpty>
                <div>
                    <img src="/assets/images/search-icon.png" width="64" height="64" alt="glancing eyes emoji"/>
                </div>
                <CardTitle
                    sx={{
                        mb: '2px',
                        mt: '32px',
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    No matching results
                </CardTitle>
                <CardSubTitle>
                    We couldn’t find what you were<br/>searching for. Try again.
                </CardSubTitle>
            </ChatEmpty>
        </Box>
    );
};

export default React.memo(SearchEmptyResult);
