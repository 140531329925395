import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {clientService} from '../../../api';
import {LoadingMask} from '../../../components';
import {RouteClientPortal} from '../../../constants';


// Redirect here from the ThankYou page or by + Add a Role button at the Header
// Silently create new Role and riderect to the /:roleId/edit

const CreateNewRolePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        let isMount = true;
        const addRole = async () => {
            const response = await clientService.createRole();

            if (isMount) {
                navigate(`${RouteClientPortal.myRoleDetails}/${response.data.id}/edit`);
            }
        };

        addRole();

        return () => {
            isMount = false;
        };
    }, [navigate]);

    return (
        <>
            <LoadingMask />;
        </>
    );
};

export default CreateNewRolePage;
