import React, {useState} from 'react';

import {Grid} from '@mui/material';

import {AsideBlock, AsideSignupInitialContent, MainContent} from '../../../components';
import {ERole} from '../../../models';
import theme from '../../../theme';

import {JoinAsClientOrFreelancerLayout} from './index';

const JoinAsClientOrFreelancer = () => {
    const [isClientRole, setIsClientRole] = useState(false);

    const handleRoleChange = (role: ERole) => {
        setIsClientRole(role === ERole.CLIENT);
    };

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'column',
                [theme.breakpoints.up('sm')]: {
                    flexDirection: 'row'
                }
            }}
        >
            <AsideBlock fixedScroll>
                <AsideSignupInitialContent isClientRole={isClientRole}/>
            </AsideBlock>
            <MainContent isMobileDark>
                <JoinAsClientOrFreelancerLayout isClientRole={isClientRole} onRoleChange={handleRoleChange}/>
            </MainContent>
        </Grid>
    );
};

export default React.memo(JoinAsClientOrFreelancer);
