import React, {KeyboardEvent, ReactNode, useCallback, useRef} from 'react';

import {Autocomplete, Box, FilterOptionsState, TextField, useTheme} from '@mui/material';

import {CheckboxIcon, EnterIcon} from '../../../assets/icons';
import {OptionType} from '../../../models';
import {AnimateHeight, ChipsList} from '../..';
import {PressEnterCaption} from '../../AutocompleteAsync/AutocompleteAsync';

interface IProps {
    freeSolo?: boolean;
    label?: ReactNode;
    options: OptionType[];
    placeholder: string;
    pressCaption?: string;
    selected: OptionType[];
    onChange: (newValue: OptionType | null) => void;
    onDelete: (item: OptionType) => void;
}

const SelectMulti: React.FC<IProps> = ({freeSolo, label, options, placeholder, pressCaption, selected, onChange, onDelete}) => {
    const [searchValue, setSearchValue] = React.useState<OptionType | null>(null);
    const theme = useTheme();
    const ref = useRef<HTMLInputElement | null>(null);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptionType | null) => {
        if (newValue?.name) {
            onChange(newValue);

            setSearchValue(null);
        }
    };

    const handleKeydown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (freeSolo && event.code === 'Enter' && searchValue) {
            const duplicate = options.find(tool => tool.name.toLowerCase() === searchValue.name.toLowerCase());

            handleChange(event, duplicate || searchValue);
            ref?.current?.blur();
        }
    };

    const handleFilter = (options: OptionType[], params: FilterOptionsState<OptionType>) => {
        return options.filter((opt) => opt.name.toLowerCase().includes(params.inputValue.toLowerCase()));
    };

    const handleOptionLabel = (option: OptionType) => {
        return option.name;
    };

    const isSelected = useCallback((id?: number) => selected.find((option) => option.id === id), [selected]);

    return (
        <>
            {label || null}

            <Autocomplete
                clearOnBlur
                blurOnSelect
                handleHomeEndKeys
                id="select-multi"
                filterOptions={handleFilter}
                fullWidth
                getOptionLabel={handleOptionLabel}
                noOptionsText=""
                options={options || []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        inputRef={ref}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {pressCaption && (
                                        <PressEnterCaption>
                                            <EnterIcon/>
                                            <span>Press Enter <span>to add a {pressCaption}</span></span>
                                        </PressEnterCaption>
                                    )}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                        placeholder={placeholder}
                        variant="standard"
                        sx={{
                            '.MuiInput-root, &.MuiInput-root': {
                                '&::before': {
                                    borderBottomColor: theme.palette.inputDisabledBorderColor.main,
                                    borderWidth: '2px',
                                },
                                '&:hover': {
                                    '&::before': {
                                        borderBottomColor: theme.palette.primary.main
                                    }
                                }
                            }
                        }}
                        onInput={(event: any) => setSearchValue({name: event.target.value})}
                        onKeyDown={handleKeydown}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} style={{display: 'block'}}>
                        {
                            isSelected(option?.id) ? (
                                <Box
                                    color="primary.main"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <span>{option.name}</span> <CheckboxIcon fill={theme.palette.primary.main}
                                        height={22} width={22}/>
                                </Box>
                            ) : (
                                <span>{option.name}</span>
                            )
                        }

                    </li>
                )}
                selectOnFocus
                sx={{mb: 3}}
                value={searchValue}
                onChange={handleChange}
            />

            <AnimateHeight isVisible={true}>
                <ChipsList items={selected || []} onDelete={onDelete}/>
            </AnimateHeight>

        </>
    );
};

export default React.memo(SelectMulti);
