import React from 'react';

import {Avatar, Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {BlackBorderButton, ContinueButton} from '../../../../../../components';
import {LG} from '../../../../../../constants';
import {EEngagementStatus, IEngagementClient} from '../../../../../../models';
import theme from '../../../../../../theme';
import {getNameLetters, stringToColor} from '../../../../../../utils';

import BudgetType from './BudgetType';
import PauseEngagementBtn from './PauseEngagementBtn';

const Wrapper = styled('div')`
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid ${theme.palette.lightGray.main};
  border-bottom: 1px solid ${theme.palette.lightGray.main};

  @media (min-width: ${LG}) {
    margin: 0;
    padding: 24px;
    background-color: ${theme.palette.white.main};
    border-radius: 24px;
    border: none;
  }
`;

interface FreelancerInfoBoxProps {
    data: IEngagementClient;
    onMessage: () => void;
    onViewProfile: () => void;
}

const FreelancerInfoBox: React.FC<FreelancerInfoBoxProps> = ({data, onMessage, onViewProfile}) => {
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));


    return (
        <Wrapper>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '64px auto',
                    gridTemplateAreas: '"avatar name"' +
                        '"avatar experience"',
                    alignContent: 'center',
                    columnGap: '16px',
                    mb: '8px',
                    [theme.breakpoints.up('lg')]: {
                        gridTemplateColumns: '80px auto',
                    }
                }}
            >
                <Avatar
                    alt={data.freelancerName}
                    src={data.freelancerAvatar}
                    sx={{
                        gridArea: 'avatar',
                        width: '64px',
                        height: '64px',
                        background: stringToColor(data.freelancerName),
                        fontSize: '20px',
                        lineHeight: 1.5,
                        fontWeight: 500,
                        'img': {
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            aspectRatio: '1/1'
                        },
                        [theme.breakpoints.up('lg')]: {
                            width: '80px',
                            height: '80px',
                            fontSize: '32px',
                        }
                    }}
                >
                    {getNameLetters(data.freelancerName)}
                </Avatar>
                <Typography
                    sx={{
                        gridArea: 'name',
                        alignSelf: 'flex-end',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    {data.freelancerName}
                </Typography>
                <Typography
                    sx={{
                        gridArea: 'experience',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: 1.5,
                        color: theme.palette.gray.main,
                        'span': {
                            font: 'inherit',
                            lineHeight: 'inherit',
                            color: theme.palette.black.main
                        }
                    }}
                >
                    <span>{data.freelancer.profile.yearsOfExperience} years</span> of
                    experience in marketing
                </Typography>
            </Box>
            <Typography
                sx={{
                    mb: '16px',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.6,
                    color: theme.palette.textGray.dark
                }}
            >
                {data.freelancer.profile.bio}
            </Typography>

            <BudgetType budgetType={data.budgetType} isMonthly={data.isMonthly}/>

            {lgUp && (
                <Box
                    sx={{
                        borderTop: `1px solid ${theme.palette.lightGray.main}`,
                        pt: '16px',
                        mt: '16px',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                        '.MuiButton-sizeSmall': {
                            p: '10px 15px !important'
                        }
                    }}
                >
                    {/* Link to chat active for Pending, Active, and Paused Roles */}
                    {
                        data.status !== EEngagementStatus.CLOSED && (
                            <ContinueButton
                                size="small"
                                sx={{
                                    width: 'auto!important'
                                }}
                                variant="contained"
                                onClick={onMessage}
                            >
                                Message
                            </ContinueButton>
                        )
                    }

                    <BlackBorderButton
                        size="small"
                        onClick={onViewProfile}
                    >
                        View Profile
                    </BlackBorderButton>

                    <PauseEngagementBtn
                        data={data}
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '8px',
                            'svg': {
                                width: '16px',
                                height: '16px'
                            },
                            'path': {
                                fill: 'currentColor'
                            }
                        }}
                    />

                </Box>
            )}

        </Wrapper>
    );
};

export default FreelancerInfoBox;
