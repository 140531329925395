import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {
    AnimatePageWrp,
    ChannelsList,
    ClientTopTitle,
    ContinueButton,
    LoadingMask,
    Portal,
    showToastError,
    showToastSuccess,
    SubChannelsList,
} from '../../../components';
import {RouteCreateClientAccount} from '../../../constants';
import {useChannels, useClientRole, useNavigateUTM} from '../../../hooks';
import {IChannel, ISubChannel} from '../../../models';
import theme from '../../../theme';

export const ClientChannels = () => {
    const navigate = useNavigateUTM();
    const {channelId} = useParams();

    const {clientRole, isClientLoading, refetchClient} = useClientRole();
    const {channels, isChannelsLoading} = useChannels();
    const [selectedChannelIds, setSelectedChannelIds] = useState<number[]>([]);
    const [selectedSubChannelIds, setSelectedSubChannelIds] = useState<number[]>([]);

    const isSubChannelsView = !!channelId;
    const selectedChannel = channels?.find(channel => channel.id.toString() === channelId);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (channel: IChannel) => clientService.channelsSubmit(clientRole?.id || 0, channel),
        {
            onSuccess() {
                showToastSuccess('Channel was saved successfully!');
                navigate(RouteCreateClientAccount.verticals);
                refetchClient();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const selectedCount = useMemo(() => {
        return channels?.filter(channel => selectedChannelIds.includes(channel.id)).length || 0;
    }, [channels, selectedChannelIds]);

    const isSubChannelsExists = useMemo(() => {
        const result = channels?.find(channel => selectedChannelIds.includes(channel.id) && channel.subchannels.length);

        return !!result;
    }, [channels, selectedChannelIds]);

    const handleClickChannel = useCallback((channel: IChannel) => {
        const ids = selectedChannelIds.filter(id => id !== channel.id);

        // unselect by second click
        if (ids.length !== selectedChannelIds.length) {
            setSelectedChannelIds(ids);
        } else {
            setSelectedChannelIds([channel.id]);
        }
    }, [selectedChannelIds]);

    const handleClickSubChannelChange = useCallback((subChannel: ISubChannel) => {
        const ids = selectedSubChannelIds.filter(id => id !== subChannel.id);

        // if it is new then add into the selected arr
        if (ids.length === selectedSubChannelIds.length) {
            setSelectedSubChannelIds([...ids, subChannel.id]);
        } else {
            setSelectedSubChannelIds(ids);
        }
    }, [selectedSubChannelIds]);

    const handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
        (event.target as HTMLButtonElement).blur(); // fix remove focus for FireFox

        if (selectedCount < 1) {
            showToastError('Please select a channel.');

            // switch to SubChannels view
        } else if (isSubChannelsExists && !isSubChannelsView) {
            const firstChannel = channels?.find(channel => channel.id === selectedChannelIds[0]);

            navigate(`${RouteCreateClientAccount.channels}/${firstChannel?.id}`);
        } else {
            submit();
        }
    };

    const submit = () => {
        const channel = channels?.find(channel => channel.id === selectedChannelIds[0]);
        const payload = {
            ...channel,
            subchannels: channel ? channel.subchannels.filter(subChannel => selectedSubChannelIds.includes(subChannel.id)) : []
        };

        if (!isEmpty(payload) && !isEqual(payload, clientRole?.channel)) {
            mutate(payload as IChannel);
        } else {
            navigate(RouteCreateClientAccount.verticals);
        }
    };

    useEffect(() => {
        if (!clientRole) {
            return;
        }
        const channelsIds = clientRole.channel ? [clientRole.channel.id] : [];
        const subChannelsIds = clientRole.channel ? clientRole.channel.subchannels.map(item => item.id) : [];

        setSelectedChannelIds(channelsIds);
        setSelectedSubChannelIds(subChannelsIds);
    }, [clientRole]);

    const validateSubChannel = () => {
        if (!channelId) {
            return true;
        }
        const ids = selectedChannel?.subchannels.map(item => item.id);
        const isExists = selectedSubChannelIds.some(item => ids?.includes(item));

        return isExists;
    };

    const isValid = selectedCount >= 1 && validateSubChannel();

    const isSubmitBtn = !isValid || isClientLoading || isChannelsLoading || isSubmitting;

    return (
        <AnimatePageWrp maxWidth={{md: 729, lg: 792}}>
            <ClientTopTitle isSubChannelsView={isSubChannelsView} subChannelsName={selectedChannel?.name}/>

            {
                (isChannelsLoading)
                    ? (
                        <LoadingMask/>
                    ) : (
                        isSubChannelsView && selectedChannel
                            ? <SubChannelsList
                                channel={selectedChannel}
                                selectedSubChannelIds={selectedSubChannelIds}
                                onClick={handleClickSubChannelChange}
                            />
                            : <ChannelsList
                                items={channels || []}
                                selectedIds={selectedChannelIds}
                                onClick={handleClickChannel}
                            />
                    )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        my: 5,
                        mb: '40px',
                        display: 'block'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isSubmitBtn}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal
                order={2}
            >
                <ContinueButton
                    disabled={isSubmitBtn}
                    hasArrow
                    variant="contained"
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};
