import React, {useCallback, useLayoutEffect} from 'react';
import {AxiosError} from 'axios';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {Box, styled} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {ArrowRight} from '../../../assets/icons';
import {BackButton, LoadingMask, showToastError} from '../../../components';
import ClientPortalTitle from '../../../components/ClientPortalTitle';
import {CLIENT_ROLES, GET_MATCHED, MATCHES, MD, RouteClientPortal} from '../../../constants';
import theme from '../../../theme';

import {DynamicProfiles} from './components';

const PageTopWrp = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 22px;

  @media (min-width: ${MD}) {
    gap: 24px;
  }
`;

const TopLinkWrp = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: ${theme.palette.purple.main};
  background-color: ${theme.palette.purple.light};
  border-radius: 100px;
  transition: all .3s;

  a {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: inherit;
  }

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    path {
      transition: fill .3s;
    }
  }

  &:hover {
    color: ${theme.palette.white.main};
    background-color: ${theme.palette.purple.main};

    svg {
      path {
        fill: ${theme.palette.white.main};
      }
    }
  }
`;

const MyMatchesDetailsPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const roleId = parseInt(params.roleId || '', 10);

    const {
        isLoading,
        data
    } = useQuery([CLIENT_ROLES, roleId, MATCHES], () => clientService.getMatches(roleId), {
        enabled: !!roleId,
        staleTime: 60 * 1000,
        select: ((response) => response.data),
        onError: (error) => showToastError(error as AxiosError)
    });

    const matches = data?.matches;

    const {
        data: role
    } = useQuery([GET_MATCHED], clientService.getMatched, {
        staleTime: 60 * 1000,
        select: ((response) => response.data.find(role => role.id === roleId))
    });

    const handleBackMove = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    // Wrong screen position when opening freelancer detailed profile second time
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box
            sx={{
                pt: '38px',
                [theme.breakpoints.up('md')]: {
                    pt: '56px'
                }
            }}
        >
            <BackButton
                sx={{
                    mb: '16px',
                    [theme.breakpoints.up('md')]: {
                        mb: '23px'
                    }
                }}
                onClick={handleBackMove}
            />

            <PageTopWrp>
                <ClientPortalTitle>My matches ({matches?.length})</ClientPortalTitle>
                <TopLinkWrp>
                    <Link
                        to={`${RouteClientPortal.myRoleDetails}/${role?.id}`}
                    >
                        {role?.projectName}
                        <ArrowRight/>
                    </Link>
                </TopLinkWrp>
            </PageTopWrp>

            {isLoading ? <LoadingMask/> : <DynamicProfiles data={matches}/>}

        </Box>
    );
};

export default MyMatchesDetailsPage;
