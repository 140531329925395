import React, {SVGProps} from 'react';


const CheckboxIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14.0325 5.4074C13.9628 5.3371 13.8799 5.2813 13.7885 5.24323C13.6971 5.20515 13.599 5.18555 13.5 5.18555C13.401 5.18555 13.303 5.20515 13.2116 5.24323C13.1202 5.2813 13.0372 5.3371 12.9675 5.4074L7.38002 11.0024L5.03252 8.6474C4.96013 8.57747 4.87468 8.52248 4.78103 8.48558C4.68739 8.44868 4.5874 8.43058 4.48677 8.43232C4.38613 8.43406 4.28682 8.45561 4.19451 8.49573C4.1022 8.53585 4.0187 8.59375 3.94877 8.66615C3.87884 8.73854 3.82386 8.82399 3.78696 8.91763C3.75005 9.01128 3.73196 9.11127 3.7337 9.2119C3.73544 9.31254 3.75698 9.41185 3.7971 9.50416C3.83722 9.59646 3.89513 9.67997 3.96752 9.7499L6.84752 12.6299C6.91725 12.7002 7.0002 12.756 7.09159 12.7941C7.18299 12.8321 7.28101 12.8517 7.38002 12.8517C7.47903 12.8517 7.57706 12.8321 7.66846 12.7941C7.75985 12.756 7.8428 12.7002 7.91252 12.6299L14.0325 6.5099C14.1087 6.43966 14.1694 6.35443 14.211 6.25955C14.2525 6.16468 14.274 6.06222 14.274 5.95865C14.274 5.85507 14.2525 5.75262 14.211 5.65774C14.1694 5.56287 14.1087 5.47763 14.0325 5.4074Z"/>
        </svg>
    );
};

export default CheckboxIcon;
