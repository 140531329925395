import React, {useCallback, useState} from 'react';

import {SxProps} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {PlayIcon} from '../../../assets/icons';
import {CLIENT_ENGAGEMENT, FREELANCER_ENGAGEMENT} from '../../../constants';
import {useAuth} from '../../../hooks';
import {EEngagementStatus, EPauseStatus, EResumeStatus, IEngagement} from '../../../models';
import {BlueFilledButton, DialogProposal, DialogSuccess} from '../..';

interface ResumeEngagementBtnProps {
    data: IEngagement;
    fullWidth?: boolean;
    sx?: SxProps;
}

const ResumeEngagementBtn: React.FC<ResumeEngagementBtnProps> = ({data, ...props}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const handleSuccessOpen = (isNeedUpdate: boolean) => {
        setOpen(false);

        // play modal show/hide animation
        setTimeout(() => {
            setOpenSuccess(isNeedUpdate);
        }, 500);
    };

    const handleSuccessClose = useCallback(() => {
        setOpenSuccess(false);
        queryClient.invalidateQueries({queryKey: [CLIENT_ENGAGEMENT]});     // don't remove. used for Dashboard refresh
        queryClient.invalidateQueries({queryKey: [FREELANCER_ENGAGEMENT]});
    }, [queryClient]);

    const isResumNotExists = !data.resume || [EResumeStatus.FREELANCER_CANCELLED, EResumeStatus.CLIENT_REJECTED].includes(data.resume?.resumeStatus as EResumeStatus);

    if (
        data.status !== EEngagementStatus.PAUSED
        || !(data.pause?.status === EPauseStatus.CLIENT_ACCEPTED && isResumNotExists)
    ) {
        return null;
    }

    return (
        <>
            <BlueFilledButton
                {...props}
                color="secondary"
                disabled={isImpersonal}
                size="small"
                variant="contained"
                onClick={() => setOpen(true)}
            >
                <PlayIcon/>Resume engagement
            </BlueFilledButton>

            <DialogProposal
                data={data}
                open={open}
                onClose={handleSuccessOpen}
            />

            <DialogSuccess
                open={openSuccess}
                title="Your resume engagement request has been successfully submitted"
                subtitle={<>
                    Please wait until client approves or rejects your request.
                    Once it’s approved, you can keep the work going. If it’s rejected, you can negotiate the details in Inbox.
                </>}
                onClose={handleSuccessClose}
            />
        </>
    );
};

export default React.memo(ResumeEngagementBtn);
