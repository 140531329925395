import React, {useCallback} from 'react';

import {styled, Theme, useMediaQuery} from '@mui/material';

import {EditIcon, HelpIcon} from '../../../../assets/icons';
import {AvailableForHireSwitch, AvailableForWorkSwitch, IMenuItem, PortalHeader} from '../../../../components';
import {DETAILS, LG, RouteFreelancerPortal} from '../../../../constants';
import theme from '../../../../theme';

const Wrp = styled('div')`
  display: grid;
  gap: 24px;

  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 0;
    justify-content: space-between;
    gap: 8px;
  }

  .MuiTypography-root {
    color: ${theme.palette.white.main};
  }

  @media (min-width: ${LG}) {
    display: flex;
    gap: 32px;
    padding: 8px 16px;
    border-radius: 50px;
    background-color: ${theme.palette.lightGray.light};

    .MuiFormControlLabel-root {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -16px;
        transform: translateY(-50%);
        height: 100%;
        width: 1px;
        background-color: ${theme.palette.lightGray.main}
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }

    .MuiTypography-root {
      color: ${theme.palette.black.main};
    }
  }
`;

const headerListItems = [
    {
        link: RouteFreelancerPortal.myWorks,
        text: 'My Work'
    },
    {
        link: RouteFreelancerPortal.myMatches,
        text: 'My Matches'
    },
    {
        link: RouteFreelancerPortal.publicFreelancerProfile,
        text: 'My Profile'
    },
    {
        link: RouteFreelancerPortal.myEarnings,
        text: 'My Earnings'
    }
];

const profileListItems = [
    {
        link: `${RouteFreelancerPortal.profileSettings}/${DETAILS}`,
        icon: <EditIcon/>,
        text: 'Edit Profile',
    },
    /*{
        link: `${RouteFreelancerPortal.profileSettings}/${ACCOUNT}`,
        icon: <SettingsIcon/>,
        text: 'Account Settings'
    },*/
    {
        link: RouteFreelancerPortal.help,
        icon: <HelpIcon/>,
        text: 'Help'
    }
];

export const FreelancerPortalHeader = () => {
    const up1400px = useMediaQuery((theme: Theme) => theme.breakpoints.up(1400));
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const handleAction = useCallback((item: IMenuItem) => {
        console.log(item);
    }, []);

    return (
        <PortalHeader
            chatLink={RouteFreelancerPortal.inbox}
            headerListItems={headerListItems}
            profileListItems={profileListItems}
            onAction={handleAction}
        >
            {(up1400px || lgDown) && (<Wrp>
                <AvailableForWorkSwitch/>
                <AvailableForHireSwitch/>
            </Wrp>)}
        </PortalHeader>
    );
};
