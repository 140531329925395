import React, {ChangeEvent, useState} from 'react';
import clsx, {ClassValue} from 'clsx';
import get from 'lodash/get';
import {Controller, useFormContext} from 'react-hook-form';

import {
    FormControl,
    FormHelperText,
    InputAdornment,
    TextField,
    TextFieldProps as MUITextFieldProps
} from '@mui/material';

import {EyeClosedIcon, EyeIcon} from '../../../assets/icons';
import {MAX_SAFE_INTEGER} from '../../../constants';
import theme from '../../../theme';
import {AnimateHeight} from '../..';

import {Counter} from './Counter';

export const styleInput = {
    position: 'relative',
    marginBottom: '19px',
    color: theme.palette.black.main,
    '.MuiInputBase-root': {
        '&::before': {
            borderWidth: '2px',
            borderBottomColor: theme.palette.inputDisabledBorderColor.main
        },
        '&:hover': {
            '&::before': {
                borderColor: theme.palette.primary.main
            },
        },
        '[data-projection-id]': {
            width: 'calc(100% - 30px)'
        },
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.black.main,
            'input::placeholder, input::-webkit-input-placeholder': {
                color: theme.palette.gray.main,
                opacity: '1 !important'
            },
        },
    },
    [theme.breakpoints.down('sm')]: {
        marginBottom: '22px',
        color: theme.palette.black.main,
        'input::placeholder, input::-webkit-input-placeholder': {
            color: theme.palette.inputDisabledBorderColor.main,
            opacity: '1 !important'
        },
    },
} as any;

const transform = {
    input: (value: number) =>
        isNaN(value) || value === 0 ? '' : value.toString(),
    output: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isInteger?: boolean) => {
        const {value} = e.target;
        const output = isInteger ? parseInt(value, 10) : parseFloat(value);

        if (isNaN(output)) {
            return '';
        }

        return output > MAX_SAFE_INTEGER ? MAX_SAFE_INTEGER : output;
    }
};

type TextFieldProps = MUITextFieldProps & {
    classNames?: ClassValue[];
    counter?: number;
    isInteger?: boolean;    // for money we will not allow dot - and we will send cents to the BE
    isResizableY?: boolean;
}

const Input = React.forwardRef(({
    classNames,
    counter,
    fullWidth = true,
    helperText,
    isInteger,
    isResizableY,
    sx,
    name = '',
    variant,
    ...props
}: TextFieldProps, ref): JSX.Element => {
    const [isVisiblePassword, setVisiblePassword] = useState(false);
    const {
        control,
        formState: {errors},
    } = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue=''
            name={name}
            render={({field: {ref: fieldRef, ...field}}) => (
                <FormControl
                    error={!!get(errors, name)}
                    fullWidth={fullWidth}
                    sx={{
                        ...styleInput,
                        ...sx,
                        alignSelf: 'flex-start',
                        '& textarea': {
                            resize: isResizableY ? 'vertical' : 'none'
                        }
                    }}
                >
                    <TextField
                        {...props}
                        {...field}
                        className={clsx(classNames)}
                        error={!!get(errors, name)}
                        inputRef={ref || fieldRef}      // Code Verification auto focus 5 digits, but form validation auto focus too
                        InputProps={{
                            ...props.InputProps,
                            endAdornment: props.InputProps?.endAdornment
                                || props.type === 'password' && (
                                    <InputAdornment
                                        position="end"
                                        sx={{
                                            cursor: 'pointer',
                                            pointerEvents: props.disabled ? 'none' : 'auto',
                                            'svg': {
                                                width: '20px',
                                                height: '20px'
                                            },
                                            'path': {
                                                fill: theme.palette.gray.main
                                            },
                                            'svg:hover path': {
                                                fill: theme.palette.black.main
                                            }
                                        }}
                                        onClick={() => setVisiblePassword(!isVisiblePassword)}
                                    >
                                        {isVisiblePassword ? <EyeIcon/> : <EyeClosedIcon/>}
                                    </InputAdornment>
                                ),
                        }}
                        type={props.type === 'password' && isVisiblePassword ? 'text' : props.type}
                        variant={variant || 'standard'}
                        onChange={(e) => props.type === 'number'
                            ? field.onChange(transform.output(e, isInteger))
                            : field.onChange(e)}
                    />

                    {/* Show/Hide validation errors with animation */}
                    <AnimateHeight isVisible={!!(get(errors, name)?.message || helperText)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {get(errors, name)?.message?.toString() || helperText}
                        </FormHelperText>
                    </AnimateHeight>

                    <Counter counter={counter} isError={!!get(errors, name)?.message}
                        maxLength={props.inputProps?.maxLength}/>

                </FormControl>
            )}
        />
    );
});

export default Input;
