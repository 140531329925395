import React, {FC} from 'react';

import {styled, Typography} from '@mui/material';

import {ArrowCurvyIcon} from '../../../assets/icons';
import {LG} from '../../../constants';
import theme from '../../../theme';

const Wrp = styled('div')`
  position: relative;

  svg {
    display: none;

    @media (min-width: ${LG}) {
      display: block;
      position: absolute;
      top: -40px;
      right: -70px;
      width: 80px;
      height: 80px;
    }
  }
`;

interface IFreelancerFitsBudget {
    firstName?: string;
    isClient?: boolean;
    lastName?: string;
    ratesMatch?: boolean;
}

const FreelancerFitsBudget: FC<IFreelancerFitsBudget> = ({firstName, isClient, lastName, ratesMatch}) => {
    return (
        <Wrp>
            <Typography
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    mb: '4px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '21px',
                    'img': {
                        width: '20px',
                        height: '20px'
                    }
                }}
            >
                <img src={isClient ? '/assets/images/cool-emoji.png' : '/assets/images/eye-emoji.png'} width="20"
                    height="20" alt={isClient ? 'emoji cool' : 'eye emoji'}/>
                {isClient
                    ? `${firstName} ${lastName} fits ${ratesMatch ? 'your budget' : 'you'}!`
                    : 'Your profile as seen by clients'}

            </Typography>


            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '20px',
                    color: theme.palette.textGray.dark
                }}
            >
                {isClient
                    ? `Our team thinks that ${firstName} would be a great fit for this role`
                    : 'This is the public view of your profile. You can edit it anytime you want.'}
            </Typography>
            {isClient && ratesMatch && <ArrowCurvyIcon/>}
        </Wrp>
    );
};

export default React.memo(FreelancerFitsBudget);
