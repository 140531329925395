import React, {useEffect, useState} from 'react';
import {useMotionValue, useSpring} from 'framer-motion';

import {Box, styled} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import theme from '../../theme';
import {ProgressBar} from '../CreateAccountSteps';

const Wrp = styled('div')`
  border-radius: 24px;
  background-color: ${theme.palette.black.main};
  padding: 24px;
`;

const Title = styled('h2')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${theme.palette.white.main};

  img {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`;

const Caption = styled('p')`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: ${theme.palette.gray.main};
`;

interface Props {
    caption: string;
    sx?: SxProps<Theme>;
    value: number;
}

const ProgressBox: React.FC<Props> = ({caption, sx, value}) => {
    const [progress, setProgress] = useState(value);
    const motionValue = useMotionValue(value);
    const spring = useSpring(motionValue, {damping: 30});

    useEffect(() => {
        spring.onChange(latest => setProgress(latest.toFixed(0)));
        motionValue.set(value);

        return () => spring.destroy();
    }, [motionValue, spring, value]);

    return (
        <Wrp sx={{...sx}}>
            <Box
                sx={{
                    display: 'inline',
                }}
            >
                <Title>
                    Your profile is {progress}% complete
                    <img src="/assets/images/confetti-icon.png" width="24" height="24" alt="confetti icon"/>
                </Title>
            </Box>

            <ProgressBar
                progress={value}
                height={12}
                sx={{
                    display: 'block !important',
                    mb: '16px'
                }}
            />

            <Caption>
                {caption}
            </Caption>
        </Wrp>
    );
};


export default ProgressBox; // React.memo(ProgressBox);
