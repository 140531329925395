import React from 'react';
import {AxiosError} from 'axios';
import {useParams} from 'react-router';

import {Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {BlackBorderButton, EngagementInfoBox, showToastError} from '../../../../../../components';
import {CLIENT_ENGAGEMENT, TIMESHEETS} from '../../../../../../constants';
import {EEngagementStatus, EWorkType, EWorkWeekStatus, IEngagement} from '../../../../../../models';
import {formatDate} from '../../../../../../utils';

interface TimesheetReviewProps {
    data: IEngagement;
    onTimesheet: () => void;
}

const TimesheetReview: React.FC<TimesheetReviewProps> = ({data, onTimesheet}) => {
    const {engagementId} = useParams();

    const {
        data: timesheets,
        // refetch,
    } = useQuery([CLIENT_ENGAGEMENT, Number(engagementId), TIMESHEETS], clientService.getTimesheets(Number(engagementId)), {
        enabled: !!engagementId,
        staleTime: 5 * 60 * 1000,
        select: (timesheets) => timesheets?.filter(item => item.status === EWorkWeekStatus.SENT),
        onError: (error) => showToastError(error as AxiosError)
    });

    if (data.status !== EEngagementStatus.ACTIVE || data.budgetType !== EWorkType.hourly || !timesheets?.length) {
        return null;
    }

    return (
        <EngagementInfoBox
            iconUrl="/assets/images/alarm-clock-icon.png"
            title="New timesheet waiting for your review"
        >
            {data.freelancerName.split(' ')[0]} has submitted new timesheets for the following billing
            period: {formatDate(timesheets[0].fromDate)} – {formatDate(timesheets[0].toDate)}.
            You have up to 2 business days to review them before they get automatically approved.

            <Box
                sx={{
                    mt: '16px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <BlackBorderButton
                    size="small"
                    onClick={onTimesheet}
                >
                    Go to the timesheets
                </BlackBorderButton>
            </Box>
        </EngagementInfoBox>
    );
};

export default React.memo(TimesheetReview);
