import React from 'react';

import PageWrapper, {Subtitle, Text, Title} from './components/PageWrapper';

const PrivacyPolicy = () => {
    return (
        <PageWrapper title="Privacy Policy">
            <Text>
                Last Modified: December 4th, 2020
            </Text>

            <Title>
                Introduction
            </Title>

            <Text>
                Growth Collective Solutions, Inc. (“Company” or “We”) respects your privacy and is committed to
                protecting it through our compliance with this policy.
            </Text>
            <Text>
                This policy describes the types of information we may collect from you or that you may provide when you
                visit the website www.growthcollective.com and any managed subdomains or affiliate sites including
                social media outlets (our “Site“) and our practices for collecting, using, maintaining, protecting, and
                disclosing that information.
            </Text>

            <Subtitle>
                This policy applies to information we collect:
            </Subtitle>

            <Text>
                – On the Site
            </Text>
            <Text>
                – In email, text, and other electronic messages between you and the Site
            </Text>
            <Text>
                – Through mobile and desktop applications you download from the Site, which provide dedicated
                non-browser-based interaction between you and the Site
            </Text>
            <Text>
                – When you interact with our advertising and applications on third-party websites and services, if those
                applications or advertising include links to this policy
            </Text>

            <Subtitle>
                It does not apply to information collected by:
            </Subtitle>

            <Text>
                – Us offline or through any other means, including on any other website operated by Company or any third
                party (including our affiliates and subsidiaries); or
            </Text>
            <Text>
                – Any third party (including our affiliates and subsidiaries), including through any application or
                content (including advertising) that may link to or be accessible from or on the Site
            </Text>
            <Text>
                Please read this policy carefully to understand our policies and practices regarding your information
                and how we will treat it. If you do not agree with our policies and practices, your choice is not to use
                our Site. By accessing or using this Site, you agree to this privacy policy. This policy may change from
                time to time (see Changes to Our Privacy Policy). Your continued use of this Site after we make changes
                is deemed to be acceptance of those changes, so please check the policy periodically for updates.
            </Text>

            <Title>
                Authorized users of the site
            </Title>
            <Text>
                This Site (www.growthcollective.com) is offered and available to users subject to the Company’s Terms of
                Service who are 18 years of age or older, and reside in the United States or any of its territories or
                possessions.
            </Text>

            <Title>
                Information We Collect About You and How We Collect It
            </Title>

            <Subtitle>
                We collect several types of information from and about users of our Site, including information:
            </Subtitle>

            <Text>
                – by which you may be personally identified, such as name, postal address, e-mail address, telephone
                number and any other identifier by which you may be contacted online or offline (“personal
                information“);
            </Text>
            <Text>
                – that is about you but individually does not identify you; and/or
            </Text>
            <Text>
                – about your internet connection, the equipment you use to access our Site and usage details.
            </Text>

            <Subtitle>
                We collect this information:
            </Subtitle>

            <Text>
                – Directly from you when you provide it to us.
            </Text>
            <Text>
                – Automatically as you navigate through the site. Information collected automatically may include usage
                details, IP addresses, and information collected through cookies, web beacons, and other tracking
                technologies.
            </Text>
            <Text>
                – From third parties, for example, our business partners.
            </Text>

            <Text>
                We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose.
                Aggregated Data could be derived from your personal data but is not considered personal data in law as
                this data will not directly or indirectly reveal your identity. For example, we may aggregate your usage
                data to calculate the percentage of users accessing a specific website feature. However, if we combine
                or connect Aggregated Data with your personal data so that it can directly or indirectly identify you,
                we treat the combined data as personal data which will be used in accordance with this privacy policy.
            </Text>
            <Text>
                We do not collect any Special Categories of Personal Data about you (this includes details about your
                race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions,
                trade union membership, information about your health, and genetic and biometric data). Nor do we
                collect any information about criminal convictions and offenses.
            </Text>

            <Title>
                Information You Provide to Us
            </Title>

            <Subtitle>
                The information we collect on or through our Site may include:
            </Subtitle>

            <Text>
                – Information that you provide by filling in forms on our Site. This includes information provided at
                the time of registering to use certain areas of our Site and information provided at the time of
                subscribing to our service, posting material, or requesting further services. We may also ask you for
                information when you enter a contest or promotion sponsored by us, and when you report a problem with
                our Site.
            </Text>
            <Text>
                – Records and copies of your correspondence (including email addresses), if you contact us.
            </Text>
            <Text>
                – Your responses to surveys that we might ask you to complete for research purposes.
            </Text>
            <Text>
                – Details of transactions you carry out through our Site.
            </Text>
            <Text>
                – Your search queries on the Site.
            </Text>
            <Text>
                You also may provide information to be published or displayed (hereinafter, “posted“) on public areas of
                the Site, or transmitted to other users of the Site or third parties (collectively, “User
                Contributions“). Your User Contributions are posted on and transmitted to others at your own risk.
                Please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control
                the actions of other users of the Site with whom you may choose to share your User Contributions.
                Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by
                unauthorized persons. Your User Contributions may be accessible by the general public and are being
                disclosed at your own risk.
            </Text>

            <Title>
                Information We Collect Through Automatic Data Collection Technologies
            </Title>

            <Subtitle>
                As you navigate through and interact with our Site, we may use automatic data collection technologies to
                collect certain information about your equipment, browsing actions, and patterns, including:
            </Subtitle>

            <Text>
                – Details of your visits to our Site, including traffic data, location data, logs, and other
                communication data and the resources that you access and use on the Site.
            </Text>
            <Text>
                – Information about your computer and internet connection, including your IP address, operating system,
                and browser type.
            </Text>
            <Text>
                We also may use these technologies to collect information about your online activities over time and
                across third-party websites or other online services (behavioral tracking).
            </Text>
            <Text>
                The information we collect automatically is statistical data and may include personal information, or we
                may maintain it or associate it with personal information we collect in other ways or receive from third
                parties. It helps us to improve our Site and to deliver a better and more personalized service,
                including by enabling us to:
            </Text>

            <Subtitle>
                It helps us to improve our Site and to deliver a better and more personalized service, including by
                enabling us to:
            </Subtitle>

            <Text>
                – Estimate our audience size and usage patterns.
            </Text>
            <Text>
                – Store information about your preferences, allowing us to customize our Site according to your
                individual interests.
            </Text>
            <Text>
                – Speed up your searches.
            </Text>
            <Text>
                – Recognize you when you return to our Site.
            </Text>

            <Subtitle>
                The technologies we use for this automatic data collection may include:
            </Subtitle>

            <Text>
                – Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You
                may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if
                you select this setting you may be unable to access certain parts of our Site. Unless you have adjusted
                your browser setting so that it will refuse cookies, our system will issue cookies when you direct your
                browser to our Site.
            </Text>
            <Text>
                – Flash Cookies. Certain features of our Site may use local stored objects (or Flash cookies) to collect
                and store information about your preferences and navigation to, from, and on our Site. Flash cookies are
                not managed by the same browser settings as are used for browser cookies.
            </Text>
            <Text>
                – Web Beacons. Pages of our Site and our e-mails may contain small electronic files known as web beacons
                (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for
                example, to count users who have visited those pages or opened an email and for other related website
                statistics (for example, recording the popularity of certain website content and verifying system and
                server integrity).
            </Text>
            <Text>
                We do not collect personal information automatically, but we may tie this information to personal
                information about you that we collect from other sources or you provide to us. Please see additional
                information on our Cookie Policy below.
            </Text>

            <Title>
                Cookie Policy
            </Title>

            <Text>
                Our website uses cookies to distinguish you from other users of our website. This helps us to provide
                you with a good experience when you browse our website and also allows us to improve our site. By
                continuing to browse the site, you are agreeing to our use of cookies.
            </Text>
            <Text>
                A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
                computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.
            </Text>

            <Subtitle>
                We may use the following cookies:
            </Subtitle>

            <Text>
                – Strictly necessary cookies. These are cookies that are required for the operation of our website. They
                include, for example, cookies that enable you to log into secure areas of our website, use a shopping
                cart or make use of e-billing services.
            </Text>
            <Text>
                – Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see
                how visitors move around our website when they are using it. This helps us to improve the way our
                website works, for example, by ensuring that users are finding what they are looking for easily.
            </Text>
            <Text>
                – Functionality cookies. These are used to recognize you when you return to our website. This enables us
                to personalize our content for you, greet you by name and remember your preferences (for example, your
                choice of language or region).
            </Text>
            <Text>
                – Targeting cookies. These cookies record your visit to our website, the pages you have visited and the
                links you have followed. We will use this information to make our website and the advertising displayed
                on it more relevant to your interests. We may also share this information with third parties for this
                purpose.
            </Text>
            <Text>
                Please note that third parties (including, for example, advertising networks and providers of external
                services like web traffic analysis services) may also use cookies, over which we have no control. These
                cookies are likely to be analytical/performance cookies or targeting cookies.
            </Text>
            <Text>
                You block cookies by activating the setting on your browser that allows you to refuse the setting of all
                or some cookies. However, if you use your browser settings to block all cookies (including essential
                cookies) you may not be able to access all or parts of our site.
            </Text>

            <Title>
                Third-Party Use of Cookies and Other Tracking Technologies
            </Title>

            <Text>
                Some content or applications, including advertisements, on the Site are served by third-parties,
                including advertisers, ad networks and servers, content providers, and application providers. These
                third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to
                collect information about you when you use our Site. The information they collect may be associated with
                your personal information or they may collect information, including personal information, about your
                online activities over time and across different websites and other online services. They may use this
                information to provide you with interest-based (behavioral) advertising or other targeted content.
            </Text>
            <Text>
                We do not control these third parties’ tracking technologies or how they may be used. If you have any
                questions about an advertisement or other targeted content, you should contact the responsible provider
                directly.
            </Text>

            <Title>
                How We Use Your Information
            </Title>

            <Subtitle>
                We use information that we collect about you or that you provide to us, including any personal
                information:
            </Subtitle>

            <Text>
                – To present our Site and its contents to you.
            </Text>
            <Text>
                – To provide you with information, products, or services that you request from us.
            </Text>
            <Text>
                – To fulfill any other purpose for which you provide it.
            </Text>
            <Text>
                – To provide you with notices about your account if applicable, including expiration and renewal
                notices.
            </Text>
            <Text>
                – To carry out our obligations and enforce our rights arising from any contracts entered into between
                you and us, including for billing and collection.
            </Text>
            <Text>
                – To notify you about changes to our Site or any products or services we offer or provide though it.
            </Text>
            <Text>
                – To allow you to participate in interactive features on our Site.
            </Text>
            <Text>
                – In any other way we may describe when you provide the information.
            </Text>
            <Text>
                – For any other purpose with your consent.
            </Text>
            <Text>
                We may also use your information to contact you about our own and third-parties’ goods and services that
                may be of interest to you. If you do not want us to use your information in this way, please adjust your
                user preferences in your account profile if applicable.
            </Text>
            <Text>
                We may use the information we have collected from you to enable us to display advertisements to our
                advertisers’ target audiences. Even though we do not disclose your personal information for these
                purposes without your consent, if you click on or otherwise interact with an advertisement, the
                advertiser may assume that you meet its target criteria.
            </Text>

            <Title>
                Disclosure of Your Information
            </Title>

            <Text>
                Do not submit your information if you do not want it collected, used and disclosed.
            </Text>
            <Text>
                We may disclose aggregated information about our users, and information that does not identify any
                individual, without restriction.
            </Text>

            <Subtitle>
                We may disclose personal information that we collect or you provide as described in this privacy policy:
            </Subtitle>

            <Text>
                – To our subsidiaries and affiliates.
            </Text>
            <Text>
                – To contractors, service providers, and other third parties we use to support our business and who are
                bound by contractual obligations to keep personal information confidential and use it only for the
                purposes for which we disclose it to them.
            </Text>
            <Text>
                – To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of the Company’s assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held
                by the Company about our Site users is among the assets transferred.
            </Text>
            <Text>
                – To third parties to market their products or services to you if you have not opted out of these
                disclosures. We contractually require these third parties to keep personal information confidential and
                use it only for the purposes for which we disclose it to them. To fulfill the purpose for which you
                provide it.
            </Text>
            <Text>
                – For any other purpose disclosed by us when you provide the information.
            </Text>
            <Text>
                – With your consent.
            </Text>

            <Subtitle>
                We may also disclose your personal information:
            </Subtitle>

            <Text>
                – To comply with any court order, law, or legal process, including to respond to any government or
                regulatory request.
            </Text>
            <Text>
                – To enforce or apply our terms of service, www.growthcollective.com/terms-of-service or terms of sale
                and other agreements, including for billing and collection purposes.
            </Text>
            <Text>
                – If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
                Company, our customers, or others. This includes exchanging information with other companies and
                organizations for the purposes of fraud protection and credit risk reduction.
            </Text>

            <Title>
                Choices About How We Use and Disclose Your Information
            </Title>

            <Text>
                Do not submit your information to us if you do not want it collected, used and disclosed.
            </Text>

            <Subtitle>
                We strive to provide you with choices regarding the personal information you provide to us. We have
                created mechanisms to provide you with the following control over your information:
            </Subtitle>

            <Text>
                – Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies,
                or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings,
                visit the Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note
                that some parts of this site may then be inaccessible or not function properly.
            </Text>
            <Text>
                – Disclosure of Your Information for Third-Party Advertising. If you do not want us to share your
                personal information with unaffiliated or non-agent third parties for promotional purposes, do not
                submit your information.
            </Text>
            <Text>
                – Promotional Offers from the Company. If you do not wish to have your email address and/or contact
                information used by the Company to promote our own or third parties’ products or services, you can
                opt-out by checking the relevant box located on the form on which we collect your data or any other
                opt-out method we provide to you or at any other time by logging into the Site and adjusting your user
                preferences in your account profile by checking or unchecking the relevant boxes or by sending us an
                email stating your request to support@growthcollective.com If we have sent you a promotional email, you
                may send us a return email asking to be omitted from future email distributions. This opt out does not
                apply to information provided to the Company as a result of a product purchase, warranty registration,
                product service experience or other transactions.
            </Text>
            <Text>
                – Targeted Advertising. If you do not want us to use information that we collect or that you provide to
                us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out
                by [OPT-OUT METHOD].
            </Text>
            <Text>
                We do not control third parties’ collection or use of your information to serve interest-based
                advertising. However these third parties may provide you with ways to choose not to have your
                information collected or used in this way. You can opt out of receiving targeted ads from members of the
                Network Advertising Initiative (“NAI“) on the NAI’s website.
            </Text>

            <Title>
                Accessing and Correcting Your Information
            </Title>

            <Text>
                For users that register on our website (if any), we also store the personal information they provide in
                their user profile. All users can see, edit, or delete their personal information at any time (except
                they cannot change their username). Website administrators can also see and edit that information.
            </Text>
            <Text>
                You can review and change your personal information by logging into the Site and visiting your account
                profile page.
            </Text>
            <Text>
                You may also send us an email at support@growthcollective.com to request access to, correct or delete
                any personal information that you have provided to us. We cannot delete your personal information except
                by also deleting your user account. We may not accommodate a request to change information if we believe
                the change would violate any law or legal requirement or cause the information to be incorrect.
            </Text>
            <Text>
                If you delete your User Contributions from the Site, copies of your User Contributions may remain
                viewable in cached and archived pages, or might have been copied or stored by other Site users. Proper
                access and use of information provided on the Site, including User Contributions, is governed by our
                terms of service found at www.growthcollective.com/terms-of-service.
            </Text>

            <Title>
                Data Security
            </Title>

            <Text>
                We have implemented measures designed to secure your personal information from accidental loss and from
                unauthorized access, use, alteration, and disclosure. Any payment transactions will be encrypted.
            </Text>
            <Text>
                The safety and security of your information also depends on you. Where we have given you (or where you
                have chosen) a password for access to certain parts of our Site, you are responsible for keeping this
                password confidential. We ask you not to share your password with anyone. We urge you to be careful
                about giving out information in public areas of the Site like message boards. The information you share
                in public areas may be viewed by any user of the Site.
            </Text>
            <Text>
                Unfortunately, the transmission of information via the internet is not completely secure. Although we do
                our best to protect your personal information, we cannot guarantee the security of your personal
                information transmitted to our Site. Any transmission of personal information is at your own risk. We
                are not responsible for circumvention of any privacy settings or security measures contained on the
                Site.
            </Text>

            <Title>
                International Transfer
            </Title>

            <Text>
                Site visitors from the European Economic Area (EEA) should consider that many of our external third
                parties are based outside the EEA so the processing of your personal data will involve transfer of data
                outside the EEA. If we transfer personal data (including out of the EEA), we ensure a similar degree of
                protection afforded to ensuring the following safeguards is implemented: Where we use providers based in
                the US, we may transfer data to them if they are part of the Privacy Shield which requires them to
                provide similar protection to personal data shared between Europe and the US. For further details, see
                Euro Commission: <a href="//www.privacyshield.gov/welcome" target="_blank"
                    rel="noopener noreferrer nofollow">EU-US Privacy Shield</a>.
            </Text>

            <Title>
                Changes to Our Privacy Policy
            </Title>

            <Text>
                It is our policy to post any changes we make to our privacy policy on this page. If we make material
                changes to how we treat our users’ personal information, we will notify you by email to the email
                address specified in your account or through a notice on the Site home page. The date the privacy policy
                was last revised is identified at the top of the page. You are responsible for ensuring we have an
                up-to-date active and deliverable email address for you, and for periodically visiting our Site and this
                privacy policy to check for any changes.
            </Text>

            <Title>
                Contact Information
            </Title>

            <Text>
                To ask questions or comment about this privacy policy and our privacy practices, contact us at: <a
                    href="mailto:support@growthcollective.com." target="_blank"
                    rel="noopener noreferrer nofollow">support@growthcollective.com</a>.
            </Text>
        </PageWrapper>
    );
};

export default PrivacyPolicy;
