import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import {useMutation} from '@tanstack/react-query';

import {freelancerService} from '../../../../api';
import {
    ChangeEmailBtn,
    ChangePasswordBtn,
    ContinueButton,
    Input,
    InputPhone,
    showToastError,
    showToastSuccess
} from '../../../../components';
import {useAuth} from '../../../../hooks';
import theme from '../../../../theme';
import {applicantSchema, findFieldError, replaceSymbolsInPhone} from '../../../../utils';
import {useFreelancer} from '../../contexts';

export type AccountInput = TypeOf<typeof applicantSchema>;

export const AccountTab = React.memo(() => {
    const {isImpersonal} = useAuth();
    const {state: {freelancer}, refetchFleelancer} = useFreelancer();

    const methods = useForm<AccountInput>({
        resolver: zodResolver(applicantSchema),
    });

    const {
        handleSubmit,
        formState: {isDirty},
        setError,
        setValue,
        reset
    } = methods;

    const {mutate, isLoading: isSubmitting} = useMutation(
        ({phone, ...values}: AccountInput) => {
            const updatedPhone = replaceSymbolsInPhone(phone);

            // FIXME use update endpoint
            return freelancerService.submitFleelancer({...values, phone: updatedPhone});
        },
        {
            // Create new account
            onSuccess() {
                showToastSuccess('Account was successfully updated!');
                refetchFleelancer();
            },
            onError(error: AxiosError) {
                const phone = findFieldError(error, 'phone');

                if (phone) {
                    showToastError('Phone number is invalid');
                    setError('phone', {type: 'custom', message: phone.message});
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const onSubmitHandler: SubmitHandler<AccountInput> = (values) => {
        // 👇 Execute the Mutation
        mutate(values);
    };

    useEffect(() => {
        if (!freelancer) return;

        const {firstName, lastName, email, phone} = freelancer;

        reset({firstName, lastName, email, phone});
    }, [freelancer, reset]);

    useEffect(() => {
        if (freelancer?.email) {
            setValue('email', freelancer.email);
        }
    }, [freelancer?.email, setValue]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(onSubmitHandler)}
                noValidate
                autoComplete="off"
            >
                <Box sx={{maxWidth: 664}}>
                    <Typography
                        sx={{
                            mt: '32px',
                            mb: '20px',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: 1.5,
                            [theme.breakpoints.up('md')]: {
                                mt: 0,
                                mb: '32px',
                                fontSize: '16px'
                            }
                        }}
                    >
                        Account Settings
                    </Typography>
                    <Grid
                        container
                        spacing="24px"
                        sx={{
                            '.MuiFormControl-root': {
                                mb: 0
                            }
                        }}
                    >
                        <Grid item xs={12} sm={6}>
                            <Input
                                disabled={isSubmitting}
                                label="First Name"
                                name="firstName"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                disabled={isSubmitting}
                                label="Last Name"
                                name="lastName"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                gap: '24px'
                            }}
                        >
                            {/*  Email (read-only) */}
                            <Input
                                disabled
                                label="Email"
                                name="email"
                                variant="outlined"
                            />
                            <ChangeEmailBtn
                                disabled={isSubmitting || isImpersonal}
                                verification={freelancer?.emailVerificationProcess}
                                onChange={refetchFleelancer}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                gap: '24px'
                            }}
                        >
                            <Input
                                disabled
                                label="Password"
                                placeholder="**********"
                                type="password"
                                variant="outlined"
                                sx={{
                                    'svg': {
                                        display: 'none !important'
                                    }
                                }}
                            />
                            <ChangePasswordBtn disabled={isSubmitting || isImpersonal}/>
                        </Grid>

                        <Grid item xs={12}>
                            <InputPhone
                                disabled={isSubmitting}
                                label="Phone number"
                                name="phone"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ContinueButton
                                disabled={isSubmitting || !isDirty || isImpersonal}
                                sx={{width: 'auto !important', mt: '16px'}}
                                type="submit"
                                variant="contained"
                            >
                                Save changes
                            </ContinueButton>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </FormProvider>
    );
});
