import React, {EventHandler, ReactNode} from 'react';

import {IconButton as MUIIconButton, SxProps} from '@mui/material';

import theme from '../../theme';

type IconButtonProps = {
    color?: string;
    colorActive?: string;
    disabled?: boolean;
    icon: ReactNode;
    sx?: SxProps;
    onClick: EventHandler<any>;
};

const IconButton: React.FC<IconButtonProps> = ({color, colorActive, disabled, icon, sx, onClick}) => {
    return (
        <MUIIconButton
            disabled={disabled}
            sx={{
                flexShrink: 0,
                'path': {
                    fill: color || theme.palette.black.main,
                    transition: 'fill .3s'
                },
                '&:hover': {
                    'path': {
                        fill: colorActive || theme.palette.primary.main
                    },
                    background: 'transparent',
                },
                ...sx
            }}
            onClick={onClick}
        >
            {icon}
        </MUIIconButton>
    );
};

export default IconButton;
