import React, {Fragment, useCallback, useMemo} from 'react';
import {useController, useFormContext} from 'react-hook-form';

import {FormHelperText, Grid} from '@mui/material';

import {useChannels} from '../../../hooks';
import {IChannel, ISubChannel} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight, CardContent, CardSubTitle, CardTitle, CheckButton, LoadingMask} from '../..';

interface InputSubChannelsProps {
    name?: string;
}

export const InputSubChannels: React.FC<InputSubChannelsProps> = React.memo(({name = 'channels'}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const {channels: items, isChannelsLoading} = useChannels();
    const channels: IChannel[] = value;

    const handleClick = useCallback((ids?: string) => {
        if (!ids) return;

        const [channelId, subChannelId] = ids.split('-').map(it => Number(it));
        const channel = channels.find(it => it.id === channelId);
        const subchannels = items?.find(it => it.id === channelId)?.subchannels;
        const subchannel = subchannels?.find(it => it.id === subChannelId);

        if (!channel || !subchannel) return;

        const isExist = channel.subchannels.find(it => it.id === subChannelId);

        // unselect by second click
        if (isExist) {
            channel.subchannels = channel.subchannels.filter(it => it.id !== subChannelId);
        } else {
            channel.subchannels.push(subchannel);
        }

        onChange([...channels]);
        trigger(name);
        trigger('subchannels');
    }, [channels, items, name, trigger, onChange]);

    const isSelected = (subchannels: ISubChannel[], id: number) => !!subchannels.find(it => it.id === id);

    const content = useMemo(() => (
        <>
            {
                channels?.map(channel => {
                    const subchannels = items?.find(it => it.id === channel.id)?.subchannels;

                    if (!subchannels?.length) return null;

                    return (
                        <Fragment key={channel.id}>
                            <CardTitle>Sub-channels for {channel.name}</CardTitle>
                            <CardSubTitle>You can select as many you would like.</CardSubTitle>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {subchannels?.map((subChannel: ISubChannel) => (
                                        <Grid item key={subChannel.id}>
                                            <CheckButton
                                                id={`${channel.id}-${subChannel.id}`}
                                                label={subChannel.name}
                                                selected={isSelected(channel.subchannels, subChannel.id)}
                                                size="small"
                                                onClick={handleClick}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>

                                <AnimateHeight
                                    isVisible={!channel.subchannels.length && !!(errors.subchannels?.message)}>
                                    <FormHelperText id={`helper-text-subchannels-${channel.id}`}
                                        sx={{color: theme.palette.error.main}}>
                                        {errors.subchannels?.message?.toString()}
                                    </FormHelperText>
                                </AnimateHeight>
                            </CardContent>

                        </Fragment>
                    );
                })
            }

        </>
    ), [channels, errors.subchannels, items, handleClick]);

    return isChannelsLoading ? <LoadingMask/> : content;
});
