import {styled} from '@mui/material';

import theme from '../../../../theme';

export const Wrp = styled('div')<{ disabled?: boolean}>`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  padding: 4px 24px;
  max-height: 217px;

  border: ${props => props.disabled
        ? '2px solid #dddddd'
        : 'none'};
  border-top: 1px solid ${theme.palette.lightGray.main};
  opacity: ${props => props.disabled
        ? 0.5
        : 1};

  &.disabled {
    pointer-events: none;
  }

  form {
    width: 100%;
    padding-bottom: 4px;
  }

  .MuiFormControl-root {
    margin-bottom: 0;

  }

  .MuiInputBase-root {
    &::before {
      border-bottom: unset;
      display: none;
    }

    &::after {
      display: none;
    }

    &:hover, &:focus {
      &::before {
        border-bottom: unset;
        border-color: unset;
      }
    }
  }

  .MuiInputBase-input {
    padding-bottom: 0 !important;
  }
`;

export const iconBtnStyles = {
    'svg path': {
        transition: 'fill .3s'
    },
    '&[disabled]': {
        opacity: 0.3
    },
    '&:hover': {
        backgroundColor: 'transparent',
        'svg path': {
            fill: theme.palette.purple.main
        }
    }
};

export const stylesEmojiPopover = {
    '.MuiPaper-root': {
        width: '350px',
        height: '460px',
    },
    '.MuiPopover-paper': {
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
    }
};

export const stylesInput = {
    width: '100%',
    '.MuiInputBase-root': {
        padding: '8px',
        borderRadius: '4px',
    },
    'textarea, textarea::placeholder': {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
    }
};