import React, {SyntheticEvent} from 'react';
import {AxiosError} from 'axios';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, IconButton, Menu, MenuItem, Theme} from '@mui/material';
import {SxProps} from '@mui/system';
import {useQueryClient} from '@tanstack/react-query';

import {ArchiveIcon, CheckStatusIcon, DuplicateIcon, EditIcon, MoreVerticalIcon} from '../../../../../assets/icons';
import {showToastError} from '../../../../../components';
import {CLIENT_ROLES, RouteClientPortal} from '../../../../../constants';
import {useAuth} from '../../../../../hooks';
import {ERoleStatus, ERoleStatusFilter} from '../../../../../models';
import theme from '../../../../../theme';
import {useRoles} from '../../../contexts/roles.context';

type ActionsMenuProps = {
    isPublishBtn: boolean;
    roleId: number;
    status: ERoleStatus;
    sx: SxProps<Theme>;
};

const menuItemStyles = {
    gap: '16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.black.main,
    backgroundColor: 'transparent !important',
    transition: 'color .3s',
    'svg': {
        width: '18px',
        height: '18px',
        'path': {
            fill: theme.palette.gray.main,
            transition: 'fill .3s'
        }
    },
    '&:hover': {
        color: theme.palette.purple.main,
        'path': {
            fill: 'currentColor'
        }
    }
};

const ActionsMenu: React.FC<ActionsMenuProps> = ({isPublishBtn, status, roleId, sx}) => {
    const {isImpersonal} = useAuth();
    const {cancelRole, cloneRole, publishRole} = useRoles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const params = useParams();
    const queryClient = useQueryClient();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleCancel = async (event: SyntheticEvent) => {
        event.stopPropagation();
        handleClose(event);

        // close details if open
        if (roleId === Number(params.roleId)) {
            navigate(`${RouteClientPortal.myRoles}/${params.filterBy}`);
        }

        try {
            await cancelRole(roleId);
        } catch (error) {
            showToastError(error as AxiosError);
        }
    };

    const handleClone = async (event: SyntheticEvent) => {
        event.stopPropagation();
        handleClose(event);

        try {
            const newRoleId = await cloneRole(roleId);

            queryClient.invalidateQueries({queryKey: [CLIENT_ROLES]});

            // fix flick animation
            setTimeout(() => {
                navigate(`${RouteClientPortal.myRoles}/${ERoleStatusFilter.Drafts}/${newRoleId}`);
            }, 1000);
        } catch (error) {
            showToastError(error as AxiosError);
        }
    };

    const handleClose = (event: SyntheticEvent) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleEdit = (event: SyntheticEvent) => {
        event.stopPropagation();
        handleClose(event);
        navigate(`${RouteClientPortal.myRoleDetails}/${roleId}/edit`);
    };

    const handlePublish = (event: SyntheticEvent) => {
        event.stopPropagation();
        handleClose(event);
        publishRole(roleId);
    };

    return (
        <Box sx={sx}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'actions-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVerticalIcon/>
            </IconButton>
            {status === ERoleStatus.Draft ?
                <Menu
                    anchorEl={anchorEl}
                    id="actions-menu"
                    MenuListProps={{
                        'aria-labelledby': 'action-button',
                    }}
                    open={open}
                    sx={{
                        '.MuiList-root': {
                            minWidth: '160px'
                        }
                    }}
                    onClose={handleClose}
                >
                    {/* if it is Draft and all 6 steps are filled */}
                    {isPublishBtn && (
                        <MenuItem
                            disabled={isImpersonal}
                            sx={menuItemStyles}
                            onClick={handlePublish}
                        >
                            <CheckStatusIcon/>
                            Publish
                        </MenuItem>
                    )}
                    <MenuItem
                        disabled={isImpersonal}
                        sx={menuItemStyles}
                        onClick={handleClone}
                    >
                        <DuplicateIcon/>
                        Duplicate
                    </MenuItem>
                    <MenuItem
                        sx={menuItemStyles}
                        onClick={handleEdit}
                    >
                        <EditIcon/>
                        Edit
                    </MenuItem>
                    <MenuItem
                        disabled={isImpersonal}
                        sx={menuItemStyles}
                        onClick={handleCancel}
                    >
                        <ArchiveIcon/>
                        Archive
                    </MenuItem>
                </Menu>
                :
                <Menu
                    anchorEl={anchorEl}
                    id="actions-menu"
                    MenuListProps={{
                        'aria-labelledby': 'action-button',
                    }}
                    open={open}
                    sx={{
                        '.MuiList-root': {
                            minWidth: '160px'
                        }
                    }}
                    onClose={handleClose}
                >
                    <MenuItem
                        disabled={isImpersonal}
                        sx={menuItemStyles}
                        onClick={handleClone}
                    >
                        <DuplicateIcon/>
                        Duplicate
                    </MenuItem>
                    <MenuItem
                        disabled={[
                            ERoleStatus.Cancelled,
                            ERoleStatus.EngagementActive,
                            ERoleStatus.EngagementPaused,
                            ERoleStatus.EngagementPending,
                            ERoleStatus.Closed
                        ].includes(status) || isImpersonal}   // Role in ‘engagements started’ status is completed, so they can only be cloned.
                        sx={menuItemStyles}
                        onClick={handleCancel}
                    >
                        <ArchiveIcon/>
                        Archive
                    </MenuItem>
                </Menu>
            }
        </Box>
    );
};

export default React.memo(ActionsMenu);
