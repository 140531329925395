import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {AnimatePageWrp, ContinueButton, LoadingMask, Portal, ProjectForm, ProjectTopTitle, showToastError, showToastSuccess} from '../../../components';
import {RouteCreateClientAccount, RouteWidget} from '../../../constants';
import {useClientRole, useNavigateUTM} from '../../../hooks';
import {IProjectRequest} from '../../../models';
import theme from '../../../theme';
import {CLIENT_PROJECT_MAX_LENGTH, clientProjectSchema, isWidget} from '../../../utils';


type ProjectInput = TypeOf<typeof clientProjectSchema>;

export const Project = () => {
    const {clientRole, isClientLoading, refetchClient} = useClientRole();

    const navigate = useNavigateUTM();

    const methods = useForm<ProjectInput>({
        resolver: zodResolver(clientProjectSchema),
    });

    const {
        handleSubmit,
        setValue,
        watch
    } = methods;

    const projectName = watch('projectName', '');
    const projectDescription = watch('projectDescription', '');

    const {mutate, isLoading: isSubmitting} = useMutation(
        (payload: IProjectRequest) => clientService.projectSubmit(clientRole?.id || 0, payload),
        {
            onSuccess() {
                showToastSuccess('Project was saved successfully!');

                // if it is widget - we did not have signup at the start
                // so now we should signup an anonymous user
                if (isWidget()) {
                    navigate(RouteWidget.signup);
                } else {
                    navigate(RouteCreateClientAccount.confirmationCode);
                }
                refetchClient();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const onSubmitHandler: SubmitHandler<ProjectInput> = (values: IProjectRequest) => {
        const isDirty = values.projectName !== clientRole?.projectName || values.projectDescription !== clientRole.projectDescription;

        if (isDirty) {
            mutate(values);
        } else if (isWidget()) {
            navigate(RouteWidget.signup);
        } else {
            navigate(RouteCreateClientAccount.confirmationCode);
        }
    };

    useEffect(() => {
        if (!clientRole) {
            return;
        }
        const {projectName, projectDescription} = clientRole;

        if (projectName) {
            setValue('projectName', projectName);
            setValue('projectDescription', projectDescription);
        }
    }, [clientRole, setValue]);

    useEffect(() => {
        if (!projectDescription) return;

        if (projectDescription.length > CLIENT_PROJECT_MAX_LENGTH) {
            setValue('projectDescription', projectDescription.substring(0, CLIENT_PROJECT_MAX_LENGTH));
        }
    }, [projectDescription, setValue]);

    return (
        <AnimatePageWrp maxWidth={{md: 500, lg: 746}}>
            <ProjectTopTitle/>

            {
                isClientLoading
                    ? (
                        <LoadingMask/>
                    ) : (
                        <FormProvider {...methods}>
                            <ProjectForm
                                description={projectDescription}
                                isSubmitting={isSubmitting}
                                max={CLIENT_PROJECT_MAX_LENGTH}
                                name={projectName}
                            />
                        </FormProvider>
                    )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '72px'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '63px'
                    },
                    '@media(max-height: 900px)': {
                        pb: '60px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isClientLoading || isSubmitting}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isClientLoading || isSubmitting}
                    hasArrow
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};
