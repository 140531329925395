import {DateRange} from 'mui-daterange-picker';

import {IReportSearchParams} from '../../models';
import {localToUTC} from '../../utils';

export const prepareQueryParams = (roleIds: string[], dateRange?: DateRange, status?: string) => {
    const params: IReportSearchParams = {};

    if (dateRange?.startDate) {
        params.fromDate = localToUTC(dateRange?.startDate).toISOString().split('T')[0];
    }

    if (dateRange?.endDate) {
        params.toDate = localToUTC(dateRange?.endDate).toISOString().split('T')[0];
    }

    if (roleIds.length) {
        params.roleIds = roleIds;
    }

    if (status) {
        params.status = status;
    }

    return params;
};
