import {useQuery} from '@tanstack/react-query';

import {metaService} from '../api';
import {GET_CHANNELS} from '../constants';

export const useChannels = () => {
    const query = useQuery([GET_CHANNELS], metaService.getChannels, {
        staleTime: 15 * 60 * 1000,
        select: (data) => data,
        onSuccess: (data) => {
            // stateContext.dispatch({ type: ActionTypesAuth.SET_DATA, payload: data });
        }
    });

    return {
        ...query,
        channels: query.data,
        isChannelsLoading: query.isLoading || query.isFetching,
        refetchChannels: query.refetch
    };
};
