import React, {useEffect, useMemo, useRef} from 'react';
import format from 'date-fns/format';
import orderBy from 'lodash/orderBy';
import {useParams} from 'react-router';

import {Avatar, Box, styled, Typography} from '@mui/material';
import {Conversation} from '@twilio/conversations';

import {LG, MD, XL} from '../../../../constants';
import {EConversationStatus, EMeetingStatus, IConversationAttributes, IConversationMeta} from '../../../../models';
import theme from '../../../../theme';
import {getNameLetters, showTimeOrDate, stringToColor, zonedTimeToUtc} from '../../../../utils';
import Pill from '../../../Pill';
import {ARCHIVED_STATUSES} from '../../InboxPage';


const Wrp = styled('div')`
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 16px;
  padding-bottom: 24px;
  display: grid;
  gap: 8px;
`;

const Item = styled('div')<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 16px 9px;
  border-radius: 16px;
  background-color: ${props => props.selected ? theme.palette.blue.light : 'transparent'};
  transition: background-color .3s;
  cursor: pointer;
  min-width: 0;

  &:hover {
    background-color: ${props => props.selected ? theme.palette.blue.light : theme.palette.lightGray.light};
  }
`;

const TopRow = styled('div')<{ status?: EConversationStatus }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 3px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.black.main};
  }

  img {
    width: 20px;
    height: 20px;
  }

  span {
    flex-shrink: 0;
    margin-left: auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${theme.palette.gray.main};
  }
`;

const MessageRow = styled('div')<{ isEmptyMessage?: boolean }>`
  margin-bottom: 3px;
  overflow: hidden;
  max-width: calc(100vw - 156px);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${props => props.isEmptyMessage ? theme.palette.gray.main : theme.palette.textGray.dark};

  @media (min-width: ${LG}) {
    max-width: 195px;
  }

  @media (min-width: ${XL}) {
    max-width: 431px;
  }
`;

const RedBadge = styled('div')`
  height: 8px;
  width: 8px;
  background-color: ${theme.palette.error.main};
  border-radius: 50%;
  display: inline-block;
`;

const RoleRow = styled('div')<{ inSelected?: boolean }>`
  display: inline-block;
  max-width: calc(100vw - 156px);
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${props => props.inSelected ? theme.palette.blue.dark : theme.palette.lightGray.light};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.inSelected ? theme.palette.white.main : theme.palette.textGray.dark};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all .3s;

    // @media (min-width: ${MD}) {
  //   max-width: 230px;
  // } 

  @media (min-width: ${LG}) {
    max-width: 160px;
  }

  @media (min-width: ${XL}) {
    max-width: 311px;
  }
`;

interface IListItem {
    avatar: string;
    dateUpdated: string;
    isActive: boolean;
    name: string;
    message: string;
    online: boolean;
    role: string;
    scheduledMeeting?: string;
    sid: string;
    status: EConversationStatus;
    time: string;
    unreadMessages: number;
}

interface IProps {
    conversations: Conversation[];
    onClick: (uniqueName: string) => void;
}

const ChatList: React.FC<IProps> = ({conversations, onClick}) => {
    const {conversationId} = useParams();
    const activeItemRef = useRef<HTMLDivElement | null>(null);

    const itemsList = useMemo(() => {
        const result: IListItem[] = conversations.map((conversation) => {
            const attributes = conversation?.attributes as unknown as IConversationAttributes;
            const meta = (conversation as any)?.meta as IConversationMeta || {};
            const scheduledMeeting = attributes.meetings?.find(it => it.status !== EMeetingStatus.PAST);
            const scheduledMeetingTime = scheduledMeeting ? format(zonedTimeToUtc(scheduledMeeting.from, scheduledMeeting.timezone), 'Pp') : undefined;


            return {
                avatar: meta.remoteParticipant?.avatar || '',
                dateUpdated: (meta?.lastMessage?.dateUpdated as unknown as string) || '',
                isActive: conversation.sid === conversationId,
                message: meta?.lastMessage?.body || '',
                name: meta.remoteParticipant?.name || '',
                online: false,
                role: conversation.friendlyName || '',
                scheduledMeeting: scheduledMeetingTime ? `Scheduled meeting ${scheduledMeetingTime}` : '',
                sid: conversation.sid,
                status: attributes.status,
                time: meta?.lastMessage?.dateUpdated ? showTimeOrDate(meta?.lastMessage?.dateUpdated) : '',
                unreadMessages: meta?.unreadMessagesCount,
            };
        });

        return orderBy(result, [it => new Date(it.dateUpdated)], ['desc']);
    }, [conversationId, conversations]);

    useEffect(() => {
        if (activeItemRef.current?.scrollIntoView) {
            setTimeout(() => {
                activeItemRef.current?.scrollIntoView
                    ? activeItemRef.current.scrollIntoView({behavior: 'smooth'})
                    : activeItemRef.current?.focus();
            }, 200);
        }
    }, []);

    return (
        <Wrp>

            {
                itemsList.map((item, index) => (
                    <Item
                        key={index}
                        selected={item.isActive}
                        onClick={() => onClick(item.sid)}
                    > {/*changes styles of an item*/}
                        <Box
                            ref={item.isActive ? activeItemRef : null}
                            sx={{
                                position: 'relative',
                                'span': {
                                    position: 'absolute',
                                    bottom: '-2px',
                                    right: '6px',
                                    backgroundColor: theme.palette.green.dark,
                                    borderRadius: '50%',
                                    border: '2px solid',
                                    borderColor: theme.palette.white.main,
                                    width: '14px',
                                    height: '14px',
                                    transform: 'scale(0)',
                                    transition: 'transform .3s',
                                    '&.online': {
                                        transform: 'scale(1)'
                                    }
                                }
                            }}
                            tabIndex={0}
                        >
                            <Avatar
                                alt={item.name}
                                src={item.avatar}
                                sx={{
                                    width: '64px',
                                    height: '64px',
                                    backgroundColor: stringToColor(item.name),
                                    fontSize: '24px',
                                    lineHeight: 1.2,
                                    fontWeight: 500,
                                    'img': {
                                        display: 'block',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        aspectRatio: '1/1'
                                    }
                                }}
                            >
                                {getNameLetters(item.name)}
                            </Avatar>

                            {/*todo: className "online" should be added when a user is online, the very span should remain*/}
                            <span
                                className={item.online ? 'online' : ''}
                            />
                        </Box>
                        <Box sx={{
                            width: '100%',
                            [theme.breakpoints.up('lg')]: {
                                maxWidth: '310px'
                            }
                        }}>
                            <TopRow>
                                <p>{item.name}</p>

                                {
                                    item.status === EConversationStatus.Proposed && (
                                        <img
                                            src="/assets/images/proposal-icon.png"
                                            width="20"
                                            height="20"
                                            alt="proposal sent icon"
                                            title="Proposal sent"
                                        />
                                    )
                                }

                                {
                                    ARCHIVED_STATUSES.includes(item.status) && (
                                        <Pill size="small" variant="gray">Archived</Pill>
                                    )
                                }

                                <span>{item.time}</span>
                            </TopRow>
                            <Box
                                sx={{
                                    position: 'relative',
                                    paddingRight: item.unreadMessages > 0 ? '36px' : 0
                                }}
                            >
                                <MessageRow isEmptyMessage={item.message.length < 1}>
                                    {
                                        item.message.length < 1
                                            ? 'New conversation...'
                                            : item.scheduledMeeting
                                                ? (<><RedBadge/> {item.scheduledMeeting}</>)
                                                : item.message
                                    }
                                </MessageRow>
                                <RoleRow inSelected={item.isActive}> {/*changes styles of an item*/}
                                    {item.role}
                                </RoleRow>
                                {
                                    item.unreadMessages > 0 && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: 'block',
                                                padding: '2px',
                                                borderRadius: '50px',
                                                backgroundColor: theme.palette.error.main,
                                                fontWeight: 600,
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: theme.palette.white.main,
                                                textAlign: 'center',
                                                position: 'absolute',
                                                top: '50%',
                                                right: 0,
                                                transform: 'translateY(-50%)',
                                                minWidth: '21px',
                                                height: '21px'
                                            }}
                                        >
                                            {item.unreadMessages}
                                        </Typography>
                                    )
                                }
                            </Box>
                        </Box>
                    </Item>
                ))
            }
        </Wrp>
    );
};

export default React.memo(ChatList);
