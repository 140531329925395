import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import {AxiosError} from 'axios';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {Box, styled} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {freelancerService} from '../../../api';
import {BackButton, LoadingMask, showToastError} from '../../../components';
import {FREELANCER_ENGAGEMENT, LG, RouteFreelancerPortal} from '../../../constants';
import {useTwilio} from '../../../hooks';
import theme from '../../../theme';

import {TabMessages, TabOverview, Tabs, TabTimesheets} from './components';

const Wrp = styled('div')`
  margin-top: 40px;

  @media (min-width: ${LG}) {
    margin-top: 64px;
  }
`;

export enum EDisplayMode {
    Messages = 'messages',
    Overview = 'overview',
    Reports = 'reports',
    Timesheets = 'timesheets'
}

const MyWorkDetailsPage = () => {
    const {engagementId, tab} = useParams();
    const location = useLocation();
    const redirectBackRef = useRef(location.state?.redirectBack);
    const {loading: isLoadingTwilio, finishConversation, selectConversationById} = useTwilio();
    const navigate = useNavigate();

    const {
        isLoading,
        data
    } = useQuery([FREELANCER_ENGAGEMENT, Number(engagementId)], freelancerService.getWork(Number(engagementId)), {
        enabled: !!engagementId,
        staleTime: 5 * 60 * 1000,
        onError: (error) => showToastError(error as AxiosError)
    });

    const handleBackMove = useCallback(() => {
        navigate(redirectBackRef.current || RouteFreelancerPortal.myWorks);
    }, [navigate]);

    // redirect to Overview tab by default
    useEffect(() => {
        if (!tab) {
            navigate(`${RouteFreelancerPortal.myWorkDetails}/${engagementId}/${EDisplayMode.Overview}`, {replace: true});
        }
    }, [engagementId, tab, navigate]);

    // get unread messages
    useEffect(() => {
        if (data?.conversationSid) {
            selectConversationById(data.conversationSid);
        }

        return () => finishConversation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // Wrong screen position when opening freelancer detailed profile second time
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Wrp>
            <BackButton
                onClick={handleBackMove}
            />
            <Box
                sx={{
                    maxWidth: '1406px',
                    m: '0 auto'
                }}
            >

                {isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            height: 'calc(100vh - 320px)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            'div': {
                                width: '100%'
                            }
                        }}
                    >
                        <LoadingMask/>
                    </Box>
                ) : (
                    <>
                        <Box
                            sx={{
                                mt: '32px',
                                mb: '32px',
                                [theme.breakpoints.up('md')]: {
                                    mb: '24px'
                                }
                            }}
                        >
                            {data && <Tabs data={data}/>}
                        </Box>

                        <Box>

                            {tab === EDisplayMode.Overview && data && (
                                <TabOverview data={data}/>
                            )}
                            {tab === EDisplayMode.Messages && data && (
                                <TabMessages data={data} isLoading={isLoadingTwilio}/>
                            )}
                            {tab === EDisplayMode.Timesheets && data && (
                                <TabTimesheets data={data}/>
                            )}

                        </Box>
                    </>
                )}

            </Box>
        </Wrp>
    );
};

export default React.memo(MyWorkDetailsPage);
