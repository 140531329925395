import {NOTIFICATIONS, NOTIFICATIONS_ALL_READ, NOTIFICATIONS_BADGE} from '../constants';
import {INotification} from '../models';

import axiosInstance from './base.api';
import {convertHTTPResponse, formatParams, GetListParams, GetListResult} from './list.api';

const getBadge = async () => {
    return await axiosInstance.get<{badge: number}>(NOTIFICATIONS_BADGE);
};

const getNotifications = async (params: GetListParams) => {
    return await axiosInstance.get<GetListResult<INotification>>(NOTIFICATIONS + formatParams(params))
        .then(convertHTTPResponse);
};

const setAllRead = async () => {
    return await axiosInstance.post<null>(NOTIFICATIONS_ALL_READ);
};

const getTwilioToken = async () => {
    const response = await axiosInstance.get<string>('/twilio/access-token');

    return response.data;
};

export const notificationService = {
    getBadge,
    getNotifications,
    getTwilioToken,
    setAllRead
};
