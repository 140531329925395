import React from 'react';
import {AnimatePresence, LayoutGroup} from 'framer-motion';
import {useNavigate} from 'react-router-dom';

import {Box, styled} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';

import {AddNewItemButton} from '../../../../components';
import {animationAlertBox} from '../../../../components/Animations';
import {MD} from '../../../../constants';
import {useAuth} from '../../../../hooks';
import theme from '../../../../theme';
import {useFreelancer} from '../../contexts';

import CertificateItem from './Certificate/CertificateItem';

const ListWrapper = styled('div')`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  min-width: 0;
  transition: width .3s;
  padding: 0;

  @media (min-width: ${MD}) {
    gap: 24px;
  }
`;

export const CertificatesTab = React.memo(() => {
    const {isImpersonal} = useAuth();
    const {state: {data}, refetchProfile} = useFreelancer();

    const navigate = useNavigate();

    return (
        <Box
            sx={{
                [theme.breakpoints.up('lg')]: {
                    maxWidth: 700
                }
            }}
        >
            <Typography
                sx={{
                    mt: '32px',
                    mb: '20px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: 1.5,
                    [theme.breakpoints.up('md')]: {
                        mt: 0,
                        mb: '32px',
                        fontSize: '16px'
                    }
                }}
            >
                My Certificates
            </Typography>

            <ListWrapper>
                <LayoutGroup>
                    <AddNewItemButton
                        value="add_btn"
                        onClick={() => isImpersonal ? null : navigate('new')}
                    >
                        Add a new certificate
                    </AddNewItemButton>

                    <AnimatePresence>
                        {data?.certificates?.map((certificate, i) => (
                            <CertificateItem
                                {...animationAlertBox(i)}
                                data={certificate}
                                isDeletable={
                                    data?.certificates?.length
                                        ? (data.certificates.length > 1) && !isImpersonal
                                        : false
                                }
                                key={certificate.id}
                                layout
                                onDelete={refetchProfile}
                            />
                        ))}
                    </AnimatePresence>
                </LayoutGroup>
            </ListWrapper>
        </Box>
    );
});
