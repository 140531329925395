import React, {FC, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Theme, useMediaQuery} from '@mui/material';
import {Stack} from '@mui/system';
import {useMutation} from '@tanstack/react-query';

import {emailService} from '../../api';
import {findFieldError, passwordScheme} from '../../utils';
import {AnimateHeight, BlackBorderButton, ContinueButton, DialogEmoji, Input} from '..';

export type PasswordInput = TypeOf<typeof passwordScheme>;

interface IProps {
    disabled: boolean;
}

const ChangePasswordBtn: FC<IProps> = ({disabled}) => {
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const methods = useForm<PasswordInput>({
        resolver: zodResolver(passwordScheme),
    });

    const {mutate, isLoading: isSubmitting} = useMutation(
        (newPassword: string) => emailService.updatePassword(newPassword),
        {
            onSuccess() {
                setOpen(false);

                // play modal show/hide animation
                setTimeout(() => {
                    setOpenSuccess(true);
                }, 500);
            },
            onError(error: AxiosError) {
                const password = findFieldError(error, 'password');

                if (password) {
                    setError('password' as any, {type: 'custom', message: password.message});
                }
            },
        }
    );

    const {
        handleSubmit,
        setError,
        reset,
        watch,
    } = methods;

    const password = watch('password', '');

    const onSubmitHandler: SubmitHandler<PasswordInput> = (values) => {
        // 👇 Execute the Mutation
        mutate(values.password);
    };

    // clean form by close
    useEffect(() => {
        reset({});
    }, [open, reset]);

    return (
        <>
            <BlackBorderButton
                disabled={disabled || isSubmitting}
                size="small"
                onClick={() => setOpen(true)}
            >
                Change
            </BlackBorderButton>

            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            disabled={isSubmitting}
                            fullWidth={mdDown}
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </BlackBorderButton>

                        <ContinueButton
                            fullWidth={mdDown}
                            disabled={isSubmitting}
                            sx={{width: mdDown ? '100%' : 'auto !important'}}
                            variant="contained"
                            onClick={handleSubmit(onSubmitHandler)}
                        >
                            Change password
                        </ContinueButton>
                    </>
                )}
                maxWidth="sm"
                open={open}
                subtitle="Enter a new password below"
                title={(
                    <>
                        <img src="/assets/images/lock-icon.png" width="48" height="48" alt="lock icon"/>
                        Change password
                    </>
                )}
                onClose={() => setOpen(false)}
            >
                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                    >
                        <Stack direction="column" spacing={2} sx={{mb: 5}}>
                            <Box>
                                <Input
                                    name="password"
                                    placeholder="New Password"
                                    type="password"
                                />

                                <AnimateHeight isVisible={!!password?.length} isOverflowHidden={false}>
                                    <PasswordStrengthBar password={password} minLength={8}/>
                                </AnimateHeight>
                            </Box>


                            <Input
                                name="passwordConfirm"
                                placeholder="Repeat new password"
                                type="password"
                            />
                        </Stack>


                    </form>
                </FormProvider>

            </DialogEmoji>

            {/* Password changed successfully */}
            <DialogEmoji
                actions={(
                    <>
                        <ContinueButton
                            fullWidth={mdDown}
                            sx={{width: mdDown ? '161px !important' : 'auto !important'}}
                            variant="contained"
                            onClick={() => setOpenSuccess(false)}
                        >
                            Done
                        </ContinueButton>
                    </>
                )}
                maxWidth="sm"
                open={openSuccess}
                title={(
                    <>
                        <img src="/assets/images/lock-icon.png" width="48" height="48" alt="sad icon"/>
                        Password changed successfully
                    </>
                )}
                onClose={() => setOpenSuccess(false)}
            >
                <></>
            </DialogEmoji>
        </>
    );
};

export default ChangePasswordBtn;
