import React from 'react';

import {Button as MUIButton, ButtonProps} from '@mui/material';

import theme from '../../../theme';

interface IProps {
    inactive?: boolean;
}

const WhiteSquareButton = ({children, inactive, ...props}: ButtonProps & IProps): JSX.Element => {
    return (
        <MUIButton
            {...props}
            disableElevation
            disableFocusRipple
            sx={{
                p: '10px 16px !important',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '8px',
                fontWeight: 500,
                fontSize: '14px',
                backgroundColor: inactive ? 'transparent' : theme.palette.white.main,
                color: inactive ? theme.palette.gray.main : theme.palette.blue.dark,
                borderRadius: '8px',
                '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: theme.palette.gray.main
                },
                'svg': {
                    width: '24px',
                    height: '24px'
                },
                'path': {
                    fill: 'currentColor'
                },
                '&:hover': {
                    backgroundColor: theme.palette.white.light,
                    color: theme.palette.blue.dark,
                },
                ...props.sx
            }}
        >
            {children}
        </MUIButton>
    );
};

export default React.memo(WhiteSquareButton);
