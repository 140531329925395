import React, {FC, memo} from 'react';

import {Box, CircularProgress} from '@mui/material';

interface Props {
    color?: 'inherit' | 'primary' | 'secondary';
    extendClasses?: any;
    hasNoSpace?: boolean;
    opaque?: boolean;
    size?: number;
    thickness?: number;
}

export const LoadingMask: FC<Props> = memo(({opaque, extendClasses = {}, hasNoSpace, ...rest}): JSX.Element => {
    return (
        hasNoSpace &&
        <div>
            <CircularProgress
                {...rest}
            />
        </div> ||
        <Box justifyContent="center" sx={{display: 'flex', p: 5}}>
            <CircularProgress
                {...rest}
            />
        </Box>
    );
});

export default LoadingMask;
