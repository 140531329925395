import React, {FC} from 'react';
import {Reorder} from 'framer-motion';

import {Avatar, Box, IconButton, Stack, styled, Typography} from '@mui/material';

import {ExclamationMarkIcon, ExclamationWarnMarkIcon, PinIcon} from '../../assets/icons';
import {MD, XL} from '../../constants';
import {useAuth} from '../../hooks';
import {EEngagementStatus, IEngagement} from '../../models';
import theme from '../../theme';
import {formatCurrency, formatDate, getNameLetters, stringToColor} from '../../utils';
import {BlackBorderButton, ContinueButton, ImagePlaceholder, Pill, RoleStatusPill} from '../index';

const AnimatedItem = styled(Reorder.Item)`
  padding: 24px;
  border-radius: 24px;
  background-color: ${theme.palette.white.main};
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    width: calc(50% - 8px);
  }

  @media (min-width: ${MD}) {
    width: calc(50% - 12px);
  }

  @media (min-width: 1100px) {
    width: calc(33% - 13px);
  }

  @media (min-width: ${XL}) {
    width: calc(24% - 3px);
    max-width: 390px;
  }
`;

const avatarStyles = {
    width: '80px',
    height: '80px',
    margin: '0 auto',
    fontSize: '30px',
    lineHeight: 1.2,
    fontWeight: 500,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    }
};

interface IEngagementListItemProps {
    data: IEngagement;
    isClientPortal: boolean;
    isSubmitting: boolean;
    value: number;          // used for animation
    onDetails: (item: IEngagement) => void;
    onMessage: (item: IEngagement) => void;
    onTogglePin: (item: IEngagement) => void;
}

const EngagementListItem: FC<IEngagementListItemProps> = ({
    data,
    isClientPortal,
    isSubmitting,
    value,
    onDetails,
    onMessage,
    onTogglePin
}) => {
    const {isImpersonal} = useAuth();

    return (
        <AnimatedItem
            dragListener={false}
            value={value}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    mb: !isClientPortal && data?.clientCompanyName ? '4px' : '24px',
                    gap: '24px'
                }}
            >
                <Box>
                    <RoleStatusPill
                        status={data.status}
                    />

                    {data.hasOverdueTimesheets && (
                        <Box
                            component="span"
                            sx={{
                                ml: 2,
                                'svg': {
                                    width: '20px',
                                    height: '20px',
                                    fill: theme.palette.blue.main,
                                }
                            }}
                            title="Overdue time card(s) exists"
                        >
                            <ExclamationWarnMarkIcon/>
                        </Box>
                    )}

                    {data.hasRejectedTimesheets && (
                        <Box
                            component="span"
                            sx={{
                                ml: 2,
                                'svg': {
                                    width: '20px',
                                    height: '20px',
                                }
                            }}
                            title="Rejected time card(s) exists"
                        >
                            <ExclamationMarkIcon/>
                        </Box>
                    )}

                </Box>
                {data.status !== EEngagementStatus.CLOSED && (
                    <IconButton
                        disabled={isSubmitting || isImpersonal}
                        sx={{opacity: isSubmitting ? 0.5 : 1}}
                        onClick={() => onTogglePin(data)}
                    >
                        <PinIcon filled={data.pinned}/>
                    </IconButton>
                )}

                <Box
                    sx={{
                        width: '100%',
                        flexShrink: 0,
                        '& > .MuiAvatar-root': {
                            objectFit: 'contain',
                            aspectRatio: '1/1',
                            objectPosition: 'center',
                            borderRadius: '50%',
                            border: `1px solid ${theme.palette.lightGray.main}`
                        }
                    }}
                >
                    {/* At the ClientPortal we can show photo or letters Avatar */}
                    {/* At the FreelancerPortal we can show Role icon or thumbnail */}
                    {
                        isClientPortal
                            ? (
                                <Avatar
                                    alt={data.freelancerName}
                                    className="avatar"
                                    src={data.freelancerAvatar}
                                    sx={{
                                        ...avatarStyles,
                                        backgroundColor: stringToColor(data.freelancerName),
                                    }}
                                >
                                    {getNameLetters(data.freelancerName)}
                                </Avatar>
                            ) : !isClientPortal && data.clientCompanyLogo
                                ? (
                                    <Avatar
                                        alt={data.clientCompanyName}
                                        className="avatar"
                                        src={data.clientCompanyLogo}
                                        sx={{
                                            ...avatarStyles,
                                            backgroundColor: stringToColor(data.clientCompanyName),
                                        }}
                                    >
                                        {getNameLetters(data.clientCompanyName)}
                                    </Avatar>
                                )
                                : (
                                    <Box textAlign="center">
                                        <ImagePlaceholder width="80" height="80"/>
                                    </Box>
                                )
                    }

                </Box>

            </Box>

            <Box textAlign="center" sx={isClientPortal ? {mb: 2} : {mb: 2, mt: 3}}>
                <Pill variant="gray">ID: {data.id}</Pill>
            </Box>

            {!isClientPortal && data?.clientCompanyName && (
                <Typography align="center" sx={{fontSize: 20, fontWeight: 'bold'}}>{data.clientCompanyName}</Typography>
            )}
            <Typography
                sx={{
                    display: '-webkit-box',
                    fontWeight: 600,
                    fontSize: '16px',
                    color: data.status === EEngagementStatus.CLOSED ? theme.palette.gray.main : '',
                    lineHeight: 1.5,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                    mb: '16px',
                }}
            >
                {data.name}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: '16px 0',
                    mt: 'auto',
                    mb: '16px',
                    borderTop: `1px solid ${theme.palette.lightGray.main}`,
                    borderBottom: `1px solid ${theme.palette.lightGray.main}`
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: 1.5,
                        color: data.status === EEngagementStatus.CLOSED ? theme.palette.gray.main : theme.palette.purple.main,
                        'span': {
                            fontWeight: 400,
                            fontSize: '13px',
                            lineHeight: '24px',
                            color: theme.palette.gray.main,
                        }
                    }}
                >
                    {formatCurrency(data.paid || 0)} <span>{isClientPortal ? 'paid' : 'earned'}</span>
                </Typography>
                <Stack direction="row" spacing={2}>
                    <BlackBorderButton
                        size="small"
                        onClick={() => onDetails(data)}
                    >
                        See details
                    </BlackBorderButton>
                    <ContinueButton
                        size="small"
                        sx={{
                            width: 'auto !important'
                        }}
                        variant="contained"
                        onClick={() => onMessage(data)}
                    >
                        Message
                    </ContinueButton>
                </Stack>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <Typography
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: theme.palette.gray.main,
                        mr: '8px',
                        '&::after': {
                            content: '""',
                            width: '4px',
                            height: '4px',
                            ml: '8px',
                            backgroundColor: 'currentColor',
                            borderRadius: '50%'
                        }
                    }}
                >
                    Start date: {formatDate(data.startDate)}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: theme.palette.gray.main,
                    }}
                >
                    Next billing date: {data.nextBillingDate ? formatDate(data.nextBillingDate) : '-'}
                </Typography>
            </Box>
        </AnimatedItem>
    );
};

export default React.memo(EngagementListItem);
