import React from 'react';

import {Box, Slider as MUISlider} from '@mui/material';

import theme from '../../theme';

const count = 21;
const marks = Array.from(Array(count), (_, i) => i + 3)
    .map(value => ({label: `${value % 2 ? value : ''}${value === 23 ? '+' : ''}`, value}));

interface ExperienceProps {
    value: number;
    onChange: (event: Event, newValue: number | number[]) => void;
}

const Slider: React.FC<ExperienceProps> = React.forwardRef(({value, onChange}, ref) => {
    return (
        <Box tabIndex={0} ref={ref}>
            <MUISlider
                color="primary"
                marks={marks}
                min={3}
                max={23}
                step={1}
                sx={{
                    marginTop: '46px',
                    [theme.breakpoints.up('lg')]: {
                        marginTop: '51px'
                    }
                }}
                value={value}
                valueLabelDisplay="auto"
                onChange={onChange}
            />
        </Box>
    );
});

export const SliderExperience = React.memo(Slider);
