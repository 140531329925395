import React, {ReactNode} from 'react';

import {Box, Stack, Typography} from '@mui/material';

import theme from '../../../theme';

type HeaderItemProps = {
    icon: ReactNode;
    label: string;
    value: string;
};

const HeaderItem: React.FC<HeaderItemProps> = ({label, icon, value}) => {
    return (
        <Stack
            direction="row"
            display="flex"
            alignItems="center"
            spacing="12px"
            sx={{
                flexShrink: 0,
                'svg': {
                    width: '24px',
                    height: '24px',
                    flexShrink: 0,
                },
                'path': {
                    fill: theme.palette.gray.main
                }
            }}
        >
            {icon}
            <Box>
                <Typography
                    sx={{
                        mb: '2px',
                        fontWeight: 400,
                        fontSize: '14px',
                        color: theme.palette.gray.main
                    }}
                    variant="body1"
                >
                    {label}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: '16px'
                    }}
                >
                    {value}
                </Typography>
            </Box>
        </Stack>
    );
};

export default React.memo(HeaderItem);
