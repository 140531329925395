import React, {FC, ReactNode} from 'react';

import {
    Breakpoint,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';

import {CloseIcon} from '../../assets/icons';
import theme from '../../theme';


interface IProps {
    actions: ReactNode;
    children?: ReactNode;
    maxWidth: Breakpoint;
    maxWidthPx?: number;
    title: ReactNode;
    subtitle?: ReactNode;
    sx?: SxProps<Theme>;
    open: boolean;
    onClose: () => void;
}

const DialogEmoji: FC<IProps> = ({actions, children, maxWidth, maxWidthPx, open, subtitle, sx, title, onClose}) => {
    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={open}
            scroll="paper"
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor: theme.palette.white.main,
                    maxWidth: maxWidthPx ? `${maxWidthPx}px` : '680px',
                    borderRadius: '24px',
                    margin: 0,
                    width: '100%'
                },
                [theme.breakpoints.down('md')]: {
                    '.MuiDialog-container': {
                        alignItems: 'flex-end',
                    },
                    '& .MuiPaper-root': {
                        borderRadius: '24px 24px 0 0'
                    }
                },
                ...sx
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    padding: '40px 16px 32px',
                    fontWeight: 600,
                    fontSize: '16px',
                    [theme.breakpoints.up('md')]: {
                        fontSize: '24px'
                    },
                    lineHeight: 1.5,
                    textAlign: 'center',
                    color: theme.palette.black.main,
                    position: 'relative',
                    [theme.breakpoints.up('md')]: {
                        padding: '64px 64px 40px',
                        gap: '24px',
                        fontSize: '24px',
                        'img': {
                            width: '32px',
                            height: '32px',
                            [theme.breakpoints.up('md')]: {
                                width: '48px',
                                height: '48px'
                            }
                        },
                    }
                }}
            >
                {title}
                {
                    subtitle ? (
                        <Typography
                            component="span"
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: theme.palette.gray.main,
                                mt: -3
                            }}
                        >
                            {subtitle}
                        </Typography>
                    ) : null
                }
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '18px',
                        right: '18px',
                        '.close-icon': {
                            width: '24px',
                            height: '24px',
                        },
                        'svg': {
                            width: '24px',
                            height: '24px',
                        }
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    p: '0 20px',
                    [theme.breakpoints.up('md')]: {
                        p: '0 64px',
                    }
                }}
            >
                {children}
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'center',
                    padding: '16px 20px 40px',
                    borderTop: `1px solid ${theme.palette.lightGray.main}`,
                    [theme.breakpoints.up('md')]: {
                        padding: '4px 64px 64px',
                        borderTop: 'unset'
                    }
                }}
            >

                {actions}

            </DialogActions>
        </Dialog>
    );
};

export default React.memo(DialogEmoji);
