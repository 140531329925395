import React from 'react';

const PdfIcon = () => {
    return (
        <div style={{height: '24px', width: '24px', flexShrink: 0}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.80131 15.0151C7.59431 15.0151 7.45481 15.0354 7.38281 15.0556V16.3809C7.46831 16.4011 7.57519 16.4068 7.72256 16.4068C8.26144 16.4068 8.59331 16.1345 8.59331 15.6744C8.59331 15.2626 8.30756 15.0151 7.80131 15.0151ZM11.7242 15.0286C11.4992 15.0286 11.3529 15.0489 11.2663 15.0691V18.0054C11.3529 18.0256 11.4924 18.0256 11.6184 18.0256C12.5375 18.0324 13.136 17.5261 13.136 16.4551C13.1428 15.5214 12.5972 15.0286 11.7242 15.0286Z"
                    fill="black" fillOpacity="0.5"/>
                <path
                    d="M14.25 0.75H5.25C4.65327 0.75 4.08097 0.987049 3.65901 1.40901C3.23705 1.83097 3 2.40327 3 3V21C3 21.5967 3.23705 22.169 3.65901 22.591C4.08097 23.013 4.65327 23.25 5.25 23.25H18.75C19.3467 23.25 19.919 23.013 20.341 22.591C20.763 22.169 21 21.5967 21 21V7.5L14.25 0.75ZM9.18525 16.7137C8.83762 17.04 8.32462 17.1862 7.72725 17.1862C7.61142 17.1875 7.49565 17.1807 7.38075 17.166V18.7702H6.375V14.3422C6.82888 14.2745 7.28751 14.2437 7.74637 14.25C8.373 14.25 8.8185 14.3692 9.11887 14.6089C9.40462 14.8361 9.59812 15.2085 9.59812 15.6472C9.597 16.0882 9.45075 16.4606 9.18525 16.7137ZM13.4681 18.2381C12.9956 18.6307 12.2767 18.8175 11.3981 18.8175C10.8716 18.8175 10.4992 18.7837 10.2461 18.75V14.3434C10.7002 14.2771 11.1586 14.2458 11.6175 14.25C12.4691 14.25 13.0226 14.403 13.4546 14.7292C13.9215 15.0757 14.214 15.6281 14.214 16.4212C14.214 17.2796 13.9001 17.8725 13.4681 18.2381ZM17.625 15.1162H15.9015V16.1411H17.5125V16.9669H15.9015V18.7714H14.8822V14.2837H17.625V15.1162ZM14.25 8.625H13.125V3L18.75 8.625H14.25Z"
                    fill="black" fillOpacity="0.5"/>
            </svg>
        </div>
    );
};

export default PdfIcon;
