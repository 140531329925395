import React from 'react';

import {Box, Typography} from '@mui/material';

import theme from '../../../../../theme';

interface IProps {
    isClient?: boolean;
}

const MyMatchesEmpty: React.FC<IProps> = ({isClient}) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: 'calc(100vh - 300px)',
                display: 'flex'
            }}
        >
            <Box
                sx={{
                    margin: 'auto',
                    textAlign: 'center',
                    'img': {
                        width: '64px',
                        height: '64px',
                        display: 'inline-block',
                        mb: '32px'
                    }
                }}
            >
                <img src="/assets/images/glancing-eyes-emoji.png" width="64" height="64" alt="glancing eyes emoji"/>
                <Typography
                    sx={{
                        mb: '2px',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    No matches yet
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main
                    }}
                >
                    {
                        isClient
                            ? 'We are working on finding some great hires for you.'
                            : 'We are working on finding some great clients for you.'
                    }
                </Typography>
            </Box>
        </Box>
    );
};

export default React.memo(MyMatchesEmpty);
