import {any, literal, object, string} from 'zod';

import {MAX_FILE_SIZE, MAX_FILE_SIZE_MB} from '../constants';

import {urlRegex} from './utils';

export const emailSchema = {
    email: string()
        .trim()
        .min(1, 'Email address is required')
        .max(100, 'Value is too long')
        .email('Email Address is invalid')
};

const applicantBase = {
    ...emailSchema,
    firstName: string()
        .trim()
        .min(1, 'First Name is required')
        .max(100, 'Value is too long')
        .regex(/[a-zA-Z]+/gi, 'First name can contain only latin characters'),
    lastName: string()
        .trim()
        .min(1, 'Last Name is required')
        .max(100, 'Value is too long')
        .regex(/[a-zA-Z]+/gi, 'Last name can contain only latin characters'),
    phone: string()
        .trim()
        .min(11, 'Phone is required'),
};

export const newPassword = {
    password: string()
        .trim()
        .min(1, 'Password is required')
        .min(8, 'Password must be more than 8 characters'),
    passwordConfirm: string().trim().min(1, 'Please confirm your password'),
};

const applicant = {
    ...applicantBase,
    ...newPassword,
    termsAgree: literal(true, {
        errorMap: () => ({message: 'You must accept Terms of Use and Privacy Policy'})
    })
};

const client = {
    companyName: string()
        .trim()
        .min(1, 'Company is required')
        .max(100, 'Value is too long'),
    position: string()
        .trim()
        .min(1, 'Position is required')
        .max(100, 'Value is too long'),
    companyWebsite: string()
        .trim()
        .min(1, 'Company website is required')
        .max(200, 'Value is too long')
        .refine((value) => value.startsWith('https://') || value.startsWith('www.'), {
            message: 'Required https:// or www',
        })
        .refine((value) => value.match(urlRegex), {
            message: 'Please enter a valid Website (e.g. https://www.facebook.com).',
        }),
};

// password should include Uppercase, Digit and Symbol
export const isPassword = (data: {password: string}) => (/[A-Z]/g).test(data.password) && (/\d/g).test(data.password) && (/\W|_/g).test(data.password);
export const errorPassword = {
    path: ['password'],
    message: 'Password must contain at least one uppercase letter, one digit and one special character'
};

export const isPasswordConfirm = (data: {password: string; passwordConfirm:string}) => data.password === data.passwordConfirm;
export const errorIsPasswordConfirm = {
    path: ['passwordConfirm'],
    message: 'Password doesn’t match. Please check and try again',
};

const applicantSchema = object({
    ...applicantBase,
});

const clientSchema = object({
    ...applicantBase,
    ...client,
    avatar: any(),
    companyLogo: any(),
    fileAvatar: any()
        .refine((files) => files?.length ? (files?.[0]?.size <= MAX_FILE_SIZE_MB) : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        ),
    fileCompanyLogo: any()
        .refine((files) => files?.length ? (files?.[0]?.size <= MAX_FILE_SIZE_MB) : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        ),
});

const registerSchemaApplicant = object({
    ...applicant,
}).refine(isPassword, errorPassword).refine(isPasswordConfirm, errorIsPasswordConfirm);

const registerSchemaClient = object({
    ...applicant,
    ...client
}).refine(isPassword, errorPassword).refine(isPasswordConfirm, errorIsPasswordConfirm);

const passwordScheme = object({
    ...newPassword,
}).refine(isPassword, errorPassword).refine(isPasswordConfirm, errorIsPasswordConfirm);


export {
    applicantSchema,
    clientSchema,
    passwordScheme,
    registerSchemaApplicant,
    registerSchemaClient,
};
