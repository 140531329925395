import React, {useCallback, useState} from 'react';

import {useQueryClient} from '@tanstack/react-query';

import {CrossOutlineIcon} from '../../../assets/icons';
import {CLIENT_ENGAGEMENT} from '../../../constants';
import {useAuth} from '../../../hooks';
import {EEngagementStatus, EResumeStatus, IEngagement} from '../../../models';
import {DialogResumeCancel} from '../../../modules/ClientPortal';
import {DialogSuccess, RedFilledButton} from '../..';

type ResumeCancelBtnProps = {
    data: IEngagement;
};

const ResumeCancelBtn: React.FC<ResumeCancelBtnProps> = ({data}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const handleRejectClose = useCallback((isNeedUpdate: boolean) => {
        setOpen(false);
        // play modal show/hide animation
        setTimeout(() => {
            setOpenSuccess(isNeedUpdate);
        }, 500);
    }, []);

    const handleSuccessClose = useCallback(() => {
        setOpenSuccess(false);
        queryClient.invalidateQueries({queryKey: [CLIENT_ENGAGEMENT]});
    }, [queryClient]);

    if (data.status !== EEngagementStatus.PAUSED
        || ![
            EResumeStatus.CLIENT_ACCEPTED,
            EResumeStatus.FREELANCER_ACCEPTED,
            EResumeStatus.CLIENT_REQUESTED
        ].includes(data.resume?.resumeStatus as EResumeStatus)) {
        return null;
    }

    return (
        <>
            <RedFilledButton
                disabled={isImpersonal}
                size="small"
                sx={{
                    width: 'auto!important'
                }}
                onClick={() => setOpen(true)}
            >
                <CrossOutlineIcon/> Cancel engagement resume
            </RedFilledButton>

            <DialogResumeCancel data={data} open={open} onClose={handleRejectClose}/>

            <DialogSuccess
                open={openSuccess}
                title="The engagement resume has been successfully cancelled."
                onClose={handleSuccessClose}
            />
        </>
    );
};

export default React.memo(ResumeCancelBtn);
