import React from 'react';

import {styled} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import {LG} from '../../constants';
import theme from '../../theme';

const Wrp = styled('div')`
  margin: 0 -20px -20px;
  padding: 16px 20px;
  background-color: ${theme.palette.white.main};
  border-radius: 24px 24px 0 0;
  @media (min-width: ${LG}) {
    margin: 0;
    padding: 0;
    background-color: unset;
    border-radius: unset;
    display: flex;
    gap: 24px;
    align-items: flex-start;
  }
`;

interface ITabContentWrapper {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
}

const TabContentWrapper: React.FC<ITabContentWrapper> = ({children, sx}) => {
    return (
        <Wrp
            sx={sx}
        >
            {children}
        </Wrp>
    );
};

export default React.memo(TabContentWrapper);
