import React from 'react';

import {FaqList} from '../../../../components';

const supportQuestions = [
    {
        title: 'How does time-tracking work?',
        text: 'Experts submit time cards into our system once per week. Clients have full visibility into these weekly time cards within your client portal. If you’d like a more detailed view, you can also set up a shared sheet or tool directly with your freelancer.'
    },
    {
        title: 'What if I’m not happy with the marketer that I’m working with?',
        text: 'We pride ourselves on the quality of the marketers in our network, but we understand that sometimes it’s just not the right fit. If you are ever unhappy with your marketer, we can rematch you with another option from the GC network. All of our contracts are flexible, so you can cancel at any time.'
    },
    {
        title: 'How do I see my matches?',
        text: 'Once you’ve received your first match from us, you’ll get an email from our matching team with a link to the Client Portal. There, you’ll be able to review your matches, schedule intro calls and send proposals.'
    }
];

const SupportTab = () => {
    return (
        <>
            <FaqList list={supportQuestions}/>
        </>
    );
};

export default React.memo(SupportTab);
