import React, {FC} from 'react';
import {object, string} from 'zod';

import {Grid} from '@mui/material';

import {Input} from '../../../../../../components';
import {ISelectOption} from '../../../../../../models';

import BillingAddressForm, {billingAddressSchema} from './BillingAddressForm';
import {stylesInput} from './DialogPaymentMethod';

export const achSchema = object({
    // accountName: string()
    //     .trim()
    //     .min(1, 'Account Name is required')
    //     .max(100, 'Value is too long'),
    // accountHolderType: string(),
    // bankName: string()
    //     .trim()
    //     .min(1, 'Bank Name is required')
    //     .max(100, 'Value is too long'),
    routingNumber: string()
        .trim()
        .min(6, 'Routing Number is required')
        .max(9, 'Value is too long'),
    number: string()
        .trim()
        .min(3, 'Account Number is required')
        .max(17, 'Value is too long'),
    ...billingAddressSchema
});

interface IProps {
    optionsCountry?: ISelectOption[];
}

const PaymentACHForm: FC<IProps> = ({optionsCountry}) => {
    return (
        <>
            <Grid container spacing="24px" sx={{mt: '16px'}}>
                {/* <Grid item xs={12}>
                    <Input
                        label="Name on Account"
                        name="accountName"
                        sx={stylesInput}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        label="Account Holder Type"
                        name="accountHolderType"
                        sx={{
                            width: '100%'
                        }}
                        value="0"
                        variant="outlined"
                        onChange={() => console.log('select change')}
                    >
                        <MenuItem
                            sx={stylesMenuItem}
                            value="0"
                        >
                    Select
                        </MenuItem>
                        <MenuItem
                            sx={stylesMenuItem}
                            value={'test val 1'}
                        >
                    test val 1
                        </MenuItem>
                        <MenuItem
                            sx={stylesMenuItem}
                            value={'test val 2'}
                        >
                    test val 1
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        label="Bank Name"
                        name="bankName"
                        sx={{
                            width: '100%'
                        }}
                        value="0"
                        variant="outlined"
                        onChange={() => console.log('select change')}
                    >
                        <MenuItem
                            sx={stylesMenuItem}
                            value="0"
                        >
                    Select
                        </MenuItem>
                        <MenuItem
                            sx={stylesMenuItem}
                            value={'test val 1'}
                        >
                    test val 1
                        </MenuItem>
                        <MenuItem
                            sx={stylesMenuItem}
                            value={'test val 2'}
                        >
                    test val 1
                        </MenuItem>
                    </Select>
                </Grid> */}
                <Grid item xs={12}>
                    <Input
                        label="Routing Number"
                        name="routingNumber"
                        placeholder="000000000"
                        sx={stylesInput}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        label="Account Number"
                        name="number"
                        placeholder="000000000000"
                        sx={stylesInput}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <BillingAddressForm optionsCountry={optionsCountry}/>
        </>
    );
};

export default React.memo(PaymentACHForm);
