import React from 'react';

const ArrowCurvyIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3987_6189)">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M59.1344 19.2342C58.7516 25.173 55.9075 30.548 51.826 35.1422C45.662 42.0801 36.6755 47.2397 28.9728 49.8671C24.132 51.5189 20.3048 51.0181 15.2461 51.5836C15.0413 51.6066 14.8937 51.7912 14.9167 51.996C14.9392 52.2001 15.1243 52.3484 15.3286 52.3247C20.4465 51.7526 24.3164 52.2441 29.2141 50.5733C37.024 47.9092 46.133 42.6725 52.3838 35.6376C56.578 30.9168 59.4855 25.3855 59.879 19.2824C59.8924 19.0767 59.7371 18.8994 59.5314 18.886C59.3258 18.8727 59.1477 19.0286 59.1344 19.2342Z"
                    fill="black"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M14.2535 52.6532C14.2717 52.6522 14.393 52.6427 14.4953 52.6456C14.9195 52.6551 15.6602 52.7397 16.5883 52.9016C19.7011 53.4487 24.9116 54.871 27.3479 57.4832C27.4885 57.6339 27.7243 57.6417 27.875 57.5011C28.0257 57.3605 28.0348 57.1248 27.8942 56.9742C25.3593 54.2562 19.9559 52.7356 16.717 52.1672C16.2831 52.0914 15.8876 52.0312 15.5435 51.9882C16.4933 51.5189 17.4533 51.075 18.4208 50.6409C21.2065 49.392 23.3577 47.7345 25.3648 45.404C25.4992 45.2486 25.4816 45.0123 25.3254 44.8784C25.1701 44.744 24.9337 44.7616 24.7998 44.9178C22.8676 47.1605 20.7972 48.7577 18.116 49.9602C16.8518 50.5278 15.5988 51.1129 14.3659 51.7456C14.2432 51.8085 13.9261 51.9756 13.8854 52.006C13.6527 52.1791 13.7403 52.409 13.7622 52.4587C13.7726 52.4828 13.9187 52.7744 14.2535 52.6532ZM14.0248 51.9432C14.0157 51.9467 14.0059 51.9495 13.996 51.9536C14.0038 51.9499 14.0136 51.9471 14.0248 51.9432Z"
                    fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_3987_6189">
                    <rect width="57" height="57" fill="white" transform="translate(45.0557) rotate(52.2276)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default ArrowCurvyIcon;
