import isNil from 'lodash/isNil';

import {
    BANNERS,
    CASE_STUDIES,
    CERTIFICATES,
    FREELANCER,
    FREELANCER_DASHBOARD,
    FREELANCER_ENGAGEMENT,
    FREELANCER_LEGAL_DOCS,
    FREELANCER_MATCHES,
    FREELANCER_METHODS,
    FREELANCER_PROFILE,
    GET_FREELANCER_PROPOSALS,
    MEETINGS,
    PROPOSALS,
    SET_AVATAR,
} from '../constants';
import {
    IClientRole,
    IClientRoleDetails,
    IContactUs,
    IDashboard,
    IDashboardBanners,
    IEngagement,
    IEngagementFreelancer,
    IEngagementVersion,
    IFreelancer,
    IFreelancerMatchList,
    IFreelancerPaymentMethods,
    IFreelancerWithEmailVerificationProcess,
    ILegalDocResponse,
    IMeeting,
    IPause,
    IPaymentPreview,
    IProfile,
    IProposal,
    IProposalRequest,
    LegalDocType,
} from '../models';

import axiosInstance from './base.api';
import {convertHTTPResponse, GetListResult} from './list.api';
import {ICertificateRequest} from '.';

const caseStudyDelete = async (caseStudyId: number, isDraft?: boolean) => {
    return await axiosInstance.delete<null>(`${FREELANCER_PROFILE}/${CASE_STUDIES}/${caseStudyId}${isDraft === false ? '/public' : isDraft ? '/draft' : ''}`);
};

const caseStudyPublish = async (caseStudyId: number) => {
    return await axiosInstance.patch<null>(`${FREELANCER_PROFILE}/${CASE_STUDIES}/${caseStudyId}`);
};

const caseStudySubmit = async (formData: FormData) => {
    return await axiosInstance.put<null>(`${FREELANCER_PROFILE}/${CASE_STUDIES}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const certificateDelete = async (certificateId: number) => {
    return await axiosInstance.delete<null>(`${FREELANCER_PROFILE}/${CERTIFICATES}/${certificateId}`);
};

const certificateSubmit = async (payload: ICertificateRequest) => {
    const formData = new FormData();

    formData.append('name', payload.name);

    if (!isNil(payload.id)) {
        formData.append('id', payload.id.toString());
    }

    if (payload.file?.length) {
        payload.file.forEach(f => formData.append('file', f));
    } else if (payload.fileName) {
        formData.append('fileName', payload.fileName);
    }

    if (payload.url) {
        formData.append('url', payload.url);
    }

    return await axiosInstance.put<null>(`${FREELANCER_PROFILE}/${CERTIFICATES}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

// you set contacted flag
const chatContacted = async (conversationId: string) => {
    return await axiosInstance.patch<null>(`${FREELANCER_MATCHES}/chat/${conversationId}/contacted`);
};

// you finish call
const chatFinishCall = async (conversationId: string) => {
    return await axiosInstance.delete<{ conversationId: string }>(`${FREELANCER_MATCHES}/chat/${conversationId}/call`);
};

// you join call
const chatJoinCall = async (conversationId: string) => {
    return await axiosInstance.patch<{ conversationId: string }>(`${FREELANCER_MATCHES}/chat/${conversationId}/call`);
};

// 5 minutes lapses (heart beat)
const chatHeartBeat = async (conversationId: string) => {
    return await axiosInstance.get<{ conversationId: string }>(`${FREELANCER_MATCHES}/chat/${conversationId}/call`);
};

// start new call (differentiated by audio/video)
const chatStartCall = async (conversationId: string, isVideo: boolean) => {
    return await axiosInstance.post<{ conversationId: string }>(`${FREELANCER_MATCHES}/chat/${conversationId}/call?video=${isVideo}`);
};

const closureConfirm = async (id: number) => {
    return await axiosInstance.patch<null>(`${FREELANCER_ENGAGEMENT}/${id}/closure`);
};

const closureReject = async (id: number) => {
    return await axiosInstance.delete<null>(`${FREELANCER_ENGAGEMENT}/${id}/closure`);
};

const closureSubmit = async (id: number) => {
    return await axiosInstance.post<null>(`${FREELANCER_ENGAGEMENT}/${id}/closure`);
};

const contactUs = async (payload: IContactUs) => {
    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
        if (key === 'attachments') {
            if (value?.length) {
                value.forEach((file: File) => formData.append('attachments', file));
            }
        } else {
            formData.append(key, value);
        }
    });

    return await axiosInstance.post<null>(`${FREELANCER}/contact-us`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const dashboard = async () => {
    const response = await axiosInstance.get<IDashboard>(FREELANCER_DASHBOARD);

    return response.data;
};

const dashboardBanners = async () => {
    const response = await axiosInstance.get<IDashboardBanners>(`${FREELANCER_DASHBOARD}/${BANNERS}`);

    return response.data;
};

// init twillio conversation
// add 2 participants. Client and Freelancer
const getChatRoom = async (matchId: number) => {
    const response = await axiosInstance.get<{ conversationId: string }>(`${FREELANCER_MATCHES}/${matchId}/chat`);

    return response.data.conversationId;
};

const getFleelancer = async () => {
    const response = await axiosInstance.get<IFreelancerWithEmailVerificationProcess>(FREELANCER);

    return response.data;
};

const getMatch = async (matchId: number) => {
    return await axiosInstance.get<IClientRoleDetails>(`${FREELANCER_MATCHES}/${matchId}`);
};

const getMatches = (filterBy: string = 'ALL', pageSize: number = 10, sorting: string = 'id,DESC', isDashboard?: boolean) => {
    const sort = encodeURIComponent(sorting);

    return async ({pageParam = 0}) => await axiosInstance.get<GetListResult<IFreelancerMatchList>>(
        `${FREELANCER_MATCHES}?${isDashboard ? 'dashboard=true&' : ''}page=${pageParam}&size=${pageSize}&filter=${filterBy}&sort=${sort}`
    )
        .then(convertHTTPResponse);
};

const getMatchRole = async (matchId: number) => {
    return await axiosInstance.get<IClientRole>(`${FREELANCER_MATCHES}/${matchId}/role`);
};

const getProposal = async (proposalId: number) => {
    return await axiosInstance.get<IProposal>(`${GET_FREELANCER_PROPOSALS}/${proposalId}`);
};

const getProfile = async () => {
    const response = await axiosInstance.get<IProfile>(FREELANCER_PROFILE);

    return response.data;
};

const getWork = (id: number) => async () => {
    const response = await axiosInstance.get<IEngagementFreelancer>(`${FREELANCER_ENGAGEMENT}/${id}`);

    return response.data;
};

const getWorks = (sortBy: string = 'id', sortDirection = 'ASC', filterBy: string = 'ALL', pageSize: number = 10) => {
    const sort = encodeURIComponent(`${sortBy},${sortDirection}`);

    return async ({pageParam = 0}) => await axiosInstance.get<GetListResult<IEngagement>>(
        `${FREELANCER_ENGAGEMENT}?page=${pageParam}&size=${pageSize}&filter=${filterBy}&sort=${sort}`
    )
        .then(convertHTTPResponse);
};

const legalDocDelete = async (documentId: string) => {
    return await axiosInstance.delete<null>(`${FREELANCER_LEGAL_DOCS}/${documentId}`);
};

const legalDocNew = async (documentType: LegalDocType, taxYear: number) => {
    const response = await axiosInstance.put<ILegalDocResponse>(`${FREELANCER_LEGAL_DOCS}/${documentType}/${taxYear}`);

    return response.data;
};

const legalDocSubmit = async (documentId: string) => {
    return await axiosInstance.patch<ILegalDocResponse>(`${FREELANCER_LEGAL_DOCS}/${documentId}`);
};

const meetingAccept = async (meetingId: number) => {
    return await axiosInstance.patch<{id: number}>(`${FREELANCER}/${MEETINGS}/${meetingId}`);
};

const meetingReject = async (meetingId: number) => {
    return await axiosInstance.delete<{id: number}>(`${FREELANCER}/${MEETINGS}/${meetingId}`);
};

const meetingSubmit = async (conversationId: string, payload: Partial<IMeeting>) => {
    return await axiosInstance.post<{id: number}>(`${FREELANCER_MATCHES}/chat/${conversationId}/${MEETINGS}`, payload);
};

const meetingUpdate = async (meetingId: number, payload: Partial<IMeeting>) => {
    return await axiosInstance.put<{id: number}>(`${FREELANCER}/${MEETINGS}/${meetingId}`, payload);
};

const paymentMethods = async () => {
    return await axiosInstance.get<IFreelancerPaymentMethods>(FREELANCER_METHODS);
};

const paymentMethodsLink = async () => {
    return await axiosInstance.put<{onboardingUrl: string}>(FREELANCER_METHODS);
};

const paymentMethodSetPrimary = async (methodId: number) => {
    return await axiosInstance.patch<IPaymentPreview>(`${FREELANCER_METHODS}/${methodId}/primary`);
};

const proposalAccept = async (proposalId: number) => {
    return await axiosInstance.patch<IProposal>(`${GET_FREELANCER_PROPOSALS}/${proposalId}`);
};

const proposalReject = async (proposalId: number) => {
    return await axiosInstance.delete<IProposal>(`${GET_FREELANCER_PROPOSALS}/${proposalId}`);
};

const rejectMatch = async (matchId: number) => {
    return await axiosInstance.delete<null>(`${FREELANCER_MATCHES}/${matchId}`);
};

const pauseReject = async (id: number) => {
    return await axiosInstance.delete<{ id: number }>(`${FREELANCER_ENGAGEMENT}/${id}/pause`);
};

const pauseSubmit = async (id: number, payload: IPause) => {
    return await axiosInstance.post<{ id: number }>(`${FREELANCER_ENGAGEMENT}/${id}/pause`, payload);
};

const proposalSubmit = async (matchId: number, payload: IProposalRequest) => {
    return await axiosInstance.post<{ id: number }>(`${FREELANCER_MATCHES}/${matchId}/${PROPOSALS}`, payload);
};

const resumeAccept = async (id: number) => {
    return await axiosInstance.patch<null>(`${FREELANCER_ENGAGEMENT}/${id}/resume`);
};

const resumeReject = async (id: number) => {
    return await axiosInstance.delete<null>(`${FREELANCER_ENGAGEMENT}/${id}/resume`);
};

const resumeSubmit = async (id: number, payload: IEngagementVersion) => {
    return await axiosInstance.post<null>(`${FREELANCER_ENGAGEMENT}/${id}/resume`, payload);
};

const submitAvatar = async (file?: File) => {
    if (file) {
        const formData = new FormData();

        formData.append('file', file);

        return await axiosInstance.put<null>(SET_AVATAR, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } else {
        return await axiosInstance.delete<null>(SET_AVATAR);
    }
};

const submitFleelancer = async (data: Partial<IFreelancer>) => {
    return await axiosInstance.put<null>(FREELANCER, data);
};

const submitProfile = async (data: Partial<IProfile>) => {
    return await axiosInstance.put<null>(FREELANCER_PROFILE, data);
};

const togglePin = async (id: number) => {
    return await axiosInstance.patch<null>(`${FREELANCER_ENGAGEMENT}/${id}/pin`);
};

export const freelancerService = {
    caseStudyDelete,
    caseStudySubmit,
    caseStudyPublish,
    certificateDelete,
    certificateSubmit,
    chatContacted,
    chatFinishCall,
    chatHeartBeat,
    chatJoinCall,
    chatStartCall,
    closureConfirm,
    closureReject,
    closureSubmit,
    contactUs,
    dashboard,
    dashboardBanners,
    getChatRoom,
    getFleelancer,
    getMatch,
    getMatches,
    getMatchRole,
    getProfile,
    getProposal,
    getWork,
    getWorks,
    legalDocDelete,
    legalDocNew,
    legalDocSubmit,
    meetingAccept,
    meetingReject,
    meetingSubmit,
    meetingUpdate,
    paymentMethods,
    paymentMethodsLink,
    paymentMethodSetPrimary,
    pauseReject,
    pauseSubmit,
    proposalAccept,
    proposalReject,
    proposalSubmit,
    rejectMatch,
    resumeAccept,
    resumeReject,
    resumeSubmit,
    submitAvatar,
    submitFleelancer,
    submitProfile,
    togglePin,
};
