import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Grid} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {CardTitle, ContinueButton, Input, InputPhone, showToastError, showToastSuccess} from '../../../../../../components';
import {CLIENT} from '../../../../../../constants';
import {useAuth} from '../../../../../../hooks';
import theme from '../../../../../../theme';

import {stylesInput} from './DialogPaymentMethod';

export const contactSchema = object({
    email: string()
        .trim()
        .max(100, 'Value is too long')
        .email('Email Address is invalid'),
    firstName: string()
        .trim()
        .max(100, 'Value is too long'),
    lastName: string()
        .trim()
        .max(100, 'Value is too long'),
    phone: string()
        .trim()
        .max(100, 'Value is too long'),
    position: string()
        .trim()
        .max(100, 'Value is too long'),
});

type ContactInput = TypeOf<typeof contactSchema>;

const BillingContactForm = () => {
    const {isImpersonal} = useAuth();

    const {
        data: client,
    } = useQuery([CLIENT], clientService.getClient, {
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const methods = useForm<ContactInput>({
        resolver: zodResolver(contactSchema),
    });

    const {
        formState: {isDirty},
        handleSubmit,
        // getValues,
        reset,
        // setError,
    } = methods;

    const {mutate, isLoading} = useMutation(clientService.paymentContactSubmit,
        {
            onSuccess(response) {
                showToastSuccess('Billing contact has been saved successfully!');
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const onSubmitHandler: SubmitHandler<ContactInput> = (values) => {
        mutate(values);
    };

    // populate a form
    useEffect(() => {
        if (client?.billingContact) {
            reset(client?.billingContact);
        }
    }, [client, reset]);

    return (
        <FormProvider {...methods}>
            <form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit(onSubmitHandler)}
            >
                <Grid
                    container
                    spacing="16px"
                >
                    <Grid item xs={12}>
                        <CardTitle>Billing contact</CardTitle>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            disabled={isLoading}
                            label="First Name"
                            name="firstName"
                            sx={stylesInput}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            disabled={isLoading}
                            label="Last Name"
                            name="lastName"
                            sx={stylesInput}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            disabled={isLoading}
                            label="Email"
                            name="email"
                            sx={stylesInput}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputPhone
                            disabled={isLoading}
                            label="Contact phone number"
                            name="phone"
                            sx={stylesInput}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            disabled={isLoading}
                            label="Position"
                            name="position"
                            sx={stylesInput}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ContinueButton
                            disabled={!isDirty || isLoading || isImpersonal}
                            sx={{
                                mt: '8px',
                                width: 'auto !important',
                                [theme.breakpoints.up('md')]: {
                                    mt: '32px',
                                }
                            }}
                            type="submit"
                            variant="contained"
                        >
                            Save changes
                        </ContinueButton>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default React.memo(BillingContactForm);
