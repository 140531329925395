import React from 'react';

import {Box, ButtonProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import theme from '../../theme';

interface IProps {
    animateOnHover?: boolean;
    children: React.ReactNode;
    error?: boolean;
    sx?: SxProps<Theme>;
    onClick?: () => void;
}

const GradientBorderBox: React.FC<IProps & ButtonProps> = ({animateOnHover, children, error, sx, onClick}) => {
    return (
        <Box
            sx={{
                width: '100%',
                p: '2px',
                borderRadius: '24px',
                position: 'relative',
                cursor: animateOnHover ? 'pointer' : null,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    translate: '-50% -50%',
                    width: '100%',
                    height: '100%',
                    backgroundImage: error ? '' : (animateOnHover
                        ? 'linear-gradient(94.4deg, #7E5FFF 1.66%, #3C94F7 24.29%, #7CF2D0 46.92%, #67DD42 69.54%)'
                        : 'linear-gradient(95.11deg, #7E5FFF 1.91%, #3C94F7 33.99%, #7CF2D0 66.08%, #67DD42 98.16%)'),

                    backgroundPosition: animateOnHover ? '0 50%' : 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: animateOnHover ? '136%' : '100%',
                    borderRadius: '26px',
                    // transition: animateOnHover ? 'background-position .3s' : null,
                    // animationName: animateOnHover ? 'fluidGradient' : null,
                    animationIterationCount: animateOnHover ? 'infinite' : null,
                    animationDuration: animateOnHover ? '1.5s' : null,
                    animationDirection: animateOnHover ? 'alternate' : null,
                    border: error ? '2px solid #EF462C' : '',
                },
                '&:hover': {
                    '&::before': {
                        animationName: animateOnHover ? 'fluidGradient' : null,
                        // backgroundPosition: animateOnHover ? '100% 50%' : 'center',
                    }
                },
                ...sx,
                '@keyframes fluidGradient': {
                    'from': {
                        backgroundPosition: '0 50%'
                    },
                    'to': {
                        backgroundPosition: '100% 50%'
                    }
                },
            }}
            onClick={onClick}
        >
            <Box
                className="inner-box"
                sx={{
                    width: '100%',
                    p: '24px',
                    position: 'relative',
                    zIndex: 5,
                    backgroundColor: theme.palette.white.main,
                    borderRadius: 'inherit'
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default React.memo(GradientBorderBox);
