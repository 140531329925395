import React from 'react';

import {Grid} from '@mui/material';

import {EWorkType, EWorkTypeDisplay} from '../../models';
import theme from '../../theme';
import {CheckButton} from '../Button';
import {InputCaption} from '../Forms';


const containerStyles = {
    gap: '12px',
    [theme.breakpoints.up('lg')]: {
        gap: '16px'
    }
};

const itemStyles = {
    width: 'calc(50% - 6px)',
    flexBasis: 'calc(50% - 6px)',
    maxWidth: 'calc(50% - 6px) !important',
    [theme.breakpoints.up('md')]: {
        width: 'calc(33% - 9px)',
        flexBasis: 'calc(33% - 9px)',
        maxWidth: 'calc(33% - 9px) !important'
    }
};

interface BudgetTypeProps {
    label?: React.ReactNode;
    value: EWorkType | null;
    onType: (type: EWorkType) => void;
}

export const BudgetType: React.FC<BudgetTypeProps> = React.memo(({label, value, onType}) => {
    return (
        <>
            {
                label ? label : (
                    <InputCaption>Select your <span>budget type</span></InputCaption>
                )
            }
            <Grid
                container
                sx={containerStyles}
            >
                <Grid
                    item
                    xs={6}
                    md={4}
                    sx={itemStyles}
                >
                    <CheckButton
                        label={EWorkTypeDisplay.hourly}
                        selected={value === EWorkType.hourly}
                        onClick={() => onType(EWorkType.hourly)}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={4}
                    sx={itemStyles}>
                    <CheckButton
                        label={EWorkTypeDisplay.fixed}
                        selected={value === EWorkType.fixed}
                        onClick={() => onType(EWorkType.fixed)}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={4}
                    sx={itemStyles}
                >
                    <CheckButton
                        label={EWorkTypeDisplay.retainer}
                        selected={value === EWorkType.retainer}
                        onClick={() => onType(EWorkType.retainer)}
                    />
                </Grid>
            </Grid>
        </>
    );
});
