import React, {useCallback, useState} from 'react';

import {useQueryClient} from '@tanstack/react-query';

import {CrossOutlineIcon} from '../../../assets/icons';
import {CLIENT_ENGAGEMENT, FREELANCER_ENGAGEMENT} from '../../../constants';
import {useAuth} from '../../../hooks';
import {EEngagementStatus, EPauseStatus, IEngagement} from '../../../models';
import {DialogConfirmPending} from '../../../modules/FreelancerPortal';
import {DialogSuccess, RedFilledButton} from '../..';

type PauseCancelBtnProps = {
    data: IEngagement;
};

const PauseCancelBtn: React.FC<PauseCancelBtnProps> = ({data}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const handleRejectClose = useCallback((isNeedUpdate: boolean) => {
        setOpen(false);
        // play modal show/hide animation
        setTimeout(() => {
            setOpenSuccess(isNeedUpdate);
        }, 500);
    }, []);

    const handleSuccessClose = useCallback((isNeedUpdate: boolean) => {
        setOpenSuccess(false);
        queryClient.invalidateQueries({queryKey: [CLIENT_ENGAGEMENT]});     // don't remove. used for Dashboard refresh
        queryClient.invalidateQueries([FREELANCER_ENGAGEMENT, data.id]);
    }, [data.id, queryClient]);

    if (
        data?.status !== EEngagementStatus.ACTIVE
        || data?.pause?.status !== EPauseStatus.CLIENT_ACCEPTED
    ) {
        return null;
    }

    return (
        <>
            <RedFilledButton
                disabled={isImpersonal}
                size="small"
                onClick={() => setOpen(true)}
            >
                <CrossOutlineIcon/> Cancel engagement pause
            </RedFilledButton>

            <DialogConfirmPending engagementId={data.id} open={open} onClose={handleRejectClose}/>

            <DialogSuccess
                open={openSuccess}
                subtitle="Wishing you two productive work!"
                title="The engagement pause has been successfully cancelled."
                onClose={handleSuccessClose}
            />
        </>
    );
};

export default React.memo(PauseCancelBtn);
