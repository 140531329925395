import React, {useCallback, useEffect, useMemo, useState} from 'react';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';

import {Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {metaService, WIDGET_PAYLOAD} from '../../../api';
import {AnimateHeight, AnimatePageWrp, ClientTopTitleTools, ContinueButton, NewToolAlertBox, Portal, SelectMulti,} from '../../../components';
import {GET_TOOLS, RouteWidget} from '../../../constants';
import {useNavigateUTM} from '../../../hooks';
import {EModerationStatus, ITool} from '../../../models';
import theme from '../../../theme';
import {utm} from '../../../utils';

interface OptionType extends ITool {
    inputValue?: string;
}

const WidgetTools = () => {
    const widgetId = utm.campaign;
    const [tools, setTools] = useState<ITool[]>([]);

    const navigate = useNavigateUTM();

    const {
        isLoading: isLoadingData,
        data: toolsData
    } = useQuery([GET_TOOLS], () => metaService.searchTools(''), {
        staleTime: 60 * 60 * 1000,
        select: (response => orderBy(response, 'name'))
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(debounce((newValue: OptionType | null) => {
        if (!newValue) {
            return;
        }

        // don't add duplicate
        if (!tools.find(tool => tool.name.toLowerCase() === newValue.name.toLowerCase())) {
            setTools(tools => [...tools, newValue]);
        }
    }, 100), [tools]);

    const handleDelete = useCallback((tool: ITool) => {
        const newTools = tools.filter(t => t.name !== tool.name);

        setTools(newTools);
    }, [tools]);

    const handleSubmit = () => {
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
        const data = widgetPayload ? JSON.parse(widgetPayload) : {};

        localStorage.setItem(WIDGET_PAYLOAD + widgetId, JSON.stringify({...data, tools}));
        navigate(RouteWidget.location + location.search);
    };

    useEffect(() => {
        if (!widgetId) return;
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
        const data = widgetPayload ? JSON.parse(widgetPayload) : {};

        if (data.tools) {
            setTools(data.tools);
        }
    }, [widgetId]);

    const isNewToolExist = useMemo(() => !!tools?.some(tool => !tool.id || (tool.moderationStatus && tool.moderationStatus === EModerationStatus.NEW)), [tools]);

    return (
        <AnimatePageWrp maxWidth={{md: 600}}>
            <ClientTopTitleTools/>

            <SelectMulti
                freeSolo
                options={toolsData || []}
                placeholder="Ex. HubSpot, MailChimp, ActiveCampaign"
                pressCaption="tool"
                selected={tools}
                onChange={handleChange}
                onDelete={handleDelete}
            />

            <AnimateHeight isVisible={isNewToolExist} isOverflowHidden={false}>
                <NewToolAlertBox/>
            </AnimateHeight>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '57px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isLoadingData}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isLoadingData}
                    hasArrow
                    variant="contained"
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};

export default WidgetTools;
