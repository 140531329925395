import React from 'react';

import {FaqList} from '../../../../components';

const generalQuestions = [
    {
        title: 'Who can join Growth Collective?',
        text: 'We accept experts across every major channel, tool and vertical. Most specialists have 5+ years of experience in their domain. However, we occasionally make exceptions for exceptional junior talent.\n' +
            '\n' +
            'In addition to marketers, we accept experts of marketing-related tools and no-code/low-code platforms. We’ve found that these skills are often complementary. Commonly requested tools include Webflow, Klaviyo, Salesforce, HubSpot and Bubble.'
    },
    {
        title: 'How long does the application process take?',
        text: 'We receive a large volume of applicants and our review process is thorough. It can take up to a month to complete the entire process. If you’re interested in working with us in the future, we encourage you to apply today. Once accepted, you can adjust your availability as needed.'
    },
    {
        title: 'Do I have to be from the US or CA to join?',
        text: 'While most of the experts within our network are US or CA based, we accept marketers from all regions of the world. However, most of our clients are here in North America, so you may need to make yourself available during those hours for projects.'
    },
];

const GeneralTab = () => {
    return (
        <>
            <FaqList list={generalQuestions}/>
        </>
    );
};

export default React.memo(GeneralTab);
