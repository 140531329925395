import React from 'react';

const PlayIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6673 6.84536L7.33399 4.9187C7.1314 4.80173 6.9016 4.74012 6.66766 4.74006C6.43373 4.74 6.2039 4.80149 6.00125 4.91836C5.79859 5.03522 5.63025 5.20334 5.51313 5.40584C5.39601 5.60835 5.33423 5.8381 5.33399 6.07203V9.92536C5.33423 10.1592 5.39595 10.3888 5.51296 10.5913C5.62997 10.7937 5.79816 10.9618 6.00065 11.0787C6.20335 11.1957 6.43327 11.2573 6.66732 11.2573C6.90137 11.2573 7.1313 11.1957 7.33399 11.0787L10.6673 9.15203C10.8694 9.03482 11.0371 8.86659 11.1537 8.66418C11.2703 8.46177 11.3316 8.23228 11.3316 7.9987C11.3316 7.76511 11.2703 7.53562 11.1537 7.33321C11.0371 7.1308 10.8694 6.96257 10.6673 6.84536V6.84536ZM10.0007 7.9987L6.66732 9.92536V6.07203L10.0007 7.9987ZM8.00065 1.33203C6.68211 1.33203 5.39318 1.72302 4.29685 2.45557C3.20052 3.18811 2.34604 4.2293 1.84146 5.44747C1.33687 6.66565 1.20485 8.00609 1.46209 9.2993C1.71932 10.5925 2.35426 11.7804 3.28661 12.7127C4.21896 13.6451 5.40685 14.28 6.70005 14.5373C7.99326 14.7945 9.3337 14.6625 10.5519 14.1579C11.7701 13.6533 12.8112 12.7988 13.5438 11.7025C14.2763 10.6062 14.6673 9.31724 14.6673 7.9987C14.6673 7.12322 14.4949 6.25631 14.1599 5.44747C13.8248 4.63864 13.3338 3.90371 12.7147 3.28465C12.0956 2.6656 11.3607 2.17453 10.5519 1.8395C9.74304 1.50447 8.87613 1.33203 8.00065 1.33203V1.33203ZM8.00065 13.332C6.94582 13.332 5.91467 13.0192 5.03761 12.4332C4.16055 11.8472 3.47696 11.0142 3.0733 10.0397C2.66963 9.06514 2.56401 7.99278 2.7698 6.95822C2.97559 5.92365 3.48354 4.97334 4.22942 4.22746C4.9753 3.48158 5.92561 2.97363 6.96017 2.76784C7.99474 2.56206 9.06709 2.66767 10.0416 3.07134C11.0162 3.47501 11.8491 4.15859 12.4352 5.03566C13.0212 5.91272 13.334 6.94386 13.334 7.9987C13.334 9.41319 12.7721 10.7697 11.7719 11.7699C10.7717 12.7701 9.41514 13.332 8.00065 13.332V13.332Z"
                fill="white"/>
        </svg>
    );
};

export default PlayIcon;
