import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {any, date, object, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, SxProps, Theme, useMediaQuery} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {PausedWorkStatusIcon} from '../../../../../../assets/icons';
import {
    BlackBorderButton,
    Button,
    ContinueButton,
    DialogEmoji,
    DialogSuccess,
    InputCaption,
    InputDate,
    showToastError
} from '../../../../../../components';
import {CLIENT_ENGAGEMENT} from '../../../../../../constants';
import {useAuth} from '../../../../../../hooks';
import {EEngagementStatus, EPauseStatus, EWorkType, IEngagement, IPauseClientRequest} from '../../../../../../models';
import theme from '../../../../../../theme';
import {formatDate, localToUTC} from '../../../../../../utils';

export const pauseSchema = object({
    startDate: date({
        required_error: 'Start Date is required',
        invalid_type_error: 'Start Date is required',
    }),
    endDate: any(),

})
    .refine((data) => !!data.startDate, {
        path: ['startDate'],
        message: 'Start Date is required'
    })
    .refine((data) => {
        const {startDate, endDate} = data;

        if (startDate && endDate) {
            return endDate.getTime() > startDate.getTime();
        }

        return true;
    }, {
        path: ['endDate'],
        message: 'End Date should be later than the Start Date'
    });

type PauseInput = TypeOf<typeof pauseSchema>;

interface PauseEngagementBtnProps {
    data: IEngagement;
    fullWidth?: boolean;
    sx?: SxProps;
}

const PauseEngagementBtn: React.FC<PauseEngagementBtnProps> = ({data, ...props}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const methods = useForm<PauseInput>({
        resolver: zodResolver(pauseSchema),
    });

    const {
        formState: {isDirty},
        handleSubmit,
        getValues,
        reset,
        setValue,
        watch
    } = methods;

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const minEndDate = addDays(startDate, 1);
    const maxEndDate = addMonths(addDays(startDate, -1), 1);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (payload: IPauseClientRequest) => clientService.pauseSubmit(data.id, payload),
        {
            onSuccess(response) {
                reset({});
                setOpen(false);
                setOpenSuccess(true);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleClose = useCallback(() => {
        reset({});
        setOpen(false);
    }, [reset]);

    const handleSuccessClose = useCallback((isNeedUpdate: boolean) => {
        setOpenSuccess(false);
        queryClient.invalidateQueries([CLIENT_ENGAGEMENT, data.id]);
    }, [data.id, queryClient]);

    const onSubmitHandler: SubmitHandler<PauseInput> = () => {
        const {startDate, endDate} = getValues();

        const payload: IPauseClientRequest = {
            startDate: localToUTC(startDate).toISOString().split('T')[0],
        };

        if (endDate) {
            payload.endDate = localToUTC(endDate).toISOString().split('T')[0];
        }

        mutate(payload);
    };

    // erase endDate if user clean startDate
    useEffect(() => {
        if (!startDate && endDate) {
            setValue('endDate', null, {shouldValidate: true});
        }
    }, [startDate, endDate, setValue]);

    // show only for the Active and if not waiting for accepting
    if (
        data.budgetType === EWorkType.fixed     // From a Billing perspective, it makes no sense to pause fixed-type engagements.
        || data.status !== EEngagementStatus.ACTIVE
        || (data.pause ? ![EPauseStatus.CANCELLED, EPauseStatus.CLIENT_REJECTED].includes(data.pause?.status as EPauseStatus) : false)
    ) {
        return null;
    }

    return (
        <>
            <Button
                {...props}
                color="secondary"
                disabled={isImpersonal}
                size="small"
                variant="contained"
                onClick={() => setOpen(true)}
            >
                <PausedWorkStatusIcon/>Pause engagement
            </Button>

            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            disabled={isSubmitting}
                            sx={{
                                width: mdDown ? '50%' : 'auto',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </BlackBorderButton>

                        <ContinueButton
                            disabled={isSubmitting || !isDirty}
                            sx={{
                                width: mdDown ? '50% !important' : 'auto !important',
                                'div': {
                                    justifyContent: 'center'
                                }
                            }}
                            variant="contained"
                            onClick={handleSubmit(onSubmitHandler)}
                        >
                            Confirm
                        </ContinueButton>
                    </>
                )}
                maxWidth="sm"
                maxWidthPx={574}
                open={open}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img src="/assets/images/pause-icon.png" width="48" height="48" alt="sad icon"/>
                        </Box>
                        Pause engagement
                    </>
                )}
                onClose={handleClose}
            >
                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={mdDown ? 2 : 4}>

                            <Grid item xs={6}>
                                <InputCaption
                                    sx={{
                                        textAlign: 'left !important',
                                        mb: '16px !important'
                                    }}
                                >
                                    Start date
                                </InputCaption>
                                <InputDate
                                    disabled={isSubmitting}
                                    disablePast
                                    name="startDate"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputCaption
                                    sx={{
                                        textAlign: 'left !important',
                                        mb: '16px !important',
                                        opacity: startDate ? 1 : 0.5,
                                        '& span': {
                                            color: theme.palette.gray.main,
                                            fontSize: 12,
                                        }
                                    }}
                                >
                                    End date <span>(Max 1 month)</span>
                                </InputCaption>
                                <InputDate
                                    disabled={!startDate || isSubmitting}
                                    maxDate={maxEndDate}
                                    minDate={minEndDate}
                                    name="endDate"
                                />
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop: '0px !important'}}>
                                <Typography
                                    paragraph
                                    sx={{
                                        color: theme.palette.gray.main,
                                        mb: '24px',
                                        [theme.breakpoints.up('md')]: {
                                            mb: '40px'
                                        }
                                    }}
                                    variant="caption"
                                >
                                    You will not be charged when the engagement is on hold.
                                </Typography>
                            </Grid>

                        </Grid>
                    </form>
                </FormProvider>
            </DialogEmoji>

            {/* Your pause engagement request has been successfully submitted */}
            <DialogSuccess
                open={openSuccess}
                subtitle={<>
                    {endDate ? (
                        <>
                            The engagement is now on hold. It will automatically be renewed
                            on {formatDate(addDays(endDate, 1))}.<br/>
                        </>
                    ) : (
                        <>
                            The engagement is now on hold, until you manually resume it.<br/>
                        </>
                    )}
                    We have notified {data.freelancerName.split(' ')[0]} about it.
                </>}
                title="You have successfully paused the engagement"
                onClose={handleSuccessClose}
            />
        </>
    );
};

export default React.memo(PauseEngagementBtn);
