import React, {useEffect, useMemo} from 'react';
import isEqual from 'lodash/isEqual';
import {useLocation, useNavigate} from 'react-router-dom';

import {Typography} from '@mui/material';

import {ECaseStudySteps, ECaseStudyStepsDisplay} from '../../../models';
import theme from '../../../theme';
import {getCaseStudyStep, stepsOrder} from '../../../utils';
import {StepsLayout} from '../../StepsNavBar';
import {IStepNav} from '../../StepsNavBar/StepsNavBar';
import {useCaseStudy} from '../context/case-study.context';

const CaseStudyEdit = () => {
    const {state: {data, isNew}, onCancel} = useCaseStudy();
    const location = useLocation();
    const navigate = useNavigate();

    const completedIndex = useMemo(() => {
        if (!data) return 0;

        // open Published for editing. copy all to the draft object
        if (data.publicVersion && !data.draftVersion) {
            data.draftVersion = data.publicVersion;
        }

        const {completedIndex} = getCaseStudyStep(data.draftVersion);

        return completedIndex;
    }, [data]);


    const currentStep = useMemo(() => {
        const routes = location.pathname.split('/').reverse();

        const step = routes.find(route => stepsOrder.includes(route as ECaseStudySteps));

        return step ? stepsOrder.indexOf(step as ECaseStudySteps) : 0;
    }, [location.pathname]);


    const steps: IStepNav[] = useMemo(() => {
        const result = Object.entries(ECaseStudySteps).map(([key, value], index) => ({
            active: currentStep === index,
            disabled: currentStep !== index && completedIndex < index,
            text: ECaseStudyStepsDisplay[value],
            link: key
        }));

        if (completedIndex + 1 === result.length) {
            const isDirty = !isEqual(data?.draftVersion, data?.publicVersion);
            const redirect = location.pathname
                .replace(`/${ECaseStudySteps.challenge}`, '')
                .replace(`/${ECaseStudySteps.company}`, '')
                .replace(`/${ECaseStudySteps.description}`, '')
                .replace(`/${ECaseStudySteps.outcomes}`, '')
                .replace(`/${ECaseStudySteps.testimonial}`, '')
                .replace('/edit', '');

            result.push({
                active: false,
                disabled: false,
                text: ECaseStudyStepsDisplay.preview,
                link: redirect + (isDirty ? '?draft=true' : '')
            });
        }

        return result;
    }, [completedIndex, currentStep, data, location]);

    // if step missed set active first
    useEffect(() => {
        if (location.pathname.endsWith('new') || (data && location.pathname.endsWith(data.id.toString()))) {
            navigate(ECaseStudySteps.description, {replace: true});
        }
    }, [data, location.pathname, navigate]);

    return (
        <>
            <Typography
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('md')]: {
                        display: 'block',
                        fontWeight: 600,
                        fontSize: '24px',
                        mt: '64px'
                    }
                }}
                textAlign="center"
                variant="body1"
            >
                {isNew ? 'Add' : 'Edit'} a case study
            </Typography>

            <StepsLayout
                currentStep={currentStep + 1}
                steps={steps}
                onCancel={onCancel}
            />
        </>
    );
};

export default CaseStudyEdit;
