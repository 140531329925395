import React, {useCallback, useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, InputAdornment, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {freelancerService, ICertificateRequest} from '../../../../../api';
import {HyperlinkIcon} from '../../../../../assets/icons';
import {
    BackButton,
    BlackBorderButton,
    ContinueButton,
    Input,
    InputFile,
    Portal,
    showToastError,
    showToastSuccess
} from '../../../../../components';
import {ACCEPTED_DOCUMENTS_TYPES, CERTIFICATES, MD, RouteFreelancerPortal} from '../../../../../constants';
import {useAuth} from '../../../../../hooks';
import theme from '../../../../../theme';
import {ValidationCertificate} from '../../../../../utils';
import {useFreelancer} from '../../../contexts';

type CertificateInput = TypeOf<typeof ValidationCertificate.certificateSchema>;


const Wrp = styled('div')`
  padding-top: 12px;

  @media (min-width: ${MD}) {
    padding-top: 32px;
  }
`;

const Title = styled('p')<{ alignCenter?: boolean }>`
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.black.main};
  text-align: ${props => props.alignCenter && 'center'};
`;

export const AddCertificate = React.memo(() => {
    const {isImpersonal} = useAuth();
    const params = useParams();
    const {state, refetchProfile} = useFreelancer();

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const navigate = useNavigate();

    const certificateId = Number(params.certificateId);
    const data = state.data?.certificates?.find(item => item.id === certificateId);

    const prevPage = `${RouteFreelancerPortal.profileSettings}/${CERTIFICATES}`;

    const {mutate, isLoading: isSubmitting} = useMutation(freelancerService.certificateSubmit,
        {
            onSuccess() {
                showToastSuccess('Certificate was successfully ' + (data?.id ? 'updated!' : ' added!'));
                refetchProfile();
                navigate(prevPage);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const methods = useForm<CertificateInput>({
        resolver: zodResolver(ValidationCertificate.certificateSchema),
        defaultValues: ValidationCertificate.defaultValues,
        mode: 'onTouched',
    });

    const {
        clearErrors,
        handleSubmit,
        formState: {isDirty},
        getValues,
        setValue,
        reset,
        trigger,
        watch,
    } = methods;

    const url = watch('url');
    const file = watch('file');

    // erase File errors
    useEffect(() => {
        if (url) {
            trigger('file');
        }
    }, [url, trigger]);

    const f = file?.[0];

    // erase URL errors
    useEffect(() => {
        if (file) {
            trigger('url');
        }
    }, [f, file, trigger]);

    // clean URL if File exists
    useEffect(() => {
        if (url) {
            setValue('file', false, {shouldValidate: true});
        }
    }, [url, setValue]);

    // clean File if URL exists
    useEffect(() => {
        if (file) {
            setValue('url', '', {shouldValidate: true});
        }
    }, [file, setValue]);

    useEffect(() => {
        if (data) {
            const {id, fileName, name, url} = data;
            const values: CertificateInput = {name, url};

            if (id && fileName) {
                values.file = [new File([], fileName)];
            }
            reset(values);
        } else {
            reset(ValidationCertificate.defaultValues);
        }
    }, [data, clearErrors, reset, setValue]);

    const onSubmitHandler: SubmitHandler<CertificateInput> = useCallback(() => {
        const values = getValues();

        // Update request
        if (data) {
            const file = values?.file?.[0];
            const payload: ICertificateRequest = {
                ...values,
                id: data.id,
                file: file?.size ? [file] : []
            };

            // keep previou file
            if (file && !file.size) {
                payload.fileName = data.fileName;
            }

            mutate(payload);
        } else {
            mutate(values);
        }
    }, [data, getValues, mutate]);

    return (
        <Wrp>
            <BackButton
                onClick={() => navigate(prevPage)}
            />

            <Box>
                <Typography
                    sx={{
                        mb: '24px',
                        fontWeight: 600,
                        fontSize: '16px',
                        textAlign: 'center',
                        [theme.breakpoints.up('md')]: {
                            mb: '40px',
                            fontSize: '24px'
                        }
                    }}
                >
                    {data?.id ? 'Edit' : 'Add'} a certificate
                </Typography>

                <Box
                    sx={{
                        p: '16px',
                        pb: '80px',
                        maxWidth: '696px',
                        m: '0 auto',
                        backgroundColor: theme.palette.white.main,
                        borderRadius: '16px',
                        [theme.breakpoints.up('md')]: {
                            p: '40px'
                        }
                    }}
                >
                    <FormProvider {...methods}>
                        <form
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Name Your Certificate</Title>
                            <Input
                                disabled={isSubmitting}
                                name="name"
                                placeholder="Name of your certificate"
                                sx={{
                                    mb: '24px'
                                }}
                            />

                            <Box
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        mt: '16px'
                                    }
                                }}
                            >
                                <InputFile
                                    accept={ACCEPTED_DOCUMENTS_TYPES}
                                    disabled={isSubmitting || !!url}
                                    isPreviewThumbs={false}
                                    placeholder={
                                        <>
                                            Drag your certificate here or click to browse
                                            <span>
                                                PDF, JPEG and PNG files with max size of 5 MB
                                            </span>
                                        </>}
                                    name="file"
                                    mode="update"
                                    multiple={false}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '24px',
                                    mt: '40px',
                                    mb: '40px',
                                    '&::before, &::after': {
                                        content: '""',
                                        height: '1px',
                                        width: '100%',
                                        backgroundColor: theme.palette.lightGray.main
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        mt: '64px',
                                        mb: '64px',
                                    }
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: 1.5
                                    }}
                                >
                                    OR
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    pb: '40px'
                                }}
                            >
                                <Title alignCenter>
                                    Paste a URL link to your certificate
                                </Title>

                                <Input
                                    disabled={isSubmitting}
                                    name="url"
                                    placeholder="https://www..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HyperlinkIcon/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </form>

                        {
                            mdUp ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '16px'
                                    }}
                                >
                                    <BlackBorderButton
                                        disabled={isSubmitting}
                                        onClick={() => navigate(prevPage)}
                                    >
                                        Cancel
                                    </BlackBorderButton>
                                    <ContinueButton
                                        disabled={isSubmitting || !isDirty || isImpersonal}
                                        sx={{
                                            width: 'auto !important',
                                            'div': {
                                                justifyContent: 'center'
                                            }
                                        }}
                                        variant="contained"
                                        onClick={handleSubmit(onSubmitHandler)}
                                    >
                                        Save changes
                                    </ContinueButton>
                                </Box>
                            ) : (
                                <Portal order={1} visibleUntil={MD}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '16px',
                                            m: '-40px -20px',
                                            p: '16px 40px 40px',
                                            backgroundColor: theme.palette.white.main,
                                            borderTop: `1px solid ${theme.palette.lightGray.main}`
                                        }}
                                    >
                                        <BlackBorderButton
                                            disabled={isSubmitting}
                                            sx={{
                                                width: '50% !important'
                                            }}
                                            onClick={() => navigate(prevPage)}
                                        >
                                            Cancel
                                        </BlackBorderButton>
                                        <ContinueButton
                                            disabled={isSubmitting || !isDirty || isImpersonal}
                                            sx={{
                                                width: '50% !important',
                                                'div': {
                                                    justifyContent: 'center'
                                                }
                                            }}
                                            variant="contained"
                                            onClick={handleSubmit(onSubmitHandler)}
                                        >
                                            Save changes
                                        </ContinueButton>
                                    </Box>
                                </Portal>
                            )
                        }
                    </FormProvider>
                </Box>
            </Box>
        </Wrp>
    );
});
