import {EWorkType} from './work.model';

interface IProposalBase {
    description: string;
    endDate?: string;
    isMonthly?: boolean;
    name: string;
    rate?: number;
    startDate?: string;
    weeklyEngagementHours?: number;
}

export interface IProposalRequest extends IProposalBase {
    workType: string;
}

export interface IProposal extends IProposalBase {
    id: number;
    isMonthly: boolean;
    matchId: number;
    status: EProposalStatus;
    workType: EWorkType;
}

export enum EProposalStatus {
    CLIENT_ACCEPTED = 'CLIENT_ACCEPTED',
    CLIENT_REJECTED = 'CLIENT_REJECTED',
    CLIENT_SENT = 'CLIENT_SENT',
    FREELANCER_ACCEPTED = 'FREELANCER_ACCEPTED',
    FREELANCER_REJECTED = 'FREELANCER_REJECTED',
    FREELANCER_SENT = 'FREELANCER_SENT',
}
