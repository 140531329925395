import CloseCancelBtn from './CloseCancelBtn';
import CloseRespondBtn from './CloseRespondBtn';
import PauseCancelBtn from './PauseCancelBtn';
import ResumeCancelBtn from './ResumeCancelBtn';
import ResumeEngagementBtn from './ResumeEngagementBtn';
import ViewProposalBtn from './ViewProposalBtn';


export const EngagementActionsClient = {
    CloseCancelBtn,
    CloseRespondBtn,
    PauseCancelBtn,
    ResumeCancelBtn,
    ResumeEngagementBtn,
    ViewProposalBtn,
};
