import React from 'react';

import {styled} from '@mui/material';

import {LG, MD, SM, XL} from '../../constants';
import theme from '../../theme';
import {Logo} from '../index';


interface IAsideBlock {
    children: React.ReactNode;
    fixedScroll?: boolean;
}

const AsideBlockWrapper = styled('aside')`
  height: 100%;
  width: 100%;
  background-color: ${theme.palette.black.main};
  color: ${theme.palette.white.main};

  @media (min-width: ${SM}) {
    width: auto;
    max-width: 550px;
    flex-shrink: 1.5;
    min-width: 270px;
  }

  @media (min-width: ${MD}) {
    width: 100%;
    flex-shrink: 1.3;
  }

  @media (min-width: ${XL}) {
    max-width: 600px;
  }

  .logo {
    flex-shrink: 0;
    margin: 0 auto;
    margin-bottom: 32px;

    @media (min-width: ${SM}) {
      margin: 0;
      margin-bottom: 80px;
    }

    @media (min-width: ${MD}) {
      margin-bottom: 40px;
    }
  }
`;

const AsideBlockInner = styled('div')<{ fixedScroll?: boolean }>`
  padding: 30px 20px 0;
  width: 100%;

  & .logo {
    width: 102px;
    height: 30px;

    @media (min-width: ${LG}) {
      margin-left: 17px;
    }

    @media (min-width: ${XL}) {
      width: 103px;
      margin-left: 32px;
      margin-bottom: 48px;
    }
  }

  @media (min-width: ${SM}) {
    padding: 80px 16px;
    overflow-y: auto;
    height: 100vh;
  }

  @media (min-width: ${MD}) {
    padding: 40px 60px;
  }

  @media (min-width: ${LG}) {
    padding: 40px 93px 0 63px;
  }

  @media (min-width: ${XL}) {
    padding: 64px 40px 0 48px;
  }
`;

const AsideBlockContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const AsideBlock = ({children, fixedScroll}: IAsideBlock): JSX.Element => {
    return (
        <AsideBlockWrapper>
            <AsideBlockInner fixedScroll={fixedScroll}>
                <AsideBlockContent>
                    <Logo/>
                    {children}
                </AsideBlockContent>
            </AsideBlockInner>
        </AsideBlockWrapper>
    );
};

export default AsideBlock;
