import React, {useMemo} from 'react';

import {styled} from '@mui/material';

import {CancelledStatusIcon, CheckStatusIcon, PausedWorkStatusIcon, ReloadStatusIcon} from '../../assets/icons';
import {EEngagementStatus, EEngagementStatusDisplay} from '../../models';
import theme from '../../theme';

const Wrp = styled('div')`
  padding: 10px 18px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  &.active {
    color: ${theme.palette.darkGreen.main};
    background-color: ${theme.palette.green.light};
  }

  &.pending {
    color: ${theme.palette.blue.dark};
    background-color: ${theme.palette.blue.light};
  }

  &.paused {
    color: ${theme.palette.gray.main};
    background-color: ${theme.palette.lightGray.light};
  }

  &.closed {
    color: ${theme.palette.error.main};
    background-color: ${theme.palette.lightRed.main};
  }
`;

interface IProps {
    status: EEngagementStatus;
    caption?: string;
}

const RoleStatusPill: React.FC<IProps> = ({status, caption}) => {
    const renderIcon = useMemo(() => {
        switch (status) {
            case EEngagementStatus.ACTIVE:
                return <CheckStatusIcon/>;
            case EEngagementStatus.PENDING:
                return <ReloadStatusIcon/>;
            case EEngagementStatus.CLOSED:
                return <CancelledStatusIcon/>;
            case EEngagementStatus.PAUSED:
                return <PausedWorkStatusIcon/>;
            default:
                return '';
        }
    }, [status]);

    return (
        <Wrp className={status.toLowerCase()}>
            {renderIcon}
            {caption || EEngagementStatusDisplay[status]}
        </Wrp>
    );
};

export default React.memo(RoleStatusPill);
