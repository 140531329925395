import React from 'react';

import {Grid, Grow} from '@mui/material';

import {IChannel} from '../../models';
import theme from '../../theme';
import {CheckButton} from '..';

interface IProps {
    items: IChannel[];
    selectedIds: number[];
    onClick: (channel: IChannel) => void;
}

export const ChannelsList: React.FC<IProps> = ({items, selectedIds, onClick}) => {
    const isSelected = (id: number) => selectedIds.includes(id);

    return (
        <Grid container sx={{
            mt: '-12px',
            ml: '-12px',
            width: 'calc(100% + 12px)',
            [theme.breakpoints.up('xl')]: {
                mt: '-16px',
                ml: '-16px',
                width: 'calc(100% + 16px)',
            }
        }}>
            {
                items?.map((channel) => (
                    <Grow
                        in={true}
                        key={channel.id}
                        timeout={Math.random() * 2000}
                    >
                        <Grid item xs={6} sm={6} md={4} sx={{
                            pt: '12px',
                            pl: '12px',
                            [theme.breakpoints.up('xl')]: {
                                pt: '16px',
                                pl: '16px',
                            }
                        }}>
                            <CheckButton
                                label={channel.name}
                                selected={isSelected(channel.id)}
                                onClick={() => onClick(channel)}
                            />
                        </Grid>
                    </Grow>
                ))
            }
        </Grid>
    );
};
