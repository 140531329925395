import React, {useEffect, useMemo, useRef} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {ActiveWorkStatusIcon, EmojiIcon, MenuMobile, PurseOutlineIcon} from '../../../assets/icons';
import {AsideMenu, ContinueButton, Tab, Tabs} from '../../../components';
import {IAsideMenuItem} from '../../../components/AsideMenu/AsideMenu';
import {GENERAL, LG, MD, PAYMENT, PROCESS, RouteFreelancerPortal, SUPPORT, XL} from '../../../constants';
import {EMailType} from '../../../models';
import theme from '../../../theme';

const ContentWrp = styled('div')`
  @media (min-width: ${MD}) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 365px auto;
    gap: 24px;
  }

  @media (min-width: ${LG}) {
    gap: 120px;
  }

  @media (min-width: ${XL}) {
    grid-template-columns: 365px auto;
  }
`;

const desktopTabs: IAsideMenuItem[] = [
    {
        link: `${RouteFreelancerPortal.help}/${GENERAL}`,
        title: 'General',
        icon: <MenuMobile/>
    },
    {
        link: `${RouteFreelancerPortal.help}/${PAYMENT}`,
        title: 'Payment',
        icon: <PurseOutlineIcon/>
    },
    {
        link: `${RouteFreelancerPortal.help}/${PROCESS}`,
        title: 'Process',
        icon: <ActiveWorkStatusIcon/>
    },
    {
        link: `${RouteFreelancerPortal.help}/${SUPPORT}`,
        title: 'Support',
        icon: <EmojiIcon/>
    }
];

const tabs = [GENERAL, PAYMENT, PROCESS, SUPPORT];

const HelpPage = () => {
    // const {state: {isLoading}} = useFreelancer();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const location = useLocation();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);

    const activeTab = useMemo(() => location.pathname.replace(`${RouteFreelancerPortal.help}/`, ''), [location.pathname]);

    useEffect(() => {
        if (location.pathname === RouteFreelancerPortal.help) {
            navigate(tabs[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!activeTab) return;

        ref.current?.focus();
    }, [activeTab]);

    return (
        <>
            <Box
                sx={{
                    maxWidth: '766px',
                    m: '40px auto 24px',
                    textAlign: 'center',
                    [theme.breakpoints.up('md')]: {
                        mt: '64px',
                        mb: '64px'
                    },
                    'img': {
                        width: '48px',
                        height: '48px',
                        [theme.breakpoints.up('md')]: {
                            width: '64px',
                            height: '64px'
                        }
                    }
                }}
            >
                <img src="/assets/images/magic-wand-icon.png" width="64" height="64" alt="magic wand icon"/>
                <Typography
                    sx={{
                        mt: '24px',
                        mb: '8px',
                        fontWeight: 600,
                        fontSize: '16px',
                        [theme.breakpoints.up('md')]: {
                            fontSize: '24px'
                        }
                    }}
                    variant="h1"
                >
                    Frequently Asked Questions
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        color: theme.palette.gray.main
                    }}
                >
                    Learn how Growth Collective can help you build a dynamic team of world-class marketers and tool
                    experts. Below are some answers to the most common questions about working with Growth Collective.
                </Typography>
            </Box>
            <ContentWrp>
                {
                    mdDown ? (
                        <Tabs hasScroll fullWidth>
                            {tabs.map(item => (
                                <Tab
                                    isActive={activeTab === item}
                                    key={item}
                                    tabIndex={activeTab === item ? 0 : undefined}
                                    ref={activeTab === item ? ref : null}
                                    onClick={() => navigate(item)}
                                >
                                    {getTabNameByRoute(item)}
                                </Tab>
                            ))}
                        </Tabs>
                    ) : (
                        <Box
                            sx={{
                                position: 'relative'
                            }}
                        >
                            <AsideMenu list={desktopTabs}/>
                        </Box>
                    )
                }

                <Outlet/>
            </ContentWrp>

            <Box
                sx={{
                    mt: '24px',
                    p: '24px',
                    backgroundColor: theme.palette.white.main,
                    borderRadius: '24px',
                    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                    backdropFilter: 'blur(2px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '24px',
                    [theme.breakpoints.up('md')]: {
                        mt: '64px',
                        justifyContent: 'flex-start',
                        flexDirection: 'row'
                    },
                    'img': {
                        display: 'block',
                        width: '48px',
                        height: '48px',
                    },
                }}
            >
                <img src="/assets/images/message-icon.png" width="48" height="48" alt="message icon"/>
                <Box
                    sx={{
                        textAlign: 'center',
                        [theme.breakpoints.up('md')]: {
                            textAlign: 'left',
                            mr: 'auto'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            mb: '4px'
                        }}
                    >
                        Still have questions?
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.gray.main
                        }}
                    >
                        Can’t find the answer you’re looking for? Please send our friendly team a message
                    </Typography>
                </Box>
                <ContinueButton
                    size="small"
                    sx={{
                        justifyContent: 'center',
                        width: 'auto !important',
                        flexShrink: 0
                    }}
                    variant="contained"
                    onClick={() => navigate(RouteFreelancerPortal.contactUs, {state: {data: {type: EMailType.OTHERS_SUPPORT}}})}
                >
                    Contact us
                </ContinueButton>
            </Box>
        </>
    );
};

function getTabNameByRoute(route: string) {
    switch (route) {
        case GENERAL:
            return 'General';
        case PAYMENT:
            return 'Payment';
        case PROCESS:
            return 'Process';
        case SUPPORT:
            return 'Support';
        default:
            return '';
    }
}

export default React.memo(HelpPage);
