import React from 'react';

import {Box} from '@mui/material';

const ChatMain: React.FC<{ children: React.ReactNode }> = ({children}) => {
    return (
        <Box
            sx={{
                display: 'grid',
                width: '100%',
                gridTemplateRows: '88px auto min-content',
                position: 'relative'
            }}
        >
            {children}
        </Box>
    );
};

export default React.memo(ChatMain);
