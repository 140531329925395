interface IDashboardBase {
    activeEngagements: number;
    monthlyMoney: number;
    totalMatches: number;
    totalMoney: number;
}

export interface IDashboardClient extends IDashboardBase {
    hasAnyPaymentMethodAdded: boolean;
}

export interface IDashboardFreelancer extends IDashboardBase {
    hasAnyPayoutMethodAdded: boolean;
    stripeAccountInitiated: boolean;
}

export type IDashboard = IDashboardClient | IDashboardFreelancer;

export interface IDashboardBanners {
    billingProblem: boolean;
    newMatch: boolean;
    newProposal: boolean;
    requestForPause: string;
    stripeProblem: boolean;
    timesheetForReview: string;
    timesheetInfo: IBannerTimeshhetData;
}

export interface IBannerTimeshhetData {
    engagementId: number;
    timesheetId: number;
    overdue: boolean;
}

export enum EDashboardNotification {
    NEW_MATCH = 'NEW_MATCH',
    NEW_PROPOSAL = 'NEW_PROPOSAL',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    REQUEST_ENGAGEMENT_PAUSE = 'REQUEST_ENGAGEMENT_PAUSE',
    STRIPE_ISSUE_CONNECT = 'STRIPE_ISSUE_CONNECT',
    TIMECARD_WAITING = 'TIMECARD_WAITING',
    TIMESHEET_INFO = 'TIMESHEET_INFO',
}

export enum EBannerType {
    IntegrateStripe = 'integrate-stripe',
    AddPayout = 'add-payout',
    Webinar = 'webinar',
}
