// https://react-dropzone.js.org/

import React, {useCallback, useMemo} from 'react';
import {Accept, FileRejection, useDropzone} from 'react-dropzone';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Avatar, Box, FormControl, FormHelperText, Stack} from '@mui/material';

import {ImgIcon} from '../../../assets/icons';
import theme from '../../../theme';
import {getFileExtansion, stringToColor} from '../../../utils';
import {AnimateHeight, BlackBorderButton, CardSubTitle, RedButton} from '../..';

interface Props {
    accept: Accept;
    alt: string;
    altShort: string;
    disabled?: boolean;
    helperText?: string;
    label?: string;
    name: string;
    previewURL: string;
}

const InputPhoto: React.FC<Props> = ({
    accept,
    alt,
    altShort,
    disabled,
    helperText,
    name,
    label,
    previewURL,
    ...props
}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const files = value;

    const onDrop = useCallback(
        (droppedFiles: File[]) => {
            const withSafeNames = droppedFiles.map((file: File) => {
                const extansion = getFileExtansion(file.name);
                const newName = `avatar-${Date.now()}.${extansion}`;

                return new File([file], newName, {type: file.type});
            });

            onChange(withSafeNames);
            trigger(name);
        },
        [name, onChange, trigger]
    );

    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        onChange([fileRejections[0].file]);
        trigger(name);
    }, [name, onChange, trigger]);

    const {/*isDragActive,*/ getRootProps, getInputProps} = useDropzone({
        accept,
        onDrop,
        onDropRejected,
    });

    const handleDelete = useCallback((event: React.SyntheticEvent) => {
        event.stopPropagation();
        onChange(false);
        trigger(name);
    }, [name, onChange, trigger]);

    // prevent rerender by context change
    const content = useMemo(() => {
        return (
            <FormControl
                error={!!errors[name]}
                fullWidth
            >
                <Stack direction="row" spacing="24px" alignItems="center">
                    <Avatar
                        alt={alt}
                        src={files?.length ? URL.createObjectURL(files[0]) : previewURL}
                        sx={{
                            width: 100,
                            height: 100,
                            fontSize: 45,
                            background: stringToColor(alt),
                            'svg path': {
                                fill: theme.palette.black.main
                            }
                        }}
                    >
                        {altShort || <ImgIcon/>}
                    </Avatar>

                    <Box
                        {...getRootProps()}
                        sx={{
                            mr: 'auto',
                            borderRadius: '10px',
                            pointerEvents: disabled ? 'none' : 'auto',
                            width: '100%'
                        }}>
                        <input
                            {...props}
                            id={name}
                            name={name}
                            {...getInputProps()}
                        />

                        {/*
                        {!isDragActive && 'Click here or drop a file to upload!'}
                        {isDragActive && !isDragReject && "Drop it like it's hot!"}
                        {isDragReject && "File type not accepted, sorry!"}
                    */}

                        <Stack direction="row" flexWrap="wrap" sx={{mb: 2, gap: '8px'}}>
                            <BlackBorderButton
                                disabled={disabled}
                                size="small"
                                variant="contained"
                            >
                                {label || 'Upload photo'}
                            </BlackBorderButton>

                            {
                                (files || previewURL) && (
                                    <RedButton
                                        disabled={disabled}
                                        size="small"
                                        onClick={handleDelete}
                                    >
                                        Delete photo
                                    </RedButton>
                                )
                            }
                        </Stack>

                        <CardSubTitle>{helperText}</CardSubTitle>

                    </Box>
                </Stack>

                <AnimateHeight isVisible={!!(errors[name]?.message)}>
                    <FormHelperText id={`helper-text-${name}`}>
                        {errors[name]?.message?.toString()}
                    </FormHelperText>
                </AnimateHeight>
            </FormControl>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alt, altShort, disabled, errors, helperText, files, name, previewURL, handleDelete, getInputProps, getRootProps]);

    return (
        <Controller
            control={control}
            defaultValue={false}
            name={name}
            render={() => content}
        />
    );
};

export default React.memo(InputPhoto);
