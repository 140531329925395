import {useEffect, useState} from 'react';
import {useMotionValue, useSpring} from 'framer-motion';

export const useAnimProgress = (value: number = 0) => {
    const [progress, setProgress] = useState<string | number>(value);
    const motionValue = useMotionValue(value);

    const spring = useSpring(motionValue, {damping: value > 10000 ? 1000 : 1.5 * value});

    useEffect(() => {
        spring.onChange(latest => setProgress((Math.abs(latest)).toFixed(0)));
        motionValue.set(value);

        return () => spring.destroy();
    }, [motionValue, spring, value]);

    return Number(progress);
};
