import React from 'react';

const DownChevronIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.40831 9.55069C9.48578 9.6288 9.57795 9.6908 9.6795 9.7331C9.78105 9.77541 9.88997 9.79719 9.99998 9.79719C10.11 9.79719 10.2189 9.77541 10.3205 9.7331C10.422 9.6908 10.5142 9.6288 10.5916 9.55069L13.0916 7.05069C13.1715 6.97299 13.2353 6.88032 13.2794 6.77797C13.3235 6.67561 13.347 6.56558 13.3485 6.45415C13.3501 6.34271 13.3296 6.23207 13.2884 6.12853C13.2472 6.02499 13.186 5.93058 13.1083 5.85069C13.0306 5.77081 12.9379 5.707 12.8356 5.66293C12.7332 5.61886 12.6232 5.59538 12.5118 5.59383C12.4003 5.59228 12.2897 5.6127 12.1861 5.65391C12.0826 5.69512 11.9882 5.75633 11.9083 5.83403L9.99998 7.78403L8.09164 5.83403C7.9303 5.67711 7.71324 5.59071 7.48819 5.59383C7.26315 5.59696 7.04856 5.68935 6.89164 5.85069C6.73472 6.01203 6.64832 6.2291 6.65145 6.45415C6.65457 6.67919 6.74697 6.89377 6.90831 7.05069L9.40831 9.55069ZM11.9083 10.4507L9.99998 12.3674L8.09164 10.4507C7.9303 10.2938 7.71324 10.2074 7.48819 10.2105C7.26315 10.2136 7.04856 10.306 6.89164 10.4674C6.73472 10.6287 6.64832 10.8458 6.65145 11.0708C6.65457 11.2959 6.74697 11.5104 6.90831 11.6674L9.40831 14.1674C9.48578 14.2455 9.57795 14.3075 9.6795 14.3498C9.78105 14.3921 9.88997 14.4139 9.99998 14.4139C10.11 14.4139 10.2189 14.3921 10.3205 14.3498C10.422 14.3075 10.5142 14.2455 10.5916 14.1674L13.0916 11.6674C13.2486 11.5104 13.3367 11.2976 13.3367 11.0757C13.3367 10.8538 13.2486 10.6409 13.0916 10.484C12.9347 10.3271 12.7219 10.239 12.5 10.239C12.2781 10.239 12.0652 10.3271 11.9083 10.484V10.4507Z"
                fill="#7E5FFF"/>
        </svg>
    );
};

export default DownChevronIcon;
