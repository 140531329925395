import React, {useCallback, useMemo} from 'react';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {metaService} from '../../../api';
import {GET_TOOLS} from '../../../constants';
import {EModerationStatus, ITool, OptionType} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight, NewToolAlertBox, SelectMulti} from '../..';

interface InputToolsProps {
    name: string;
}

export const InputTools: React.FC<InputToolsProps> = React.memo(({name}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const tools: ITool[] = value;

    const {
        data: toolsData
    } = useQuery([GET_TOOLS], () => metaService.searchTools(''), {
        staleTime: 60 * 60 * 1000,
        select: (response => orderBy(response, 'name'))
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(debounce((newValue: OptionType | null) => {
        if (!newValue) {
            return;
        }

        // don't add duplicate
        if (!tools.find(tool => tool.name.toLowerCase() === newValue.name.toLowerCase())) {
            onChange([...tools, newValue]);
            trigger(name);
        }
    }, 100), [name, tools, onChange, trigger]);

    const handleDelete = useCallback((tool: ITool) => {
        const newTools = tools.filter(t => t.name.toLowerCase() !== tool.name.toLowerCase());

        onChange(newTools);
        trigger(name);
    }, [name, tools, onChange, trigger]);

    const isNewToolExist = useMemo(() => !!tools?.some(tool => !tool.id || (tool.moderationStatus && tool.moderationStatus === EModerationStatus.NEW)), [tools]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <Box tabIndex={0} ref={ref}>
                    <SelectMulti
                        freeSolo
                        options={toolsData || []}
                        placeholder="Ex. HubSpot, MailChimp, ActiveCampaign"
                        pressCaption="tool"
                        selected={tools}
                        onChange={handleChange}
                        onDelete={handleDelete}
                    />

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>

                    <AnimateHeight isVisible={isNewToolExist} isOverflowHidden={false}>
                        <NewToolAlertBox/>
                    </AnimateHeight>
                </Box>
            )}
        />
    );
});
