// axios not good with arrays  ?foo[]=5&foo[]=2&foo[]=11
// but BE waiting for          ?foo=5&foo=2&foo=11
export const formatQueryStringParams = (values: any) => {
    const params = new URLSearchParams();
    const entities = Object.entries(values);

    entities.forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(item => params.append(key, item));
        } else {
            params.append(key, value as string);
        }
    });

    return params;
};

// check is it widget inside iFrame or not
export const isWidget = () => {
    try {
        if (process.env.NODE_ENV === 'development') {
            return location.search.includes('utm_campaign'); // !!localStorage.getItem(WIDGET_ID);
        } else {
            return window.self !== window.top;
        }
    } catch (e) {
        return true;
    }
};
