import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useParams} from 'react-router';
import {object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Theme, useMediaQuery} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {
    BlackBorderButton,
    DialogEmoji,
    DialogSuccess,
    Input,
    InputCaption,
    RedFilledButton,
    showToastError
} from '../../../../../../components';
import {CLIENT_ENGAGEMENT, TIMESHEETS} from '../../../../../../constants';

const MAX_REASON_LENGTH = 1000;

const reasonSchema = object({
    reason: string()
        .min(0)
        .max(MAX_REASON_LENGTH)
});

type ReasonInput = TypeOf<typeof reasonSchema>;

type DialogRejectProps = {
    open: boolean;
    timesheetId: number;
    onClose: (isNeedUpdate: boolean) => void;
};

const DialogReject: React.FC<DialogRejectProps> = ({open, timesheetId, onClose}) => {
    const {engagementId} = useParams();
    const [openSuccess, setOpenSuccess] = useState(false);

    const queryClient = useQueryClient();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const methods = useForm<ReasonInput>({
        resolver: zodResolver(reasonSchema),
    });

    const {
        handleSubmit,
        // setError,
        reset,
        watch,
    } = methods;

    const reason = watch('reason', '');

    const {mutate, isLoading: isSubmitting} = useMutation(
        (reason: string) => clientService.timesheetsReject(timesheetId, reason),
        {
            onSuccess: async () => {
                onClose(false);
                setTimeout(() => {
                    setOpenSuccess(true);
                }, 500);
            },
            onError(error: AxiosError) {
                showToastError(error);
            }
        }
    );

    const handleRejectedClose = useCallback(() => {
        setOpenSuccess(false);
        queryClient.invalidateQueries([CLIENT_ENGAGEMENT, Number(engagementId), TIMESHEETS]);
    }, [engagementId, queryClient]);

    const onSubmitHandler: SubmitHandler<ReasonInput> = (values) => {
        mutate(values.reason);
    };

    useEffect(() => {
        reset({});
    }, [open, reset]);

    return (
        <>
            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            disabled={isSubmitting}
                            fullWidth={mdDown}
                            onClick={() => onClose(false)}
                        >
                            Cancel
                        </BlackBorderButton>

                        <RedFilledButton
                            fullWidth={mdDown}
                            disabled={isSubmitting}
                            sx={{width: mdDown ? '100%' : 'auto !important'}}
                            variant="contained"
                            onClick={handleSubmit(onSubmitHandler)}
                        >
                            Reject timesheet
                        </RedFilledButton>
                    </>
                )}
                maxWidth="sm"
                subtitle="Please specify a reason for rejecting this timesheet"
                title={(
                    <>
                        <img src="/assets/images/cross-icon.png" width="48" height="48" alt="sad icon"/>
                        Reject this timesheet?
                    </>
                )}
                open={open}
                onClose={() => onClose(false)}
            >
                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                    >

                        <InputCaption
                            sx={{
                                mb: '16px !important',
                                textAlign: 'left !important',
                            }}
                        >
                            Comment for a freelancer
                        </InputCaption>
                        <Input
                            counter={MAX_REASON_LENGTH - (reason?.length || 0)}
                            disabled={isSubmitting}
                            inputProps={{maxLength: MAX_REASON_LENGTH}}
                            minRows={5}
                            multiline
                            name="reason"
                            placeholder="Describe briefly why you’re rejecting this timesheet"
                            variant="outlined"
                        />
                    </form>
                </FormProvider>

            </DialogEmoji>

            {/* Success rejected */}
            <DialogSuccess
                open={openSuccess}
                title="You have successfully rejected the timesheet"
                onClose={handleRejectedClose}
            />
        </>
    );
};

export default DialogReject;
