import React, {FC} from 'react';
import {any, string} from 'zod';

import {Grid} from '@mui/material';

import {CardTitle, Input, Location} from '../../../../../../components';
import {ISelectOption} from '../../../../../../models';

import {stylesInput} from './DialogPaymentMethod';

export const billingAddressSchema = {
    country: any()
        .refine(value => !!value, 'Country is required'),
    city: string()
        .trim()
        .min(1, 'City is required')
        .max(100, 'Value is too long'),
    line1: string()
        .trim()
        .min(1, 'Address is required')
        .max(100, 'Value is too long'),
    line2: string(),
    postalCode: string()
        .trim()
        .min(1, 'ZIP or Postal Code is required')
        .max(100, 'Value is too long'),
    state: string()
        .trim()
        .min(1, 'State is required')
        .max(100, 'Value is too long'),
};

interface IProps {
    optionsCountry?: ISelectOption[];
}

const BillingAddressForm: FC<IProps> = ({optionsCountry}) => {
    return (
        <Grid container spacing="24px" sx={{mt: '16px', mb: '40px'}}>
            <Grid item xs={12}>
                <CardTitle>Add a billing address</CardTitle>
            </Grid>
            {/* <Grid item xs={12}>
                <Input
                    label="Company Name"
                    name="companyName"
                    sx={stylesInput}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label="Full Name"
                    name="fullName"
                    sx={stylesInput}
                    variant="outlined"
                />
            </Grid> */}
            <Grid item xs={12}>
                <Input
                    label="Street Address Line 1"
                    name="line1"
                    sx={stylesInput}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label="Street Address Line 2"
                    name="line2"
                    sx={stylesInput}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    label="City"
                    name="city"
                    sx={stylesInput}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    label="State or province"
                    name="state"
                    sx={stylesInput}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    label="ZIP or Postal Code"
                    name="postalCode"
                    sx={stylesInput}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Location
                    labelText="Country"
                    name="country"
                    options={optionsCountry || []}
                    placeholder=" "
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            padding: '7px',
                            // background: 'red',
                        }
                    }}
                    variant="outlined"
                />
            </Grid>
            {/* <Grid item xs={12}>
                <Input
                    label="Other"
                    name="other"
                    sx={stylesInput}
                    variant="outlined"
                />
            </Grid> */}
        </Grid>
    );
};

export default React.memo(BillingAddressForm);
