import React, {useCallback} from 'react';
import {AnimatePresence, motion} from 'framer-motion';

import {Box} from '@mui/material';

import {EModerationStatus} from '../../models';
import {animationChips} from '../Animations';
import {Chip} from '..';

import Avatar from './Avatar';

interface IItem {
    id?: number;
    moderationStatus?: EModerationStatus;
    name: string;
    logo?: string;
}

interface IProps {
    avatarProperty?: string;
    items: IItem[];
    selectedVerticals?: IItem[];
    onAdd?: (tool: IItem) => void;
    onDelete?: (tool: IItem) => void;
}

const ChipsList: React.FC<IProps> = ({avatarProperty, items, selectedVerticals, onAdd, onDelete}) => {
    const handleClick = useCallback((item: IItem) => () => {
        if (onAdd) {
            onAdd(item);
        } else if (onDelete) {
            onDelete(item);
        }
    }, [onAdd, onDelete]);

    const getVariant = (item: IItem) => {
        if (item.id && (!item.moderationStatus || item.moderationStatus === EModerationStatus.APPROVED)) {
            return 'active';
        } else {
            return 'filled';
        }
    };

    const checkIfSelected = (item: IItem) => {
        return selectedVerticals?.some(selectedVertical => selectedVertical.id === item.id);
    };

    return (
        <Box display="flex" flexWrap="wrap">
            <AnimatePresence>
                {items.map((item, i) => (
                    <Box
                        {...animationChips(i)}
                        component={motion.div}
                        key={item.id || item.name}
                        onClick={handleClick(item)}
                    >

                        {/* used for Verticals Suggestions only */}
                        {
                            onAdd && (
                                <Chip
                                    label={item.name}
                                    sx={{mb: 2, mr: 2}}
                                    isSelected={checkIfSelected(item)}
                                    variant='default'
                                    onDelete={() => onAdd(item)}
                                />
                            )
                        }

                        {/* used for Brands and Verticals lists */}
                        {
                            !onAdd && onDelete && (
                                <Chip
                                    avatar={avatarProperty ? <Avatar url={(item as any)[avatarProperty]}/> : undefined}
                                    label={item.name}
                                    sx={{mb: 2, mr: 2}}
                                    variant={getVariant(item)}
                                    onDelete={() => onDelete(item)}
                                />
                            )
                        }
                    </Box>
                ))}
            </AnimatePresence>
        </Box>
    );
};

export default React.memo(ChipsList);
