import {EWorkWeekStatus} from '../../models';

export const getVariantByStatus = (status: string) => {
    switch (status) {
        case EWorkWeekStatus.DRAFT:
            return 'blue';
        case EWorkWeekStatus.SENT:
            return 'gray';
        case EWorkWeekStatus.APPROVED:
            return 'green';
        case EWorkWeekStatus.REJECTED:
        case EWorkWeekStatus.REJECTED_DRAFT:
            return 'red';
        default:
            return 'gray';
    }
};
