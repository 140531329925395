import React from 'react';

import {Avatar, Box, Menu, MenuItem, Typography} from '@mui/material';
import {PopoverProps} from '@mui/material/Popover';

import {SignOutIcon} from '../../assets/icons';
import theme from '../../theme';
import {stringToColor} from '../../utils';

import {IMenuItem} from './HeaderPortal';

const styleMenuItem = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    margin: 0,
    padding: '16px !important',
    color: theme.palette.textGray.dark,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    transition: 'color .3s',
    backgroundColor: 'unset',
    minWidth: 'unset',
    'svg': {
        flexShrink: 0,
        width: '24px',
        height: '24px'
    },
    'path': {
        fill: theme.palette.gray.main,
        transition: 'fill .3s'
    },
    '&:hover': {
        color: theme.palette.black.main,
        backgroundColor: theme.palette.lightGray.light + '!important',
        'path': {
            fill: theme.palette.purple.main
        }
    },
};

interface IProps {
    anchorEl: PopoverProps['anchorEl'];
    avatarSrc?: string;
    email?: string;
    fullName: string;
    open: boolean;
    profileListItems: IMenuItem[];
    shortName: string;
    switchItems?: React.ReactNode[];
    onClose: PopoverProps['onClose'];
    onItemClick: (event: React.MouseEvent<HTMLElement>, item: IMenuItem) => void;
    onLogout: (event: React.MouseEvent<HTMLElement>) => void;
}

const HeaderDropdownMenu: React.FC<IProps> = ({
    anchorEl,
    avatarSrc,
    email,
    fullName,
    open,
    profileListItems,
    shortName,
    switchItems,
    onClose,
    onItemClick,
    onLogout
}) => {
    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            sx={{
                '.MuiPaper-root': {
                    mt: '24px',
                },
                '& ul': {
                    minWidth: '170px',
                    maxWidth: '300px',
                    maxHeight: switchItems ? '448px !important' : '308px !important',
                    p: '0 !important',
                    borderColor: theme.palette.lightGray.main + '!important'
                }
            }}
            onClose={onClose}
        >
            <MenuItem
                sx={{
                    pointerEvents: 'none',
                    borderBottom: `1px solid ${theme.palette.lightGray.main}`,
                    ...styleMenuItem
                }}
            >
                <Avatar
                    alt={fullName}
                    src={avatarSrc}
                    sx={{
                        width: '48px',
                        height: '48px',
                        background: stringToColor(fullName),
                        fontSize: '20px',
                        lineHeight: 1.5,
                        fontWeight: 500,
                        'img': {
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            aspectRatio: '1/1'
                        }
                    }}
                >
                    {shortName}
                </Avatar>
                <Box
                    sx={{
                        minWidth: 0
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                        title={fullName}
                    >
                        {fullName}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            color: theme.palette.gray.main,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                        title={email}
                    >
                        {email}
                    </Typography>
                </Box>

            </MenuItem>
            {
                profileListItems.map((item, index) => (
                    <MenuItem
                        key={item.text}
                        divider={profileListItems.length - 1 === index}
                        sx={{
                            ...styleMenuItem,
                        }}
                        onClick={event => onItemClick(event, item)}
                    >
                        {item.icon}
                        {item.text}
                    </MenuItem>
                ))
            }
            {
                switchItems && switchItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        divider={index === 1}
                        sx={{
                            ...styleMenuItem,
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                'label': {
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    gap: '8px',
                                    ml: 0
                                }
                            }}
                        >
                            {item}
                        </Box>
                    </MenuItem>
                ))
            }
            <MenuItem
                sx={styleMenuItem}
                onClick={onLogout}
            >
                <SignOutIcon/>
                Sign Out
            </MenuItem>
        </Menu>
    );
};

export default React.memo(HeaderDropdownMenu);
