import React from 'react';
import {AxiosError} from 'axios';

import {Box, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {clientService} from '../../../../../../../api';
import {ContinueButton, DialogEmoji, RedFilledButton, showToastError} from '../../../../../../../components';
import {IEngagementClient} from '../../../../../../../models';
import theme from '../../../../../../../theme';
import {formatDate} from '../../../../../../../utils';

type DialogConfirmRequestProps = {
    data: IEngagementClient;
    open: boolean;
    onClose: (isNeedUpdate: boolean) => void;
};

const DialogConfirmRequest: React.FC<DialogConfirmRequestProps> = ({data, open, onClose}) => {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const {mutate, isLoading: isSubmitting} = useMutation(
        (isConfirm: boolean) => isConfirm ? clientService.pauseConfirm(data.id) : clientService.pauseReject(data.id),
        {
            onSuccess() {
                onClose(true);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    return (
        <DialogEmoji
            actions={(
                <>
                    <RedFilledButton
                        disabled={isSubmitting}
                        sx={{
                            width: mdDown ? '50%' : 'auto'
                        }}
                        onClick={() => mutate(false)}
                    >
                        Reject
                    </RedFilledButton>
                    <ContinueButton
                        disabled={isSubmitting}
                        variant="contained"
                        sx={{
                            width: mdDown ? '50% !important' : 'auto !important',
                            'div': {
                                justifyContent: 'center'
                            }
                        }}
                        onClick={() => mutate(true)}
                    >
                        Confirm
                    </ContinueButton>
                </>
            )}
            maxWidth="sm"
            open={open}
            title={(
                <>
                    <Box justifyContent="center">
                        <img src="/assets/images/done-icon.png" width="48" height="48" alt="ok icon"/>
                    </Box>
                    Confirm {data.freelancer.firstName}’s pause engagement request
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.textGray.dark
                        }}
                    >
                        {data.freelancerName} asked to pause the engagement
                        from {formatDate(data.pause.startDate)} {data.pause.endDate ? ` to ${formatDate(data.pause?.endDate)}` : ''} due
                        to {data.pause?.reason}. Please confirm or reject this pause.
                    </Typography>
                </>
            )}
            onClose={() => onClose(false)}
        >
        </DialogEmoji>
    );
};

export default React.memo(DialogConfirmRequest);
