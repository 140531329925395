import {EChannel} from './notification.model';

export enum ERecipientType {
    FREELANCER = 'FREELANCER',
    CLIENT = 'CLIENT',
    ADMIN = 'ADMIN',
}

export enum EEventType {
    APPLICANT_REGISTERED = 'APPLICANT_REGISTERED',
    APPLICATION_ACCEPTED = 'APPLICATION_ACCEPTED',
    APPLICATION_REJECTED = 'APPLICATION_REJECTED',
    APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',
    APPLICATION_DROPPED = 'APPLICATION_DROPPED',
    CLIENT_REGISTERED = 'CLIENT_REGISTERED',
    ROLE_SUBMITTED = 'ROLE_SUBMITTED',
    ROLE_DROPPED = 'ROLE_DROPPED',
}

export interface IEventType {
    channels: EChannel[];
    delayed: boolean;
    dynamicParameters: string[];
    immediate: boolean;
    source: EEventSource;
    type: EEventType;
}

export enum EEventSource {
    APPLICANT = 'APPLICANT',
    FREELANCER = 'FREELANCER',
    ADMINISTRATOR = 'ADMINISTRATOR',
    CLIENT = 'CLIENT',
    OTHER = 'OTHER'
}
