import {useNavigate} from 'react-router';
import type {NavigateOptions} from 'react-router/dist/lib/context';
import type {NavigateFunction} from 'react-router/dist/lib/hooks';

import type {To} from '@remix-run/router';

import {utm} from '../utils';

export function useNavigateUTM(): NavigateFunction {
    const parent = useNavigate();

    return (to: To | number, options?: NavigateOptions): void => {
        if (typeof to === 'number') {
            return parent(to);
        }
        if (utm.empty) {
            return parent(to, options);
        }
        if (typeof to === 'string') {
            return parent(utm.patchUri(to), options);
        }
        if (!to.search) {
            return parent(to, options);
        }
        to.search = utm.addParameters(new URLSearchParams(to.search)).toString();

        return parent(to, options);
    };
}
