import React from 'react';

import {TOKEN_KEY} from '../api';
import {DocIcon, ImgIcon, PdfIcon} from '../assets/icons';
import {ACCEPTED_IMAGE_TYPES} from '../constants';

export const downloadFile = async (path: string, fileName: string) => {
    if (!path) return;

    const image = await fetch(path);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');

    link.href = imageURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadFileWithToken = (url: string, fileName: string) => {
    const authTokens = localStorage.getItem(TOKEN_KEY);
    const anchor = document.createElement('a');
    const headers = new Headers();

    document.body.appendChild(anchor);
    headers.append('Authorization', 'Bearer ' + authTokens);

    return fetch(url, {headers})
        .then(response => response.blob())
        .then(blobby => {
            const objectUrl = window.URL.createObjectURL(blobby);

            anchor.href = objectUrl;
            anchor.download = fileName;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
            document.body.removeChild(anchor);
        });
};

export const getAcceptedExtentions = (values: { [key: string]: string[] }) => Object.values(values)
    .reduce((arr: string[], extensions) => {
        arr.push(...extensions);

        return arr;
    }, []);

export const getAcceptedTypes = (values: { [key: string]: string[] }) => Object.keys(values).join(', ');

export const getCoverImagePath = (themeName: string, variant: number, breakpoints?: {lgUp: boolean; mdUp: boolean; xsUp: boolean}) => {
    const base = `${process.env.REACT_APP_S3_PUBLIC_BUCKET_URL}/case_study_covers/${themeName}/${variant}`;

    // if it is preview
    if (!breakpoints) {
        return `${base}/preview_img.png`;
    } else if (breakpoints.lgUp) {
        return `${base}/desktop_img.png`;
    } else if (breakpoints.mdUp) {
        return `${base}/tablet_img.png`;
    } else if (breakpoints.xsUp) {
        return `${base}/mobile_img.png`;
    }

    return `${base}/desktop.png`;
};

export const getFileExtansion = (fileName: string) => fileName.split('.').pop();

export const getFileTypeIcon = (fileName: string) => {
    const ext = `.${(getFileExtansion(fileName) || '').toLowerCase()}`;

    if (ext === '.pdf') {
        return <PdfIcon/>;
    } else if (Object.values(ACCEPTED_IMAGE_TYPES).find(exts => exts.includes(ext))) {
        return <ImgIcon/>;
    } else {
        return <DocIcon/>;
    }
};

export const getReadableFileSizeString = (fileSizeInBytes: number): string => {
    let i = -1;
    const byteUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    do {
        fileSizeInBytes /= 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

// {fileName}...{[0-3].ext} -> Microsoft_2023_Cert...te.pdf
export const getFileName = (fileName: string = '', max = 50) => {
    const suffixLength = 7;
    const name = fileName.split('/').pop() || '';
    const extPlusSuffix = fileName.substring(fileName.length - suffixLength);

    return name.length - suffixLength > max ? (name.substring(0, max) + '...' + extPlusSuffix) : name;
};

export const isImage = (fileName: string) => {
    const ext = (getFileExtansion(fileName) || '').toLowerCase();

    return !!Object.values(ACCEPTED_IMAGE_TYPES).find(exts => exts.includes(`.${ext}`));
};
