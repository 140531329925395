import React, {FC, useCallback, useMemo} from 'react';
import {AxiosError} from 'axios';
import sortBy from 'lodash/sortBy';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {Box, styled, Theme, useMediaQuery} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {timesheetsService} from '../../../../../api';
import {LoadingMask, showToastError, TimesheetStatusTitle, TimesheetWrapper, WeekEmpty} from '../../../../../components';
import {FREELANCER_ENGAGEMENT, LG, RouteFreelancerPortal, TIMESHEETS} from '../../../../../constants';
import {EEngagementStatus, EWorkWeekStatus, IEngagementFreelancer} from '../../../../../models';
import {EDisplayMode} from '../../MyWorkDetailsPage';
import {InfoBox} from '../TabOverview/components';

import {WeekForm, WeekView} from './components';

const rejectedStatuses = [EWorkWeekStatus.REJECTED, EWorkWeekStatus.REJECTED_DRAFT];

interface IProps {
    data: IEngagementFreelancer;
}

const Wrp = styled('div')`
  @media (min-width: ${LG}) {
    display: flex;
    gap: 24px;
    align-items: flex-start;
  }
`;
const TabTimesheets: FC<IProps> = ({data}) => {
    const {engagementId, timesheetId} = useParams();
    const [searchParams] = useSearchParams();

    const autoFocusId = Number(timesheetId) || Number(searchParams.get('id'));

    const navigate = useNavigate();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const {
        isLoading,
        data: timesheets,
        refetch,
    } = useQuery([FREELANCER_ENGAGEMENT, Number(engagementId), TIMESHEETS], timesheetsService.getTimesheets(Number(engagementId)), {
        enabled: !!engagementId,
        staleTime: 5 * 60 * 1000,
        select: (response) => sortBy(response, 'id'),
        onError: (error) => showToastError(error as AxiosError)
    });

    const itemsDraft = useMemo(() => timesheets?.filter(item => item.status === EWorkWeekStatus.DRAFT) || [], [timesheets]);
    const itemsRejected = useMemo(() => timesheets?.filter(item => rejectedStatuses.includes(item.status as EWorkWeekStatus)) || [], [timesheets]);
    const itemsRecentlySubmitted = useMemo(() => timesheets?.filter(item => item.status === EWorkWeekStatus.SENT) || [], [timesheets]);
    const itemsApproved = useMemo(() => timesheets?.filter(item => item.status === EWorkWeekStatus.APPROVED) || [], [timesheets]);

    const handleMessageClick = useCallback(() => {
        navigate(`${RouteFreelancerPortal.myWorkDetails}/${data.id}/${EDisplayMode.Messages}`);
    }, [data.id, navigate]);

    return (
        <Wrp>
            {isLoading && <LoadingMask/>}

            <Box sx={{width: '100%'}}
            >
                {/* Empty week for Paused */}
                {!isLoading && ([EEngagementStatus.PAUSED, EEngagementStatus.PENDING].includes(data.status) || !timesheets?.length) && (

                    <TimesheetWrapper>
                        <WeekEmpty
                            data={{
                                isOverdue: false,
                                fromDate: data.pause?.startDate || data.startDate,
                                toDate: data.pause?.endDate || '',
                                status: EWorkWeekStatus.DRAFT,
                                money: 0,
                                workDays: [],
                                workTime: ''
                            }}
                            isPaused={data.status === EEngagementStatus.PAUSED}
                            isPending={data.status === EEngagementStatus.PENDING}
                        />
                    </TimesheetWrapper>
                )}

                {/* Current week */}
                <TimesheetWrapper>
                    {itemsDraft.map(item => <WeekForm data={item} key={item.id} isAutoFocus={autoFocusId === item.id} onRefetch={refetch}/>)}
                </TimesheetWrapper>

                {/* Rejected */}
                {itemsRejected?.length ? (
                    <TimesheetWrapper>
                        <TimesheetStatusTitle status={EWorkWeekStatus.REJECTED}/>
                        {itemsRejected.map(item => <WeekForm data={item} key={item.id} isAutoFocus={autoFocusId === item.id} onRefetch={refetch}/>)}
                    </TimesheetWrapper>
                ) : null}

                {/* Recently Submitted */}
                {itemsRecentlySubmitted?.length ? (
                    <TimesheetWrapper>
                        <TimesheetStatusTitle status={EWorkWeekStatus.SENT}/>
                        {itemsRecentlySubmitted.map(item => <WeekView data={item} key={item.id} isAutoFocus={autoFocusId === item.id}/>)}
                    </TimesheetWrapper>
                ) : null}

                {/* Approved */}
                {itemsApproved?.length ? (
                    <TimesheetWrapper>
                        <TimesheetStatusTitle status={EWorkWeekStatus.APPROVED}/>
                        {itemsApproved.map(item => <WeekView data={item} key={item.id} isAutoFocus={autoFocusId === item.id}/>)}
                    </TimesheetWrapper>
                ) : null}
            </Box>

            {!isLoading && lgUp && (
                <Box
                    sx={{
                        width: '454px',
                        flexShrink: 0
                    }}
                >
                    <InfoBox
                        data={data}
                        isCompany={true}
                        onMessage={handleMessageClick}
                    />
                </Box>
            )}
        </Wrp>
    );
};

export default React.memo(TabTimesheets);
