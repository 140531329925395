import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import {Avatar, Typography} from '@mui/material';

import {TwilioContext} from '../../../../contexts';
import theme from '../../../../theme';
import {getNameLetters, stringToColor} from '../../../../utils';

import {ChatOngoingCallBox} from './ChatOngoingCallBox';


const ActiveCallListItem = () => {
    const {activeCall, duration, setIsCompact} = useContext(TwilioContext);

    const navigate = useNavigate();

    const handleClick = () => {
        if (!activeCall) return;
        navigate(activeCall.pathname);
        setIsCompact(false);
    };

    return (
        <ChatOngoingCallBox
            onClick={handleClick}
        >
            <p>
                Video call with {activeCall?.remoteParticipant.name.split(' ')[0]}
            </p>
            <Typography
                sx={{
                    fontWeight: 300,
                    mr: 'auto',
                    p: '4px 8px',
                    width: 'max-content',
                    borderRadius: '30px',
                    textAlign: 'center',
                    color: theme.palette.white.main,
                    backgroundColor: theme.palette.black.main
                }}
            >
                {duration}
            </Typography>

            <Avatar
                alt={activeCall?.localParticipant.name}
                src={activeCall?.localParticipant.avatar}
                sx={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: stringToColor(activeCall?.localParticipant.name || ''),
                    fontSize: '11px',
                    lineHeight: 1.2,
                    fontWeight: 500,
                    'img': {
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        aspectRatio: '1/1'
                    }
                }}
            >
                {getNameLetters(activeCall?.localParticipant.name || '')}
            </Avatar>

            <Avatar
                alt={activeCall?.remoteParticipant.name}
                src={activeCall?.remoteParticipant.avatar}
                sx={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: stringToColor(activeCall?.remoteParticipant.name || ''),
                    fontSize: '11px',
                    lineHeight: 1.2,
                    fontWeight: 500,
                    'img': {
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        aspectRatio: '1/1'
                    }
                }}
            >
                {getNameLetters(activeCall?.remoteParticipant.name || '')}
            </Avatar>

        </ChatOngoingCallBox>
    );
};

export default React.memo(ActiveCallListItem);
