import React from 'react';

import {FaqList} from '../../../../components';

const paymentQuestions = [
    {
        title: 'How do I receive payments for client work?',
        text: 'Once you accept your first project with Growth Collective, you will be invited to our time-tracking and payments platform.'
    },
    {
        title: 'How much will I get paid?',
        text: 'As an expert on our platform, you set your own hourly rate. Most rates range from $50-$200/hr depending on the type of work and your level of experience. You are also able to offer retainer or flat rate pricing.'
    },
    {
        title: 'What types of billing structures do you support?',
        text: 'We support hourly, retainer or flat rate agreements.'
    },
    {
        title: 'How much does GC take?',
        text: 'Growth Collective earns 20% for handling lead generation, sales, legal, time-tracking, billing, payments and 1099 compliance. For clients that we connect you with, you are required to keep all work on-platform.\n' +
            '\n' +
            'We are not an open, self-service platform like Upwork or Fiverr. We have an internal team that personally vets every member of the network and we hand-match every client with the right experts. This process requires more effort from us but means you don’t have to slash your rates to find work with Growth Collective.'
    }
];

const PaymentTab = () => {
    return (
        <>
            <FaqList list={paymentQuestions}/>
        </>
    );
};

export default React.memo(PaymentTab);
