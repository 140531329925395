import React from 'react';

import PageTopTitle from '../PageTopTitle';

interface IProps {
    desktopFontSize?: number;
}

export const BudgetTopTitle: React.FC<IProps> = React.memo(({desktopFontSize}) => {
    return (
        <PageTopTitle
            desktopFontSize={desktopFontSize}
            title={<>What <span>type of engagement</span> and <span>level of commitment</span> do you expect?</>}
        />
    );
});
