import React, {useCallback, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {Container, styled} from '@mui/material';

import {RouteCreateApplicantAccount, RouteCreateClientAccount, SM} from '../../constants';
import {useAuth} from '../../hooks';
import theme from '../../theme';
import WhiteButton from '../Button/WhiteButton';
import {BackButton, Portal} from '../index';

interface IMainContent {
    children: React.ReactNode;
    isMobileDark?: boolean;
}

const MainWrp = styled('main')<{ isAuthenticated: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: ${theme.palette.black.main};
  min-height: ${props => props.isAuthenticated ? 'unset' : 'calc(100vh - 92px)'};

  @media (min-width: ${SM}) {
    min-height: unset;
    height: 100vh;
    padding-bottom: 0;
    overflow-y: auto;
    background-color: ${theme.palette.white.main};
  }
`;

const MainContent = ({children, isMobileDark}: IMainContent) => {
    const {isApplicant, isClientUnverified, isAuthenticated} = useAuth();
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const backBtnVisible = useMemo(() => {
        let isFirstPage: boolean = true;
        let isSkipPage: boolean = true;

        if (isApplicant) {
            isFirstPage = pathname === RouteCreateApplicantAccount.experience;
            isSkipPage = pathname === RouteCreateApplicantAccount.signup;
        } else if (isClientUnverified) {
            isFirstPage = pathname === RouteCreateClientAccount.channels;
            isSkipPage = pathname === RouteCreateClientAccount.signup || pathname === RouteCreateClientAccount.completed;
        }

        return !isFirstPage && !isSkipPage;
    }, [isApplicant, isClientUnverified, pathname]);

    const handleBackMove = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <MainWrp isAuthenticated={isAuthenticated}>
            {
                backBtnVisible && (<>
                    <BackButton
                        sx={{
                            width: 0,
                            height: 0,
                            '& > div': {
                                top: '80px !important',
                                left: '40px !important'
                            },
                            [theme.breakpoints.down('sm')]: {
                                display: 'none'
                            }
                        }}
                        onClick={handleBackMove}
                    />
                </>)
            }
            <Container maxWidth="lg" sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: isMobileDark ? theme.palette.black.main : theme.palette.white.main,
                borderRadius: isMobileDark ? 'inset' : '24px 24px 0 0',
                paddingTop: isMobileDark ? 0 : '40px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingBottom: isAuthenticated ? '120px' : '0',
                [theme.breakpoints.up('sm')]: {
                    margin: 'auto',
                    paddingBottom: 0,
                    backgroundColor: 'unset',
                }
            }}>
                {children}
                {backBtnVisible && (
                    <Portal order={1}>
                        <WhiteButton
                            fullWidth
                            onClick={handleBackMove}
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    display: 'none'
                                }
                            }}
                        >
                            Back
                        </WhiteButton>
                    </Portal>
                )}
            </Container>
        </MainWrp>
    );
};

export default MainContent;
