import {
    GET_BRANDS, GET_BUSINESS_MODELS,
    GET_CHANNELS,
    GET_LOCATIONS,
    GET_TOOLS, GET_VERTICALS,
} from '../constants';
import {IBrand, IChannel, ICountry, ITool, IVerticalsResponse} from '../models';

import axiosInstance from './base.api';

const getBusinessModels = async () => {
    const response = await axiosInstance.get<{businessModels: string[]}>(GET_BUSINESS_MODELS);

    return response.data;
};

const getChannels = async () => {
    const response = await axiosInstance.get<IChannel[]>(GET_CHANNELS);

    return response.data;
};

const getLocations = async () => {
    const response = await axiosInstance.get<{countries: ICountry[]; languages: string[]}>(GET_LOCATIONS);

    return response.data;
};

const getVerticals = async () => {
    const response = await axiosInstance.get<IVerticalsResponse>(GET_VERTICALS);

    return response.data;
};

const searchBrands = async (q: string) => {
    const response = await axiosInstance.get<{ brands: IBrand[] }>(GET_BRANDS, {params: {q}});

    return response.data.brands;
};

const searchTools = async (q: string) => {
    const response = await axiosInstance.get<{tools: ITool[]}>(GET_TOOLS, {params: {q}});

    return response.data.tools;
};

export const metaService = {
    getBusinessModels,
    getChannels,
    getLocations,
    getVerticals,
    searchBrands,
    searchTools,
};
