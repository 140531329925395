import React, {useEffect, useMemo, useState} from 'react';
import {motion, useAnimation} from 'framer-motion';

import {Box, styled, Typography} from '@mui/material';

import {CheckboxIcon} from '../../../../assets/icons';
import {ProgressBar} from '../../../../components';
import {IProfile} from '../../../../models';
import theme from '../../../../theme';

const APPLICATION_PROFILE_FILLED = 30;

const Wrp = styled('div')`

`;

const InnerWrp = styled('div')`
  max-width: 300px;
  min-width: 100%;
  padding: 24px;
  position: relative;
  background-color: ${theme.palette.white.main};
  border-radius: 24px;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);

  @media (min-width: 1400px) {
    width: 365px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(153.6deg, rgba(255, 255, 255, 0) 9.37%, rgba(126, 95, 255, 0.22) 100%);
    filter: drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.03));
    border-radius: 24px;
    pointer-events: none;
  }
`;

const CheckList = styled('ul')`
  list-style: none;
  margin: 0;
  margin-top: 24px;
  padding: 0;
`;

const CheckItem = styled('li')<{ checked?: boolean }>`
  display: flex;
  padding-bottom: 18px;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: ${props => props.checked ? theme.palette.purple.main : theme.palette.gray.main};
    transition: fill .3s;
  }

  p {
    font: inherit;
    line-height: inherit;
    color: ${props => props.checked ? theme.palette.black.main : theme.palette.gray.main};
    transition: color .3s;
  }

  span {
    margin-left: auto;
    font: inherit;
    line-height: inherit;
    color: ${theme.palette.gray.main};
  }
`;

interface IProps {
    data: IProfile;
    bio?: string;
    photo?: string;
}

export const Gamification: React.FC<IProps> = React.memo(({data, bio, photo}) => {
    const [progress, setProgress] = useState(0);
    const animControls = useAnimation();

    const caseStudiesPublished = data?.caseStudies.filter(it => it.publicVersion) || [];
    const caseStudies = caseStudiesPublished.length >= 2 ? 30 : caseStudiesPublished.length === 1 ? 15 : 0;
    const references = data.acceptedReferencesCount >= 1 ? 20 : 0;
    const certificates = data.acceptedCertificatesCount >= 1 ? 10 : 0;
    const profilePhoto = photo ? 5 : 0;
    const hasBio = bio && bio.length > 0 ? 5 : 0;

    const sum = APPLICATION_PROFILE_FILLED + caseStudies + references + certificates + profilePhoto + hasBio;

    const checkList = [
        {
            checked: true,
            title: 'Application profile',
            value: '30%'
        },
        {
            checked: caseStudiesPublished.length >= 2,
            title: 'Case studies',
            value: caseStudies + '%'
        },
        {
            checked: !!references,
            title: 'References',
            value: '20%'
        },
        {
            checked: !!certificates,
            title: 'Certificates',
            value: '10%'
        },
        {
            checked: !!profilePhoto,
            title: 'Profile photo',
            value: '5%'
        },
        {
            checked: !!hasBio,
            title: 'Bio',
            value: '5%'
        }
    ];

    useEffect(() => {
        animControls.start({
            height: sum,
            transition: {duration: 0.5, ease: 'easeInOut'},
        });
    }, [animControls, sum]);

    const renderText = useMemo(() => {
        if (!bio && !photo) {
            return 'Add your photo and short bio to make your profile even more awesome!';
        } else if (!bio) {
            return 'Add your short bio to make your profile even more awesome!';
        } else if (!photo) {
            return 'Add your photo to make your profile even more awesome!';
        } else {
            return null;
        }
    }, [bio, photo]);

    return (
        <Wrp>
            <InnerWrp>
                <Box
                    sx={{
                        position: 'relative',
                        zIndex: 5
                    }}
                >
                    <Box
                        sx={{mb: '24px', overflow: 'hidden'}}
                    >
                        <Box
                            sx={{
                                width: '64px',
                                height: '64px',
                                mt: '10px',
                                float: 'right',
                                'img': {
                                    display: 'inline-block',
                                    width: 'auto',
                                    height: 'auto',
                                    maxWidth: '100%'
                                }
                            }}
                        >
                            <img
                                src="/assets/images/star-eyes-emoji.png"
                                width="64"
                                height="64"
                                alt="star eyes emoji"
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: 1.5,
                                color: theme.palette.black.main
                            }}
                        >
                            <Typography
                                animate={animControls}
                                component={motion.span}
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '64px',
                                    lineHeight: 1.3,
                                    letterSpacing: '-0.04em',
                                    color: theme.palette.purple.main
                                }}
                                variant="caption"
                                onUpdate={latest => setProgress(Math.round(+latest.height))}
                            >
                                {progress}%
                            </Typography>
                            <br/>
                            of your profile is complete!
                        </Typography>
                    </Box>
                    <ProgressBar
                        progress={progress}
                        isLight
                        displaySmUp="block"
                        height={8}
                    />
                    <CheckList>
                        {
                            checkList.map(item =>
                                <CheckItem
                                    key={item.title}
                                    checked={item.checked}
                                >
                                    <CheckboxIcon/>
                                    <p>{item.title}</p>
                                    <span>{item.value}</span>
                                </CheckItem>
                            )
                        }
                    </CheckList>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '21px',
                            color: theme.palette.textGray.dark
                        }}
                    >
                        {renderText}
                    </Typography>
                </Box>
            </InnerWrp>
        </Wrp>
    );
});
