export interface SortPayload {
    field: string;
    order: string;
}

export interface FilterPayload {
    [k: string]: any;
}

export interface PaginationPayload {
    page: number;
    perPage: number;
}

export interface ListRecord {
    id: number;
    [key: string]: any;
}

export interface GetListParams {
    pagination: PaginationPayload;
    sort: SortPayload;
    filter?: any;
}

export interface GetListResult<RecordType extends ListRecord = any> {
    data: RecordType[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    total?: number;
    size: number;
    sort:{
        empty:false;
        sorted:true;
        unsorted:false;
    };
}

export const formatParams = (params: GetListParams): string => {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    let filter = '';

    for (const filterProp in params.filter) {
        const value = params.filter[filterProp];

        if (value !== undefined && value !== null) {
            filter += `&${filterProp}=${encodeURIComponent(value)}`;
        }
    }

    return `?page=${page - 1}&size=${perPage}&sort=${field},${order}${filter}`;
};

export const convertHTTPResponse = (response: any): GetListResult<any> => {
    const {content, empty, first, number, last, size, sort, totalElements} = response.data;

    return {
        data: content,
        empty,
        first,
        last,
        number: parseInt(number, 10),
        size: parseInt(size, 10),
        sort,
        total: parseInt(totalElements, 10)
    };
};
