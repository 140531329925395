import React from 'react';

const SubdirectoryArrowIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.56683 9.48333C2.60649 9.58563 2.66596 9.67908 2.74183 9.75833L5.24183 12.2583C5.39875 12.4153 5.61158 12.5034 5.8335 12.5034C6.05541 12.5034 6.26824 12.4153 6.42516 12.2583C6.58208 12.1014 6.67024 11.8886 6.67024 11.6667C6.67024 11.4447 6.58208 11.2319 6.42516 11.075L5.34183 10H13.3335C13.5545 10 13.7665 10.0878 13.9228 10.2441C14.079 10.4004 14.1668 10.6123 14.1668 10.8333V14.1667C14.1668 14.3877 14.2546 14.5996 14.4109 14.7559C14.5672 14.9122 14.7791 15 15.0002 15C15.2212 15 15.4331 14.9122 15.5894 14.7559C15.7457 14.5996 15.8335 14.3877 15.8335 14.1667V10.8333C15.8335 10.1703 15.5701 9.53441 15.1013 9.06557C14.6324 8.59673 13.9965 8.33333 13.3335 8.33333H5.34183L6.42516 7.25833C6.50327 7.18086 6.56527 7.0887 6.60757 6.98715C6.64988 6.8856 6.67166 6.77668 6.67166 6.66667C6.67166 6.55666 6.64988 6.44774 6.60757 6.34619C6.56527 6.24464 6.50327 6.15247 6.42516 6.075C6.34769 5.99689 6.25553 5.9349 6.15398 5.89259C6.05243 5.85028 5.94351 5.8285 5.8335 5.8285C5.72349 5.8285 5.61456 5.85028 5.51301 5.89259C5.41147 5.9349 5.3193 5.99689 5.24183 6.075L2.74183 8.575C2.66596 8.65425 2.60649 8.74771 2.56683 8.85C2.48348 9.05288 2.48348 9.28045 2.56683 9.48333Z"
                fill="#999999"/>
        </svg>
    );
};

export default SubdirectoryArrowIcon;
