import React, {useMemo} from 'react';
import subDays from 'date-fns/subDays';
import sortBy from 'lodash/sortBy';
import {useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {timesheetsService} from '../../../../../../api';
import {BlackBorderButton, EngagementInfoBox} from '../../../../../../components';
import {FREELANCER_ENGAGEMENT, TIMESHEETS} from '../../../../../../constants';
import {EEngagementStatus, EWorkType, IEngagement} from '../../../../../../models';
import {formatDate} from '../../../../../../utils';

interface TrackYourTimeProps {
    data: IEngagement;
    onTimesheet: () => void;
}

const TrackYourTime: React.FC<TrackYourTimeProps> = ({data, onTimesheet}) => {
    const {engagementId} = useParams();

    const dayBeforeBilling = useMemo(() => subDays(new Date(data.nextBillingDate), 1), [data.nextBillingDate]);

    const {
        data: timesheetOverdue,
    } = useQuery([FREELANCER_ENGAGEMENT, Number(engagementId), TIMESHEETS], timesheetsService.getTimesheets(Number(engagementId)), {
        enabled: !!engagementId && data.hasOverdueTimesheets,
        staleTime: 5 * 60 * 1000,
        select: (response) => {
            sortBy(response, 'id');

            return response.find(it => it.isOverdue);
        },
    });

    if (data.status !== EEngagementStatus.ACTIVE || data.budgetType !== EWorkType.hourly || !data.nextBillingDate) {
        return null;
    }

    return (
        <EngagementInfoBox
            isError={data.hasOverdueTimesheets}
            iconUrl="/assets/images/alarm-clock-icon.png"
            title="Track your time"
        >
            {data.hasOverdueTimesheets ? (
                <>
                    You have not submitted the timesheet from {timesheetOverdue ? formatDate(timesheetOverdue.fromDate) : '-'} to {timesheetOverdue ? formatDate(timesheetOverdue.toDate) : '-'}. Please track this time period.
                </>
            ) : (
                <>
                    It would be best if you tracked your time before<br/>
                    {formatDate(dayBeforeBilling)}
                </>
            )}

            <Box
                sx={{
                    mt: '16px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <BlackBorderButton
                    size="small"
                    onClick={onTimesheet}
                >
                    Go to the timesheets
                </BlackBorderButton>
            </Box>
        </EngagementInfoBox>
    );
};

export default React.memo(TrackYourTime);
