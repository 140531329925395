import React, {useState} from 'react';
import clsx, {ClassValue} from 'clsx';
import {Controller, useFormContext} from 'react-hook-form';

import {ClickAwayListener, FormControl, FormHelperText, TextFieldProps as MUITextFieldProps} from '@mui/material';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';

import {AnimateHeight} from '../..';
import {styleInput} from '../Input/Input';


type TextFieldProps = MUITextFieldProps & {
    classNames?: ClassValue[];
    disablePast?: boolean;
    label?: string;
    maxDate?: Date;
    minDate?: Date;
}

const InputDate = ({
    classNames,
    disablePast,
    disabled,
    fullWidth = true,
    helperText,
    label,
    maxDate,
    minDate,
    name = '',
    sx,
    variant,
    ...props
}: TextFieldProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const handleClick = (event: any) => {
        const value = event.target.outerText;

        // close only if selected a day, not year or month
        if (Number(value) && Number(value) < 32) {
            setOpen(false);
        }
    };

    const {
        control,
        formState: {errors},
    } = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue=''
            name={name}
            render={({field: {ref, ...field}}) => (
                <FormControl
                    disabled={disabled}
                    error={!!errors[name]}
                    fullWidth={fullWidth}
                    sx={{
                        ...styleInput,
                        ...sx,
                    }}
                >
                    <ClickAwayListener onClickAway={handleClick} mouseEvent="onMouseDown">
                        <DesktopDatePicker
                            {...field}
                            className={clsx(classNames)}
                            disabled={disabled}
                            disablePast={disablePast}
                            // format="MM/dd/yyyy"  // use from local
                            inputRef={ref}
                            label={label}
                            minDate={minDate}
                            maxDate={maxDate}
                            open={open}
                            onOpen={() => setOpen(true)}
                            slotProps={{
                                textField: {
                                    error: !!errors[name],
                                    helperText: '',
                                    variant: variant || 'standard'
                                },
                            }}

                        />
                    </ClickAwayListener>

                    {/* Show/Hide validation errors with animation */}
                    <AnimateHeight isVisible={!!(errors[name]?.message || helperText)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {errors[name]?.message?.toString() || helperText}
                        </FormHelperText>
                    </AnimateHeight>

                </FormControl>
            )}
        />
    );
};

export default React.memo(InputDate);
