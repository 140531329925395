import React, {FC, useCallback, useEffect, useRef} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useFormContext} from 'react-hook-form';

import {FormHelperText, Grid, Link, Typography} from '@mui/material';

import theme from '../../../theme';
import {AnimateHeight} from '../../';

import ConfirmationCodeInput from './ConfirmationCodeInput';

interface IProps {
    isCodeResent: boolean;
    isSubmitting: boolean;
    onResendCode: () => void;
}

const Digits: FC<IProps> = ({isCodeResent, isSubmitting, onResendCode}) => {
    const {
        formState: {errors},
        clearErrors,
        setValue,
        watch,
    } = useFormContext();

    const valueDigit1 = watch('digit1');
    const valueDigit2 = watch('digit2');
    const valueDigit3 = watch('digit3');
    const valueDigit4 = watch('digit4');
    const valueDigit5 = watch('digit5');

    const digit1Ref = useRef<HTMLInputElement>(null);
    const digit2Ref = useRef<HTMLInputElement>(null);
    const digit3Ref = useRef<HTMLInputElement>(null);
    const digit4Ref = useRef<HTMLInputElement>(null);
    const digit5Ref = useRef<HTMLInputElement>(null);

    const clearForm = useCallback(() => {
        setValue('digit1', '', {shouldValidate: true});
        setValue('digit2', '', {shouldValidate: true});
        setValue('digit3', '', {shouldValidate: true});
        setValue('digit4', '', {shouldValidate: true});
        setValue('digit5', '', {shouldValidate: true});
    }, [setValue]);

    // if current input erased then move to the previous
    const handleKeyDown = (focusedValue: number, previousInputRef: React.RefObject<HTMLDivElement>) => (event: KeyboardEvent) => {
        if (event.code === 'Backspace' && isEmpty(focusedValue)) {
            previousInputRef.current?.focus();
        }
    };

    // if use Copy and Paste into the first input 5 digits - lets populate all of them
    const handleCopyPast = useCallback((event: ClipboardEvent) => {
        const paste = (event.clipboardData || (window as any).clipboardData)
            .getData('text')
            .replaceAll(' ', '');
        const arr = [...paste].slice(0, 5);

        arr.forEach((item, index) => setValue(`digit${index + 1}`, item, {shouldValidate: true}));
    }, [setValue]);

    const handleResendCode = useCallback(() => {
        clearForm();
        onResendCode();
    }, [clearForm, onResendCode]);

    const eraseFn2 = handleKeyDown(valueDigit2, digit1Ref);
    const eraseFn3 = handleKeyDown(valueDigit3, digit2Ref);
    const eraseFn4 = handleKeyDown(valueDigit4, digit3Ref);
    const eraseFn5 = handleKeyDown(valueDigit5, digit4Ref);

    useEffect(() => {
        const inputDigit1 = digit1Ref.current;
        const inputDigit2 = digit2Ref.current;
        const inputDigit3 = digit3Ref.current;
        const inputDigit4 = digit4Ref.current;
        const inputDigit5 = digit5Ref.current;

        inputDigit1?.addEventListener('paste', handleCopyPast);

        inputDigit2?.addEventListener('keydown', eraseFn2);
        inputDigit3?.addEventListener('keydown', eraseFn3);
        inputDigit4?.addEventListener('keydown', eraseFn4);
        inputDigit5?.addEventListener('keydown', eraseFn5);

        return () => {
            inputDigit2?.addEventListener('paste', handleCopyPast);

            inputDigit2?.removeEventListener('keydown', eraseFn2);
            inputDigit3?.removeEventListener('keydown', eraseFn3);
            inputDigit4?.removeEventListener('keydown', eraseFn4);
            inputDigit5?.removeEventListener('keydown', eraseFn5);
        };
    }, [handleCopyPast, eraseFn2, eraseFn3, eraseFn4, eraseFn5]);

    useEffect(() => {
        if (valueDigit1) digit2Ref.current?.focus();
    }, [valueDigit1]);

    useEffect(() => {
        if (valueDigit2) digit3Ref.current?.focus();
    }, [valueDigit2]);

    useEffect(() => {
        if (valueDigit3) digit4Ref.current?.focus();
    }, [valueDigit3]);

    useEffect(() => {
        if (valueDigit4) digit5Ref.current?.focus();
    }, [valueDigit4]);

    // erase server-side error
    useEffect(() => {
        if (errors.serverSideError) {
            clearErrors('serverSideError');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueDigit1, valueDigit2, valueDigit3, valueDigit4, valueDigit5, clearErrors]);

    return (
        <Grid
            container
            justifyContent="center"
            sx={{
                gap: '12px',
                [theme.breakpoints.up('lg')]: {
                    gap: '16px'
                }
            }}
        >
            <Grid item>
                <ConfirmationCodeInput
                    autoFocus
                    disabled={isSubmitting}
                    ref={digit1Ref}
                    name="digit1"
                />
            </Grid>
            <Grid item>
                <ConfirmationCodeInput
                    disabled={isSubmitting}
                    ref={digit2Ref}
                    name="digit2"
                />
            </Grid>
            <Grid item>
                <ConfirmationCodeInput
                    disabled={isSubmitting}
                    ref={digit3Ref}
                    name="digit3"
                />
            </Grid>
            <Grid item>
                <ConfirmationCodeInput
                    disabled={isSubmitting}
                    ref={digit4Ref}
                    name="digit4"
                />
            </Grid>
            <Grid item>
                <ConfirmationCodeInput
                    disabled={isSubmitting}
                    ref={digit5Ref}
                    name="digit5"
                />
            </Grid>

            {/* Show server side errors: Code invalid, expired... etc */}
            <Grid item xs={12} sx={{marginTop: '-45px'}}>
                <AnimateHeight isVisible={!!errors.serverSideError}>
                    <FormHelperText id="serverSideError" sx={{color: theme.palette.error.main, textAlign: 'center'}}>
                        {errors.serverSideError?.message?.toString()}
                    </FormHelperText>
                </AnimateHeight>
            </Grid>

            <Grid item xs={12}>
                <Typography
                    textAlign="center"
                    variant="body2"
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        [theme.breakpoints.up('xl')]: {
                            mt: '-22px'
                        }
                    }}
                >
                    {
                        isCodeResent
                            ? 'Code sent again'
                            : <>
                            Didn’t get a code or it expired? <Link underline="hover"
                                    onClick={handleResendCode}>Resend
                            code</Link>
                            </>
                    }

                </Typography>
            </Grid>
        </Grid>
    );
};

export default React.memo(Digits);
