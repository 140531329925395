import React, {useState} from 'react';

import {EEngagementStatus, EResumeStatus, IEngagement} from '../../../models';
import {ContinueButton, DialogProposal} from '../..';

type ViewProposalBtnProps = {
    data: IEngagement;
};

const ViewProposalBtn: React.FC<ViewProposalBtnProps> = ({data}) => {
    const [open, setOpen] = useState(false);

    if (data.status !== EEngagementStatus.PAUSED || data.resume?.resumeStatus !== EResumeStatus.CLIENT_REQUESTED) {
        return null;
    }

    return (
        <>
            <ContinueButton
                size="small"
                sx={{
                    width: 'auto!important'
                }}
                variant="contained"
                onClick={() => setOpen(true)}
            >
                View request
            </ContinueButton>

            <DialogProposal
                data={data}
                open={open}
                onClose={setOpen}
            />
        </>
    );
};

export default React.memo(ViewProposalBtn);
