import React from 'react';

const VideoCamOffIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M4.40579 4.99805H3.35C2.32275 4.99805 1.49 5.8308 1.49 6.85805V13.138C1.49 14.1653 2.32275 14.998 3.35 14.998H11.12C12.0125 14.998 12.7582 14.3694 12.9385 13.5307L4.40579 4.99805ZM12.98 10.7438V6.85805C12.98 5.8308 12.1472 4.99805 11.12 4.99805H7.23422L12.98 10.7438Z"
                fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M1.0929 2.49114C1.48343 2.10061 2.11659 2.10061 2.50712 2.49114L16.5071 16.4911C16.8976 16.8817 16.8976 17.5148 16.5071 17.9053C16.1166 18.2959 15.4834 18.2959 15.0929 17.9053L1.0929 3.90535C0.70238 3.51482 0.70238 2.88166 1.0929 2.49114Z"
                fill="white"/>
            <path
                d="M17.1322 6.02999C17.0205 6.04862 16.9088 6.10448 16.8157 6.16035L13.9106 7.83633V12.1566L16.8343 13.8326C17.3743 14.1492 18.0447 13.963 18.3613 13.4229C18.4544 13.2554 18.5103 13.0691 18.5103 12.8643V7.11007C18.5103 6.42106 17.8585 5.8624 17.1322 6.02999Z"
                fill="white"/>
        </svg>
    );
};

export default VideoCamOffIcon;
