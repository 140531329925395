import React, {ReactNode, useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {useLocation} from 'react-router-dom';

import {styled} from '@mui/material';

import {LG, XL} from '../../constants';

const Content = styled('div')<{ maxWidth?: IMaxWidthValues; minWidth?: IMaxWidthValues }>`
  max-width: ${props => props.maxWidth?.md ? `${props.maxWidth.md}px` : 'unset'};
  margin: 0 auto;

  @media (min-width: ${LG}) {
    min-width: ${props => props.minWidth?.md ? `${props.minWidth.md}px` : 'unset'};
    max-width: ${props => props.maxWidth?.md ? `${props.maxWidth.md}px` : 'unset'};
  }

  @media (min-width: ${XL}) {
    min-width: ${props => props.minWidth?.lg ? `${props.minWidth.lg}px` : `${props.minWidth?.md}px`};
    max-width: ${props => props.maxWidth?.lg ? `${props.maxWidth.lg}px` : `${props.maxWidth?.md}px`};
  }
`;

interface IMaxWidthValues {
    md?: number;
    lg?: number;
}

interface IProps {
    isUpAnimation?: boolean;
    children: ReactNode;
    minWidth?: IMaxWidthValues;
    maxWidth?: IMaxWidthValues;
}

export const AnimatePageWrp: React.FC<IProps> = ({children, isUpAnimation = true, minWidth, maxWidth}) => {
    const {pathname} = useLocation();
    const [animate, setAnimation] = useState({});

    useEffect(() => {
        setAnimation({opacity: 1, y: 0});
    }, []);

    return (
        <motion.div
            key={pathname}
            animate={animate}
            transition={{duration: 1}}
            style={{
                width: '100%',
                willChange: 'transform',
                transformStyle: 'preserve-3d',
                opacity: 0,
                y: isUpAnimation ? 50 : 0
            }}
        >
            <Content minWidth={minWidth} maxWidth={maxWidth}>
                {children}
            </Content>
        </motion.div>
    );
};
