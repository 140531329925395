import {loadStripe} from '@stripe/stripe-js';

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)
    .then(stripe => {
        if (!stripe) {
            console.error('Unable to load stripe');
            throw new Error('Unable to access payment gateway');
        }

        return stripe;
    });
