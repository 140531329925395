import React, {useCallback, useMemo, useState} from 'react';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import {useNavigate} from 'react-router-dom';

import {styled, Typography} from '@mui/material';

import {freelancerService, matchesService} from '../../../../api';
import {useAuth} from '../../../../hooks';
import {EMeetingStatus, IMeeting} from '../../../../models';
import theme from '../../../../theme';
import {formatDateTimeWithTimezone, zonedTimeToUtc} from '../../../../utils';
import {RedButton, WhiteButton} from '../../..';
import ChatScheduleMeeting from '../ChatScheduleMeeting';

const EventScheduledWrp = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 24px;
  background-color: ${theme.palette.white.main};
  border-bottom: 1px solid ${theme.palette.lightGray.main};
  z-index: 10;

  img {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
`;

interface ScheduledMeetingProps {
    conversationId: string;
    data: IMeeting;
}

const ScheduledMeeting: React.FC<ScheduledMeetingProps> = ({conversationId, data}) => {
    const {isClient, isFreelancer} = useAuth();
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

    const navigate = useNavigate();

    const apiService = isClient ? matchesService : freelancerService;

    const isNew = [EMeetingStatus.CLIENT_PROPOSED, EMeetingStatus.FREELANCER_PROPOSED].includes(data?.status as EMeetingStatus);
    const isOwner = useMemo(() => {
        return (isClient && data.status === EMeetingStatus.CLIENT_PROPOSED)
            || (isFreelancer && data.status === EMeetingStatus.FREELANCER_PROPOSED);
    }, [isClient, isFreelancer, data]);

    const dateTime = useMemo(() => {
        if (!data.from) return undefined;

        return zonedTimeToUtc(data.from, data.timezone);
    }, [data]);

    const displayDateTime = useMemo(() => {
        if (!dateTime) return undefined;

        return formatDateTimeWithTimezone(dateTime);
    }, [dateTime]);

    const handleCancel = async () => {
        if (!data.id) return;
        await apiService.meetingReject(data.id);
    };

    const handleScheduleModalClose = useCallback((meetingId: number | null) => {
        setScheduleModalOpen(false);

        if (meetingId) {
            console.log('edit meeting', meetingId);
        }
    }, []);

    // show Join Meeting 15 min before
    // and don't send POST /call when joining
    // and user will not get incoming call
    const is15MinutesLeftForBeginning = data.status === EMeetingStatus.ACCEPTED
        && dateTime
        && differenceInMinutes(dateTime, new Date()) <= 15;

    return (
        <>
            <EventScheduledWrp>
                <img src="/assets/images/alarm-clock-icon.png" width="20" height="20" alt="alarm clock icon"/>
                <Typography
                    sx={{
                        mr: 'auto',
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '21px',
                        color: theme.palette.black.main,
                        'span': {
                            font: 'inherit',
                            lineHeight: 'inherit',
                            color: theme.palette.blue.dark
                        }
                    }}
                >
                    Video call scheduled for <span>{displayDateTime}</span>
                </Typography>

                {(data?.status === EMeetingStatus.ACCEPTED) && (
                    <>
                        <RedButton
                            size="small"
                            onClick={handleCancel}
                        >
                            Cancel
                        </RedButton>
                        <WhiteButton
                            size="small"
                            onClick={() => setScheduleModalOpen(true)}
                        >
                            View
                        </WhiteButton>
                    </>
                )}

                {/* if it owner then can be canceled or edit */}
                {isOwner && (
                    <>
                        <RedButton
                            size="small"
                            onClick={handleCancel}
                        >
                            Cancel
                        </RedButton>
                        <WhiteButton
                            size="small"
                            onClick={() => setScheduleModalOpen(true)}
                        >
                            Reschedule
                        </WhiteButton>
                    </>

                )}

                {isNew && !isOwner && (
                    <WhiteButton
                        size="small"
                        onClick={() => setScheduleModalOpen(true)}
                    >
                        Pending
                    </WhiteButton>
                )}

                {is15MinutesLeftForBeginning && (
                    <WhiteButton
                        size="small"
                        onClick={() => navigate(`scheduled-${data.id}`)}
                    >
                        Join
                    </WhiteButton>
                )}

            </EventScheduledWrp>

            {/* Edit modal */}
            <ChatScheduleMeeting
                conversationId={conversationId}
                data={data}
                open={scheduleModalOpen}
                onClose={handleScheduleModalClose}
            />
        </>
    );
};

export default React.memo(ScheduledMeeting);
