import {ICaseStudy} from './case-study.model';
import {ICertificate} from './certificate.model';
import {ERoleStatus, IClientRole} from './client-role.model';
import {IBrand} from './verticals-brands.model';
import {EWorkType} from './work.model';

export interface IMatchingForReview {
    created: string;
    id: number;
    results: IMatchingResultForReview[];
    resultsCount: number;
    reviewedResultsCount: number;
}

export interface IMatchingResultForReview {
    freelancerAvatar?: string;
    freelancerCreated: string;
    freelancerId: number;
    freelancerName: string;
    freelancerRate: number;
    id: number;
    profileId: number;
    status: EMatchStatus;
    isContacted: boolean;
    isProposalSent: boolean;
    isHired: boolean;
}

export interface IRoleWithMatching {
    created: string;
    id: number;
    matching: IMatchingForReview;
    projectDescription: string;
    projectName: string;
    status: ERoleStatus;
    submitted: string;
    view: string;
}

export interface IMatchesResponse {
    forceFeedback: boolean;
    matches: IDynamicProfiles[];
}

export interface IDynamicProfiles {
    avatar?: string;
    bio?: string;
    brands: IBrand[];
    businessModels: IMatchMetaStrings;
    caseStudies: ICaseStudy[];
    certificates: ICertificate[];
    channels: IMatchMeta;
    country?: string;
    description: string;
    firstName: string;
    freelancerId: number;
    id: number;
    isAvailableForNewProject?: boolean;
    isOpenToHireRole?: boolean;
    languages?: IMatchMetaStrings;
    lastName: string;
    promo: string[];
    rate: number;
    ratesMatch: boolean;
    status: EMatchStatus;
    subchannels: IMatchMeta;
    tools: IMatchMeta;
    timezones: IMatchMetaStrings;
    verticals: IMatchMeta;
    workTypes: IMatchWorkType;
    yearsOfExperience: number;
    referencesCount: number;
}

export interface IMatchMeta {
    additional: IName[];
    common: IName[];
}

export interface IMatchWorkType {
    additional: EWorkType[];
    common: EWorkType[];
}

export interface IName {
    id: number;
    name: string;
}

export interface IMatchMetaStrings {
    additional: string[];
    common: string[];
}

export enum EMatchStatus {
    ACCEPTED = 'ACCEPTED',
    HIDDEN = 'HIDDEN',
    IN_TOUCH = 'IN_TOUCH',      // means the client started conversation
    LIKED = 'LIKED',
    LIKED_IN_TOUCH = 'LIKED_IN_TOUCH',
    PROPOSED = 'PROPOSED',
    REJECTED_BY_CLIENT = 'REJECTED_BY_CLIENT',
    REJECTED_BY_FREELANCER = 'REJECTED_BY_FREELANCER',
    REJECTED_IN_TOUCH = 'REJECTED_IN_TOUCH',
    REJECTED_LIKED = 'REJECTED_LIKED',
    UNLIKED = 'UNLIKED',
}

export interface IMatchWithRole {
    dynamicProfile: IDynamicProfiles;
    role: IClientRole;
}

export interface IMatchWithRecommendation {
    askForNewRole: boolean;
    dynamicProfile: IDynamicProfiles;
    forceRejectionFeedback: boolean;
    recommendation: IRecommendation;
    roleId: number;
}

export interface IRecommendation {
    author: string;
    authorAvatar: string;
    authorPosition: string;
    header: string;
    id: number;
    text: string;
}
