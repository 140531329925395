import React, {useCallback} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText, Grid} from '@mui/material';

import theme from '../../../theme';
import {AnimateHeight, CheckButton} from '../..';

interface InputWorkTypeProps {
    name: string;
    onChange?: (value: boolean) => void;
}

export const InputRetainerType: React.FC<InputWorkTypeProps> = React.memo(({name, ...props}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const isMonthly: boolean = value;

    const handleClickType = useCallback((value?: string) => {
        const newValue = value === 'isMonthly';

        onChange(newValue);

        props.onChange && props.onChange(newValue);

        trigger(name);
    }, [name, onChange, props, trigger]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <Box tabIndex={0} ref={ref}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={4}>
                            <CheckButton
                                id="isMonthly"
                                label="Monthly"
                                selected={isMonthly}
                                onClick={handleClickType}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <CheckButton
                                id="isWeekly"
                                label="Weekly"
                                selected={!isMonthly}
                                onClick={handleClickType}
                            />
                        </Grid>
                    </Grid>

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </Box>
            )}
        />
    );
});
