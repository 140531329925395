// https://react-dropzone.js.org/

import React, {useCallback, useMemo} from 'react';
import {Accept, DropEvent, FileRejection, useDropzone} from 'react-dropzone';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormControl, FormHelperText, IconButton, InputLabel} from '@mui/material';

import {CancelledStatusIcon, ImgIcon} from '../../../assets/icons';
import theme from '../../../theme';
import {AnimateHeight, BlackBorderButton, ImagePlaceholder} from '../..';

const img = {
    display: 'block',
};

interface Props {
    accept: Accept;
    disabled?: boolean;
    label?: string;
    mode: 'append' | 'update';
    name: string;
    previewURL: string;
}

const InputLogoNoBg: React.FC<Props> = ({accept, disabled, name, label, mode = 'update', previewURL, ...props}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const files = value;

    const onDrop = useCallback(
        (droppedFiles: File[]) => {
            let newFiles = mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles];

            if (mode === 'append') {
                newFiles = newFiles.reduce((prev, file) => {
                    const fo = Object.entries(file);

                    if (
                        prev.find((e: File) => {
                            const eo = Object.entries(e);

                            return eo.every(
                                ([key, value], index) =>
                                    key === fo[index][0] && value === fo[index][1]
                            );
                        })
                    ) {
                        return prev;
                    } else {
                        return [...prev, file];
                    }
                }, []);
            }
            onChange(newFiles);
            trigger(name);
        },
        [files, mode, name, onChange, trigger]
    );

    const onDropRejected = useCallback((fileRejections: FileRejection[], event: DropEvent) => {
        onChange([fileRejections[0].file]);
        trigger(name);
    }, [name, onChange, trigger]);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        accept
    });

    const handleDelete = useCallback((event: React.SyntheticEvent) => {
        event.stopPropagation();
        onChange(false);
        trigger(name);
    }, [name, onChange, trigger]);

    const previewFromS3 = useMemo(() => {
        if (!files?.length && previewURL) {
            return (
                <ImagePlaceholder width="48" height="48">
                    <img
                        src={previewURL}
                        style={img}
                        alt="company logo"
                    />
                </ImagePlaceholder>
            );
        }
    }, [files, previewURL]);

    const thumbs = useMemo(() => files && files.map((file: File) => (
        <ImagePlaceholder key={file.name} width="48" height="48">
            <img
                src={URL.createObjectURL(file)}
                style={img}
                alt={file.name}
            />
        </ImagePlaceholder>
    )), [files]);

    return (
        <Controller
            control={control}
            defaultValue={false}
            name={name}
            render={() => (
                <FormControl
                    error={!!errors[name]}
                >
                    {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
                    <Box
                        {...getRootProps()}
                        sx={{
                            opacity: disabled ? 0.5 : 1,
                            pointerEvents: disabled ? 'none' : 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px'
                        }}>
                        <input
                            {...props}
                            id={name}
                            name={name}
                            {...getInputProps()}
                        />

                        {/*
                                {!isDragActive && 'Click here or drop a file to upload!'}
                                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                                {isDragReject && "File type not accepted, sorry!"}
                            */}

                        {
                            thumbs || previewFromS3 || (
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.palette.gray.main}`,
                                        borderRadius: '50%'
                                    }}
                                >
                                    <ImagePlaceholder width="48" height="48">
                                        <ImgIcon/>
                                    </ImagePlaceholder>
                                </Box>
                            )
                        }

                        <BlackBorderButton size="small">
                            Upload a logo
                        </BlackBorderButton>

                        {
                            files &&
                          <IconButton
                              onClick={handleDelete}
                              sx={{
                                  borderColor: theme.palette.error.main,
                                  border: '2px solid',
                                  color: theme.palette.error.main,
                                  p: '12px 16px',
                                  borderRadius: '50px'
                              }}
                          >
                              <CancelledStatusIcon/>
                          </IconButton>
                        }
                    </Box>
                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </FormControl>
            )
            }
        />
    );
};

export default React.memo(InputLogoNoBg);
