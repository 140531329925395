import React from 'react';
import type {RouteObject} from 'react-router-dom';

import {
    CaseStudyPreviewClient,
    ContactUsPage,
    DashboardPage,
    InboxPage,
    PublicFreelancerProfilePageForClient,
    ReportsPage
} from '../components';
import {
    ACCOUNT,
    BILLING,
    BUDGET,
    CASE_STUDIES,
    CHANNEL,
    GENERAL,
    LOCATION,
    PRICING_AND_BILLING,
    PROCESS,
    PROJECT,
    RouteClientPortal,
    SUPPORT,
    THANK_YOU,
    TOOLS,
    VERTICALS
} from '../constants';
import {ERole} from '../models';
import {RequireUser} from '../modules/Auth';
import {
    AccountTab,
    BillingTab,
    Budget,
    ClientChannels,
    ClientPortalLayout,
    ClientProfilePage,
    CreateNewRolePage,
    EditRolePage,
    GeneralTab,
    HelpPage,
    LocationTimezone,
    MyHiresDetailsPage,
    MyHiresPage,
    MyMatchesDetailsPage,
    MyMatchesPage,
    MyRolesPage,
    MySpendingsPage,
    PricingAndBillingTab,
    ProcessTab,
    Project,
    SupportTab,
    ThankYouAddRole,
    Tools,
    VerticalsAndBusinessModel
} from '../modules/ClientPortal';

import {errorsRoutes} from './errors.router';

export const clientPortalRoutes: RouteObject = {
    path: RouteClientPortal.default,
    element: <ClientPortalLayout/>,
    children: [
        {
            path: '',
            element: <RequireUser allowedRoles={[ERole.CLIENT]}/>,
            children: [
                {
                    index: true,
                    element: <DashboardPage/>
                },
                {
                    path: RouteClientPortal.myRoles,
                    element: <MyRolesPage/>
                },
                {
                    path: RouteClientPortal.myRolesNew,
                    element: <CreateNewRolePage/>
                },
                {
                    path: `${RouteClientPortal.myRoles}/:filterBy`,
                    element: <MyRolesPage/>,
                },
                {
                    path: `${RouteClientPortal.myRoles}/:filterBy/:roleId`,
                    element: <MyRolesPage/>,
                },
                {
                    path: `${RouteClientPortal.myRoleDetails}/:roleId`,
                    element: <MyRolesPage/>
                },
                {
                    path: `${RouteClientPortal.myRoleDetails}/:roleId/edit`,
                    element: <EditRolePage/>,
                    children: [{
                        path: CHANNEL,
                        element: <ClientChannels/>
                    },
                    {
                        path: `${CHANNEL}/:channelId`,
                        element: <ClientChannels/>
                    },
                    {
                        path: VERTICALS,
                        element: <VerticalsAndBusinessModel/>
                    },
                    {
                        path: TOOLS,
                        element: <Tools/>
                    },
                    {
                        path: LOCATION,
                        element: <LocationTimezone/>
                    },
                    {
                        path: BUDGET,
                        element: <Budget/>
                    },
                    {
                        path: PROJECT,
                        element: <Project/>
                    }]
                },
                {
                    path: `${RouteClientPortal.myRoleDetails}/:roleId/${THANK_YOU}`,
                    element: <ThankYouAddRole/>
                },
                {
                    path: RouteClientPortal.profileSettings,
                    element: <ClientProfilePage/>,
                    children: [
                        {
                            path: ACCOUNT,
                            element: <AccountTab/>
                        },
                        {
                            path: BILLING,
                            element: <BillingTab/>
                        }
                    ]
                },
                {
                    path: RouteClientPortal.myMatches,
                    element: <MyMatchesPage/>
                },
                {
                    path: RouteClientPortal.myHires,
                    element: <MyHiresPage/>
                },
                {
                    path: `${RouteClientPortal.myHireDetails}/:engagementId`,
                    element: <MyHiresDetailsPage/>
                },
                {
                    path: `${RouteClientPortal.myHireDetails}/:engagementId/:tab`,
                    element: <MyHiresDetailsPage/>
                },
                {
                    path: `${RouteClientPortal.myHireDetails}/:engagementId/:tab/:videoCallRoomId`,
                    element: <MyHiresDetailsPage/>
                },
                {
                    path: `${RouteClientPortal.myHireDetails}/:engagementId/:tab/:videoCallRoomId/:isAudioOnly`,
                    element: <MyHiresDetailsPage/>
                },
                {
                    path: `${RouteClientPortal.myHires}/:sortBy/:filterBy`,
                    element: <MyHiresPage/>,
                },
                {
                    path: `${RouteClientPortal.myMatches}/:roleId`,
                    element: <MyMatchesDetailsPage/>
                },
                {
                    path: RouteClientPortal.mySpendings,
                    element: <MySpendingsPage/>
                },

                // Others
                {
                    path: RouteClientPortal.inbox,
                    element: <InboxPage/>,
                },
                {
                    path: `${RouteClientPortal.inbox}/:conversationId`,
                    element: <InboxPage/>,
                },
                {
                    path: `${RouteClientPortal.inbox}/:conversationId/:videoCallRoomId`, // audio/video call room name
                    element: <InboxPage/>,
                },
                {
                    path: `${RouteClientPortal.inbox}/:conversationId/:videoCallRoomId/:isAudioOnly`,
                    element: <InboxPage/>,
                },
                {
                    path: `${RouteClientPortal.publicFreelancerProfile}/:matchId`,
                    element: <PublicFreelancerProfilePageForClient/>,
                },
                {
                    path: `${RouteClientPortal.publicFreelancerProfile}/:matchId/${CASE_STUDIES}/:caseStudyId`,
                    element: <CaseStudyPreviewClient/>,
                },
                {
                    path: RouteClientPortal.reports,
                    element: <ReportsPage/>,
                },
                {
                    path: RouteClientPortal.help,
                    element: <HelpPage/>,
                    children: [
                        {
                            path: GENERAL,
                            element: <GeneralTab/>
                        },
                        {
                            path: PRICING_AND_BILLING,
                            element: <PricingAndBillingTab/>
                        },
                        {
                            path: PROCESS,
                            element: <ProcessTab/>
                        },
                        {
                            path: SUPPORT,
                            element: <SupportTab/>
                        }
                    ]
                },
                {
                    path: RouteClientPortal.contactUs,
                    element: <ContactUsPage/>
                },
                ...errorsRoutes
            ]
        }
    ]
};
