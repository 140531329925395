import React from 'react';
import {AxiosError} from 'axios';

import {Box, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {clientService} from '../../../../../../../api';
import {BlackBorderButton, DialogEmoji, RedFilledButton, showToastError} from '../../../../../../../components';

type DialogConfirmPendingProps = {
    engagementId: number;
    open: boolean;
    onClose: (isNeedUpdate: boolean) => void;
};

const DialogConfirmPending: React.FC<DialogConfirmPendingProps> = ({engagementId, open, onClose}) => {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const {mutate, isLoading: isSubmitting} = useMutation(
        () => clientService.pauseReject(engagementId),
        {
            onSuccess() {
                onClose(true);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    return (
        <DialogEmoji
            actions={(
                <>
                    <BlackBorderButton
                        disabled={isSubmitting}
                        fullWidth={mdDown}
                        onClick={() => onClose(false)}
                    >
                        Back
                    </BlackBorderButton>
                    <RedFilledButton
                        disabled={isSubmitting}
                        fullWidth={mdDown}
                        onClick={() => mutate()}
                    >
                        Yes, cancel
                    </RedFilledButton>
                </>
            )}
            maxWidth="sm"
            open={open}
            title={(
                <>
                    <Box justifyContent="center">
                        <img src="/assets/images/cross-icon.png" width="48" height="48" alt="ok icon"/>
                    </Box>
                    Are you sure you want to cancel the engagement pause?
                    <Typography align="center" variant="caption">
                        We will notify the freelancer about it.
                    </Typography>
                </>
            )}
            onClose={() => onClose(false)}
        >
        </DialogEmoji>
    );
};

export default React.memo(DialogConfirmPending);
