import React, {useCallback} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText, Stack} from '@mui/material';

import {EWorkType} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight} from '../..';
import {CheckButton} from '../../Button';

interface InputWorkTypesProps {
    name: string;
}

export const InputWorkTypes: React.FC<InputWorkTypesProps> = React.memo(({name}) => {
    const {
        control,
        formState: {errors},
        setValue,
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const workTypes: string[] = value;

    const isRetainer = workTypes?.includes(EWorkType.retainer);
    const isHourly = workTypes?.includes(EWorkType.hourly);
    const isFixed = workTypes?.includes(EWorkType.fixed);

    const handleClickType = useCallback((id?: string) => {
        const type = id as EWorkType;
        const newValues = workTypes?.filter(t => t !== type);

        // if it is new push into the array
        if (workTypes && newValues.length === workTypes?.length) {
            onChange([...workTypes, type]);

            // or remove from the array
        } else {
            onChange(newValues);

            // if was unchecked all items then let's erase rates and fixed too
            if (!newValues.length) {
                setValue('fixedRate', null);
                setValue('rateRange', []);

                // if hide textField - clean value
            } else if (type === EWorkType.hourly) {
                setValue('fixedRate', null);
                // if hide both types - clean rate ranges
            } else if (!(isRetainer && isFixed)) {
                setValue('rateRange', []);
            }
        }

        trigger(name);
        // trigger('fixedRate');
        // trigger('rateRange');
    }, [isRetainer, isFixed, name, workTypes, onChange, setValue, trigger]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <Box tabIndex={0} ref={ref}>
                    <Stack direction="row" spacing={2}>
                        <CheckButton
                            id={EWorkType.retainer}
                            label="Retainer"
                            selected={isRetainer}
                            size="small"
                            onClick={handleClickType}
                        />
                        <CheckButton
                            id={EWorkType.hourly}
                            label="Hourly"
                            selected={isHourly}
                            size="small"
                            onClick={handleClickType}
                        />
                        <CheckButton
                            id={EWorkType.fixed}
                            label="Fixed"
                            selected={isFixed}
                            size="small"
                            onClick={handleClickType}
                        />
                    </Stack>

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </Box>
            )}
        />
    );
});
