import React, {FC, useMemo} from 'react';
import sortBy from 'lodash/sortBy';

import {Box, MenuItem} from '@mui/material';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {useQuery} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../../api';
import {CLIENT_ROLES, FREELANCER_MATCHES} from '../../../constants';
import {useAuth} from '../../../hooks';
import {ERoleStatus, ISelectOption} from '../../../models';
import theme from '../../../theme';

const PAGE_SIZE = 9999;

const MenuProps = {
    PaperProps: {
        style: {
            width: 500,
        },
    },
};

const stylesMenuItem = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    whiteSpace: 'pre-wrap'
};

interface IProps {
    values: string[];
    onSelect: (values: string[]) => void;
}

const SelectRole: FC<IProps> = ({values, onSelect}) => {
    const {isClient, isFreelancer} = useAuth();

    const {
        // isLoading: isLoadingClient,
        data: dataClient
    } = useQuery([CLIENT_ROLES],
        () => clientService.getRoles([
            ERoleStatus.EngagementActive,
            ERoleStatus.EngagementPaused,
            ERoleStatus.EngagementPending,
            ERoleStatus.Closed
        ], 'id', 'DESC', PAGE_SIZE)({pageParam: 0}), {
            enabled: isClient,
            staleTime: 5 * 60 * 1000,
            select: ({data}) => data.map(item => ({projectName: item.projectName, roleId: item.id}))
        }
    );

    const {
        // isLoading: isLoadingFreelancer,
        data: dataFreelancer
    } = useQuery([FREELANCER_MATCHES],
        () => freelancerService.getWorks('id', 'ASC', 'ALL', PAGE_SIZE)({pageParam: 0}), {
            enabled: isFreelancer,
            staleTime: 5 * 60 * 1000,
            select: ({data}) => data.map(item => ({projectName: item.name, roleId: item.roleId}))
        }
    );

    const roles = dataClient || dataFreelancer;
    // const isLoading = isLoadingClient || isLoadingFreelancer;

    const options: ISelectOption[] = useMemo(() => {
        return sortBy(roles, 'projectName')?.map(item => ({
            text: item.projectName,
            value: item.roleId
        })) || [];
    }, [roles]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const {target} = event;
        const isClean = Array.isArray(target.value) ? (target.value.indexOf('0') !== 0 && target.value.indexOf('0') !== -1) : false;
        const value = Array.isArray(target.value) ? target.value.filter(it => it !== '0') : target.value;

        if (isClean || !value || !value.length) {
            onSelect([]);

            return;
        }

        onSelect(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                width: 300,
                gap: '4px',
                alignItems: 'center',
                p: '10px 16px',
                backgroundColor: theme.palette.white.main,
                border: `1px solid ${theme.palette.lightGray.main}`,
                borderRadius: '8px',
                '.MuiInputBase-root': {
                    width: '100%',
                    '&::before, &::after': {
                        display: 'none'
                    }
                },
                '.MuiSelect-select': {
                    p: '0',
                    pr: '24px',
                    '&:focus': {
                        backgroundColor: 'unset'
                    },
                },
            }}
        >
            <Select
                MenuProps={MenuProps}
                multiple
                sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: theme.palette.black.main,
                }}
                value={values.length ? values as any : ['0']}
                variant="standard"
                onChange={handleChange}
            >
                <MenuItem
                    sx={stylesMenuItem}
                    value="0"
                >
                    All roles
                </MenuItem>

                {options.map(item => (
                    <MenuItem
                        key={item.value}
                        sx={stylesMenuItem}
                        title={item.text}
                        value={item.value.toString()}
                    >
                        {item.text}
                    </MenuItem>
                ))}

            </Select>
        </Box>
    );
};

export default React.memo(SelectRole);
