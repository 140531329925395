import React from 'react';
import {motion} from 'framer-motion';

import {Box, Grid, Typography} from '@mui/material';

import theme from '../../../theme';

interface IProps {
    label: string | React.ReactNode;
    selected: boolean;
    value?: string;
    onClick: (id?: string) => void;
}

const CheckButtonOutlined: React.FC<IProps> = ({label, selected, value, onClick}) => {
    return (
        <Box
            component={motion.div}
            whileHover={{
                scale: 1.01
            }}
            whileTap={{
                scale: 1
            }}
            sx={{
                backgroundColor: theme.palette.white.main,
                border: '2px solid',
                borderColor: selected ? theme.palette.purple.main : theme.palette.lightGray.main,
                borderRadius: '12px',
                cursor: 'pointer',
                padding: '14px 22px',
                '&:hover': {
                    borderColor: theme.palette.purple.main
                },
                '&:focus-visible': {
                    outlineColor: theme.palette.purple.main,
                    borderColor: theme.palette.purple.main
                }
            }}
            tabIndex={0}
            onClick={() => onClick(value)}
            onKeyPress={() => onClick(value)}
        >
            <Grid
                container
                spacing={1}
                wrap="nowrap"
                alignItems="center"
                sx={{
                    height: '100%'
                }}
            >
                <Grid
                    item
                    xs
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            fontWeight: selected ? 600 : 400,
                            fontSize: '14px',
                            lineHeight: '21px',
                            color: selected ? theme.palette.black.main : theme.palette.gray.main,
                            userSelect: 'none',
                            'path': {
                                fill: selected ? theme.palette.purple.main : theme.palette.gray.main
                            },
                            [theme.breakpoints.up('sm')]: {
                                marginTop: 0,
                                marginLeft: 0
                            }
                        }}
                        variant="body1"
                    >
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default React.memo(CheckButtonOutlined);
