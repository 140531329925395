import React, {FC, ReactNode, useCallback} from 'react';
import {useController, useFormContext} from 'react-hook-form';

import {Box, SxProps, useTheme} from '@mui/material';

import {CheckboxIcon} from '../../assets/icons';
import {ISelectOption} from '../../models';
import {Autocomplete} from '..';

interface ILocation {
    label?: ReactNode;
    labelText?: string;
    name?: string;
    options: ISelectOption[];
    placeholder?: string;
    sx?: SxProps;
    variant?: 'outlined' | 'filled' | 'standard' | undefined;
}

const Location: FC<ILocation> = ({label, labelText, name = 'country', options, placeholder, sx, variant}): JSX.Element => {
    const {control} = useFormContext();
    const {field: {value: item}} = useController({control, name});
    const theme = useTheme();

    const primaryColor = theme.palette.primary.main;

    const handleRenderOptions = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
        return (
            <Box
                {...props}
                component="li"
                sx={{
                    '& > img': {mr: 2},
                    '& > span': {flexGrow: 1},
                    color: item?.value === option.value ? 'primary.main' : 'default',
                    display: 'flex',
                    width: '100%',
                    '&.MuiInput-root, .MuiInput-root': {
                        '&:hover': {
                            '&::before': {
                                borderBottomColor: primaryColor
                            }
                        }
                    }
                }}
            >
                <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                />
                <span>{option.text} ({option.code})</span>
                {item?.value === option.value ?
                    <CheckboxIcon fill={primaryColor} height={22} width={22}/> : null}
            </Box>
        );
    }, [primaryColor, item?.value]);

    const handleOptionEqualToValue = useCallback((option: ISelectOption, value: ISelectOption) => option.value === value.value, []);


    return (
        <>
            {label}

            <Autocomplete
                fullWidth
                isOptionEqualToValue={handleOptionEqualToValue}
                label={labelText}
                name={name}
                options={options || []}
                placeholder={placeholder || 'Select location'}
                renderOption={handleRenderOptions}
                sx={sx}
                variant={variant}
            />

        </>
    );
};

export default React.memo(Location);
