import React, {useCallback} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText, Stack} from '@mui/material';

import theme from '../../../theme';
import {AnimateHeight, CheckButton} from '../..';

interface InputRateRangeProps {
    name: string;
}

export const InputRateRange: React.FC<InputRateRangeProps> = React.memo(({name}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const rateRange: number[] = value;

    const rateRangeMin = rateRange?.[0];

    const handleClickRange = useCallback((min: number, max: number) => {
        // un-check
        if (min === rateRangeMin) {
            onChange([]);
        } else {
            onChange([min, max]);
        }
        trigger(name);
    }, [name, rateRangeMin, onChange, trigger]);

    const handleRange50Click = useCallback(() => handleClickRange(50, 100), [handleClickRange]);
    const handleRange100Click = useCallback(() => handleClickRange(100, 150), [handleClickRange]);
    const handleRange150Click = useCallback(() => handleClickRange(150, 200), [handleClickRange]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <Box tabIndex={0} ref={ref}>
                    <Stack direction="row" spacing={2}>
                        <CheckButton
                            label="$50 - $100"
                            selected={rateRangeMin === 50}
                            size="small"
                            onClick={handleRange50Click}
                        />
                        <CheckButton
                            label="$100 - $150"
                            selected={rateRangeMin === 100}
                            size="small"
                            onClick={handleRange100Click}
                        />
                        <CheckButton
                            label="$150 - $200"
                            selected={rateRangeMin === 150}
                            size="small"
                            onClick={handleRange150Click}
                        />
                    </Stack>

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </Box>
            )}
        />
    );
});
