import {
    BUDGET,
    CHANNEL,
    COMPLETED,
    CONFIRMATION_CODE,
    LOCATION,
    PROJECT,
    RouteApplication,
    RouteCreateApplicantAccount,
    RouteCreateClientAccount,
    RouteWidget,
    TOOLS,
    VERTICALS
} from '../constants';
import {isWidget} from '../utils';


// if step < 8 it is Create Account
// if step > 7 it is Process Application
export const urlByApplicationStep = (step: number): string => {
    switch (step) {
        case 1:
            return RouteCreateApplicantAccount.experience;
        case 2:
            return RouteCreateApplicantAccount.channels;
        case 3:
            return RouteCreateApplicantAccount.expertise;
        case 4:
            return RouteCreateApplicantAccount.verticalsAndBrands;
        case 5:
            return RouteCreateApplicantAccount.workTypes;
        case 6:
            return RouteCreateApplicantAccount.otherDetails;
        case 7:
            return RouteCreateApplicantAccount.confirmationCode;
        case 8:
            return RouteApplication.application;
        case 9:
            return RouteApplication.completed;
        default:
            console.log(step, 'step not implemented yet');

            return '';
    }
};

export const stepsOrderRole = [
    CHANNEL,
    VERTICALS,
    TOOLS,
    LOCATION,
    BUDGET,
    PROJECT,
];

export const stepsOrderClient = [
    ...stepsOrderRole,
    CONFIRMATION_CODE,
    COMPLETED
];

export const stepsOrderRoleDisplay = [
    'Marketing Channel',
    'Verticals',
    'Tools',
    'Country, Timezone, Languages',
    'Role Information',
    'Work title & description',
];

export const urlByClientView = (view?: string): string => {
    switch (view) {
        case CHANNEL:
            return RouteCreateClientAccount.verticals;
        case VERTICALS:
            return RouteCreateClientAccount.tools;
        case TOOLS:
            return RouteCreateClientAccount.location;
        case LOCATION:
            return RouteCreateClientAccount.budget;
        case BUDGET:
            return RouteCreateClientAccount.project;
        case PROJECT: {
            if (isWidget()) {
                return RouteWidget.signup;
            } else {
                return RouteCreateClientAccount.confirmationCode;
            }
        }
        case COMPLETED:
            if (isWidget()) {
                return RouteWidget.completed;
            } else {
                return RouteCreateClientAccount.completed;
            }
        default:
            return RouteCreateClientAccount.channels;
    }
};
