import React from 'react';
import {useNavigate} from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import {alpha, Box, Stack} from '@mui/material';

import {CheckCurvyIcon, CheckCurvyIconDisabled} from '../../assets/icons';
import {ECaseStudyStepsDisplay} from '../../models';
import theme from '../../theme';

export interface IStepNav {
    active: boolean;
    disabled: boolean;
    link: string;
    text: string;
}

type StepsNavBarProps = {
    items: IStepNav[];
};

const StepsNavBar: React.FC<StepsNavBarProps> = ({items}) => {
    const navigate = useNavigate();

    return (
        <Stack
            spacing={2}
            sx={{
                width: '100%',
                maxWidth: '365px'
            }}
        >
            {items.map(item => (
                <Box
                    key={item.link}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '24px',
                        height: '80px',
                        padding: '16px 24px',
                        border: '1px solid',
                        borderColor: (item.disabled || item.active) ? 'transparent' : theme.palette.green.dark,
                        borderRadius: '16px',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: item.disabled
                            ? theme.palette.gray.main
                            : theme.palette.black.main,
                        cursor: !item.disabled ? 'pointer' : 'default',
                        backgroundColor: item.disabled
                            ? alpha(theme.palette.white.main, 0.5)  // white with opacity 50%
                            : (
                                item.active
                                    ? theme.palette.white.main
                                    : theme.palette.green.light
                            ),
                        boxShadow: item.active ? '0px 0px 50px rgba(104, 221, 67, 0.24)' : 'none',
                        transition: 'box-shadow .3s',
                        '& svg, & div': {
                            width: '48px',
                            height: '48px',
                        },
                        '&:hover': {
                            color: item.disabled
                                ? theme.palette.gray.main
                                : theme.palette.black.main,
                            boxShadow: !item.disabled ? '0px 0px 50px rgba(104, 221, 67, 0.24)' : 'none',
                            'svg': {
                                opacity: (item.active || item.disabled) ? 1 : 0.8
                            }
                        }


                    }}
                    onClick={() => !item.active && !item.disabled && navigate(item.link)}
                >
                    {
                        item.disabled
                            ? (
                                <CheckCurvyIconDisabled/>
                            ) : item.text === ECaseStudyStepsDisplay.preview
                                ? (
                                    <VisibilityIcon sx={{width: 30, fill: theme.palette.green.dark}}/>
                                ) : (
                                    <CheckCurvyIcon
                                        fill={item.active ? theme.palette.green.light : theme.palette.green.dark}
                                    />
                                )
                    }
                    {item.text}
                </Box>
            ))}
        </Stack>
    );
};

export default React.memo(StepsNavBar);
