import React from 'react';

import {Box, Typography} from '@mui/material';

import {Button} from '../../../../components';
import {IRoleWithMatching} from '../../../../models';
import theme from '../../../../theme';
import {formatDate} from '../../../../utils';

interface MatchedItemProps {
    item: IRoleWithMatching;
}

const MatchedItemEmpty: React.FC<MatchedItemProps> = ({item}) => {
    return (
        <Box
            sx={{
                p: '16px',
                backgroundColor: theme.palette.white.main,
                borderRadius: '24px'
            }}
        >
            <Typography
                sx={{
                    mb: '4px',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: 1.5
                }}
            >
                {item.projectName}
            </Typography>
            <Typography
                sx={{
                    mb: '24px',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: theme.palette.gray.main
                }}
            >
                Submitted: {item.submitted ? formatDate(item.submitted) : ''}
            </Typography>

            <Button
                disabled
                size="small"
                variant="contained"
                sx={{
                    mb: '8px'
                }}
            >
                View all matches
            </Button>

            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: theme.palette.gray.main
                }}
            >
                You don’t have matches for now. You will receive matches within 24 hours.
            </Typography>
        </Box>
    );
};

export default React.memo(MatchedItemEmpty);
