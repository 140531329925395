import React from 'react';

import {applicationService} from '../../../api';
import {ConfirmationCode} from '../../../components';
import {RouteApplication, RouteCreateApplicantAccount} from '../../../constants';
import {useApplication} from '../../../hooks';

const ApplicantConfirmationCodePage = () => {
    const {refetchApplication} = useApplication();

    return (
        <ConfirmationCode
            title="Thanks for completing our onboarding process, you’re almost there!"
            routeConfirmationCode={RouteCreateApplicantAccount.confirmationCode}
            routeNextStep={RouteApplication.application}
            routeUpdateEmail={RouteCreateApplicantAccount.updateEmail}
            service={applicationService}
            onRefetch={refetchApplication}
        />
    );
};

export default ApplicantConfirmationCodePage;
