import React, {FC} from 'react';

import {Box, styled, Typography} from '@mui/material';

import {LinkCertificateIcon, PdfCertificateIcon} from '../../../assets/icons';
import {ICertificate} from '../../../models';
import theme from '../../../theme';
import {getFileName} from '../../../utils';

const MAX_LENGTH = 35;

const Wrp = styled('div')``;

const Title = styled('p')`
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.black.main};
`;

const CertificateItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding: 24px;
  border: 1px solid ${theme.palette.lightGray.main};
  border-radius: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .icon {
    padding: 12px;
    background-color: ${theme.palette.lilac.main};
    border-radius: 8px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    width: 100%;
  }
`;

interface IFreelancerCertifications {
    items: ICertificate[];
}

const FreelancerCertifications: FC<IFreelancerCertifications> = ({items}) => {
    return (
        <Wrp>
            <Title>
                Certifications
            </Title>
            <Box>
                {
                    items.map(certificate => (
                        <CertificateItem key={certificate.name}>
                            <div className="icon">
                                {certificate.isFile ? <PdfCertificateIcon/> : <LinkCertificateIcon/>}
                            </div>
                            <div className="text">
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '21px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        maxWidth: 'calc(100% - 62px)'
                                    }}
                                >
                                    {certificate.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: theme.palette.gray.main
                                    }}
                                >
                                    {certificate.isFile
                                        ? getFileName(certificate.fileName, MAX_LENGTH)
                                        : certificate.url?.length > MAX_LENGTH ? (certificate.url.substring(0, MAX_LENGTH) + '...') : certificate.url
                                    }
                                </Typography>
                            </div>
                        </CertificateItem>
                    ))
                }
            </Box>
        </Wrp>
    );
};

export default React.memo(FreelancerCertifications);
