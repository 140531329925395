import React, {useCallback} from 'react';

import {Box} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {freelancerService} from '../../../../../../../api';
import {BlackBorderButton, EngagementActionsFrelancer, EngagementInfoBox} from '../../../../../../../components';
import {FREELANCER_ENGAGEMENT} from '../../../../../../../constants';
import {EEngagementStatus, EPauseStatus, EResumeStatus, IEngagement} from '../../../../../../../models';

interface ResumeCanceledProps {
    data: IEngagement;
    onMessage: () => void;
}

const ResumeCanceled: React.FC<ResumeCanceledProps> = ({data, onMessage}) => {
    const queryClient = useQueryClient();

    const resumeStatus = data.resume?.resumeStatus as EResumeStatus;
    const isCanceled = [EResumeStatus.FREELANCER_CANCELLED, EResumeStatus.CLIENT_CANCELLED].includes(resumeStatus);

    const isYouInitiator = data.pause?.status === EPauseStatus.CLIENT_ACCEPTED;
    const isInitiatorClient = data.pause?.status === EPauseStatus.CLIENT_DECIDED;
    const isYouCanceled = resumeStatus === EResumeStatus.FREELANCER_CANCELLED;
    const isClientCanceled = resumeStatus === EResumeStatus.CLIENT_CANCELLED;

    // hide this info box by Message button
    const handleMessage = useCallback(async () => {
        await freelancerService.resumeReject(data.id);
        queryClient.invalidateQueries([FREELANCER_ENGAGEMENT, data.id]);
        onMessage();
    }, [data.id, queryClient, onMessage]);

    if (data.status !== EEngagementStatus.PAUSED || !isCanceled) {
        return null;
    }

    return (
        <EngagementInfoBox
            iconUrl="/assets/images/cross-icon.png"
            isError
            title="Engagement resume has been cancelled"
        >
            {isYouCanceled && isYouInitiator && (
                <>
                    You have cancelled the engagement resume.
                    It is now on hold, until you manually resume it.
                    Jump into conversation to negotiate the details.
                </>
            )}

            {isYouCanceled && isInitiatorClient && (
                <>
                    You have cancelled the engagement resume.
                    It is now on hold, until {data.clientName.split(' ')[0]} manually resumes it.
                    Jump into conversation to negotiate the details.
                </>
            )}

            {isClientCanceled && isYouInitiator && (
                <>
                    {data.clientName.split(' ')[0]} has cancelled the engagement resume.
                        It is now on hold, until you manually resume it.
                        Jump into conversation to negotiate the details.
                </>
            )}

            {isClientCanceled && isInitiatorClient && (
                <>
                    {data.clientName.split(' ')[0]} has cancelled the engagement resume.
                        It is now on hold, until {data.clientName.split(' ')[0]} manually resumes it.
                        Jump into conversation to negotiate the details.
                </>
            )}


            <Box
                sx={{
                    mt: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '16px'
                }}
            >
                {isYouInitiator && (
                    <EngagementActionsFrelancer.ResumeEngagementBtn
                        data={data}
                    />
                )}

                <BlackBorderButton
                    size="small"
                    onClick={handleMessage}
                >
                    Message {data.clientName.split(' ')[0]}
                </BlackBorderButton>
            </Box>

        </EngagementInfoBox>
    );
};

export default React.memo(ResumeCanceled);
