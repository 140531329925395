export const DELAY_API_CALL = 500;
export const MAX_FILE_SIZE = 5;
export const MAX_FILE_SIZE_MB = MAX_FILE_SIZE * 1024 * 1024; // 5Mb;

export const MAX_SAFE_INTEGER = 2147483647;

export const META: string = 'meta';

export const GET_BRANDS: string = `${META}/brands`;
export const GET_BUSINESS_MODELS: string = `${META}/business-models`;
export const GET_CHANNELS: string = `${META}/channels`;
export const GET_LOCATIONS: string = `${META}/locations`;
export const GET_TOOLS: string = `${META}/tools`;
export const GET_VERTICALS: string = `${META}/verticals`;

// only photos
export const ACCEPTED_PHOTO_TYPES = {
    'image/webp': ['.webp'],
    'image/png': ['.png'],              // The value must be an object with a common MIME type as keys and an array of file extensions as values
    'image/jpg': ['.jpeg', '.jpg'],
};

// also vector graphics + gifs
export const ACCEPTED_IMAGE_TYPES = {
    ...ACCEPTED_PHOTO_TYPES,
    'image/svg+xml': ['.svg'],
    'image/gif': ['.gif'],
};

export const ACCEPTED_DOCUMENTS_TYPES = {
    ...ACCEPTED_PHOTO_TYPES,
    'application/pdf': ['.pdf']
};

export const ACCEPTED_FILES_TYPES = {
    ...ACCEPTED_IMAGE_TYPES,
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
};

export const MEETINGS = 'meetings';
export const PROPOSALS = 'proposals';
