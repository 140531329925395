import React from 'react';

const ArrowMobileShort = (): JSX.Element => {
    return (
        <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7H0L0 9H1L1 7ZM31.7071 8.70711C32.0976 8.31658 32.0976 7.68342 31.7071 7.29289L25.3431 0.928932C24.9526 0.538408 24.3195 0.538408 23.9289 0.928932C23.5384 1.31946 23.5384 1.95262 23.9289 2.34315L29.5858 8L23.9289 13.6569C23.5384 14.0474 23.5384 14.6805 23.9289 15.0711C24.3195 15.4616 24.9526 15.4616 25.3431 15.0711L31.7071 8.70711ZM1 9L9.61878 9V7L1 7L1 9ZM9.61878 9L17.0773 9V7L9.61878 7V9ZM17.0773 9L24.2044 9V7L17.0773 7V9ZM24.2044 9L31 9V7L24.2044 7V9Z" fill="white"/>
        </svg>
    );
};

export default ArrowMobileShort;
