import React, {useCallback, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {RouteClientPortal, RouteFreelancerPortal} from '../constants';
import {IApplication, IClientRole} from '../models';

export enum ActionTypes {
    OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU',
    SET_APPLICATION = 'SET_APPLICATION',
    SET_CLIENT = 'SET_CLIENT',
}

type IActionOpenMobileMenu = {
    type: ActionTypes.OPEN_MOBILE_MENU;
    payload: boolean;
};

type IActionSetApplication = {
    type: ActionTypes.SET_APPLICATION;
    payload: IApplication | null;
};

type IActionSetClient = {
    type: ActionTypes.SET_CLIENT;
    payload: IClientRole | null;
};

type Action = IActionOpenMobileMenu | IActionSetApplication | IActionSetClient;

type Dispatch = (action: Action) => void;

type State = {
    application: IApplication | null;
    clientRole: IClientRole | null;
    openMobileMenu: boolean;
};

const initialState: State = {
    application: null,
    clientRole: null,
    openMobileMenu: false,
};

type StateContextProviderProps = { children: React.ReactNode };

const StateContext = React.createContext<
{ state: State; dispatch: Dispatch } | undefined>(undefined);

const stateReducer = (state: State, action: Action): State => {
    // console.log(action, state);
    switch (action.type) {
        case ActionTypes.OPEN_MOBILE_MENU: {
            return {
                ...state,
                openMobileMenu: action.payload,
            };
        }
        case ActionTypes.SET_APPLICATION: {
            return {
                ...state,
                application: action.payload,
            };
        }
        case ActionTypes.SET_CLIENT: {
            return {
                ...state,
                clientRole: action.payload,
            };
        }
        default: {
            throw new Error('Unhandled action type');
        }
    }
};

const StateContextProvider = ({children}: StateContextProviderProps) => {
    const [state, dispatch] = React.useReducer(stateReducer, initialState);
    const value = {state, dispatch};
    const location = useLocation();
    const navigate = useNavigate();

    const listener = useCallback(() => {
        let module: string = '';

        // if it is not /failed page
        // redirect to the Portal failed page
        if (!location.pathname.endsWith('/failed')) {
            if (location.pathname.startsWith(RouteClientPortal.default)) {
                module = RouteClientPortal.default;
            }
            if (location.pathname.startsWith(RouteFreelancerPortal.default)) {
                module = RouteFreelancerPortal.default;
            }
            navigate(`${module}/failed`);
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        document.body.addEventListener('isRedirectToFailedPage', listener);

        return () => document.body.removeEventListener('isRedirectToFailedPage', listener);
    }, [listener]);

    return (
        <StateContext.Provider value={value}>{children}</StateContext.Provider>
    );
};

const useStateContext = () => {
    const context = React.useContext(StateContext);

    if (context) {
        return context;
    }

    throw new Error('useStateContext must be used within a StateContextProvider');
};

export {StateContextProvider, useStateContext};
