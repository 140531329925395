import {IChannel, ISubChannel} from './channel.model';
import {EMatchStatus, IMatchMeta, IMatchMetaStrings, IMatchWorkType} from './matching.model';
import {IContactPerson} from './profile.model';
import {ITool} from './tools.model';
import {IEmailVerificationProcess} from './verification-proccess';
import {IVertical} from './verticals-brands.model';
import {EWorkType} from './work.model';

export interface IRegisterClient {
    avatar?: string;
    companyName: string;
    companyLogo?: string;
    companyWebsite: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    position: string;
    widgetId?: string;
}

export interface IClientWithEmailVerificationProcess extends IRegisterClient {
    billingContact?: IContactPerson;
    emailVerificationProcess?: IEmailVerificationProcess;
    hasAnyUnverifiedPaymentMethods?: boolean;
}

export interface IClientRole {
    budget: number;
    businessModels: string[];
    channel: IChannel;
    client?: Partial<IRegisterClient>;
    countries: string[];
    created: string;
    engagementId?: number;      // for FINISHED only
    expectedEngagementHours: number[];
    id: number;
    languages: string[];
    name: string;
    projectDescription: string;
    projectName: string;
    rateRange: number[];
    status: ERoleStatus;
    subchannels: ISubChannel[];
    submitted: string;
    tier: ETier;
    timezone: string;
    tools: ITool[];
    verticals: IVertical[];
    view: string;
    workType: EWorkType;
}

export interface IClientRoleDetails {
    businessModels: IMatchMetaStrings;
    companyLogo?: string;
    companyName: string;
    countries: IMatchMetaStrings;
    channels: IMatchMeta;
    client: IClientInfo;
    id: number;
    languages: IMatchMetaStrings;
    nextMatchId: number;
    otherMatches: IOtherMatch[];
    projectDescription: string;
    projectName: string;
    promo: string[];
    rateRange: number[];
    roleId: number;
    subchannels: IMatchMeta;
    timezones: IMatchMetaStrings;
    tools: IMatchMeta;
    previousMatchId: number;
    status: EMatchStatus;
    verticals: IMatchMeta;
    weeklyEngagementHours: number[];
    workTypes: IMatchWorkType;
}

export interface IClientInfo {
    avatar?: string;
    name: string;
    id: number;
    registeredAt: string;
}

export interface IOtherMatch {
    channel: IChannel;
    companyLogo?: string;
    companyName: string;
    id: number;
    isActive: boolean;
    projectName: string;
    publishedAt: string;
    rateRange: number[];
    roleId: number;
    workTypes: EWorkType[];
}

export enum ETier {
    Basic = 'BASIC',
    Premium = 'PREMIUM'
}

// DRAFT,                   not yet submitted. form in progress
// SUBMITTED,               submitted but no matching has been started yet
// MATCHING_IN_PROGRESS,
// MATCHING_FINISHED,       got some matchings but not yet shared to client
// IN_REVIEW,               matching results shared to client
// CANCELLED,
// ENGAGEMENT_ACTIVE,       accepted match
// ENGAGEMENT_PAUSED,
// CLOSED

export enum ERoleStatus {
    Cancelled = 'CANCELLED',
    Closed = 'CLOSED',
    Draft = 'DRAFT',
    EngagementActive = 'ENGAGEMENT_ACTIVE',
    EngagementPaused = 'ENGAGEMENT_PAUSED',
    EngagementPending = 'ENGAGEMENT_PENDING',
    InReview = 'IN_REVIEW',
    MatchingFinished = 'MATCHING_FINISHED',
    MatchingInProgress = 'MATCHING_IN_PROGRESS',
    Submitted = 'SUBMITTED',
}

export enum ERoleDisplayStatus {
    CANCELLED = 'Role archived',
    CLOSED = 'Engagement closed',
    DRAFT = 'Draft',
    ENGAGEMENT_ACTIVE = 'Engagement active',
    ENGAGEMENT_PAUSED = 'Engagement paused',
    ENGAGEMENT_PENDING = 'Engagement pending',
    IN_REVIEW = 'In review',
    MATCHING_FINISHED = 'In review',
    MATCHING_IN_PROGRESS = 'In review',
    SUBMITTED = 'Role submitted',
}

export enum ERoleStatusFilter {
    All = 'All',
    Active = 'Active',
    Drafts = 'Drafts',
    Archive = 'Archive'
}

export interface IVerticalBussinessModelRequest {
    businessModels: string[];
    verticals: IVertical[];
}

export interface ILocationRequest {
    countries: string[];
    languages: string[];
    timezone: string | null;
}

export interface IBudgetRequest {
    workType: EWorkType;
    expectedEngagementHours: number[] | null;
    rateRange: number[];
}

export interface IProjectRequest {
    projectName: string;
    projectDescription: string;
}

export interface IRegisterWidget {
    companyName: string;
    companyWebsite: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    position: string;
    widgetId: string;

    businessModels: string[];
    countries: string[];
    channelId: number;
    expectedEngagementHours: number[];
    languages: string[];
    projectDescription: string;
    projectName: string;
    rateRange: number[];
    subchannelIds: number[];
    timezone: string;
    tools: ITool[];
    verticalIds: number[];
    workType: EWorkType;
}
