import React from 'react';

import {EngagementInfoBox} from '../../../../../../../components';
import {EEngagementStatus, EPauseStatus, EResumeStatus, IEngagement} from '../../../../../../../models';

interface EngagementOnHoldProps {
    data: IEngagement;
}

const EngagementOnHold: React.FC<EngagementOnHoldProps> = ({data}) => {
    const isResumNotExists = !data.resume || data.resume?.resumeStatus === EResumeStatus.CLIENT_REJECTED;

    if (
        data.status !== EEngagementStatus.PAUSED
        || !(data.pause?.status === EPauseStatus.CLIENT_ACCEPTED && isResumNotExists)) {
        return null;
    }

    return (
        <EngagementInfoBox
            iconUrl="/assets/images/pause-icon.png"
            title="Engagement is on hold"
        >
            {data.freelancerName.split(' ')[0]} has asked to pause the engagement.
            {data.pause?.endDate
                ? ` It will be automatically resumed on ${data.pause?.endDate}. The billing is currently stopped.`
                : ' If it is not resumed within 6 months, the engagement will be automatically closed.'
            }
        </EngagementInfoBox>
    );
};

export default React.memo(EngagementOnHold);
