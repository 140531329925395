import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm, useWatch} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, Stack, Theme, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {useAuth} from '../../../../hooks';
import {ECaseStudySteps} from '../../../../models';
import theme from '../../../../theme';
import {CASE_STUDY_MAX_REVIEW_LENGTH, getTextLength, testimonialSchema} from '../../../../utils';
import {
    AnimatePageWrp,
    ContinueButton,
    GrayButton,
    Input,
    Portal,
    showToastError,
    showToastSuccess,
    useCaseStudy,
    WhiteButton,
} from '../../..';

import {InputCaption, InputSubCaption} from './styles';

type TestimonialInput = TypeOf<typeof testimonialSchema>;

export const Step5Testimonial = () => {
    const {isImpersonal} = useAuth();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const {state: {data, cancelUrl, isCanBePublished}, onRefetch, publish, updateCaseStudy} = useCaseStudy();

    const location = useLocation();
    const navigate = useNavigate();

    let nextStep = location.pathname.replace(`/${ECaseStudySteps.testimonial}`, '').replace('/edit', '') + '?draft=true';
    const prevStep = location.pathname.replace(ECaseStudySteps.testimonial, ECaseStudySteps.outcomes);

    const methods = useForm<TestimonialInput>({
        resolver: zodResolver(testimonialSchema),
    });

    const {
        control,
        handleSubmit,
        formState: {isDirty, isValid},
        getValues,
        reset,
    } = methods;

    const {clientsReview} = useWatch({control});

    const {mutate, isLoading: isSubmitting} = useMutation(
        (values: TestimonialInput) => updateCaseStudy({...data?.draftVersion, ...values, id: data?.id}),
        {
            onSuccess(response: any) {
                showToastSuccess('Testimonial were saved successfully!');

                if (nextStep === 'publish') {
                    publish(response.data.id);
                } else {
                    navigate(nextStep);
                    onRefetch();
                }
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handlePublishClick = () => {
        const values = getValues();

        nextStep = 'publish';
        mutate(values);
    };

    const handleSaveAndExit = () => {
        const values = getValues();

        nextStep = cancelUrl;
        mutate(values);
    };

    const onSubmitHandler: SubmitHandler<TestimonialInput> = (values: TestimonialInput) => {
        const {clientsName, clientsPosition, clientsReview} = data?.draftVersion || {};
        const isFirstVisit = !clientsName && !clientsPosition && !clientsReview;

        if ((isDirty || isFirstVisit) && !isImpersonal) {
            mutate({...values, clientsReview: values.clientsReview || ' '}); // store empty string for detection - was this step visited already?
        } else {
            navigate(nextStep);
        }
    };

    useEffect(() => {
        if (!data) return;

        const {clientsName, clientsPosition, clientsReview} = data.draftVersion;

        reset({
            clientsName,
            clientsPosition,
            clientsReview: clientsReview ? clientsReview.trim() : '' // could be empty string as indicator that this step was visited already?
        });
    }, [data, reset]);

    return (
        <AnimatePageWrp>

            <FormProvider {...methods}>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            mb: '20px'
                        }}
                    >
                        <Box sx={{mb: '20px'}}>
                            <InputCaption>Add a customer’s testimonial if you have one (optional)</InputCaption>
                            <InputSubCaption sx={{pb: '24px'}}>
                                Include a quote from the subject or their team about the work done and its impact
                            </InputSubCaption>
                            <Input
                                autoFocus={!clientsReview}
                                disabled={isSubmitting}
                                name="clientsReview"
                                counter={CASE_STUDY_MAX_REVIEW_LENGTH - getTextLength(clientsReview || '')}
                                inputProps={{maxLength: CASE_STUDY_MAX_REVIEW_LENGTH}}
                                isResizableY
                                minRows={5}
                                multiline
                                placeholder="Add some description to give us more details of what you did exactly"
                                variant="outlined"
                            />
                        </Box>

                        <Box>
                            <Grid container spacing="48px">
                                <Grid item xs={12} md={6}>
                                    <InputCaption sx={{pb: '24px'}}>Customer’s name (optional)</InputCaption>
                                    <Input
                                        disabled={isSubmitting}
                                        name="clientsName"
                                        placeholder="John Doe"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputCaption sx={{pb: '24px'}}>Customer’s position (optional)</InputCaption>
                                    <Input
                                        disabled={isSubmitting}
                                        name="clientsPosition"
                                        placeholder="Senior Sales Manager"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </form>
            </FormProvider>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '16px',
                        flexWrap: 'wrap'
                    },
                    'button': {
                        width: 'auto'
                    }
                }}
                textAlign="center"
            >
                <WhiteButton
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={() => navigate(prevStep)}
                >
                    Back
                </WhiteButton>
                <ContinueButton
                    disabled={isSubmitting}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
                <GrayButton
                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>
                {isCanBePublished && (
                    <GrayButton
                        disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                display: 'none'
                            }
                        }}
                        variant="contained"
                        onClick={handlePublishClick}
                    >
                        Publish
                    </GrayButton>
                )}
            </Box>
            {
                isSmallScreen && (
                    <>
                        <Portal fullWidth order={1} innerPages>
                            <ContinueButton
                                disabled={isSubmitting}
                                hasArrow
                                variant="contained"
                                sx={{
                                    'div': {
                                        justifyContent: 'center !important'
                                    }
                                }}
                                onClick={handleSubmit(onSubmitHandler)}
                            >
                                Continue
                            </ContinueButton>

                        </Portal>

                        <Portal fullWidth order={2} innerPages>
                            <WhiteButton
                                onClick={() => navigate(prevStep)}
                            >
                                Back
                            </WhiteButton>
                        </Portal>

                        <Portal fullWidth={!isCanBePublished} order={3} innerPages>
                            <GrayButton
                                disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                fullWidth
                                variant="contained"
                                onClick={handleSaveAndExit}
                            >
                                Save & exit
                            </GrayButton>
                        </Portal>

                        {isCanBePublished && (
                            <Portal order={4} innerPages>
                                <GrayButton
                                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                    variant="contained"
                                    onClick={handlePublishClick}
                                >
                                    Publish
                                </GrayButton>
                            </Portal>
                        )}
                    </>
                )
            }
        </AnimatePageWrp>
    );
};
