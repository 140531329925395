import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Typography} from '@mui/material';

import {RouteClientPortal} from '../../../../../constants';
import {ERoleStatusFilter} from '../../../../../models';
import theme from '../../../../../theme';

interface IProps {
    status: ERoleStatusFilter;
}

const RolesEmpty: React.FC<IProps> = ({status}) => {
    const navigate = useNavigate();

    const renderCaption = useMemo(() => {
        switch (status) {
            case ERoleStatusFilter.Active:
                return <>
                    Once you <span onClick={() => navigate(RouteClientPortal.myRolesNew)}>create a role</span>, it will
                    appear here.
                </>;
            case ERoleStatusFilter.Archive:
                return <>
                    Once you archive a role, it will appear here.
                </>;
            case ERoleStatusFilter.Drafts:
                return <>
                    Once you add a role, it will appear here.
                </>;
            default:
                return <>
                    Once you <span onClick={() => navigate(RouteClientPortal.myRolesNew)}>create a role</span>, it will
                    appear here.
                </>;
        }
    }, [navigate, status]);

    return (
        <Box
            sx={{
                width: '100%',
                height: 'calc(100vh - 300px)',
                display: 'flex'
            }}
        >
            <Box
                sx={{
                    margin: 'auto',
                    textAlign: 'center',
                    'img': {
                        width: '64px',
                        height: '64px',
                        display: 'inline-block',
                        mb: '32px'
                    }
                }}
            >
                <img src="/assets/images/glancing-eyes-emoji.png" width="64" height="64" alt="glancing eyes emoji"/>
                <Typography
                    sx={{
                        mb: '2px',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    No roles yet
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main,
                        'span': {
                            font: 'inherit',
                            fontWeight: 500,
                            color: theme.palette.purple.main,
                            cursor: 'pointer'
                        }
                    }}
                >
                    {renderCaption}
                </Typography>
            </Box>
        </Box>
    );
};

export default React.memo(RolesEmpty);
