import React from 'react';

const SingleHeartIcon = ({color}: {color: string}) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4903_4692)">
                <path
                    d="M8.8125 0.703125C8.16134 0.703125 7.56434 0.909469 7.03812 1.31644C6.53363 1.7066 6.19774 2.20355 6 2.56491C5.80226 2.20352 5.46637 1.7066 4.96188 1.31644C4.43566 0.909469 3.83866 0.703125 3.1875 0.703125C1.37034 0.703125 0 2.18946 0 4.16048C0 6.28988 1.7096 7.74677 4.29771 9.95231C4.73721 10.3269 5.23537 10.7514 5.75316 11.2042C5.82141 11.264 5.90906 11.2969 6 11.2969C6.09094 11.2969 6.17859 11.264 6.24684 11.2042C6.76467 10.7514 7.26281 10.3268 7.70257 9.95208C10.2904 7.74677 12 6.28988 12 4.16048C12 2.18946 10.6297 0.703125 8.8125 0.703125Z"
                    fill="#7E5FFF"/>
            </g>
            <defs>
                <clipPath id="clip0_4903_4692">
                    <rect width="12" height="12" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default SingleHeartIcon;
