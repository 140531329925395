export enum EPaymentMethod {
    ACH = 'ACH',
    CreditCard = 'CARD',
    ManualInvoicing = 'MANUAL_INVOICING',
    SEPA = 'SEPA',
}

export enum EPaymentMethodStatus {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    VERIFIED = 'VERIFIED',
}

export enum EPaymentStatus {
    FAILED = 'FAILED',
    NEW = 'NEW',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    REFUNDED = 'REFUNDED',
}

export enum EPaymentStatusDisplay {
    FAILED = 'Failed',
    NEW = 'Pending',        // GC-1024 should be shown as PENDING instead of new
    PENDING = 'Pending',
    SUCCESS = 'Success',
    REFUNDED = 'Refunded'
}

export enum EPayoutStatusDisplay {
    FAILED = 'Failed',
    NEW = 'Pending',
    PENDING = 'Processing',         // GC-1136 should be shown as Processing instead of PENDING
    SUCCESS = 'Success',
    REFUNDED = 'Refunded',
}

export interface IPaymentPreview {
    id: number;
    isPrimary: boolean;
    last4: string;
    name?: string;
    method: EPaymentMethod;
    verificationUrl?: string;
    status: EPaymentMethodStatus;
}

export interface IPaymentCard {
    id?: number;
    method: EPaymentMethod.CreditCard;
    number: string;
}

export interface IPaymentACH {
    id?: number;
    method: EPaymentMethod.ACH;
    number: string;
    routingNumber: string;
}

export interface IPaymentSEPA {
    id?: number;
    method: EPaymentMethod.SEPA;
    number: string;
}

export type IPaymentMethod = IPaymentCard | IPaymentACH | IPaymentSEPA;

export interface IBillingAddress {
    country: string;
    city: string;
    line1: string;
    line2: string;
    postalCode: string;
    state: string;
}

export interface IFreelancerPaymentMethods {
    onboardingFinished: boolean;
    payoutMethods: IPaymentPreview[];
}
