import React, {useState} from 'react';
import clsx from 'clsx';

import {Box, styled} from '@mui/material';

import LoadingMask from '../LoadingMask';

export const Like = styled('div')(({theme}) => ({
    '&': {
        cursor: 'pointer',
        height: '65px',
        width: '65px',
        backgroundImage: "url('/assets/images/web_heart_animation.png')",
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '2900%',
    },
    '&:hover, &.active': {
        backgroundPosition: 'right',
    },
    '&.active:hover': {
        opacity: 0.8,
    },
    '&:hover:not(.active)': {
        opacity: 0.5,
    },
    '&.isAnimate': {
        animation: 'heart-burst .8s steps(28) 1',
    },
    '@keyframes heart-burst': {
        from: {backgroundPosition: 'left'},
        to: {backgroundPosition: 'right'}
    },
}));

interface LikeButtonProps {
    active: boolean;
    loading: boolean;
    onClick: (value: boolean) => void;
}

const LikeButton: React.FC<LikeButtonProps> = ({active, loading, onClick}) => {
    const [isAnimate, setIsAnimate] = useState(false);

    const handleAnimationEnd = () => {
        setIsAnimate(false);
        onClick(true);
    };

    // if no like then show animation
    // if active then set no like without animation
    // if animation in progress then ignore click
    const handleClick = () => {
        if (isAnimate || loading) return;

        if (!active) {
            setIsAnimate(true);
        } else {
            onClick(false);
        }
    };

    return (
        <Like
            className={clsx({
                active,
                isAnimate
            })}
            onAnimationEnd={handleAnimationEnd}
            onPointerDown={handleClick}
        >
            {loading && (
                <Box sx={{opacity: 0.2, m: 2, mt: '4px'}}>
                    <LoadingMask hasNoSpace={true} size={50} thickness={1} />
                </Box>
            )}
        </Like>
    );
};

export default React.memo(LikeButton);
