import React, {useState} from 'react';
import {AxiosError} from 'axios';
import orderBy from 'lodash/orderBy';
import {DateRange} from 'mui-daterange-picker';
import {useNavigate} from 'react-router-dom';

import {Box, styled} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {
    ClientPortalTitle,
    DateRangeCalendarPicker,
    EarningsSpendingsEmpty,
    EarningsSpendingsList,
    LoadingMask,
    showToastError,
    Tab,
    Tabs
} from '../../../components';
import {HeaderTotals, prepareQueryParams} from '../../../components/Reports';
import {CLIENT_PAYMENTS, MD, RouteClientPortal} from '../../../constants';
import {EPaymentMethodStatus, IPaymentPreview} from '../../../models';
import theme from '../../../theme';

const PageTopWrp = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 22px;

  @media (min-width: ${MD}) {
    gap: 24px;
  }
`;

const MySpendingsPage = () => {
    const [dateRange, setDateRange] = useState<DateRange | undefined>();
    const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);
    const navigate = useNavigate();

    const {
        isLoading,
        data
    } = useQuery(['spendings', dateRange?.startDate, dateRange?.endDate, selectedRoleIds.join(',')],
        () => clientService.getSpendings(prepareQueryParams(selectedRoleIds, dateRange /*, EPaymentStatus.SUCCESS*/ )), {
            staleTime: 5 * 60 * 1000,
            onError: (error) => showToastError(error as AxiosError)
        });

    const {
        data: paymentMethods,
    } = useQuery([CLIENT_PAYMENTS], clientService.paymentMethods, {
        refetchOnWindowFocus: true,
        select: ((response: {
            data: { paymentMethods: IPaymentPreview[] };
        }) => orderBy(response.data.paymentMethods.filter(it => it.status === EPaymentMethodStatus.VERIFIED), ['isPrimary'], 'desc')),
        onError: (error) => showToastError(error as AxiosError)
    });

    return (
        <Box
            sx={{
                pt: '38px',
                [theme.breakpoints.up('md')]: {
                    pt: '56px'
                }
            }}
        >
            <PageTopWrp>
                <ClientPortalTitle>My Spendings</ClientPortalTitle>
                <Tabs
                    sx={{
                        width: '100%',
                        [theme.breakpoints.up('sm')]: {
                            ml: 'auto',
                            width: 'auto'
                        }
                    }}
                >
                    <Tab
                        isActive={true}
                        onClick={() => navigate(RouteClientPortal.mySpendings)}
                    >
                        Spendings
                    </Tab>
                    <Tab
                        isActive={false}
                        onClick={() => navigate(RouteClientPortal.reports)}
                    >
                        Timesheets
                    </Tab>
                </Tabs>
            </PageTopWrp>

            <Box
                sx={{
                    m: '0 auto',
                    maxWidth: '884px'
                }}
            >
                <HeaderTotals
                    data={data}
                    selectedRoleIds={selectedRoleIds}
                    onSelectRole={setSelectedRoleIds}
                />

                <Box sx={{mb: '16px'}}>
                    <DateRangeCalendarPicker
                        dateRange={dateRange}
                        endDate={dateRange?.endDate}
                        startDate={dateRange?.startDate}
                        onDateRange={setDateRange}
                    />
                </Box>

                {isLoading
                    ? <LoadingMask/>
                    : (
                        (data?.spendings?.length || 0) < 1 ? (
                            <EarningsSpendingsEmpty/>
                        ) : (
                            <EarningsSpendingsList
                                data={data?.spendings || []}
                                paymentMethods={paymentMethods?.length ? paymentMethods : undefined}
                            />
                        )
                    )}
            </Box>
        </Box>
    );
};

export default React.memo(MySpendingsPage);
