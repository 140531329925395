// https://react-dropzone.js.org/

import React, {useCallback, useMemo} from 'react';
import {Accept, DropEvent, FileRejection, useDropzone} from 'react-dropzone';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormControl, FormHelperText, InputLabel, styled} from '@mui/material';

import {ImgIcon} from '../../../assets/icons';
import {AnimateHeight, Button} from '../..';

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const LogoPlaceholder = styled('div')`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #D9D9D9;
  width: 64px;
  height: 64px;
  overflow: hidden;
  
  img, svg {
    flex-shrink: 0;
  }
`;

interface Props {
    accept: Accept;
    disabled?: boolean;
    label?: string;
    mode: 'append' | 'update';
    name: string;
    previewURL: string;
}

const InputLogo: React.FC<Props> = ({accept, disabled, name, label, mode = 'update', previewURL, ...props}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const files = value;

    const onDrop = useCallback(
        (droppedFiles: File[]) => {
            let newFiles = mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles];

            if (mode === 'append') {
                newFiles = newFiles.reduce((prev, file) => {
                    const fo = Object.entries(file);

                    if (
                        prev.find((e: File) => {
                            const eo = Object.entries(e);

                            return eo.every(
                                ([key, value], index) =>
                                    key === fo[index][0] && value === fo[index][1]
                            );
                        })
                    ) {
                        return prev;
                    } else {
                        return [...prev, file];
                    }
                }, []);
            }
            onChange(newFiles);
            trigger(name);
        },
        [files, mode, name, onChange, trigger]
    );

    const onDropRejected = useCallback((fileRejections: FileRejection[], event: DropEvent) => {
        onChange([fileRejections[0].file]);
        trigger(name);
    }, [name, onChange, trigger]);

    const {isDragActive, getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        accept
    });

    const handleDelete = useCallback((event: React.SyntheticEvent) => {
        event.stopPropagation();
        onChange(false);
        trigger(name);
    }, [name, onChange, trigger]);

    const previewFromS3 = useMemo(() => {
        if (!files?.length && previewURL) {
            return (
                <LogoPlaceholder>
                    <img
                        src={previewURL}
                        style={img}
                        alt="company logo"
                    />
                </LogoPlaceholder>
            );
        }
    }, [files, previewURL]);

    const thumbs = useMemo(() => files && files.map((file: File) => (
        <LogoPlaceholder key={file.name}>
            <img
                src={URL.createObjectURL(file)}
                style={img}
                alt={file.name}
            />
        </LogoPlaceholder>
    )), [files]);

    return (
        <Controller
            control={control}
            defaultValue={false}
            name={name}
            render={() => (
                <FormControl
                    error={!!errors[name]}
                >
                    <InputLabel htmlFor={name}>{label}</InputLabel>
                    <Box
                        {...getRootProps()}
                        sx={{
                            background: disabled ? '#ededed' : '#F8F8F8',
                            p: 2,
                            minHeight: 120,
                            borderRadius: '10px',
                            border: disabled ? '2px solid #dddddd' : (isDragActive ? '2px dashed #7E5FFF' : '2px dashed #D6D6D6'),
                            opacity: disabled ? 0.5 : 1,
                            pointerEvents: disabled ? 'none' : 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px'
                        }}>
                        <input
                            {...props}
                            id={name}
                            name={name}
                            {...getInputProps()}
                        />

                        {/*
                                {!isDragActive && 'Click here or drop a file to upload!'}
                                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                                {isDragReject && "File type not accepted, sorry!"}
                            */}

                        {
                            thumbs || previewFromS3 || (
                                <LogoPlaceholder>
                                    <ImgIcon/>
                                </LogoPlaceholder>
                            )
                        }

                        <Button
                            variant="contained"
                        >
                                Upload a logo
                        </Button>

                        {
                            files &&
                              <Button
                                  variant="outlined"
                                  onClick={handleDelete}
                                  //   color="error"
                              >
                                Delete logo
                              </Button>
                        }
                    </Box>
                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </FormControl>
            )
            }
        />
    );
};

export default React.memo(InputLogo);
