import React from 'react';

import {Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {BudgetTypeRow, ContinueButton, FoldingText} from '../../../../../../components';
import {LG} from '../../../../../../constants';
import {EEngagementStatus, IEngagementFreelancer} from '../../../../../../models';
import theme from '../../../../../../theme';
import {formatCurrency, formatDate} from '../../../../../../utils';

import PauseEngagementBtn from './PauseBoxes/PauseEngagementBtn';

const Wrapper = styled('div')`
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid ${theme.palette.lightGray.main};
  border-bottom: 1px solid ${theme.palette.lightGray.main};

  @media (min-width: ${LG}) {
    margin: 0;
    padding: 24px;
    background-color: ${theme.palette.white.main};
    border-radius: 24px;
    border: none;
  }
`;

interface FreelancerInfoBoxProps {
    data: IEngagementFreelancer;
    isCompany: boolean;         // hide Logo at the Overview page because we have big one there
    onMessage: () => void;
}

const FreelancerInfoBox: React.FC<FreelancerInfoBoxProps> = ({data, isCompany, onMessage}) => {
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    return (
        <Wrapper>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: '45px',
                    lineHeight: '58px',
                    letterSpacing: '-0.02em',
                    color: theme.palette.primary.main,
                    '& > span': {
                        fontWeight: 400,
                        fontSize: '12px',
                        color: theme.palette.gray.main,
                        letterSpacing: 'initial'
                    }
                }}
            >
                {formatCurrency(data.paid || 0, true)} <span>earned</span>
            </Typography>

            {data.nextBillingDate && (
                <Typography
                    sx={{
                        mb: '16px',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: 1.6,
                        color: theme.palette.gray.main
                    }}
                >
                    Next billing date: {formatDate(data.nextBillingDate)}
                </Typography>
            )}

            <BudgetTypeRow
                budgetType={data.budgetType}
                clientCompanyLogo={isCompany ? data.clientCompanyLogo : ''}
                clientCompanyName={isCompany ? data.clientCompanyName : ''}
                isFreelancerView={true}
                isMonthly={data.isMonthly}
                hourlyRate={data.rate}
            />

            {
                data.name && (
                    <Box
                        sx={{
                            mt: '16px',
                            pt: '16px',
                            borderTop: '1px solid ' + theme.palette.lightGray.main,
                            borderBottom: '1px solid ' + theme.palette.lightGray.main
                        }}
                    >
                        <FoldingText
                            title="Role name"
                            text={data.name}
                            sx={{
                                '.text': {
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    lineHeight: 1.5,
                                    color: theme.palette.black.main
                                }
                            }}
                        />
                    </Box>
                )
            }

            {
                data.description && (
                    <Box sx={{
                        mt: '16px',
                    }}>
                        <FoldingText
                            title="Role description"
                            text={data.description}
                        />
                    </Box>
                )
            }

            {lgUp && data.status !== EEngagementStatus.CLOSED && (
                <Box
                    sx={{
                        borderTop: `1px solid ${theme.palette.lightGray.main}`,
                        pt: '16px',
                        mt: '16px',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '.MuiButton-sizeSmall': {
                            p: '10px 15px !important'
                        }
                    }}
                >
                    <ContinueButton
                        size="small"
                        sx={{
                            width: 'auto!important'
                        }}
                        variant="contained"
                        onClick={onMessage}
                    >
                        Message client
                    </ContinueButton>

                    <PauseEngagementBtn
                        data={data}
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '8px',
                            'svg': {
                                width: '16px',
                                height: '16px'
                            },
                            'path': {
                                fill: 'currentColor'
                            }
                        }}
                    />

                </Box>
            )}
        </Wrapper>
    );
};

export default FreelancerInfoBox;
