import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import capitalize from 'lodash/capitalize';
import {useNavigate} from 'react-router-dom';

import {
    Box,
    IconButton,
    styled,
    Theme,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
    useMediaQuery
} from '@mui/material';

import {applicationService} from '../../../../api';
import {CheckCurvyIcon, CheckCurvyIconDisabled, HelpIcon} from '../../../../assets/icons';
import {BlackBorderButton, DialogEmoji, RedFilledButton, showToastSuccess} from '../../../../components';
import {EDIT_CERTIFICATE, EDIT_REFERENCE, RouteApplication, SM} from '../../../../constants';
import {useApplication} from '../../../../hooks';
import {EAttachmentType, IAttachment, ICaseStudy, ICertificate, IReference} from '../../../../models';
import theme from '../../../../theme';

import {IconType} from './ApplicationAddedItem';
import {ApplicationAddedItem} from './index';

const formatConfirmOptions = (name: string) => ({
    title: `Are you sure you want to delete this ${name}?`,
    text: `Are you sure you want to delete a ${name}?`,
    toast: `${capitalize(name)} was successfully deleted!`
});

const Wrp = styled('div')<{ hasItems?: boolean }>`
  padding: 24px;
  background-color: ${theme.palette.white.main};
  border: 1px solid ${theme.palette.lightGray.light};
  border-radius: 24px;
  display: grid;
  grid-template-areas: 
          'checkIcon'
  'textWrap'
  'button';
  gap: 16px;

  @media (min-width: ${SM}) {
    grid-template-areas: 
          'checkIcon textWrap button';
    grid-template-columns: 48px auto 70px;
    column-gap: 16px;
    row-gap: 24px;
  }

  &.has-items {
    grid-template-areas: 
          'checkIcon'
  'textWrap'
  'itemsList'
  'button';

    @media (min-width: ${SM}) {
      grid-template-areas: 
          'checkIcon textWrap button'
  'itemsList itemsList itemsList';
      grid-template-columns: 48px auto 70px;
    }
  }
`;

const IconWrp = styled('div')`
  grid-area: checkIcon;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: inherit;
    height: inherit;
  }
`;

const TextWrp = styled('div')`
  grid-area: textWrap;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: auto;
`;

const ItemsList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const ItemCol = styled(motion.div)`
  flex-shrink: 0;
  margin: 0 auto;
  width: 100%;
`;

const NoMaxWidthTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: '12px',
        maxWidth: 'none',
    },
});

interface IProps {
    caption: string;
    checked?: boolean;
    icon: React.ReactNode;
    isEdit?: boolean;
    items?: ICertificate[] | IReference[] | ICaseStudy[];
    helperText?: ReactNode;
    pathToAdd: string;
    titleType: string;
}

const ApplicationRow = ({
    checked,
    caption,
    icon,
    isEdit,
    items,
    helperText,
    pathToAdd,
    titleType
}: IProps) => {
    const {refetchApplication} = useApplication();

    const [selectedItem, setSelectedItem] = useState<IAttachment | null>();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const navigate = useNavigate();

    const handleClickAdd = () => {
        navigate(pathToAdd);
    };

    const handleClickDelete = useCallback((item: IAttachment) => () => {
        setSelectedItem(item);
        setIsConfirmOpen(true);
    }, [setSelectedItem, setIsConfirmOpen]);

    const confirmConfig = useMemo(() => {
        switch (selectedItem?.type) {
            case EAttachmentType.certificate:
                return formatConfirmOptions('certificate');
            case EAttachmentType.reference:
                return formatConfirmOptions('reference');
            case EAttachmentType.study:
                return formatConfirmOptions('case study');
            default:
                return {text: '', title: '', toast: ''};
        }
    }, [selectedItem]);

    const handleConfirm = useCallback(async () => {
        const minimum = new Promise((reject) => setTimeout(() => reject(true), 1000));    // show preloader minimum 1 sec to prevent flicking
        let method;

        setIsConfirmOpen(false);
        setIsSubmitting(true);

        if (selectedItem?.type === EAttachmentType.certificate) {
            method = applicationService.certificateDelete;
        } else if (selectedItem?.type === EAttachmentType.reference) {
            method = applicationService.referenceDelete;
        } else if (selectedItem?.type === EAttachmentType.study) {
            method = applicationService.caseStudyDelete;
        }

        await Promise.all([minimum, method && method(selectedItem?.id || 0)]);

        await refetchApplication();
        showToastSuccess(confirmConfig.toast);
        setSelectedItem(null);
        setIsSubmitting(false);
    }, [confirmConfig, selectedItem, setSelectedItem, setIsConfirmOpen, refetchApplication]);

    const handleClickEdit = useCallback((item: IAttachment) => () => {
        setSelectedItem(item);

        switch (item.type) {
            case EAttachmentType.certificate:
                navigate(`${RouteApplication.application}/${EDIT_CERTIFICATE}/${item.id}`);
                break;
            case EAttachmentType.reference:
                navigate(`${RouteApplication.application}/${EDIT_REFERENCE}/${item.id}`);
                break;
            case EAttachmentType.study:
                navigate(`${RouteApplication.caseStudy}/edit/${item.id}`);
                break;
            default:
                break;
        }
    }, [navigate]);

    const getType = (item: ICertificate | IReference | ICaseStudy): IconType => {
        if (item.type === EAttachmentType.certificate) {
            return item.isFile ? 'pdf' : 'link';
        } else if (item.type === EAttachmentType.reference) {
            return 'reference';
        } else if (item.type === EAttachmentType.study) {
            return 'case study';
        } else {
            return 'default';
        }
    };

    return (
        <>
            <Wrp className={!!items?.length ? 'has-items' : ''}>
                <IconWrp>
                    {
                        checked ? (
                            <CheckCurvyIcon
                                fill={theme.palette.green.dark}
                            />
                        ) : (
                            <CheckCurvyIconDisabled/>
                        )
                    }
                </IconWrp>

                <TextWrp>
                    <Box
                        sx={{
                            flexShrink: 0,
                            width: '24px',
                            height: '24px',
                            'path': {
                                fill: theme.palette.purple.main
                            }
                        }}
                    >
                        {icon}
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                mb: '2px',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: 1.5
                            }}
                        >
                            {isEdit ? 'Edit' : 'Add'} {titleType}
                            {helperText && (
                                <NoMaxWidthTooltip
                                    arrow
                                    placement="top"
                                    title={helperText}
                                    sx={{
                                        [`& .${tooltipClasses.tooltip}`]: {
                                            maxWidth: 'none',
                                        }
                                    }}
                                >
                                    <IconButton sx={{p: 0}}>
                                        <HelpIcon/>
                                    </IconButton>
                                </NoMaxWidthTooltip>
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: '400',
                                fontSize: '13px',
                                lineHeight: '20px',
                                color: theme.palette.gray.main
                            }}
                        >
                            {caption}
                        </Typography>
                    </Box>
                </TextWrp>
                <BlackBorderButton onClick={handleClickAdd} size="small" sx={{gridArea: 'button'}}>
                    Add
                </BlackBorderButton>
                <Box sx={{gridArea: 'itemsList', display: !!items?.length ? 'block' : 'none', minWidth: 0}}>
                    {items && (
                        <ItemsList>
                            <AnimatePresence>
                                {items.map((item, index) =>
                                    <ItemCol
                                        key={item.id + (item.isDraft ? '-draft' : '')}
                                        initial={{opacity: 0, height: 0}}
                                        animate={{opacity: 1, height: 'auto'}}
                                        exit={{opacity: 0, height: 0}}
                                        transition={{
                                            delay: 0.05 * index,
                                            duration: 0.3,
                                            ease: 'easeInOut',
                                        }}
                                    >
                                        <ApplicationAddedItem
                                            icon={icon}
                                            isDraft={item.isDraft}
                                            isSubmitting={isSubmitting && item.id === selectedItem?.id}
                                            name={item.name}
                                            type={getType(item)}
                                            onEdit={handleClickEdit(item)}
                                            onDelete={handleClickDelete(item)}
                                        />
                                    </ItemCol>
                                )}
                            </AnimatePresence>
                        </ItemsList>
                    )}
                </Box>
            </Wrp>
            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            disabled={isSubmitting}
                            sx={{
                                width: mdDown ? '50%' : 'auto'
                            }}
                            onClick={() => setIsConfirmOpen(false)}
                        >
                            Cancel
                        </BlackBorderButton>
                        <RedFilledButton
                            disabled={isSubmitting}
                            sx={{
                                width: mdDown ? '50%' : 'auto'
                            }}
                            onClick={() => handleConfirm()}
                        >
                            Yes, delete
                        </RedFilledButton>
                    </>
                )}
                maxWidth="sm"
                open={isConfirmOpen}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img src="/assets/images/cross-icon.png" width="48" height="48" alt="cross icon"/>
                        </Box>
                        {confirmConfig.title}
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: theme.palette.textGray.dark
                            }}
                        >
                            This action can’t be undone.
                        </Typography>
                    </>
                )}
                onClose={() => setIsConfirmOpen(false)}/>
        </>
    );
};

export default React.memo(ApplicationRow);
