import React, {FC} from 'react';

interface IProps {
    fill?: string;
}

const CheckCurvyIcon: FC<IProps> = ({fill}) => {
    return (
        <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.2887 4.8945C18.1525 2.43657 21.8475 2.43657 23.7113 4.8945C24.7282 6.23564 26.3895 6.9238 28.0569 6.69454C31.1128 6.27438 33.7256 8.88719 33.3055 11.9431C33.0762 13.6105 33.7644 15.2718 35.1055 16.2887C37.5634 18.1525 37.5634 21.8475 35.1055 23.7113C33.7644 24.7282 33.0762 26.3895 33.3055 28.0569C33.7256 31.1128 31.1128 33.7256 28.0569 33.3055C26.3895 33.0762 24.7282 33.7644 23.7113 35.1055C21.8475 37.5634 18.1525 37.5634 16.2887 35.1055C15.2718 33.7644 13.6105 33.0762 11.9431 33.3055C8.88719 33.7256 6.27438 31.1128 6.69454 28.0569C6.9238 26.3895 6.23564 24.7282 4.8945 23.7113C2.43657 21.8475 2.43657 18.1525 4.8945 16.2887C6.23564 15.2718 6.9238 13.6105 6.69454 11.9431C6.27438 8.88719 8.88719 6.27438 11.9431 6.69454C13.6105 6.9238 15.2718 6.23564 16.2887 4.8945Z"
                    fill={fill || 'white'}/>

                <path
                    d="M25.5917 16.0083C25.5142 15.9302 25.4221 15.8682 25.3205 15.8259C25.219 15.7836 25.11 15.7618 25 15.7618C24.89 15.7618 24.7811 15.7836 24.6796 15.8259C24.578 15.8682 24.4858 15.9302 24.4084 16.0083L18.2 22.225L15.5917 19.6083C15.5113 19.5306 15.4163 19.4695 15.3123 19.4285C15.2082 19.3875 15.0971 19.3674 14.9853 19.3694C14.8735 19.3713 14.7631 19.3952 14.6606 19.4398C14.558 19.4844 14.4652 19.5487 14.3875 19.6292C14.3098 19.7096 14.2487 19.8046 14.2077 19.9086C14.1667 20.0127 14.1466 20.1238 14.1486 20.2356C14.1505 20.3474 14.1744 20.4577 14.219 20.5603C14.2636 20.6629 14.3279 20.7556 14.4084 20.8333L17.6084 24.0333C17.6858 24.1114 17.778 24.1734 17.8796 24.2157C17.9811 24.2581 18.09 24.2798 18.2 24.2798C18.31 24.2798 18.419 24.2581 18.5205 24.2157C18.6221 24.1734 18.7142 24.1114 18.7917 24.0333L25.5917 17.2333C25.6763 17.1553 25.7438 17.0606 25.79 16.9552C25.8361 16.8498 25.86 16.7359 25.86 16.6208C25.86 16.5058 25.8361 16.3919 25.79 16.2865C25.7438 16.1811 25.6763 16.0864 25.5917 16.0083Z"
                    fill="#121416"/>

            </svg>

        </div>
    );
};

export default CheckCurvyIcon;
