import React, {Ref} from 'react';

import {Box, Grid} from '@mui/material';

import theme from '../../theme';
import {InputAdornment, InputCaption} from '../Forms';

interface BudgetFixedRateProps {
    ref: Ref<HTMLInputElement | undefined>;
    value: number | null;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BudgetFixedRate: React.FC<BudgetFixedRateProps> = React.forwardRef(({value, onChange}, ref) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        mt: '40px',
                        mb: '-12px',
                        [theme.breakpoints.up('sm')]: {
                            mt: '64px'
                        }
                    }}
                >
                    <InputCaption>Enter an expected <span>hours amount</span></InputCaption>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <InputAdornment
                    autoFocus
                    inputRef={ref}
                    icon="hours"
                    placeholder="Type expected hours amount here"
                    position="end"
                    type="number"
                    value={value || ''}
                    onChange={onChange}
                />
            </Grid>
        </Grid>
    );
});
