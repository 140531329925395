import React, {FC, useCallback, useMemo} from 'react';

import {Autocomplete, Box, TextField, Typography, useTheme} from '@mui/material';

import {CheckboxIcon} from '../../assets/icons';
import {ISelectOption} from '../../models';
import {AnimateHeight, ChipsList, InputCaption} from '..';

type CountryType = ISelectOption & {code: string};

interface ILocation {
    options: CountryType[];
    values?: ISelectOption[];
    onChange: (values: ISelectOption[]) => void;
}

const LocationsMulti: FC<ILocation> = (props): JSX.Element => {
    const [searchValue, setSearchValue] = React.useState<CountryType | null>(null);

    const theme = useTheme();

    const values: any = useMemo(() => {
        return props.values ? props.values.map(option => ({id: option.value, name: option.text})) : [];
    }, [props.values]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: ISelectOption | null) => {
        if (newValue?.value) {
            const isExists = props.values?.find(item => item.value === newValue.value);

            if (!isExists) {
                const result = props.values?.length ? [...props.values, newValue] : [newValue];

                props.onChange(result);
            }

            setSearchValue(null);
        }
    };

    const handleDelete = useCallback((item: any) => {   // FIXME remove any
        const result = props.values ? props.values.filter(option => option.value !== item.id) : [];

        props.onChange(result);
    }, [props]);

    const isSelected = useCallback((id: string) => values.find((option: {id: string}) => option.id === id), [values]);

    const handleOptionLabel = (option: ISelectOption) => {
        return option.text;
    };

    return (
        <>
            <InputCaption>
                Select a <span>country</span> (optional)
                <Typography
                    component="b"   // fix DOM validation error <p> inside <p>
                    sx={{
                        display: 'block',
                        margin: '0 auto',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main,
                        textAlign: 'center',
                    }}
                    textAlign="center"
                    variant="body2"
                >
                    Select as many countries as you want. Please note: if you don’t specify a country we will provide candidates from any country
                </Typography>
            </InputCaption>

            <Autocomplete
                clearOnBlur
                blurOnSelect
                handleHomeEndKeys
                id="countries"
                fullWidth
                getOptionLabel={handleOptionLabel}
                noOptionsText=""
                options={props.options || []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        placeholder="Select a country"
                        variant="standard"
                    />
                )}
                renderOption={(props, option: CountryType) => (
                    <Box
                        {...props}
                        component="li"
                        sx={{
                            '& > img': {mr: 2},
                            '& > span': {flexGrow: 1},
                            color: isSelected(option.value) ? 'primary.main' : 'default',
                            display: 'flex',
                            width: '100%'
                        }}
                    >
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        <span>{option.text} ({option.code})</span>
                        {isSelected(option.value) ? <CheckboxIcon fill={theme.palette.primary.main} height={22} width={22} /> : null}
                    </Box>
                )}
                selectOnFocus
                sx={{mb: 3}}
                value={searchValue}
                onChange={handleChange}
            />

            <AnimateHeight isVisible={true}>
                <ChipsList items={values} onDelete={handleDelete} />
            </AnimateHeight>

        </>
    );
};

export default React.memo(LocationsMulti);
