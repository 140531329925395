import {useQuery} from '@tanstack/react-query';

import {metaService} from '../api';
import {GET_LOCATIONS} from '../constants';

export const useLocations = () => {
    const query = useQuery([GET_LOCATIONS], metaService.getLocations, {
        staleTime: 30 * 60 * 1000,
        select: (data) => data,
        onSuccess: (data) => {
            // stateContext.dispatch({ type: ActionTypesAuth.SET_DATA, payload: data });
        }
    });

    return {
        ...query,
        isLocationLoading: query.isLoading || query.isFetching,
        countries: query.data?.countries,
        languages: query.data?.languages,
        refetchChannels: query.refetch
    };
};
