import React from 'react';
import {Outlet} from 'react-router-dom';

import {Container, styled} from '@mui/material';

import {Logo} from '../../../components';
import theme from '../../../theme';

const Main = styled('main')`
  min-height: 100vh;
  margin-bottom: -48px;
  padding-bottom: 48px;
  background-color: ${theme.palette.lightGray.light};
`;

const Header = styled('header')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: ${theme.palette.lightGray.light};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .logo {
    width: 120px;
    height: 30px;
  }
`;

const ApplicationLayout = () => {
    return (
        <Main>
            <Container
                sx={{
                    pt: '80px',
                    pl: '20px',
                    pr: '20px',
                    mb: '48px'
                }}
            >
                <Header>
                    <Logo color="black"/>
                </Header>
                <Outlet/>
            </Container>
        </Main>
    );
};

export default ApplicationLayout;
