import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';

import {Box, Dialog} from '@mui/material';

import {CloseIcon} from '../../assets/icons';
import theme from '../../theme';
import LoadingMask from '../LoadingMask';

type DialogPreviewImageProps = {
    open: boolean;
    url: string;
    onClose: () => void;
};

const DialogPreviewImage: React.FC<DialogPreviewImageProps> = ({open, url, onClose}) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const image = new Image();
        const loaded = () => setIsLoading(false);

        setIsLoading(true);
        image.addEventListener('load', loaded);
        image.src = url;

        return () => image.removeEventListener('load', loaded);
    }, [url]);

    return (
        <Dialog
            maxWidth="lg"
            open={open}
            sx={{
                '.MuiDialog-paper': {
                    overflowY: 'visible',
                    width: '100%',
                    m: 0,
                    p: '20px'
                },
                '.close-icon': {
                    width: '48px',
                    height: '48px',
                    borderRadius: '50%',
                    backgroundColor: theme.palette.white.main,
                    p: 2,
                    ml: 'calc(100% - 48px)',
                    mb: '24px',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 0.8
                    }
                },
                'img': {
                    borderRadius: '24px'
                }
            }}
            onClose={onClose}
        >
            {url ? (
                <>
                    <Box onClick={onClose}>
                        <CloseIcon/>
                    </Box>

                    {isLoading ? <LoadingMask/> : (
                        <Box
                            component={motion.div}
                            initial={{opacity: 0, scale: 0}}
                            animate={{opacity: 1, scale: 1}}
                            transition={{
                                delay: 0.2,
                                duration: 0.3,
                                ease: 'easeInOut',
                            }}
                            sx={{
                                height: '70vh',
                                backgroundImage: `url(${url})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPositionX: 'center',
                            }}
                        />
                    )}

                </>
            ) : (
                <LoadingMask/>
            )}

        </Dialog>
    );
};

export default React.memo(DialogPreviewImage);
