import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';

import {Box, Stack, styled, Typography} from '@mui/material';

import {MD} from '../../constants';
import {EDashboardNotification} from '../../models';
import theme from '../../theme';
import {animationAlertBox} from '../Animations';

import {DashboardNotification} from './index';

const GridParent = styled('div')<{ hasBanner: boolean }>`
  display: grid;
  gap: 24px;
  grid-template-areas: ${props => props.hasBanner
        ? (
            '"profile"\n' +
                  '"banner"\n' +
                  '"overview"\n' +
                  '"work"\n' +
                  '"matches"'
        )
        : (
            '"profile"\n' +
                  '"overview"\n' +
                  '"work"\n' +
                  '"matches"'
        )};

  @media (min-width: ${MD}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: ${props => props.hasBanner
        ? (
            '"banner banner"\n' +
                    '"profile overview"\n' +
                    '"work matches"'
        )
        : (
            '"profile overview"\n' +
                    '"work matches"'
        )};
  }

  @media (min-width: 1350px) {
    grid-template-columns:  400px repeat(2, 1fr);
    grid-template-areas: ${props => props.hasBanner
        ? (
            '"profile banner banner"\n' +
                    '"profile overview overview"\n' +
                    '"profile work matches"'
        )
        : (
            '"profile overview overview"\n' +
                    '"profile work matches"'
        )};
    grid-auto-rows: auto 1fr;

  }
`;

export const WhiteWrapperBox = styled('div')`
  padding: 24px;
  background-color: ${theme.palette.white.main};
  border-radius: 24px;

  @media (min-width: ${MD}) {
    padding: 40px;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;

    img {
      flex-shrink: 0;
    }

    @media (min-width: ${MD}) {
      font-size: 16px;
    }
  }
`;

interface IProps {
    children: React.ReactNode;
    hasBanner: boolean;
    notifications?: Array<{
        name?: string;
        type: EDashboardNotification;
    }>;
    title: string;
}

const DashboardLayout: React.FC<IProps> = ({children, hasBanner, notifications, title}) => {
    return (
        <Box>
            <Typography
                sx={{
                    mt: '40px',
                    mb: '24px',
                    fontWeight: '600 !important',
                    fontSize: '16px !important',
                    [theme.breakpoints.up('md')]: {
                        mt: '48px',
                        fontSize: '24px !important'
                    }
                }}
                variant="h1">
                {title}
            </Typography>
            <Stack spacing="16px" sx={{mb: notifications && notifications.length > 0 ? '24px' : null}}>
                <AnimatePresence>
                    {
                        notifications?.map((notification, i) => (
                            <motion.div
                                {...animationAlertBox(i)}
                                key={notification.type}
                            >
                                <DashboardNotification
                                    {...notification}
                                />
                            </motion.div>
                        ))
                    }
                </AnimatePresence>
            </Stack>
            <GridParent hasBanner={hasBanner}>
                {children}
            </GridParent>
        </Box>
    );
};

export default React.memo(DashboardLayout);
