import React, {createContext, Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import {ConnectOptions, RemoteParticipant} from 'twilio-video';
import {useRoom} from 'use-twilio-video';

import {Client, Conversation, Message, Paginator, Participant} from '@twilio/conversations';

import {emailService, freelancerService, matchesService, notificationService} from '../api';
import {VIDEO_CALL} from '../constants';
import {useAuth} from '../hooks';
import {ECallType, EParticipantType, formatMeta, IActiveCallMeta, ICall, IConversationAttributes, IConversationMeta, IRoom} from '../models';
import {differenceTime, getLastMessage} from '../utils';

const types = [ECallType.CALLING_VIDEO, ECallType.CALLING_AUDIO];

interface TwilioContextProps extends Omit<IRoom, 'connectRoom' | 'disconnectRoom'> {
    activeCall: IActiveCallMeta | null;
    calls: ICall[] | null;     // found incoming call from another conversation (not selected now)
    client: Client | null;
    loading: boolean;

    // selected Conversation
    conversationActive: Conversation | null;
    messages: Message[];
    meta: IConversationMeta | null;
    typing: string;
    //////////

    allUnreadMessagesCount: number;
    conversations: Conversation[];
    duration: string;           // call duration 07:57;
    isCompact: boolean;         // fullscreen or minimize component
    isDragActive: boolean;
    clean(): void;
    initClient(): Promise<Conversation[]>;
    setActiveCall: Dispatch<SetStateAction<IActiveCallMeta | null>>;
    setActiveConversation: (conversation: Conversation | null) => void;
    setIsCompact: Dispatch<SetStateAction<boolean>>;
    setIsDragActive: Dispatch<SetStateAction<boolean>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setMessages: Dispatch<SetStateAction<Message[]>>;
    setMeta: Dispatch<SetStateAction<IConversationMeta | null>>;
    setToken: Dispatch<SetStateAction<string>>;
    setTyping: Dispatch<SetStateAction<string>>;

    // Video/Audio Calls
    finishCall(conversationId: string): Promise<void>;  // remove conversation.attribute for detect finish by all participants
    startCall(conversationId: string, options: ConnectOptions): void;          // set conversation.attribute for detect incoming call by all participants
}

const TwilioContext = createContext<TwilioContextProps>({} as TwilioContextProps);

type TwilioProviderProps = { children: React.ReactNode };

const TwilioProvider = ({children}: TwilioProviderProps) => {
    const [activeCall, setActiveCall] = useState<IActiveCallMeta | null>(null);
    const [allUnreadMessagesCount, setAllUnreadMessagesCount] = useState(0);
    const [calls, setCalls] = useState<ICall[] | null>(null);
    const [clientTwilio, setClient] = useState<Client | null>(null);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const {isClient, isFreelancer, isImpersonal, user} = useAuth();
    const [isCompact, setIsCompact] = useState(false);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isInitiator, setIsInitiator] = useState(false);  // who start a call for showing Outgoing/Incoming message
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string>('');

    // Selected conversation
    const [conversationActive, setActiveConv] = useState<Conversation | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [meta, setMeta] = useState<IConversationMeta | null>(null);
    const [typing, setTyping] = useState<string>('');
    /////////////////////////

    const conversationActiveRef = useRef<Conversation | null>(null);
    const conversationsRef = useRef<Conversation[]>([]);

    // user friendly duration from start
    const [duration, setDuration] = useState<string>('');
    // call started at time
    const [timestamp, setTimestamp] = useState<number | null>(null);

    conversationActiveRef.current = conversationActive;
    conversationsRef.current = conversations;

    const {
        error,
        isCameraOn,
        isMicrophoneOn,
        localParticipant,
        remoteParticipants,
        room,
        connectRoom,
        disconnectRoom,
        toggleCamera,
        toggleMicrophone
    } = useRoom() as IRoom;

    const getUnreadMessagesCount = useCallback(async (isInit?: boolean) => {
        const responses = await Promise.all(conversationsRef.current.map(it => it.getUnreadMessagesCount()));
        let total = 0;

        responses.forEach(async (count, index) => {
            const conversation = conversationsRef.current[index] as any;

            // skip active conversation
            if (isInit || !conversationActiveRef.current || (conversationActiveRef.current && conversation.sid !== conversationActiveRef.current.sid)) {
                if (conversation?.meta) {
                    // new message detected
                    if ((conversation.meta as IConversationMeta).unreadMessagesCount !== count) {
                        const messages = await conversation.getMessages();

                        (conversation.meta as IConversationMeta).lastMessage = getLastMessage(messages.items);
                    }

                    (conversation.meta as IConversationMeta).unreadMessagesCount = count || 0;
                }
                total += count || 0;
            }

            // init history horizont
            if (isNil(count)) {
                conversation.updateLastReadMessageIndex(0);
            }
        });

        setAllUnreadMessagesCount(total);
    }, []);

    const initClient = useCallback(async (): Promise<Conversation[]> => {
        // Now, if hasNextPage is true
        // call nextPage() to get the records instead of getSubscribedConversations()
        const processPages = async (paginator: Paginator<Conversation>) => {
            if (paginator.hasNextPage) {
                const nextPaginator = await paginator.nextPage();

                conversationsRef.current = [...nextPaginator.items, ...conversationsRef.current];
                setConversations(conversationsRef.current);
                processPages(nextPaginator);
            } else {
                // console.log('END OF RECORDS');
            }
        };

        // if Client already inited then update conversations list
        if (clientTwilio) {
            const paginator = await clientTwilio.getSubscribedConversations();

            conversationsRef.current = [...paginator.items];
            setConversations(conversationsRef.current);

            await processPages(paginator);

            return conversationsRef.current;
        }

        // if it first time then init cline, get conversations list and subscribe events
        const token = await notificationService.getTwilioToken();
        const client = await Client.create(token);
        const paginator = await client.getSubscribedConversations();

        client.on('connectionStateChanged', (state) => {
            if (state === 'connecting') console.log('Connecting to Twilio…');
            if (state === 'connected') console.log('You are connected.');
            if (state === 'disconnecting') console.log('Disconnecting from Twilio…');
            if (state === 'disconnected') console.log('Disconnected.');
            if (state === 'denied') console.log('Failed to connect.');
        });

        client.on('conversationUpdated', ({conversation}) => {
            // new conversation detected
            if (!conversationsRef.current.find(it => it.sid === conversation.sid)) {
                const refetch = async () => {
                    const newValues = [...conversationsRef.current, conversation];
                    const messages = await conversation.getMessages();

                    await conversation?.updateLastReadMessageIndex(0);    // set history horizont for the new conversation
                    await updateMeta(conversation, messages.items);

                    conversationsRef.current = newValues;

                    setConversations(newValues);
                    getUnreadMessagesCount();
                };

                refetch();
            } else {
                findIncomingCalls();
                getUnreadMessagesCount();

                // Send Proposal, reject and accept
                // update state and components will be rerendered
                if (conversationsRef.current) {
                    setConversations([...conversationsRef.current]);
                }
            }
        });

        client.on('tokenAboutToExpire', async () => {
            const token = await notificationService.getTwilioToken();

            setToken(token);
            client.updateToken(token);
        });

        client.on('tokenExpired', async () => {
            const token = await notificationService.getTwilioToken();

            setToken(token);
            client.updateToken(token);
        });

        conversationsRef.current = [...paginator.items];
        setConversations(conversationsRef.current);
        await processPages(paginator);
        findIncomingCalls();
        getUnreadMessagesCount(true);

        setClient(client);
        setToken(token);

        return conversationsRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientTwilio, getUnreadMessagesCount]);

    const clean = useCallback(async () => {
        setClient(null);
        setActiveConv(null);
        setConversations([]);
    }, []);

    const join = async (): Promise<void> => {
        if (conversationActiveRef.current) {
            if (conversationActiveRef.current.status !== 'joined') {
                await conversationActiveRef.current.join();
            }

            listeners();

            (window as any).www = (conversationActiveRef.current as any)._events;

            const messages = await conversationActiveRef.current.getMessages();

            setMessages(messages.items);

            // get participants
            await updateMeta(conversationActiveRef.current, messages.items);
        }
    };

    // If Freelancer initiates a conversation, he could only send one message
    // so find does message from the Client exists?
    const getIsInitByFreelancer = (messages: Message[], remoteParticipant?: Participant) => {
        return isFreelancer && !messages.find(it => it.participantSid === remoteParticipant?.sid);
    };

    const getMetaData = async (conversation: Conversation, messages: Message[]): Promise<IConversationMeta> => {
        const participants = await conversation.getParticipants();
        const localParticipant = participants.find(it => it.identity === user?.email);
        const remoteParticipant = participants.find(it => it.identity !== user?.email && !(it.attributes as any)?.isAdmin);  // Admin could be added to Conversation too for the admin panel
        const unreadMessagesCount = await conversation.getUnreadMessagesCount() || 0;

        const isInitByFreelancer = getIsInitByFreelancer(messages, remoteParticipant);

        return formatMeta({
            isInitByFreelancer,
            localParticipant,
            remoteParticipant,
            unreadMessagesCount,
        });
    };

    const findIncomingCalls = () => {
        // try to find incoming calls through all conversations
        const calls = conversationsRef.current.filter(it => types.includes((it.attributes as unknown as IConversationAttributes).call))
            .map(it => ({conversationId: it.sid, type: (it.attributes as unknown as IConversationAttributes).call}));

        setCalls(calls);
    };

    const listeners = useCallback((isUnsubscribe?: boolean) => {
        if (!conversationActiveRef.current) return;

        const addFn = (message: Message) => {
            if (message.conversation.sid !== conversationActiveRef.current?.sid) return;  // not sure why but got messages from different Conversation

            setMessages((messages: Message[]) => {
                const newMessages = message.body ? [...messages, message] : [...messages];
                const uniq = uniqBy(newMessages, 'sid');        // don't know why, but we see duplicate messages some times

                // if this is first message lets check if initiator Freelancer then allow only one message
                if (uniq.length <= 2 && conversationActiveRef.current) {
                    updateMeta(conversationActiveRef.current, uniq);
                }

                return uniq;
            });

            // if it from remote participant send history horizont to BE
            if ((message as any).state.author !== user?.email) {
                emailService.readMessage(message.conversation.sid, (message as any).state.sid);
            }

            if (!isImpersonal) {
                conversationActiveRef.current.setAllMessagesRead();
            }
        };
        const typingFn = (member: Participant) => setTyping(member.identity || '');
        const typingEndFn = (member: Participant) => setTyping('');

        if (isUnsubscribe) {
            conversationActiveRef.current.off('messageAdded', addFn);
            conversationActiveRef.current.off('typingStarted', typingFn);
            conversationActiveRef.current.off('typingEnded', typingEndFn);
        } else {
            conversationActiveRef.current.on('messageAdded', addFn);
            conversationActiveRef.current.on('typingStarted', typingFn);
            conversationActiveRef.current.on('typingEnded', typingEndFn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setActiveConversation = useCallback((newConversation: Conversation | null) => {
        if (conversationActiveRef.current) {
            listeners(true);
        }

        setActiveConv(newConversation);
    }, [listeners]);

    const startCall = useCallback(async (conversationId: string, options: ConnectOptions) => {
        const conversation = conversations?.find(conversation => conversation.sid === conversationId);
        const mutate = isClient ? matchesService : freelancerService;
        const isVideo = !location.pathname.includes(`${VIDEO_CALL}/audio`);

        if (!conversation) return;

        setTimestamp(null);
        setActiveCall({
            ...(conversation as unknown as { meta: IConversationMeta }).meta,
            pathname: location.pathname
        });

        // set conversation attribute VIDEO_CALL
        // for showing incoming call for remote participant
        if (!location.pathname.includes('scheduled-')) {
            await mutate.chatStartCall(conversationId, isVideo);
        }

        await connectRoom({token, options});
    }, [conversations, isClient, token, connectRoom, setActiveCall]);

    const finishCall = useCallback(async (conversationId: string): Promise<any> => {
        const author = isClient ? EParticipantType.CLIENT : EParticipantType.FREELANCER;
        const conversation = conversations?.find(conversation => conversation.sid === conversationId);
        const mutate = isClient ? matchesService : freelancerService;

        // who leave room first - create a call info row message
        // with author + duration
        if (room?.participants.size) {
            // added new message into the list about Call and author
            // Incommin/Outgoing Call + duration
            conversation?.prepareMessage()
                .setBody(`Room Name: ${room?.name}`)
                .setAttributes({
                    author,
                    isInitiator,
                    duration: differenceTime(new Date(), new Date(timestamp || 0)),
                    isAudioOnly: !(room as any)?._options.video,
                    roomSid: room?.sid || ''
                })
                .build()
                .send();
        }

        await disconnectRoom();

        setActiveCall(null);
        setTimestamp(null);
        setIsCompact(false);

        return await mutate.chatFinishCall(conversationId);
    }, [conversations, isClient, isInitiator, timestamp, room, disconnectRoom, setActiveCall]);

    const updateMeta = async (conversation: Conversation, messages: Message[]) => {
        // get participants
        const data = await getMetaData(conversation, messages);
        const newMeta = {
            ...data,
            lastMessage: getLastMessage(messages),
            // unreadMessagesCount: conversation?.sid === conversationActive?.sid ? 0 : data.unreadMessagesCount
        };

        setMeta(newMeta);
        (conversation as any).meta = newMeta;
    };

    // call duration
    useEffect(() => {
        let id: number;

        if (timestamp) {
            id = window.setInterval(() => {
                const diff = new Date(Date.now() - timestamp);

                setDuration(diff.toUTCString().split(' ')[4]);
            }, 1000);
        } else {
            setDuration('--:--:--');
        }

        return () => {
            clearInterval(id);
        };
    }, [timestamp]);

    useEffect(() => {
        const isBothParticipantsExists = !!room?.participants.size;

        // if no participats then this is initiator
        setIsInitiator(!isBothParticipantsExists || false);

        // if participant exist then start count call duration
        // else - waiting for 'participantConnected' connected event
        if (isBothParticipantsExists) {
            setTimestamp(Date.now());
        }

        if (room) {
            const participantConnected = (participant: RemoteParticipant) => {
                setTimestamp(Date.now());
            };

            room.on('participantConnected', participantConnected);

            return () => {
                room.off('participantConnected', participantConnected);
            };
        }
    }, [room]);

    useEffect(() => {
        if (conversationActiveRef.current) {
            join();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationActiveRef.current]);

    return (
        <TwilioContext.Provider
            value={{
                activeCall,
                calls,
                client: clientTwilio,
                loading,

                conversationActive,
                messages,
                meta,
                typing,

                allUnreadMessagesCount,
                conversations,

                duration,
                isCompact,
                isDragActive,

                clean,
                initClient,
                setActiveCall,
                setActiveConversation,
                setIsCompact,
                setIsDragActive,
                setLoading,
                setMessages,
                setMeta,
                setToken,
                setTyping,

                // TWILIO ROOM
                error,
                isCameraOn,
                isMicrophoneOn,
                localParticipant,
                remoteParticipants,
                room,
                toggleCamera,
                toggleMicrophone,

                // Video/Audio Calls
                finishCall,
                startCall,
            }}
        >
            {children}
        </TwilioContext.Provider>
    );
};

export {TwilioContext, TwilioProvider};
