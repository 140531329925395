import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {useParams} from 'react-router';
import {useLocation, useNavigate} from 'react-router-dom';

import {Box, styled, Theme, useMediaQuery} from '@mui/material';
import {useQuery, useQueryClient} from '@tanstack/react-query';

import {matchesService} from '../../api';
import {CLIENT_MATCHES, CLIENT_ROLES, GET_MATCHED, LG, MATCHES, RouteClientPortal, SM} from '../../constants';
import {useAuth, useTwilio} from '../../hooks';
import {EMatchStatus} from '../../models';
import theme from '../../theme';
import LikeButtonWithText from '../Button/LikeButtonWithText';
import {BackButton, ContinueButton, Portal, showToastError} from '../index';
import LoadingMask from '../LoadingMask';

import FreelancerCertifications from './components/FreelancerCertifications';
import FreelancerPersonalRecommendation from './components/FreelancerPersonalRecommendation';
import {
    FreelancerAboutInfo,
    FreelancerCaseStudies,
    FreelancerFitsBudget,
    FreelancerMatchesList,
    FreelancerRateInfo,
    FreelancerTopInfo,
    FreelancerWhyThisMatch,
    ModalButtonReject
} from './components';

const Wrp = styled('div')<{ hasRecommendation?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 40px;
  padding-bottom: 170px;
  padding-right: 20px;
  margin-right: -20px;
  overflow: hidden;

  @media (min-width: ${SM}) {
    display: grid;
    grid-template-areas: ${props => props.hasRecommendation
        ? (
            '"backButton ."\n' +
                    '"nameBox nameBox"\n' +
                    '"rateBox fitBudgetBox"\n' +
                    '"matchReasonBox matchReasonBox"\n' +
                    '"personalRecommendationBox personalRecommendationBox" \n' +
                    '"aboutBox aboutBox" \n' +
                    '"matchesBox matchesBox" \n' +
                    '"certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox"'
        ) : (
            '"backButton ."\n' +
                    '"nameBox nameBox"\n' +
                    '"rateBox fitBudgetBox"\n' +
                    '"matchReasonBox matchReasonBox"\n' +
                    '"aboutBox aboutBox" \n' +
                    '"matchesBox matchesBox" \n' +
                    '"certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox"'
        )};
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${LG}) {
    padding-bottom: 48px;
    grid-template-areas: ${props => props.hasRecommendation
        ? (
            '"backButton . ." \n' +
                    '"nameBox nameBox nameBox" \n' +
                    '"matchReasonBox matchReasonBox rateBox" \n' +
                    '"matchReasonBox matchReasonBox fitBudgetBox" \n' +
                    '"personalRecommendationBox personalRecommendationBox personalRecommendationBox" \n' +
                    '"aboutBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox" \n' +
                    '"matchesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox"'
        )
        : (
            '"backButton . ." \n' +
                    '"nameBox nameBox nameBox" \n' +
                    '"matchReasonBox matchReasonBox rateBox" \n' +
                    '"matchReasonBox matchReasonBox fitBudgetBox" \n' +
                    '"aboutBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox" \n' +
                    '"matchesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox"'
        )};
    grid-template-columns: repeat(3, auto);
  }

  @media (min-width: 1400px) {
    grid-template-areas: ${props => props.hasRecommendation
        ? (
            '"backButton . . . "\n' +
                    '"nameBox nameBox nameBox rateBox "\n' +
                    '"nameBox nameBox nameBox fitBudgetBox "\n' +
                    '"matchReasonBox matchReasonBox personalRecommendationBox personalRecommendationBox "\n' +
                    '"aboutBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox "\n' +
                    '"matchesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox "'
        )
        : (
            '"backButton . . . "\n' +
                    '"nameBox nameBox nameBox rateBox "\n' +
                    '"nameBox nameBox nameBox fitBudgetBox "\n' +
                    '"matchReasonBox matchReasonBox matchReasonBox matchReasonBox "\n' +
                    '"aboutBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox "\n' +
                    '"matchesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox "'
        )};
    grid-template-columns: 540px auto 223px 293px;
    gap: 24px;
  }

  @media (min-width: ${LG}) {
    padding-top: 56px;
  }

  @media (min-width: 1728px) {
    overflow: visible;
  }
`;

export const Item = styled('div')<{
    gridArea: string;
    padding?: string;
    backgroundColor?: string;
    backgroundImage?: string;
    boxShadow?: string;
    borderColor?: string;
    overflowVisible?: boolean;
    widthXl?: string;
}>`
  grid-area: ${props => props.gridArea};
  padding: ${props => props.padding ? props.padding : '24px'};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : theme.palette.white.main};
  background-image: ${props => props.backgroundImage ? props.backgroundImage : 'none'};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  border: ${props => props.borderColor ? `2px solid ${props.borderColor}` : 'unset'};
  box-shadow: ${props => props.boxShadow ? props.boxShadow : 'unset'};
  overflow: ${props => props.overflowVisible ? 'visible' : 'hidden'};

  @media (min-width: 1400px) {
    width: ${props => props.widthXl ? props.widthXl + 'px' : 'auto'};
  }
`;

const PublicFreelancerProfilePageForClient = () => {
    const {isImpersonal} = useAuth();
    const [isLiked, setIsLiked] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const twilioClient = useTwilio();

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const queryClient = useQueryClient();
    const {matchId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const {
        isLoading,
        data,
    } = useQuery([CLIENT_MATCHES, Number(matchId)], () => matchesService.getMatch(Number(matchId)), {
        enabled: !!matchId,
        staleTime: 15 * 60 * 1000,
        select: ((response) => response.data),
        onError: (error) => showToastError(error as AxiosError)
    });

    const handleBackMove = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const handleReject = useCallback(() => {
        queryClient.invalidateQueries([GET_MATCHED]);       // refetch needed for Dynamic ClientProfile list
        queryClient.invalidateQueries([CLIENT_ROLES, data?.roleId, MATCHES]); // refetch needed for My Matches list
        handleBackMove();
    }, [data?.roleId, handleBackMove, queryClient]);

    const handleLikeClick = useCallback(async (active: boolean) => {
        const mutate = active ? matchesService.setMatcheLike : matchesService.setMatcheUnLike;
        const minimum = new Promise((reject) => setTimeout(() => reject(true), 1000));    // show preloader minimum 1 sec to prevent flicking

        setIsLiked(active);
        setIsSubmitting(true);

        try {
            await Promise.all([minimum, mutate(Number(matchId))]);
            queryClient.invalidateQueries([MATCHES, Number(matchId)]);
            queryClient.invalidateQueries([CLIENT_ROLES, data?.roleId, MATCHES]);
            queryClient.invalidateQueries([GET_MATCHED]);
        } catch (error) {
            showToastError(error as AxiosError);
            setIsLiked(!active);
        }

        setIsSubmitting(false);
    }, [data?.roleId, matchId, queryClient]);

    const handleMessageClick = async () => {
        // if Accepted then conversation already created
        // and we should redirect to MyHires page
        if (data?.dynamicProfile.status === EMatchStatus.ACCEPTED && location?.state?.engagementId) {
            navigate(`${RouteClientPortal.myHireDetails}/${location?.state?.engagementId}/messages`);

            return;
        }

        // if it is new BE will init new room and retirn ID
        // or BE will return ID of existed room
        setIsSubmitting(true);
        try {
            const conversationId = await matchesService.getChatRoom(Number(matchId));

            twilioClient.invalidateQueries();   // Contact list can miss new room
            navigate(`${RouteClientPortal.inbox}/${conversationId}`);
        } catch (error) {
            showToastError(error as AxiosError);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (data) {
            setIsLiked([EMatchStatus.LIKED, EMatchStatus.LIKED_IN_TOUCH].includes(data.dynamicProfile.status));
        }
    }, [data]);

    // Wrong screen position when opening freelancer detailed profile second time
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            {isLoading && <LoadingMask/>}

            {!isLoading && data?.dynamicProfile && (
                <Wrp hasRecommendation={!!data?.recommendation}>
                    <BackButton
                        sx={{
                            gridArea: 'backButton',
                            alignSelf: 'flex-start',
                            justifySelf: 'flex-start',
                            [theme.breakpoints.up('lg')]: {
                                mb: '8px'
                            }
                        }}
                        onClick={handleBackMove}
                    />
                    <Item gridArea="nameBox">
                        {data && (
                            <FreelancerTopInfo
                                data={{
                                    avatar: data.dynamicProfile.avatar,
                                    businessModels: data.dynamicProfile.businessModels,
                                    country: data.dynamicProfile.country || '',
                                    isAvailableForNewProject: !!data.dynamicProfile.isAvailableForNewProject,
                                    isOpenToHireRole: !!data.dynamicProfile.isOpenToHireRole,
                                    timezones: data.dynamicProfile.timezones,
                                    firstName: data.dynamicProfile.firstName,
                                    lastName: data.dynamicProfile.lastName,
                                    yearsOfExperience: data.dynamicProfile.yearsOfExperience,
                                }}
                            >
                                {
                                    lgUp ? (
                                        <Box
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    gridArea: 'buttons',
                                                    justifySelf: 'flex-end',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    gap: '16px'
                                                },
                                                [theme.breakpoints.up(1400)]: {
                                                    flexWrap: 'nowrap'
                                                }
                                            }}
                                        >

                                            {
                                                ![
                                                    EMatchStatus.ACCEPTED,
                                                    EMatchStatus.REJECTED_BY_CLIENT,
                                                    EMatchStatus.REJECTED_BY_FREELANCER,
                                                    EMatchStatus.REJECTED_IN_TOUCH,
                                                    EMatchStatus.REJECTED_LIKED
                                                ].includes(data.dynamicProfile.status) && (
                                                    <ModalButtonReject
                                                        isAskForNewRole={!!data?.askForNewRole}
                                                        isRequired={!!data?.forceRejectionFeedback}
                                                        matchId={Number(matchId)}
                                                        firstName={data?.dynamicProfile.firstName || ''}
                                                        onReject={handleReject}
                                                    />
                                                )
                                            }

                                            {data.dynamicProfile.status !== EMatchStatus.ACCEPTED && (
                                                <LikeButtonWithText
                                                    active={isLiked}
                                                    loading={isSubmitting}
                                                    onClick={handleLikeClick}
                                                >
                                                    Like
                                                </LikeButtonWithText>
                                            )}

                                            <ContinueButton
                                                disabled={isImpersonal}
                                                sx={{
                                                    width: 'auto !important',
                                                    'div': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                                variant="contained"
                                                onClick={handleMessageClick}
                                            >
                                                Message
                                            </ContinueButton>
                                        </Box>
                                    ) : (
                                        <Portal fullWidth order={1} visibleUntil={LG}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: '12px',
                                                    'button': {
                                                        width: 'calc(50% - 6px) !important',
                                                        [theme.breakpoints.up('md')]: {
                                                            width: 'calc(33% - 8px) !important'
                                                        },
                                                    },
                                                }}
                                            >
                                                {data.dynamicProfile.status !== EMatchStatus.ACCEPTED && (
                                                    <LikeButtonWithText
                                                        active={isLiked}
                                                        loading={isSubmitting}
                                                        onClick={handleLikeClick}
                                                    >
                                                        Like
                                                    </LikeButtonWithText>
                                                )}
                                                <ContinueButton
                                                    disabled={isImpersonal}
                                                    sx={{
                                                        width: 'auto !important',
                                                        'div': {
                                                            justifyContent: 'center'
                                                        }
                                                    }}
                                                    variant="contained"
                                                    onClick={handleMessageClick}
                                                >
                                                    Message
                                                </ContinueButton>

                                                {
                                                    ![
                                                        EMatchStatus.ACCEPTED,
                                                        EMatchStatus.REJECTED_BY_CLIENT,
                                                        EMatchStatus.REJECTED_BY_FREELANCER,
                                                        EMatchStatus.REJECTED_IN_TOUCH,
                                                        EMatchStatus.REJECTED_LIKED
                                                    ].includes(data.dynamicProfile.status) && (
                                                        <ModalButtonReject
                                                            isRequired={!!data?.forceRejectionFeedback}
                                                            matchId={Number(matchId)}
                                                            firstName={data?.dynamicProfile.firstName || ''}
                                                            onReject={handleReject}
                                                        />
                                                    )
                                                }

                                            </Box>
                                        </Portal>
                                    )
                                }
                            </FreelancerTopInfo>
                        )}
                    </Item>
                    <Item
                        gridArea="rateBox"
                        backgroundColor={theme.palette.purple.main}
                        widthXl="293"
                    >
                        <FreelancerRateInfo
                            rate={data.dynamicProfile.rate}
                            workTypes={[...data.dynamicProfile.workTypes.common, ...data.dynamicProfile.workTypes.additional]}
                        />
                    </Item>
                    <Item
                        gridArea="fitBudgetBox"
                        boxShadow="0 0 50px rgba(126, 95, 255, 0.2)"
                        overflowVisible
                        widthXl="293"
                    >
                        <FreelancerFitsBudget
                            isClient
                            firstName={data.dynamicProfile.firstName}
                            lastName={data.dynamicProfile.lastName}
                            ratesMatch={data.dynamicProfile.ratesMatch}
                        />
                    </Item>
                    <Item
                        gridArea="matchReasonBox"
                        borderColor={theme.palette.green.dark}
                        boxShadow="0px 0px 50px rgba(103, 221, 66, 0.15)"
                    >
                        <FreelancerWhyThisMatch data={data.dynamicProfile}/>
                    </Item>

                    {
                        data.recommendation && (
                            <Item
                                gridArea="personalRecommendationBox"
                                boxShadow="0 24px 50px rgba(126, 95, 255, 0.12)"
                                backgroundImage="linear-gradient(259.97deg, rgba(126, 95, 255, 0.2) 1.64%, rgba(126, 95, 255, 0) 49.75%, rgba(255, 255, 255, 0.5))"
                                widthXl="540"
                            >
                                <FreelancerPersonalRecommendation data={data.recommendation}/>
                            </Item>
                        )
                    }

                    <Item gridArea="aboutBox" widthXl="540">
                        <FreelancerAboutInfo
                            bio={data.dynamicProfile.bio || ''}
                            brands={data.dynamicProfile.brands}
                        />
                    </Item>
                    <Item gridArea="matchesBox" widthXl="540">
                        <FreelancerMatchesList data={data.dynamicProfile}/>
                    </Item>
                    <Box
                        sx={{
                            gridArea: 'certificationsAndCaseStudiesBox',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            [theme.breakpoints.up(1400)]: {
                                gap: '24px'
                            }
                        }}
                    >
                        <Item gridArea="caseStudiesBox">
                            <FreelancerCaseStudies
                                items={data.dynamicProfile.caseStudies}
                            />
                        </Item>
                        <Item gridArea="certificationsBox">
                            <FreelancerCertifications
                                items={data.dynamicProfile.certificates}
                            />
                        </Item>
                    </Box>
                </Wrp>
            )}

        </div>
    );
};

export default React.memo(PublicFreelancerProfilePageForClient);
