import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';

import {Box, Grid, Theme, Typography} from '@mui/material';
import {SxProps} from '@mui/system';

import {CheckCurvyIcon} from '../../../assets/icons';
import theme from '../../../theme';
import Tooltip from '../../Tooltip/Tooltip';

interface IProps {
    id?: string;
    label: string | React.ReactNode;
    selected: boolean;
    size?: 'small' | 'large';
    subtitle?: string;
    sx?: SxProps<Theme>;
    onClick: (id?: string) => void;
}

const CheckButton: React.FC<IProps> = ({id, label, selected, size = 'large', subtitle, sx, onClick}) => {
    return (
        <Box
            component={motion.div}
            whileHover={{
                scale: 1.025
            }}
            whileTap={{
                scale: 1
            }}
            sx={{
                backgroundColor: selected ? '#7E5FFF' : '#F5F2FF',
                border: '2px solid',
                borderColor: selected ? '#7E5FFF' : '#F5F2FF',
                borderRadius: '12px',
                cursor: 'pointer',
                height: size === 'large' ? 60 : 'auto',
                // minWidth: selected ? 'unset' : '189px', // fixme: check later for responsive solutions (concerns WorkType screen)
                padding: size === 'large' ? '15px 10px 14px 24px' : '12px 16px',
                boxShadow: selected && size === 'large' ? '0px 0px 50px rgba(126, 95, 255, 0.5)' : '0px 0px 50px rgba(126, 95, 255, 0)',
                transition: 'border-color .3s',
                '&:hover': {
                    borderColor: '#7E5FFF'
                },
                '&:focus-visible': {
                    outlineColor: '#7E5FFF',
                    borderColor: '#7E5FFF'
                },
                [theme.breakpoints.up('lg')]: {
                    padding: size === 'large' ? '15px 13px 10px 22px' : '12px 16px',
                },
                [theme.breakpoints.up('xl')]: {
                    height: size === 'large' ? 69 : 'auto',
                    padding: size === 'large' ? '15px 12px 11px 22px' : '12px 16px',
                },
                ...sx
            }}
            tabIndex={0}
            onClick={() => onClick(id)}
            onKeyPress={() => onClick(id)}
        >
            <Tooltip title={label}>
                <Grid
                    container
                    spacing={1}
                    wrap="nowrap"
                    alignItems="center"
                    sx={{
                        height: '100%'
                    }}
                >
                    <Grid
                        item
                        xs
                        sx={{
                            width: selected ? 'calc(100% - 38px)' : '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            [theme.breakpoints.up('sm')]: {
                                width: selected ? 'calc(100% - 42px)' : '100%',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: selected ? 600 : 400,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: selected ? '#FFFFFF' : '#121416',
                                userSelect: 'none',
                                marginTop: '4px',
                                marginLeft: '-2px',
                                display: '-webkit-box',
                                maxWidth: '100%',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                [theme.breakpoints.up('sm')]: {
                                    marginTop: 0,
                                    marginLeft: 0
                                }
                            }}
                            variant="body1"
                        >
                            {label}
                            {subtitle && (
                                <>
                                    <br/>
                                    <Typography variant="caption">{subtitle}</Typography>
                                </>
                            )}
                        </Typography>
                    </Grid>
                    <AnimatePresence>
                        {
                            selected && size === 'large' && (
                                <Grid item
                                    component={motion.div}
                                    initial={{opacity: 0, scale: 0}}
                                    animate={{opacity: 1, scale: 1}}
                                    exit={{opacity: 0, scale: 0}}
                                    sx={{
                                        pl: '0 !important',
                                        'svg': {
                                            pt: '8px',
                                            width: '38px',
                                            height: '38px',
                                            [theme.breakpoints.up('sm')]: {
                                                pt: '6px',
                                                width: '40px',
                                                height: '40px'
                                            },
                                            [theme.breakpoints.up('md')]: {
                                                pt: '4px',
                                                mb: '-2px',
                                                width: '44px',
                                                height: '44px',
                                            },
                                            [theme.breakpoints.up('xl')]: {
                                                mb: '-4px',
                                            }
                                        }
                                    }}
                                >
                                    <CheckCurvyIcon/>
                                </Grid>
                            )
                        }
                    </AnimatePresence>
                </Grid>
            </Tooltip>
        </Box>
    );
};

export default React.memo(CheckButton);
