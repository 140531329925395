import React, {Fragment, useEffect, useMemo} from 'react';

import {styled, Typography} from '@mui/material';
import {Message} from '@twilio/conversations';

import {useConversation, useTwilio} from '../../../../hooks';
import {ICallMessageAttributes, IMessageAttributes} from '../../../../models';
import theme from '../../../../theme';

import CallRow from './components/CallRow';
import {DateRow, FileRow, MessageRow, ProposalRow} from './components';


const OuterWrp = styled('div')`
  overflow: hidden;
`;

const InnerWrp = styled('div')`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
`;

const MessagesList = styled('div')`
  display: grid;
  gap: 24px;
  padding: 0 24px;
`;


const EmptyPlaceholder = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IProps {
    conversationId: string;
}

const ChatMainMessages: React.FC<IProps> = ({conversationId}) => {
    const {meta} = useConversation(conversationId);
    const {setAllMessageRead} = useTwilio();

    const name = useMemo(() => {
        const {name} = meta?.remoteParticipant || {};

        return name ? name.split(' ')[0] : 'participant';
    }, [meta]);

    const {messages} = useConversation(conversationId);

    const renderRow = (message: Message, index: number) => {
        const {proposalId, roomSid} = message.attributes as ICallMessageAttributes & IMessageAttributes;

        if (proposalId) {
            return <ProposalRow conversationId={conversationId} data={message}/>;
        } else if (roomSid) {
            return <CallRow data={message}/>;
        } else if (message.type === 'media') {
            return <FileRow conversationId={conversationId} data={message}/>;
        }

        return (
            <MessageRow
                conversationId={conversationId}
                data={message}
                isNewAuthor={message.author !== messages[index - 1]?.author}
            />
        );
    };

    // erase unread badge when user select conversation
    useEffect(() => {
        // if (meta?.unreadMessagesCount) {
        //     setAllMessageRead();
        // }
        setAllMessageRead();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta]);

    return (
        <OuterWrp>
            <InnerWrp>
                {
                    messages.length > 0 ? (
                        <MessagesList>

                            {messages.map((message, index) => (
                                <Fragment key={message.sid}>
                                    <DateRow currentDate={message.dateUpdated}
                                        prevDate={messages[index - 1]?.dateUpdated}/>

                                    {renderRow(message, index)}

                                </Fragment>
                            ))}
                        </MessagesList>
                    ) : (
                        <EmptyPlaceholder>
                            <img src="/assets/images/waving-hand-icon.png" width="64" height="64"
                                alt="waving hand icon"/>
                            <Typography
                                sx={{
                                    mt: '32px',
                                    mb: '2px',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    lineHeight: 1.5,
                                    color: theme.palette.black.main
                                }}
                            >
                                Say hi to {name}!
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    color: theme.palette.gray.main
                                }}
                            >
                                Start talking with {name} about your new role!
                            </Typography>
                        </EmptyPlaceholder>
                    )
                }
            </InnerWrp>
        </OuterWrp>
    );
};

export default React.memo(ChatMainMessages);
