import React, {useState} from 'react';
import clsx from 'clsx';

import {Box, styled} from '@mui/material';

import {useAuth} from '../../hooks';
import theme from '../../theme';
import LoadingMask from '../LoadingMask';

import {Button} from './index';

export const Like = styled('div')(({theme}) => ({
    '&': {
        cursor: 'pointer',
        height: '65px',
        width: '65px',
        backgroundImage: "url('/assets/images/web_heart_animation_transparent.png')",
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '2900%',
    },
    '&:hover, &.active': {
        backgroundPosition: 'right',
    },
    '&.active:hover': {
        opacity: 0.8,
    },
    '&:hover:not(.active)': {
        opacity: 0.5,
    },
    '&.isAnimate': {
        animation: 'heart-burst .8s steps(28) 1',
    },
    '@keyframes heart-burst': {
        from: {backgroundPosition: 'left'},
        to: {backgroundPosition: 'right'}
    },
}));

interface LikeButtonWithTextProps {
    active: boolean;
    children?: React.ReactNode;
    loading: boolean;
    onClick: (value: boolean) => void;
}

const LikeButtonWithText: React.FC<LikeButtonWithTextProps> = ({active, children, loading, onClick}) => {
    const {isImpersonal} = useAuth();
    const [isAnimate, setIsAnimate] = useState(false);

    const handleAnimationEnd = () => {
        setIsAnimate(false);
        onClick(true);
    };

    // if no like then show animation
    // if active then set no like without animation
    // if animation in progress then ignore click
    const handleClick = () => {
        if (isAnimate || loading || isImpersonal) return;

        if (!active) {
            setIsAnimate(true);
        } else {
            onClick(false);
        }
    };

    return (
        <Button
            sx={{
                padding: '0 34px 0 0 !important',
                color: theme.palette.purple.main,
                backgroundColor: theme.palette.lilac.main,
                '&:hover': {
                    color: theme.palette.purple.main,
                    backgroundColor: theme.palette.lilac.main,
                    '.like-icon': {
                        backgroundPosition: 'right',
                    }
                },
            }}
            onClick={handleClick}
        >
            <Like
                className={clsx({
                    active,
                    isAnimate,
                    'like-icon': true
                })}
                onAnimationEnd={handleAnimationEnd}
                onPointerDown={handleClick}
            >
                {loading && (
                    <Box sx={{opacity: 0.2, m: 2, mt: '7px'}}>
                        <LoadingMask hasNoSpace={true} size={50} thickness={1}/>
                    </Box>
                )}
            </Like>
            {children}
        </Button>
    );
};

export default React.memo(LikeButtonWithText);
