import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';
import {useLocation} from 'react-router-dom';

import {Box} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {applicationService} from '../../../api';
import {EmojiSmile} from '../../../assets/icons';
import {
    AnimatePageWrp,
    ChannelsList,
    ContinueButton,
    LoadingMask,
    PageTopTitle,
    Portal,
    showToastError,
    showToastSuccess,
    SubChannelsList,
} from '../../../components';
import {CHANNELS, RouteCreateApplicantAccount, SUB_CHANNELS} from '../../../constants';
import {useApplication, useChannels, useNavigateUTM} from '../../../hooks';
import {IChannel, ISubChannel} from '../../../models';
import theme from '../../../theme';

const Channels = () => {
    const navigate = useNavigateUTM();
    const isSubChannelsView = useLocation().pathname.includes(SUB_CHANNELS);
    const {application, isApplicationLoading, refetchApplication} = useApplication();
    const {channels, isChannelsLoading} = useChannels();
    const [selectedChannelIds, setSelectedChannelIds] = useState<number[]>([]);
    const [selectedSubChannelIds, setSelectedSubChannelIds] = useState<number[]>([]);

    const selectedChannelsList = (channelsIds: number[], channels?: IChannel[]) => {
        return channels?.filter(channel => channelsIds.includes(channel.id) && channel.subchannels.length > 0) || [];
    };

    const {mutate, isLoading: isSubmitting} = useMutation(
        applicationService.channelsSubmit,
        {
            onSuccess() {
                showToastSuccess('Channels were saved successfully!');
                navigate(RouteCreateApplicantAccount.expertise);
                refetchApplication();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const selectedCount = useMemo(() => {
        return channels?.filter(channel => selectedChannelIds.includes(channel.id)).length || 0;
    }, [channels, selectedChannelIds]);

    const isSubChannelsExists = useMemo(() => {
        const result = channels?.find(channel => selectedChannelIds.includes(channel.id) && channel.subchannels.length);

        return !!result;
    }, [channels, selectedChannelIds]);

    const handleClickChannel = useCallback((channel: IChannel) => {
        const ids = selectedChannelIds.filter(id => id !== channel.id);

        // unselect by second click
        if (ids.length !== selectedChannelIds.length) {
            setSelectedChannelIds(ids);
        } else {
            ids.push(channel.id);
            // is able to choose up to 4 marketing channels maximum
            if (ids.length > 4) {
                ids.shift();
            }
            setSelectedChannelIds(ids);
        }
    }, [selectedChannelIds]);

    const handleClickSubChannelChange = useCallback((subChannel: ISubChannel) => {
        const ids = selectedSubChannelIds.filter(id => id !== subChannel.id);

        // if it is new then add into the selected arr
        if (ids.length === selectedSubChannelIds.length) {
            setSelectedSubChannelIds([...ids, subChannel.id]);
        } else {
            setSelectedSubChannelIds(ids);
        }
    }, [selectedSubChannelIds]);

    const handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
        (event.target as HTMLButtonElement).blur(); // fix remove focus for FireFox

        if (selectedCount < 1) {
            showToastError('Please select a channel.');

            // switch to SubChannels view
        } else if (isSubChannelsExists && !isSubChannelsView) {
            navigate(`/${CHANNELS}/${SUB_CHANNELS}`);
        } else if (validateSubChannel()) {
            submit();
        } else {
            showToastError('Pick at least one sub-channel per a channel.');
        }
    };

    const submit = () => {
        const payload = channels?.filter(channel => selectedChannelIds.includes(channel.id))
            .map(channel => ({
                ...channel,
                subchannels: channel.subchannels.filter(subChannel => selectedSubChannelIds.includes(subChannel.id))
            }));

        if (payload && !isEqual(payload, application?.channels)) {
            mutate(payload);
        } else {
            navigate(RouteCreateApplicantAccount.expertise);
        }
    };

    useEffect(() => {
        if (!application) {
            return;
        }
        const subChannelsIds: number[] = [];
        const channelsIds = application.channels.map(channel => {
            const subChannelIds = channel.subchannels.map(subChannel => subChannel.id);

            subChannelsIds.push(...subChannelIds);

            return channel.id;
        });

        setSelectedChannelIds(channelsIds);
        setSelectedSubChannelIds(subChannelsIds);
    }, [application]);

    const validateSubChannel = () => {
        const isNoSubchannelsSelected = channels?.find(channel => {
            // if channel selected also subchannel should be selected if exists
            if (selectedChannelIds.includes(channel.id)) {
                if (channel.subchannels.length) {
                    const isAnySelected = channel.subchannels.find(subchannel => selectedSubChannelIds.includes(subchannel.id));

                    if (!isAnySelected) {
                        console.log('   -', channel.name, 'without selected subchannel');
                    }

                    return !isAnySelected;
                } else {
                    return false;
                }
            }

            return false;
        });

        return !isNoSubchannelsSelected;
    };

    const isValid = selectedCount >= 1;
    const isSubmitBtn = !isValid || isApplicationLoading || isChannelsLoading || isSubmitting;

    return (
        <AnimatePageWrp maxWidth={{md: 729, lg: 947}}>
            <PageTopTitle
                title={
                    isSubChannelsView
                        ? <>Cool, please select the <span>sub-channels</span> below for each of the channels you are
                            really good at</>
                        : <>Okay, now what <span>marketing channels</span> are you an expert in?</>
                }
                subtitle={
                    isSubChannelsView
                        ? 'You can select as many you would like.'
                        : <>
                            You can select up to four channels as not everybody can be amazing at everything<EmojiSmile/>
                        </>
                }
                titleMaxWidth={{md: 538, lg: 947}}
            />

            {
                (isChannelsLoading)
                    ? (
                        <LoadingMask/>
                    ) : (
                        isSubChannelsView
                            ? selectedChannelsList(selectedChannelIds, channels).map((item) => (
                                <SubChannelsList
                                    key={item.id}
                                    channel={item}
                                    selectedSubChannelIds={selectedSubChannelIds}
                                    onClick={handleClickSubChannelChange}
                                />
                            ))
                            : <ChannelsList
                                items={channels || []}
                                selectedIds={selectedChannelIds}
                                onClick={handleClickChannel}
                            />
                    )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        my: 5,
                        mb: '40px',
                        display: 'block'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isSubmitBtn}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isSubmitBtn}
                    hasArrow
                    variant="contained"
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};

export default Channels;
