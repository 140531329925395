import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, Grid, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import {clientService, metaService} from '../../../../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    BusinessModelsList,
    ChipsList,
    ClientTopTitleVerticals,
    ContinueButton,
    GrayButton,
    InputCaption,
    LoadingMask,
    Portal,
    showToastError,
    showToastSuccess,
    Verticals,
    WhiteButton
} from '../../../../../../components';
import {
    CHANNEL,
    CLIENT_ROLES,
    GET_BUSINESS_MODELS,
    GET_VERTICALS,
    RouteClientPortal,
    TOOLS
} from '../../../../../../constants';
import {useClientRole} from '../../../../../../hooks';
import {
    ERoleStatusFilter,
    IVertical,
    IVerticalBussinessModelRequest,
    IVerticalsResponse,
    OptionType
} from '../../../../../../models';
import theme from '../../../../../../theme';

export const VerticalsAndBusinessModel = () => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const queryClient = useQueryClient();
    const {roleId} = useParams();
    const {clientRole, isClientLoading, refetchClient} = useClientRole(Number(roleId));
    const [businessModels, setBusinessModels] = useState<string[]>([]);
    const [verticals, setVerticals] = useState<IVertical[]>([]);

    const navigate = useNavigate();

    let nextStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${TOOLS}`;
    const prevStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${CHANNEL}`;

    const {
        isLoading,
        isFetching,
        data: verticalsAndRecommendedData
    } = useQuery([GET_VERTICALS], metaService.getVerticals, {
        select: ((response: IVerticalsResponse) => ({
            recommended: uniqBy(response.recommended, 'name'),
            verticals: uniqBy(response.verticals, 'name')
        }))
    });

    const verticalsData = verticalsAndRecommendedData?.verticals || [];
    const verticalsRecommendedData = verticalsAndRecommendedData?.recommended || [];

    const {
        data: businessModelsData
    } = useQuery([GET_BUSINESS_MODELS], metaService.getBusinessModels, {
        select: (response: { businessModels: string[] }) => response.businessModels,
    });

    const isDirty = !isEqual(businessModels, clientRole?.businessModels)
        || !isEqual(verticals, clientRole?.verticals);

    const isValid = verticals?.length && businessModels?.length;

    const handleClickBusinessModel = useCallback((model: string) => {
        const result = businessModels?.filter(str => str !== model);

        // unselect by second click
        if (businessModels && result.length !== businessModels.length) {
            setBusinessModels(result);
        } else {
            setBusinessModels([model]);
        }
    }, [businessModels]);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (payload: IVerticalBussinessModelRequest) => clientService.verticalsSubmit(clientRole?.id || 0, payload),
        {
            onSuccess() {
                showToastSuccess('Verticals were saved successfully!');
                navigate(nextStep);
                refetchClient();
                queryClient.invalidateQueries([CLIENT_ROLES]);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleChangeVertical = useCallback((newValue: OptionType | null) => {
        const isAlreadyExists = verticals.find(item => item.name === newValue?.name);

        if (newValue?.name && !isAlreadyExists) {
            setVerticals([newValue as IVertical]);
        }
    }, [verticals]);

    const handleDeleteVertical = useCallback((option: OptionType) => {
        const newVerticals = verticals.filter(v => v !== option);

        setVerticals(newVerticals);
    }, [verticals]);

    const handleAddVertical = useCallback((option: OptionType) => {
        const isAlreadyExists = verticals.find(item => item.id === option.id);

        if (!isAlreadyExists) {
            setVerticals([option as IVertical]);
        }
    }, [verticals]);

    const handleSaveAndExit = () => {
        if (verticals?.length && businessModels?.length) {
            nextStep = `${RouteClientPortal.myRoles}/${ERoleStatusFilter.Drafts}/${roleId}`;
            mutate({businessModels, verticals, saveAndExit: true} as any); // fix GC-1172
        }
    };

    const handleSubmit = () => {
        if (!verticals?.length) {
            showToastError('Please add a Vertical.');
        } else if (!businessModels?.length) {
            showToastError('Please select Business Model.');
        } else if (isDirty) {
            mutate({businessModels, verticals});
        } else {
            navigate(nextStep);
        }
    };

    useEffect(() => {
        if (!clientRole) {
            return;
        }

        const {businessModels, verticals} = clientRole;

        setBusinessModels(businessModels);
        setVerticals(verticals);
    }, [clientRole]);

    const isSubmitBtnDisabled = isClientLoading || isLoading || isFetching || isSubmitting;
    const isSaveBtnDisabled = isSubmitBtnDisabled || !isDirty || !isValid;

    return (
        <AnimatePageWrp maxWidth={{md: 600, lg: 621}}>
            <ClientTopTitleVerticals desktopFontSize={24}/>

            {
                isClientLoading ? (
                    <LoadingMask/>
                ) : (
                    <Grid
                        container
                        maxWidth={611}
                        spacing={2}
                        rowSpacing={0}
                        sx={{
                            m: '0 auto',
                            [theme.breakpoints.up('sm')]: {
                                mt: '-8px'
                            },
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: '40px',
                                [theme.breakpoints.up('lg')]: {
                                    mb: '56px'
                                }
                            }}
                        >

                            <Verticals
                                options={verticalsData}
                                placeholder="Ex. Health & Wellness, Campaigns, SaaS"
                                value={verticals[0] || null}
                                onChange={handleChangeVertical}
                                onDelete={handleDeleteVertical}
                            />

                            <Typography
                                sx={{
                                    display: 'inline-block',
                                    marginTop: '24px',
                                    marginBottom: '12px',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: theme.palette.gray.main
                                }}
                                variant="caption"
                            >
                                Suggested verticals
                            </Typography>
                            <AnimateHeight isVisible={true}>
                                <ChipsList
                                    items={verticalsRecommendedData}
                                    selectedVerticals={verticals}
                                    onAdd={handleAddVertical}
                                />
                            </AnimateHeight>

                        </Grid>
                        <Grid item xs={12} sx={{mb: 2}}>
                            <InputCaption>
                                What is your <span>business model</span>?
                            </InputCaption>

                            <BusinessModelsList
                                items={businessModelsData || []}
                                selectedIds={businessModels}
                                onClick={handleClickBusinessModel}
                            />
                        </Grid>
                    </Grid>
                )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        justifyContent: 'center',
                        mt: '50px',
                        gap: '16px'
                    },
                    [theme.breakpoints.up('lg')]: {
                        mt: '56px'
                    },
                    '@media(max-height: 1400px)': {
                        pb: '60px'
                    }
                }}
                textAlign="center"
            >
                <WhiteButton
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={() => navigate(prevStep)}
                >
                    Back
                </WhiteButton>
                <ContinueButton
                    disabled={isSubmitBtnDisabled}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>

                <GrayButton
                    disabled={isSaveBtnDisabled}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>
            </Box>

            {
                isSmallScreen && (
                    <>
                        <Portal fullWidth order={1}>
                            <ContinueButton
                                disabled={isSubmitBtnDisabled}
                                hasArrow
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Continue
                            </ContinueButton>
                        </Portal>
                        <Portal order={2}>
                            <WhiteButton
                                onClick={() => navigate(prevStep)}
                            >
                                Back
                            </WhiteButton>
                        </Portal>
                        <Portal order={3}>
                            <GrayButton
                                disabled={isSaveBtnDisabled}
                                variant="contained"
                                onClick={handleSaveAndExit}
                            >
                                Save & exit
                            </GrayButton>
                        </Portal>
                    </>
                )

            }

        </AnimatePageWrp>
    );
};
