import {PROPOSALS} from './meta.api.routes';
import {TIMESHEETS} from './Router';

export const CLIENT = 'client';

export const CLIENT_DASHBOARD = `${CLIENT}/dashboard`;

export const CLIENT_ENGAGEMENT = `${CLIENT}/engagements`;

export const CLIENT_MATCHES = `${CLIENT}/matches`;

export const CLIENT_BILLING_ADDRESS = `${CLIENT}/payments/address`;

export const CLIENT_BILLING_CONTACT = `${CLIENT}/payments/contact`;

export const CLIENT_PAYMENTS = `${CLIENT}/payments/methods`;

export const CLIENT_ROLES = `${CLIENT}/roles`;

export const CLIENT_REGISTRATION = `${CLIENT}/registration`;

export const CLIENT_TIMESHEETS = `${CLIENT}/${TIMESHEETS}`;

export const GET_MATCHED = `${CLIENT_ROLES}/matched`;

export const GET_PROPOSALS = `${CLIENT}/${PROPOSALS}`;

export const SET_CLIENT_AVATAR = `${CLIENT}/avatar`;

export const SET_COMPANY_LOGO = `${CLIENT}/company/logo`;
