import React, {useState} from 'react';
import {AxiosError} from 'axios/index';
import {motion} from 'framer-motion';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {object, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, Typography} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {userService} from '../../../api';
import {
    AnimateHeight,
    ContinueButton,
    Input,
    LoginPageWrp,
    Logo,
    showToastError,
} from '../../../components';
import {animationAlertBox} from '../../../components/Animations';
import {RouteAuth} from '../../../constants';
import {errorIsPasswordConfirm, errorPassword, findFieldError, isPassword, isPasswordConfirm, newPassword} from '../../../utils';

const inputStyles = {
    mb: 0
};

const newPasswordSchema = object({
    ...newPassword,
}).refine(isPassword, errorPassword)
    .refine(isPasswordConfirm, errorIsPasswordConfirm);

export type NewPasswordInput = TypeOf<typeof newPasswordSchema>;

const NewPassword = () => {
    const [searchParams] = useSearchParams();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const email = searchParams.get('email');
    const token = searchParams.get('token');

    console.log('email', email);

    const navigate = useNavigate();

    const methods = useForm<NewPasswordInput>({
        resolver: zodResolver(newPasswordSchema),
    });

    const {
        handleSubmit,
        setError,
        watch,
    } = methods;

    const {mutate} = useMutation(
        userService.resetPassword,
        {
            onSuccess() {
                // showToastSuccess('Reset password email sent!');
                setIsSubmitted(true);
            },
            onError(error: AxiosError) {
                const password = findFieldError(error, 'password');

                if (password) {
                    setError('password', {type: 'custom', message: password.message});
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const onSubmitHandler: SubmitHandler<NewPasswordInput> = (values) => {
        if (!email || !token) {
            return;
        }

        const payload = {
            email: decodeURIComponent(email),
            newPassword: values.password,
            token,
        };

        mutate(payload);
    };

    const password = watch('password', '');

    return (
        <LoginPageWrp>
            {!isSubmitted ? (
                <>
                    <Box textAlign="center" onClick={() => navigate(RouteAuth.login)}>
                        <Logo color="#121416"/>
                    </Box>

                    <Typography
                        align="center"
                        variant="h1"
                        sx={{
                            mt: '40px',
                            mb: '32px',
                            fontWeight: '600 !important',
                            fontSize: '24px !important'
                        }}
                    >
                        Create new password
                    </Typography>
                    <FormProvider {...methods}>
                        <form
                            autoComplete="off"
                            noValidate
                            style={{width: '100%'}}
                            onSubmit={handleSubmit(onSubmitHandler)}
                        >
                            <Grid container spacing="32px">
                                <Grid item xs={12}>
                                    <Input
                                        autoFocus
                                        name="password"
                                        placeholder="Enter new password"
                                        type="password"
                                        sx={inputStyles}
                                    />

                                    <AnimateHeight isVisible={!!password?.length} isOverflowHidden={false}>
                                        <PasswordStrengthBar password={password} minLength={8}/>
                                    </AnimateHeight>

                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        name="passwordConfirm"
                                        placeholder="Repeat new password"
                                        type="password"
                                        sx={inputStyles}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <ContinueButton
                                        fullWidth
                                        sx={{
                                            'div': {
                                                justifyContent: 'center'
                                            }
                                        }}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Confirm
                                    </ContinueButton>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </>
            ) : (
                <Box
                    component={motion.div}
                    {...animationAlertBox(0)}
                    sx={{
                        textAlign: 'center',
                        'img': {
                            display: 'block',
                            width: '48px',
                            height: '48px',
                            m: '0 auto 40px'
                        }
                    }}
                >
                    <img src="/assets/images/done-icon.png" width="48" height="48" alt="done icon"/>
                    <Typography
                        sx={{
                            mb: '32px',
                            fontWeight: 600,
                            fontSize: '24px',
                        }}
                    >
                        Password has been changed successfully
                    </Typography>
                    <ContinueButton
                        sx={{
                            display: 'block',
                            m: '0 auto',
                            width: 'auto !important',
                            'div': {
                                justifyContent: 'center'
                            }
                        }}
                        variant="contained"
                        onClick={() => navigate(RouteAuth.login)}
                    >
                        Log in
                    </ContinueButton>
                </Box>
            )}
        </LoginPageWrp>
    );
};

export default NewPassword;
