import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Avatar, Box, IconButton, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {ArrowDown} from '../../../../../assets/icons';
import {BudgetTypeRow, ContinueButton, FoldingText} from '../../../../../components';
import ChatTopButtons from '../../../../../components/Inbox/components/ChatTopButtons';
import {LG, RouteFreelancerPortal, SM} from '../../../../../constants';
import {IEngagementFreelancer} from '../../../../../models';
import theme from '../../../../../theme';
import {formatCurrency, formatDate, getNameLetters, stringToColor} from '../../../../../utils';
import {EDisplayMode} from '../../MyWorkDetailsPage';
import {PauseEngagementBtn} from '../TabOverview/components';

const Wrapper = styled('div')<{ isVisible?: boolean }>`
  display: ${props => props.isVisible ? 'block' : 'none'};
  width: 100%;
  padding: 0 20px;
  flex-shrink: 0;
  overflow-y: auto;
  position: absolute;
  height: 100%;
  top: 0;
  left: ${props => props.isVisible ? 0 : '100%'};
  border-radius: 24px;
  border-top: unset;
  background-color: ${theme.palette.white.main};
  //transition: left .3s linear;
  will-change: left;

  @media (min-width: ${SM}) {
    width: 467px;
    padding: 24px;
    padding-bottom: 0;
    left: ${props => props.isVisible ? 'calc(100% - 448px)' : '100%'};
    border-left: 1px solid ${theme.palette.lightGray.main};
    max-height: inherit;
    overflow-y: auto;
  }

  @media (min-width: 1200px) {
    left: ${props => props.isVisible ? 'calc(100% - 467px)' : '100%'};
  }

  @media (min-width: 1400px) {
    position: static;
    width: 466px;
    will-change: unset;
    flex-grow: 0;
    border-left: none;
  }
`;

const TopInfo = styled('div')<{ isClient?: boolean }>`
  display: grid;
  align-items: center;
  column-gap: 16px;
  grid-template-areas: 
  'backButton avatar name'
  'backButton avatar experience';
  grid-template-columns: 24px 40px auto;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.palette.lightGray.main};

  @media (min-width: ${LG}) {
    grid-template-areas: ${props => props.isClient ? (
        '"avatar name rate"\n' +
            '"avatar experience rate"'
    ) : (
        '"avatar name name"\n' +
            '"avatar experience experience"'
    )};
    grid-template-columns: 80px auto auto;
  }
`;

const avatarStyles = {
    gridArea: 'avatar',
    width: '40px',
    height: '40px',
    backgroundColor: stringToColor('You'),
    fontSize: '14px',
    lineHeight: 1.2,
    fontWeight: 500,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    },
    [theme.breakpoints.up('lg')]: {
        width: '80px',
        height: '80px',
        fontSize: '30px',
    }
};

interface IProps {
    data: IEngagementFreelancer;
    isVisible?: boolean;
    onChatDetailsOpen: (isOpen: boolean) => void;
}

const ChatDetails: React.FC<IProps> = ({data, isVisible, onChatDetailsOpen}) => {
    const [currentTime, setCurrentTime] = useState('');
    const navigate = useNavigate();

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const {conversationSid} = data;

    const handleRoleDetailsClick = useCallback(() => {
        navigate(`${RouteFreelancerPortal.myWorkDetails}/${data.id}/${EDisplayMode.Overview}`);
    }, [data.id, navigate]);

    useEffect(() => {
        if (!data?.role?.timezone) return;

        let isMounted = true;
        const id = setInterval(() => {
            const value = new Intl.DateTimeFormat(undefined, {
                dateStyle: undefined,
                timeStyle: 'long',
                timeZone: data.role.timezone
            }).format(new Date());

            if (isMounted) {
                setCurrentTime(value);
            }
        }, 1000);

        return () => {
            isMounted = false;
            clearInterval(id);
        };
    }, [data?.role?.timezone]);

    return (
        <Wrapper isVisible={isVisible}>
            <TopInfo>
                {!lgUp && (
                    <IconButton
                        sx={{
                            ml: '-8px',
                            mr: '-8px',
                            width: '36px',
                            height: '36px',
                            gridArea: 'backButton',
                            'svg': {
                                transform: 'rotate(90deg)'
                            }
                        }}
                        onClick={() => onChatDetailsOpen(false)}
                    >
                        <ArrowDown/>
                    </IconButton>
                )}

                <Avatar
                    alt={data.clientName}
                    className="avatar"
                    src={data.role.client?.avatar}
                    sx={{...avatarStyles, backgroundColor: stringToColor(data.clientName)}}
                >
                    {getNameLetters(data.clientName)}
                </Avatar>
                <Typography
                    sx={{
                        gridArea: 'name',
                        alignSelf: 'flex-end',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.black.main,
                        'span': {
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '21px',
                            color: theme.palette.gray.main
                        },
                        [theme.breakpoints.up('lg')]: {
                            fontSize: '16px',
                            lineHeight: 1.5,
                        }
                    }}
                >
                    {data.clientName} <span>({data.role?.countries.join(', ')})</span><br/>
                </Typography>

                {
                    currentTime && (
                        <Typography
                            sx={{
                                gridArea: 'experience',
                                alignSelf: 'flex-start',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: 1.5,
                                color: theme.palette.gray.main
                            }}
                        >
                            Current local time: {currentTime}
                        </Typography>
                    )
                }
            </TopInfo>

            {!lgUp &&
                <Box
                    sx={{
                        padding: '16px 0',
                        borderBottom: `1px solid ${theme.palette.lightGray.main}`,
                    }}
                >
                    <ChatTopButtons conversationId={conversationSid} engagementStatus={data.status}/>
                </Box>
            }

            <Box
                sx={{
                    pt: '16px',
                    pb: '16px',
                    borderBottom: `1px solid ${theme.palette.lightGray.main}`,
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: 1.5,
                        color: theme.palette.purple.main,
                        'span': {
                            fontWeight: 400,
                            fontSize: '13px',
                            lineHeight: 1.5,
                            color: theme.palette.gray.main,
                        }
                    }}
                >
                    {formatCurrency(data.paid || 0, true)} <span>earned</span>
                </Typography>
                {data.nextBillingDate && (
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '13px',
                            lineHeight: 1.5,
                            color: theme.palette.gray.main,
                        }}
                    >
                        Next billing date: {formatDate(data.nextBillingDate)}
                    </Typography>
                )}

            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '24px',
                    pt: '16px',
                    pb: '16px',
                    borderBottom: `1px solid ${theme.palette.lightGray.main}`,
                }}
            >
                <BudgetTypeRow
                    budgetType={data.budgetType}
                    clientCompanyLogo={data.clientCompanyLogo}
                    clientCompanyName={data.clientCompanyName}
                    isFreelancerView={true}
                    isMonthly={data.isMonthly}
                    hourlyRate={data.rate}
                />

            </Box>

            <Box
                sx={{
                    mt: '16px',
                }}
            >
                <FoldingText
                    title="Role name"
                    text={data.name}
                    sx={{
                        '.text': {
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: 1.5,
                            color: theme.palette.black.main
                        }
                    }}
                />
            </Box>

            <Box
                sx={{
                    mt: '16px',
                    pt: '16px',
                    borderTop: `1px solid ${theme.palette.lightGray.main}`
                }}
            >
                <FoldingText
                    title="Role description"
                    text={data.description}
                />
            </Box>

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    width: 'auto',
                    m: '24px -24px 0',
                    p: '16px 24px 40px',
                    backgroundColor: theme.palette.white.main,
                    borderTop: `1px solid ${theme.palette.lightGray.main}`,
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '16px'
                }}
            >

                <PauseEngagementBtn
                    data={data}
                    fullWidth
                    sx={{
                        width: '100%',
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '8px',
                        'svg': {
                            width: '16px',
                            height: '16px'
                        },
                        'path': {
                            fill: 'currentColor'
                        },
                    }}
                />

                <ContinueButton
                    fullWidth
                    size="small"
                    sx={{
                        'div': {
                            justifyContent: 'center'
                        }
                    }}
                    variant="contained"
                    onClick={handleRoleDetailsClick}
                >
                    Role details
                </ContinueButton>
            </Box>
        </Wrapper>

    );
};

export default React.memo(ChatDetails);
