import {styled, Typography} from '@mui/material';

import theme from '../../../../theme';

export const InputCaption = styled(Typography)`
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${theme.palette.black.main};
`;

export const InputSubCaption = styled(Typography)`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.gray.main};
    padding-bottom: 16px;
`;
