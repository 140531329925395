import {any, object, string} from 'zod';

import {MAX_SAFE_INTEGER} from '../../../../constants';
import {EWorkType} from '../../../../models';
import {projectDescription, projectName} from '../../../../utils';

export const propasalSchema = object({
    description: projectDescription,
    isMonthly: any(),
    name: projectName,
    workType: string({
        required_error: 'Budget Type is required',
        invalid_type_error: 'Budget Type is required',
    }),

    // related fields
    rate: any(),
    weeklyEngagementHours: any(),

    // date range
    startDate: any(),
    endDate: any()
})
    .refine((data) => data.rate > 0, {
        path: ['rate'],
        message: 'Rate is required'
    })
    .refine((data) => Number(data.rate) <= MAX_SAFE_INTEGER / 100, {
        path: ['rate'],
        message: 'Invalid value'
    })
    // Please select your hourly rate (per hour)..
    .refine((data) => {
        const isHourly = data.workType === EWorkType.hourly;

        return isHourly ? Number(data.weeklyEngagementHours) > 0 : true;
    }, {
        path: ['weeklyEngagementHours'],
        message: 'Hours are required'
    })
    .refine((data) => data.workType !== EWorkType.hourly ? true : Number(data.weeklyEngagementHours) <= MAX_SAFE_INTEGER / 100, {
        path: ['weeklyEngagementHours'],
        message: 'Invalid value'
    })
    .refine((data) => !!data.startDate, {
        path: ['startDate'],
        message: 'Start Date is required'
    });
