import React from 'react';

import {AuthContext} from '../contexts';

export const useAuth = () => {
    const context = React.useContext(AuthContext);

    if (context) {
        return context;
    }

    throw new Error('useAuth must be used within a AuthContextProvider');
};
