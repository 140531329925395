import React from 'react';

const VideoCallIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3427_6570)">
                <path
                    d="M10.0941 4.104H2.49014C1.48723 4.104 0.666656 4.92457 0.666656 5.92749V12.0726C0.666656 13.0756 1.48723 13.8961 2.49014 13.8961H10.0941C11.097 13.8961 11.9176 13.0756 11.9176 12.0726V5.92749C11.9176 4.90634 11.097 4.104 10.0941 4.104Z"
                    fill="#3C94F7"/>
                <path
                    d="M15.9839 5.10652C15.8745 5.12475 15.7651 5.17946 15.674 5.23416L12.8293 6.8753V11.1058L15.6922 12.7469C16.221 13.0569 16.8775 12.8746 17.1874 12.3458C17.2786 12.1816 17.3333 11.9993 17.3333 11.7987V6.16414C17.3333 5.48945 16.6951 4.94241 15.9839 5.10652Z"
                    fill="#3C94F7"/>
            </g>
            <defs>
                <clipPath id="clip0_3427_6570">
                    <rect width="16.6667" height="16.6667" fill="white" transform="translate(0.666656 0.666504)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default VideoCallIcon;
