import React from 'react';

const MessageBubbleIcon = ({color}: {color?: string}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3253_530)">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M0.385809 15.9524C0.619358 16.0491 0.888189 15.9957 1.06694 15.8169L1.18919 15.6947L1.18919 15.6947C2.03666 14.8472 3.32872 14.6288 4.3895 15.1604C5.4642 15.699 6.67914 16.0005 7.96707 16C12.366 16.0019 15.9573 12.4841 15.9996 8.07842C16.0422 3.64456 12.4994 -1.90735e-05 8 -1.90735e-05C3.57114 -1.90735e-05 -0.0405777 3.63922 0.00034435 8.07534L6.69388e-07 15.375C-1.12217e-05 15.6277 0.15226 15.8557 0.385809 15.9524ZM7.96677 14.75C6.87625 14.7505 5.85291 14.4956 4.94956 14.0429L4.94955 14.0429C3.75792 13.4457 2.37851 13.499 1.25006 14.1073L1.25034 8.07245C1.25034 8.07044 1.25033 8.06843 1.25032 8.06642C1.21437 4.32437 4.2647 1.24998 8 1.24998C11.7975 1.24998 14.7857 4.32072 14.7497 8.06642C14.714 11.7795 11.6874 14.7517 7.96735 14.75H7.96677Z"/>
                <path
                    d="M6.125 8C6.125 8.43147 5.77522 8.78125 5.34375 8.78125C4.91228 8.78125 4.5625 8.43147 4.5625 8C4.5625 7.56853 4.91228 7.21875 5.34375 7.21875C5.77522 7.21875 6.125 7.56853 6.125 8Z"/>
                <path
                    d="M8.8125 8C8.8125 8.43147 8.46272 8.78125 8.03125 8.78125C7.59978 8.78125 7.25 8.43147 7.25 8C7.25 7.56853 7.59978 7.21875 8.03125 7.21875C8.46272 7.21875 8.8125 7.56853 8.8125 8Z"/>
                <path
                    d="M11.5 8C11.5 8.43147 11.1502 8.78125 10.7188 8.78125C10.2873 8.78125 9.9375 8.43147 9.9375 8C9.9375 7.56853 10.2873 7.21875 10.7188 7.21875C11.1502 7.21875 11.5 7.56853 11.5 8Z"/>
            </g>
            <defs>
                <clipPath id="clip0_3253_530">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default MessageBubbleIcon;
