import React, {FC, useCallback, useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Theme,
    useMediaQuery
} from '@mui/material';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import {clientService, freelancerService, matchesService} from '../../../../api';
import {CloseIcon} from '../../../../assets/icons';
import {
    BILLING,
    CLIENT,
    CLIENT_ROLES,
    FREELANCER_MATCHES,
    GET_MATCHED,
    MATCHE_WITH_ROLE,
    MATCHES,
    RouteClientPortal
} from '../../../../constants';
import {useAuth, useConversation} from '../../../../hooks';
import {EWorkType, IProposal, IProposalRequest} from '../../../../models';
import theme from '../../../../theme';
import {CLIENT_PROJECT_MAX_LENGTH, localToUTC} from '../../../../utils';
import {InputRetainerType} from '../../../Forms/InputRetainerTypes';
import {
    AnimateHeight,
    BlackBorderButton,
    CardSubTitle,
    CardTitle,
    ContinueButton,
    GradientBorderBox,
    Input,
    InputCaption,
    InputDate,
    InputWorkType,
    RedFilledButton,
    showToastError,
    showToastSuccess
} from '../../../index';

import ChatProposalSentView from './ChatProposalSentView';
import {propasalSchema} from './proposal.schema';

type PropasalInput = TypeOf<typeof propasalSchema>;

interface IProps {
    conversationId: string;
    data?: IProposal;
    isReadOnly?: boolean;   // is Author open it or already Accepted/Rejected
    open: boolean;
    onClose: (proposalId: number | null) => void;
}

const ChatSendProposal: FC<IProps> = ({conversationId, data, isReadOnly, open, onClose}) => {
    const {isClient, isFreelancer, isImpersonal} = useAuth();
    const {attributes: {matchId, roleId}, meta} = useConversation(conversationId);
    const queryClient = useQueryClient();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const isNewProposal = !data;

    const apiService = isClient ? matchesService : freelancerService;

    const methods = useForm<PropasalInput>({
        resolver: zodResolver(propasalSchema),
    });

    const {
        data: client,
    } = useQuery([CLIENT], clientService.getClient, {
        enabled: isClient,
        staleTime: 15 * 60 * 1000,
    });

    // if CLIENT - for the new get Role to populate Name and Description
    const {
        // isLoading,
        data: clientMatchindData
    } = useQuery([MATCHE_WITH_ROLE, matchId], () => matchesService.getMatchWithRole(matchId), {
        enabled: isClient && open && !!matchId,
        staleTime: 15 * 60 * 1000,
        select: ((response) => response.data),
    });

    // if Freelancer - for the new get Role to populate Name and Description
    const {
        // isLoading,
        data: freelancerMatchindData
    } = useQuery([FREELANCER_MATCHES, matchId], () => freelancerService.getMatchRole(matchId), {
        enabled: isFreelancer && open && !!matchId,
        staleTime: 15 * 60 * 1000,
        select: ((response) => response.data),
    });

    const roleData = clientMatchindData?.role || freelancerMatchindData;

    const {
        handleSubmit,
        formState: {isDirty},
        reset,
        setValue,
        watch
    } = methods;

    const description = watch('description', '');
    const rate = watch('rate');
    const weeklyEngagementHours = watch('weeklyEngagementHours');
    const workType = watch('workType', '');
    const isMonthly = watch('isMonthly', false);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (payload: IProposalRequest) => apiService.proposalSubmit(matchId, payload),
        {
            onSuccess(response) {
                console.log('proposalSubmit', response);
                showToastSuccess('Proposal has been created successfully!');
                reset({});
                onClose(response.data.id);
            },
            onError(error: AxiosError) {
                console.log(error);
                showToastError(error);
            },
        }
    );

    const handleAccept = useCallback(async () => {
        if (!data) return;
        await apiService.proposalAccept(data.id);

        // Role should be removed from the My Matches page and shown on the My Hires page.
        queryClient.invalidateQueries([MATCHES, Number(matchId)]);
        queryClient.invalidateQueries([CLIENT_ROLES, roleId, MATCHES]);
        queryClient.invalidateQueries([GET_MATCHED]);

        reset({});
        onClose(1);
    }, [apiService, data, matchId, roleId, queryClient, onClose, reset]);

    const handleClose = useCallback(() => {
        reset({});
        onClose(null);
    }, [onClose, reset]);

    const handleReject = useCallback(async () => {
        if (!data) return;
        await apiService.proposalReject(data.id);
        reset({});
        onClose(1);
    }, [apiService, data, onClose, reset]);

    const handleWorkTypeChange = useCallback((value: EWorkType | null) => {
        if (rate) {
            setValue('rate', '' as any);
        }
        if (weeklyEngagementHours) {
            setValue('weeklyEngagementHours', '' as any);
        }

        if (value === EWorkType.retainer) {
            setValue('isMonthly', true);
        } else {
            setValue('isMonthly', undefined);
        }
    }, [rate, weeklyEngagementHours, setValue]);

    const handleRetainerTypeChange = useCallback((value: boolean) => {
        setValue('isMonthly', value);
    }, [setValue]);

    const onSubmitHandler: SubmitHandler<PropasalInput> = (values: IProposalRequest) => {
        console.log('onSubmitHandler', values);
        mutate({
            ...values,
            rate: values.rate ? Number(values.rate) * 100 : 0,  // convert with cents
            startDate: localToUTC(values.startDate as unknown as Date).toISOString().split('T')[0]
        });
    };

    // populate form for new Proposal
    useEffect(() => {
        if (!roleData || !isNewProposal) {
            return;
        }
        const {projectName, projectDescription} = roleData;

        reset({name: projectName, description: projectDescription});
    }, [isNewProposal, roleData, reset]);

    // populate read-only form for view existing Proposal
    useEffect(() => {
        if (data && open && !isNewProposal) {
            const {description, isMonthly, name, startDate, weeklyEngagementHours, workType} = data;

            reset({
                description,
                isMonthly,
                name,
                rate: data.rate ? (data.rate / 100) : '', // convert without cents
                startDate,
                weeklyEngagementHours,
                workType
            });
        }
    }, [data, open, isNewProposal, reset]);

    // cut description 1000 char
    useEffect(() => {
        if (!description) return;

        if (description.length > CLIENT_PROJECT_MAX_LENGTH) {
            setValue('description', description.substring(0, CLIENT_PROJECT_MAX_LENGTH));
        }
    }, [description, setValue]);

    return (
        <Dialog
            fullWidth
            open={open}
            scroll="paper"
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor: theme.palette.white.main,
                    maxWidth: '728px',
                    borderRadius: '24px',
                    margin: 0,
                    width: '100%'
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    padding: '40px 16px 32px',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: 1.5,
                    textAlign: 'center',
                    color: theme.palette.black.main,
                    position: 'relative',
                    'img': {
                        width: '32px',
                        height: '32px'
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: '64px 64px 40px',
                        gap: '24px',
                        fontSize: '24px',
                        'img': {
                            width: '48px',
                            height: '48px'
                        },
                    }
                }}
            >
                <img src="/assets/images/proposal-icon.png" width="48" height="48" alt="proposal icon"/>
                {isNewProposal ? `Send Proposal to ${meta?.remoteParticipant?.name}` : `${meta?.remoteParticipant?.name}’s Proposal`}
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '18px',
                        right: '18px',
                        '.close-icon': {
                            width: '24px',
                            height: '24px',
                        },
                        'svg': {
                            width: '24px',
                            height: '24px',
                        }
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    p: '0 20px',
                    [theme.breakpoints.up('md')]: {
                        p: '0 64px',
                    }
                }}
            >
                {
                    isClient && client?.hasAnyUnverifiedPaymentMethods && (
                        <GradientBorderBox
                            sx={{
                                mb: '32px',
                                textAlign: 'center',
                                'img': {
                                    display: 'inline-block',
                                    width: '48px',
                                    height: '48px',
                                    mb: '24px'
                                },
                                [theme.breakpoints.up('md')]: {
                                    mb: '40px'
                                }
                            }}
                        >
                            <img
                                src="/assets/images/bank-card-icon.png"
                                width="48"
                                height="48"
                                alt="bank card icon"
                            />
                            <CardTitle
                                sx={{
                                    mb: '0',
                                    fontSize: '16px'
                                }}
                            >
                                Please add your payment details to proceed
                            </CardTitle>
                            <CardSubTitle
                                sx={{
                                    mb: '24px'
                                }}
                            >
                                Before you can submit a proposal you must have a payment method on file. Please go
                                to settings and add your payment information before proceeding.
                            </CardSubTitle>
                            <BlackBorderButton
                                fullWidth
                                size="small"
                                onClick={() => navigate(`${RouteClientPortal.profileSettings}/${BILLING}`)}
                            >
                                Go to billing settings
                            </BlackBorderButton>
                        </GradientBorderBox>
                    )
                }

                {
                    isNewProposal ? (
                        <Box
                            sx={{
                                filter: client?.hasAnyUnverifiedPaymentMethods ? 'grayscale(1)' : null,
                                opacity: client?.hasAnyUnverifiedPaymentMethods ? .7 : null,
                                pointerEvents: client?.hasAnyUnverifiedPaymentMethods ? 'none' : null
                            }}
                        >
                            <FormProvider {...methods}>
                                <form
                                    noValidate
                                    autoComplete="off"
                                >
                                    <InputCaption
                                        sx={{
                                            textAlign: 'left !important',
                                            mb: '16px !important'
                                        }}
                                    >
                                        Role name
                                    </InputCaption>

                                    <Input
                                        disabled={isSubmitting}
                                        name="name"
                                        placeholder="Marketing of a new product on IG and TikTok"
                                    />

                                    <InputCaption
                                        sx={{
                                            mt: '21px',
                                            textAlign: 'left !important',
                                        }}
                                    >
                                        Budget type
                                    </InputCaption>

                                    <InputWorkType name="workType" onChange={handleWorkTypeChange}/>

                                    <Box
                                        sx={{
                                            m: '0 -20px',
                                            [theme.breakpoints.up('md')]: {
                                                m: '0 -64px'
                                            }
                                        }}
                                    >
                                        <AnimateHeight isVisible={!!workType}>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gap: '16px',
                                                    p: '40px 20px 0',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    [theme.breakpoints.up('sm')]: {
                                                        p: '40px 64px 0',
                                                        gridTemplateColumns: 'repeat(3, 1fr)',
                                                    }
                                                }}
                                            >
                                                {
                                                    workType === EWorkType.retainer && (
                                                        <Box sx={{
                                                            width: '100%',
                                                            gridColumn: 'span 2',
                                                            [theme.breakpoints.up('sm')]: {
                                                                gridColumn: 'span 3',
                                                            }
                                                        }}>
                                                            <InputCaption
                                                                sx={{
                                                                    textAlign: 'left !important',
                                                                    mb: '24px !important'
                                                                }}
                                                            >
                                                                Choose Retainer type
                                                            </InputCaption>
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    mb: '24px'
                                                                }}
                                                            >
                                                                <InputRetainerType
                                                                    name="isMonthly"
                                                                    onChange={handleRetainerTypeChange}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                                <Box
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <InputCaption
                                                        sx={{
                                                            textAlign: 'left !important',
                                                            mb: '16px !important'
                                                        }}
                                                    >
                                                        {workType === EWorkType.fixed && 'Precise budget'}
                                                        {workType === EWorkType.retainer && 'Budget'}
                                                        {workType === EWorkType.hourly && 'Precise rate'}
                                                    </InputCaption>

                                                    {workType === EWorkType.retainer ? (
                                                        <Input
                                                            disabled={isSubmitting}
                                                            isInteger={true}
                                                            name="rate"
                                                            type="number"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment
                                                                    position="start">$</InputAdornment>,
                                                                endAdornment: <InputAdornment
                                                                    position="end">{isMonthly ? 'monthly' : 'weekly'}</InputAdornment>
                                                            }}
                                                            sx={{
                                                                'input::placeholder, input::-webkit-input-placeholder': {
                                                                    color: theme.palette.textGray.dark + '!important',
                                                                },
                                                            }}
                                                        />
                                                    ) : (
                                                        <Input
                                                            disabled={isSubmitting}
                                                            isInteger={true}
                                                            name="rate"
                                                            type="number"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment
                                                                    position="start">$</InputAdornment>,
                                                                endAdornment: <InputAdornment
                                                                    position="end">{workType === EWorkType.hourly ? 'hour' : ''}</InputAdornment>
                                                            }}
                                                            sx={{
                                                                'input::placeholder, input::-webkit-input-placeholder': {
                                                                    color: theme.palette.textGray.dark + '!important',
                                                                },
                                                            }}
                                                        />
                                                    )}

                                                </Box>
                                                {
                                                    workType === EWorkType.hourly && (
                                                        <Box
                                                            sx={{
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <InputCaption
                                                                sx={{
                                                                    textAlign: 'left !important',
                                                                    mb: '16px !important'
                                                                }}
                                                            >
                                                                Availability
                                                            </InputCaption>

                                                            <Input
                                                                disabled={isSubmitting}
                                                                name="weeklyEngagementHours"
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment
                                                                        position="end">hours/week</InputAdornment>
                                                                }}
                                                                sx={{
                                                                    'input::placeholder, input::-webkit-input-placeholder': {
                                                                        color: theme.palette.textGray.dark + '!important',
                                                                    },
                                                                }}
                                                            />

                                                        </Box>
                                                    )
                                                }
                                                <Box
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <InputCaption
                                                        sx={{
                                                            textAlign: 'left !important',
                                                            mb: '16px !important'
                                                        }}
                                                    >
                                                        Start date
                                                    </InputCaption>

                                                    <InputDate
                                                        disabled={isSubmitting}
                                                        disablePast
                                                        name="startDate"
                                                    />
                                                </Box>

                                            </Box>
                                        </AnimateHeight>
                                    </Box>

                                    <InputCaption
                                        sx={{
                                            mt: '40px',
                                            mb: '16px !important',
                                            textAlign: 'left !important',
                                        }}
                                    >
                                        Role description
                                    </InputCaption>

                                    <Input
                                        counter={CLIENT_PROJECT_MAX_LENGTH - (description?.length || 0)}
                                        disabled={isSubmitting}
                                        inputProps={{maxLength: CLIENT_PROJECT_MAX_LENGTH}}
                                        minRows={5}
                                        multiline
                                        name="description"
                                        placeholder="Add some description to give us more details of what needs to be done"
                                        variant="outlined"
                                    />
                                </form>
                            </FormProvider>
                        </Box>
                    ) : (
                        <>
                            <ChatProposalSentView data={data}/>
                        </>
                    )
                }
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'center',
                    padding: '0 20px 40px',
                    [theme.breakpoints.up('md')]: {
                        padding: '40px 40px 65px'
                    }
                }}
            >
                {isNewProposal
                    ? (
                        <>
                            <BlackBorderButton
                                fullWidth={mdDown}
                                onClick={handleClose}
                            >
                                Cancel
                            </BlackBorderButton>
                            <ContinueButton
                                fullWidth={mdDown}
                                disabled={isSubmitting || !isDirty || isImpersonal || client?.hasAnyUnverifiedPaymentMethods}
                                variant="contained"
                                sx={{width: mdDown ? '100%' : 'auto !important'}}
                                onClick={handleSubmit(onSubmitHandler)}
                            >
                                Send proposal
                            </ContinueButton>
                        </>
                    )
                    : (
                        isReadOnly
                            ? (
                                <BlackBorderButton
                                    fullWidth={mdDown}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </BlackBorderButton>
                            )
                            : (
                                <>
                                    <RedFilledButton
                                        disabled={isSubmitting || isImpersonal}
                                        fullWidth={mdDown}
                                        onClick={handleReject}
                                    >
                                        Reject
                                    </RedFilledButton>
                                    <ContinueButton
                                        fullWidth={mdDown}
                                        disabled={isSubmitting || isImpersonal || client?.hasAnyUnverifiedPaymentMethods}
                                        variant="contained"
                                        sx={{width: mdDown ? '100%' : 'auto !important'}}
                                        onClick={handleAccept}
                                    >
                                        Accept
                                    </ContinueButton>
                                </>
                            )
                    )
                }

            </DialogActions>
        </Dialog>
    );
};

export default React.memo(ChatSendProposal);
