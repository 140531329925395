import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import {useSearchParams} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, Link, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {userService, WIDGET_PAYLOAD} from '../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    AnimatePageWrpFadeIn,
    AsideCaption,
    AsideLetsConnect,
    Checkbox,
    ContinueButton,
    darkBgInputStyles,
    Input,
    InputPhone,
    LoginLink,
    showToastError,
    showToastSuccess,
    TermsAndPrivacyLabel
} from '../../../components';
import {COMPLETED, RouteAuth, RouteCreateApplicantAccount, RouteCreateClientAccount, RouteWidget} from '../../../constants';
import {useAuth, useNavigateUTM} from '../../../hooks';
import {IClientRole, IRegisterWidget} from '../../../models';
import theme from '../../../theme';
import {findFieldError, isWidget, registerSchemaClient, replaceSymbolsInPhone, utm} from '../../../utils';

type RegisterInput = TypeOf<typeof registerSchemaClient>;

const SignUpForm = () => {
    const [searchParams] = useSearchParams();
    const widgetId = searchParams.get('utm_campaign');
    const {login, logout} = useAuth();
    const navigate = useNavigateUTM();
    const isProd = process.env.REACT_APP_ENV === 'prod';
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const methods = useForm<RegisterInput>({
        resolver: zodResolver(registerSchemaClient),
    });

    // Calling the Register Mutation
    const {mutate, isLoading} = useMutation(
        ({passwordConfirm, termsAgree, ...userData}: RegisterInput) => {
            const updatedPhone = replaceSymbolsInPhone(userData.phone);
            const payload = {...userData, phone: updatedPhone};

            const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
            const data: IClientRole = widgetPayload ? JSON.parse(widgetPayload) : {};

            if (isWidget() && !widgetId) {
                return Promise.reject();
            }

            // if it is Widget then we already filled all steps and should show ThankYou page
            if (isWidget() && widgetId) {
                const {channel, verticals, ...rest} = data;
                const params: IRegisterWidget = {
                    ...payload,
                    ...rest,
                    channelId: channel.id,
                    subchannelIds: channel?.subchannels ? channel.subchannels.map(it => it.id) : [],
                    verticalIds: verticals.map(it => it.id || 0),
                    widgetId,
                };

                return userService.signUpWidget(params);

                // or create regular Client
            } else {
                return userService.signUpClient(payload);
            }
        },
        {
            // Create new account
            onSuccess() {
                showToastSuccess('Account was successfully created!');

                if (isWidget()) {
                    const {email, password} = getValues();

                    const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
                    const data: IClientRole = widgetPayload ? JSON.parse(widgetPayload) : {};

                    localStorage.setItem(WIDGET_PAYLOAD + widgetId, JSON.stringify({...data, view: COMPLETED}));

                    login(email, password, RouteWidget.confirmationCode + location.search);
                } else {
                    loginSilently();
                }
            },
            onError(error: AxiosError) {
                // context: 'CLIENT';
                // field: 'form.email';
                // message: 'email is already taken';

                const email = findFieldError(error, 'form.email');
                const phone = findFieldError(error, 'form.phone');

                if (email) {
                    // // User already has account as a CLIENT and can login
                    // if (email.context === ERole.CLIENT) {
                    //     showToastError(error);

                    //     // User trying to register CLIENT account but email already used for the APPLICANT.
                    //     // User should be redirected for login.
                    // } else if (email.context === ERole.APPLICANT) {
                    //     showToastError('this email is taken, try to login');
                    //     setError('emailTaken' as any, {type: 'custom', message: 'redirect to login'});
                    // }

                    showToastError('this email is taken, try to login');
                    setError('emailTaken' as any, {type: 'custom', message: 'redirect to login'});

                    //Show user-friendly error message except regex
                } else if (phone) {
                    showToastError('Phone number is invalid');
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const {
        handleSubmit,
        getValues,
        formState: {errors},
        setError,
        watch
    } = methods;

    const password = watch('password', '');

    const loginSilently = () => {
        const {email, password} = getValues();

        login(email, password, RouteCreateClientAccount.channels);
    };

    const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
        // 👇 Execute the Mutation
        mutate(values);
    };

    const pageContent = (
        <Box sx={{
            backgroundColor: theme.palette.black.main,
            margin: '0 -8px',
            padding: '0 20px 40px',
            [theme.breakpoints.up('sm')]: {
                backgroundColor: 'unset'
            },
            'form': {
                [theme.breakpoints.up('sm')]: {
                    maxWidth: '391px',
                    margin: '0 auto'
                }
            }
        }}>
            <AsideLetsConnect>
                Let’s find you perfect matches
            </AsideLetsConnect>
            <AsideCaption>
                Create an account and start working with top-notch freelancers right away
            </AsideCaption>

            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmitHandler)}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={2} rowSpacing={0}>
                        <Grid item xs={12} sm={6}>
                            <Input
                                name="firstName"
                                placeholder="First Name"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                name="lastName"
                                placeholder="Last Name"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                name="email"
                                placeholder="Email"
                                sx={darkBgInputStyles}
                                helperText={(errors as any).emailTaken && (
                                    <Typography color="error" variant="caption">
                                        It seems that you are already registered. <Link underline="hover"
                                            onClick={() => navigate(RouteAuth.login)}>Please
                                        try to login.</Link>
                                    </Typography>
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                name="companyName"
                                placeholder="Company name"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                name="position"
                                placeholder="Position"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                name="companyWebsite"
                                placeholder="Company website"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputPhone
                                name="phone"
                                placeholder="Phone"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                name="password"
                                placeholder="Password"
                                type="password"
                                sx={darkBgInputStyles}
                            />
                            <AnimateHeight isVisible={!!password?.length} isOverflowHidden={false}>
                                <PasswordStrengthBar password={password} minLength={8}/>
                            </AnimateHeight>

                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                name="passwordConfirm"
                                placeholder="Repeat Password"
                                type="password"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} marginBottom="24px">
                            <Checkbox
                                name="termsAgree"
                                label={<TermsAndPrivacyLabel/>}
                            />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            mt: '3px',
                            mb: '29px',
                            [theme.breakpoints.up('sm')]: {
                                mt: '4px',
                                mb: '38px'
                            },
                            [theme.breakpoints.up('md')]: {
                                mt: '5px',
                                mb: '61px'
                            }
                        }}
                        textAlign="center"
                    >
                        <ContinueButton
                            disabled={isLoading}
                            fullWidth
                            hasArrow
                            hasGradient
                            type="submit"
                            variant="contained"
                        >
                            Continue
                        </ContinueButton>
                    </Box>

                </form>
            </FormProvider>

            {isWidget() ? null : (
                <Stack
                    direction={mdUp ? 'row' : 'column'}
                    spacing={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="24"
                >
                    <Typography>
                        <LoginLink
                            to={utm.patchUri(RouteAuth.login)}
                        >
                            I already have an account
                        </LoginLink>
                    </Typography>

                    {mdUp && <span>•</span>}

                    <Typography>
                        <LoginLink
                            //  GC-1213 stopped registration for new freelancers
                            //  to={RouteCreateApplicantAccount.signup}
                            to={isProd
                                ? 'https://www.growthcollective.com/join'
                                : utm.patchUri(RouteCreateApplicantAccount.signup)}
                        >
                            Sign up as a freelancer
                        </LoginLink>
                    </Typography>
                </Stack>
            )}

        </Box>
    );

    useEffect(() => {
        // stackholders can switch from Client to Freelancer
        // clean previous tokens from local storage
        // but for widget SignUp step is last - so you shoud have valid token
        if (!isWidget()) {
            logout();
        }
    }, [logout]);

    return (
        mdUp
            ? <AnimatePageWrp
                maxWidth={{md: 560, lg: 703}}
            >
                {pageContent}
            </AnimatePageWrp>
            : <AnimatePageWrpFadeIn
                maxWidth={{md: 560, lg: 703}}
            >
                {pageContent}
            </AnimatePageWrpFadeIn>
    );
};

export default SignUpForm;
