import React, {useCallback, useEffect, useMemo} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useNavigate, useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useInfiniteQuery} from '@tanstack/react-query';

import {freelancerService, GetListResult} from '../../../api';
import {CaseIcon} from '../../../assets/icons';
import {ClientPortalTitle, LoadingMask, SelectSortBy, TabsStatusFilter, TopBox} from '../../../components';
import {FREELANCER_ENGAGEMENT, RouteFreelancerPortal} from '../../../constants';
import {EEngagementFilter, EEngagementFilterDisplay, EEngagementSort, IEngagement} from '../../../models';
import theme from '../../../theme';

import {MyWorkList} from './components';

const PAGE_SIZE = 10;

const optionsFilter = [
    EEngagementFilter.All,
    EEngagementFilter.Active,
    EEngagementFilter.Archive
].map(option => ({text: EEngagementFilterDisplay[option], value: option}));

const MyWorkPage = () => {
    const {filterBy, sortBy} = useParams();
    const navigate = useNavigate();

    const {data, hasNextPage, isLoading, fetchNextPage} = useInfiniteQuery(
        [FREELANCER_ENGAGEMENT, sortBy, filterBy],
        freelancerService.getWorks('startDate', sortBy, filterBy || '', PAGE_SIZE),
        {
            enabled: !!sortBy && !!filterBy,
            getNextPageParam: (lastPage: GetListResult<IEngagement>) => {
                return lastPage.last ? false : lastPage.number + 1;
            },
        }
    );

    // collect all pages results
    const items = useMemo(() => {
        const result: IEngagement[] = [];

        data?.pages.forEach(it => result.push(...it.data));

        return result;
    }, [data]);

    const handleFilterChange = useCallback((filter: string) => {
        navigate(`${RouteFreelancerPortal.myWorks}/${sortBy}/${filter}`);
    }, [sortBy, navigate]);

    const handleSortChange = useCallback((sort: string) => {
        navigate(`${RouteFreelancerPortal.myWorks}/${sort}/${filterBy}`);
    }, [filterBy, navigate]);

    // by default set 'ALL' filter active
    useEffect(() => {
        if (!sortBy || !filterBy) {
            navigate(`${RouteFreelancerPortal.myWorks}/${EEngagementSort.DESC}/${EEngagementFilter.Active}`, {replace: true});
        }
    }, [filterBy, sortBy, navigate]);

    return (
        <Box
            sx={{
                pt: '38px',
                [theme.breakpoints.up('md')]: {
                    pt: '56px'
                }
            }}
        >
            <Box
                sx={{
                    mb: '22px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }
                }}
            >
                <ClientPortalTitle>
                    My Work
                </ClientPortalTitle>

                <Box
                    sx={{
                        ml: 'auto',
                        [theme.breakpoints.up('md')]: {
                            mr: 'auto',
                            ml: 0
                        }
                    }}
                >
                    <SelectSortBy value={sortBy || ''} onChange={handleSortChange}/>
                </Box>

                <TabsStatusFilter
                    items={optionsFilter}
                    value={filterBy || EEngagementFilter.All}
                    onChange={handleFilterChange}
                />
            </Box>

            <TopBox
                title="All the roles you were hired for"
                subtitle="Below you’ll find all the engagements you have with clients"
                icon={<CaseIcon/>}
            />

            {isLoading && !data ? (
                <LoadingMask/>
            ): (
                <Box sx={{
                    width: '100%',
                    [theme.breakpoints.up('lg')]: {
                        display: 'flex',
                        gap: '24px',
                        alignItems: 'flex-start'
                    }
                }}>
                    <Box
                        sx={{width: '100%', flexGrow: 0}}
                    >
                        <InfiniteScroll
                            dataLength={items.length}
                            hasMore={hasNextPage || false}
                            loader={<LoadingMask/>}
                            next={fetchNextPage}
                        >
                            <MyWorkList items={items}/>
                        </InfiniteScroll>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default React.memo(MyWorkPage);
