import * as React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {
    Checkbox as MUICheckbox,
    CheckboxProps as MuiCheckboxProps,
    FormControl,
    FormControlLabel,
    FormHelperText
} from '@mui/material';

import {AnimateHeight} from '..';

interface CheckboxProps extends MuiCheckboxProps {
    label?: string | React.ReactNode;
}

const Checkbox = ({label, name = '', value, ...props}: CheckboxProps): JSX.Element => {
    const {
        control,
        formState: {errors},
    } = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue={false}
            name={name}
            render={({field}) => (
                <FormControl
                    error={!!errors[name]}
                >
                    <FormControlLabel
                        control={<MUICheckbox
                            {...props}
                            {...field}
                            checked={field.value}
                            disableRipple
                            disableFocusRipple
                            sx={{
                                color: `${props.color || 'primary'}.main`,
                                '&.Mui-disabled': {
                                    color: `${props.color || 'primary'}.main`,
                                    opacity: .65
                                },
                                '& + .MuiFormControlLabel-label, & + .MuiTypography-root': {
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                }
                            }}
                        />}
                        label={label}
                    />

                    {/* Show/Hide validation errors with animation */}
                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </FormControl>
            )}
        />
    );
};

export default Checkbox;
