import React from 'react';

import {Box} from '@mui/material';

import {AnimatePageWrp} from './AnimatePageWrp';

export const Component500 = () => {
    return (
        <>
            <img src="/assets/images/500.svg" width="455"/>
        </>
    );
};

export const Page500 = () => {
    return (
        <AnimatePageWrp>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80vh'
                }}
            >
                <Component500/>
            </Box>
        </AnimatePageWrp>
    );
};


