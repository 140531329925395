import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsClient, EngagementInfoBox} from '../../../../../../../components';
import {EClosureStatus, EEngagementStatus, IEngagementClient} from '../../../../../../../models';

type CloseRequestIncomingProps = {
    data: IEngagementClient;
};

const CloseRequestIncomingBox: React.FC<CloseRequestIncomingProps> = ({data}) => {
    if (data.status === EEngagementStatus.CLOSED || data.closureStatus !== EClosureStatus.FREELANCER_REQUESTED) {
        return null;
    }

    const freelancerName = data.freelancerName.split(' ')[0];

    return (
        <EngagementInfoBox
            iconUrl="/assets/images/pit-stop-icon.png"
            title={`Close engagement request from ${freelancerName}`}
        >
            {freelancerName} has submitted the engagement close request.
                Before responding, please ensure that all tasks and obligations have been completed.

            <Box
                sx={{
                    mt: '16px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <EngagementActionsClient.CloseRespondBtn data={data}/>
            </Box>

        </EngagementInfoBox>
    );
};

export default React.memo(CloseRequestIncomingBox);
