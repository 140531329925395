import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, Theme, useMediaQuery} from '@mui/material';

import {BlackBorderButton, BrightThankYouBox, Button} from '../../../../../../components';
import {RouteClientPortal} from '../../../../../../constants';
import {ERoleStatusFilter} from '../../../../../../models';
import theme from '../../../../../../theme';

export const ThankYouAddRole = () => {
    const navigate = useNavigate();
    const {roleId} = useParams();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Box
            sx={{
                display: 'flex',
                height: 'calc(100vh - 144px)',
                p: '24px 0',
                [theme.breakpoints.up('md')]: {
                    height: 'calc(100vh - 214px)',
                }
            }}
        >
            <BrightThankYouBox
                title="Thank you! Our team is excited to start matching you with an expert!"
                caption={<>
                    You will receive matches in your dashboard <span>within 24 hours</span>
                </>}
            >
                <Box
                    sx={{
                        mt: '24px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        [theme.breakpoints.up('md')]: {
                            mt: '40px',
                            flexDirection: 'row',
                            gap: '16px',
                            justifyContent: 'center'
                        }
                    }}
                >
                    <BlackBorderButton
                        fullWidth={!mdUp}
                        onClick={() => navigate(`${RouteClientPortal.myRoles}/${ERoleStatusFilter.Drafts}/${roleId}`)}
                    >
                        Go to Roles
                    </BlackBorderButton>
                    <Button
                        fullWidth={!mdUp}
                        sx={{
                            backgroundColor: theme.palette.purple.main,
                            boxShadow: '0 10px 20px rgba(126, 95, 255, 0)',
                            transition: '.3s',
                            '&:hover': {
                                backgroundColor: theme.palette.purple.main,
                                boxShadow: '0 10px 20px rgba(126, 95, 255, 0.2)'
                            }
                        }}
                        variant="contained"
                        onClick={() => navigate(RouteClientPortal.myRolesNew)}
                    >
                        Create another role
                    </Button>
                </Box>
            </BrightThankYouBox>
        </Box>
    );
};
