import React, {useState} from 'react';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';

import {Box, Typography} from '@mui/material';

import {ArrowDown} from '../../../../assets/icons';
import {AnimateHeight, Button} from '../../../../components';
import {animationFade} from '../../../../components/Animations';
import {RouteClientPortal} from '../../../../constants';
import {IRoleWithMatching} from '../../../../models';
import theme from '../../../../theme';
import {formatDate} from '../../../../utils';

import Freelancer from './Freelancer';

interface MatchedItemProps {
    index: number;
    item: IRoleWithMatching;
}

const MatchedItem: React.FC<MatchedItemProps> = ({index, item}) => {
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Box
            component={motion.div}
            {...animationFade(index)}
            sx={{
                display: 'grid',
                justifyItems: 'flex-start',
                gap: '8px',
                gridTemplateAreas: '"title"\n' +
                    '"viewAllMatches"\n' +
                    '"collapseButton"\n' +
                    '"likedMatches"',
                backgroundColor: theme.palette.white.main,
                p: '16px',
                borderRadius: '24px',
                [theme.breakpoints.up('md')]: {
                    p: '24px',
                    gap: '12px',
                    gridTemplateAreas: '"title collapseButton"\n' +
                        '"likedMatches likedMatches"\n' +
                        '"viewAllMatches ."',
                }
            }}
        >
            <Box
                sx={{
                    gridArea: 'title',
                    display: 'grid',
                    gap: '4px'
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    {item.projectName}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main
                    }}
                >
                    Submitted: {item.submitted ? formatDate(item.submitted) : ''}
                </Typography>
            </Box>

            {
                item.matching.results.length > 0 && (
                    <>
                        <Button
                            color="secondary"
                            size="small"
                            variant="contained"
                            sx={{
                                gridArea: 'collapseButton',
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                                'svg': {
                                    width: '24px',
                                    height: '24px',
                                    transform: isCollapsed ? 'scaleY(1)' : 'scaleY(-1)',
                                    transition: 'transform .3s'
                                },
                                [theme.breakpoints.up('md')]: {
                                    justifySelf: 'flex-end'
                                }
                            }}
                            onClick={handleCollapse}
                        >
                            View selected freelancers ({item.matching.results.length})
                            <ArrowDown/>
                        </Button>
                        <Box
                            sx={{
                                gridArea: 'likedMatches',
                                width: '100%',
                            }}
                        >
                            <AnimateHeight isVisible={!isCollapsed}>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gap: '16px',
                                        [theme.breakpoints.up('sm')]: {
                                            display: 'flex',
                                            flexWrap: 'wrap'
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            pt: '12px',
                                            pb: '8px'
                                        }
                                    }}
                                >
                                    {item.matching.results.map(freelancer => (
                                        <Freelancer key={freelancer.id} item={freelancer} roleId={item.id}/>
                                    ))}
                                </Box>
                            </AnimateHeight>
                        </Box>
                    </>
                )
            }

            <Button
                size="small"
                variant="contained"
                sx={{gridArea: 'viewAllMatches'}}
                onClick={() => navigate(RouteClientPortal.myMatches + '/' + item.id)}
            >
                View all matches ({item.matching.resultsCount})
            </Button>
        </Box>
    )
    ;
};

export default React.memo(MatchedItem);
