import {BANNERS, CLIENT, CLIENT_BILLING_ADDRESS, CLIENT_BILLING_CONTACT, CLIENT_DASHBOARD, CLIENT_ENGAGEMENT, CLIENT_PAYMENTS, CLIENT_REGISTRATION, CLIENT_ROLES, CLIENT_TIMESHEETS, GET_MATCHED, SET_CLIENT_AVATAR, SET_COMPANY_LOGO, TIMESHEETS} from '../constants';
import {
    IBillingAddress,
    IBudgetRequest,
    IChannel,
    IClientRole,
    IClientWithEmailVerificationProcess,
    IContactPerson,
    IContactUs,
    IDashboard,
    IDashboardBanners,
    IEngagement,
    IEngagementClient,
    IEngagementVersion,
    ILocationRequest,
    IMatchesResponse,
    IPauseClientRequest,
    IPaymentMethod,
    IPaymentPreview,
    IProjectRequest,
    IRegisterClient,
    IReportDate,
    IReportRole,
    IReportSearchParams,
    IRoleWithMatching,
    ISpending,
    ISpendingReport,
    ITool,
    IVerticalBussinessModelRequest,
    IWorkWeek
} from '../models';
import {formatQueryStringParams} from '../utils/request.utils';

import axiosInstance from './base.api';
import {convertHTTPResponse, GetListResult} from './list.api';

const budgetSubmit = async (id: number, payload: IBudgetRequest) => {
    return await axiosInstance.put<null>(`${CLIENT_ROLES}/${id}/budget`, payload);
};

const channelsSubmit = async (id: number, payload: IChannel) => {
    return await axiosInstance.put<null>(`${CLIENT_ROLES}/${id}/channel`, payload);
};

const cancelRole = async (roleId: number) => {
    return await axiosInstance.delete<null>(`${CLIENT_ROLES}/${roleId}`);
};

const cloneRole = async (roleId: number) => {
    return await axiosInstance.post<IClientRole>(`${CLIENT_ROLES}?duplicateOf=${roleId}`);
};

const createRole = async () => {
    return await axiosInstance.post<IClientRole>(CLIENT_ROLES);
};

const closureConfirm = async (id: number) => {
    return await axiosInstance.patch<null>(`${CLIENT_ENGAGEMENT}/${id}/closure`);
};

const closureReject = async (id: number) => {
    return await axiosInstance.delete<null>(`${CLIENT_ENGAGEMENT}/${id}/closure`);
};

const closureSubmit = async (id: number) => {
    return await axiosInstance.post<null>(`${CLIENT_ENGAGEMENT}/${id}/closure`);
};

const confimationCodeSubmit = async (code: string) => {
    return await axiosInstance.put<null>(CLIENT_REGISTRATION, {code});
};

const contactUs = async (payload: IContactUs) => {
    const formData = new FormData();

    console.log('payload', payload);

    Object.entries(payload).forEach(([key, value]) => {
        if (key === 'attachments') {
            if (value?.length) {
                value.forEach((file: File) => formData.append('attachments', file));
            }
        } else {
            formData.append(key, value);
        }
    });

    return await axiosInstance.post<null>(`${CLIENT}/contact-us`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const dashboard = async () => {
    const response = await axiosInstance.get<IDashboard>(CLIENT_DASHBOARD);

    return response.data;
};

const dashboardBanners = async () => {
    const response = await axiosInstance.get<IDashboardBanners>(`${CLIENT_DASHBOARD}/${BANNERS}`);

    return response.data;
};

const getClient = async () => {
    const response = await axiosInstance.get<IClientWithEmailVerificationProcess>(CLIENT);

    return response.data;
};

const getHire = (id: number) => async () => {
    const response = await axiosInstance.get<IEngagementClient>(`${CLIENT_ENGAGEMENT}/${id}`);

    return response.data;
};

const getHires = (sortBy: string = 'id', sortDirection = 'ASC', filterBy: string = 'ALL', pageSize: number = 10) => {
    const sort = encodeURIComponent(`${sortBy},${sortDirection}`);

    return async ({pageParam = 0}) => await axiosInstance.get<GetListResult<IEngagement>>(
        `${CLIENT_ENGAGEMENT}?page=${pageParam}&size=${pageSize}&filter=${filterBy}&sort=${sort}`
    )
        .then(convertHTTPResponse);
};

const getMatched = async () => {
    return await axiosInstance.get<IRoleWithMatching[]>(GET_MATCHED);
};

const getMatches = async (roleId: number) => {
    return await axiosInstance.get<IMatchesResponse>(`${CLIENT_ROLES}/${roleId}/matches`);
};

const getRoles = (statuses: string[], sortBy: string = 'id', sortDirection = 'ASC', pageSize: number = 10) => {
    const sort = encodeURIComponent(`${sortBy},${sortDirection}`);
    const filter = statuses?.length ? `&status=${statuses.join('&status=')}` : '';

    return async ({pageParam = 0}) => await axiosInstance.get<GetListResult<IClientRole>>(
        `${CLIENT_ROLES}?page=${pageParam}&size=${pageSize}${filter}&sort=${sort}`
    )
        .then(convertHTTPResponse);
};

const getRoleById = (id: number) => async () => {
    const response = await axiosInstance.get<IClientRole>(`${CLIENT_ROLES}/${id}`);

    return response.data;
};

const getSpendings = async (params: IReportSearchParams) => {
    const response = await axiosInstance.get<ISpendingReport>(`${CLIENT}/payments`, {
        params: formatQueryStringParams(params)
    });

    return response.data;
};

const getTimesheets = (engagementId: number) => async () => {
    const response = await axiosInstance.get<{timesheets: IWorkWeek[]}>(`${CLIENT_ENGAGEMENT}/${engagementId}/${TIMESHEETS}`);

    return response.data.timesheets;
};

const getTimesheetsDate = async (params: IReportSearchParams) => {
    const response = await axiosInstance.get<IReportDate>(`${CLIENT}/${TIMESHEETS}/report/dates`, {
        params: formatQueryStringParams(params)
    });

    return response.data;
};

const getTimesheetsRole = async (params: IReportSearchParams) => {
    const response = await axiosInstance.get<IReportRole>(`${CLIENT}/${TIMESHEETS}/report/roles`, {
        params: formatQueryStringParams(params)
    });

    return response.data;
};

const locationSubmit = async (id: number, data: ILocationRequest) => {
    return await axiosInstance.put<null>(`${CLIENT_ROLES}/${id}/location`, data);
};

const paymentBillingAddress = async () => {
    return await axiosInstance.get<IBillingAddress>(CLIENT_BILLING_ADDRESS);
};

const paymentBillingAddressSubmit = async (payload: IBillingAddress) => {
    return await axiosInstance.put<null>(CLIENT_BILLING_ADDRESS, payload);
};

const paymentContactSubmit = async (payload: IContactPerson) => {
    return await axiosInstance.put<null>(CLIENT_BILLING_CONTACT, payload);
};

const paymentMethods = async () => {
    return await axiosInstance.get<{paymentMethods: IPaymentPreview[]}>(CLIENT_PAYMENTS);
};

const paymentMethodDelete = async (methodId: number) => {
    return await axiosInstance.delete<null>(`${CLIENT_PAYMENTS}/${methodId}`);
};

const paymentMethodRetry = async (paymentId: number, methodId: number) => {
    return await axiosInstance.post<ISpending>(`${CLIENT}/payments/${paymentId}`, {methodId});
};

const paymentMethodSubmit = async (payload: IPaymentMethod) => {
    return await axiosInstance.post<IPaymentPreview>(CLIENT_PAYMENTS, payload);
};

const paymentMethodSetPrimary = async (methodId: number) => {
    return await axiosInstance.patch<IPaymentPreview>(`${CLIENT_PAYMENTS}/${methodId}/primary`);
};

const pauseConfirm = async (id: number) => {
    return await axiosInstance.patch<null>(`${CLIENT_ENGAGEMENT}/${id}/pause`);
};

const pauseReject = async (id: number) => {
    return await axiosInstance.delete<null>(`${CLIENT_ENGAGEMENT}/${id}/pause`);
};

const pauseSubmit = async (id: number, payload: IPauseClientRequest) => {
    return await axiosInstance.post<null>(`${CLIENT_ENGAGEMENT}/${id}/pause`, payload);
};

const projectSubmit = async (id: number, payload: IProjectRequest) => {
    return await axiosInstance.put<null>(`${CLIENT_ROLES}/${id}/project`, payload);
};

const publishRole = async (roleId: number) => {
    return await axiosInstance.patch<null>(`${CLIENT_ROLES}/${roleId}`);
};

const resendCode = async () => {
    return await axiosInstance.post<null>(`${CLIENT_REGISTRATION}/code`);
};

const resumeAccept = async (id: number) => {
    return await axiosInstance.patch<null>(`${CLIENT_ENGAGEMENT}/${id}/resume`);
};

const resumeReject = async (id: number) => {
    return await axiosInstance.delete<null>(`${CLIENT_ENGAGEMENT}/${id}/resume`);
};

const resumeSubmit = async (id: number, payload: IEngagementVersion) => {
    return await axiosInstance.post<null>(`${CLIENT_ENGAGEMENT}/${id}/resume`, payload);
};

const submitAvatar = async (file?: File) => {
    if (file) {
        const formData = new FormData();

        formData.append('file', file);

        return await axiosInstance.put<null>(SET_CLIENT_AVATAR, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } else {
        return await axiosInstance.delete<null>(SET_CLIENT_AVATAR);
    }
};

const submitClient = async (data: Partial<IRegisterClient>) => {
    return await axiosInstance.put<null>(CLIENT, data);
};

const submitCompanyLogo = async (file?: File) => {
    if (file) {
        const formData = new FormData();

        formData.append('file', file);

        return await axiosInstance.put<null>(SET_COMPANY_LOGO, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } else {
        return await axiosInstance.delete<null>(SET_COMPANY_LOGO);
    }
};

const timesheetsApprove = async (id: number) => {
    return await axiosInstance.patch<null>(`${CLIENT_TIMESHEETS}/${id}`);
};

const timesheetsReject = async (id: number, reason: string) => {
    return await axiosInstance.delete<null>(`${CLIENT_TIMESHEETS}/${id}`, {data: {reason}});
};

const togglePin = async (id: number) => {
    return await axiosInstance.patch<null>(`${CLIENT_ENGAGEMENT}/${id}/pin`);
};

const toolsSubmit = async (id: number, payload: {tools: ITool[]}) => {
    return await axiosInstance.put<null>(`${CLIENT_ROLES}/${id}/tools`, payload);
};

const verticalsSubmit = async (id: number, payload: IVerticalBussinessModelRequest) => {
    const response = await axiosInstance.put<null>(`${CLIENT_ROLES}/${id}/vertical`, payload);

    return response.data;
};

const updateEmail = async (email: string) => {
    return await axiosInstance.put<null>(`${CLIENT_REGISTRATION}/email`, {email});
};

export const clientService = {
    budgetSubmit,
    cancelRole,
    channelsSubmit,
    confimationCodeSubmit,
    contactUs,
    cloneRole,
    createRole,
    closureConfirm,
    closureReject,
    closureSubmit,
    dashboard,
    dashboardBanners,
    getClient,
    getHire,
    getHires,
    getMatched,
    getMatches,
    getRoleById,
    getRoles,
    getSpendings,
    getTimesheets,
    getTimesheetsDate,
    getTimesheetsRole,
    locationSubmit,
    paymentBillingAddress,
    paymentBillingAddressSubmit,
    paymentContactSubmit,
    paymentMethods,
    paymentMethodDelete,
    paymentMethodRetry,
    paymentMethodSetPrimary,
    paymentMethodSubmit,
    pauseConfirm,
    pauseReject,
    pauseSubmit,
    projectSubmit,
    publishRole,
    resendCode,
    resumeAccept,
    resumeReject,
    resumeSubmit,
    submitAvatar,
    submitClient,
    submitCompanyLogo,
    timesheetsApprove,
    timesheetsReject,
    togglePin,
    toolsSubmit,
    verticalsSubmit,
    updateEmail,
};
