import {EAttachmentType, IAttachment} from './attachment.model';

export interface ICaseStudy extends IAttachment {
    brief: string;
    clientsName: string;
    clientsPosition: string;
    clientsReview: string;
    challenge: string;
    companyLogo?: File[];
    companyLogoUrl?: string;
    companyName: string;
    coverImage: string;
    // goals: string;
    outcomes: IOutcome[];
    solution: string;
    summary: string;
    type: EAttachmentType.study;
}

export interface IOutcome {
    description: string;
    value: string;
}

export interface ICaseStudyVersion {
    id: number;
    draftVersion: ICaseStudy;
    publicVersion: ICaseStudy;
    created: string;
    notes: string;
    approved: string;
    approvedBy: string;
}

export enum ECaseStudySteps {
    description = 'description',
    company = 'company',
    challenge = 'challenge',
    outcomes = 'outcomes',
    testimonial = 'testimonial',
}

export enum ECaseStudyStepsDisplay {
    description = 'Title & Description',
    company = 'Company information',
    challenge = 'Challenge, Solution & Process',
    outcomes = 'Outcomes',
    testimonial = 'Testimonial (optional)',
    preview = 'Preview',
}

export interface ICoverImage {
    name: string;
    variant: number | null;
}
