import React, {useEffect} from 'react';

import {clientService} from '../../../api';
import {ConfirmationCode} from '../../../components';
import {RouteCreateClientAccount} from '../../../constants';
import {useClientRole} from '../../../hooks';

declare const window: Window & typeof globalThis & { hellobar?: { trigger: { event: (s: string) => void } } };

export const ClientConfirmationCodePage = () => {
    useEffect(() => {
        let attempt = 0;
        const triggerEvent = () => {
            if (!window.hellobar) {
                if (attempt++ < 10) {
                    setTimeout(triggerEvent, 1000);
                }

                return;
            }
            window.hellobar.trigger.event('confirmation-code');
        };

        triggerEvent();
    }, []);

    const {refetchClient} = useClientRole();

    return (
        <ConfirmationCode
            title="Thanks for creating the role, you’re almost there!"
            routeConfirmationCode={RouteCreateClientAccount.confirmationCode}
            routeNextStep={RouteCreateClientAccount.completed}
            routeUpdateEmail={RouteCreateClientAccount.updateEmail}
            service={clientService}
            onRefetch={refetchClient}
        />
    );
};
