import React, {FC} from 'react';

import {Box, Typography} from '@mui/material';

import {useAuth} from '../../../hooks';
import theme from '../../../theme';

interface IProps {
    isPaused?: boolean;
    isPending?: boolean;
}
const TimesheetEmptyPlaceholder: FC<IProps> = ({isPaused, isPending}) => {
    const {isClient} = useAuth();

    return (
        <Box
            sx={{
                pt: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                'img': {
                    mb: '32px',
                    width: '64px',
                    height: '64px'
                }
            }}
        >
            <img src="/assets/images/glancing-eyes-emoji.png" width="64" height="64" alt="eyes emoji"/>
            <Typography
                sx={{
                    mb: '2px',
                    fontWeight: 500,
                    fontSize: '16px'
                }}
            >
                {isClient ? 'No timesheets during this billing time yet' : 'No timesheets during this time'}
            </Typography>

            {
                isPaused && (
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.gray.main
                        }}
                    >
                        {
                            isClient
                                ? 'Once a freelancer submits timesheets for this billing period, it will appear here'
                                : 'The engagement has been paused, so you don’t have to track your time.'
                        }
                    </Typography>
                )
            }

            {
                isPending && (
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.gray.main
                        }}
                    >
                        {
                            isClient
                                ? 'Once a freelancer submits timesheets for the billing period, it will appear here'
                                : "The engagement hasn't started yet, so there's no need to track your time"
                        }
                    </Typography>
                )
            }

        </Box>
    );
};

export default React.memo(TimesheetEmptyPlaceholder);
