import React from 'react';

const PausedWorkStatusIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.1 10.8C7.29833 10.8 7.4647 10.7328 7.5991 10.5984C7.7335 10.464 7.80047 10.2979 7.8 10.1V5.8825C7.8 5.68417 7.7328 5.52083 7.5984 5.3925C7.464 5.26417 7.29787 5.2 7.1 5.2C6.90167 5.2 6.7353 5.2672 6.6009 5.4016C6.4665 5.536 6.39953 5.70213 6.4 5.9V10.1175C6.4 10.3158 6.4672 10.4792 6.6016 10.6075C6.736 10.7358 6.90213 10.8 7.1 10.8ZM9.9 10.8C10.0983 10.8 10.2647 10.7328 10.3991 10.5984C10.5335 10.464 10.6005 10.2979 10.6 10.1V5.8825C10.6 5.68417 10.5328 5.52083 10.3984 5.3925C10.264 5.26417 10.0979 5.2 9.9 5.2C9.70167 5.2 9.5353 5.2672 9.4009 5.4016C9.2665 5.536 9.19953 5.70213 9.2 5.9V10.1175C9.2 10.3158 9.2672 10.4792 9.4016 10.6075C9.536 10.7358 9.70213 10.8 9.9 10.8ZM8.5 15C7.53167 15 6.62167 14.8161 5.77 14.4484C4.91833 14.0807 4.1775 13.582 3.5475 12.9525C2.9175 12.3225 2.41887 11.5817 2.0516 10.73C1.68433 9.87833 1.50047 8.96833 1.5 8C1.5 7.03167 1.68387 6.12167 2.0516 5.27C2.41933 4.41833 2.91797 3.6775 3.5475 3.0475C4.1775 2.4175 4.91833 1.91887 5.77 1.5516C6.62167 1.18433 7.53167 1.00047 8.5 1C9.46833 1 10.3783 1.18387 11.23 1.5516C12.0817 1.91933 12.8225 2.41797 13.4525 3.0475C14.0825 3.6775 14.5814 4.41833 14.9491 5.27C15.3168 6.12167 15.5005 7.03167 15.5 8C15.5 8.96833 15.3161 9.87833 14.9484 10.73C14.5807 11.5817 14.082 12.3225 13.4525 12.9525C12.8225 13.5825 12.0817 14.0814 11.23 14.4491C10.3783 14.8168 9.46833 15.0005 8.5 15ZM8.5 13.6C10.0517 13.6 11.373 13.0547 12.4641 11.9641C13.5552 10.8735 14.1005 9.55213 14.1 8C14.1 6.44833 13.5547 5.12697 12.4641 4.0359C11.3735 2.94483 10.0521 2.39953 8.5 2.4C6.94833 2.4 5.62697 2.9453 4.5359 4.0359C3.44483 5.1265 2.89953 6.44787 2.9 8C2.9 9.55167 3.4453 10.873 4.5359 11.9641C5.6265 13.0552 6.94787 13.6005 8.5 13.6Z"
                fill="#999999"/>
        </svg>
    );
};

export default PausedWorkStatusIcon;
