import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {useNavigate} from 'react-router-dom';

import {Avatar, Box, styled, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {matchesService} from '../../../../api';
import {MessageBubbleIcon} from '../../../../assets/icons';
import {BlackBorderButton, BlueButton, LikeButton, showToastError} from '../../../../components';
import {CLIENT_ROLES, GET_MATCHED, MATCHES, RouteClientPortal} from '../../../../constants';
import {useTwilio} from '../../../../hooks';
import {EMatchStatus, IMatchingResultForReview} from '../../../../models';
import theme from '../../../../theme';
import {formatCurrency, formatDate, getNameLetters, stringToColor} from '../../../../utils';

const BoxWithBorderBottom = styled('div')`
  padding: 16px;
  border-bottom: 1px solid ${theme.palette.lightGray.main};

  &:last-child {
    border-bottom: unset;
  }
`;

const RateBox = styled('div')`
  display: inline-block;
  padding: 6px 12px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.purple.main};
  background-color: ${theme.palette.lilac.main};
`;

const InfoRow = styled('p')`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.black.main};
  padding: 8px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  span {
    font: inherit;
    font-weight: 400;
    line-height: inherit;
    color: ${theme.palette.textGray.dark};
  }
`;

interface FreelancerProps {
    item: IMatchingResultForReview;
    roleId: number;
}

const Freelancer: React.FC<FreelancerProps> = ({item, roleId}) => {
    const [isLiked, setIsLiked] = useState([EMatchStatus.LIKED, EMatchStatus.LIKED_IN_TOUCH].includes(item.status));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isMutated, setIsMutated] = useState(false);

    const queryClient = useQueryClient();
    const twilioClient = useTwilio();
    const navigate = useNavigate();

    const handleLikeClick = useCallback(async (active: boolean) => {
        const mutate = active ? matchesService.setMatcheLike : matchesService.setMatcheUnLike;
        const minimum = new Promise((reject) => setTimeout(() => reject(true), 1000));    // show preloader minimum 1 sec to prevent flicking

        setIsLiked(active);
        setIsSubmitting(true);

        try {
            await Promise.all([minimum, mutate(item.id)]);
            setIsMutated(true);
        } catch (error) {
            showToastError(error as AxiosError);
            setIsLiked(!active);
        }

        setIsSubmitting(false);
    }, [item.id]);

    const handleMessageClick = async () => {
        setIsSubmitting(true);

        try {
            const conversationId = await matchesService.getChatRoom(item.id);

            twilioClient.invalidateQueries();   // Contact list can miss new room
            navigate(`${RouteClientPortal.inbox}/${conversationId}`);
        } catch (error) {
            showToastError(error as AxiosError);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        return () => {
            // As I client, I could accidentally dislike the freelancer on the Dynamic ClientProfile
            // and it would immediately disappear from the My Matches page.
            // Let’s apply changes after the Client leaves the page.
            if (isMutated) {
                queryClient.invalidateQueries([CLIENT_ROLES, Number(roleId), MATCHES]);
                queryClient.invalidateQueries([GET_MATCHED]);
            }
        };
    }, [isMutated, roleId, queryClient]);

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.white.main,
                border: '1px solid',
                borderColor: theme.palette.lightGray.main,
                borderRadius: '16px',
                boxShadow: '0 8px 40px rgba(0, 0, 0, 0.03)',
                width: '100%',
                maxWidth: '500px',
                [theme.breakpoints.up(700)]: {
                    width: 'calc(50% - 8px)',
                    maxWidth: '380px',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '340px'
                }
            }}
        >
            <BoxWithBorderBottom>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        mr: '-16px'
                    }}
                >
                    <Box
                        sx={{
                            flexShrink: 0
                        }}
                    >
                        <Avatar
                            alt={item.freelancerName}
                            src={item.freelancerAvatar} /*fixme: put img src when it is available on backend*/
                            sx={{
                                width: '40px',
                                height: '40px',
                                background: stringToColor(item.freelancerName),
                                fontSize: '14px',
                                lineHeight: 1.2,
                                fontWeight: 500,
                                'img': {
                                    display: 'block',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    aspectRatio: '1/1'
                                },
                                [theme.breakpoints.up('sm')]: {
                                    width: '76px',
                                    height: '76px',
                                    fontSize: '28px',
                                }
                            }}
                        >
                            {getNameLetters(item.freelancerName)}
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            mr: 'auto'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: theme.palette.black.main
                            }}
                        >
                            {item.freelancerName}
                        </Typography>
                        <Typography
                            sx={{
                                mb: '4px',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: theme.palette.gray.main
                            }}
                        >
                            Accepted on: {formatDate(item.freelancerCreated)}
                        </Typography>
                        <RateBox>{formatCurrency(item.freelancerRate)}/hour</RateBox>
                    </Box>
                    <LikeButton
                        active={isLiked}
                        loading={isSubmitting}
                        onClick={handleLikeClick}
                    />
                </Box>
            </BoxWithBorderBottom>

            <BoxWithBorderBottom>
                <InfoRow>Contacted <span>{item.isContacted ? 'Yes' : 'No'}</span></InfoRow>
                <InfoRow>Proposal sent <span>{item.isProposalSent ? 'Yes' : 'No'}</span></InfoRow>
                <InfoRow>Hired <span>{item.isHired ? 'Yes' : 'No'}</span></InfoRow>
            </BoxWithBorderBottom>

            <BoxWithBorderBottom>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: '8px'
                    }}
                >
                    <BlueButton
                        disabled={isSubmitting}
                        size="small"
                        onClick={handleMessageClick}
                    >
                        <MessageBubbleIcon/>
                        Message
                    </BlueButton>
                    <BlackBorderButton
                        size="small"
                        onClick={() => navigate(`${RouteClientPortal.publicFreelancerProfile}/${item.id}`)}
                    >
                        See details
                    </BlackBorderButton>
                </Box>
            </BoxWithBorderBottom>
        </Box>
    );
};

export default React.memo(Freelancer);
