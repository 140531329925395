import React, {FC, ReactNode, useMemo} from 'react';
import capitalize from 'lodash/capitalize';

import {Box, styled, Typography} from '@mui/material';

import {CheckCurvyIcon} from '../../../assets/icons';
import {LG, MD} from '../../../constants';
import {IDynamicProfiles} from '../../../models';
import theme from '../../../theme';

const Wrp = styled('div')``;

const MatchItem = styled('div')`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${MD}) {
    margin-bottom: 0;
    flex-shrink: 0;
    width: 261px;
  }

  @media (min-width: ${LG}) {
    width: 100%;
    //max-width: 261px;
    flex-shrink: unset;
  }

  .top {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 4px;

    div {
      width: 24px;
      height: 24px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  path {
    &:first-of-type {
      fill: ${theme.palette.purple.main};
    }

    &:last-of-type {
      fill: ${theme.palette.white.main};
    }
  }
`;

const MatchItemTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${theme.palette.textGray.dark};

  span {
    font-size: inherit;
    font-weight: 600;
    line-height: inherit;
    color: ${theme.palette.black.main};
  }
`;

const MatchItemText = styled(Typography)`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.gray.main};
`;

interface IFreelancerWhyThisMatch {
    data: IDynamicProfiles;
}

const FreelancerWhyThisMatch: FC<IFreelancerWhyThisMatch> = ({
    data: {
        businessModels,
        channels,
        country,
        firstName,
        lastName,
        languages,
        promo,
        subchannels,
        timezones,
        tools,
        verticals,
        workTypes
    }
}) => {
    const content = useMemo(() => {
        return promo.map(property => {
            let values: { title: ReactNode; text: ReactNode };

            switch (property) {
                case 'businessModels':
                    values = {
                        title: <>Business model: <span>{businessModels.common[0]}</span></>,
                        text: <>{firstName} has a strong understanding of what it takes to succeed in your business
                            model</>
                    };
                    break;
                case 'channels':
                    values = {
                        title: <>Channel: <span>{channels.common[0].name}</span></>,
                        text: <>{firstName} is a top expert in the primary channel for your role</>
                    };
                    break;
                case 'countries':
                    values = {
                        title: <>Country: <span>{country}</span></>,
                        text: <>{firstName} has marketing experience in the countries you are looking to market it</>
                    };
                    break;
                case 'languages':
                    values = {
                        title: <>Language: <span>{languages?.common[0]}</span></>,
                        text: <>{firstName} speaks your language and those of the customers you are targeting for this
                            role</>
                    };
                    break;
                case 'subchannels':
                    values = {
                        title: <>Sub-Channel: <span>{subchannels.common[0].name}</span></>,
                        text: <>{firstName} also highly skilled in your required subchannel</>
                    };
                    break;
                case 'timezones':
                    values = {
                        title: <>Timezones: <span>{timezones.common[0]}</span></>,
                        text: <>{firstName} is happy to work at your preferred timezone to make it easy to work
                            together</>
                    };
                    break;
                case 'tools':
                    values = {
                        title: <>Tools: <span>{tools.common[0].name}</span></>,
                        text: <>{firstName} has excellent experience with the tools needed for success in this role</>
                    };
                    break;
                case 'verticals':
                    values = {
                        title: <>Verticals: <span>{verticals.common[0].name}</span></>,
                        text: <>{firstName} has gotten good results for clients in your vertical</>
                    };
                    break;
                case 'workTypes':
                    values = {
                        title: <>Budget type: <span>{capitalize(workTypes.common[0])}</span></>,
                        text: <>{firstName} looks forward to working with you in your preferred work type</>
                    };
                    break;

                default:
                    values = {
                        title: property,
                        text: ''
                    };
            }

            return (
                <MatchItem key={property}>
                    <div className="top">
                        <CheckCurvyIcon/>
                        <MatchItemTitle>
                            {values.title}
                        </MatchItemTitle>
                    </div>
                    <MatchItemText>
                        {values.text}
                    </MatchItemText>
                </MatchItem>
            );
        });
    }, [businessModels, channels, country, firstName, languages, promo, subchannels, timezones, tools, verticals, workTypes]);

    return (
        <Wrp>
            <Typography
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    mb: '8px',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: 1.5,
                    'img': {
                        width: '24px',
                        height: '24px'
                    }
                }}
            >
                <img src="/assets/images/star-eyes-emoji.png" width="24" height="24" alt="star eyes emoji"/>
                Why this match?
            </Typography>
            <Typography
                sx={{
                    mb: '24px',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: theme.palette.textGray.dark
                }}
            >
                We analyzed your role requirements and found that {firstName} {lastName}’s following experience meets
                your needs.
            </Typography>
            <Box
                sx={{
                    [theme.breakpoints.up('md')]: {
                        display: 'flex',
                        gap: '20px'
                    },
                    [theme.breakpoints.up('lg')]: {
                        gap: '40px'
                    }
                }}
            >
                {content}
            </Box>
        </Wrp>
    );
};

export default React.memo(FreelancerWhyThisMatch);
