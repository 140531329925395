import React from 'react';

const TextIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30 45.5H18C7.14 45.5 2.5 40.86 2.5 30V18C2.5 7.14 7.14 2.5 18 2.5H28C28.82 2.5 29.5 3.18 29.5 4C29.5 4.82 28.82 5.5 28 5.5H18C8.78 5.5 5.5 8.78 5.5 18V30C5.5 39.22 8.78 42.5 18 42.5H30C39.22 42.5 42.5 39.22 42.5 30V20C42.5 19.18 43.18 18.5 44 18.5C44.82 18.5 45.5 19.18 45.5 20V30C45.5 40.86 40.86 45.5 30 45.5Z"
                fill="#7E5FFF"/>
            <path
                d="M44 21.501H36C29.16 21.501 26.5 18.841 26.5 12.001V4.00095C26.5 3.40095 26.86 2.84095 27.42 2.62095C27.98 2.38095 28.62 2.52095 29.06 2.94095L45.06 18.941C45.48 19.361 45.62 20.021 45.38 20.581C45.14 21.141 44.6 21.501 44 21.501ZM29.5 7.62095V12.001C29.5 17.161 30.84 18.501 36 18.501H40.38L29.5 7.62095Z"
                fill="#7E5FFF"/>
            <path
                d="M26 27.5H14C13.18 27.5 12.5 26.82 12.5 26C12.5 25.18 13.18 24.5 14 24.5H26C26.82 24.5 27.5 25.18 27.5 26C27.5 26.82 26.82 27.5 26 27.5Z"
                fill="#7E5FFF"/>
            <path
                d="M22 35.5H14C13.18 35.5 12.5 34.82 12.5 34C12.5 33.18 13.18 32.5 14 32.5H22C22.82 32.5 23.5 33.18 23.5 34C23.5 34.82 22.82 35.5 22 35.5Z"
                fill="#7E5FFF"/>
        </svg>
    );
};

export default TextIcon;
