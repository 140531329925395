import React from 'react';

const LockOpenIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 8.99992H9V6.99992C8.99854 6.40611 9.17334 5.82522 9.50226 5.33082C9.83118 4.83643 10.2994 4.45077 10.8477 4.22268C11.3959 3.99459 11.9996 3.93435 12.5821 4.04957C13.1646 4.16479 13.6999 4.45029 14.12 4.86992C14.4959 5.25399 14.7649 5.72975 14.9 6.24992C14.9328 6.3773 14.9904 6.49697 15.0695 6.60209C15.1486 6.70721 15.2476 6.79573 15.3609 6.86259C15.4742 6.92945 15.5995 6.97334 15.7298 6.99175C15.86 7.01017 15.9926 7.00275 16.12 6.96992C16.2474 6.93709 16.3671 6.87949 16.4722 6.80041C16.5773 6.72133 16.6658 6.62232 16.7327 6.50904C16.7995 6.39575 16.8434 6.2704 16.8618 6.14015C16.8802 6.0099 16.8728 5.8773 16.84 5.74992C16.6122 4.88472 16.1603 4.09486 15.53 3.45992C14.8302 2.76229 13.9393 2.28766 12.97 2.09596C12.0006 1.90427 10.9961 2.00411 10.0835 2.38288C9.17078 2.76164 8.3908 3.40235 7.84201 4.22409C7.29321 5.04584 7.00021 6.01177 7 6.99992V8.99992C6.20435 8.99992 5.44129 9.31599 4.87868 9.8786C4.31607 10.4412 4 11.2043 4 11.9999V18.9999C4 19.7956 4.31607 20.5586 4.87868 21.1212C5.44129 21.6838 6.20435 21.9999 7 21.9999H17C17.7956 21.9999 18.5587 21.6838 19.1213 21.1212C19.6839 20.5586 20 19.7956 20 18.9999V11.9999C20 11.2043 19.6839 10.4412 19.1213 9.8786C18.5587 9.31599 17.7956 8.99992 17 8.99992ZM18 18.9999C18 19.2651 17.8946 19.5195 17.7071 19.707C17.5196 19.8946 17.2652 19.9999 17 19.9999H7C6.73478 19.9999 6.48043 19.8946 6.29289 19.707C6.10536 19.5195 6 19.2651 6 18.9999V11.9999C6 11.7347 6.10536 11.4803 6.29289 11.2928C6.48043 11.1053 6.73478 10.9999 7 10.9999H17C17.2652 10.9999 17.5196 11.1053 17.7071 11.2928C17.8946 11.4803 18 11.7347 18 11.9999V18.9999Z"
                fill="#999999"/>
        </svg>
    );
};

export default LockOpenIcon;
