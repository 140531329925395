import React, {useMemo} from 'react';

import {Typography} from '@mui/material';

import theme from '../../theme';
import {formatWeek} from '../../utils';

type DayLabelProps = {
    date: string;
};

const DayLabel: React.FC<DayLabelProps> = ({date}) => {
    const renderDate = useMemo(() => formatWeek(date).split(','), [date]);

    return (
        <Typography
            sx={{
                fontWeight: 500,
                fontSize: '14px',
                'span': {
                    color: theme.palette.gray.main
                }
            }}
            variant="body1"
        >
            {renderDate[0]},
            <span>{renderDate[1]}</span>
        </Typography>
    );
};

export default React.memo(DayLabel);
