import React, {useCallback, useMemo, useState} from 'react';
import {AxiosError} from 'axios';

import PaymentsIcon from '@mui/icons-material/Payments';
import {Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../api';
import {
    BankIcon,
    CheckCurvyIcon,
    CheckCurvyIconDisabled,
    CreditCardIcon,
    CrossInStarIcon,
    DeleteIcon
} from '../../assets/icons';
import {
    BlackBorderButton,
    ContinueButton,
    DialogEmoji,
    IconButton,
    LoadingMask,
    RedFilledButton,
    showToastError,
    TextButton,
} from '../../components';
import {CLIENT_PAYMENTS, LG, MD} from '../../constants';
import {useAuth} from '../../hooks';
import {EPaymentMethod, EPaymentMethodStatus, IPaymentPreview} from '../../models';
import theme from '../../theme';
import Tooltip from '../Tooltip/Tooltip';

const Wrapper = styled('div')`
  padding: 24px;
  background-color: ${theme.palette.white.main};
  border: 1px solid ${theme.palette.lightGray.main};
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  max-width: 620px;

  & > svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }

  & > svg path {
    fill: ${theme.palette.gray.main};
  }

  @media (min-width: ${MD}) {
    flex-wrap: nowrap;
  }

  @media (min-width: ${LG}) {
    padding-right: 168px;
    position: relative;

    &:hover {
      .actions {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`;

const LabelPrimary = styled('div')`
  padding: 4px 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.black.main};
  background-color: ${theme.palette.purple.light};
  border-radius: 50px;
`;

interface IProps {
    data: IPaymentPreview;
    isDeleteBtn: boolean;
}

const PaymentMethodRow: React.FC<IProps> = ({data, isDeleteBtn}) => {
    const {isClient, isImpersonal} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openSuccessDeleted, setOpenSuccessDeleted] = useState(false);

    const queryClient = useQueryClient();

    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const handleDeleteClick = useCallback(async () => {
        const minimum = new Promise((reject) => setTimeout(() => reject(true), 1000));    // show preloader minimum 1 sec to prevent flicking

        setOpenConfirmDelete(false);
        setIsLoading(true);
        try {
            await Promise.all([minimum, clientService.paymentMethodDelete(data.id)]);
            setOpenSuccessDeleted(true);
        } catch (error) {
            showToastError(error as AxiosError);
        }
        setIsLoading(false);
    }, [data]);

    const handleSetPrimaryClick = async () => {
        if (isLoading || isImpersonal) return;

        setIsLoading(true);
        try {
            await (isClient ? clientService : freelancerService).paymentMethodSetPrimary(data.id);
            queryClient.invalidateQueries([CLIENT_PAYMENTS]);
        } catch (error) {
            showToastError(error as AxiosError);
        }
        setIsLoading(false);
    };

    const handleSuccessClose = () => {
        setOpenSuccessDeleted(false);
        queryClient.invalidateQueries([CLIENT_PAYMENTS]);
    };

    const renderStatus = useMemo(() => {
        switch (data.status) {
            case EPaymentMethodStatus.FAILED:
                return (
                    <Tooltip title="Failed" placement="top">
                        <div>
                            <CrossInStarIcon/>
                        </div>
                    </Tooltip>
                );
            // case EPaymentMethodStatus.PENDING:
            //     return 'gray';
            case EPaymentMethodStatus.VERIFIED:
                return (
                    <CheckCurvyIcon
                        fill={theme.palette.green.dark}
                    />
                );
            default:
                return (
                    <CheckCurvyIconDisabled/>
                );
        }
    }, [data.status]);

    return (
        <>
            <Wrapper>
                {data.method === EPaymentMethod.ManualInvoicing
                    ? <PaymentsIcon/>
                    : data.method === EPaymentMethod.CreditCard ? <CreditCardIcon/> : <BankIcon/>}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        maxWidth: 'calc(100% - 40px)',
                    }}
                >
                    <Typography
                        sx={{
                            mr: '8px',
                            fontWeight: 500,
                            fontSize: '14px',
                        }}
                    >
                        {data.name
                            ? data.name
                            : data.method === EPaymentMethod.CreditCard ? 'Card' : 'Bank Name'
                        }

                    </Typography>

                    {
                        data.status && (
                            <Box
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    mr: '8px',
                                    'div, svg': {
                                        width: 'inherit',
                                        height: 'inherit'
                                    }
                                }}
                            >
                                {renderStatus}
                            </Box>
                        )
                    }

                    {
                        (!data.status || data.status === EPaymentMethodStatus.VERIFIED) && (
                            <>
                                {
                                    data.isPrimary ? (
                                        <LabelPrimary>
                                            Primary
                                        </LabelPrimary>
                                    ) : (
                                        <TextButton
                                            sx={{
                                                m: '7px 0'
                                            }}
                                            onClick={handleSetPrimaryClick}
                                        >
                                            {isClient ? 'Set as primary' : ''}
                                        </TextButton>
                                    )
                                }
                            </>
                        )

                    }
                    {
                        data.method !== EPaymentMethod.ManualInvoicing && (
                            <Typography
                                sx={{
                                    width: '100%',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    color: theme.palette.gray.main
                                }}
                            >
                                {data.method === EPaymentMethod.CreditCard ? 'Card' : 'Account'} number ending in {data.last4}
                            </Typography>
                        )
                    }

                </Box>

                {isLoading && <LoadingMask hasNoSpace size={24}/>}

                {!isLoading && (
                    <Box
                        className="actions"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            flexDirection: 'row-reverse',
                            ml: 'auto',
                            [theme.breakpoints.up('md')]: {
                                flexDirection: 'row'
                            },
                            [theme.breakpoints.up('lg')]: {
                                position: 'absolute',
                                top: '50%',
                                right: '1px',
                                transform: 'translateY(-50%)',
                                height: 'calc(100% - 2px)',
                                padding: '24px',
                                backgroundColor: theme.palette.white,
                                opacity: data.verificationUrl ? 1 : 0,
                                transition: 'opacity .3s',
                                pointerEvents: 'none'
                            }
                        }}
                    >

                        {data.status === EPaymentMethodStatus.PENDING && data.verificationUrl && (
                            <BlackBorderButton
                                disabled={isLoading || isImpersonal}
                                size="small"
                                onClick={() => window.open(data.verificationUrl, '_self')}
                            >
                                Finish verification
                            </BlackBorderButton>
                        )}

                        {isClient && isDeleteBtn && (
                            <>
                                <IconButton
                                    icon={<DeleteIcon/>}
                                    color={theme.palette.gray.main}
                                    colorActive={theme.palette.error.main}
                                    disabled={isLoading || isImpersonal}
                                    onClick={() => setOpenConfirmDelete(true)}
                                />
                                {/* <BlackBorderButton
                                    disabled={isLoading}
                                    size="small"
                                // onClick={onEdit}
                                >
                                    Edit
                                </BlackBorderButton> */}
                            </>
                        )}

                    </Box>
                )}

            </Wrapper>

            {/* Confirm Delete */}
            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            sx={{
                                width: mdDown ? '50% !important' : 'auto !important'
                            }}
                            onClick={() => setOpenConfirmDelete(false)}
                        >
                            Back
                        </BlackBorderButton>
                        <RedFilledButton
                            // disabled={isLoading}
                            sx={{
                                width: mdDown ? '50% !important' : 'auto !important',
                            }}
                            onClick={handleDeleteClick}
                        >
                            Yes, delete
                        </RedFilledButton>
                    </>
                )}
                maxWidth="lg"
                maxWidthPx={672}
                open={openConfirmDelete}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img
                                src="/assets/images/cross-icon.png"
                                width="48"
                                height="48"
                                alt="cross icon"
                            />
                        </Box>
                        Are you sure you want to delete this account number ending in {data.last4}?
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: theme.palette.gray.main
                            }}
                        >
                            This action can’t be undone.
                        </Typography>
                    </>
                )}
                onClose={() => setOpenConfirmDelete(false)}
            />

            {/* Success deleted */}
            <DialogEmoji
                actions={(
                    <>
                        <ContinueButton
                            sx={{
                                width: mdDown ? '50% !important' : 'auto !important',
                                'div': {
                                    justifyContent: 'center'
                                }
                            }}
                            variant="contained"
                            onClick={handleSuccessClose}
                        >
                            Back to Billing
                        </ContinueButton>
                    </>
                )}
                maxWidth="lg"
                maxWidthPx={672}
                open={openSuccessDeleted}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img
                                src="/assets/images/done-icon.png"
                                width="48"
                                height="48"
                                alt="done icon"
                            />
                        </Box>
                        Your account number ending in {data.last4}? has been successfully deleted
                    </>
                )}
                onClose={handleSuccessClose}
            />
        </>
    );
};

export default React.memo(PaymentMethodRow);
