import React from 'react';

import {Box, IconButton} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import {ArrowLeft} from '../../../assets/icons';
import theme from '../../../theme';

interface IProps {
    sx?: SxProps<Theme>;
    onClick: () => void;
}

const BackButton: React.FC<IProps> = ({sx, onClick}) => {
    return (
        <Box
            sx={{
                width: '64px',
                height: '64px',
                pointerEvents: 'none',
                ...sx
            }}
        >
            <Box
                className="back-button-track"
                sx={{
                    position: 'absolute',
                    top: '100px',
                    bottom: 0,
                    left: '20px',
                    zIndex: 10,
                    [theme.breakpoints.up('md')]: {
                        top: '138px',
                    },
                    [theme.breakpoints.up(1672)]: {
                        left: 'calc(50vw - 820px)'
                    }
                }}
            >
                <Box
                    sx={{
                        position: 'sticky',
                        top: '80px',
                        left: 0,
                        height: 'max-content',
                        [theme.breakpoints.up('md')]: {
                            top: '100px',
                        }
                    }}
                >
                    <IconButton
                        sx={{
                            width: '64px',
                            height: '64px',
                            backgroundColor: theme.palette.white.main,
                            boxShadow: '0px 8px 42px 0px rgba(0, 0, 0, 0.25)',
                            pointerEvents: 'auto',
                            '&:hover': {
                                backgroundColor: theme.palette.white.main,
                            }
                        }}
                        onClick={onClick}
                    >
                        <ArrowLeft/>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(BackButton);
