import React, {Ref} from 'react';

import {Grid, Theme, Typography, useMediaQuery} from '@mui/material';

import {IWorkDay} from '../../models';
import {formatHoursFromDuration} from '../../utils';
import {DayLabel} from '../Timesheets';

interface IProps extends IWorkDay {
    ref?: Ref<HTMLDivElement | null>;
}

const WorkDayGrid: React.FC<IProps> = React.forwardRef(({date, workDescription, workTime}, ref) => {
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Grid
            container
            spacing={mdUp ? '24px' : '32px'}
            alignItems="center"
            ref={ref}
            sx={{
                mt: 0,
                '&:first-of-type': {
                    mt: '-24px'
                }
            }}
            tabIndex={0}
        >
            <Grid item xs={6} md={2} flexShrink={0}>
                <DayLabel date={date}/>
            </Grid>

            <Grid item xs={12} md={8} order={{xs: 3, md: 2}}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: 1.5
                    }}
                >
                    {workDescription || '-'}
                </Typography>
            </Grid>

            <Grid item xs={6} md={2} order={{xs: 2, md: 3}}>
                <Typography
                    sx={{
                        textAlign: 'right',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: 1.5
                    }}
                >
                    {(workTime && workTime !== 'PT0S') ? formatHoursFromDuration(workTime) : '0'}h
                </Typography>
            </Grid>
        </Grid>
    );
});

export default React.memo(WorkDayGrid);
