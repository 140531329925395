import React from 'react';

import {Button, ButtonProps, styled} from '@mui/material';

import {PlusIcon} from '../../../assets/icons';
import {SM} from '../../../constants';
import theme from '../../../theme';

const AddButton = styled(Button)`
  width: 100%;
  height: 109px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: ${theme.palette.white.main};
  background-color: ${theme.palette.purple.main};
  border-radius: 16px;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);

  svg {
    width: 16px;
    height: 16px;
  }

  path {
    fill: currentColor;
  }

  &:hover {
      background-color: ${theme.palette.purple.main};
      box-shadow: 0px 0px 100px rgba(126, 95, 255, 0.5);
  }

  @media (min-width: ${SM}) {
    width: 200px;
    height: auto;
    aspect-ratio: 1/1;
  }
`;

const AddNewItemButton = ({children, sx, ...props}: ButtonProps) => {
    return (
        <AddButton
            {...props}
            sx={sx}
        >
            <PlusIcon/>
            {children}
        </AddButton>
    );
};

export default React.memo(AddNewItemButton);
