import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../../api';
import {FREELANCER_MATCHES, GET_MATCHED, RouteClientPortal, RouteFreelancerPortal} from '../../../constants';
import {useAuth} from '../../../hooks';
import {IFreelancerMatchList, IRoleWithMatching} from '../../../models';
import {EFilter} from '../../../modules/FreelancerPortal/MyMatches/components';
import LoadingMask from '../../LoadingMask';
import {WhiteWrapperBox} from '../DashboardLayout';
import {ClientMatch, FreelancerMatch, ShowMoreButton} from '../Matches';

import {MyCurrentMatchesEmpty} from './MyCurrentMatchesEmpty';

const MyCurrentMatches = () => {
    const {isClient, isFreelancer} = useAuth();

    const navigate = useNavigate();

    const {
        data: dataClient,
        isFetching: isLoadingClient,
    } = useQuery([GET_MATCHED], clientService.getMatched, {
        enabled: isClient,
        staleTime: 60 * 1000,
        select: ((response) => response.data)
    });

    const {
        data: dataFreelancer,
        isFetching: isLoadingFreelancer
    } = useQuery(
        [FREELANCER_MATCHES, EFilter.Active, 'pageSize=3'],
        freelancerService.getMatches(EFilter.Active || '', 3, 'id,DESC', true),
        {enabled: isFreelancer}
    );

    const isLoading = isLoadingClient || isLoadingFreelancer;
    const items = dataClient?.slice(0, 3) || dataFreelancer?.data as IFreelancerMatchList[] || [];
    const totalCount = dataClient?.length || dataFreelancer?.total || 0;

    const isEmpty = !isLoading && !totalCount;

    return (
        <WhiteWrapperBox sx={{
            height: isEmpty ? '100%' : null
        }}>
            <div className="title">
                <img src="/assets/images/fire-icon.png" width="24" height="24" alt="fire icon"/>
                My current matches
            </div>

            {isLoading ? (
                <Box display="flex" justifyContent="center">
                    <LoadingMask hasNoSpace/>
                </Box>
            ) : (
                <>
                    {!isEmpty
                        ? items.map(item => isClient
                            ? <ClientMatch key={item.id} data={item as IRoleWithMatching}/>
                            : <FreelancerMatch key={item.id} data={item as IFreelancerMatchList}/>
                        )
                        : <MyCurrentMatchesEmpty/>
                    }

                    {totalCount > 3 && (
                        <ShowMoreButton onClick={() => navigate((isClient ? RouteClientPortal : RouteFreelancerPortal).myMatches)}/>
                    )}
                </>
            )}

        </WhiteWrapperBox>
    );
};

export default React.memo(MyCurrentMatches);
