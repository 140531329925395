import {AxiosError} from 'axios';
import {Theme, toast, ToastPosition} from 'react-toastify';

const LIMIT_SIZE = 120;

const DEFAULT_CONFIG = {
    position: 'top-right' as ToastPosition,
    theme: 'light' as Theme,
    // hideProgressBar: true    // i like progress bar :)
};

export const showToastError = (error: AxiosError | string) => {
    const show = (message: string) => {
        toast.error(
            message.substring(0, LIMIT_SIZE),
            {
                ...DEFAULT_CONFIG,
                toastId: message,
            }
        );
    };

    const result = showFriendlyMessage(error);
    const message = typeof result === 'string' ? result : result.text;

    if (Array.isArray((error as any).response?.data.errors)) {
        (error as any).response.data.errors.forEach((el: any) =>
            el.message
                ? show(`${el.field ? el.field + ' - ' : ''}${el.message}`)
                : show(message)
        );
    } else if (message) {
        show(message);
    } else {
        const {error: err, error_description, message} = (error as any).response?.data || {};
        const result = message || error_description || (typeof err === 'string' ? err : '');

        show(result || (error as any)?.message);
    }

    // show toast and also redirect to the 500 page
    if ((result as any).isRedirectToFailedPage) {
        const event = new CustomEvent('isRedirectToFailedPage');

        document.body.dispatchEvent(event);
    }
};

export const showToastInfo = (message: string) => {
    toast.info((message), {...DEFAULT_CONFIG, toastId: message});
};

export const showToastSuccess = (message: string) => {
    toast.success((message), {
        ...DEFAULT_CONFIG,
        toastId: message,
    });
};

function showFriendlyMessage(error: AxiosError | string): string | { isRedirectToFailedPage: boolean; text: string } {
    let text = '';

    if (typeof error === 'string') {
        text = error;
    } else if (error.response?.status) {
        switch (error.response.status) {
            case 0:
            // case 400:
            // case 404:
            // case 409:
            case 500:
            case 501:
            case 504:
                text = (error as any).response.data?.error;

                return {
                    isRedirectToFailedPage: true,
                    text
                };

            // case 401:
            case 403:
                if (error.message && error.message.startsWith('Token: expired')) {
                    text = 'Session expired';
                    // this.stateStore.destroySession();
                } else {
                    text = error.message || 'Unauthorized!';
                }
                break;
            case 404:
                text = (error?.response?.data as any)?.error || error.message;
                break;
        }
    }

    return text;
}
