import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {LayoutGroup} from 'framer-motion';
import {FormProvider, SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, FormHelperText, Stack, Theme, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {useAuth} from '../../../../hooks';
import {ECaseStudySteps} from '../../../../models';
import theme from '../../../../theme';
import {outcomesSchema} from '../../../../utils';
import {
    AnimateHeight,
    AnimatePageWrp,
    Button,
    ContinueButton,
    GrayButton,
    Portal,
    showToastError,
    showToastSuccess,
    useCaseStudy,
    WhiteButton,
} from '../../..';

import Outcome from './components/Outcome';
import {InputCaption} from './styles';

const DEFAULT_VALUES = [{
    description: '',
    value: ''
}, {
    description: '',
    value: ''
}];

type OutcomesInput = TypeOf<typeof outcomesSchema>;

export const Step4Outcomes = () => {
    const {isImpersonal} = useAuth();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const {state: {data, cancelUrl, isCanBePublished}, onRefetch, publish, updateCaseStudy} = useCaseStudy();

    const location = useLocation();
    const navigate = useNavigate();

    let nextStep = location.pathname.replace(ECaseStudySteps.outcomes, ECaseStudySteps.testimonial);
    const prevStep = location.pathname.replace(ECaseStudySteps.outcomes, ECaseStudySteps.challenge);

    const methods = useForm<OutcomesInput>({
        resolver: zodResolver(outcomesSchema),
        defaultValues: {
            outcomes: DEFAULT_VALUES
        }
    });

    const {
        control,
        formState: {isDirty, isValid, errors},
        handleSubmit,
        getValues,
        reset,
        watch
    } = methods;

    const outcome = watch('outcomes', []);

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'outcomes'
    });

    const {mutate, isLoading: isSubmitting} = useMutation(
        (values: OutcomesInput) => updateCaseStudy({...data?.draftVersion, ...values, id: data?.id}),
        {
            onSuccess(response: any) {
                showToastSuccess('Outcomes were saved successfully!');

                if (nextStep === 'publish') {
                    publish(response.data.id);
                } else {
                    navigate(nextStep);
                    onRefetch();
                }
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handlePublishClick = () => {
        const values = getValues();

        nextStep = 'publish';
        mutate(values);
    };

    const handleSaveAndExit = () => {
        const values = getValues();

        nextStep = cancelUrl;
        mutate(values);
    };

    const onSubmitHandler: SubmitHandler<OutcomesInput> = (values: OutcomesInput) => {
        if (isDirty && !isImpersonal) {
            mutate(values);
        } else {
            navigate(nextStep);
        }
    };

    useEffect(() => {
        if (!data) return;

        const {outcomes} = data.draftVersion;

        reset({outcomes: outcomes.length ? outcomes : DEFAULT_VALUES});
    }, [data, reset]);

    return (
        <AnimatePageWrp>

            <FormProvider {...methods}>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <Stack
                        direction="column"
                    >
                        <InputCaption
                            sx={{
                                mb: '24px',
                                [theme.breakpoints.up('md')]: {
                                    mb: '48px'
                                }
                            }}
                        >
                            Include up to 4 outcomes for this case study and provide descriptions for each of them if
                            necessary.
                        </InputCaption>

                        <AnimateHeight isVisible={true} isOverflowHidden={false}>
                            <LayoutGroup>
                                {fields.map((item, index) => (
                                    <Outcome
                                        disabled={isSubmitting}
                                        index={index}
                                        isDeletable={outcome.length > 2}
                                        key={item.id}
                                        name={`outcomes.${index}`}
                                        onDelete={remove}
                                    />
                                ))}
                            </LayoutGroup>
                        </AnimateHeight>

                        <Box
                            sx={{
                                mb: '40px'
                            }}
                        >
                            <AnimateHeight isVisible={!!errors.outcomes}>
                                <FormHelperText id="error-outcomes" sx={{color: theme.palette.error.main}}>
                                    {errors.outcomes?.message}
                                </FormHelperText>
                            </AnimateHeight>

                            <Button
                                disabled={outcome.length >= 4}
                                variant="text"
                                sx={{
                                    p: 0 + '!important',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: theme.palette.purple.main
                                    }
                                }}
                                onClick={() => append(DEFAULT_VALUES[0])}
                            >
                                + Add outcome
                            </Button>
                        </Box>

                    </Stack>
                </form>
            </FormProvider>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '16px',
                        flexWrap: 'wrap'
                    },
                    'button': {
                        width: 'auto'
                    }
                }}
                textAlign="center"
            >
                <WhiteButton
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={() => navigate(prevStep)}
                >
                    Back
                </WhiteButton>
                <ContinueButton
                    disabled={isSubmitting}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
                <GrayButton
                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>
                {isCanBePublished && (
                    <GrayButton
                        disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                display: 'none'
                            }
                        }}
                        variant="contained"
                        onClick={handlePublishClick}
                    >
                        Publish
                    </GrayButton>
                )}
            </Box>
            {
                isSmallScreen && (
                    <>
                        <Portal fullWidth order={1} innerPages>
                            <ContinueButton
                                disabled={isSubmitting}
                                hasArrow
                                variant="contained"
                                sx={{
                                    'div': {
                                        justifyContent: 'center !important'
                                    }
                                }}
                                onClick={handleSubmit(onSubmitHandler)}
                            >
                                Continue
                            </ContinueButton>

                        </Portal>

                        <Portal fullWidth order={2} innerPages>
                            <WhiteButton
                                onClick={() => navigate(prevStep)}
                            >
                                Back
                            </WhiteButton>
                        </Portal>

                        <Portal fullWidth={!isCanBePublished} order={3} innerPages>
                            <GrayButton
                                disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                fullWidth
                                variant="contained"
                                onClick={handleSaveAndExit}
                            >
                                Save & exit
                            </GrayButton>
                        </Portal>

                        {isCanBePublished && (
                            <Portal order={4} innerPages>
                                <GrayButton
                                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                    variant="contained"
                                    onClick={handlePublishClick}
                                >
                                    Publish
                                </GrayButton>
                            </Portal>
                        )}
                    </>
                )
            }
        </AnimatePageWrp>
    );
};
