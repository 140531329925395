import React, {useState} from 'react';
import clsx, {ClassValue} from 'clsx';
import {Controller, useFormContext} from 'react-hook-form';

import {ClickAwayListener, FormControl, FormHelperText, TextFieldProps as MUITextFieldProps} from '@mui/material';
import {DesktopTimePicker} from '@mui/x-date-pickers/DesktopTimePicker';

import {AnimateHeight} from '../..';
import {styleInput} from '../Input/Input';


type TextFieldProps = MUITextFieldProps & {
    classNames?: ClassValue[];
    disablePast?: boolean;
    label?: string;
    maxTime?: Date;
    minTime?: Date;
}

const InputTime = ({
    classNames,
    disablePast,
    disabled,
    fullWidth = true,
    helperText,
    label,
    maxTime,
    minTime,
    name = '',
    sx,
    variant,
    ...props
}: TextFieldProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const handleClick = (event: any) => {
        const value = event.target.outerText;

        if (!Number(value)) {
            setOpen(false);
        }
    };

    const {
        control,
        formState: {errors},
    } = useFormContext();

    const handleKeyDown = (event: any) => {
        if (event.code === 'Enter' && ['AM', 'PM', 'OK'].includes(event.target.outerText)) {
            handleClick(event);
        }
    };

    return (
        <Controller
            control={control}
            defaultValue=''
            name={name}
            render={({field: {ref, ...field}}) => (
                <FormControl
                    disabled={disabled}
                    error={!!errors[name]}
                    fullWidth={fullWidth}
                    sx={{
                        ...styleInput,
                        ...sx,
                    }}
                    onKeyDown={handleKeyDown}
                >
                    <ClickAwayListener onClickAway={handleClick} mouseEvent="onMouseDown">
                        <DesktopTimePicker
                            {...field}
                            className={clsx(classNames)}
                            closeOnSelect={false}
                            disabled={disabled}
                            disablePast={disablePast}
                            inputRef={ref}
                            label={label}
                            minutesStep={5}
                            minTime={minTime}
                            maxTime={maxTime}
                            open={open}
                            slotProps={{
                                actionBar: {actions: ['cancel', 'accept']},
                                textField: {
                                    error: !!errors[name],
                                    helperText: '',
                                    variant: variant || 'standard'
                                },
                            }}
                            onOpen={() => setOpen(true)}
                        />
                    </ClickAwayListener>


                    {/* Show/Hide validation errors with animation */}
                    <AnimateHeight isVisible={!!(errors[name]?.message || helperText)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {errors[name]?.message?.toString() || helperText}
                        </FormHelperText>
                    </AnimateHeight>

                </FormControl>
            )}
        />
    );
};

export default React.memo(InputTime);
