import React from 'react';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {Box, Stack, Typography} from '@mui/material';

import {useAuth} from '../../hooks';
import theme from '../../theme';

const ImpersonalWrapper = () => {
    const {user} = useAuth();

    return (
        <Box
            sx={{
                height: '100vh',
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                border: '5px solid',
                borderColor: theme.palette.error.main,
                zIndex: 10000,
                pointerEvents: 'none',
            }}
        >
            <Box display="flex" justifyContent="center">
                <Box
                    sx={{
                        padding: '10px 12px 10px 12px',
                        borderRadius: '50px',
                        background: theme.palette.error.main,
                        mt: '2px'
                    }}
                >
                    <Stack direction="row" spacing={2} alignItems="center">
                        <VisibilityOutlinedIcon sx={{fill: theme.palette.white.main}}/>
                        <Typography sx={{color: theme.palette.white.main, fontSize: 14}}>Following {user?.name}</Typography>
                    </Stack>
                </Box>
            </Box>

        </Box>
    );
};

export default React.memo(ImpersonalWrapper);
