import isNil from 'lodash/isNil';

import {
    CREATE_ACCOUNT_STEP_1,
    CREATE_ACCOUNT_STEP_2,
    CREATE_ACCOUNT_STEP_3,
    CREATE_ACCOUNT_STEP_4,
    CREATE_ACCOUNT_STEP_5,
    CREATE_ACCOUNT_STEP_6,
    CREATE_ACCOUNT_STEP_7,
    CREATE_ACCOUNT_STEP_7_RESEND_CODE,
    CREATE_ACCOUNT_STEP_8,
    CREATE_ACCOUNT_STEP_8_CERTIFICATE,
    CREATE_ACCOUNT_STEP_8_REFERENCE,
    CREATE_ACCOUNT_STEP_8_STUDY,
    GET_APPLICATION,
    UPDATE_EMAIL,
} from '../constants';
import {EAttachmentType, IChannel, IReference, ITool, IVerticalsBrandsRequest} from '../models';
import {IApplication, IOthersRequest} from '../models/application.model';
import {IWorkPreference} from '../models/work.model';

import axiosInstance from './base.api';
import {ICertificateRequest} from './types';

const getApplication = async () => {
    const response = await axiosInstance.get<IApplication>(GET_APPLICATION);

    response.data.certificates?.forEach(item => item.type = EAttachmentType.certificate);
    response.data.references?.forEach(item => item.type = EAttachmentType.reference);
    response.data.caseStudies?.forEach(item => {
        item.type = EAttachmentType.study;
    });

    return response.data;
};

// Create Account Step 1
const experienceSubmit = async (yearsOfExperience: number) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_1, {yearsOfExperience});
};

// Create Account Step 2
const channelsSubmit = async (channels: IChannel[]) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_2, {channels});
};

// Create Account Step 3
const toolsSubmit = async (tools: ITool[]) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_3, {tools});
};

// Create Account Step 4
const verticalsBrandsSubmit = async (data: IVerticalsBrandsRequest) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_4, data );
};

// Create Account Step 5
const workTypeSubmit = async (data: IWorkPreference) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_5, data );
};

// Create Account Step 6
const othersSubmit = async (data: IOthersRequest) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_6, data );
};

// Create Account Step 7
const confimationCodeSubmit = async (code: string) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_7, {code} );
};

// Create Account Step 7 | Re send code
const resendCode = async () => {
    return await axiosInstance.post<null>(CREATE_ACCOUNT_STEP_7_RESEND_CODE);
};

// Create Account Step 7 | Update email
const updateEmail = async (email: string) => {
    return await axiosInstance.put<null>(UPDATE_EMAIL, {email});
};

// Create Account Step 8
const applicationSubmit = async () => {
    return await axiosInstance.put<null>(`${CREATE_ACCOUNT_STEP_8}`);
};

// Create Account Step 8 | Add Certificate
const certificateSubmit = async (payload: ICertificateRequest) => {
    const formData = new FormData();

    formData.append('name', payload.name);

    if (!isNil(payload.id)) {
        formData.append('id', payload.id.toString());
    }

    if (payload.file?.length) {
        payload.file.forEach(f => formData.append('file', f));
    }

    if (payload.url) {
        formData.append('url', payload.url);
    }

    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_8_CERTIFICATE, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

// Create Account Step 8 | Delete Certificate
const certificateDelete = async (id: number) => {
    return await axiosInstance.delete<null>(`${CREATE_ACCOUNT_STEP_8_CERTIFICATE}/${id}`);
};

// Create Account Step 8 | Add Reference
const referenceSubmit = async (payload: IReference) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_8_REFERENCE, payload);
};

// Create Account Step 8 | Delete Reference
const referenceDelete = async (id: number) => {
    return await axiosInstance.delete<null>(`${CREATE_ACCOUNT_STEP_8_REFERENCE}/${id}`);
};

// Create Account Step 8 | Add Case Study
const caseStudySubmit = async (formData: FormData) => {
    return await axiosInstance.put<null>(CREATE_ACCOUNT_STEP_8_STUDY, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

// Create Account Step 8 | Delete Reference
const caseStudyDelete = async (id: number) => {
    return await axiosInstance.delete<null>(`${CREATE_ACCOUNT_STEP_8_STUDY}/${id}`);
};

export const applicationService = {
    applicationSubmit,
    caseStudyDelete,
    caseStudySubmit,
    certificateSubmit,
    certificateDelete,
    channelsSubmit,
    confimationCodeSubmit,
    experienceSubmit,
    getApplication,
    othersSubmit,
    referenceDelete,
    referenceSubmit,
    resendCode,
    toolsSubmit,
    verticalsBrandsSubmit,
    workTypeSubmit,
    updateEmail,
};
