import React, {useState} from 'react';

import {useAuth} from '../../../hooks';
import {EClosureStatus, EEngagementStatus, IEngagement} from '../../../models';
import {ContinueButton, DialogCloseRequest} from '../..';

type CloseRespondBtnProps = {
    data: IEngagement;
};

const CloseRespondBtn: React.FC<CloseRespondBtnProps> = ({data}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);

    if (data.status === EEngagementStatus.CLOSED || data.closureStatus !== EClosureStatus.CLIENT_REQUESTED) {
        return null;
    }

    return (
        <>
            <ContinueButton
                disabled={isImpersonal}
                size="small"
                sx={{
                    width: 'auto!important'
                }}
                variant="contained"
                onClick={() => setOpen(true)}
            >
                Respond
            </ContinueButton>

            <DialogCloseRequest data={data} open={open} onClose={setOpen}/>

        </>
    );
};

export default React.memo(CloseRespondBtn);
