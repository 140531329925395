import React, {useMemo} from 'react';
import {AxiosError} from 'axios';
import capitalize from 'lodash/capitalize';
// import capitalize from 'lodash/fp';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';

import {Avatar, Box, IconButton, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {freelancerService, matchesService} from '../../../../api';
import {ArrowDown, CheckboxIcon, CloseIcon, LocationIcon} from '../../../../assets/icons';
import {
    DETAILS,
    FREELANCER_MATCHES,
    LG,
    MATCHE_WITH_ROLE,
    MD,
    RouteClientPortal,
    RouteFreelancerPortal
} from '../../../../constants';
import {useAuth, useConversation} from '../../../../hooks';
import {EModerationStatus, IBrand} from '../../../../models';
import theme from '../../../../theme';
import {formatCurrency, getNameLetters, stringToColor} from '../../../../utils';
import {BlackBorderButton, FoldingText, ImagePlaceholder, showToastError} from '../../../index';
import Pill from '../../../Pill';
import ChatTopButtons from '../ChatTopButtons';


const Wrp = styled('div')<{ isVisible?: boolean }>`
  padding: 16px 20px;
  width: 100%;
  flex-shrink: 0;
  background-color: ${theme.palette.white.main};
  overflow-y: auto;
  position: absolute;
  height: 100%;
  top: 0;
  left: ${props => props.isVisible ? 0 : '100%'};
  border-top: 1px solid ${theme.palette.lightGray.main};
  transition: left .3s linear;
  will-change: left;
  z-index: 15;

  @media (min-width: ${MD}) {
    padding: 24px;
    width: 455px;
    left: ${props => props.isVisible ? 'calc(100% - 455px)' : '100%'};
    border-left: 1px solid ${theme.palette.lightGray.main};
    border-radius: 0 24px 24px 0;
  }

  @media (min-width: ${LG}) {
    border-top: unset;
  }

  @media (min-width: 1400px) {
    position: static;
    width: 455px;
    will-change: unset;
  }
`;

const TopInfo = styled('div')<{ isClient?: boolean }>`
  display: grid;
  align-items: center;
  column-gap: 16px;
  grid-template-areas: 
  'backButton avatar name'
  'backButton avatar experience';
  grid-template-columns: 24px 48px auto;

  @media (min-width: ${LG}) {
    grid-template-areas: ${props => props.isClient ? (
        '"avatar name rate"\n' +
            '"avatar experience rate"'
    ) : (
        '"avatar name name"\n' +
            '"avatar experience experience"'
    )};
    grid-template-columns: 48px auto auto;
  }
`;

const RateBox = styled('div')`
  display: inline-flex;
  margin-top: 4px;
  padding: 8px 16px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.palette.purple.main};
  background-color: ${theme.palette.lilac.main};

  @media (min-width: ${LG}) {
    margin-right: -24px;
    margin-left: auto;
    margin-top: 0;
    padding: 8px 16px;
    border-radius: 50px 0 0 50px;
  }
`;

const BoxItem = styled('div')<{ isFlex?: boolean }>`
  display: ${props => props.isFlex ? 'flex' : 'block'};
  gap: 8px;
  justify-content: space-between;
  padding: 24px;
  margin: 0 -24px;
  border-bottom: 1px solid ${theme.palette.lightGray.main};

  &:last-of-type {
    border-bottom: unset;
  }
`;

const OpenWorkItem = styled('div')<{ isOpenToWork?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.isOpenToWork ? theme.palette.black.main : theme.palette.gray.main};

  &:last-of-type {
    margin-bottom: 0;
  }

  svg, .close-icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  path {
    fill: ${props => props.isOpenToWork ? theme.palette.purple.main : theme.palette.gray.main}
  }
`;

const Title = styled('p')<{ mt?: boolean }>`
  margin-top: ${props => props.mt ? '10px' : 0};
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.black.main};
`;

const CompaniesList = styled('ul')`
  margin: 0;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  list-style: none;
  padding: 0;

  li {
    display: grid;
    gap: 8px;
    grid-auto-rows: 40px auto;
    justify-items: center;
    max-width: calc(25% - 17px);
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${theme.palette.black.main};
  }

  img {
    display: block;
    width: 40px;
    height: auto;
    align-self: center;
    justify-self: center;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
  }
`;

const PillsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const ViewProfileWrp = styled('div')`
  height: 102px;
  margin: 0 -24px -24px;
  padding: 32px 24px;
  position: sticky;
  bottom: -24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, #FFFFFF 52.6%);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0 0 24px 0;
`;

const avatarStyles = {
    gridArea: 'avatar',
    width: '48px',
    height: '48px',
    backgroundColor: stringToColor('You'),
    fontSize: '14px',
    lineHeight: 1.2,
    fontWeight: 500,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    }
};

interface IProps {
    isVisible?: boolean;
    onChatDetailsOpen: (isOpen: boolean) => void;
}

const ChatDetails: React.FC<IProps> = ({isVisible, onChatDetailsOpen}) => {
    const {isClient, isFreelancer} = useAuth();
    const {conversationId} = useParams();
    const {attributes: {matchId}} = useConversation(conversationId || '');

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const navigate = useNavigate();

    // if CLIENT - for the new get Role to populate Name and Description
    const {
        // isLoading,
        data: clientMatchingData
    } = useQuery([MATCHE_WITH_ROLE, matchId], () => matchesService.getMatchWithRole(matchId), {
        enabled: !!matchId && isClient,
        staleTime: 15 * 60 * 1000,
        select: ((response) => response.data),
        onError: (error) => showToastError(error as AxiosError)
    });

    // if Freelancer - for the new get Role to populate Name and Description
    const {
        // isLoading,
        data: freelancerMatchingData
    } = useQuery([FREELANCER_MATCHES, matchId], () => freelancerService.getMatchRole(matchId), {
        enabled: !!matchId && isFreelancer,
        staleTime: 15 * 60 * 1000,
        select: ((response) => response.data),
        onError: (error) => showToastError(error as AxiosError)
    });

    const clientData = clientMatchingData?.dynamicProfile;

    const brandsApproved = useMemo(() => {
        return clientData?.brands?.filter(brand => brand.moderationStatus === EModerationStatus.APPROVED);
    }, [clientData?.brands]);

    return (
        <Wrp isVisible={isVisible}>
            <TopInfo isClient={!!clientData}>
                {!lgUp && (
                    <IconButton
                        sx={{
                            ml: '-8px',
                            mr: '-8px',
                            width: '36px',
                            height: '36px',
                            gridArea: 'backButton',
                            'svg': {
                                transform: 'rotate(90deg)'
                            }
                        }}
                        onClick={() => onChatDetailsOpen(false)}
                    >
                        <ArrowDown/>
                    </IconButton>
                )}

                {
                    clientData ? (
                        <Avatar
                            alt={clientData.avatar}
                            className="avatar"
                            src={clientData.avatar}
                            sx={{
                                ...avatarStyles,
                                backgroundColor: stringToColor(`${clientData.firstName} ${clientData.lastName}`)
                            }}
                        >
                            {getNameLetters(`${clientData.firstName} ${clientData.lastName}`)}
                        </Avatar>
                    ) : (
                        <>
                            {
                                freelancerMatchingData?.client?.companyLogo ? (
                                    <Avatar
                                        alt={freelancerMatchingData.client?.companyName}
                                        className="avatar"
                                        src={freelancerMatchingData.client?.companyLogo}
                                        sx={{...avatarStyles}}
                                    >
                                    </Avatar>
                                ) : (
                                    <ImagePlaceholder height="48" width="48"/>
                                )
                            }
                        </>
                    )
                }

                <Typography
                    sx={{
                        gridArea: 'name',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.black.main,
                        'span': {
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '21px',
                            color: theme.palette.gray.main
                        },
                        [theme.breakpoints.up('lg')]: {
                            fontSize: '16px',
                            lineHeight: 1.5,
                        }
                    }}
                >
                    {clientData && `${clientData.firstName} ${clientData.lastName}`}
                    {freelancerMatchingData?.projectName}
                    &nbsp;
                    {
                        clientData && <span>({clientData.country})</span>
                    }
                </Typography>
                {
                    clientData && (
                        <Typography
                            sx={{
                                gridArea: 'experience',
                                fontWeight: 400,
                                fontSize: '13px',
                                lineHeight: '20px',
                                color: theme.palette.black.main,
                                'span': {
                                    font: 'inherit',
                                    lineHeight: 'inherit',
                                    color: theme.palette.gray.main
                                }
                            }}
                        >
                            {clientData?.yearsOfExperience}
                            &nbsp;
                            year{clientData && clientData?.yearsOfExperience > 1 ? 's' : ''}
                            &nbsp;
                            <span>
                        of experience in marketing
                            </span>
                        </Typography>
                    )
                }
                {
                    freelancerMatchingData && (
                        <Typography
                            sx={{
                                gridArea: 'experience',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                fontWeight: 600,
                                fontSize: '13px',
                                lineHeight: '20px',
                                color: theme.palette.black.main,
                                'span': {
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    pl: '12px',
                                    font: 'inherit',
                                    fontWeight: 400,
                                    lineHeight: 'inherit',
                                    color: theme.palette.gray.main,
                                    position: 'relative',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '50%',
                                        left: 0,
                                        transform: 'translateY(-50%)',
                                        width: '4px',
                                        height: '4px',
                                        borderRadius: '50%',
                                        backgroundColor: 'currentColor'
                                    }
                                }
                            }}
                        >
                            {freelancerMatchingData.client?.companyName}
                            &nbsp;
                            {freelancerMatchingData.countries && (
                                <span><LocationIcon/>{freelancerMatchingData.countries.join(', ')}</span>
                            )}

                            <span>{capitalize(freelancerMatchingData.tier)}</span>
                        </Typography>
                    )
                }
                {
                    lgUp && clientData && (
                        <RateBox>
                            {clientData?.rate ? formatCurrency(clientData.rate) : ''}/hour
                        </RateBox>
                    )
                }
            </TopInfo>
            {
                !lgUp && (
                    <Box
                        sx={{
                            padding: '12px 20px',
                            mt: '14px',
                            mx: '-24px',
                            borderTop: '1px solid ' + theme.palette.lightGray.main,
                            borderBottom: '1px solid ' + theme.palette.lightGray.main
                        }}
                    >
                        <ChatTopButtons conversationId={conversationId || ''}/>
                    </Box>
                )
            }
            {(clientData?.bio || freelancerMatchingData?.projectDescription) && (
                <FoldingText
                    text={(clientData?.bio || freelancerMatchingData?.projectDescription || '').replaceAll('\n', ' ')}
                    sx={{
                        marginTop: '24px',
                        '.text': {
                            color: theme.palette.textGray.dark,
                        }
                    }}
                />
            )}
            {
                clientData && (
                    <>
                        <BoxItem>
                            <OpenWorkItem isOpenToWork={clientData?.isAvailableForNewProject}>
                                {
                                    clientData?.isAvailableForNewProject ?
                                        <><CheckboxIcon/>Open to working with agencies</> :
                                        <><CloseIcon/>Not open to working with agencies</>
                                }
                            </OpenWorkItem>
                            <OpenWorkItem isOpenToWork={clientData?.isOpenToHireRole}>

                                {
                                    clientData?.isOpenToHireRole ?
                                        <><CheckboxIcon/>Open to contract to hire roles</> :
                                        <><CloseIcon/>Not open to contract to hire roles</>
                                }
                            </OpenWorkItem>
                            {
                                !lgUp && clientData && (
                                    <RateBox>
                                        {clientData?.rate ? formatCurrency(clientData.rate) : ''}/hour
                                    </RateBox>
                                )
                            }
                        </BoxItem>
                        <BoxItem>
                            <Title>I’ve worked in</Title>
                            <CompaniesList>
                                {
                                    brandsApproved?.map((brand: IBrand) => (
                                        <li key={brand.id}
                                        >
                                            <ImagePlaceholder width="40" height="40">
                                                <img src={brand.logo} width="40" height="40" alt={brand.name}/>
                                            </ImagePlaceholder>
                                            {brand.name}
                                        </li>
                                    ))
                                }
                            </CompaniesList>
                        </BoxItem>
                    </>
                )
            }
            {
                freelancerMatchingData?.rateRange && (
                    <BoxItem isFlex>
                        <Title mt>Salary range</Title>
                        <PillsWrapper>
                            <Pill
                                variant="purple"
                            >
                                {freelancerMatchingData.rateRange.map(it => formatCurrency(it)).join(' - ')}
                            </Pill>
                        </PillsWrapper>
                    </BoxItem>
                )
            }
            <BoxItem isFlex>
                <Title mt>Channels</Title>
                <PillsWrapper>
                    {
                        clientData?.channels?.common?.map(channel => (
                            <Pill
                                key={channel.id}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {channel.name}
                            </Pill>
                        ))
                    }
                    {
                        clientData?.channels?.additional?.map(channel => (
                            <Pill
                                key={channel.id}
                                variant="gray"
                            >
                                {channel.name}
                            </Pill>
                        ))
                    }
                    {
                        freelancerMatchingData?.channel && (
                            <Pill
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {freelancerMatchingData.channel.name}
                            </Pill>
                        )
                    }
                </PillsWrapper>
            </BoxItem>
            {
                clientData?.subchannels && (
                    <BoxItem isFlex>
                        <Title mt>Subchannels</Title>
                        <PillsWrapper>
                            {
                                clientData?.subchannels?.common?.map(subchannel => (
                                    <Pill
                                        key={subchannel.id}
                                        hasIcon
                                        hasOutline
                                        variant="green"
                                    >
                                        {subchannel.name}
                                    </Pill>
                                ))
                            }
                            {
                                clientData?.subchannels?.additional?.map(subchannel => (
                                    <Pill
                                        key={subchannel.id}
                                        variant="gray"
                                    >
                                        {subchannel.name}
                                    </Pill>
                                ))
                            }
                        </PillsWrapper>
                    </BoxItem>
                )
            }
            {
                freelancerMatchingData?.channel?.subchannels && (freelancerMatchingData?.channel?.subchannels?.length > 0) && (
                    <BoxItem isFlex>
                        <Title mt>Subchannels</Title>
                        <PillsWrapper>
                            {
                                freelancerMatchingData?.channel.subchannels.map(subchannel => (
                                    <Pill
                                        key={subchannel.id}
                                        variant="gray"
                                    >
                                        {subchannel.name}
                                    </Pill>
                                ))
                            }
                        </PillsWrapper>
                    </BoxItem>
                )
            }
            <BoxItem isFlex>
                <Title mt>Verticals</Title>
                <PillsWrapper>
                    {
                        clientData?.verticals?.common?.map(vertical => (
                            <Pill
                                key={vertical.id}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {vertical.name}
                            </Pill>
                        ))
                    }
                    {
                        clientData?.verticals?.additional?.map(vertical => (
                            <Pill
                                key={vertical.id}
                                variant="gray"
                            >
                                {vertical.name}
                            </Pill>
                        ))
                    }
                    {
                        freelancerMatchingData?.verticals?.map(vertical => (
                            <Pill
                                key={vertical.id}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {vertical.name}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </BoxItem>
            <BoxItem isFlex>
                <Title mt>Tools</Title>
                <PillsWrapper>
                    {
                        clientData?.tools?.common?.map(tool => (
                            <Pill
                                key={tool.id}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {tool.name}
                            </Pill>
                        ))
                    }
                    {
                        clientData?.tools?.additional?.map(tool => (
                            <Pill
                                key={tool.id}
                                variant="gray"
                            >
                                {tool.name}
                            </Pill>
                        ))
                    }
                    {
                        freelancerMatchingData?.tools?.map(tool => (
                            <Pill
                                key={tool.id}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {tool.name}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </BoxItem>
            <BoxItem isFlex>
                <Title mt>Budget type</Title>
                <PillsWrapper>
                    {
                        clientData?.workTypes?.common?.map(workType => (
                            <Pill
                                key={workType}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {capitalize(workType)}
                            </Pill>
                        ))
                    }
                    {
                        clientData?.workTypes?.additional?.map(workType => (
                            <Pill
                                key={workType}
                                variant="gray"
                            >
                                {capitalize(workType)}
                            </Pill>
                        ))
                    }
                    {
                        freelancerMatchingData?.businessModels?.map(budgetType => (
                            <Pill
                                key={budgetType}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {budgetType}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </BoxItem>
            <BoxItem isFlex>
                <Title mt>Languages</Title>
                <PillsWrapper>
                    {
                        clientData?.languages?.common?.map(language => (
                            <Pill
                                key={language}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {capitalize(language)}
                            </Pill>
                        ))
                    }
                    {
                        clientData?.languages?.additional?.map(language => (
                            <Pill
                                key={language}
                                variant="gray"
                            >
                                {capitalize(language)}
                            </Pill>
                        ))
                    }
                    {
                        freelancerMatchingData?.languages?.map(language => (
                            <Pill
                                key={language}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {capitalize(language)}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </BoxItem>
            <ViewProfileWrp>
                {
                    clientData && (
                        <BlackBorderButton
                            fullWidth
                            size="small"
                            onClick={() => navigate(`${RouteClientPortal.publicFreelancerProfile}/${clientData.id}`)}
                        >
                            View profile
                        </BlackBorderButton>
                    )
                }
                {
                    freelancerMatchingData && (
                        <BlackBorderButton
                            fullWidth
                            size="small"
                            onClick={() => navigate(`${RouteFreelancerPortal.myMatches}/${DETAILS}/${matchId}`)}
                        >
                            Role details
                        </BlackBorderButton>
                    )
                }
            </ViewProfileWrp>
        </Wrp>
    );
};

export default ChatDetails;
