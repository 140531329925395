import {ILegalDoc} from './attachment.model';
import {IEmailVerificationProcess} from './verification-proccess';
import {EWorkType} from './work.model';

export interface IFreelancer {
    avatar?: string;
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    created: string;
    phone: string;
}

export interface IFreelancerWithEmailVerificationProcess extends IFreelancer {
    emailVerificationProcess?: IEmailVerificationProcess;
    legalDocuments?: ILegalDoc[];
}

export interface IFreelancerMatchList {
    companyLogo?: string;
    companyName: string;
    country: string;
    id: number;
    isActive: boolean;
    isLiked: boolean;
    isMessaged: boolean;
    languages: string[];
    projectName: string;
    publishedAt: string;
    rateRange: [number, number];
    roleId: number;
    workTypes: EWorkType[];
}

export enum EWorkStatus {
    draft = 'DRAFT',
    submitted = 'SUBMITTED',
    matchingInProcess = 'MATCHING_IN_PROGRESS',
    mathchingFinished = 'MATCHING_FINISHED',
    inReview = 'IN_REVIEW',
    finished = 'FINISHED',
    cancelled = 'CANCELLED'
}
