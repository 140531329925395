import React, {ReactNode} from 'react';

import {Box, Theme, Typography, useMediaQuery} from '@mui/material';

import theme from '../../theme';
import {BlackBorderButton, ContinueButton, DialogEmoji, RedFilledButton} from '..';

type DialogConfirmProps = {
    disabled?: boolean;
    error?: boolean;
    iconUrl: string;
    labelBtn: string;
    open: boolean;
    subtitle?: ReactNode;
    title: ReactNode;
    onClose: (isNeedUpdate: boolean) => void;
};

const DialogConfirm: React.FC<DialogConfirmProps> = ({disabled, error, iconUrl, labelBtn, open, subtitle, title, onClose}) => {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <DialogEmoji
            actions={(
                <>
                    <BlackBorderButton
                        disabled={disabled}
                        sx={{
                            width: mdDown ? '50%' : 'auto'
                        }}
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </BlackBorderButton>

                    {error ? (
                        <RedFilledButton
                            disabled={disabled}
                            sx={{
                                width: mdDown ? '50%' : 'auto'
                            }}
                            onClick={() => onClose(true)}
                        >
                            {labelBtn}
                        </RedFilledButton>
                    ) : (
                        <ContinueButton
                            variant="contained"
                            sx={{
                                width: mdDown ? '50% !important' : 'auto !important',
                                minWidth: mdDown ? '161px' : 'unset',
                                'div': {
                                    justifyContent: 'center'
                                }
                            }}
                            onClick={() => onClose(true)}
                        >
                            {labelBtn}
                        </ContinueButton>
                    )}
                </>
            )}
            maxWidth="sm"
            open={open}
            title={(
                <>
                    <Box justifyContent="center">
                        <img src={iconUrl} width="48" height="48" alt="icon"/>
                    </Box>
                    {title}
                    <Typography
                        align="center"
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.textGray.dark
                        }}
                        variant="caption"
                    >
                        {subtitle}
                    </Typography>
                </>
            )}
            onClose={() => onClose(false)}
        >
        </DialogEmoji>
    );
};

export default React.memo(DialogConfirm);
