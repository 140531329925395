import React from 'react';

const ArrowDown = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.1666 7.64076C14.0104 7.48556 13.7992 7.39844 13.5791 7.39844C13.3589 7.39844 13.1477 7.48556 12.9916 7.64076L9.99992 10.5908L7.04992 7.64076C6.89378 7.48556 6.68257 7.39844 6.46242 7.39844C6.24226 7.39844 6.03105 7.48556 5.87492 7.64076C5.79681 7.71823 5.73481 7.8104 5.69251 7.91195C5.6502 8.0135 5.62842 8.12242 5.62842 8.23243C5.62842 8.34244 5.6502 8.45136 5.69251 8.55291C5.73481 8.65446 5.79681 8.74663 5.87492 8.8241L9.40825 12.3574C9.48572 12.4355 9.57789 12.4975 9.67944 12.5398C9.78098 12.5821 9.88991 12.6039 9.99992 12.6039C10.1099 12.6039 10.2188 12.5821 10.3204 12.5398C10.4219 12.4975 10.5141 12.4355 10.5916 12.3574L14.1666 8.8241C14.2447 8.74663 14.3067 8.65446 14.349 8.55291C14.3913 8.45136 14.4131 8.34244 14.4131 8.23243C14.4131 8.12242 14.3913 8.0135 14.349 7.91195C14.3067 7.8104 14.2447 7.71823 14.1666 7.64076Z"
                fill="#121416"/>
        </svg>
    );
};

export default ArrowDown;
