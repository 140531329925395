import React from 'react';

interface ISeniorityLevelRange {
    rateRange: number[];
}

const SeniorityLevelRange = ({rateRange}: ISeniorityLevelRange) => {
    const [from, to] = rateRange.map(it => it / 100);  // format without cents

    if (from === 50 && to === 100) {
        return <>Mid level (${from} - ${to})</>;
    } else if (from === 100 && to === 150) {
        return <>Senior level (${from} - ${to})</>;
    } else if (from === 150) {
        return <>Executive level (${from}+)</>;
    }

    return <>n/a</>;
};

export default React.memo(SeniorityLevelRange);
