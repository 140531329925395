import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    styled,
    Theme,
    useMediaQuery
} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../api';
import {CloseIcon} from '../../assets/icons';
import {CLIENT_ENGAGEMENT, FREELANCER_ENGAGEMENT} from '../../constants';
import {useAuth} from '../../hooks';
import {EResumeStatus, EWorkType, IEngagement, IEngagementVersion, IProposal, IProposalRequest} from '../../models';
import theme from '../../theme';
import {localToUTC} from '../../utils';
import {InputRetainerType} from '../Forms/InputRetainerTypes';
import {ChatProposalSentView, propasalSchema} from '../Inbox';
import {
    AnimateHeight,
    BlackBorderButton,
    ContinueButton,
    DialogConfirm,
    DialogSuccess,
    Input,
    InputCaption,
    InputDate,
    InputWorkType,
    RedFilledButton,
    showToastError,
} from '../index';

const enum EResult {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

const Row = styled('div')`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${theme.palette.lightGray.main};
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: unset;
  }

  .MuiBox-root {
    min-width: 150px;
  }

  .green {
    display: inline-flex;
  }
`;

const Text = styled('p')`
  color: ${theme.palette.textGray.dark};
  font-size: 14px;

  &.primary {
    color: ${theme.palette.primary.main};
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
  }
`;

type PropasalInput = TypeOf<typeof propasalSchema>;

interface IProps {
    data: IEngagement;
    isReadOnly?: boolean;   // is Author open it or already Accepted/Rejected
    open: boolean;
    onClose: (open: boolean) => void;
}

const DialogProposal: FC<IProps> = ({data, isReadOnly, open, onClose}) => {
    const {isClient, isFreelancer, isImpersonal} = useAuth();
    const [openAccept, setOpenAccept] = useState(false);
    const [openCreated, setOpenCreated] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openSuccess, setOpenSuccess] = useState<EResult | null>(null);
    const queryClient = useQueryClient();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const isNewProposal = !data.resume
        || (isClient && [EResumeStatus.FREELANCER_REJECTED, EResumeStatus.FREELANCER_CANCELLED, EResumeStatus.CLIENT_CANCELLED].includes(data.resume?.resumeStatus as EResumeStatus))
        || (isFreelancer && [EResumeStatus.CLIENT_REJECTED, EResumeStatus.CLIENT_CANCELLED, EResumeStatus.FREELANCER_CANCELLED].includes(data.resume?.resumeStatus as EResumeStatus));

    const apiService = isClient ? clientService : freelancerService;

    const methods = useForm<PropasalInput>({
        resolver: zodResolver(propasalSchema),
    });

    const {
        handleSubmit,
        // formState: {isDirty},
        reset,
        setValue,
        watch
    } = methods;

    const rate = watch('rate');
    const weeklyEngagementHours = watch('weeklyEngagementHours');
    const workType = watch('workType', '');
    const isMonthly = watch('isMonthly', false);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (payload: IEngagementVersion) => apiService.resumeSubmit(data.id, payload),
        {
            onSuccess() {
                reset({});
                onClose(false);
                // play modal show/hide animation
                setTimeout(() => {
                    setOpenCreated(true);
                }, 500);
            },
            onError(error: AxiosError) {
                console.log(error);
                showToastError(error);
            },
        }
    );

    // refresh MyHires and MyWors pages
    const refetch = useCallback(() => {
        queryClient.invalidateQueries({queryKey: [CLIENT_ENGAGEMENT]});
        queryClient.invalidateQueries({queryKey: [FREELANCER_ENGAGEMENT]});
    }, [queryClient]);

    const handleAccept = useCallback(async (reason: boolean) => {
        if (!reason) {
            setOpenAccept(false);

            return;
        }

        try {
            await apiService.resumeAccept(data.id);
            reset({});
            setOpenAccept(false);
            // play modal show/hide animation
            setTimeout(() => {
                setOpenSuccess(EResult.Accepted);
            }, 500);
        } catch (error) {
            showToastError(error as AxiosError);
        }
    }, [apiService, data, reset]);

    const handleClose = useCallback(() => {
        reset({});
        onClose(false);
    }, [onClose, reset]);

    const handleCreatedClose = useCallback(() => {
        setOpenCreated(false);
        refetch();
    }, [refetch]);

    const handleReject = useCallback(async (reason: boolean) => {
        if (!reason) {
            setOpenReject(false);

            return;
        }
        try {
            await apiService.resumeReject(data.id);
            reset({});
            setOpenReject(false);

            // play modal show/hide animation
            setTimeout(() => {
                setOpenSuccess(EResult.Rejected);
            }, 500);
        } catch (error) {
            showToastError(error as AxiosError);
        }
    }, [apiService, data, reset]);

    const handleWorkTypeChange = useCallback((value: EWorkType | null) => {
        if (rate) {
            setValue('rate', '' as any);
        }
        if (weeklyEngagementHours) {
            setValue('weeklyEngagementHours', '' as any);
        }

        if (value === EWorkType.retainer) {
            setValue('isMonthly', true);
        } else {
            setValue('isMonthly', undefined);
        }
    }, [rate, weeklyEngagementHours, setValue]);

    const handleRetainerTypeChange = useCallback((value: boolean) => {
        setValue('isMonthly', value);
    }, [setValue]);

    const onSubmitHandler: SubmitHandler<PropasalInput> = (values: IProposalRequest) => {
        console.log('onSubmitHandler', values);

        const {isMonthly, startDate, rate, weeklyEngagementHours, workType} = values;
        const payload: IEngagementVersion = {
            isMonthly: !!isMonthly,
            startDate: startDate ? localToUTC(startDate as unknown as Date).toISOString().split('T')[0] : '',
            rate: rate ? Number(rate) * 100 : 0,  // convert with cents
            budgetType: workType,
        };

        if (weeklyEngagementHours) {
            payload.weeklyEngagement = weeklyEngagementHours;
        }

        mutate(payload);
    };

    // populate form
    useEffect(() => {
        if (data && open && isNewProposal) {
            const {description, isMonthly, name, budgetType} = data;

            reset({
                description,
                isMonthly,
                name,
                rate: data.rate ? (data.rate / 100) : '', // convert without cents
                startDate: isNewProposal ? new Date() : new Date(data.resume?.startDate || ''),
                weeklyEngagementHours: isNewProposal ? data.weeklyEngagement : data.resume?.weeklyEngagement,
                workType: budgetType
            });
        }
    }, [data, open, isNewProposal, reset]);

    const previewProposal = useMemo(() => {
        return {
            description: data.description,
            isMonthly: data.resume?.isMonthly,
            name: data.name,
            rate: data.resume?.rate ? data.resume.rate : 0,
            startDate: data.resume?.startDate,
            workType: data.resume?.budgetType,
            weeklyEngagementHours: data.resume?.weeklyEngagement
        } as unknown as IProposal;
    }, [data]);

    return (
        <>
            <Dialog
                fullWidth
                open={open}
                scroll="paper"
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: theme.palette.white.main,
                        maxWidth: '728px',
                        borderRadius: '24px',
                        margin: 0,
                        width: '100%'
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '16px',
                        padding: '40px 16px 32px',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: 1.5,
                        textAlign: 'center',
                        color: theme.palette.black.main,
                        position: 'relative',
                        'img': {
                            width: '32px',
                            height: '32px'
                        },
                        [theme.breakpoints.up('md')]: {
                            padding: '64px 64px 40px',
                            gap: '24px',
                            fontSize: '24px',
                            'img': {
                                width: '48px',
                                height: '48px'
                            },
                        }
                    }}
                >
                    <img src="/assets/images/play-icon.png" width="48" height="48" alt="proposal icon"/>
                    Resume engagement
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '18px',
                            right: '18px',
                            '.close-icon': {
                                width: '24px',
                                height: '24px',
                            },
                            'svg': {
                                width: '24px',
                                height: '24px',
                            }
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        p: '0 20px',
                        [theme.breakpoints.up('md')]: {
                            p: '0 64px',
                        }
                    }}
                >
                    {
                        isNewProposal ? (
                            <FormProvider {...methods}>
                                <form
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Row>
                                        <Box>
                                            <InputCaption
                                                sx={{
                                                    textAlign: 'left !important',
                                                    mb: '8px !important'
                                                }}
                                            >
                                                Role name
                                            </InputCaption>
                                            <Text>{data.name}</Text>
                                        </Box>
                                    </Row>

                                    <InputCaption
                                        sx={{
                                            mt: '21px',
                                            textAlign: 'left !important',
                                        }}
                                    >
                                        Budget type
                                    </InputCaption>

                                    <InputWorkType name="workType" onChange={handleWorkTypeChange}/>

                                    <Box
                                        sx={{
                                            m: '0 -20px',
                                            [theme.breakpoints.up('md')]: {
                                                m: '0 -64px'
                                            }
                                        }}
                                    >
                                        <AnimateHeight isVisible={!!workType}>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gap: '16px',
                                                    p: '40px 20px 0',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    [theme.breakpoints.up('sm')]: {
                                                        p: '40px 64px 0',
                                                        gridTemplateColumns: 'repeat(3, 1fr)',
                                                    }
                                                }}
                                            >
                                                {
                                                    workType === EWorkType.retainer && (
                                                        <Box sx={{
                                                            width: '100%',
                                                            gridColumn: 'span 2',
                                                            [theme.breakpoints.up('sm')]: {
                                                                gridColumn: 'span 3',
                                                            }
                                                        }}>
                                                            <InputCaption
                                                                sx={{
                                                                    textAlign: 'left !important',
                                                                    mb: '24px !important'
                                                                }}
                                                            >
                                                                Choose Retainer type
                                                            </InputCaption>
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    mb: '24px'
                                                                }}
                                                            >
                                                                <InputRetainerType
                                                                    name="isMonthly"
                                                                    onChange={handleRetainerTypeChange}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                                <Box
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <InputCaption
                                                        sx={{
                                                            textAlign: 'left !important',
                                                            mb: '16px !important'
                                                        }}
                                                    >
                                                        {workType === EWorkType.fixed && 'Precise budget'}
                                                        {workType === EWorkType.retainer && 'Budget'}
                                                        {workType === EWorkType.hourly && 'Precise rate'}
                                                    </InputCaption>

                                                    {workType === EWorkType.retainer ? (
                                                        <Input
                                                            disabled={isSubmitting}
                                                            isInteger={true}
                                                            name="rate"
                                                            type="number"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start"
                                                                    >
                                                                        $
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment
                                                                        position="end"
                                                                    >
                                                                        {isMonthly ? 'monthly' : 'weekly'}
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            sx={{
                                                                'input::placeholder, input::-webkit-input-placeholder': {
                                                                    color: theme.palette.textGray.dark + '!important',
                                                                },
                                                            }}
                                                        />
                                                    ) : (
                                                        <Input
                                                            disabled={isSubmitting}
                                                            isInteger={true}
                                                            name="rate"
                                                            type="number"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start"
                                                                    >
                                                                        $
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment
                                                                        position="end"
                                                                    >
                                                                        {workType === EWorkType.hourly ? 'hour' : ''}
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            sx={{
                                                                'input::placeholder, input::-webkit-input-placeholder': {
                                                                    color: theme.palette.textGray.dark + '!important',
                                                                },
                                                            }}
                                                        />
                                                    )}

                                                </Box>
                                                {
                                                    workType === EWorkType.hourly && (
                                                        <Box
                                                            sx={{
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <InputCaption
                                                                sx={{
                                                                    textAlign: 'left !important',
                                                                    mb: '16px !important'
                                                                }}
                                                            >
                                                                Availability
                                                            </InputCaption>

                                                            <Input
                                                                disabled={isSubmitting}
                                                                name="weeklyEngagementHours"
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment
                                                                            position="end"
                                                                            sx={{
                                                                                '.MuiTypography-root': {
                                                                                    fontSize: '14px',
                                                                                    color: 'tomato',
                                                                                }
                                                                            }}
                                                                        >
                                                                            hours/week
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                sx={{
                                                                    'input::placeholder, input::-webkit-input-placeholder': {
                                                                        color: theme.palette.textGray.dark + '!important',
                                                                    },
                                                                }}
                                                            />

                                                        </Box>
                                                    )
                                                }
                                                <Box
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <InputCaption
                                                        sx={{
                                                            textAlign: 'left !important',
                                                            mb: '16px !important'
                                                        }}
                                                    >
                                                        Start date
                                                    </InputCaption>

                                                    <InputDate
                                                        disabled={isSubmitting}
                                                        disablePast
                                                        name="startDate"
                                                    />
                                                </Box>

                                            </Box>
                                        </AnimateHeight>
                                    </Box>

                                    <Row>
                                        <Box>
                                            <InputCaption
                                                sx={{
                                                    mb: '8px !important',
                                                    textAlign: 'left !important',
                                                }}
                                            >
                                                Role description
                                            </InputCaption>
                                            <Text>{data.description}</Text>
                                        </Box>
                                    </Row>
                                </form>
                            </FormProvider>
                        ) : (
                            <ChatProposalSentView data={previewProposal}/>
                        )
                    }
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'center',
                        padding: '0 20px 40px',
                        [theme.breakpoints.up('md')]: {
                            padding: '40px 40px 65px'
                        }
                    }}
                >
                    {isNewProposal
                        ? (
                            <>
                                <BlackBorderButton
                                    fullWidth={mdDown}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </BlackBorderButton>
                                <ContinueButton
                                    fullWidth={mdDown}
                                    disabled={isSubmitting || isImpersonal}
                                    variant="contained"
                                    sx={{width: mdDown ? '100%' : 'auto !important'}}
                                    onClick={handleSubmit(onSubmitHandler)}
                                >
                                    Resume engagement
                                </ContinueButton>
                            </>
                        )
                        : (
                            isReadOnly
                                ? (
                                    <BlackBorderButton
                                        fullWidth={mdDown}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </BlackBorderButton>
                                )
                                : (
                                    <>
                                        <RedFilledButton
                                            disabled={isImpersonal}
                                            fullWidth={mdDown}
                                            onClick={() => {
                                                onClose(false);
                                                setOpenReject(true);
                                            }}
                                        >
                                            Reject
                                        </RedFilledButton>
                                        <ContinueButton
                                            fullWidth={mdDown}
                                            disabled={isSubmitting || isImpersonal}
                                            variant="contained"
                                            sx={{width: mdDown ? '100%' : 'auto !important'}}
                                            onClick={() => {
                                                onClose(false);
                                                setOpenAccept(true);
                                            }}
                                        >
                                            Accept
                                        </ContinueButton>
                                    </>
                                )
                        )
                    }

                </DialogActions>
            </Dialog>

            {/* Initiator created Resume request with new Proposal */}
            <DialogSuccess
                open={openCreated}
                subtitle={`Please wait until ${isClient ? 'freelancer' : 'client'} approves or rejects your request. Once it’s approved, you can keep the work going. If it’s rejected, you can negotiate the details in Inbox.`}
                title="Your resume engagement request has been successfully submitted"
                onClose={handleCreatedClose}
            />

            {/* Remote participant Accept new Proposal */}
            <DialogConfirm
                iconUrl="/assets/images/proposal-icon.png"
                labelBtn="Yes, resume"
                open={openAccept}
                title={<>
                    You are about to resume the engagement. Are you sure?
                </>}
                onClose={handleAccept}
            />

            {/* Remote participant Reject new Proposal */}
            <DialogConfirm
                error
                iconUrl="/assets/images/proposal-icon.png"
                labelBtn="Yes, reject"
                open={openReject}
                title={<p>
                    You are about to <span style={{color: theme.palette.error.main}}>reject</span> the engagement resume
                    request. Are you sure?
                </p>}
                onClose={handleReject}
            />

            {/* Show Success modal after Accept or Reject */}
            <DialogSuccess
                open={!!openSuccess}
                subtitle={
                    openSuccess === EResult.Accepted
                        ? 'We wish you two a productive cooperation!'
                        : `We will notify ${(isClient ? data.freelancerName : data.clientName).split(' ')[0]} about it.`
                }
                title={
                    openSuccess === EResult.Accepted
                        ? 'The engagement has been successfully resumed'
                        : 'The engagement resume request has been successfully rejected'
                }
                onClose={() => {
                    refetch();
                    setOpenSuccess(null);
                }}
            />


        </>
    );
};

export default React.memo(DialogProposal);
