import {styled} from '@mui/material';

import theme from '../../../../theme';

export const ChatOngoingCallBox = styled('div')`
    margin: 12px -6px 8px;
    padding: 16px 16px 16px 24px;
    background-color: rgba(18, 20, 22, 0.9);
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    gap: 12px;
    position: sticky;
    top: 0;
    z-index: 50;

    p {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: ${theme.palette.white.main};
    }
`;
