import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {SubmitHandler} from 'react-hook-form';

import {useMutation, useQuery} from '@tanstack/react-query';

import {freelancerService} from '../../api';
import {FREELANCER_PROFILE} from '../../constants';
import {useAuth} from '../../hooks';
import {IProfile} from '../../models';
import Switch from '../Forms/Switch';
import {showToastError, showToastSuccess} from '../ToastError/toast.service';

const AvailableForWorkSwitch = () => {
    const {isImpersonal} = useAuth();
    const [availableForNewProject, setAvailableForNewProject] = useState(false);
    const {
        data,
        isLoading,
        refetch,
    } = useQuery([FREELANCER_PROFILE], freelancerService.getProfile, {
        staleTime: 60 * 1000,
        select: ((data) => data)
    });

    const {mutate, isLoading: isSubmitted} = useMutation(
        freelancerService.submitProfile,
        {
            onSuccess() {
                showToastSuccess('Your availability status is successfully updated!');
                refetch();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const onSubmitHandler: SubmitHandler<Partial<IProfile>> = useCallback((values) => {
        if (!data) return;

        mutate({...data, ...values});
    }, [data, mutate]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAvailableForNewProject(e.target.checked);

        onSubmitHandler({[e.target.name]: e.target.checked});
    }, [onSubmitHandler]);

    useEffect(() => {
        if (!data) return;

        setAvailableForNewProject(data.isAvailableForNewProject);
    }, [data]);


    return (
        <Switch
            checked={availableForNewProject}
            disabled={isImpersonal}
            label={availableForNewProject ? 'Available for work' : 'Not available for work'}
            name="isAvailableForNewProject"
            onChange={handleChange}
            sx={{
                pointerEvents: (isLoading || isSubmitted) ? 'none' : 'auto'
            }}
        />
    );
};

export default React.memo(AvailableForWorkSwitch);
