import React from 'react';

const EnterIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9999 6C18.7347 6 18.4803 6.10536 18.2928 6.29289C18.1053 6.48043 17.9999 6.73478 17.9999 7V11C17.9999 11.2652 17.8945 11.5196 17.707 11.7071C17.5195 11.8946 17.2651 12 16.9999 12H7.4099L8.7099 10.71C8.8982 10.5217 9.00399 10.2663 9.00399 10C9.00399 9.7337 8.8982 9.4783 8.7099 9.29C8.52159 9.1017 8.2662 8.99591 7.9999 8.99591C7.73359 8.99591 7.4782 9.1017 7.2899 9.29L4.2899 12.29C4.19886 12.3851 4.12749 12.4972 4.0799 12.62C3.97988 12.8635 3.97988 13.1365 4.0799 13.38C4.12749 13.5028 4.19886 13.6149 4.2899 13.71L7.2899 16.71C7.38286 16.8037 7.49346 16.8781 7.61532 16.9289C7.73718 16.9797 7.86788 17.0058 7.9999 17.0058C8.13191 17.0058 8.26261 16.9797 8.38447 16.9289C8.50633 16.8781 8.61693 16.8037 8.7099 16.71C8.80363 16.617 8.87802 16.5064 8.92879 16.3846C8.97956 16.2627 9.00569 16.132 9.00569 16C9.00569 15.868 8.97956 15.7373 8.92879 15.6154C8.87802 15.4936 8.80363 15.383 8.7099 15.29L7.4099 14H16.9999C17.7955 14 18.5586 13.6839 19.1212 13.1213C19.6838 12.5587 19.9999 11.7956 19.9999 11V7C19.9999 6.73478 19.8945 6.48043 19.707 6.29289C19.5195 6.10536 19.2651 6 18.9999 6Z" fill="#999999"/>
        </svg>
    );
};

export default EnterIcon;
