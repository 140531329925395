import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import {Box, styled, Typography} from '@mui/material';

import {Logo} from '../../../../components';
import {MD, RouteAuth, RouteClientPortal, RouteFreelancerPortal} from '../../../../constants';
import {useAuth} from '../../../../hooks';
import theme from '../../../../theme';

interface IProps {
    children: React.ReactNode;
    title: string;
}

const Header = styled('header')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: ${theme.palette.white.main};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .logo {
    width: 102px;
    height: 30px;
  }
`;

const PageWrapper: React.FC<IProps> = ({children, title}) => {
    const {isClient, isFreelancer} = useAuth();

    const headerLink = useMemo(() => {
        if (isClient) {
            return RouteClientPortal.default;
        } else if (isFreelancer) {
            return RouteFreelancerPortal.default;
        } else {
            return RouteAuth.login;
        }
    }, [isFreelancer, isClient]);

    return (
        <>
            <Header>
                <Link
                    to={headerLink}
                >
                    <Logo color="black"/>
                </Link>
            </Header>
            <Box
                sx={{
                    maxWidth: '800px',
                    m: '0 auto',
                    pt: '40px',
                    pb: '102px',
                    [theme.breakpoints.up('md')]: {
                        pt: '126px'
                    },
                    'img': {
                        display: 'block',
                        m: '0 auto 24px',
                        width: '48px',
                        height: '48px',
                        [theme.breakpoints.up('md')]: {
                            width: '64px',
                            height: '64px'
                        }
                    }
                }}
            >
                <img src="/assets/images/proposal-icon.png" width="64" height="64" alt="document icon"/>
                <Typography
                    sx={{
                        textAlign: 'center',
                        mb: '24px',
                        fontWeight: '600 !important',
                        fontSize: '16px !important',
                        [theme.breakpoints.up('md')]: {
                            fontSize: '24px !important'
                        }
                    }}
                    variant="h1"
                >
                    {title}
                </Typography>
                {children}
            </Box>
        </>
    );
};

export default PageWrapper;

export const Text = styled('p')<{ bold?: boolean }>`
  margin-bottom: 16px;
  font-weight: ${props => props.bold ? 600 : 400};
  font-size: 14px;
  line-height: 165%;
  color: ${props => props.bold ? theme.palette.black.main : theme.palette.textGray.dark};

  a {
    font: inherit;
    color: currentColor;
    text-decoration: underline;
  }

  a[href^=mailto] {
    color: ${theme.palette.purple.main};
  }
`;

export const Title = styled('h2')`
  margin-top: 40px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${theme.palette.black.main};

  @media (min-width: ${MD}) {
    font-size: 24px;
  }
`;

export const Subtitle = styled('h3')`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.palette.black.main};
`;
