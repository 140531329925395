import React from 'react';

const ActiveWorkStatusIcon = () => {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M8.61652 12.2482C8.25613 12.3248 7.8824 12.365 7.49954 12.365C4.53466 12.365 2.1278 9.94743 2.1278 6.9694C2.1278 6.51846 1.76317 6.15189 1.3139 6.15189C0.864627 6.15189 0.5 6.51846 0.5 6.9694C0.5 10.85 3.63644 14 7.49954 14C7.99797 14 8.48436 13.9477 8.95349 13.8479C9.393 13.7544 9.67427 13.3202 9.58116 12.8787C9.48838 12.4372 9.05603 12.1547 8.61652 12.2482ZM8.25647 1.62712C10.8636 1.99663 12.8713 4.2497 12.8713 6.9694C12.8713 8.89349 11.8663 10.5838 10.3553 11.5393C9.97509 11.7796 9.86083 12.2849 10.1004 12.6665C10.3397 13.0484 10.8427 13.1632 11.2226 12.9228C13.1906 11.6779 14.4991 9.47589 14.4991 6.9694C14.4991 3.42499 11.8816 0.489799 8.48403 0.00812099C8.03931 -0.0549909 7.62715 0.256637 7.56432 0.703326C7.50148 1.15001 7.81175 1.56401 8.25647 1.62712ZM2.51424 4.95701C3.08983 3.52179 4.2625 2.39133 5.72231 1.87662C6.14619 1.72718 6.36919 1.26021 6.22041 0.834455C6.07163 0.408694 5.60674 0.184377 5.18318 0.333818C3.28159 1.00418 1.75439 2.47668 1.0043 4.34616C0.836633 4.76473 1.03848 5.2415 1.4552 5.40991C1.87192 5.57864 2.34658 5.37558 2.51424 4.95701Z"
                fill="#67DD42"/>
        </svg>
    );
};

export default ActiveWorkStatusIcon;
