export const COVER_IMAGES = [{
    name: 'black',
    value: '#000000',
}, {
    name: 'white',
    value: '#f5f5f5',
}, {
    name: 'red',
    value: '#F9554B',
}, {
    name: 'orange',
    value: '#E99838',
}, {
    name: 'yellow',
    value: '#F3D539',
}, {
    name: 'green',
    value: '#7AC61A',
}, {
    name: 'blue',
    value: '#3894E9',
}, {
    name: 'purple',
    value: '#AB69ED',
}, {
    name: 'pink',
    value: '#FFBAEC',
}];
