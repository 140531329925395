import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';

import {Box, Grid, Typography} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';

import {applicationService, metaService} from '../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    BusinessModelsList,
    ChipsList,
    ContinueButton,
    InputCaption,
    LoadingMask,
    PageTopTitle,
    Portal,
    SelectMulti,
    showToastError,
    showToastSuccess,
} from '../../../components';
import {GET_BUSINESS_MODELS, GET_VERTICALS, RouteCreateApplicantAccount} from '../../../constants';
import {useApplication, useNavigateUTM} from '../../../hooks';
import {IBrand, IVertical, IVerticalsBrandsRequest, IVerticalsResponse, OptionType} from '../../../models';
import theme from '../../../theme';

import {Brands} from './components';

const VerticalsAndBrands = () => {
    const {application, isApplicationLoading, refetchApplication} = useApplication();
    const [businessModels, setBusinessModels] = useState<string[]>([]);
    const [verticals, setVerticals] = useState<IVertical[]>([]);
    const [brands, setBrands] = useState<IBrand[]>([]);

    const navigate = useNavigateUTM();

    const {
        isLoading,
        isFetching,
        data: verticalsAndRecommendedData
    } = useQuery([GET_VERTICALS], metaService.getVerticals, {
        staleTime: 15 * 60 * 1000,
        select: ((response: IVerticalsResponse) => ({
            recommended: uniqBy(response.recommended, 'name'),
            verticals: uniqBy(response.verticals, 'name')
        }))
    });

    const verticalsData = verticalsAndRecommendedData?.verticals || [];
    const verticalsRecommendedData = verticalsAndRecommendedData?.recommended || [];

    const {
        data: businessModelsData
    } = useQuery([GET_BUSINESS_MODELS], metaService.getBusinessModels, {
        staleTime: 15 * 60 * 1000,
        select: (response: { businessModels: string[] }) => response.businessModels,
    });

    const handleClickBusinessModel = useCallback((model: string) => {
        const result = businessModels?.filter(str => str !== model);

        // unselect by second click
        if (businessModels && result.length !== businessModels?.length) {
            setBusinessModels(result);
        } else if (businessModels) {
            setBusinessModels([...businessModels, model]);
        } else {
            setBusinessModels([model]);
        }
    }, [businessModels]);

    const {mutate, isLoading: isSubmitting} = useMutation(applicationService.verticalsBrandsSubmit,
        {
            onSuccess() {
                showToastSuccess('Verticals & Brands were saved successfully!');
                navigate(RouteCreateApplicantAccount.workTypes);
                refetchApplication();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleChangeBrand = useCallback((newValue: OptionType | null) => {
        const isAlreadyExists = brands.find(item => item.name.toLowerCase() === newValue?.name.toLowerCase());

        if (newValue?.name && !isAlreadyExists) {
            setBrands([...brands, newValue as IBrand]);
        }
    }, [brands]);

    const handleChangeVertical = useCallback((newValue: OptionType | null) => {
        const isAlreadyExists = verticals.find(item => item.name === newValue?.name);

        if (newValue?.name && !isAlreadyExists) {
            setVerticals([...verticals, newValue as IVertical]);
        }
    }, [verticals]);

    const handleDeleteVertical = useCallback((option: OptionType) => {
        const newVerticals = verticals.filter(v => v !== option);

        setVerticals(newVerticals);
    }, [verticals]);

    const handleAddVertical = useCallback((option: OptionType) => {
        const isAlreadyExists = verticals.find(item => item.id === option.id);

        if (!isAlreadyExists) {
            setVerticals([...verticals, option as IVertical]);
        }
    }, [verticals]);

    const handleDeleteBrand = useCallback((option: OptionType) => {
        const newBrands = brands.filter(brand => option.id ? brand.id !== option.id : option.name.toLowerCase() !== brand.name.toLowerCase());

        setBrands(newBrands);
    }, [brands]);

    const handleSubmit = () => {
        const isDirty = !isEqual(verticals, application?.verticals)
            || !isEqual(brands, application?.brands)
            || !isEqual(businessModels, application?.businessModels);

        if (!verticals?.length) {
            showToastError('Please add a Vertical.');
        } else if (!brands?.length) {
            showToastError('Please add a Brand.');
        } else if (!businessModels?.length) {
            showToastError('Please select Business Model.');
        } else if (isDirty) {
            const payload: IVerticalsBrandsRequest = {
                verticals: verticals,
                brands: brands,
                businessModels: businessModels
            };

            mutate(payload);
        } else {
            navigate(RouteCreateApplicantAccount.workTypes);
        }
    };

    useEffect(() => {
        if (!application) {
            return;
        }

        const {brands, businessModels, verticals} = application;

        setBrands(brands);
        setBusinessModels(businessModels);
        setVerticals(verticals);
    }, [application]);

    return (
        <AnimatePageWrp maxWidth={{md: 600, lg: 621}}>
            <PageTopTitle
                title={<>Now tell us what <span>verticals & brands</span> you have worked with</>}
                titleMaxWidth={{md: 418, lg: 621}}
            />

            {
                isApplicationLoading ? (
                    <LoadingMask/>
                ) : (
                    <Grid
                        container
                        maxWidth={611}
                        spacing={2}
                        rowSpacing={0}
                        sx={{
                            pb: '120px',
                            m: '0 auto',
                            [theme.breakpoints.up('sm')]: {
                                pb: 0,
                                mt: '-8px'
                            },
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: '40px',
                                [theme.breakpoints.up('lg')]: {
                                    mb: '56px'
                                }
                            }}
                        >
                            <SelectMulti
                                label={(
                                    <InputCaption>
                                        First, select the <span>verticals</span> you have worked in
                                    </InputCaption>
                                )}
                                options={verticalsData}
                                placeholder="Ex. Health & Wellness, Campaigns, SaaS"
                                selected={verticals}
                                onChange={handleChangeVertical}
                                onDelete={handleDeleteVertical}
                            />
                            <Typography
                                sx={{
                                    display: 'inline-block',
                                    marginTop: '24px',
                                    marginBottom: '12px',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: theme.palette.gray.main
                                }}
                                variant="caption"
                            >
                                Suggested verticals
                            </Typography>
                            <AnimateHeight isVisible={true}>
                                <ChipsList
                                    items={verticalsRecommendedData || []}
                                    selectedVerticals={verticals}
                                    onAdd={handleAddVertical}
                                />
                            </AnimateHeight>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: '40px',
                                [theme.breakpoints.up('lg')]: {
                                    mb: '56px'
                                }
                            }}
                        >
                            <Brands
                                placeholder="Select brands"
                                selected={brands}
                                onChange={handleChangeBrand}
                                onDelete={handleDeleteBrand}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{mb: 2}}>
                            <InputCaption>
                                And, please select the <span>business models</span> you’ve worked with
                            </InputCaption>

                            <BusinessModelsList
                                items={businessModelsData || []}
                                selectedIds={businessModels}
                                onClick={handleClickBusinessModel}
                            />
                        </Grid>
                    </Grid>
                )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '50px'
                    },
                    [theme.breakpoints.up('lg')]: {
                        mt: '56px'
                    },
                    '@media(max-height: 1200px)': {
                        pb: '80px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isApplicationLoading || isLoading || isFetching || isSubmitting}
                    hasArrow
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isApplicationLoading || isLoading || isFetching || isSubmitting}
                    hasArrow
                    variant="contained"
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>

        </AnimatePageWrp>
    );
};

export default React.memo(VerticalsAndBrands);
