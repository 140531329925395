import React from 'react';

const SearchIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.9249 16.9085L15.8332 13.8418C17.0333 12.3455 17.6145 10.4462 17.4572 8.53458C17.3 6.62292 16.4163 4.84415 14.9878 3.56403C13.5594 2.28392 11.6947 1.59976 9.77734 1.65222C7.85993 1.70469 6.0355 2.4898 4.67918 3.84612C3.32287 5.20243 2.53776 7.02686 2.48529 8.94427C2.43283 10.8617 3.11699 12.7263 4.3971 14.1548C5.67722 15.5832 7.45598 16.4669 9.36765 16.6242C11.2793 16.7814 13.1786 16.2002 14.6749 15.0002L17.7416 18.0668C17.819 18.1449 17.9112 18.2069 18.0128 18.2492C18.1143 18.2915 18.2232 18.3133 18.3332 18.3133C18.4432 18.3133 18.5522 18.2915 18.6537 18.2492C18.7553 18.2069 18.8474 18.1449 18.9249 18.0668C19.0751 17.9114 19.1591 17.7038 19.1591 17.4877C19.1591 17.2716 19.0751 17.0639 18.9249 16.9085ZM9.9999 15.0002C8.84618 15.0002 7.71836 14.658 6.75907 14.0171C5.79979 13.3761 5.05211 12.4651 4.6106 11.3992C4.16909 10.3333 4.05357 9.16036 4.27865 8.0288C4.50373 6.89725 5.0593 5.85785 5.87511 5.04204C6.69092 4.22624 7.73032 3.67066 8.86187 3.44558C9.99343 3.2205 11.1663 3.33602 12.2322 3.77753C13.2981 4.21904 14.2092 4.96672 14.8501 5.926C15.4911 6.88529 15.8332 8.01311 15.8332 9.16683C15.8332 10.7139 15.2187 12.1977 14.1247 13.2916C13.0307 14.3856 11.547 15.0002 9.9999 15.0002Z"
                fill="#999999"/>
        </svg>
    );
};

export default SearchIcon;
