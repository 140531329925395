import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsFrelancer, EngagementInfoBox} from '../../../../../../../components';
import {EEngagementStatus, EResumeStatus, IEngagementFreelancer} from '../../../../../../../models';

type ResumeRequestProps = {
    data: IEngagementFreelancer;
};

const ResumeRequest: React.FC<ResumeRequestProps> = ({data}) => {
    if (data.status !== EEngagementStatus.PAUSED || data.resume?.resumeStatus !== EResumeStatus.CLIENT_REQUESTED) {
        return null;
    }

    const clientName = data.clientName.split(' ')[0];

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/proposal-icon.png"
                title={`Resume engagement request from ${clientName}`}
            >
                {clientName} has sent you a resume engagement request.
                Please review it and decide if you want to continue cooperation.

                <Box
                    sx={{
                        mt: '16px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <EngagementActionsFrelancer.ViewProposalBtn data={data}/>
                </Box>

            </EngagementInfoBox>

        </>
    );
};

export default React.memo(ResumeRequest);
