import React from 'react';

import {Box, Typography} from '@mui/material';

import theme from '../../theme';

type StepIndicatorProps = {
    total: number;
    value: number;
};

const StepIndicator: React.FC<StepIndicatorProps> = ({total, value}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {/* // it is can be bigger than 6, skipped ThankYou and ConfirmCode pages */}
            <Typography
                sx={{
                    padding: '8px 16px',
                    borderRadius: '50px',
                    backgroundColor: theme.palette.green.dark,
                    color: theme.palette.black.main,
                    fontWeight: 500,
                    fontSize: '13px',
                    lineHeight: '20px'
                }}
            >
                {value}/{total}
            </Typography>
        </Box>
    );
};

export default React.memo(StepIndicator);
