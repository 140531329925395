import React, {useCallback, useState} from 'react';
import {AxiosError} from 'axios';

import {Box, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../api';
import {ContinueButton, DialogConfirm, DialogEmoji, DialogSuccess, RedFilledButton, showToastError} from '../../components';
import {CLIENT_ENGAGEMENT, FREELANCER_ENGAGEMENT} from '../../constants';
import {useAuth} from '../../hooks';
import {IEngagement} from '../../models';
import theme from '../../theme';

enum EReasons {
    Accept = 'Accept',
    Reject = 'Reject',
}

type DialogCloseRequestProps = {
    data: IEngagement;
    open: boolean;
    onClose: (isNeedUpdate: boolean) => void;
};

const DialogCloseRequest: React.FC<DialogCloseRequestProps> = ({data, open, onClose}) => {
    const {isClient} = useAuth();
    const [openConfirm, setOpenConfirm] = useState<EReasons | null>(null);
    const [openSuccess, setOpenSuccess] = useState<EReasons | null>(null);

    const apiService = isClient ? clientService : freelancerService;
    const queryClient = useQueryClient();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const handleSuccessClose = useCallback((isNeedUpdate: boolean) => {
        setOpenSuccess(null);
        queryClient.invalidateQueries({queryKey: [CLIENT_ENGAGEMENT]});     // also refetch MyHires list
        queryClient.invalidateQueries({queryKey: [FREELANCER_ENGAGEMENT]});
    }, [queryClient]);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (reason: EReasons) => reason === EReasons.Accept ? apiService.closureConfirm(data.id) : apiService.closureReject(data.id),
        {
            onSuccess() {
                setOpenConfirm(null);

                // play modal show/hide animation
                setTimeout(() => {
                    setOpenSuccess(openConfirm);
                }, 500);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleRejectClick = useCallback(() => {
        onClose(false);
        // play modal show/hide animation
        setTimeout(() => {
            setOpenConfirm(EReasons.Reject);
        }, 500);
    }, [onClose]);

    const handleConfirmClick = useCallback(() => {
        onClose(false);
        // play modal show/hide animation
        setTimeout(() => {
            setOpenConfirm(EReasons.Accept);
        }, 500);
    }, [onClose]);

    const handleAccept = useCallback((reason: boolean) => {
        if (reason) {
            mutate(EReasons.Accept);
        } else {
            setOpenConfirm(null);
        }
    }, [mutate]);

    const handleReject = useCallback((reason: boolean) => {
        if (reason) {
            mutate(EReasons.Reject);
        } else {
            setOpenConfirm(null);
        }
    }, [mutate]);

    return (
        <>
            <DialogEmoji
                actions={(
                    <>
                        <RedFilledButton
                            disabled={isSubmitting}
                            sx={{
                                width: mdDown ? '50%' : 'auto'
                            }}
                            onClick={handleRejectClick}
                        >
                            Reject
                        </RedFilledButton>
                        <ContinueButton
                            disabled={isSubmitting}
                            variant="contained"
                            sx={{
                                width: mdDown ? '50% !important' : 'auto !important',
                                'div': {
                                    justifyContent: 'center'
                                }
                            }}
                            onClick={handleConfirmClick}
                        >
                            Confirm
                        </ContinueButton>
                    </>
                )}
                maxWidth="sm"
                open={open}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img src="/assets/images/pit-stop-icon.png" width="48" height="48" alt="ok icon"/>
                        </Box>
                        {(isClient ? data.freelancerName : data.clientName).split(' ')[0]} proposed to close the engagement.
                        Do you agree with it?
                        <Typography align="center" variant="caption">
                        Please notice that this action cannot be undone. Make sure all the tasks and commitments are finished before confirming.
                        </Typography>
                    </>
                )}
                onClose={() => onClose(false)}
            >
            </DialogEmoji>

            {/* confirm Accept */}
            <DialogConfirm
                iconUrl="/assets/images/pit-stop-icon.png"
                labelBtn="Yes, close"
                open={openConfirm === EReasons.Accept}
                title={<>
                    You are about to close the engagement. Are you sure?
                </>}
                onClose={handleAccept}
            />

            {/* confirm Reject */}
            <DialogConfirm
                error
                iconUrl="/assets/images/pit-stop-icon.png"
                labelBtn="Yes, reject"
                open={openConfirm === EReasons.Reject}
                title={<p>
                    You are about to <span style={{color: theme.palette.error.main}}>reject</span> the closure of the engagement. Are you sure?
                </p>}
                onClose={handleReject}
            />

            {/* Show Success modal after Accept or Reject */}
            <DialogSuccess
                open={!!openSuccess}
                subtitle={
                    openSuccess === EReasons.Accept
                        ? 'We hope it was a pleasant working experience for both sides. Good luck on your next projects!'
                        : 'We hope wish you two a productive cooperation!'
                }
                title={
                    openSuccess === EReasons.Accept
                        ? 'The engagement has been successfully closed.'
                        : 'The engagement closure request has been rejected'
                }
                onClose={handleSuccessClose}
            />

        </>
    );
};

export default React.memo(DialogCloseRequest);
