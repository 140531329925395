import React from 'react';

const CheckCircleIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#7E5FFF"/>
            <path d="M21.6793 11.2604C21.5867 11.1779 21.4765 11.1124 21.3551 11.0677C21.2337 11.023 21.1035 11 20.972 11C20.8405 11 20.7103 11.023 20.5889 11.0677C20.4675 11.1124 20.3573 11.1779 20.2647 11.2604L12.8433 17.8289L9.72529 15.0642C9.62913 14.9821 9.51563 14.9175 9.39125 14.8742C9.26688 14.8309 9.13406 14.8096 9.0004 14.8117C8.86673 14.8137 8.73483 14.839 8.61222 14.8861C8.48961 14.9332 8.3787 15.0012 8.28582 15.0862C8.19294 15.1712 8.11991 15.2715 8.07089 15.3814C8.02188 15.4913 7.99784 15.6087 8.00015 15.7269C8.00246 15.845 8.03108 15.9616 8.08437 16.07C8.13766 16.1784 8.21458 16.2764 8.31073 16.3585L12.136 19.7396C12.2286 19.8221 12.3388 19.8876 12.4602 19.9323C12.5816 19.977 12.7118 20 12.8433 20C12.9748 20 13.105 19.977 13.2264 19.9323C13.3478 19.8876 13.458 19.8221 13.5506 19.7396L21.6793 12.5548C21.7804 12.4723 21.8611 12.3722 21.9163 12.2609C21.9715 12.1495 22 12.0292 22 11.9076C22 11.786 21.9715 11.6657 21.9163 11.5543C21.8611 11.443 21.7804 11.3429 21.6793 11.2604V11.2604Z" fill="white"/>
        </svg>
    );
};

export default CheckCircleIcon;
