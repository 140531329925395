import React from 'react';

const PhoneIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3427_6579)">
                <path
                    d="M15.027 12.2666C14.0202 11.4055 12.9984 10.8838 12.0039 11.7437L11.4101 12.2633C10.9756 12.6406 10.1678 14.4032 7.04455 10.8103C3.92195 7.22205 5.78016 6.66335 6.21528 6.28936L6.81236 5.76904C7.80163 4.90725 7.42829 3.82237 6.7148 2.70562L6.28423 2.0292C5.56748 0.915054 4.78699 0.183347 3.79512 1.04383L3.25919 1.51213C2.82081 1.83148 1.59545 2.86953 1.29821 4.84156C0.940487 7.20774 2.06894 9.91733 4.65431 12.8903C7.23642 15.8646 9.76455 17.3586 12.1593 17.3326C14.1496 17.3112 15.3515 16.2432 15.7275 15.8549L16.2654 15.3859C17.2546 14.5261 16.64 13.6507 15.6325 12.7876L15.027 12.2666Z"
                    fill="#3C94F7"/>
            </g>
            <defs>
                <clipPath id="clip0_3427_6579">
                    <rect width="16.6667" height="16.6667" fill="white" transform="translate(0.666672 0.666504)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default PhoneIcon;
