import React from 'react';

const PlusBiggerIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.125 11.125H12.875V5.875C12.875 5.64294 12.7828 5.42038 12.6187 5.25628C12.4546 5.09219 12.2321 5 12 5C11.7679 5 11.5454 5.09219 11.3813 5.25628C11.2172 5.42038 11.125 5.64294 11.125 5.875V11.125H5.875C5.64294 11.125 5.42038 11.2172 5.25628 11.3813C5.09219 11.5454 5 11.7679 5 12C5 12.2321 5.09219 12.4546 5.25628 12.6187C5.42038 12.7828 5.64294 12.875 5.875 12.875H11.125V18.125C11.125 18.3571 11.2172 18.5796 11.3813 18.7437C11.5454 18.9078 11.7679 19 12 19C12.2321 19 12.4546 18.9078 12.6187 18.7437C12.7828 18.5796 12.875 18.3571 12.875 18.125V12.875H18.125C18.3571 12.875 18.5796 12.7828 18.7437 12.6187C18.9078 12.4546 19 12.2321 19 12C19 11.7679 18.9078 11.5454 18.7437 11.3813C18.5796 11.2172 18.3571 11.125 18.125 11.125Z"
                fill="#121416"/>
        </svg>
    );
};

export default PlusBiggerIcon;
