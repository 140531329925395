import React, {FC, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {styled} from '@mui/material';

import {DETAILS, MD, RouteFreelancerPortal} from '../../../../constants';
import {IFreelancerMatchList} from '../../../../models';

import {MatchItem} from './';

const ListWrp = styled('div')`
  display: flex;
  gap: 24px;
  width: 100%;

  @media (min-width: ${MD}) {
    margin-top: 24px;
  }
`;

const ItemList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-width: 0;
  transition: width .3s;
`;

interface IMatchList {
    isEmpty: boolean;
    items: IFreelancerMatchList[];
}

const MatchList: FC<IMatchList> = ({isEmpty, items}) => {
    const navigate = useNavigate();

    const handleItemClick = useCallback((matchId: number) => {
        navigate(`${RouteFreelancerPortal.myMatches}/${DETAILS}/${matchId}`);
    }, [navigate]);

    return (
        <ListWrp>
            <ItemList>
                {isEmpty && 'No Records found'}
                {items?.map((item: IFreelancerMatchList) => <MatchItem key={item.id} item={item}
                    onClick={handleItemClick}/>)}
            </ItemList>
        </ListWrp>
    );
};

export default React.memo(MatchList);
