import React, {FC} from 'react';

import {styled, Typography} from '@mui/material';

import {MD} from '../../constants';
import theme from '../../theme';

interface IMaxWidthValues {
    md?: number;
    lg?: number;
}

interface IPageTopTitle {
    desktopFontSize?: number;
    desktopMarginBottom?: number;
    title: string | React.ReactElement;
    titleMaxWidth?: IMaxWidthValues;
    subtitle?: string | React.ReactElement;
    subtitleMaxWidth?: IMaxWidthValues;
}

const Wrp = styled('div')<{desktopMarginBottom: number}>`
  margin-bottom: 40px;

  @media (min-width: ${MD}) {
    margin-bottom: ${props => props.desktopMarginBottom}px;
  }
`;

const PageTopTitle: FC<IPageTopTitle> = ({
    desktopFontSize,
    desktopMarginBottom,
    title,
    subtitle,
    titleMaxWidth,
    subtitleMaxWidth
}: IPageTopTitle): JSX.Element => {
    return (
        <Wrp desktopMarginBottom={desktopMarginBottom || 64}>
            <Typography
                sx={{
                    margin: '0 auto',
                    mb: subtitle ? '8px' : 0,
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '150%',
                    maxWidth: '277px',
                    'span': {
                        font: 'inherit',
                        lineHeight: 'inherit',
                        color: '#7E5FFF'
                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: '24px',
                        maxWidth: titleMaxWidth?.md ? `${titleMaxWidth.md}px` : '440px',
                    },
                    [theme.breakpoints.up('xl')]: {
                        fontSize: desktopFontSize ? `${desktopFontSize}px` : '40px',
                        maxWidth: titleMaxWidth?.lg ? `${titleMaxWidth?.lg}px` : 'unset',
                    },
                }}
                textAlign="center"
                variant="h5"
            >
                {title}
            </Typography>
            {
                subtitle && <Typography
                    sx={{
                        maxWidth: subtitleMaxWidth?.md ? `${subtitleMaxWidth.md}px` : 'unset',
                        margin: '0 auto',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main,
                        textAlign: 'center',
                        [theme.breakpoints.up('md')]: {
                            maxWidth: subtitleMaxWidth?.md ? `${subtitleMaxWidth.md}px` : 'unset'
                        },
                        [theme.breakpoints.up('xl')]: {
                            maxWidth: subtitleMaxWidth?.lg ? `${subtitleMaxWidth.lg}px` : 'unset'
                        },
                        'svg': {
                            display: 'inline-block',
                            width: '16px',
                            height: '16px',
                            marginLeft: '6px',
                            verticalAlign: 'text-top',
                        }
                    }}
                    textAlign="center"
                    variant="body2"
                >
                    {subtitle}
                </Typography>
            }
        </Wrp>
    );
};

export default PageTopTitle;
