import React, {useCallback, useMemo, useState} from 'react';

import {Box, SxProps, Theme, useMediaQuery} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {DialogSuccess, RedButton} from '../../../../../../../components';
import {FREELANCER_ENGAGEMENT} from '../../../../../../../constants';
import {useAuth} from '../../../../../../../hooks';
import {EClosureStatus, EEngagementStatus, IEngagementFreelancer} from '../../../../../../../models';
import theme from '../../../../../../../theme';

import DialogConfirmRequest from './DialogConfirmRequest';

interface CloseEngagmentBtnProps {
    data: IEngagementFreelancer;
    sx?: SxProps;
}

const CloseEngagmentBtn: React.FC<CloseEngagmentBtnProps> = ({data, ...props}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const handleRejectClose = useCallback((isNeedUpdate: boolean) => {
        setOpen(false);
        // play modal show/hide animation
        setTimeout(() => {
            setOpenSuccess(isNeedUpdate);
        }, 500);
    }, []);

    const handleSuccessClose = useCallback(() => {
        setOpenSuccess(false);
        queryClient.invalidateQueries([FREELANCER_ENGAGEMENT, data.id]);
    }, [data.id, queryClient]);

    const sx = useMemo(() => {
        return lgUp ? {
            ...props.sx,
            mt: '24px',
            p: '24px',
            backgroundColor: theme.palette.white.main,
            borderRadius: '24px'
        } : {
            ...props.sx,
            mt: '40px',
            // pb: '158px',
            [theme.breakpoints.up('md')]: {
                pb: '48px',
            }
        };
    }, [lgUp, props]);

    if (
        data.status === EEngagementStatus.CLOSED || [
            EClosureStatus.CLIENT_ACCEPTED,
            EClosureStatus.CLIENT_REQUESTED,
            EClosureStatus.FREELANCER_ACCEPTED,
            EClosureStatus.FREELANCER_REQUESTED,
        ].includes(data.closureStatus as EClosureStatus)
    ) {
        return null;
    }

    return (
        <>
            <Box sx={sx}>
                <RedButton
                    disabled={isImpersonal}
                    fullWidth
                    size="small"
                    sx={{
                        backgroundColor: theme.palette.lightRed.main,
                        '&:hover': {
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.white.main
                        }
                    }}
                    onClick={() => setOpen(true)}
                >
                    Close engagement
                </RedButton>
            </Box>

            <DialogConfirmRequest data={data} open={open} onClose={handleRejectClose}/>

            <DialogSuccess
                open={openSuccess}
                subtitle="We will notify you when the client approves it."
                title="Your close engagement request has been successfully submitted"
                onClose={handleSuccessClose}
            />
        </>
    );
};

export default CloseEngagmentBtn;
