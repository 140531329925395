export interface IEmailVerificationProcess {
    id: number;
    status: EVerificationStatus;
    newEmail: string;
}

export enum EVerificationStatus {
    STARTED = 'STARTED',
    EMAIL_SENT = 'EMAIL_SENT',
    CONFIRMED = 'CONFIRMED',
    CODE_EXPIRED = 'CODE_EXPIRED',
}