import React, {FC, useCallback, useState} from 'react';

import {Box, Theme, useMediaQuery} from '@mui/material';

import {freelancerService} from '../../../../../api';
import {BlackBorderButton, Button, DialogEmoji, RedFilledButton, showToastError} from '../../../../../components';
import {useAuth} from '../../../../../hooks';

interface IProps {
    matchId: number;
    onReject: () => void;
}

const MyMatchesModalReject: FC<IProps> = ({matchId, onReject}) => {
    const {isImpersonal} = useAuth();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleReject = useCallback(async () => {
        setLoading(true);
        try {
            await freelancerService.rejectMatch(matchId);
            onReject();
        } catch (error: any) {
            showToastError(error);
        }
        setLoading(false);
    }, [matchId, onReject]);


    return (
        <>
            <Button
                color="error"
                disabled={isImpersonal}
                sx={{flexShrink: 0}}
                variant="text"
                onClick={() => setOpen(true)}
            >
                Reject match
            </Button>

            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            fullWidth={mdDown}
                            disabled={loading}
                            onClick={handleClose}
                        >
                            Cancel
                        </BlackBorderButton>

                        <RedFilledButton
                            fullWidth={mdDown}
                            disabled={loading}
                            variant="contained"
                            sx={{width: mdDown ? '100%' : 'auto !important'}}
                            onClick={handleReject}
                        >
                            Reject
                        </RedFilledButton>
                    </>
                )}
                maxWidth="sm"
                open={open}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img src="/assets/images/sad-emoji.png" width="48" height="48" alt="sad icon"/>
                        </Box>
                        Are you sure you want to reject the match?
                    </>
                )}
                onClose={handleClose}
            >
            </DialogEmoji>

        </>
    );
};

export default React.memo(MyMatchesModalReject);
