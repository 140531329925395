import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import {Box} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';

import {clientService, metaService} from '../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    ClientTopTitleTools,
    ContinueButton,
    LoadingMask,
    NewToolAlertBox,
    Portal,
    SelectMulti,
    showToastError,
    showToastSuccess,
} from '../../../components';
import {GET_TOOLS, RouteCreateClientAccount} from '../../../constants';
import {useClientRole, useNavigateUTM} from '../../../hooks';
import {EModerationStatus, ITool} from '../../../models';
import theme from '../../../theme';

interface OptionType extends ITool {
    inputValue?: string;
}

export const Tools = () => {
    const {clientRole, isClientLoading, refetchClient} = useClientRole();
    const [tools, setTools] = useState<ITool[]>([]);

    const navigate = useNavigateUTM();

    const {
        isLoading: isLoadingData,
        data: toolsData
    } = useQuery([GET_TOOLS], () => metaService.searchTools(''), {
        staleTime: 60 * 60 * 1000,
        select: (response => orderBy(response, 'name'))
    });

    const {mutate, isLoading: isSubmitting} = useMutation(
        (tools: ITool[]) => clientService.toolsSubmit(clientRole?.id || 0, {tools}),
        {
            onSuccess() {
                showToastSuccess('Tools were saved successfully!');
                navigate(RouteCreateClientAccount.location);
                refetchClient();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(debounce((newValue: OptionType | null) => {
        if (!newValue) {
            return;
        }

        // don't add duplicate
        if (!tools.find(tool => tool.name.toLowerCase() === newValue.name.toLowerCase())) {
            setTools(tools => [...tools, newValue]);
        }
    }, 100), [tools]);

    const handleDelete = useCallback((tool: ITool) => {
        const newTools = tools.filter(t => t.name !== tool.name);

        setTools(newTools);
    }, [tools]);

    const handleSubmit = () => {
        // this step is optional so submit en empty array to move on to the next step
        if (!isEqual(tools, clientRole?.tools) || clientRole?.view === 'verticals') {
            mutate(tools);
        } else {
            navigate(RouteCreateClientAccount.location);
        }
    };

    useEffect(() => {
        if (!clientRole) {
            return;
        }
        setTools(clientRole.tools);
    }, [clientRole]);

    const isLoading = isLoadingData || isSubmitting;
    const isNewToolExist = useMemo(() => !!tools?.some(tool => !tool.id || (tool.moderationStatus && tool.moderationStatus === EModerationStatus.NEW)), [tools]);

    return (
        <AnimatePageWrp maxWidth={{md: 600}}>
            <ClientTopTitleTools/>

            {
                isClientLoading
                    ? (
                        <LoadingMask/>
                    ) : (
                        <SelectMulti
                            freeSolo
                            options={toolsData || []}
                            placeholder="Ex. HubSpot, MailChimp, ActiveCampaign"
                            pressCaption="tool"
                            selected={tools}
                            onChange={handleChange}
                            onDelete={handleDelete}
                        />
                    )
            }

            <AnimateHeight isVisible={isNewToolExist} isOverflowHidden={false}>
                <NewToolAlertBox/>
            </AnimateHeight>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '57px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isClientLoading || isLoading}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isClientLoading || isLoading}
                    hasArrow
                    variant="contained"
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};
