import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import {useNavigate} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, Link, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {userService} from '../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    AnimatePageWrpFadeIn,
    AsideCaption,
    AsideLetsConnect,
    Checkbox,
    ContinueButton,
    darkBgInputStyles,
    Input,
    InputPhone,
    LoginLink,
    showToastError,
    showToastSuccess,
    TermsAndPrivacyLabel
} from '../../../components';
import {RouteAuth, RouteCreateApplicantAccount, RouteCreateClientAccount} from '../../../constants';
import {useAuth} from '../../../hooks';
import theme from '../../../theme';
import {findFieldError, registerSchemaApplicant, replaceSymbolsInPhone, utm} from '../../../utils';

export type RegisterInput = TypeOf<typeof registerSchemaApplicant>;

const SignUpApplicantForm = () => {
    const {login, logout} = useAuth();
    const navigate = useNavigate();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const methods = useForm<RegisterInput>({
        resolver: zodResolver(registerSchemaApplicant),
    });

    // Calling the Register Mutation
    const {mutate, isLoading} = useMutation(
        ({passwordConfirm, termsAgree, ...userData}: RegisterInput) => {
            const updatedPhone = replaceSymbolsInPhone(userData.phone);

            return userService.signUpApplicant({...userData, phone: updatedPhone});
        },
        {
            // Create new account
            onSuccess() {
                showToastSuccess('Account was successfully created!');

                loginSilently();
            },
            onError(error: AxiosError) {
                const email = findFieldError(error, 'email');
                const phone = findFieldError(error, 'phone');

                // This email used in the different Portal
                // already taken at this Portal - user can try to login
                if (email || error?.response?.status === 409) {
                    showToastError('this email is taken, try to login');
                    setError('emailTaken' as any, {type: 'custom', message: 'redirect to login'});
                } else if (phone) {
                    showToastError('Phone number is invalid');
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const {
        formState: {errors},
        handleSubmit,
        getValues,
        setError,
        watch,
    } = methods;

    const password = watch('password', '');

    const loginSilently = async () => {
        const {email, password} = getValues();

        login(email, password, RouteCreateApplicantAccount.experience);
    };

    const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
        // 👇 Execute the Mutation
        mutate(values);
    };

    const pageContent = (
        <Box sx={{
            backgroundColor: theme.palette.black.main,
            margin: '0 -8px',
            padding: '0 20px 40px',
            [theme.breakpoints.up('sm')]: {
                backgroundColor: 'unset'
            },
            'form': {
                [theme.breakpoints.up('sm')]: {
                    maxWidth: '391px',
                    margin: '0 auto'
                }
            }
        }}>
            <AsideLetsConnect hasGradient>
                Let’s connect!
            </AsideLetsConnect>
            <AsideCaption>
                Join Growth Collective and get matches as soon as possible
            </AsideCaption>

            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmitHandler)}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={2} columnSpacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Input
                                name="firstName"
                                placeholder="First Name"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                name="lastName"
                                placeholder="Last Name"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                name="email"
                                placeholder="Email"
                                sx={darkBgInputStyles}
                                helperText={(errors as any).emailTaken && (
                                    <Typography color="error" variant="caption">
                                        It seems that you are already registered.&nbsp;
                                        <Link
                                            underline="hover"
                                            onClick={() => navigate(RouteAuth.login)}
                                        >
                                            Please try to login.
                                        </Link>
                                    </Typography>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputPhone
                                name="phone"
                                placeholder="Phone"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                name="password"
                                placeholder="Password"
                                type="password"
                                sx={darkBgInputStyles}
                            />

                            <AnimateHeight isVisible={!!password?.length} isOverflowHidden={false}>
                                <PasswordStrengthBar password={password} minLength={8}/>
                            </AnimateHeight>

                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                name="passwordConfirm"
                                placeholder="Repeat Password"
                                type="password"
                                sx={darkBgInputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} marginBottom="24px">
                            <Checkbox
                                name="termsAgree"
                                label={<TermsAndPrivacyLabel/>}
                            />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            mt: '3px',
                            mb: '29px',
                            [theme.breakpoints.up('sm')]: {
                                mt: '4px',
                                mb: '38px'
                            },
                            [theme.breakpoints.up('md')]: {
                                mt: '5px',
                                mb: '61px'
                            }
                        }}
                        textAlign="center"
                    >
                        <ContinueButton
                            disabled={isLoading}
                            fullWidth
                            hasGradient
                            hasArrow
                            type="submit"
                            variant="contained"
                        >
                            Continue
                        </ContinueButton>
                    </Box>

                </form>
            </FormProvider>

            <Stack
                direction={mdUp ? 'row' : 'column'}
                spacing={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="24"
            >
                <Typography>
                    <LoginLink
                        to={utm.patchUri(RouteAuth.login)}
                    >
                        I already have an account
                    </LoginLink>
                </Typography>

                {mdUp && <span>•</span>}

                <Typography>
                    <LoginLink
                        to={utm.patchUri(RouteCreateClientAccount.signup)}
                    >
                        Sign up as a client
                    </LoginLink>
                </Typography>
            </Stack>
        </Box>
    );

    useEffect(() => {
        logout();   // clean previous tokens from local storage
    }, [logout]);

    return (
        mdUp
            ? <AnimatePageWrp
                maxWidth={{md: 520, lg: 663}}
            >
                {pageContent}
            </AnimatePageWrp>
            : <AnimatePageWrpFadeIn
                maxWidth={{md: 520, lg: 663}}
            >
                {pageContent}
            </AnimatePageWrpFadeIn>
    );
};

export default SignUpApplicantForm;
