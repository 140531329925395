import {isSameMonth} from 'date-fns';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/fp/differenceInDays';
import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import {zonedTimeToUtc as dateFnsTzZonedTimeToUtc} from 'date-fns-tz';
import formatWithTimezone from 'date-fns-tz/format';
import {parse} from 'duration-fns';

import {findTimezoneById} from './timezones';

export const differenceTime = (dateLater: Date, dateEarlier: Date): string => {
    const inHours = differenceInHours(dateLater, dateEarlier);

    if (inHours) return `${inHours} hour${inHours > 1 ? 's' : ''}`;

    const inMinutes = differenceInMinutes(dateLater, dateEarlier);

    if (inMinutes) return `${inMinutes} min`;

    return `${differenceInSeconds(dateLater, dateEarlier)} sec`;
};

const dateWithouTimezone = (date: string | Date) => {
    if (typeof date === 'string' && !date.includes('T')) {
        const dt = new Date(date);

        return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    }

    return date instanceof Date ? date : new Date(date);
};

// P	04/29/1453
// PP	Apr 29, 1453
export const formatDate = (date: string | Date, isCompact = false): string => {
    const dtDateOnly = dateWithouTimezone(date);

    return format(dtDateOnly, isCompact ? 'P' : 'PP');
};

// Pp	04/29/1453, 12:00 AM
export const formatDateTime = (date: string | Date) => {
    const value = date instanceof Date ? date : new Date(date);

    return format(value, 'Pp');
};

export const zonedTimeToUtc = (date: string | Date, timezone: string): Date => {
    const ret = dateFnsTzZonedTimeToUtc(date, timezone);

    if (!isNaN(ret.getTime())) {
        return ret;
    }
    const tz = findTimezoneById(timezone);

    if (!tz || /^[A-Z]{3}\d[A-Z]{3}$/.test(timezone)) {
        return ret;
    }

    const stdAlias = tz.aliases.find(alias => /^[A-Z]{3}\d[A-Z]{3}$/.test(alias));

    if (stdAlias) {
        return zonedTimeToUtc(date, stdAlias);
    }

    const geoAlias = tz.aliases.find(alias => alias.includes('/'));

    if (geoAlias) {
        return zonedTimeToUtc(date, geoAlias);
    }

    return ret;
};

// Thursday Oct 5, at 1:00 AM EEST      // (not GMT+2)
export const formatDateTimeWithTimezone = (date: string | Date) => {
    if (!date) return '';

    const dateTime = date instanceof Date ? date : new Date(date);

    let timezone = formatWithTimezone(dateTime, 'zzzz');

    if (!timezone.startsWith('GMT') && /^[A-Z][a-z]+( [A-Z][a-z]+)+$/g.test(timezone)) {
        timezone = timezone.replaceAll(/[a-z\s]/g, '');
    }

    return `${format(dateTime, 'EEEE LLL d\', at\' h:mm a')} ${timezone}`;
};

export const formatWeek = (date: string | Date): string => {
    const dtDateOnly = dateWithouTimezone(date);

    return format(dtDateOnly, 'E, MMM d');
};

// p	12:00 AM
export const formatTime = (date: string | Date) => {
    const value = date instanceof Date ? date : new Date(date);

    return format(value, 'p');
};

// ppp	12:00:00 AM GMT+2
export const formatTimeWithTimezone = (date: string | Date) => {
    const value = date instanceof Date ? date : new Date(date);

    return format(value, 'ppp');
};

// isCompact                    not compact
// P0H0M   -> 00:00             P0H0M   -> 0h 0m
// P3H15M  ->  3:15             P3H15M  -> 3h 15m
// P21H15M -> 21:15             P21H15M -> 21h 15m
export const formatHoursFromDuration = (value: string, isCompact = true): string => {
    const {hours, minutes} = parse(value);

    if (isCompact) {
        return `${!hours && !minutes ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    } else {
        return `${hours}h ${minutes < 10 ? '0' : ''}${minutes}m`;
    }
};

export const formatWeekDay = (date: string | Date, isCompact = true) => {
    const value = date instanceof Date ? date : new Date(date);

    return format(value, isCompact ? 'E' : 'EEEE');    // Mon vs Monday
};

const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (value: string | Date) => {
    const dateTime = typeof value === 'string' ? new Date(value) : value;
    const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));

    return utcFromLocal;
};

export const localToUTC = (dateTime: Date) => {
    const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));

    return utcFromLocal;
};

export const normilizeHours = (value: number) => value >= 0 && value < 10 ? `0${value}` : value;


export const showTimeOrDate = (date: string | Date, isCompact = true) => {
    const today = new Date();
    const value = date instanceof Date ? date : new Date(date);

    if (isSameDay(today, value)) {
        return formatTime(value);
    } else if (isSameWeek(today, value)) {
        return formatWeekDay(value, isCompact);
    }

    return formatDate(value);
};

export const showTodayOrDate = (date: string | Date, isCompact = true) => {
    const today = new Date();
    const value = date instanceof Date ? date : new Date(date);

    if (isSameDay(today, value)) {
        return 'Today';
    } else if (isSameWeek(today, value)) {
        return formatWeekDay(value, isCompact);
    }

    return formatDate(value);
};

export const showTodayOrLast = (date: string | Date, isCompact = true) => {
    const today = new Date();
    const value = date instanceof Date ? date : new Date(date);

    if (isSameDay(today, value)) {
        return 'Today';
    } else if (differenceInDays(value, today) === 1) {
        return 'Yesterday';
    } else if (isSameMonth(today, value)) {
        return 'Last month';
    }

    return 'Older';
};
