import React from 'react';

const ArrowLeft = () => {
    return (
        /*<svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_983_8867)">
                <path d="M0.923103 8.8154H39.0769C39.5308 8.8154 39.8975 8.44874 39.8975 7.99489C39.8975 7.54105 39.5308 7.17438 39.0769 7.17438H2.90516L7.88464 2.19489C8.20515 1.87438 8.20515 1.35387 7.88464 1.03335C7.56413 0.712841 7.04361 0.712841 6.7231 1.03335L0.341053 7.41541C0.105152 7.6513 0.035923 8.00258 0.164127 8.31028C0.292332 8.6154 0.592331 8.8154 0.923103 8.8154Z" fill="#121416"/>
                <path d="M7.31294 15.2055C7.52319 15.2055 7.73345 15.1261 7.89243 14.9645C8.21294 14.644 8.21294 14.1235 7.89243 13.803L1.50268 7.41324C1.18217 7.09272 0.661659 7.09272 0.341146 7.41324C0.0206337 7.73375 0.0206337 8.25426 0.341146 8.57478L6.73089 14.9645C6.89243 15.1261 7.10268 15.2055 7.31294 15.2055Z" fill="#121416"/>
            </g>
            <defs>
                <clipPath id="clip0_983_8867">
                    <rect width="40" height="16" fill="white" transform="matrix(-1 0 0 1 40 0)"/>
                </clipPath>
            </defs>
        </svg>*/
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.923103 20.8154H39.0769C39.5308 20.8154 39.8975 20.4487 39.8975 19.9949C39.8975 19.541 39.5308 19.1744 39.0769 19.1744H2.90516L7.88464 14.1949C8.20515 13.8744 8.20515 13.3539 7.88464 13.0334C7.56413 12.7128 7.04361 12.7128 6.7231 13.0334L0.341053 19.4154C0.105152 19.6513 0.035923 20.0026 0.164127 20.3103C0.292332 20.6154 0.592331 20.8154 0.923103 20.8154Z"
                fill="#121416"/>
            <path
                d="M7.31282 27.2046C7.52307 27.2046 7.73333 27.1251 7.89231 26.9635C8.21282 26.643 8.21282 26.1225 7.89231 25.802L1.50256 19.4123C1.18205 19.0917 0.661537 19.0917 0.341024 19.4123C0.0205116 19.7328 0.0205116 20.2533 0.341024 20.5738L6.73077 26.9635C6.8923 27.1251 7.10256 27.2046 7.31282 27.2046Z"
                fill="#121416"/>
        </svg>
    );
};

export default ArrowLeft;
