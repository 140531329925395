import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';

import {styled} from '@mui/material';

import theme from '../../theme';

interface IAsideMenuProps {
    list: IAsideMenuItem[];
}

export interface IAsideMenuItem {
    link: string;
    title: string;
    icon?: React.ReactNode;
}

const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 40px 64px;
  width: 365px;
  flex-shrink: 0;
  background-color: ${theme.palette.black.main};
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  position: sticky;
  top: 100px;
`;

const Item = styled('li')`
  a {
    display: flex;
    align-items: center;
    padding: 24px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.gray.main};
    transition: color .3s;

    svg {
      flex-shrink: 0;
      margin-right: 16px;
      width: 24px;
      height: 24px;
    }

    path {
      fill: currentColor;
      transition: fill .3s;
    }

    &::after {
      content: '';
      margin-left: auto;
      flex-shrink: 0;
      min-width: 90px;
      width: 79px;
      height: 5px;
      border-radius: 5px;
      background-image: linear-gradient(to right, transparent, ${theme.palette.purple.main});
      background-position: 0 center;
      background-size: 0;
      background-repeat: no-repeat;
      opacity: 0;
      transition: opacity .3s, background-size .7s;
    }

    &.active {
      color: ${theme.palette.white.main};

      path {
        fill: ${theme.palette.purple.main};
      }

      &::after {
        opacity: 1;
        background-size: 100%;
      }
    }

    &:hover {
      color: ${theme.palette.white.main};
    }
  }
`;
const AsideMenu: FC<IAsideMenuProps> = ({list}) => {
    return (
        <List>
            {
                list.map(item =>
                    <Item
                        key={item.link}
                    >
                        <NavLink to={item.link}>
                            {item?.icon}
                            {item.title}
                        </NavLink>
                    </Item>
                )
            }

        </List>
    );
};

export default React.memo(AsideMenu);
