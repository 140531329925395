import {any, object} from 'zod';

import {ACCEPTED_FILES_TYPES, MAX_FILE_SIZE, MAX_FILE_SIZE_MB} from '../../../../constants';
import {getAcceptedExtentions, getAcceptedTypes, getFileExtansion} from '../../../../utils';

const MAX_MESSAGE_LENGTH = 1000;

const ACCEPTED_EXTENSIONS = getAcceptedExtentions(ACCEPTED_FILES_TYPES);

export const ACCEPTED_TYPES = getAcceptedTypes(ACCEPTED_FILES_TYPES);

export const isFileSizeValid = (file: File) => file.size <= MAX_FILE_SIZE_MB;
export const isFileTypeValid = (file: File) => {
    const ext = '.' + getFileExtansion(file.name.toLowerCase());

    return ACCEPTED_EXTENSIONS.includes(ext || '');
};

export const isFileValid = (file: File) => isFileSizeValid(file) && isFileTypeValid(file);

export const messageSchema = object({
    message: any()
        .refine((message) => message?.length ? message.length < MAX_MESSAGE_LENGTH : true,
            `Max length is ${MAX_MESSAGE_LENGTH}.`
        ),
    files: any()
        .refine(
            (files: File[]) => files?.length ? !files.find(f => !isFileSizeValid(f)) : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        )
        .refine(
            (files: File[]) => files?.length ? !files.find(f => !isFileTypeValid(f)) : true,
            '.png, .jpg, .jpeg, .svg, .doc, .docx, .xsl, .xslx and .pdf files are accepted.'
        ),
    url: any()
})
    .refine(({files, message}) => !!files.length || !!message.length, {
        path: ['files'],
        message: 'File or message is required',
    })
    .refine(({files, message}) => !!files.length || !!message.length, {
        path: ['message'],
        message: 'Message or file is required',
    });
