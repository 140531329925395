import React from 'react';

import {Stack} from '@mui/material';

import {InputCaption} from '../Forms';
import Input from '../Forms/Input';

interface ProjectFormProps {
    description: string;
    isSubmitting: boolean;
    max: number;
    name: string;
}

export const ProjectForm: React.FC<ProjectFormProps> = ({description, isSubmitting, max, name}) => {
    return (
        <form
            noValidate
            autoComplete="off"
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    pb: '100px',
                    '@media (min-height: 800px)': {
                        pb: 0
                    }
                }}
            >
                <InputCaption>Enter <span>a name</span> for this role</InputCaption>
                <Input
                    autoFocus={!name}
                    disabled={isSubmitting}
                    name="projectName"
                    placeholder="I need a marketing manager for..."
                />
                <br/>
                <InputCaption><span>Describe</span> this role</InputCaption>
                <Input
                    counter={max - (description?.length || 0)}
                    disabled={isSubmitting}
                    inputProps={{maxLength: max}}
                    minRows={5}
                    multiline
                    name="projectDescription"
                    placeholder="Add some description to give us more details of what needs to be done"
                    variant="outlined"
                />
            </Stack>
        </form>
    );
};
