import React, {useRef} from 'react';
import {motion} from 'framer-motion';

import {Box, Grid, styled, Typography} from '@mui/material';

import {CheckInCircleIcon} from '../../../assets/icons';
import {ContinueButton, PageTopTitle, WhiteButton} from '../../../components';
import {animationAlertBox} from '../../../components/Animations';
import {RouteClientPortal, SM} from '../../../constants';
import {useClientRole, useNavigateUTM} from '../../../hooks';
import theme from '../../../theme';
import {isWidget} from '../../../utils';

const ThankYouWrp = styled('div')`
`;

const ThankYouBox = styled('div')`
    padding-bottom: 24px;
`;
const WhiteCircle = styled('div')`
    margin: 40px auto 24px;
    width: 100px;
    height: 100px;
    background-color: ${theme.palette.white.main};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
    }

    @media (min-width: ${SM}) {
        flex-shrink: 0;
        width: 160px;
        height: 160px;
        margin: 0;
        margin-left: auto;
    }
`;

const AnimatedButton = styled(ContinueButton)`
    position: relative;
    animation-name: enlarge;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;

    &:hover {
        animation-play-state: paused;
    }

    @keyframes enlarge {
        0% {
            transform: scale(1)
        }
        33% {
            transform: scale(1.05)
        }
        66% {
            transform: scale(1)
        }
        100% {
            transform: scale(1.05)
        }
    }
`;

export const ClientThankYou = () => {
    const ref = useRef<HTMLDivElement>();
    const navigate = useNavigateUTM();

    useClientRole(); // check is current step allowed?

    return (
        <Box ref={ref}>
            <ThankYouWrp>
                <ThankYouBox>
                    <Grid container spacing={0} rowSpacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '40px',
                                [theme.breakpoints.up('xl')]: {
                                    mb: '57px'
                                }
                            }}
                        >
                            <CheckInCircleIcon/>
                        </Grid>
                    </Grid>

                    <PageTopTitle
                        title="Thank you! Your role was created!"
                        titleMaxWidth={{md: 840, lg: 840}}
                        desktopFontSize={32}
                        desktopMarginBottom={32}
                    />
                    {isWidget() ? null : (<>
                        <Typography
                            sx={{
                                mt: '32px',
                                mb: '24px',
                                fontWeight: 600,
                                fontSize: '16px',
                                textAlign: 'center',
                            }}
                        >
                            You can log in into your Dashboard to review your matches and connect with experts for this role
                        </Typography>
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                justifyContent: 'center',
                                '.MuiButton-root': {
                                    width: '100%',
                                    textAlign: 'center',
                                    'div': {
                                        justifyContent: 'center'
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        width: 'auto'
                                    }
                                }
                            }}
                        >
                            <AnimatedButton
                                variant="contained"
                                onClick={() => navigate(RouteClientPortal.myMatches)}
                            >
                                View Matches
                            </AnimatedButton>
                        </Grid>
                    </>)}
                    <Box
                        component={motion.div}
                        {...animationAlertBox(0)}
                        sx={{
                            maxWidth: '840px',
                            ml: 'auto',
                            mr: 'auto',
                            mt: '32px',
                            position: 'relative',
                            borderRadius: '24px',
                            overflow: 'hidden',
                            p: '40px 24px',
                            backgroundColor: theme.palette.purple.main,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'bottom center',
                            [theme.breakpoints.up('sm')]: {
                                display: 'flex',
                                alignItems: 'center',
                                gap: '32px',
                                backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'840\' height=\'309\' viewBox=\'0 0 840 309\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg clip-path=\'url(%23clip0_12338_5397)\'%3E%3Cmask id=\'mask0_12338_5397\' style=\'mask-type:alpha\' maskUnits=\'userSpaceOnUse\' x=\'0\' y=\'0\' width=\'840\' height=\'309\'%3E%3Crect y=\'0.498047\' width=\'840\' height=\'308\' rx=\'24\' fill=\'%23D9D9D9\'/%3E%3C/mask%3E%3Cg mask=\'url(%23mask0_12338_5397)\'%3E%3Cpath d=\'M267 411.498C267 388.391 274.943 372.081 287.813 352.875C311.974 316.818 348.197 284.373 383.362 259.268C416.182 235.838 450.278 211.82 487.952 196.759C521.377 183.396 554.288 173.454 589.598 166.607C632.529 158.282 676.019 153.37 719.1 145.805C762.536 138.179 804.488 125.916 845.974 111.241C891.92 94.9879 933.812 74.1196 973.899 46.4202C1027.51 9.37767 1074.34 -35.0168 1126 -74.502\' stroke=\'url(%23paint0_linear_12338_5397)\' stroke-width=\'100\' stroke-linecap=\'round\'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id=\'paint0_linear_12338_5397\' x1=\'267\' y1=\'38.6187\' x2=\'1145.77\' y2=\'177.389\' gradientUnits=\'userSpaceOnUse\'%3E%3Cstop stop-color=\'%237E5FFF\'/%3E%3Cstop offset=\'0.333333\' stop-color=\'%233C94F7\'/%3E%3Cstop offset=\'0.666667\' stop-color=\'%237CF2D0\'/%3E%3Cstop offset=\'1\' stop-color=\'%2367DD42\'/%3E%3C/linearGradient%3E%3CclipPath id=\'clip0_12338_5397\'%3E%3Crect y=\'0.498047\' width=\'840\' height=\'308\' rx=\'24\' fill=\'white\'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")',
                            },
                            [theme.breakpoints.up('md')]: {
                                p: '32px',
                                flexDirection: 'row-reverse'
                            }
                        }}
                    >
                        <WhiteCircle>
                            <img src="/assets/images/jakob-photo.png" alt="jakob photo"/>
                        </WhiteCircle>
                        <Box>
                            <Typography
                                sx={{
                                    mb: '2px',
                                    fontWeight: 600,
                                    fontSize: '24px',
                                    color: theme.palette.white.main,
                                }}
                            >
                                If interested, you can schedule a call with Jacob, your Success Manager, to review your growth needs and introduce our best fractional marketers!
                            </Typography>
                            <WhiteButton
                                sx={{
                                    mt: '24px',
                                    width: '100% !important',
                                    [theme.breakpoints.up('sm')]: {
                                        width: 'auto !important'
                                    },
                                    'div': {
                                        justifyContent: 'center'
                                    }
                                }}
                                onClick={() => {window.open('https://www.growthcollective.com/schedule-meeting');}}
                            >
                                Schedule
                            </WhiteButton>
                        </Box>
                    </Box>
                </ThankYouBox>
            </ThankYouWrp>
        </Box>
    );
};
