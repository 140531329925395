import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';

import {Box, Grid} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {applicationService} from '../../../api';
import {
    AnimateHeight,
    AnimatePageWrp,
    CheckButton,
    ContinueButton,
    InputAdornment,
    InputCaption,
    LoadingMask,
    PageTopTitle,
    Portal,
    showToastError,
    showToastSuccess
} from '../../../components';
import {MAX_SAFE_INTEGER, RouteCreateApplicantAccount} from '../../../constants';
import {useApplication, useNavigateUTM} from '../../../hooks';
import {EWorkType} from '../../../models';
import theme from '../../../theme';

const containerStyles = {
    gap: '12px',
    [theme.breakpoints.up('lg')]: {
        gap: '16px'
    }
};

const itemStyles = {
    width: 'calc(50% - 6px) !important',
    flexBasis: 'calc(50% - 6px) !important',
    maxWidth: 'calc(50% - 6px) !important',
    [theme.breakpoints.up('lg')]: {
        width: 'calc(33% - 9px) !important',
        flexBasis: 'calc(33% - 9px) !important',
        maxWidth: 'calc(33% - 9px) !important',
    }
};

const WorkTypes = () => {
    const {application, isApplicationLoading, refetchApplication} = useApplication();
    const [workTypes, setWorkTypes] = useState<EWorkType[]>([]);
    const [fixedRate, setFixedRate] = useState<number | null>();
    const [rateRangeMin, setRateRangeMin] = useState<number>();
    const [rateRangeMax, setRateRangeMax] = useState<number>();
    const fixedRateRef = useRef<HTMLInputElement>();

    const navigate = useNavigateUTM();

    const isRetainer = workTypes?.includes(EWorkType.retainer);
    const isHourly = workTypes?.includes(EWorkType.hourly);
    const isFixed = workTypes?.includes(EWorkType.fixed);

    const {mutate, isLoading} = useMutation(
        applicationService.workTypeSubmit,
        {
            onSuccess() {
                showToastSuccess('Work types were saved successfully!');
                navigate(RouteCreateApplicantAccount.otherDetails);
                refetchApplication();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleClickType = useCallback((type: EWorkType) => () => {
        const newValues = workTypes?.filter(t => t !== type);

        // if it is new push into the array
        if (workTypes && newValues.length === workTypes?.length) {
            setWorkTypes([...workTypes, type]);

            // auto focus textfield
            if (type === EWorkType.hourly) {
                setTimeout(() => {
                    fixedRateRef.current?.focus();
                }, 150);
            }

            // or remove from the array
        } else {
            setWorkTypes(newValues);

            // if was unchecked all items then let's erase rates and fixed too
            if (!newValues.length) {
                setFixedRate(null);
                setRateRangeMin(0);
                setRateRangeMax(0);

                // if hide textField - clean value
            } else if (type === EWorkType.hourly) {
                setFixedRate(null);
                // if hide both types - clean rate ranges
            } else if (!(isRetainer && isFixed)) {
                setRateRangeMin(0);
                setRateRangeMax(0);
            }
        }
    }, [isRetainer, isFixed, workTypes]);

    const handleClickRange = useCallback((min: number, max: number) => () => {
        // un-check
        if (min === rateRangeMin) {
            setRateRangeMin(0);
            setRateRangeMax(0);
        } else {
            setRateRangeMin(min);
            setRateRangeMax(max);
        }
    }, [rateRangeMin]);

    const handleChangeFixedRate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);

        if (typeof value === 'number') {
            if (value > MAX_SAFE_INTEGER / 100) {
                const max = parseInt((MAX_SAFE_INTEGER / 100).toString(), 10);

                setFixedRate(max);
            } else {
                setFixedRate(value);
            }
        }
    }, []);

    const handleSubmit = () => {
        const isDirty = !isEqual(workTypes, application?.workTypes)
            || fixedRate !== application?.fixedRate
            || rateRangeMin !== application?.rateRange?.[0]
            || rateRangeMax !== application?.rateRange?.[1];
        const isRetainerOrFixed = isRetainer || isFixed;

        if (!workTypes.length) {
            showToastError('Please add a work type.');
        } else if (isRetainerOrFixed && !rateRangeMin) {
            showToastError('Please select your retainer rate (per hour).');
        } else if (isHourly && !fixedRate) {
            showToastError('Please select your hourly rate (per hour).');
        } else if (fixedRate && fixedRate > MAX_SAFE_INTEGER / 100) {    // convert MAX_SAFE_INTEGER: 2147483647 without cents
            showToastError('Please enter a valid rate value.');

            // check is dirty or not
        } else if (isDirty) {
            mutate({
                workTypes,
                fixedRate: (fixedRate || 0) * 100,  // convert to cents
                rateRange: [(rateRangeMin || 0) * 100, (rateRangeMax || 0) * 100]
            });
        } else {
            navigate(RouteCreateApplicantAccount.otherDetails);
        }
    };

    const formatRateTitle = useMemo(() => {
        if (isRetainer && !isFixed) {
            return 'Select your retainer rate range (per hour)';
        } else if (isFixed && !isRetainer) {
            return 'Select your fixed rate range (per hour)';
        } else if (isRetainer && isFixed) {
            return 'Select your retainer and fixed rate range (per hour)';
        } else {
            return '';
        }
    }, [isRetainer, isFixed]);

    useEffect(() => {
        if (!application) {
            return;
        }

        const {fixedRate, rateRange, workTypes} = application;

        fixedRate && setFixedRate(fixedRate / 100);         // remove cents
        rateRange && setRateRangeMin(rateRange[0] / 100);
        rateRange && setRateRangeMax(rateRange[1] / 100);
        setWorkTypes(workTypes);
    }, [application]);

    return (
        <AnimatePageWrp maxWidth={{md: 600}}>
            <PageTopTitle
                title={<>Okay, great, now what kind of <span>work arrangement</span> do you prefer?</>}
                subtitle="You can select multiple work types that fit you best"
            />

            {
                isApplicationLoading
                    ? (
                        <LoadingMask/>
                    ) : (
                        <>
                            <Grid
                                container
                                sx={containerStyles}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    sx={itemStyles}
                                >
                                    <CheckButton
                                        label="Retainer"
                                        selected={isRetainer}
                                        onClick={handleClickType(EWorkType.retainer)}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    sx={itemStyles}
                                >
                                    <CheckButton
                                        label="Hourly"
                                        selected={isHourly}
                                        onClick={handleClickType(EWorkType.hourly)}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    sx={itemStyles}
                                >
                                    <CheckButton
                                        label="Fixed"
                                        selected={isFixed}
                                        onClick={handleClickType(EWorkType.fixed)}
                                    />
                                </Grid>
                            </Grid>
                            <AnimateHeight isOverflowHidden={false} isVisible={isRetainer || isFixed}>
                                <Grid
                                    container
                                    sx={containerStyles}
                                >
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                mt: '40px',
                                                mb: '-12px',
                                                [theme.breakpoints.up('xl')]: {
                                                    mt: '64px',
                                                    mb: '-16px'
                                                }
                                            }}
                                        >
                                            <InputCaption>
                                                {formatRateTitle}
                                            </InputCaption>
                                        </Box>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        sx={itemStyles}
                                    >
                                        <CheckButton
                                            label="$50 - $100"
                                            selected={rateRangeMin === 50}
                                            onClick={handleClickRange(50, 100)}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        sx={itemStyles}
                                    >
                                        <CheckButton
                                            label="$100 - $150"
                                            selected={rateRangeMin === 100}
                                            onClick={handleClickRange(100, 150)}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        sx={itemStyles}
                                    >

                                        <CheckButton
                                            label="$150 - $200"
                                            selected={rateRangeMin === 150}
                                            onClick={handleClickRange(150, 200)}
                                        />

                                    </Grid>
                                </Grid>
                            </AnimateHeight>
                            <AnimateHeight isOverflowHidden={false} isVisible={isHourly}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                mt: '40px',
                                                mb: '-12px',
                                                [theme.breakpoints.up('sm')]: {
                                                    mt: '64px'
                                                }
                                            }}
                                        >
                                            <InputCaption>Enter your rate for hourly work type (in USD)</InputCaption>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputAdornment
                                            inputRef={fixedRateRef}
                                            icon="$"
                                            placeholder="Type your hourly rate here"
                                            position="start"
                                            value={fixedRate || ''}
                                            onChange={handleChangeFixedRate}
                                        />
                                    </Grid>
                                </Grid>
                            </AnimateHeight>
                        </>
                    )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '72px'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '63px'
                    },
                    '@media(max-height: 900px)': {
                        pb: '60px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isApplicationLoading || isLoading || !workTypes?.length}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isApplicationLoading || isLoading || !workTypes?.length}
                    hasArrow
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};

export default WorkTypes;
