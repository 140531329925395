import React, {FC, useCallback, useEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isValid from 'date-fns/isValid';
// import differenceInMinutes from 'date-fns/differenceInMinutes';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, Theme, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {freelancerService, matchesService} from '../../../../api';
import {useAuth} from '../../../../hooks';
import {EMeetingStatus, IMeeting, IMeetingRequest} from '../../../../models';
import {localToUTC} from '../../../../utils';
import {findTimezoneById, TIMEZONES_OPTIONS} from '../../../../utils/timezones';
import {
    BlackBorderButton,
    ContinueButton,
    DialogEmoji,
    InputCaption,
    InputDate,
    InputTime,
    InputTimezone,
    RedButton,
    showToastError,
    showToastSuccess
} from '../../../index';

import {scheduleSchema} from './schedule.schema';

type ScheduleInput = TypeOf<typeof scheduleSchema>;

interface IProps {
    conversationId: string;
    data?: IMeeting;
    open: boolean;
    onClose: (meetingId: number | null) => void;
}

const ChatScheduleMeeting: FC<IProps> = ({conversationId, data, open, onClose}) => {
    const {engagementId} = useParams();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const {isClient, isFreelancer} = useAuth();

    const apiService = isClient ? matchesService : freelancerService;

    const isOwner = useMemo(() => {
        return (isClient && data?.status && [EMeetingStatus.CLIENT_PROPOSED, EMeetingStatus.CLIENT_REJECTED].includes(data.status))
            || (isFreelancer && data?.status && [EMeetingStatus.FREELANCER_PROPOSED, EMeetingStatus.FREELANCER_REJECTED].includes(data.status));
    }, [isClient, isFreelancer, data]);

    const methods = useForm<ScheduleInput>({
        resolver: zodResolver(scheduleSchema)
    });

    const {
        formState: {isDirty},
        // getValues,
        handleSubmit,
        reset,
        setValue,
        trigger,
        watch
    } = methods;

    const from = watch('from');
    const to = watch('to');

    const {isLoading: isSubmitting, mutate} = useMutation(
        (values: Partial<IMeeting>) => {
            const {id, from, to, timezone} = values;
            const payload: Partial<IMeetingRequest> = id ? {from, to, timezone} : values;

            if (engagementId) {
                payload.engagementId = Number(engagementId);     // if Chat in the MyHires Details page we need also send engagementId
            }

            payload.from = payload.from?.replace('Z', '');
            payload.to = payload.to?.replace('Z', '');

            return id ? apiService.meetingUpdate(id, payload) : apiService.meetingSubmit(conversationId, payload);
        },
        {
            onSuccess(response) {
                console.log('scheduleSchema', response);
                showToastSuccess(data?.id ? 'Meeting has been updated.' : 'Meeting has been created successfully!');
                reset({});
                onClose(response.data.id);
            },
            onError(error: AxiosError) {
                console.log(error);
                showToastError(error);
            },
        }
    );

    const handleAccept = useCallback(async () => {
        if (!data?.id) return;
        await apiService.meetingAccept(data.id);
        showToastSuccess('Meeting has been accepted.');
        reset({});
        onClose(1);
    }, [apiService, data, onClose, reset]);

    const handleClose = useCallback(() => {
        reset({});
        onClose(null);
    }, [onClose, reset]);

    const handleReject = useCallback(async () => {
        if (!data?.id) return;
        await apiService.meetingReject(data.id);
        reset({});
        onClose(1);
    }, [apiService, data, onClose, reset]);

    const onSubmitHandler: SubmitHandler<ScheduleInput> = (values: Partial<IMeeting>) => {
        console.log('values', values);

        const {from, to, timezone} = values as any;

        mutate({
            ...values,
            from: localToUTC(new Date(from)).toISOString(),
            to: localToUTC(new Date(to)).toISOString(),
            timezone: timezone.value,
        });
    };

    const actionBtns = useMemo(() => {
        // if it is new Schedule then show create buttons

        if (!data?.id) {
            return (
                <>
                    <BlackBorderButton
                        fullWidth={mdDown}
                        onClick={handleClose}
                    >
                        Cancel
                    </BlackBorderButton>
                    <ContinueButton
                        disabled={isSubmitting || !isDirty}
                        fullWidth={mdDown}
                        sx={{width: mdDown ? '100%' : 'auto !important'}}
                        variant="contained"
                        onClick={() => validate()}
                    >
                        Schedule
                    </ContinueButton>
                </>
            );
        } else if (data?.status === EMeetingStatus.ACCEPTED) {
            return (
                <>
                    <RedButton
                        fullWidth={mdDown}
                        onClick={handleReject}
                    >
                        Cancel
                    </RedButton>
                    <ContinueButton
                        fullWidth={mdDown}
                        sx={{width: mdDown ? '100%' : 'auto !important'}}
                        variant="contained"
                        onClick={handleClose}
                    >
                        Close
                    </ContinueButton>
                </>
            );
            // if open for Editing...
        } else if (isOwner) {
            return (
                <>
                    <BlackBorderButton
                        fullWidth={mdDown}
                        onClick={handleClose}
                    >
                        Cancel
                    </BlackBorderButton>
                    <ContinueButton
                        disabled={isSubmitting || !isDirty}
                        fullWidth={mdDown}
                        sx={{width: mdDown ? '100%' : 'auto !important'}}
                        variant="contained"
                        onClick={() => validate()}
                    >
                        Edit
                    </ContinueButton>
                </>
            );

            // show Accept buttons for remote Participants
        } else {
            return (
                <>
                    <BlackBorderButton
                        fullWidth={mdDown}
                        onClick={handleReject}
                    >
                        Reject
                    </BlackBorderButton>
                    <ContinueButton
                        disabled={isSubmitting}
                        fullWidth={mdDown}
                        sx={{width: mdDown ? '100%' : 'auto !important'}}
                        variant="contained"
                        onClick={handleAccept}
                    >
                        Accept
                    </ContinueButton>
                </>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isDirty, isOwner, isSubmitting, mdDown]);

    const validate = () => {
        trigger('from');
        trigger('to');
        trigger('timezone');
        handleSubmit(onSubmitHandler)();
    };

    // find selected timezone or set User's timezone by default
    const findTimezoneOption = useCallback((value?: string) => {
        const foundTimezone = findTimezoneById(value || Intl.DateTimeFormat().resolvedOptions().timeZone);

        return TIMEZONES_OPTIONS.find(it => it.value === foundTimezone?.id);
    }, []);

    // populate form
    useEffect(() => {
        if (data && open) {
            const option = findTimezoneOption(data.timezone);

            reset({
                ...data,
                from: new Date(data.from),
                to: new Date(data.to),
                timezone: option
            });
        }

        // set User timezone by default
        if (!data?.timezone && open) {
            const defaultTimazone = findTimezoneOption();

            reset({
                timezone: defaultTimazone
            });
        }
    }, [data, open, findTimezoneOption, reset]);

    // if user select From and To is empty
    // then lets set Meeteing duration 1 hour
    useEffect(() => {
        if (!open || (!from && !to)) return;
        // console.log('useEffect', from, to, isValid(from), isValid(to));
        if (from && from < new Date()) {
            const now = new Date();
            const newMinutes = next10Minutes(now.getMinutes() + 5);

            from.setHours(now.getHours());
            from.setMinutes(newMinutes);

            setValue('from', from);
            trigger('from');
        } else if (from && !to) {
            setValue('to', new Date(from).setHours(from.getHours() + 1));
            trigger('to');
        } else if (isValid(from) && isValid(to) && differenceInMinutes(to, from) < 1) {
            setValue('to', new Date(from).setMinutes(from.getMinutes() + 15));
            trigger('to');
            // showToastError('Duration is invalid');
        }
    }, [from, to, open, setValue, trigger]);

    // don't allow modify for not Owner or Accepted meeting
    const isDisabled = isSubmitting
        || data?.status === EMeetingStatus.ACCEPTED
        || ([EMeetingStatus.CLIENT_PROPOSED, EMeetingStatus.FREELANCER_PROPOSED].includes(data?.status as EMeetingStatus) && !isOwner);

    return (

        <DialogEmoji
            actions={actionBtns}
            maxWidth="sm"
            open={open}
            title={(
                <>
                    <Box justifyContent="center">
                        <img
                            src="/assets/images/schedule-meeting-icon.png"
                            width="48"
                            height="48"
                            alt="schedule icon"
                        />
                    </Box>
                    Schedule a call
                </>
            )}
            onClose={handleClose}
        >
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    noValidate
                    // onSubmit={handleSubmit(onSubmitHandler)}  // strange but this is not working...
                >
                    <Grid container rowSpacing="21px" columnSpacing="40px" sx={{mb: '40px'}}>
                        <Grid item xs={6}>
                            <InputCaption
                                sx={{
                                    textAlign: 'left !important',
                                    mb: '16px !important'
                                }}
                            >
                                Select a date
                            </InputCaption>
                            <InputDate
                                disabled={isDisabled}
                                disablePast
                                name="from"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputCaption
                                sx={{
                                    textAlign: 'left !important',
                                    mb: '16px !important'
                                }}
                            >
                                From
                            </InputCaption>
                            <InputTime
                                disabled={!from || isDisabled}
                                name="from"
                                minTime={new Date()}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputCaption
                                sx={{
                                    textAlign: 'left !important',
                                    mb: '16px !important'
                                }}
                            >
                                To
                            </InputCaption>
                            <InputTime
                                disabled={!from || isDisabled}
                                name="to"
                                minTime={from || new Date()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputCaption
                                sx={{
                                    textAlign: 'left !important',
                                    mb: '16px !important'
                                }}
                            >
                                Time zone
                            </InputCaption>
                            <InputTimezone
                                disabled={isDisabled}
                                name="timezone"
                            />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </DialogEmoji>
    );
};

export default React.memo(ChatScheduleMeeting);

function next10Minutes(minutes: number) {
    const remainder = minutes % 10;

    const result = remainder === 0 ? minutes : minutes + (10 - remainder);

    return result > 60 ? 0 : result;
}
