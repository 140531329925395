import React from 'react';

import {Box} from '@mui/material';

import FaqItem from './FaqItem';

interface IProps {
    list: Array<{
        title: string;
        text: string;
    }>;
}

const FaqList: React.FC<IProps> = ({list}) => {
    return (
        <Box>
            {list.map((item, i) =>
                <FaqItem
                    key={i}
                    title={item.title}
                    text={item.text}
                />
            )}
        </Box>
    );
};

export default React.memo(FaqList);
