import React from 'react';

import {Grid} from '@mui/material';

import {AsideSignupInitialContent, MainContent} from '../../../components';
import AsideBlock from '../../../components/AsideBlock';
import theme from '../../../theme';

import SignUpApplicantForm from './SignUpApplicantForm';

const SignUpPageLayout = () => {
    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'column',
                [theme.breakpoints.up('sm')]: {
                    flexDirection: 'row'
                }
            }}
        >
            <AsideBlock fixedScroll>
                <AsideSignupInitialContent/>
            </AsideBlock>
            <MainContent isMobileDark>
                <SignUpApplicantForm />
            </MainContent>
        </Grid>
    );
};

export default SignUpPageLayout;
