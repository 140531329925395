import React, {FC, useMemo} from 'react';
import {AxiosError} from 'axios';
import {useParams, useSearchParams} from 'react-router-dom';

import {Box, styled, Theme, useMediaQuery} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../../../api';
import {LoadingMask, showToastError, TimesheetStatusTitle, TimesheetWrapper, WeekEmpty, WeekView} from '../../../../../components';
import {CLIENT_ENGAGEMENT, LG, TIMESHEETS} from '../../../../../constants';
import {EEngagementStatus, EWorkWeekStatus, IEngagementClient} from '../../../../../models';
import {ChatDetails} from '../MyHiresMessages';

interface IProps {
    data: IEngagementClient;
}

const Wrp = styled('div')`
  @media (min-width: ${LG}) {
    display: flex;
    gap: 24px;
    align-items: flex-start;
  }
`;

const MyHiresTimesheets: FC<IProps> = ({data}) => {
    const {engagementId} = useParams();
    const [searchParams] = useSearchParams();

    const autoFocusId = Number(searchParams.get('id'));

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const {
        isLoading,
        data: timesheets,
        // refetch,
    } = useQuery([CLIENT_ENGAGEMENT, Number(engagementId), TIMESHEETS], clientService.getTimesheets(Number(engagementId)), {
        enabled: !!engagementId,
        staleTime: 5 * 60 * 1000,
        onError: (error) => showToastError(error as AxiosError)
    });

    const itemsRejected = useMemo(() => timesheets?.filter(item => item.status === EWorkWeekStatus.REJECTED) || [], [timesheets]);
    const itemsSubmitted = useMemo(() => timesheets?.filter(item => item.status === EWorkWeekStatus.SENT) || [], [timesheets]);
    const itemsApproved = useMemo(() => timesheets?.filter(item => item.status === EWorkWeekStatus.APPROVED) || [], [timesheets]);

    return (
        <Wrp>
            {isLoading && <LoadingMask/>}

            <Box sx={{width: '100%'}}>

                {/* Empty week for Paused or Pending */}
                {!isLoading && ([EEngagementStatus.PAUSED, EEngagementStatus.PENDING].includes(data.status) || !timesheets?.length) && (

                    <TimesheetWrapper>
                        <WeekEmpty
                            data={{
                                fromDate: data.pause?.startDate,
                                toDate: data.pause?.endDate || '',
                                status: EWorkWeekStatus.DRAFT,
                                money: 0,
                                workDays: [],
                                workTime: ''
                            }}
                            isPaused={data.status === EEngagementStatus.PAUSED}
                            isPending={data.status === EEngagementStatus.PENDING}
                        />
                    </TimesheetWrapper>
                )}

                {/* Recently Submitted */}
                {itemsSubmitted?.length ? (
                    <TimesheetWrapper>
                        <TimesheetStatusTitle status={EWorkWeekStatus.SENT}/>
                        {itemsSubmitted.map(item => <WeekView data={item} key={item.id} isAutoFocus={autoFocusId === item.id}/>)}
                    </TimesheetWrapper>
                ) : null}

                {/* Rejected */}
                {itemsRejected?.length ? (
                    <TimesheetWrapper>
                        <TimesheetStatusTitle status={EWorkWeekStatus.REJECTED}/>
                        {itemsRejected.map(item => <WeekView data={item} key={item.id} isAutoFocus={autoFocusId === item.id}/>)}
                    </TimesheetWrapper>
                ) : null}

                {/* Approved */}
                {itemsApproved?.length ? (
                    <TimesheetWrapper>
                        <TimesheetStatusTitle status={EWorkWeekStatus.APPROVED}/>
                        {itemsApproved.map(item => <WeekView data={item} key={item.id} isAutoFocus={autoFocusId === item.id}/>)}
                    </TimesheetWrapper>
                ) : null}
            </Box>

            {!isLoading && lgUp && (
                <Box
                    sx={{
                        width: '454px',
                        flexShrink: 0,
                        '.details-wrapper': {
                            width: '100%'
                        }
                    }}
                >
                    <ChatDetails
                        data={data}
                        hasMessageButton
                        isStatic
                        isVisible
                    />
                </Box>
            )}
        </Wrp>
    );
};

export default React.memo(MyHiresTimesheets);
