import React, {useEffect, useRef} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Theme, useMediaQuery} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {
    AnimatePageWrp,
    ContinueButton,
    GrayButton,
    LoadingMask,
    PageTopTitle,
    Portal,
    ProjectForm,
    showToastError,
    showToastSuccess,
    WhiteButton
} from '../../../../../../components';
import {BUDGET, CLIENT_ROLES, RouteClientPortal, THANK_YOU} from '../../../../../../constants';
import {useClientRole} from '../../../../../../hooks';
import {ERoleStatusFilter, IProjectRequest} from '../../../../../../models';
import theme from '../../../../../../theme';
import {CLIENT_PROJECT_MAX_LENGTH, clientProjectSchema} from '../../../../../../utils';
import {useRoles} from '../../../../contexts/roles.context';


type ProjectInput = TypeOf<typeof clientProjectSchema>;

export const Project = () => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const queryClient = useQueryClient();
    const {roleId} = useParams();
    const {publishRole} = useRoles();
    const {clientRole, isClientLoading, refetchClient} = useClientRole(Number(roleId));
    const isNeedPublishRef = useRef(false);

    const navigate = useNavigate();

    const nextStep = `${RouteClientPortal.myRoleDetails}/${roleId}/${THANK_YOU}`;
    const prevStep = `${RouteClientPortal.myRoleDetails}/${clientRole?.id}/edit/${BUDGET}`;

    const methods = useForm<ProjectInput>({
        resolver: zodResolver(clientProjectSchema),
    });

    const {
        handleSubmit,
        formState: {isDirty},
        reset,
        setValue,
        watch
    } = methods;

    const projectName = watch('projectName', '');
    const projectDescription = watch('projectDescription', '');

    const isValid = projectName && projectDescription;

    const {mutate, isLoading: isSubmitting} = useMutation(
        (payload: IProjectRequest) => clientService.projectSubmit(clientRole?.id || 0, payload),
        {
            onSuccess() {
                reset({projectName, projectDescription});

                if (isNeedPublishRef.current) {
                    publish();
                } else {
                    showToastSuccess('Project was saved successfully!');
                    refetchClient();
                    queryClient.invalidateQueries({queryKey: [CLIENT_ROLES]});
                }
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const onSubmitHandler: SubmitHandler<ProjectInput> = (values: IProjectRequest) => {
        if (isDirty) {
            mutate({...values, saveAndExit: true} as any); // fix GC-1172
            navigate(`${RouteClientPortal.myRoles}/${ERoleStatusFilter.Drafts}/${roleId}`);
            queryClient.invalidateQueries({queryKey: [CLIENT_ROLES]});
        }
    };

    const onPublishHandler: SubmitHandler<ProjectInput> = (values: IProjectRequest) => {
        if (isDirty) {
            isNeedPublishRef.current = true;
            onSubmitHandler(values);
        } else {
            publish();
        }
    };

    const publish = async () => {
        await publishRole(Number(roleId));
        showToastSuccess('Role was published successfully!');
        isNeedPublishRef.current = false;
        navigate(nextStep);
    };

    useEffect(() => {
        if (!clientRole) {
            return;
        }
        const {projectName, projectDescription} = clientRole;

        reset({projectName, projectDescription});
    }, [clientRole, reset]);

    useEffect(() => {
        if (!projectDescription) return;

        if (projectDescription.length > CLIENT_PROJECT_MAX_LENGTH) {
            setValue('projectDescription', projectDescription.substring(0, CLIENT_PROJECT_MAX_LENGTH));
        }
    }, [projectDescription, setValue]);

    return (
        <AnimatePageWrp maxWidth={{md: 500, lg: 746}}>
            <PageTopTitle
                desktopFontSize={24}
                title="Describe the work your expert will be doing"
                subtitle="Keep it simple and straightforward. We’ll do our best to find you a perfect match for your role"
            />

            {
                isClientLoading
                    ? (
                        <LoadingMask/>
                    ) : (
                        <FormProvider {...methods}>
                            <ProjectForm
                                description={projectDescription}
                                isSubmitting={isSubmitting}
                                max={CLIENT_PROJECT_MAX_LENGTH}
                                name={projectName}
                            />
                        </FormProvider>
                    )
            }

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        mt: '72px',
                        mb: '40px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '16px',
                        flexWrap: 'wrap'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '63px'
                    },
                    '@media(max-height: 900px)': {
                        pb: '60px'
                    },
                    'button': {
                        width: 'auto'
                    }
                }}
                textAlign="center"
            >
                <WhiteButton
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={() => navigate(prevStep)}
                >
                    Back
                </WhiteButton>
                <ContinueButton
                    disabled={!isValid || isClientLoading || isSubmitting}
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit(onPublishHandler)}
                >
                    Publish role
                </ContinueButton>
                <GrayButton
                    disabled={!isValid || !isDirty || isClientLoading || isSubmitting}
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Save & exit
                </GrayButton>
            </Box>
            {
                isSmallScreen && (
                    <>
                        <Portal fullWidth order={1}>
                            <ContinueButton
                                disabled={!isValid || isClientLoading || isSubmitting}
                                variant="contained"
                                sx={{
                                    'div': {
                                        justifyContent: 'center !important'
                                    }
                                }}
                                onClick={handleSubmit(onPublishHandler)}
                            >
                                Publish role
                            </ContinueButton>

                        </Portal>
                        <Portal order={2}>
                            <WhiteButton
                                onClick={() => navigate(prevStep)}
                            >
                                Back
                            </WhiteButton>
                        </Portal>

                        <Portal order={3}>
                            <GrayButton
                                disabled={!isValid || !isDirty || isClientLoading || isSubmitting}
                                variant="contained"
                                onClick={handleSubmit(onSubmitHandler)}
                            >
                                Save & exit
                            </GrayButton>
                        </Portal>
                    </>
                )
            }
        </AnimatePageWrp>
    );
};
