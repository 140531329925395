import React, {FC} from 'react';

import {ERoleStatusFilter} from '../../../../models';
import {Tab, Tabs} from '../../..';


const filterItemsList = [
    ERoleStatusFilter.Active,
    ERoleStatusFilter.Archive
];

interface IProps {
    filterBy: ERoleStatusFilter | null;
    onFilterChange: (e: ERoleStatusFilter) => void;
}

const StatusFilter: FC<IProps> = ({filterBy, onFilterChange}) => {
    return (
        <Tabs>
            {
                filterItemsList.map(item =>
                    <Tab
                        isActive={filterBy === item}
                        key={item}
                        onClick={() => onFilterChange(item)}
                    >
                        {item}
                    </Tab>
                )
            }
        </Tabs>
    );
};

export default React.memo(StatusFilter);
