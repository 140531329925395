import React from 'react';

import {Box, Grid, styled, Typography} from '@mui/material';

import {CheckCircleIcon} from '../../assets/icons';
import theme from '../../theme';

interface IBusinessModelItemProps {
    model: string;
    selected: boolean;
    onClick: () => void;
}

const EmptyCircle = styled('div')<{selected?: boolean}>`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid ${theme.palette.lightGray.main};
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    opacity: ${props => props.selected ? 1 : 0};
    transition: opacity .3s;
  }
`;

const BusinessModelItem: React.FC<IBusinessModelItemProps> = ({model, selected, onClick}): JSX.Element => {
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.white.main,
                border: '2px solid',
                borderColor: selected ? theme.palette.purple.main : theme.palette.lightGray.main,
                borderRadius: '50px',
                cursor: 'pointer',
                padding: '5px 9px',
                width: '94px',
                maxWidth: 'unset',
                transition: 'border-color .3s'
            }}
            onClick={onClick}
            flexGrow={0}
        >
            <Grid
                container
                alignItems="center"
                wrap="nowrap"
            >
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        gap: '12px',
                        alignItems: 'center'
                    }}
                >
                    <EmptyCircle selected={selected}>
                        <CheckCircleIcon/>
                    </EmptyCircle>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16px',
                            userSelect: 'none'
                        }}
                        variant="body1"
                        noWrap
                    >
                        {model}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default React.memo(BusinessModelItem);
