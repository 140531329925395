import React, {FC} from 'react';

import {Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import theme from '../../../theme';

interface IInputCaption {
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
    text?: string;
}

const InputCaption: FC<IInputCaption> = ({children, sx, text}): JSX.Element => {
    return (
        <Typography
            sx={{
                mb: '16px',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '18px',
                'span': {
                    font: 'inherit',
                    lineHeight: 'inherit',
                    color: theme.palette.purple.main
                },
                [theme.breakpoints.up('md')]: {
                    textAlign: 'center',
                    fontSize: '14px',
                    lineHeight: '150%'
                },
                [theme.breakpoints.up('lg')]: {
                    mb: '24px'
                },
                ...sx
            }}
            variant="body1">
            {text || children}
        </Typography>
    );
};

export default InputCaption;
