import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsClient, EngagementInfoBox} from '../../../../../../../components';
import {EEngagementStatus, EResumeStatus, IEngagementClient} from '../../../../../../../models';
import {formatDate} from '../../../../../../../utils';

interface ResumedProps {
    data: IEngagementClient;
}

const Resumed: React.FC<ResumedProps> = ({data}) => {
    if (data.status !== EEngagementStatus.PAUSED || ![EResumeStatus.CLIENT_ACCEPTED, EResumeStatus.FREELANCER_ACCEPTED].includes(data.resume?.resumeStatus as EResumeStatus)) {
        return null;
    }

    return (
        <EngagementInfoBox
            iconUrl="/assets/images/waiting-icon.png"
            title="Engagement is about to be resumed"
        >
            {/* You Initiator */}
            {data.resume?.resumeStatus === EResumeStatus.FREELANCER_ACCEPTED && (
                <>
                    {data.freelancerName.split(' ')[0]} has accepted your engagement resume request.
                        The engagement is set to be resumed on {formatDate(data.resume?.startDate || '')}.
                </>
            )}

            {/* Freelancer Initiator */}
            {data.resume?.resumeStatus === EResumeStatus.CLIENT_ACCEPTED && (
                <>
                        You have accepted {data.clientName.split(' ')[0]}’s engagement resume request.
                        The engagement is set to be resumed on {formatDate(data.resume?.startDate || '')}.
                </>
            )}


            <Box
                sx={{
                    mt: '16px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <EngagementActionsClient.ResumeCancelBtn data={data}/>
            </Box>

        </EngagementInfoBox>
    );
};

export default React.memo(Resumed);
