import React from 'react';

import {Typography} from '@mui/material';

import {RouteAuth} from '../../constants';
import theme from '../../theme';

const TermsAndPrivacyLabel = () => {
    return (
        <Typography
            variant="caption"
            sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '1.6',
                [theme.breakpoints.down('sm')]: {
                    color: theme.palette.white.main

                }
            }}
        >
            I have read and I agree to the <a href={RouteAuth.terms} target="_blank"
                rel="noopener noreferrer nofollow">Terms of
            Use</a> and <a href={RouteAuth.privacyPolicy} target="_blank"
                rel="noopener noreferrer nofollow">Privacy
            Policy</a>
        </Typography>
    );
};

export default React.memo(TermsAndPrivacyLabel);
