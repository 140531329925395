import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';

import {Box} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import {CloseIcon} from '../../assets/icons';
import {EModerationStatus, ITool} from '../../models';
import {equalMemo} from '../../utils/utils';
import {animationChips} from '../Animations';
import {Chip} from '..';


interface IProps {
    items: ITool[];
    sx?: SxProps<Theme>;
    onDelete: (tool: ITool) => void;
}

export const ToolsList: React.FC<IProps> = React.memo(({items, sx, onDelete}) => {
    return (
        <Box
            display="flex"
            flexWrap="wrap"
            sx={sx}
        >
            <AnimatePresence>
                {items.map((tool, i) => (
                    <Box
                        {...animationChips(i)}
                        component={motion.div}
                        key={tool.id || tool.name}
                        onClick={() => onDelete(tool)}
                    >
                        <Chip
                            clickable
                            label={tool.name}
                            deleteIcon={!tool.id ? <CloseIcon/> : undefined}
                            sx={{mb: 2, mr: 2}}
                            variant={tool.id && (!tool.moderationStatus || tool.moderationStatus === EModerationStatus.APPROVED) ? 'active' : 'filled'}
                            onDelete={() => onDelete(tool)}
                        />
                    </Box>
                ))}
            </AnimatePresence>
        </Box>
    );
}, equalMemo());
