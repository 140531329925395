import React, {FC, ReactNode} from 'react';

import {ISelectOption} from '../../models';
import {Tab, Tabs} from '..';

export type TabItem = ISelectOption & { badge?: ReactNode; icon?: ReactNode}

interface ITabsProps {
    items: TabItem[];
    value: string;
    onChange: (value: string) => void;
}

export const TabsStatusFilter: FC<ITabsProps> = React.memo(({items, value, onChange}) => {
    return (
        <Tabs>
            {
                items.map(item =>
                    <Tab
                        isActive={value?.toLowerCase() === item.value.toLowerCase()}
                        key={item.value}
                        onClick={() => onChange(item.value)}
                    >
                        {item.icon}
                        {item.text}
                        {item.badge}
                    </Tab>
                )
            }
        </Tabs>
    );
});
