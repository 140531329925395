import React from 'react';

const CloseIconBig = () => {
    return (
        <div className="close-icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.65579 17.3429C2.70554 17.3927 2.76463 17.4322 2.82967 17.4592C2.8947 17.4861 2.96442 17.5 3.03482 17.5C3.10523 17.5 3.17494 17.4861 3.23998 17.4592C3.30501 17.4322 3.3641 17.3927 3.41386 17.3429L9.99805 10.7593L16.5849 17.3429C16.6855 17.4434 16.8218 17.4999 16.964 17.4999C17.1061 17.4999 17.2425 17.4434 17.343 17.3429C17.4435 17.2424 17.5 17.106 17.5 16.9639C17.5 16.8217 17.4435 16.6854 17.343 16.5849L10.7561 10.0013L17.3403 3.41499C17.4408 3.31447 17.4973 3.17814 17.4973 3.03599C17.4973 2.89383 17.4408 2.7575 17.3403 2.65699C17.2398 2.55647 17.1035 2.5 16.9613 2.5C16.8191 2.5 16.6828 2.55647 16.5823 2.65699L9.99805 9.24328L3.41118 2.65967C3.30869 2.57191 3.17686 2.52605 3.04203 2.53125C2.90719 2.53646 2.77929 2.59235 2.68388 2.68775C2.58847 2.78316 2.53257 2.91105 2.52737 3.04587C2.52216 3.18069 2.56802 3.31251 2.65579 3.41499L9.23999 10.0013L2.65579 16.5876C2.55601 16.6879 2.5 16.8237 2.5 16.9652C2.5 17.1067 2.55601 17.2425 2.65579 17.3429Z"
                    fill="#121416"/>
            </svg>
        </div>
    );
};

export default CloseIconBig;
