import React, {SyntheticEvent} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {Box} from '@mui/material';

import {
    CancelledStatusIcon,
    CheckStatusIcon,
    PausedWorkStatusIcon,
    PencilOutlineIcon,
    ReloadStatusIcon
} from '../../../../../assets/icons';
import {RouteClientPortal} from '../../../../../constants';
import {ERoleDisplayStatus, ERoleStatus} from '../../../../../models';
import theme from '../../../../../theme';

type RoleStatusProps = {
    engagementId?: number;
    status: ERoleStatus;
};

const RoleStatus: React.FC<RoleStatusProps> = ({engagementId, status}) => {
    const location = useLocation();
    const navigate = useNavigate();

    // 1. Matching - the user is redirected to the 'My Matches tab' where he sees the role card
    // 2. Engagement Started - upon clicking the user is redirected to Engagement detailed page (TBD)
    // 3. Engagement Pending - status should be shown to the Client only when proposal signed with start date in the future
    // 4. Cancelled - 'soft deletion'. The role is not active anymore.

    // display status is not clickable for SUBMITTED, MATCHING_IN_PROGRESS and MATCHING_FINISHED

    const handleClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        switch (status) {
            case ERoleStatus.EngagementActive:
            case ERoleStatus.EngagementPaused:
            case ERoleStatus.EngagementPending:
            case ERoleStatus.Closed: {
                const state = {redirectBack: location.pathname};

                navigate(`${RouteClientPortal.myHireDetails}/${engagementId}`, {state});
                break;
            }
            case ERoleStatus.InReview: {
                navigate(RouteClientPortal.myMatches);
                break;
            }
            default:
                break;
        }
    };

    const renderIcon = (status: ERoleStatus | null) => {
        switch (status) {
            case ERoleStatus.Cancelled:
            case ERoleStatus.Closed:
                return <CancelledStatusIcon/>;
            case ERoleStatus.Draft:
                return <PencilOutlineIcon/>;
            case ERoleStatus.EngagementPaused:
                return <PausedWorkStatusIcon/>;
            case ERoleStatus.EngagementPending:
            case ERoleStatus.InReview:
            case ERoleStatus.MatchingFinished:
            case ERoleStatus.MatchingInProgress:
                return <ReloadStatusIcon/>;
            case ERoleStatus.EngagementActive:
            case ERoleStatus.MatchingFinished:
            case ERoleStatus.Submitted:
                return <CheckStatusIcon/>;
            default:
                return <></>;
        }
    };

    const getStyle = (status: ERoleStatus | null) => {
        switch (status) {
            case ERoleStatus.Cancelled:
            case ERoleStatus.Closed:
                return 'cancelled';
            case ERoleStatus.Draft:
            case ERoleStatus.EngagementPaused:
                return 'draft';
            case ERoleStatus.EngagementPending:
            case ERoleStatus.InReview:
            case ERoleStatus.MatchingFinished:
            case ERoleStatus.MatchingInProgress:
                return 'matching';
            case ERoleStatus.EngagementActive:
            case ERoleStatus.MatchingFinished:
            case ERoleStatus.Submitted:
                return 'engagement';
            default:
                return '';
        }
    };

    return (
        <Box
            className={getStyle(status)}
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: '8px',
                padding: '10px 12px',
                borderRadius: '50px',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '20px',
                '&.cancelled': {
                    color: theme.palette.error.main,
                    backgroundColor: theme.palette.lightRed.main
                },
                '&.draft': {
                    color: theme.palette.textGray.dark,
                    backgroundColor: theme.palette.lightGray.main
                },
                '&.matching': {
                    color: theme.palette.blue.dark,
                    backgroundColor: theme.palette.blue.light
                },
                '&.engagement': {
                    color: theme.palette.darkGreen.main,
                    backgroundColor: theme.palette.green.light
                },
                'svg': {
                    width: '16px',
                    height: '16px'
                }
            }}
            onClick={handleClick}
        >
            {renderIcon(status)}
            {ERoleDisplayStatus[status]}
        </Box>
    );
};

export default React.memo(RoleStatus);
