import React, {CSSProperties, FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {motion} from 'framer-motion';

import {Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {DownChevronIcon, IdeaIcon, TargetIcon, TextIcon} from '../../assets/icons';
import {MD} from '../../constants';
import {ICaseStudy, ICoverImage} from '../../models';
import theme from '../../theme';
import {getCoverImagePath} from '../../utils';
import {WhiteSquareButton} from '../Button';
import {CaseStudyAnimatedGadient, ImagePlaceholder} from '../index';

import {COVER_IMAGES} from './config';

const TextWithTitleIcon = styled(motion.div)`
  margin-bottom: 42px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${MD}) {
    margin-bottom: 80px;
  }

  p {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;

    @media (min-width: ${MD}) {
      gap: 24px;
      margin-bottom: 32px;
    }
  }

  svg {
    width: 32px;
    height: 32px;

    @media (min-width: ${MD}) {
      width: 48px;
      height: 48px;
    }
  }

  span {
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    @media (min-width: ${MD}) {
      font-size: 18px;
    }
  }
`;

const OutcomeItem = styled(motion.div)`
  width: 280px;

  span {
    display: block;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: ${theme.palette.purple.main};
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }

  @media (min-width: ${MD}) {
    gap: 128px;

    span {
      font-size: 64px;
      line-height: 130%;
      letter-spacing: -0.02em;
    }
  }
`;

const stylesCoverWrapper = {
    position: 'relative',
    mb: '64px',
    aspectRatio: '335 / 200',
    borderRadius: '24px',
    [theme.breakpoints.up('md')]: {
        mb: '104px',
        borderRadius: '64px',
        aspectRatio: '1632 / 800'
    }
} as CSSProperties;

interface IProps {
    data: ICaseStudy;
}

const CaseStudyPreview: FC<IProps> = ({data}) => {
    const [isLoading, setIsLoading] = useState(true);   // cover image too big. cache it before starting to show

    const [isScrollBtn, setIsScrollBtn] = useState(false);
    const contentRef = useRef(null);
    const buttonRef = useRef(null);
    const summaryRef = useRef<HTMLDivElement | null>(null);

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const xsUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));

    const coverImageData = useMemo(() => {
        const value: ICoverImage = data?.coverImage ? JSON.parse(data.coverImage) : {};

        return {
            bgColor: COVER_IMAGES.find(it => it.name === value.name)?.value,
            theme: value.name,
            url: getCoverImagePath(value.name, value.variant || 1, {lgUp, mdUp, xsUp})
        };
    }, [data, lgUp, mdUp, xsUp]);

    const handleScroll = useCallback(() => {
        const bottomPosition = contentRef.current && (contentRef.current as HTMLElement).getBoundingClientRect().bottom || 0;
        const viewPortHeight = window.innerHeight;

        if ((bottomPosition - viewPortHeight) < 50) {
            setIsScrollBtn(false);
        } else {
            setIsScrollBtn(true);
        }
    }, []);

    // Wrong screen position when opening freelancer detailed profile second time
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (data?.summary) {
            window.addEventListener('scroll', handleScroll, {passive: true});
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [data, handleScroll]);

    useEffect(() => {
        if (!coverImageData?.url) return;

        const image = new Image();
        const loaded = () => setIsLoading(false);

        setIsLoading(true);
        image.addEventListener('load', loaded);
        image.src = coverImageData?.url;

        return () => image.removeEventListener('load', loaded);
    }, [coverImageData]);

    if (!data) {
        return null;
    }

    return (
        <Box
            sx={{
                mt: '40px',
                mb: '200px',
                scrollBehaviour: 'smooth',
                [theme.breakpoints.up('md')]: {
                    mt: '80px'
                }
            }}
        >

            <Box
                sx={{
                    m: '0 auto 40px',
                    maxWidth: '1000px',
                    textAlign: 'center',
                    [theme.breakpoints.up('md')]: {
                        mb: '64px'
                    }
                }}
            >
                <Typography
                    sx={{
                        mb: '24px',
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: 1.5,
                        [theme.breakpoints.up('md')]: {
                            fontSize: '45px',
                            lineHeight: 1.3
                        }
                    }}
                >
                    {data.name}
                </Typography>
                <Typography
                    sx={{
                        mb: '24px',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: 1.5,
                        color: theme.palette.textGray.dark,
                        [theme.breakpoints.up('md')]: {
                            mb: '40px'
                        }
                    }}
                >
                    {data.brief}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '16px'
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '13px',
                            color: theme.palette.gray.main
                        }}
                    >
                        Case study made for:
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px'
                        }}
                    >
                        <ImagePlaceholder width={mdUp ? '48' : '32'} height={mdUp ? '48' : '32'}>
                            {data?.companyLogoUrl && (
                                <img
                                    src={data.companyLogoUrl}
                                    width={mdUp ? '48' : '32'}
                                    height={mdUp ? '48' : '32'}
                                    alt="Figma"
                                />
                            )}
                        </ImagePlaceholder>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                            }}
                        >
                            {data.companyName}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Cover image is big so download it silently and then show */}
            {!isLoading ? (
                <Box
                    component={motion.div}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{
                        delay: 0.1,
                        duration: 0.5,
                        ease: 'easeInOut',
                    }}
                    sx={{
                        ...stylesCoverWrapper,
                        backgroundColor: coverImageData?.bgColor || 'transparent',
                        backgroundImage: `url(${coverImageData.url})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <CaseStudyAnimatedGadient
                        borderRadius={mdUp ? '64px' : '24px'}
                        themeName={coverImageData ? coverImageData.theme : ''}
                    />
                </Box>
            ) : (
                <Box sx={stylesCoverWrapper}/>
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '56px',
                    pb: '64px',
                    borderBottom: (!data.clientsReview && !data.clientsName && !data.clientsPosition) ? `1px solid ${theme.palette.lightGray.main}` : 'none',
                    [theme.breakpoints.up('md')]: {
                        pb: '104px',
                    },
                    [theme.breakpoints.up('lg')]: {
                        gap: '128px',
                        flexWrap: 'nowrap'
                    }
                }}
            >
                {data.outcomes.map((item, index) => (
                    <OutcomeItem
                        initial={{opacity: 0, scale: 0.9}}
                        whileInView={{opacity: 1, scale: 1}}
                        viewport={{once: true}}
                        transition={{
                            delay: 0.5 * index,
                            duration: 1,
                            ease: 'easeOut',
                        }}
                        key={index}
                    >
                        <span>
                            {item.value}
                        </span>
                        <p>
                            {item.description}
                        </p>
                    </OutcomeItem>
                ))}
            </Box>

            {data.clientsReview && data.clientsName && data.clientsPosition && (
                <Box
                    component={motion.div}
                    initial={{opacity: 0, scale: 0.9}}
                    whileInView={{opacity: 1, scale: 1}}
                    viewport={{once: true}}
                    transition={{
                        duration: 1,
                        ease: 'easeOut',
                    }}
                    sx={{
                        borderTop: `1px solid ${theme.palette.lightGray.main}`,
                        borderBottom: `1px solid ${theme.palette.lightGray.main}`,
                        pt: '40px',
                        pb: '40px',
                        [theme.breakpoints.up('md')]: {
                            pt: '80px',
                            pb: '80px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '800px',
                            m: '0 auto'
                        }}
                    >
                        <Typography
                            component="q"
                            sx={{
                                mb: '16px',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: 1.5,
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '24px',
                                    lineHeight: 1.65,
                                }
                            }}
                        >
                            {data.clientsReview}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: 1.5,
                                color: theme.palette.blue.dark
                            }}
                        >
                            {data.clientsName}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: 1.5,
                                color: theme.palette.gray.main
                            }}
                        >
                            {data.clientsPosition}
                        </Typography>
                    </Box>
                </Box>
            )}

            <Box
                ref={contentRef}
                sx={{
                    position: 'relative',
                    pt: '40px',
                    [theme.breakpoints.up('md')]: {
                        pt: '80px'
                    }
                }}
            >
                <Box
                    ref={buttonRef}
                    sx={{
                        display: isScrollBtn ? 'block' : 'none',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: 'auto',
                        height: '100%',
                        pt: '40px',
                        pointerEvents: 'none',
                        [theme.breakpoints.up('md')]: {
                            pt: '80px',
                        }
                    }}
                >
                    <Box
                        sx={{
                            position: 'sticky',
                            top: 'calc(100vh - 220px)',
                            right: 0,
                            height: 'max-content',
                            pointerEvents: 'auto'
                        }}
                    >
                        <WhiteSquareButton
                            onClick={() => summaryRef.current?.focus()}
                        >
                            {
                                mdUp ? 'Scroll down to summary' : 'Summary'
                            }
                            <DownChevronIcon/>
                        </WhiteSquareButton>
                    </Box>
                </Box>
                <Box
                    sx={{
                        m: '0 auto 40px',
                        maxWidth: '778px',
                        [theme.breakpoints.up('md')]: {
                            mb: '80px'
                        }
                    }}
                >
                    <TextWithTitleIcon
                        initial={{opacity: 0}}
                        whileInView={{opacity: 1}}
                        viewport={{once: true}}
                        transition={{
                            delay: 0.5,
                            duration: 1,
                            ease: 'easeOut',
                        }}
                    >
                        <p>
                            <TargetIcon/>
                            Challenge
                        </p>
                        <span>
                            {data.challenge}
                        </span>
                    </TextWithTitleIcon>

                    <TextWithTitleIcon
                        initial={{opacity: 0}}
                        whileInView={{opacity: 1}}
                        viewport={{once: true}}
                        transition={{
                            delay: 0.5,
                            duration: 1,
                            ease: 'easeOut',
                        }}
                    >
                        <p>
                            <IdeaIcon/>
                            Solution
                        </p>
                        <span>
                            {data.solution}
                        </span>
                    </TextWithTitleIcon>

                </Box>
            </Box>

            <Box
                component={motion.div}
                id="#summary"
                initial={{opacity: 0}}
                whileInView={{opacity: 1}}
                viewport={{once: true}}
                transition={{
                    delay: 0.5,
                    duration: 1,
                    ease: 'easeOut',
                }}
                sx={{
                    m: '0 auto',
                    maxWidth: '778px',
                    display: data.summary ? 'block' : 'none'
                }}
            >
                <Box
                    sx={{
                        p: '24px 43px 24px 24px',
                        backgroundColor: theme.palette.white.main,
                        borderRadius: '16px',
                        [theme.breakpoints.up('md')]: {
                            p: '40px 24px'
                        }
                    }}
                >
                    <TextWithTitleIcon ref={summaryRef} tabIndex={0}>
                        <p>
                            <TextIcon/>
                            Summary
                        </p>
                        <span>
                            {data.summary}
                        </span>
                    </TextWithTitleIcon>
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(CaseStudyPreview);
