import React, {FC, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {styled} from '@mui/material';

import {RouteClientPortal} from '../../../../constants';
import {IClientRole} from '../../../../models';

import {RoleItem} from './RoleItem';

const RolesListWrp = styled('div')`
  display: flex;
  gap: 24px;
  width: 100%;
`;

const RolesItemList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-width: 0;
  transition: width .3s;
`;

interface IProps {
    items: IClientRole[];
}

const RoleList: FC<IProps> = ({items}) => {
    const {filterBy} = useParams();

    const navigate = useNavigate();

    const handleRoleItemClick = useCallback((roleId: number) => {
        navigate(`${RouteClientPortal.myRoles}/${filterBy}/${roleId}`);
    }, [filterBy, navigate]);

    return (
        <RolesListWrp>
            <RolesItemList>

                {items?.map((item, index) => (
                    <RoleItem
                        key={item.id}
                        index={index}
                        item={item}
                        onClick={handleRoleItemClick}
                    />
                ))}

            </RolesItemList>
        </RolesListWrp>
    );
};

export default React.memo(RoleList);
