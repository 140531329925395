import {COMPLETED, RouteWidget} from '../../../../constants';
import {IClientRole} from '../../../../models';

export const widgetStepsList = [
    {
        title: 'Channels',
        link: RouteWidget.channels
    },
    {
        title: 'Verticals',
        link: RouteWidget.verticals
    },
    {
        title: 'Tools',
        link: RouteWidget.tools
    },
    {
        title: 'Country & Time Zone',
        link: RouteWidget.location
    },
    {
        title: 'Engagement Type',
        link: RouteWidget.budget
    },
    {
        title: 'Describe Your Project',
        link: RouteWidget.project
    },
] as const;

export const stepsOrderWidget = [
    RouteWidget.channels,
    RouteWidget.verticals,
    RouteWidget.tools,
    RouteWidget.location,
    RouteWidget.budget,
    RouteWidget.project,
    RouteWidget.signup,
    RouteWidget.confirmationCode,
    RouteWidget.completed
] as const;

export const getStep = (clientRole: IClientRole) => {
    if (clientRole.view === COMPLETED) return RouteWidget.confirmationCode;
    if (clientRole.projectName) return RouteWidget.signup;
    if (clientRole.workType) return RouteWidget.project;
    if (clientRole.timezone) return RouteWidget.budget;
    if (clientRole.tools) return RouteWidget.location;
    if (clientRole.verticals) return RouteWidget.tools;
    if (clientRole.channel) return RouteWidget.verticals;

    return RouteWidget.channels;
};
