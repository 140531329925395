import React, {SyntheticEvent, useRef} from 'react';
import {motion} from 'framer-motion';
import {useController, useFormContext} from 'react-hook-form';

import {Grid, Theme, useMediaQuery} from '@mui/material';

import {DeleteIcon} from '../../../../../assets/icons';
import theme from '../../../../../theme';
import {IconButton} from '../../../../Button';
import {Input} from '../../../../Forms';
import {InputCaption, InputSubCaption} from '../styles';

const MAX_LENGTH = 6;

type OutcomeProps = {
    disabled: boolean;
    index: number;
    isDeletable: boolean;
    name: string;
    onDelete: (index: number) => void;
};

const Outcome: React.FC<OutcomeProps> = ({disabled, index, isDeletable, name, onDelete}) => {
    const {
        control,
    } = useFormContext();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const descriptionRef = useRef<HTMLInputElement | null>(null);

    const {field: {value: description}} = useController({control, name: `${name}.description`});
    const {field: {value, onChange}} = useController({control, name: `${name}.value`});

    const correctValue = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        let key = (event as any).key;
        let value = target.value;

        let typeKey;

        // +$1m 35% 10X
        // allow digits, special synbols and 'X'
        if (/(\d|[xX%$&+,:;=?@#|'<>.^*()%!-]|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
            if (/(\d)/.test(key)) {
                typeKey = 'number';
            } else {
                typeKey = 'specSymbol';
            }

            const sel = window.getSelection()?.toString();

            if (sel) {
                if (typeKey !== 'specSymbol') {
                    value = value.replace(sel, key);
                    key = '';
                    // by Backspace|Delete erase all
                } else {
                    value = '';
                    target.value = '';
                    event.preventDefault();
                    onChange('');

                    return;
                }


                // ✅ Clear text selection
                window.getSelection()?.removeAllRanges();
            }

            // if 8h then move to the minutes
            if (value.length > 0 && value.length < MAX_LENGTH && (key === '%' || key.toLowerCase() === 'x')) {
                target.value += key;
                event.preventDefault();
                onChange(target.value);
                descriptionRef.current?.focus();

                return;
            }
        } else {
            event.preventDefault();

            return;
        }
    };

    return (
        <motion.div
            layout
        >
            <Grid
                container
                spacing={mdUp ? '48px' : '24px'}
                sx={{
                    mb: '10px',
                    [theme.breakpoints.up('md')]: {
                        mb: '30px'
                    }
                }}
            >
                <Grid item xs={12}>
                    <InputCaption>Outcome #{index + 1}</InputCaption>
                    <InputSubCaption
                        sx={{
                            pb: 0,
                            [theme.breakpoints.up('md')]: {
                                mb: '-24px'
                            }
                        }}
                    >
                        Example: {index % 2 === 0 ? '10% increase in sales' : '2x doubled reply rates'}
                    </InputSubCaption>
                </Grid>
                <Grid item xs={6}>
                    <Input
                        disabled={disabled}
                        counter={MAX_LENGTH - (value.length || 0)}
                        inputProps={{maxLength: MAX_LENGTH}}
                        name={`${name}.value`}
                        placeholder="% or x (optional)"
                        onKeyDown={correctValue}
                    />
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                    <Input
                        disabled={disabled}
                        counter={100 - (description.length || 0)}
                        inputProps={{maxLength: 100}}
                        name={`${name}.description`}
                        placeholder="Title of the outcome"
                        ref={descriptionRef}
                    />
                    {isDeletable && (
                        <IconButton
                            icon={<DeleteIcon/>}
                            sx={{
                                ml: 2,
                                'path': {
                                    fill: theme.palette.black.main,
                                    transition: 'fill .3s'
                                },
                                '&:hover': {
                                    'path': {
                                        fill: theme.palette.error.main
                                    }
                                }
                            }}
                            onClick={() => onDelete(index)}
                        />
                    )}
                </Grid>
            </Grid>
        </motion.div>
    );
};

export default React.memo(Outcome);
