import React, {useCallback, useEffect, useState} from 'react';

import {Box, IconButton, Theme} from '@mui/material';
import {SxProps} from '@mui/system';

import {ArrowDown} from '../../assets/icons';
import theme from '../../theme';
import {AnimateHeight} from '../AnimateHeight/AnimateHeight';


interface IProps {
    children: React.ReactNode;
    hasCollapse?: boolean;
    isCollapsed?: boolean;
    titleContent: React.ReactNode;
    sx?: SxProps<Theme>;
    onCollapseClick?: (isCollapsed: boolean) => void;
}

const CollapsibleBox: React.FC<IProps> = ({
    children,
    hasCollapse = true,
    isCollapsed,
    titleContent,
    sx,
    onCollapseClick
}) => {
    const [collapsed, setCollapsed] = useState(true);

    const handleCollapseClick = useCallback(() => {
        if (onCollapseClick) {
            onCollapseClick(!collapsed);
        }
        setCollapsed(!collapsed);
    }, [collapsed, onCollapseClick]);

    useEffect(() => {
        setCollapsed(!!isCollapsed);
    }, [isCollapsed]);

    return (
        <Box
            sx={{
                width: '100%',
                p: '24px',
                mb: '16px',
                backgroundColor: theme.palette.white.main,
                borderRadius: '24px',
                boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                '&:last-of-type': {
                    mb: 0
                },
                ...sx
            }}
        >
            <Box
                className="title-content-box"
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    rowGap: '8px',
                    columnGap: '16px',
                    cursor: hasCollapse ? 'pointer' : null,
                    [theme.breakpoints.up(850)]: {
                        gap: '16px'
                    }
                }}
                onClick={() => hasCollapse && handleCollapseClick()}
            >
                {titleContent}
                {hasCollapse && (
                    <Box
                        sx={{
                            order: 2,
                            flexShrink: 0,
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            [theme.breakpoints.up(850)]: {
                                order: 5,
                                flexGrow: 'unset'
                            }
                        }}
                    >
                        <IconButton
                            sx={{
                                mr: '-8px',
                                'svg': {
                                    transform: collapsed ? 'rotate(-90deg)' : 'rotate(0deg)',
                                    transition: 'transform .3s'
                                }
                            }}
                            onClick={handleCollapseClick}
                        >
                            <ArrowDown/>
                        </IconButton>
                    </Box>
                )}
            </Box>

            {hasCollapse ? (
                <AnimateHeight isVisible={!collapsed}>
                    {children}
                </AnimateHeight>
            ): children}

        </Box>
    );
};

export default React.memo(CollapsibleBox);
