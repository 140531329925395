import React from 'react';

import MUIAvatar from '@mui/material/Avatar';

interface Props {
    url: string;
}

const Avatar: React.FC<Props> = ({url}) => {
    if (!url) return null;

    return (
        <MUIAvatar
            alt="brand logo"
            sx={{
                '& .MuiAvatar-img': {objectFit: 'contain'},
                mr: 2,
                width: 25,
                height: 25
            }}
            src={url}
        />
    );
};

export default React.memo(Avatar);
