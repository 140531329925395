import React, {useEffect} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm, useWatch} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Stack, Theme, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {useAuth} from '../../../../hooks';
import {ECaseStudySteps} from '../../../../models';
import theme from '../../../../theme';
import {
    CASE_STUDY_MAX_TEXT_LENGTH,
    CASE_STUDY_SHORT_SUMMARY_MAX_TEXT_LENGTH,
    challengeSchema,
    getTextLength
} from '../../../../utils';
import {
    AnimatePageWrp,
    ContinueButton,
    GrayButton,
    Input,
    Portal,
    showToastError,
    showToastSuccess,
    useCaseStudy,
    WhiteButton,
} from '../../..';

import {InputCaption, InputSubCaption} from './styles';

type ChallengeInput = TypeOf<typeof challengeSchema>;

export const Step3Challenge = () => {
    const {isImpersonal} = useAuth();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const {state: {data, cancelUrl, isCanBePublished}, onRefetch, publish, updateCaseStudy} = useCaseStudy();

    const location = useLocation();
    const navigate = useNavigate();

    let nextStep = location.pathname.replace(ECaseStudySteps.challenge, ECaseStudySteps.outcomes);
    const prevStep = location.pathname.replace(ECaseStudySteps.challenge, ECaseStudySteps.company);

    const methods = useForm<ChallengeInput>({
        resolver: zodResolver(challengeSchema),
    });

    const {
        control,
        formState: {isDirty, isValid},
        handleSubmit,
        getValues,
        reset,
    } = methods;

    const {challenge, solution, summary} = useWatch({control});

    const {mutate, isLoading: isSubmitting} = useMutation(
        (values: ChallengeInput) => updateCaseStudy({...data?.draftVersion, ...values, id: data?.id}),
        {
            onSuccess(response: any) {
                showToastSuccess('Challenge, Solution & Process were saved successfully!');

                if (nextStep === 'publish') {
                    publish(response.data.id);
                } else {
                    navigate(nextStep);
                    onRefetch();
                }
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handlePublishClick = () => {
        const values = getValues();

        nextStep = 'publish';
        mutate(values);
    };

    const handleSaveAndExit = () => {
        const values = getValues();

        nextStep = cancelUrl;
        mutate(values);
    };

    const onSubmitHandler: SubmitHandler<ChallengeInput> = (values: ChallengeInput) => {
        if (isDirty && !isImpersonal) {
            mutate(values);
        } else {
            navigate(nextStep);
        }
    };

    useEffect(() => {
        if (!data) return;

        const {challenge, solution, summary} = data.draftVersion;

        reset({challenge, solution, summary});
    }, [data, reset]);

    return (
        <AnimatePageWrp>

            <FormProvider {...methods}>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <Stack
                        direction="column"
                    >
                        <Box sx={{mb: '40px'}}>
                            <InputCaption>What challenge did you solve for the client company?</InputCaption>
                            <InputSubCaption sx={{pb: '24px'}}>
                                Describe the challenges or pain points the subject was experiencing that led you to seek
                                a solution
                            </InputSubCaption>
                            <Input
                                autoFocus={!challenge}
                                disabled={isSubmitting}
                                name="challenge"
                                counter={CASE_STUDY_MAX_TEXT_LENGTH - getTextLength(challenge || '')}
                                inputProps={{maxLength: CASE_STUDY_MAX_TEXT_LENGTH}}
                                isResizableY
                                minRows={5}
                                multiline
                                placeholder="Describe the challenge here"
                                variant="outlined"
                            />
                        </Box>

                        <Box sx={{mb: '40px !important'}}>
                            <InputCaption>What solution did you come up with?</InputCaption>
                            <InputSubCaption sx={{pb: '24px'}}>
                                Explain how your product or service helped the subject overcome their challenges and
                                provide
                                specific details about the solution you provided
                            </InputSubCaption>
                            <Input
                                disabled={isSubmitting}
                                name="solution"
                                counter={CASE_STUDY_MAX_TEXT_LENGTH - getTextLength(solution || '')}
                                inputProps={{maxLength: CASE_STUDY_MAX_TEXT_LENGTH}}
                                isResizableY
                                minRows={5}
                                multiline
                                placeholder="Describe the solution here"
                                variant="outlined"
                            />
                        </Box>

                        <Box sx={{mb: '40px !important'}}>
                            <InputCaption sx={{pb: '24px'}}>Add a short summary (optional)</InputCaption>
                            <Input
                                disabled={isSubmitting}
                                name="summary"
                                counter={CASE_STUDY_SHORT_SUMMARY_MAX_TEXT_LENGTH - getTextLength(summary || '')}
                                inputProps={{maxLength: CASE_STUDY_SHORT_SUMMARY_MAX_TEXT_LENGTH}}
                                isResizableY
                                minRows={5}
                                multiline
                                placeholder="Describe the summary here"
                                variant="outlined"
                            />
                        </Box>
                    </Stack>
                </form>
            </FormProvider>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '16px',
                        flexWrap: 'wrap'
                    },
                    'button': {
                        width: 'auto'
                    }
                }}
                textAlign="center"
            >
                <WhiteButton
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={() => navigate(prevStep)}
                >
                    Back
                </WhiteButton>
                <ContinueButton
                    disabled={isSubmitting}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Continue
                </ContinueButton>
                <GrayButton
                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSaveAndExit}
                >
                    Save & exit
                </GrayButton>
                {isCanBePublished && (
                    <GrayButton
                        disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                display: 'none'
                            }
                        }}
                        variant="contained"
                        onClick={handlePublishClick}
                    >
                        Publish
                    </GrayButton>
                )}
            </Box>
            {
                isSmallScreen && (
                    <>
                        <Portal fullWidth order={1} innerPages>
                            <ContinueButton
                                disabled={isSubmitting}
                                hasArrow
                                variant="contained"
                                sx={{
                                    'div': {
                                        justifyContent: 'center !important'
                                    }
                                }}
                                onClick={handleSubmit(onSubmitHandler)}
                            >
                                Continue
                            </ContinueButton>

                        </Portal>

                        <Portal fullWidth order={2} innerPages>
                            <WhiteButton
                                onClick={() => navigate(prevStep)}
                            >
                                Back
                            </WhiteButton>
                        </Portal>

                        <Portal fullWidth={!isCanBePublished} order={3} innerPages>
                            <GrayButton
                                disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                fullWidth
                                variant="contained"
                                onClick={handleSaveAndExit}
                            >
                                Save & exit
                            </GrayButton>
                        </Portal>

                        {isCanBePublished && (
                            <Portal order={4} innerPages>
                                <GrayButton
                                    disabled={!isDirty || !isValid || isSubmitting || isImpersonal}
                                    variant="contained"
                                    onClick={handlePublishClick}
                                >
                                    Publish
                                </GrayButton>
                            </Portal>
                        )}
                    </>
                )
            }
        </AnimatePageWrp>
    );
};
