import React, {useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import orderBy from 'lodash/orderBy';
import {Link} from 'react-router-dom';

import {Box} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import {useQuery} from '@tanstack/react-query';

import {clientService} from '../../../../../api';
import {
    BlackBorderButton,
    CardSubTitle,
    CardTitle,
    GradientBorderBox,
    PaymentMethodsList,
    showToastError
} from '../../../../../components';
import {CLIENT_PAYMENTS, RouteClientPortal} from '../../../../../constants';
import {useAuth} from '../../../../../hooks';
import {EMailType, EPaymentMethod, IPaymentPreview} from '../../../../../models';
import theme from '../../../../../theme';

import BillingContactForm from './components/BillingContactForm';
import {DialogPaymentMethod} from './components';


export const BillingTab = React.memo(() => {
    const {isImpersonal} = useAuth();
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false);

    const {
        isLoading,
        data: paymentMethods,
        refetch,
    } = useQuery([CLIENT_PAYMENTS], clientService.paymentMethods, {
        refetchOnWindowFocus: true,
        select: ((response: {
            data: { paymentMethods: IPaymentPreview[] };
        }) => orderBy(response.data.paymentMethods, ['isPrimary'], 'desc')),
        onError: (error) => showToastError(error as AxiosError)
    });

    const handleClose = (isNeedUpdate: boolean) => {
        setOpenPaymentMethod(false);

        if (isNeedUpdate) {
            refetch();
        }
    };

    const paymentMethodsWithoutInvoices = useMemo(() => paymentMethods?.filter(it => it.method !== EPaymentMethod.ManualInvoicing), [paymentMethods]);

    return (
        <Box>
            <Box sx={{maxWidth: 620}}>
                <Typography
                    sx={{
                        mt: '32px',
                        mb: '20px',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: 1.5,
                        [theme.breakpoints.up('md')]: {
                            mt: 0,
                            mb: '32px',
                            fontSize: '16px'
                        }
                    }}
                >
                    Billing
                </Typography>

                <Box>
                    <CardTitle>Payment method</CardTitle>
                    <CardSubTitle sx={{mb: '16px'}}>
                        Add a new payment method or change the current one
                    </CardSubTitle>

                    <PaymentMethodsList
                        isLoading={isLoading}
                        items={paymentMethods}
                    />

                    {paymentMethodsWithoutInvoices?.length ? (
                        <GradientBorderBox
                            sx={{
                                mb: '16px',
                                'img': {
                                    display: 'inline-block',
                                    mb: '16px'
                                }
                            }}
                        >
                            <img src="/assets/images/bank-card-icon.png" width="48" height="48" alt="bank card icon"/>
                            <CardTitle
                                sx={{
                                    mb: '4px'
                                }}
                            >
                                Add one more payment method to increase transaction success rates
                            </CardTitle>
                            <CardSubTitle
                                sx={{
                                    mb: '16px'
                                }}
                            >
                                Enhance your payment options by adding one more method. Enjoy added convenience and
                                flexibility. Stay prepared for seamless transactions.
                            </CardSubTitle>
                            <BlackBorderButton
                                disabled={isImpersonal}
                                size="small"
                                onClick={() => setOpenPaymentMethod(true)}
                            >
                                + Add payment method
                            </BlackBorderButton>
                        </GradientBorderBox>
                    ) : (
                        <BlackBorderButton
                            disabled={isImpersonal}
                            size="small"
                            sx={{mb: 2}}
                            onClick={() => setOpenPaymentMethod(true)}
                        >
                            + Add payment method
                        </BlackBorderButton>
                    )}

                </Box>

                <CardSubTitle sx={{mb: 4, fontSize: 12}}>
                    Want to arrange to pay another way? Please <Link to={RouteClientPortal.contactUs}
                        state={{data: {type: EMailType.PAYMENT_METHOD}}}>contact
                    us</Link> to discuss.
                </CardSubTitle>

                <BillingContactForm/>
            </Box>

            <DialogPaymentMethod
                open={openPaymentMethod}
                onClose={handleClose}
            />
        </Box>
    );
});
