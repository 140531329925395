import React, {FC, useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {Reorder} from 'framer-motion';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {styled} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../api';
import {EmptyEngagementListItem, EngagementListItem, showToastError} from '../../../../../components';
import {CLIENT_ENGAGEMENT, MD, RouteClientPortal} from '../../../../../constants';
import {EEngagementFilter, IEngagement} from '../../../../../models';

const ListWrp = styled('div')`
  display: flex;
  gap: 24px;
  width: 100%;

  @media (min-width: ${MD}) {
    margin-top: 24px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const AnimatedList = styled(Reorder.Group)`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  min-width: 0;
  transition: width .3s;

  @media (min-width: ${MD}) {
    gap: 24px;
  }
`;

interface IHiresListProps {
    items: IEngagement[];
}

const HiresList: FC<IHiresListProps> = (props) => {
    const {filterBy} = useParams();
    const [items, setItems] = useState(props.items);    // used for animation

    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {mutate, isLoading: isSubmitting} = useMutation(clientService.togglePin,
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries({queryKey: [CLIENT_ENGAGEMENT]});
            },
            onError(error: AxiosError) {
                console.log('error', error);
                showToastError(error);
            }
        }
    );

    const handleDetailsClick = useCallback((item: IEngagement) => {
        navigate(`${RouteClientPortal.myHireDetails}/${item.id}`, {state: {redirectBack: location.pathname}});
    }, [location.pathname, navigate]);

    const handleMessageClick = useCallback((item: IEngagement) => {
        navigate(`${RouteClientPortal.myHireDetails}/${item.id}/messages`);
    }, [navigate]);

    // Unpin all before Pin new one
    const handlePinClick = useCallback(async (item: IEngagement) => {
        mutate(item.id);
    }, [mutate]);

    // by pagination update state
    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    return (
        <ListWrp>
            <AnimatedList values={items} onReorder={setItems}>
                {items.map((item) => (
                    <EngagementListItem
                        data={item}
                        isClientPortal
                        isSubmitting={isSubmitting}
                        key={item.id}
                        value={item.id} // used for animation
                        onDetails={handleDetailsClick}
                        onMessage={handleMessageClick}
                        onTogglePin={handlePinClick}
                    />
                ))}

                {/* Show Next emoji at the all tabs except ARCHIVE */}
                {filterBy !== EEngagementFilter.Archive && (
                    <EmptyEngagementListItem/>
                )}
            </AnimatedList>
        </ListWrp>
    );
};

export default React.memo(HiresList);
