import React from 'react';

import {styled} from '@mui/material';

import {useAuth} from '../../../../hooks';
import {EClosureStatus, EEngagementStatus, EPauseStatus, EResumeStatus, IEngagement} from '../../../../models';
import theme from '../../../../theme';
import {EngagementActionsClient, EngagementActionsFrelancer} from '../../../EngagementList';

const Wrp = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  height: 61px;
  padding: 8px 24px;
  background-color: ${theme.palette.white.main};
  border-bottom: 1px solid ${theme.palette.lightGray.main};

  img {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`;

const Message = styled('span')`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-right: auto;
`;

type EngagementInfoProps = {
    data?: IEngagement;
};

const EngagementInfo: React.FC<EngagementInfoProps> = ({data}) => {
    const {isClient, isFreelancer} = useAuth();
    const EngagementActions = isClient ? EngagementActionsClient : EngagementActionsFrelancer;

    // Active has Close request
    if (data?.status === EEngagementStatus.ACTIVE && data?.closureStatus) {
        // Closure requested
        const isCloseRequested = [EClosureStatus.CLIENT_REQUESTED, EClosureStatus.FREELANCER_REQUESTED].includes(data?.closureStatus as EClosureStatus);
        const isCloseRequestedInitiator = (isClient && data.closureStatus === EClosureStatus.CLIENT_REQUESTED) ||
            (isFreelancer && data.closureStatus === EClosureStatus.FREELANCER_REQUESTED);

        //  is another side rejected Closure request
        const isCloseRejected = (isClient && data.closureStatus === EClosureStatus.FREELANCER_REJECTED) ||
            (isFreelancer && data.closureStatus === EClosureStatus.CLIENT_REJECTED);

        if (isCloseRequested && isCloseRejected) {
            return (
                <>
                    {isCloseRequested && (
                        <Wrp>
                            {
                                // if Initiator - show waiting icon, else show proposal icon
                                isCloseRequestedInitiator
                                    ? (
                                        <img src="/assets/images/waiting-icon.png" alt="icon waiting"/>
                                    ) : (
                                        <img src="/assets/images/pit-stop-icon.png" alt="icon pit-stop"/>
                                    )
                            }

                            <Message>Close engagement request from
                                {
                                    isCloseRequestedInitiator
                                        ? ' pending'
                                        : ` from ${(isClient ? (data.freelancerName) : data.clientName).split(' ')[0]}`
                                }
                            </Message>

                            {/*
                                Actions: Initiator can cancel request. Another side can Confirm or Reject.
                            */}
                            <EngagementActions.CloseCancelBtn data={data}/>
                            <EngagementActions.CloseRespondBtn data={data}/>
                        </Wrp>
                    )}

                    {isCloseRejected && (
                        <Wrp>
                            <img src="/assets/images/cross-icon.png" alt="icon cross"/>
                            <Message>Close engagement request rejected</Message>
                        </Wrp>
                    )}
                </>
            );
        }
    }

    // Active has Pause request
    if (data?.status === EEngagementStatus.ACTIVE && data?.pause) {
        const isPauseIncoming = [EPauseStatus.CLIENT_ACCEPTED, EPauseStatus.CLIENT_DECIDED].includes(data.pause?.status as EPauseStatus);

        // is Pause initiator
        const isInitiator = (isClient && data.pause?.status === EPauseStatus.CLIENT_DECIDED) ||
            (isFreelancer && data.pause?.status === EPauseStatus.CLIENT_ACCEPTED);


        return (
            <>
                {isPauseIncoming && (
                    <Wrp>
                        <img src="/assets/images/waiting-icon.png" alt="icon waiting"/>
                        <Message>Engagement pause incoming</Message>

                        {isInitiator && (
                            <EngagementActions.PauseCancelBtn data={data}/>
                        )}
                    </Wrp>
                )}
            </>
        );
    }

    // Paused
    if (data?.status === EEngagementStatus.PAUSED) {
        const resumeStatus = data?.resume?.resumeStatus as EResumeStatus;

        // is new Request
        const isResumeRequested = [EResumeStatus.CLIENT_REQUESTED, EResumeStatus.FREELANCER_REQUESTED].includes(resumeStatus);
        const isResumeRequestedInitiator = (isClient && data.pause?.status === EPauseStatus.CLIENT_DECIDED && data.resume?.resumeStatus === EResumeStatus.CLIENT_REQUESTED) ||
            (isFreelancer && data.pause.status === EPauseStatus.CLIENT_ACCEPTED && data.resume?.resumeStatus === EResumeStatus.FREELANCER_REQUESTED);

        // is another side rejected
        const isResumeRejected = (isClient && data.resume?.resumeStatus === EResumeStatus.FREELANCER_REJECTED) ||
            (isFreelancer && data.resume?.resumeStatus === EResumeStatus.CLIENT_REJECTED);

        // is both Accepted
        const isResumed = [EResumeStatus.FREELANCER_ACCEPTED, EResumeStatus.CLIENT_ACCEPTED].includes(resumeStatus);

        // is any Canceled
        const isResumeCanceled = [EResumeStatus.FREELANCER_CANCELLED, EResumeStatus.CLIENT_CANCELLED].includes(resumeStatus);

        return (
            <>

                {isResumeRequested && (
                    <Wrp>
                        {
                            // if Initiator - show waiting icon, else show proposal icon
                            isResumeRequestedInitiator
                                ? (
                                    <img src="/assets/images/waiting-icon.png" alt="icon waiting"/>
                                ) : (
                                    <img src="/assets/images/proposal-icon.png" alt="icon proposal"/>
                                )
                        }

                        <Message>Resume engagement request
                            {
                                isResumeRequestedInitiator
                                    ? ' pending'
                                    : ` from ${(isClient ? (data.freelancerName) : data.clientName).split(' ')[0]}`
                            }
                        </Message>

                        {/*
                            Actions: Initiator can cancel request. Another side can view and Accept/Reject new Proposal.
                         */}
                        <EngagementActions.ResumeCancelBtn data={data}/>
                        <EngagementActions.ViewProposalBtn data={data}/>
                    </Wrp>
                )}

                {isResumeRejected && (
                    <Wrp>
                        <img src="/assets/images/cross-icon.png" alt="icon cross"/>
                        <Message>Resume engagement request rejected</Message>
                    </Wrp>
                )}

                {isResumed && (
                    <Wrp>
                        <img src="/assets/images/waiting-icon.png" alt="icon waiting"/>
                        <Message>Engagement is about to be resumed</Message>
                        <EngagementActions.ResumeCancelBtn data={data}/>
                    </Wrp>
                )}

                {isResumeCanceled && (
                    <Wrp>
                        <img src="/assets/images/cross-icon.png" alt="icon cross"/>
                        <Message>Engagement resume has been cancelled</Message>
                    </Wrp>
                )}

            </>
        );
    }

    return null;
};

export default React.memo(EngagementInfo);
