import React from 'react';

const UploadColorfulIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3104_1198)">
                <path
                    d="M79.8438 32.0312C79.8438 39.3545 73.8858 45.3125 66.5625 45.3125H46.7608C47.228 46.1975 47.0895 47.3187 46.3447 48.0634C45.4294 48.9787 43.9455 48.9787 43.0302 48.0634L42.3438 47.377V57.3438C42.3438 58.6381 41.2944 59.6875 40 59.6875C38.7056 59.6875 37.6562 58.6381 37.6562 57.3438V47.377L36.9697 48.0636C36.0544 48.9789 34.5705 48.9789 33.6552 48.0636C32.9105 47.3187 32.7719 46.1977 33.2391 45.3127H11.875C5.41328 45.3127 0.15625 40.0556 0.15625 33.5939C0.15625 27.9191 4.21125 23.1733 9.57563 22.1016C9.54609 21.7664 9.53125 21.4302 9.53125 21.0938C9.53125 14.632 14.7883 9.375 21.25 9.375C22.6116 9.375 23.9489 9.61016 25.2122 10.0653C26.1147 8.41219 27.3669 6.94766 28.8747 5.80688C31.1941 4.0525 33.9605 3.125 36.875 3.125C39.3267 3.125 41.7102 3.80531 43.7805 5.07203C46.8548 1.86187 51.1342 0 55.625 0C64.6714 0 72.0312 7.35984 72.0312 16.4062C72.0312 17.5364 71.9119 18.6622 71.6752 19.7727C76.527 21.8036 79.8438 26.6195 79.8438 32.0312ZM20.4688 65.1562C20.4688 65.778 20.7156 66.3741 21.1553 66.8134L33.6553 79.3134C34.0947 79.7531 34.6908 80 35.3125 80V67.5C35.3125 66.2056 34.2631 65.1562 32.9688 65.1562H20.4688Z"
                    fill="#ECF4FE"/>
                <path
                    d="M57.1875 28.4375H22.8125C21.5181 28.4375 20.4688 29.4869 20.4688 30.7812V65.1562H32.9688C34.2631 65.1562 35.3125 66.2056 35.3125 67.5V80H57.1875C58.4819 80 59.5312 78.9506 59.5312 77.6562V30.7812C59.5312 29.4869 58.4819 28.4375 57.1875 28.4375ZM46.3447 48.0634C45.4294 48.9787 43.9455 48.9787 43.0302 48.0634L42.3438 47.377V57.3438C42.3438 58.6381 41.2944 59.6875 40 59.6875C38.7056 59.6875 37.6562 58.6381 37.6562 57.3438V47.377L36.9697 48.0636C36.0544 48.9789 34.5705 48.9789 33.6552 48.0636C32.7398 47.1483 32.7398 45.6644 33.6552 44.7491L38.3427 40.0616C39.258 39.1463 40.7419 39.1463 41.6572 40.0616L46.3447 44.7491C47.26 45.6642 47.26 47.1483 46.3447 48.0634Z"
                    fill="url(#paint0_linear_3104_1198)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_3104_1198" x1="40" y1="28.75" x2="39.9867" y2="80"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7E5FFF"/>
                    <stop offset="1" stopColor="#3C94F7"/>
                </linearGradient>
                <clipPath id="clip0_3104_1198">
                    <rect width="80" height="80" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default UploadColorfulIcon;
