import React from 'react';

import PageTopTitle from '../PageTopTitle';

interface IProps {
    desktopFontSize?: number;
}

export const ProjectTopTitle: React.FC<IProps> = React.memo(({desktopFontSize}) => {
    return (
        <PageTopTitle
            desktopFontSize={desktopFontSize}
            title={<>Write <span>a name and short description</span> of your project</>}
            subtitle="Keep it simple and straightforward. We’ll do our best to find you a perfect match for your role"
        />
    );
});
