import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {useNavigate, useParams} from 'react-router-dom';

import {Avatar, Box, styled, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {matchesService} from '../../../../../../../api';
import {BlogIcon} from '../../../../../../../assets/icons';
import {BlackBorderButton, ContinueButton, LikeButton, Pill, showToastError} from '../../../../../../../components';
import {CLIENT_ROLES, GET_MATCHED, MATCHES, MD, RouteClientPortal} from '../../../../../../../constants';
import {useTwilio} from '../../../../../../../hooks';
import {EMatchStatus, IDynamicProfiles} from '../../../../../../../models';
import theme from '../../../../../../../theme';
import {formatCurrency, stringToColor} from '../../../../../../../utils';

const Wrapper = styled('div')`
  background-color: ${theme.palette.white.main};
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);
  border-radius: 24px;

  @media (min-width: ${MD}) {
    position: relative;
  }
`;

const TopContent = styled('div')`
  padding: 16px;
  border-bottom: 1px solid ${theme.palette.lightGray.main};

  @media (min-width: ${MD}) {
    padding: 24px 24px 8px 24px;
  }
`;

const UserInfo = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 4px;

  @media (min-width: ${MD}) {
    margin-bottom: 16px;
  }
`;

const PillsWrapper = styled('div')<{ smallerGap?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${props => props.smallerGap ? '4px' : '8px'};
  margin-bottom: 12px;

  @media (min-width: ${MD}) {
    display: ${props => props.smallerGap ? 'flex' : 'inline-flex'};
    gap: ${props => props.smallerGap ? '7px' : '8px'};
    margin-bottom: ${props => props.smallerGap ? '0' : '8px'};
    position: relative;
    padding-right: 24px;
    margin-right: 24px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 16px;
      background-color: ${theme.palette.lightGray.main};
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
`;

const BottomContent = styled('div')`
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  @media (min-width: ${MD}) {
    padding: 15px 24px 16px 24px;
  }

  .MuiButtonBase-root {
    width: calc(50% - 8px);

    div {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    @media (min-width: ${MD}) {
      width: auto;
    }
  }
`;

const RateBox = styled('div')`
  margin-right: -16px;
  margin-left: auto;
  padding: 16px 24px;
  border-radius: 50px 0 0 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.purple.main};
  background-color: ${theme.palette.lilac.main};

  @media (min-width: ${MD}) {
    position: absolute;
    top: 36px;
    right: 0;
    margin-right: 0;
  }
`;

const subItemsCaptions = {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.gray.main
};

interface Props {
    data: IDynamicProfiles;
}

export const MatchedCard: React.FC<Props> = React.memo(({data}) => {
    const {roleId} = useParams();
    const [isLiked, setIsLiked] = useState([EMatchStatus.LIKED, EMatchStatus.LIKED_IN_TOUCH].includes(data.status));
    const [isLoading, setIsLoading] = useState(false);

    const queryClient = useQueryClient();
    const twilioClient = useTwilio();
    const navigate = useNavigate();

    const handleLikeClick = useCallback(async (active: boolean) => {
        const mutate = active ? matchesService.setMatcheLike : matchesService.setMatcheUnLike;
        const minimum = new Promise((reject) => setTimeout(() => reject(true), 1000));    // show preloader minimum 1 sec to prevent flicking

        setIsLiked(active);
        setIsLoading(true);

        try {
            await Promise.all([minimum, mutate(data.id)]);
            queryClient.invalidateQueries([CLIENT_ROLES, Number(roleId), MATCHES]);
            queryClient.invalidateQueries([GET_MATCHED]);
            queryClient.invalidateQueries([MATCHES, data.id]);
        } catch (error) {
            showToastError(error as AxiosError);
            setIsLiked(!active);
        }

        setIsLoading(false);
    }, [data.id, roleId, queryClient]);

    const handleMessageClick = async () => {
        setIsLoading(true);
        try {
            const conversationId = await matchesService.getChatRoom(data.id);

            twilioClient.invalidateQueries();   // Contact list can miss new room
            navigate(`${RouteClientPortal.inbox}/${conversationId}`);
        } catch (error) {
            showToastError(error as AxiosError);
        }
        setIsLoading(false);
    };

    const showContentIfDataAvailable = useCallback((commonList: any, additionalList: any) => {
        return !!commonList || !!additionalList;
    }, []);

    useEffect(() => {
        const newValue = [EMatchStatus.LIKED, EMatchStatus.LIKED_IN_TOUCH].includes(data.status);

        // can be updated at the different list
        if (newValue !== isLiked) {
            setIsLiked(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.status]);

    return (
        <Wrapper>
            <TopContent>
                <UserInfo>
                    <Avatar
                        alt={`${data.firstName} ${data.lastName}`}
                        src={data.avatar}
                        sx={{
                            width: 80,
                            height: 80,
                            fontSize: 30,
                            fontWeight: 'bold',
                            background: stringToColor(`${data.firstName} ${data.lastName}`)
                        }}
                    >
                        {!data.avatar && `${data.firstName[0]}${data.lastName[0]}`}
                    </Avatar>
                    <div className="content">
                        <Typography
                            variant="body1"
                            sx={{
                                mb: '2px',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: 1.5,
                                color: theme.palette.black.main,
                                [theme.breakpoints.up('md')]: {
                                    display: 'inline-block'
                                }
                            }}
                        >
                            {data.firstName} {data.lastName}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                mb: '6px',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: theme.palette.gray.main,
                                'span': {
                                    font: 'inherit',
                                    lineHeight: 'inherit',
                                    color: theme.palette.black.main
                                },
                                [theme.breakpoints.up('md')]: {
                                    display: 'inline-block',
                                    position: 'relative',
                                    marginLeft: '12px',
                                    paddingLeft: '12px',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '50%',
                                        left: 0,
                                        transform: 'translateY(-50%)',
                                        width: '1px',
                                        height: '16px',
                                        backgroundColor: theme.palette.lightGray.main
                                    }
                                }
                            }}
                        >
                            <span>{data.yearsOfExperience} year{data.yearsOfExperience === 1 ? '' : 's'}</span> of
                            experience in marketing
                        </Typography>

                        {
                            showContentIfDataAvailable(data.channels.common, data.channels.additional) &&
                          <PillsWrapper smallerGap>
                              {
                                  data.channels.common?.map(channel =>
                                      <Pill
                                          key={channel.id}
                                          hasIcon
                                          hasOutline
                                          variant="green"
                                      >
                                          {channel.name}
                                      </Pill>
                                  )
                              }

                              {
                                  data.channels.additional?.map(additionalChannel =>
                                      <Pill
                                          key={additionalChannel.id}
                                          variant="gray"
                                      >
                                          {additionalChannel.name}
                                      </Pill>
                                  )
                              }
                          </PillsWrapper>
                        }
                    </div>
                </UserInfo>

                <Typography
                    variant="body1"
                    sx={{
                        mb: '16px',
                        display: '-webkit-box',
                        maxWidth: '100%',
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: 1.65,
                        color: theme.palette.gray.main
                    }}
                >
                    {data.bio}
                </Typography>

                {
                    showContentIfDataAvailable(data.subchannels.common, data.subchannels.additional) &&
                  <PillsWrapper>
                      {
                          data.subchannels.common?.map(subchannel =>
                              <Pill
                                  key={subchannel.id}
                                  variant="lilac"
                              >
                                  {subchannel.name}
                              </Pill>
                          )
                      }
                      {
                          data.subchannels.additional &&
                        <Typography variant="body2" sx={subItemsCaptions}>
                          +{data.subchannels.additional.length} {data.subchannels.additional.length > 1 ? 'more sub-channels' : 'more sub-channel'}
                        </Typography>
                      }
                  </PillsWrapper>
                }

                {
                    showContentIfDataAvailable(data.verticals.common, data.verticals.additional) &&
                  <PillsWrapper>
                      {
                          data.verticals.common?.map(vertical =>
                              <Pill
                                  key={vertical.id}
                                  variant="green"
                              >
                                  {vertical.name}
                              </Pill>
                          )
                      }

                      {
                          data.verticals.additional &&
                        <Typography variant="body2" sx={subItemsCaptions}>
                          +{data.verticals.additional.length} {data.verticals.additional.length > 1 ? 'more verticals' : 'more vertical'}
                        </Typography>
                      }
                  </PillsWrapper>
                }

                {
                    showContentIfDataAvailable(data.tools.common, data.tools.additional) &&
                  <PillsWrapper>
                      {
                          data.tools.common?.map(tool =>
                              <Pill
                                  key={tool.id}
                                  variant="blue"
                              >
                                  {tool.name}
                              </Pill>
                          )
                      }
                      {
                          data.tools.additional &&
                        <Typography variant="body2" sx={subItemsCaptions}>
                          +{data.tools.additional.length} {data.tools.additional.length > 1 ? 'more tools' : 'more tool'}
                        </Typography>
                      }
                  </PillsWrapper>
                }
            </TopContent>

            <BottomContent>
                <Typography
                    variant="body1"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        mr: 'auto',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main
                    }}
                >
                    <BlogIcon/>
                    {data.referencesCount} references
                </Typography>

                <LikeButton
                    active={isLiked}
                    loading={isLoading}
                    onClick={handleLikeClick}
                />

                <RateBox>{formatCurrency(data.rate)}/hour</RateBox>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        [theme.breakpoints.up('md')]: {
                            width: 'auto',
                            gap: '32px'
                        }
                    }}
                >
                    <BlackBorderButton
                        onClick={handleMessageClick}
                    >
                        Message
                    </BlackBorderButton>

                    <ContinueButton
                        variant="contained"
                        onClick={() => navigate(`${RouteClientPortal.publicFreelancerProfile}/${data.id}`)}
                    >
                        View profile
                    </ContinueButton>
                </Box>
            </BottomContent>
        </Wrapper>
    );
});
