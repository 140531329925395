import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';

import {Box, Theme, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {applicationService} from '../../../api';
import {AnimatePageWrp, ContinueButton, LoadingMask, PageTopTitle, Portal, showToastError, showToastSuccess, SliderExperience} from '../../../components';
import {RouteCreateApplicantAccount} from '../../../constants';
import {useApplication, useNavigateUTM} from '../../../hooks';
import theme from '../../../theme';

const Experience = () => {
    const {application, isApplicationLoading, refetchApplication} = useApplication();
    const [value, setValue] = useState(3);
    const navigate = useNavigateUTM();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const {mutate, isLoading} = useMutation(
        applicationService.experienceSubmit,
        {
            onSuccess() {
                showToastSuccess('Experience was saved successfully!');
                navigate(RouteCreateApplicantAccount.channels);
                refetchApplication();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleChange = useCallback((event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    }, []);

    const handleSubmit = () => {
        // api call if it is dirty
        if (!application?.yearsOfExperience || application?.yearsOfExperience !== value) {
            mutate(value);
        } else {
            navigate(RouteCreateApplicantAccount.channels);
        }
    };

    useEffect(() => {
        if (!application?.yearsOfExperience) {
            return;
        }
        setValue(application.yearsOfExperience);
    }, [application]);

    return (
        <AnimatePageWrp maxWidth={{md: 832}} minWidth={{md: 600}}>
            <PageTopTitle
                title={<>Okay, first, how many <span>years of marketing experience</span> do you have?</>}
                titleMaxWidth={{lg: 530}}
                subtitle="Please note: We accept only marketing specialists with 3+ years of experience..."
                subtitleMaxWidth={{lg: 545}}
            />

            {
                isApplicationLoading
                    ? (
                        <LoadingMask/>
                    )
                    : (
                        <Box sx={{px: '16px'}}>
                            <SliderExperience
                                value={value}
                                onChange={handleChange}
                            />
                        </Box>
                    )
            }

            {
                !isSmallScreen ? (
                    <Box sx={{
                        my: 5,
                        [theme.breakpoints.up('sm')]: {
                            marginTop: '71px',
                            marginBottom: '40px'
                        },
                        [theme.breakpoints.up('xl')]: {
                            marginTop: '75px'
                        }
                    }} textAlign="center">
                        <ContinueButton
                            disabled={isLoading || isApplicationLoading}
                            hasArrow
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Continue
                        </ContinueButton>
                    </Box>
                ) : (
                    <Portal order={2}>
                        <ContinueButton
                            disabled={isLoading || isApplicationLoading}
                            fullWidth
                            hasArrow
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Continue
                        </ContinueButton>
                    </Portal>
                )

            }

        </AnimatePageWrp>

    );
};

export default Experience;
