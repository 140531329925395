import React, {useMemo} from 'react';

import {Box, styled} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import {ClockIcon, ExclamationMarkIcon, RecentlySubmittedIcon} from '../../../assets/icons';
import {useAuth} from '../../../hooks';
import {EWorkWeekStatus} from '../../../models';
import theme from '../../../theme';

const StatusText = styled('p')<{ status?: EWorkWeekStatus }>`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }

  path {
    fill: ${props => props.status === EWorkWeekStatus.APPROVED && theme.palette.gray.main};
  }
`;

interface IHistoryBoxTitle {
    statusText?: EWorkWeekStatus;
    statusContent: React.ReactNode;
    sx?: SxProps<Theme>;
}

export const HistoryBoxTitle: React.FC<IHistoryBoxTitle> = ({statusText, statusContent, sx}) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            width: '100%',
            mb: '16px',
            '&::before, &::after': {
                content: '""',
                height: '1px',
                width: '100%',
                backgroundColor: theme.palette.lightGray.main
            },
            ...sx
        }}
    >
        <StatusText status={statusText}>
            {statusContent}
        </StatusText>
    </Box>
);

interface IProps {
    status: EWorkWeekStatus;
}

const TimesheetStatusTitle: React.FC<IProps> = ({status}) => {
    const {isClient} = useAuth();

    const renderStatus = useMemo(() => {
        switch (status) {
            case EWorkWeekStatus.REJECTED:
                return <>
                    <ExclamationMarkIcon/> Rejected
                </>;
            case EWorkWeekStatus.SENT:
                return <>
                    <RecentlySubmittedIcon/> Recently submitted
                </>;
            case EWorkWeekStatus.APPROVED:
                return <>
                    <ClockIcon/> History
                </>;
        }
    }, [status]);

    if (isClient && status === EWorkWeekStatus.SENT) {
        return null;
    }

    return (
        <HistoryBoxTitle
            statusText={status}
            statusContent={renderStatus}
        />
    );
};

export default React.memo(TimesheetStatusTitle);
