// APPLICANT application will not use standard OAuth2.0 Keykloak flow
// with redirection to the SSO
// we use 'grant_type'='password' request for getting token
// from our custom Login, SignUp ... pages

const OAuth2Config = {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

export const tokenURL = `/realms/${OAuth2Config.realm}/protocol/openid-connect/token`;

export const keycloakParams = {
    'client_id': OAuth2Config.clientId,
    'grant_type': 'password'
};
