import React, {useEffect, useMemo, useState} from 'react';
import localeUs from 'date-fns/locale/en-US';
import {DateRange, DateRangePicker, DefinedRange} from 'mui-daterange-picker';

import {Box, Theme, useMediaQuery} from '@mui/material';

import theme from '../../../theme';
import {AnimateHeight} from '../../AnimateHeight/AnimateHeight';
import {ContinueButton} from '../../Button';
import DialogEmoji from '../../Dialog/DialogEmoji';

import {getDefaultRanges} from './defaults';

const stylesDateRangePicker = {
    '.MuiDialogTitle-root': {
        pb: 0,
    },
    '.MuiDialogContent-root': {
        padding: '0 15px',
    },
    '.MuiGrid-root.MuiGrid-container.MuiGrid-item:not(:last-child)': {
        justifyContent: 'center',
        'span': {
            width: '36px',
            textAlign: 'center',
        }
    },
    'ul.MuiList-root': {
        maxHeight: '400px',
        border: 'none',
        '.MuiTypography-body2': {
            fontSize: 12,
            whiteSpace: 'nowrap'
        }
    },
    '[id=menu-] .MuiPaper-root': {
        maxWidth: '150px',
        border: '2px solid ' + theme.palette.lightGray.light,
        'ul': {
            maxHeight: '300px',
        }
    },

    // '.MuiDialogContent-root .MuiPaper-root > .MuiGrid-root.MuiGrid-wrap-xs-nowrap > .MuiGrid-root:first-of-type ul > div:not([style*="background-color:transparent"]) > div > p': {
    //     color: 'red'
    // },
    [theme.breakpoints.down('md')]: {
        // at mobile show 2 months vertical
        '.MuiDialogContent-root .MuiPaper-root > .MuiGrid-root.MuiGrid-wrap-xs-nowrap': {
            '& > .MuiGrid-root:first-of-type, hr': {
                display: 'none'
            },
            '.MuiGrid-root .MuiGrid-root.MuiGrid-container.MuiGrid-wrap-xs-nowrap': {
                flexWrap: 'wrap',
            }
        },
    },
};

type InputDateRangeProps = {
    initialDateRange?: DateRange;
    minDate?: Date | string;
    maxDate?: Date | string;
    open: boolean;
    onChange: (dateRange?: DateRange) => void;
    onClose: () => void;
};

const InputDateRange: React.FC<InputDateRangeProps> = ({
    initialDateRange,
    minDate,
    maxDate,
    open,
    onChange,
    onClose
}) => {
    const [value, setValue] = useState<DateRange>();

    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    // generate presets
    const definedRanges = useMemo(() => getDefaultRanges(new Date(), localeUs), []);

    useEffect(() => {
        if (!open) {
            setValue(undefined);
        }
    }, [open]);

    return (
        <>
            <DialogEmoji
                actions={(
                    <>
                        <ContinueButton
                            fullWidth={mdDown}
                            sx={{width: mdDown ? '161px !important' : 'auto !important'}}
                            variant="contained"
                            onClick={() => {
                                onChange(value || initialDateRange);
                                onClose();
                            }}
                        >
                            Search
                        </ContinueButton>
                    </>
                )}
                maxWidth="lg"
                maxWidthPx={850}
                open={open}
                sx={stylesDateRangePicker}
                title=""
                onClose={onClose}
            >
                <AnimateHeight isVisible={open}>

                    {/* Remove from DOM for reset a value, because supported only initial :(   */}
                    {open && (
                        <DateRangePicker
                            definedRanges={definedRanges as DefinedRange[]}
                            initialDateRange={initialDateRange}
                            locale={localeUs}
                            minDate={minDate}
                            maxDate={maxDate}
                            open={true}
                            toggle={onClose}
                            onChange={setValue}
                        />
                    )}
                </AnimateHeight>


                <Box paddingBottom={4}/>
            </DialogEmoji>
        </>
    );
};

export default React.memo(InputDateRange);
