import React from 'react';

import {useQuery} from '@tanstack/react-query';

import {freelancerService} from '../../../api';
import {FREELANCER, FREELANCER_PROFILE} from '../../../constants';
import {IFreelancerWithEmailVerificationProcess, IProfile} from '../../../models';

interface State {
    isLoading: boolean;
    data?: IProfile;
    freelancer?: IFreelancerWithEmailVerificationProcess;
}

type FreelancerContextProviderProps = { children: React.ReactNode };

const FreelancerContext = React.createContext<
{
    state: State;
    refetchFleelancer: () => void;
    refetchProfile: () => void;
} | undefined>(undefined);

const FreelancerContextProvider = ({children}: FreelancerContextProviderProps) => {
    const {
        isLoading: isLoadingProfile,
        data,
        refetch: refetchProfile
    } = useQuery([FREELANCER_PROFILE], freelancerService.getProfile, {
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const {
        isLoading: isLoadingFreelancer,
        data: freelancer,
        refetch: refetchFleelancer
    } = useQuery([FREELANCER], freelancerService.getFleelancer, {
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const state = {
        isLoading: isLoadingFreelancer || isLoadingProfile,
        data,
        freelancer
    };

    return (
        <FreelancerContext.Provider
            value={{
                state,
                refetchFleelancer,
                refetchProfile,
            }}
        >{children}</FreelancerContext.Provider>
    );
};

const useFreelancer = () => {
    const context = React.useContext(FreelancerContext);

    if (context) {
        return context;
    }

    throw new Error('useFreelancer must be used within a FreelancerContextProvider');
};

export {FreelancerContextProvider, useFreelancer};
