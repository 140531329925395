import {any, number, object, string} from 'zod';

import {MAX_FILE_SIZE, MAX_FILE_SIZE_MB} from '../../../../constants';
import {EWorkType, IChannel, ICountry} from '../../../../models';
import {ITimezone} from '../../../../utils/timezones';

export const ABOUT_ME_MIN_LENGTH = 100;
export const ABOUT_ME_MAX_LENGTH = 1000;

export const profileSchema = object({
    avatar: string().optional(),
    bio: string()
        .min(ABOUT_ME_MIN_LENGTH, `Short Bio must be at least ${ABOUT_ME_MIN_LENGTH} characters`)
        .max(ABOUT_ME_MAX_LENGTH, `Short Bio must be less than ${ABOUT_ME_MAX_LENGTH} characters`),
    brands: any()
        .refine(brands => !!brands?.length, 'Brand is required'),
    businessModels: string().array().nonempty({
        message: 'Business Model is required'
    }),
    channels: any()
        .refine((channels) => channels?.length >= 1,
            'Channels is required'
        ),
    country: any()
        .refine((country: ICountry) => !!country?.code,
            'Location is required'
        ),
    file: any()
        .refine((files) => files?.length ? (files?.[0]?.size <= MAX_FILE_SIZE_MB) : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        ),
    fixedRate: any(),
    isAvailableForNewProject: any(),
    isOpenToHireRole: any(),
    languages: any()
        .refine((languages: string[]) => !!languages?.length,
            'Language is required'
        ),
    rateRange: number().array().optional(),
    timezones: any()
        .refine((timezones: ITimezone[]) => !!timezones?.length,
            'Timezone is required'
        ),
    tools: any()
        .refine(tools => !!tools?.length, 'Tool is required'),
    verticals: any()
        .refine(verticals => !!verticals?.length, 'Vertical is required'),
    yearsOfExperience: number()
        .min(1, 'Years Of Experience is required'),
    workTypes: any()
        .refine(workTypes => !!workTypes?.length, 'Work Type is is required'),
})
    // Not all channels has subchannels
    // but if subchannels is not empty then pick at least one subchannel.
    .refine((data) => {
        const channels: IChannel[] = data.channels;
        const isEmpty = channels.some(channel => channel.hasSubChannels && !channel.subchannels.length);

        return !isEmpty;
    }, {
        path: ['subchannels'],
        message: 'Pick at least one subchannel'
    })
    // Please select your retainer rate (per hour).
    .refine((data) => {
        const isRetainerOrFixed = data.workTypes.includes(EWorkType.fixed) || data.workTypes.includes(EWorkType.retainer);

        return isRetainerOrFixed ? !!data.rateRange?.[0] : true;
    }, {
        path: ['rateRange'],
        message: 'Rate Range is required'
    })
    // Please select your hourly rate (per hour)..
    .refine((data) => {
        const isHourly = data.workTypes.includes(EWorkType.hourly);

        return isHourly ? data.fixedRate > 0 : true;
    }, {
        path: ['fixedRate'],
        message: 'Hourly Rate is required'
    });
