import React from 'react';

const ArrowRightShort = (): JSX.Element => {
    return (
        <svg width="42" height="16" viewBox="0 0 42 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L-8.74228e-08 7L8.74228e-08 9L1 9L1 7ZM41.7071 8.7071C42.0976 8.31658 42.0976 7.68341 41.7071 7.29289L35.3431 0.928929C34.9526 0.538405 34.3195 0.538405 33.9289 0.928929C33.5384 1.31945 33.5384 1.95262 33.9289 2.34314L39.5858 8L33.9289 13.6569C33.5384 14.0474 33.5384 14.6805 33.9289 15.0711C34.3195 15.4616 34.9526 15.4616 35.3431 15.0711L41.7071 8.7071ZM1 9L12.4917 9L12.4917 7L1 7L1 9ZM12.4917 9L22.4365 9L22.4365 7L12.4917 7L12.4917 9ZM22.4365 9L31.9392 9L31.9392 7L22.4365 7L22.4365 9ZM31.9392 9L41 9L41 7L31.9392 7L31.9392 9Z" fill="white"/>
        </svg>
    );
};

export default ArrowRightShort;
