export enum EAttachmentType {
    certificate = 'certificate',
    reference = 'reference',
    study = 'case_study'
}

export interface IAttachment {
    id: number;
    isDraft?: boolean;
    name: string;
    type: EAttachmentType;
}

export type LegalDocType = 'W8' | 'W9';

export interface ILegalDocResponse {
    id: string;
    expiresAt: string;
}

export interface ILegalDoc {
    completedAt: string;
    id: string;
    taxYear: number;
    type: LegalDocType;
}
