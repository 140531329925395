import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsFrelancer, EngagementInfoBox} from '../../../../../../../components';
import {EClosureStatus, EEngagementStatus, IEngagementFreelancer} from '../../../../../../../models';

type CloseRequestProps = {
    data: IEngagementFreelancer;
};

const CloseRequestBox: React.FC<CloseRequestProps> = ({data}) => {
    if (data.status === EEngagementStatus.CLOSED || data.closureStatus !== EClosureStatus.FREELANCER_REQUESTED) {
        return null;
    }

    return (
        <EngagementInfoBox
            iconUrl="/assets/images/pending-icon.png"
            title="Close engagement request pending"
        >
            {data.clientName.split(' ')[0]} has received your close engagement request.
            In the meantime, billing for this engagement will not occur unless you cancel the engagement closure request.

            <Box
                sx={{
                    mt: '16px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <EngagementActionsFrelancer.CloseCancelBtn data={data}/>
            </Box>

        </EngagementInfoBox>
    );
};

export default React.memo(CloseRequestBox);
