import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {ThemeProvider} from '@mui/material';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';
import {ErrorBoundary} from './components';
import {AuthContextProvider, StateContextProvider} from './contexts';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

import './index.scss';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            // refetchOnMount: false,
            // refetchOnReconnect: true,
            // retry: 1,
            staleTime: 10 * 1000,
        },
    },
});


const root = ReactDOM.createRoot(
    document.getElementById('app') as HTMLElement
);

root.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <AuthContextProvider>
                <StateContextProvider>
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                    <ThemeProvider theme={theme}>
                        <ErrorBoundary>
                            <App/>
                        </ErrorBoundary>
                    </ThemeProvider>
                </StateContextProvider>
            </AuthContextProvider>
        </BrowserRouter>
    </QueryClientProvider>
);

reportWebVitals();

let version = '';

function checkVersion() {
    const t = Date.now();

    fetch(`/v?${t}`, {cache: 'no-cache'})
        .then(result => {
            if (result.status != 200) {
                console.error('Unable to fetch version information', result);

                return;
            }
            result.text()
                .then(s => s.trim())
                .then(newVersion => {
                    if (!version) {
                        version = newVersion;
                    }
                    if (version != newVersion) {
                        console.log('New version is different, refresh requested');
                        const href = window.location.href;

                        document.location.href = href + (href.includes('?') ? '&' : '?') + `__=${t}`;
                    }
                });
        })
        .catch(error => {
            console.log('fetch v', error);
        })
        .finally(() => {
            setTimeout(checkVersion, 60000);
        });
}

if (process.env.NODE_ENV !== 'development') {
    checkVersion();
}
