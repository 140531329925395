import React, {ReactNode} from 'react';
import {AnimatePresence, motion} from 'framer-motion';

import {Box, Typography} from '@mui/material';

import theme from '../../theme';
import {animationAlertBox} from '../Animations';

type EngagementInfoBoxProps = {
    children: ReactNode;
    iconUrl: string;
    isError?: boolean;
    title: string;
};

const EngagementInfoBox: React.FC<EngagementInfoBoxProps> = ({children, iconUrl, isError, title}) => {
    return (
        <AnimatePresence>
            <Box
                component={motion.div}
                {...animationAlertBox(0)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '40px 24px',
                    gap: '16px',
                    mt: '24px',
                    p: '24px',
                    backgroundColor: isError ? theme.palette.lightRed.main : theme.palette.blue.light,
                    border: '1.5px solid',
                    borderColor: isError ? theme.palette.error.main : theme.palette.blue.dark,
                    borderRadius: '24px',
                }}
            >

                <img src={iconUrl} width="32" height="32" alt="alarm clock icon"/>

                <Box>
                    <Typography
                        component="div"
                        sx={{
                            mb: '4px',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: 1.5,
                            color: theme.palette.black.main,
                            textAlign: 'center'
                        }}
                        variant="body1"
                    >
                        {title}
                    </Typography>

                    <Typography
                        align="center"
                        component="div"
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: 1.5,
                            color: theme.palette.textGray.dark,
                        }}
                        variant="body2"
                    >
                        {children}
                    </Typography>
                </Box>
            </Box>
        </AnimatePresence>
    );
};

export default EngagementInfoBox;
