import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {SliderExperience} from '../..';

interface InputExperienceProps {
    name: string;
}

export const InputExperience: React.FC<InputExperienceProps> = React.memo(({name}) => {
    const {control} = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue=''
            name={name}
            render={({field}) => (
                <SliderExperience value={Number(field.value)} onChange={field.onChange}/>
            )}
        />
    );
});
