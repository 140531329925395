import React from 'react';

const ArchiveIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50013 9.75H10.5001C10.699 9.75 10.8898 9.67098 11.0305 9.53033C11.1711 9.38968 11.2501 9.19891 11.2501 9C11.2501 8.80109 11.1711 8.61032 11.0305 8.46967C10.8898 8.32902 10.699 8.25 10.5001 8.25H7.50013C7.30121 8.25 7.11045 8.32902 6.9698 8.46967C6.82914 8.61032 6.75013 8.80109 6.75013 9C6.75013 9.19891 6.82914 9.38968 6.9698 9.53033C7.11045 9.67098 7.30121 9.75 7.50013 9.75ZM14.2501 2.25H3.75013C3.22247 2.25409 2.71304 2.44351 2.31088 2.78514C1.90873 3.12676 1.63943 3.59887 1.55008 4.11892C1.46072 4.63897 1.55699 5.17388 1.82205 5.63015C2.08711 6.08642 2.5041 6.43502 3.00013 6.615V13.5C3.00013 14.0967 3.23718 14.669 3.65914 15.091C4.08109 15.5129 4.65339 15.75 5.25013 15.75H12.7501C13.3469 15.75 13.9192 15.5129 14.3411 15.091C14.7631 14.669 15.0001 14.0967 15.0001 13.5V6.615C15.4962 6.43502 15.9131 6.08642 16.1782 5.63015C16.4433 5.17388 16.5395 4.63897 16.4502 4.11892C16.3608 3.59887 16.0915 3.12676 15.6894 2.78514C15.2872 2.44351 14.7778 2.25409 14.2501 2.25ZM13.5001 13.5C13.5001 13.6989 13.4211 13.8897 13.2805 14.0303C13.1398 14.171 12.949 14.25 12.7501 14.25H5.25013C5.05121 14.25 4.86045 14.171 4.7198 14.0303C4.57914 13.8897 4.50013 13.6989 4.50013 13.5V6.75H13.5001V13.5ZM14.2501 5.25H3.75013C3.55121 5.25 3.36045 5.17098 3.2198 5.03033C3.07914 4.88968 3.00013 4.69891 3.00013 4.5C3.00013 4.30109 3.07914 4.11032 3.2198 3.96967C3.36045 3.82902 3.55121 3.75 3.75013 3.75H14.2501C14.449 3.75 14.6398 3.82902 14.7805 3.96967C14.9211 4.11032 15.0001 4.30109 15.0001 4.5C15.0001 4.69891 14.9211 4.88968 14.7805 5.03033C14.6398 5.17098 14.449 5.25 14.2501 5.25Z" fill="#999999"/>
        </svg>
    );
};

export default ArchiveIcon;
