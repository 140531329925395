import React from 'react';

const LocationIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00033 1.83398C6.58584 1.83398 5.22928 2.39589 4.22909 3.39608C3.2289 4.39628 2.66699 5.75283 2.66699 7.16732C2.66699 10.7673 7.36699 14.834 7.56699 15.0073C7.68775 15.1106 7.84142 15.1674 8.00033 15.1674C8.15923 15.1674 8.3129 15.1106 8.43366 15.0073C8.66699 14.834 13.3337 10.7673 13.3337 7.16732C13.3337 5.75283 12.7718 4.39628 11.7716 3.39608C10.7714 2.39589 9.41481 1.83398 8.00033 1.83398ZM8.00033 13.6007C6.58033 12.2673 4.00033 9.39398 4.00033 7.16732C4.00033 6.10645 4.42175 5.08904 5.1719 4.33889C5.92204 3.58875 6.93946 3.16732 8.00033 3.16732C9.06119 3.16732 10.0786 3.58875 10.8288 4.33889C11.5789 5.08904 12.0003 6.10645 12.0003 7.16732C12.0003 9.39398 9.42033 12.274 8.00033 13.6007ZM8.00033 4.50065C7.47291 4.50065 6.95734 4.65705 6.51881 4.95007C6.08027 5.24308 5.73848 5.65956 5.53665 6.14683C5.33481 6.6341 5.282 7.17028 5.3849 7.68756C5.48779 8.20484 5.74177 8.68 6.11471 9.05294C6.48765 9.42588 6.9628 9.67985 7.48008 9.78275C7.99737 9.88564 8.53355 9.83283 9.02081 9.631C9.50808 9.42916 9.92456 9.08737 10.2176 8.64884C10.5106 8.21031 10.667 7.69473 10.667 7.16732C10.667 6.46007 10.386 5.7818 9.88594 5.2817C9.38585 4.7816 8.70757 4.50065 8.00033 4.50065ZM8.00033 8.50065C7.73662 8.50065 7.47883 8.42245 7.25957 8.27594C7.0403 8.12944 6.8694 7.9212 6.76849 7.67756C6.66757 7.43393 6.64116 7.16584 6.69261 6.9072C6.74406 6.64856 6.87105 6.41098 7.05752 6.22451C7.24399 6.03804 7.48156 5.91105 7.74021 5.8596C7.99885 5.80816 8.26694 5.83456 8.51057 5.93548C8.7542 6.0364 8.96244 6.20729 9.10895 6.42656C9.25546 6.64582 9.33366 6.90361 9.33366 7.16732C9.33366 7.52094 9.19318 7.86008 8.94313 8.11013C8.69309 8.36018 8.35395 8.50065 8.00033 8.50065Z"
                fill="#999999"/>
        </svg>
    );
};

export default LocationIcon;
