import React, {useEffect, useMemo, useState} from 'react';

import {Box} from '@mui/material';

import {WIDGET_PAYLOAD} from '../../../api';
import {AnimatePageWrp, ContinueButton, Languages, LoadingMask, LocationsMulti, PageTopTitle, Portal, showToastError, TimeZone} from '../../../components';
import {RouteWidget} from '../../../constants';
import {useLocations, useNavigateUTM} from '../../../hooks';
import {ILocationRequest, ISelectOption} from '../../../models';
import theme from '../../../theme';
import {utm} from '../../../utils';
import {TIMEZONES_OPTIONS} from '../../../utils/timezones';

const WidgetLocationTimezone = () => {
    const widgetId = utm.campaign;
    const {
        countries: dataCountries,
        isLocationLoading,
        languages: dataLanguages,
    } = useLocations();
    const [countries, setCountries] = useState<ISelectOption[]>([]);
    const [languages, setLanguages] = useState<ISelectOption[]>([]);
    const [timezone, setTimezone] = useState<ISelectOption | null>(null);
    const navigate = useNavigateUTM();

    const optionsCountry = useMemo(() => dataCountries?.map(country => ({code: country.code, text: country.name, value: country.code})), [dataCountries]);

    const optionsLanguage = useMemo(() => dataLanguages?.map(lang => ({text: lang, value: lang})), [dataLanguages]);

    const handleSubmit = () => {
        if (!languages?.length) {
            showToastError('Please select a Language.');
        } else {
            const payload: ILocationRequest = {
                countries: countries.map((item: ISelectOption) => item.text),   // FIXME BE do not support CountryCode but should!!!
                languages: languages.map((item: ISelectOption) => item.text),
                timezone: timezone?.value || null
            };

            const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
            const data = widgetPayload ? JSON.parse(widgetPayload) : {};

            localStorage.setItem(WIDGET_PAYLOAD + widgetId, JSON.stringify({...data, ...payload}));
            navigate(RouteWidget.budget + location.search);
        }
    };

    // populate form
    useEffect(() => {
        if (!widgetId || !optionsCountry) return;
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);
        const data = widgetPayload ? JSON.parse(widgetPayload) : {};

        const {countries, languages, timezone} = data;

        if (countries?.length) {
            const values = optionsCountry?.filter(item => countries.includes(item.text));    // FIXME BE do not support CountryCode but should!!!

            setCountries(values);
        }

        if (languages) {
            const value = optionsLanguage?.filter(item => languages.includes(item.value)) || [];

            setLanguages(value);
        }

        if (timezone) {
            const value = TIMEZONES_OPTIONS.find(item => item.value === timezone) || null;

            setTimezone(value);
        }
    }, [widgetId, optionsCountry, optionsLanguage]);

    return (
        <AnimatePageWrp maxWidth={{md: 700, lg: 800}}>
            <PageTopTitle
                title={<>
                    Do you require that the work is done in a specific <span>country</span> and <span>timezone</span>?
                </>}
                titleMaxWidth={{md: 700, lg: 800}}
            />

            {
                (isLocationLoading) ? (
                    <LoadingMask/>
                ) : (
                    <>

                        <Box sx={{mb: 5}}>
                            <LocationsMulti
                                options={optionsCountry || []}
                                values={countries}
                                onChange={setCountries}
                            />
                        </Box>

                        <Box sx={{pb: 5}}>
                            <TimeZone
                                value={timezone}
                                onChange={setTimezone}
                            />
                        </Box>

                        <Languages
                            options={optionsLanguage || []}
                            values={languages}
                            onChange={setLanguages}
                        />

                    </>
                )
            }

            <Box
                textAlign="center"
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '40px'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '51px'
                    }
                }}
            >
                <ContinueButton
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    hasArrow
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};

export default WidgetLocationTimezone;
