import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../../api';
import {CLIENT_ENGAGEMENT, RouteClientPortal, RouteFreelancerPortal} from '../../../constants';
import {useAuth} from '../../../hooks';
import {EEngagementFilter, EEngagementSort, IEngagement} from '../../../models';
import {CardSubTitle, CardTitle} from '../../Card/Card';
import LoadingMask from '../../LoadingMask';
import {WhiteWrapperBox} from '../DashboardLayout';
import {EngagementsList} from '../index';
import {ShowMoreButton} from '../Matches';


const MyWorkEmpty = () => {
    const {isClient} = useAuth();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <div>
                <img src="/assets/images/glancing-eyes-emoji.png" width="64" height="64" alt="glancing eyes emoji"/>
            </div>
            <CardTitle
                sx={{
                    mb: '2px',
                    mt: '32px',
                    fontSize: '16px',
                    lineHeight: 1.5
                }}
            >
                No active engagements yet
            </CardTitle>
            <CardSubTitle>
                {isClient
                    ? 'You haven’t hired anybody for an engagement yet.  As soon as you do, your hires will show up here.'
                    : 'Once you start working with a client, your engagement will appear here'
                }
            </CardSubTitle>
        </Box>
    );
};

const MyWork = () => {
    const [items, setItems] = useState<IEngagement[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const {isClient} = useAuth();
    const method = isClient ? clientService.getHires : freelancerService.getWorks;

    const navigate = useNavigate();

    const {
        data: response,
        // isLoading,
    } = useQuery([CLIENT_ENGAGEMENT, EEngagementSort.DESC, EEngagementFilter.Active, 'pageSize=3'],
        method('startDate', EEngagementSort.DESC, EEngagementFilter.Active || '', 3), {
            staleTime: 60 * 1000,
        });

    // pagenable response don't include pause,resume,closure info
    // get needed data for all
    useEffect(() => {
        if (!response) return;

        let isMount = true;

        const fetch = async () => {
            const ids = response.data.map(it => it.id);
            const responses = await Promise.all(ids.map(id => isClient
                ? clientService.getHire(id)()
                : freelancerService.getWork(id)()
            ));

            const newItems = response.data.map((it, index) => responses[index]);

            if (isMount) {
                setItems(newItems);
                setIsLoading(false);
            }
        };

        fetch();

        return () => {
            isMount = false;
        };
    }, [isClient, response]);

    const totalCount = response?.total || 0;

    const isEmpty = !isLoading && !totalCount;

    return (
        <WhiteWrapperBox sx={{
            height: isEmpty ? '100%' : null
        }}>
            <div className="title">
                {isClient ? 'My hires' : 'My work'}
            </div>

            {isLoading ? (
                <Box display="flex" justifyContent="center">
                    <LoadingMask hasNoSpace/>
                </Box>
            ) : (
                <>
                    {
                        items && !isEmpty
                            ? <EngagementsList items={items}/>
                            : <MyWorkEmpty/>
                    }

                    {totalCount > 3 && (
                        <ShowMoreButton
                            onClick={() => navigate((isClient ? RouteClientPortal.myHires : RouteFreelancerPortal.myWorks))}/>
                    )}
                </>
            )}

        </WhiteWrapperBox>
    );
};

export default React.memo(MyWork);
