import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import {Box} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';

import {applicationService, metaService} from '../../../api';
import {EmojiWink} from '../../../assets/icons';
import {
    AnimateHeight,
    AnimatePageWrp,
    ContinueButton,
    LoadingMask,
    NewToolAlertBox,
    PageTopTitle,
    Portal,
    SelectMulti,
    showToastError,
    showToastSuccess
} from '../../../components';
import {GET_TOOLS, RouteCreateApplicantAccount} from '../../../constants';
import {useApplication, useNavigateUTM} from '../../../hooks';
import {EModerationStatus, ITool} from '../../../models';
import theme from '../../../theme';


interface OptionType extends ITool {
    inputValue?: string;
}

const Expertise = () => {
    const {application, isApplicationLoading, refetchApplication} = useApplication();
    const [tools, setTools] = useState<ITool[]>([]);

    const navigate = useNavigateUTM();

    const {
        isLoading: isLoadingData,
        data: toolsData
    } = useQuery([GET_TOOLS], () => metaService.searchTools(''), {
        staleTime: 60 * 60 * 1000,
        select: (response => orderBy(response, 'name'))
    });

    const {mutate, isLoading: isSubmitting} = useMutation(
        applicationService.toolsSubmit,
        {
            onSuccess() {
                showToastSuccess('Tools were saved successfully!');
                navigate(RouteCreateApplicantAccount.verticalsAndBrands);
                refetchApplication();
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(debounce((newValue: OptionType | null) => {
        if (!newValue) {
            return;
        }

        // don't add duplicate
        if (!tools.find(tool => tool.name.toLowerCase() === newValue.name.toLowerCase())) {
            setTools(tools => [...tools, newValue]);
        }
    }, 100), [tools]);

    const handleDelete = useCallback((tool: ITool) => {
        const newTools = tools.filter(t => t.name !== tool.name);

        setTools(newTools);
    }, [tools]);

    const handleSubmit = () => {
        if (!tools.length) {
            showToastError('Please add a tools.');

            // check is dirty or not
        } else if (!isEqual(tools, application?.tools)) {
            mutate(tools);
        } else {
            navigate(RouteCreateApplicantAccount.verticalsAndBrands);
        }
    };

    useEffect(() => {
        if (!application) {
            return;
        }
        setTools(application.tools);
    }, [application]);

    const isLoading = isLoadingData || isSubmitting;
    const isNewToolExist = useMemo(() => !!tools?.some(tool => !tool.id || (tool.moderationStatus && tool.moderationStatus === EModerationStatus.NEW)), [tools]);

    return (
        <AnimatePageWrp maxWidth={{md: 600}}>
            <PageTopTitle
                title={<>Alright, now, what <span>tools</span> are you an expert in using?</>}
                subtitle={<>You can enter as many as you would like so don’t hold back<EmojiWink/></>}
                titleMaxWidth={{
                    md: 358,
                    lg: 551
                }}
            />

            {
                isApplicationLoading
                    ? (
                        <LoadingMask/>
                    ) : (
                        <Box sx={{
                            marginTop: '43px',
                            [theme.breakpoints.up('lg')]: {
                                marginTop: '67px'
                            }
                        }}>
                            <SelectMulti
                                freeSolo
                                options={toolsData || []}
                                placeholder="Ex. HubSpot, MailChimp, ActiveCampaign"
                                pressCaption="tool"
                                selected={tools}
                                onChange={handleChange}
                                onDelete={handleDelete}
                            />
                        </Box>
                    )
            }

            <AnimateHeight isVisible={isNewToolExist} isOverflowHidden={false}>
                <NewToolAlertBox/>
            </AnimateHeight>

            <Box
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '56px'
                    }
                }}
                textAlign="center"
            >
                <ContinueButton
                    disabled={isApplicationLoading || isLoading}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isApplicationLoading || isLoading}
                    hasArrow
                    variant="contained"
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>

        </AnimatePageWrp>
    );
};

export default Expertise;
