import React from 'react';

import {Box, styled} from '@mui/material';

import {CloseOutlineIcon} from '../../../../assets/icons';
import theme from '../../../../theme';
import {getFileName, getFileTypeIcon} from '../../../../utils';

import {isFileValid} from './validate';

const Wrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
`;

const FileItem = styled('div')<{error: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;
    font-size: 14px;
    color: ${props => props.error ? theme.palette.error.main : 'inherit'};
    border: 1px solid ${props => props.error ? theme.palette.error.main : theme.palette.lightGray.main};
    border-radius: 24px;

    & svg:not(.close-icon) {
        path {
          fill: ${theme.palette.blue.dark};
          fill-opacity: 1;
        }
    }

    & svg.close-icon:hover {
        cursor: pointer;
        path {
          fill: ${theme.palette.purple.main};
          fill-opacity: 1;
        }
    }
`;

type FilesListProps = {
    items: File[];
    onRemove: (file: File) => void;
};

const FilesList: React.FC<FilesListProps> = ({items, onRemove}) => {
    return (
        <Wrapper>
            {items.map((file, index) => (
                <FileItem error={!isFileValid(file)} key={index}>

                    {getFileTypeIcon(file.name)}

                    {getFileName(file.name, 15)}

                    <Box display="contents" onClick={() => onRemove(file)}>
                        <CloseOutlineIcon/>
                    </Box>

                </FileItem>
            ))}
        </Wrapper>
    );
};

export default FilesList;
