import React from 'react';

interface IProps {
    fill?: string;
}

const ClockIcon = ({fill}: IProps) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={fill || '#535A62'} xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33333 1.33398C6.01478 1.33398 4.72585 1.72498 3.62952 2.45752C2.5332 3.19006 1.67871 4.23125 1.17413 5.44943C0.669545 6.6676 0.537523 8.00805 0.794758 9.30125C1.05199 10.5945 1.68693 11.7823 2.61928 12.7147C3.55163 13.647 4.73952 14.282 6.03272 14.5392C7.32593 14.7965 8.66638 14.6644 9.88455 14.1598C11.1027 13.6553 12.1439 12.8008 12.8765 11.7045C13.609 10.6081 14 9.31919 14 8.00065C14 7.12517 13.8276 6.25827 13.4925 5.44943C13.1575 4.64059 12.6664 3.90566 12.0474 3.28661C11.4283 2.66755 10.6934 2.17649 9.88455 1.84145C9.07571 1.50642 8.20881 1.33398 7.33333 1.33398ZM7.33333 13.334C6.27849 13.334 5.24735 13.0212 4.37028 12.4352C3.49322 11.8491 2.80964 11.0162 2.40597 10.0416C2.0023 9.06709 1.89668 7.99473 2.10247 6.96017C2.30826 5.9256 2.81621 4.97529 3.56209 4.22941C4.30797 3.48353 5.25828 2.97558 6.29284 2.7698C7.32741 2.56401 8.39977 2.66963 9.3743 3.07329C10.3488 3.47696 11.1818 4.16055 11.7678 5.03761C12.3539 5.91467 12.6667 6.94582 12.6667 8.00065C12.6667 9.41514 12.1048 10.7717 11.1046 11.7719C10.1044 12.7721 8.74781 13.334 7.33333 13.334ZM7.33333 4.00065C7.15652 4.00065 6.98695 4.07089 6.86192 4.19591C6.7369 4.32094 6.66666 4.49051 6.66666 4.66732V7.61398L5.26666 8.42065C5.13823 8.49343 5.03759 8.60677 4.98051 8.7429C4.92344 8.87904 4.91316 9.03027 4.95129 9.17287C4.98942 9.31548 5.0738 9.44139 5.1912 9.53088C5.3086 9.62036 5.45238 9.66835 5.59999 9.66732C5.71678 9.66812 5.83172 9.63824 5.93333 9.58065L7.66666 8.58065L7.72666 8.52065L7.83333 8.43398C7.85939 8.40098 7.88175 8.36521 7.89999 8.32732C7.92172 8.29153 7.9396 8.25354 7.95333 8.21398C7.97146 8.1716 7.98271 8.12659 7.98666 8.08065L7.99999 8.00065V4.66732C7.99999 4.49051 7.92976 4.32094 7.80473 4.19591C7.67971 4.07089 7.51014 4.00065 7.33333 4.00065Z" fill="#121416"/>
        </svg>
    );
};

export default ClockIcon;
