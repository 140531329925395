import React from 'react';

const EyeClosedIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.67611 6.50454C2.91184 7.3996 2.25232 8.46304 1.73363 9.66732C1.68775 9.77246 1.66406 9.88594 1.66406 10.0007C1.66406 10.1154 1.68775 10.2288 1.73363 10.334C3.41697 14.2423 6.58363 16.6673 10.0003 16.6673C11.0841 16.6673 12.1428 16.4233 13.1369 15.9653L11.8557 14.6842C11.2537 14.8919 10.631 15.0007 10.0003 15.0007C7.35863 15.0007 4.85863 13.0923 3.41697 10.0007C3.82204 9.13197 4.31067 8.3567 4.86251 7.69094L3.67611 6.50454Z"
                fill="#999999"/>
            <path
                d="M6.70051 9.52894C6.64747 9.90002 6.65711 10.2794 6.73102 10.651C6.85963 11.2976 7.1771 11.8915 7.64328 12.3577C8.10945 12.8238 8.7034 13.1413 9.35 13.2699C9.72156 13.3438 10.1009 13.3535 10.472 13.3004L6.70051 9.52894Z"
                fill="#999999"/>
            <path
                d="M13.3002 10.4718L9.5292 6.70077C9.68463 6.67858 9.84204 6.66732 10.0003 6.66732C10.8844 6.66732 11.7322 7.01851 12.3573 7.64363C12.9824 8.26875 13.3336 9.1166 13.3336 10.0007C13.3336 10.1589 13.3224 10.3163 13.3002 10.4718Z"
                fill="#999999"/>
            <path
                d="M15.1384 12.31C15.6901 11.6443 16.1786 10.8692 16.5836 10.0007C15.142 6.90898 12.642 5.00065 10.0003 5.00065C9.36977 5.00065 8.74731 5.10937 8.14538 5.31695L6.86418 4.03575C7.85817 3.57792 8.91666 3.33398 10.0003 3.33398C13.417 3.33398 16.5836 5.75898 18.267 9.66732C18.3129 9.77246 18.3365 9.88594 18.3365 10.0007C18.3365 10.1154 18.3129 10.2288 18.267 10.334C17.7484 11.5381 17.0889 12.6014 16.3248 13.4964L15.1384 12.31Z"
                fill="#999999"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M1.29289 3.29289C1.68342 2.90237 2.31658 2.90237 2.70711 3.29289L16.7071 17.2929C17.0976 17.6834 17.0976 18.3166 16.7071 18.7071C16.3166 19.0976 15.6834 19.0976 15.2929 18.7071L1.29289 4.70711C0.902369 4.31658 0.902369 3.68342 1.29289 3.29289Z"
                fill="#999999"/>
        </svg>
    );
};

export default EyeClosedIcon;
