import axiosInstance from './base.api';

const check = async (key: string, value: string) => {
    return await axiosInstance.get<null>(`check?${key}=${encodeURIComponent(value)}`);
};

const resendCode = async (processId: number) => {
    return await axiosInstance.patch<null>(`email/verification-process/${processId}`);
};

const readMessage = async (conversationId: string, messageSid: string) => {
    return await axiosInstance.get<null>(`/twilio/conversation/${conversationId}/messages/${messageSid}/read`);
};

const updateEmail = async (email: string) => {
    return await axiosInstance.put<null>('email', {email});
};

const updatePassword = async (password: string) => {
    return await axiosInstance.put<null>('/password', {password});
};

const verifyCode = async (processId: number, code: string) => {
    return await axiosInstance.post<null>(`email/verification-process/${processId}`, {code});
};


export const emailService = {
    check,
    readMessage,
    resendCode,
    updateEmail,
    updatePassword,
    verifyCode,
};
