import React from 'react';
import {Outlet} from 'react-router-dom';

import {styled} from '@mui/material';

import {ErrorBoundary, ImpersonalWrapper} from '../../../components';
import {MD} from '../../../constants';
import {TwilioProvider} from '../../../contexts';
import {useAuth} from '../../../hooks';
import theme from '../../../theme';

import {ClientPortalHeader} from './components';

const Wrp = styled('div')`
  min-height: 100vh;
  background-color: ${theme.palette.lightGray.light};
  padding-top: 72px;
  padding-bottom: 24px;

  @media (min-width: ${MD}) {
    padding-top: 82px;
    padding-bottom: 84px;
  }
`;

const Container = styled('div')`
  max-width: 1672px;
  margin: 0 auto;
  padding: 0 20px;
  //overflow: hidden;
`;

const ClientPortalLayout = () => {
    // const {isImpersonal} = useAuth();       // HOTFIX disabled for now
    const {isImpersonalBorder} = useAuth();

    return (
        <TwilioProvider>
            <Wrp>
                {isImpersonalBorder && <ImpersonalWrapper/>}
                <ClientPortalHeader/>
                <Container>
                    <ErrorBoundary>
                        <Outlet/>
                    </ErrorBoundary>
                </Container>
            </Wrp>
        </TwilioProvider>
    );
};

export default ClientPortalLayout;
