import React, {EventHandler} from 'react';

import {
    FormControl,
    IconButton,
    Input,
    InputAdornment as MuiInputAdornment,
    InputBaseProps,
    InputLabel,
    TextFieldProps
} from '@mui/material';

import theme from '../../../theme';
import {equalMemo} from '../../../utils';

export type IInputAdornment = InputBaseProps & TextFieldProps & {
    icon: JSX.Element | string;
    inputRef?: React.Ref<any>;
    label?: string;
    placeholder?: string;
    position: 'start' | 'end';
    onIconClick?: EventHandler<any>;
}

const InputAdornment = ({
    position,
    icon,
    inputRef,
    label,
    placeholder,
    onIconClick,
    ...props
}: IInputAdornment): JSX.Element => {
    return (
        <FormControl
            fullWidth
        >
            {label && (
                <InputLabel>{label}</InputLabel>
            )}
            <Input
                {...props}
                placeholder={placeholder}
                endAdornment={
                    position === 'end' &&
                  <MuiInputAdornment
                      position="end"
                  >
                      {
                          onIconClick
                              ? <IconButton
                                  edge="end"
                                  onClick={onIconClick}
                              >
                                  {icon}
                              </IconButton>
                              : icon
                      }
                  </MuiInputAdornment>
                }
                startAdornment={
                    position === 'start' &&
                  <MuiInputAdornment
                      position="start"
                  >
                      {
                          onIconClick
                              ? <IconButton
                                  edge="start"
                                  onClick={onIconClick}
                              >
                                  {icon}
                              </IconButton>
                              : icon
                      }
                  </MuiInputAdornment>
                }
                inputRef={inputRef}
                sx={{
                    '&.Mui-focused': {
                        '.MuiInputAdornment-root': {
                            '.MuiTypography-root': {
                                color: theme.palette.primary.main
                            },
                        }
                    },
                    '.MuiInputAdornment-root': {
                        'p': {
                            paddingBottom: '4px',
                            fontSize: '14px !important',
                            color: 'tomato !important'
                        }
                    },
                    '&.MuiInput-root': {
                        '&::before': {
                            borderBottom: '2px solid',
                            borderBottomColor: theme.palette.inputDisabledBorderColor.main
                        },
                        '&:hover': {
                            '&::before': {
                                borderBottomColor: theme.palette.primary.main
                            }
                        }
                    },
                    ...props.sx
                }}
            />
        </FormControl>
    );
};

export default React.memo(InputAdornment, equalMemo(['inputRef']));
