import {EPaymentStatus, IPaymentPreview} from './payment.model';
import {EWorkType} from './work.model';

export interface ISpendingReport {
    workTime: string;
    money: number;
    activeRoles: number;
    spendings: ISpending[];
}

export interface ISpending {
    amount: number;
    concludedAt: string;
    createdAt: string;
    description: string;
    endDate: string;
    freelancerAvatar: string;
    freelancerName: string;
    id: number;
    invoiceFile: string;
    invoiceName: string;
    method: IPaymentPreview;
    problem: string;
    roleName: string;
    serviceFee: number;
    startDate: string;
    status: EPaymentStatus;
    workType: EWorkType;
}

export enum EWorkTypePayment {
    fixed = 'Fixed Price',
    hourly = 'Hourly Payment',
    retainer = 'Retainer Payment',
    FIXED = 'Fixed Price',
    HOURLY = 'Hourly Payment',
    RETAINER = 'Retainer Payment',
}
