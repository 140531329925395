import React, {useCallback} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText, Grid} from '@mui/material';

import {EWorkType, EWorkTypeDisplay} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight, CheckButton} from '../..';

interface InputWorkTypeProps {
    name: string;
    onChange?: (value: EWorkType | null) => void;
}

export const InputWorkType: React.FC<InputWorkTypeProps> = React.memo(({name, ...props}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const workType: EWorkType = value;

    const isRetainer = workType === EWorkType.retainer;
    const isHourly = workType === EWorkType.hourly;
    const isFixed = workType === EWorkType.fixed;

    const handleClickType = useCallback((value?: string) => {
        const newWorkType = value as EWorkType;
        const newValue = workType !== newWorkType ? newWorkType : null;

        onChange(newValue);
        props.onChange && props.onChange(newValue);

        trigger(name);
    }, [name, props, workType, onChange, trigger]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <Box tabIndex={0} ref={ref}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={4}>
                            <CheckButton
                                id={EWorkType.hourly}
                                label={EWorkTypeDisplay.hourly}
                                selected={isHourly}
                                onClick={handleClickType}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <CheckButton
                                id={EWorkType.fixed}
                                label={EWorkTypeDisplay.fixed}
                                selected={isFixed}
                                onClick={handleClickType}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <CheckButton
                                id={EWorkType.retainer}
                                label={EWorkTypeDisplay.retainer}
                                selected={isRetainer}
                                onClick={handleClickType}
                            />
                        </Grid>
                    </Grid>

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </Box>
            )}
        />
    );
});
