import React, {useState} from 'react';
import {AxiosError} from 'axios';
import {DateRange} from 'mui-daterange-picker';
import {useNavigate} from 'react-router-dom';

import {Box, styled} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService, timesheetsService} from '../../api';
import {MD, REPORTS, RouteClientPortal, RouteFreelancerPortal} from '../../constants';
import {useAuth} from '../../hooks';
import theme from '../../theme';
import {ClientPortalTitle, LoadingMask, showToastError, Tab, Tabs} from '..';

import Filters from './components/Filters';
import HeaderTotals from './components/HeaderTotals';
import {ReportDate, ReportRole} from './components';
import {prepareQueryParams} from './helper';

export const enum EDisplayMode {
    Role = 'role',
    Date = 'date',
}

const PageTopWrp = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 22px;

  @media (min-width: ${MD}) {
    gap: 24px;
  }
`;

const ReportsPage = () => {
    const {isClient} = useAuth();
    const navigate = useNavigate();
    const [mode, setMode] = useState(EDisplayMode.Role);
    const [dateRange, setDateRange] = useState<DateRange | undefined>();
    const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);

    const apiService = isClient ? clientService : timesheetsService;

    // get data grouped by Date
    const {
        isLoading: isLoadingDate,
        data: dataByDate
    } = useQuery([REPORTS, EDisplayMode.Date, dateRange?.startDate, dateRange?.endDate, selectedRoleIds.join(',')],
        () => apiService.getTimesheetsDate(prepareQueryParams(selectedRoleIds, dateRange)), {
            enabled: mode === EDisplayMode.Date,
            staleTime: 5 * 60 * 1000,
            onError: (error) => showToastError(error as AxiosError)
        }
    );

    // get data grouped by Role
    const {
        isLoading: isLoadingRole,
        data: dataByRole
    } = useQuery([REPORTS, EDisplayMode.Role, dateRange?.startDate, dateRange?.endDate, selectedRoleIds.join(',')],
        () => apiService.getTimesheetsRole(prepareQueryParams(selectedRoleIds, dateRange)), {
            enabled: mode === EDisplayMode.Role,
            staleTime: 5 * 60 * 1000,
            onError: (error) => showToastError(error as AxiosError)
        });

    const isLoading = (mode === EDisplayMode.Date && isLoadingDate) || (mode === EDisplayMode.Role && isLoadingRole);

    return (
        <Box
            sx={{
                pt: '38px',
                [theme.breakpoints.up('md')]: {
                    pt: '56px'
                }
            }}
        >
            <PageTopWrp>
                <ClientPortalTitle>My Timesheets</ClientPortalTitle>
                <Tabs
                    sx={{
                        width: '100%',
                        [theme.breakpoints.up('sm')]: {
                            ml: 'auto',
                            width: 'auto'
                        }
                    }}
                >
                    {
                        isClient ? (
                            <>
                                <Tab
                                    isActive={false}
                                    onClick={() => navigate(RouteClientPortal.mySpendings)}
                                >
                                    Spendings
                                </Tab>
                                <Tab
                                    isActive={true}
                                    onClick={() => navigate(RouteClientPortal.reports)}
                                >
                                    Timesheets
                                </Tab>
                            </>
                        ) : (
                            <>
                                <Tab
                                    isActive={false}
                                    onClick={() => navigate(RouteFreelancerPortal.myEarnings)}
                                >
                                    Earnings
                                </Tab>
                                <Tab
                                    isActive={true}
                                    onClick={() => navigate(RouteFreelancerPortal.reports)}
                                >
                                    Timesheets
                                </Tab>
                            </>
                        )
                    }
                </Tabs>
            </PageTopWrp>

            <Box
                sx={{
                    m: '0 auto',
                    maxWidth: '884px'
                }}
            >
                <HeaderTotals
                    data={mode === EDisplayMode.Date ? dataByDate : dataByRole}
                    selectedRoleIds={selectedRoleIds}
                    onSelectRole={setSelectedRoleIds}
                />

                <Box sx={{mb: '16px'}}>
                    <Filters
                        dateRange={dateRange}
                        groupBy={mode}
                        onDateRange={setDateRange}
                        onGroup={setMode}
                    />
                </Box>

                {isLoading
                    ? <LoadingMask/>
                    : (
                        <>
                            {mode === EDisplayMode.Date && dataByDate && (
                                <ReportDate data={dataByDate}/>
                            )}

                            {mode === EDisplayMode.Role && dataByRole && (
                                <ReportRole data={dataByRole}/>
                            )}
                        </>
                    )
                }
            </Box>
        </Box>
    );
};

export default React.memo(ReportsPage);
