import {useQuery} from '@tanstack/react-query';

import {clientService} from '../api';
import {CLIENT_ROLES} from '../constants';
import {ActionTypes, useStateContext} from '../contexts';
import {ERoleStatus} from '../models';
import {urlByClientView} from '../routes/routes.utils';
import {isWidget} from '../utils';

import {useNavigateUTM} from './navigate-utm';

export const useClientRole = (roleId?: number) => {
    const navigate = useNavigateUTM();
    const globalContext = useStateContext();

    // Get all user roles and find first not confirmed yet
    const {data: role} = useQuery(
        [CLIENT_ROLES],
        clientService.getRoles([ERoleStatus.Draft]), {
            enabled: typeof roleId !== 'number',
            select: (response) => response.data.find(role => role.view !== 'confirmed'), // FIXME how last step will be called at the BE
        });

    const {data, isLoading, isFetching, refetch} = useQuery(
        [CLIENT_ROLES, roleId || role?.id],
        clientService.getRoleById(roleId || role?.id || 0), {
            enabled: typeof roleId === 'number' || !!role?.id,
            staleTime: 60 * 1000,
            select: (data) => data,
            onSuccess: (data) => {
                globalContext.dispatch({type: ActionTypes.SET_CLIENT, payload: data});

                if (!roleId && !globalContext.state.clientRole && !isWidget()) {
                    navigate(urlByClientView(data.view));
                }
            }
        });

    return {
        clientRole: data,
        isClientLoading: isLoading || isFetching,
        refetchClient: refetch
    };
};
