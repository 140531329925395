import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {Typography} from '@mui/material';

import {authService, ITokenResponse} from '../../../api';
import {LoadingMask} from '../../../components';
import {RouteClientPortal, RouteFreelancerPortal} from '../../../constants';
import {useAuth} from '../../../hooks';

const Impersonal = () => {
    const {isClient, isFreelancer, isImpersonal, refreshUser} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const navigate = useNavigate();

    useEffect(() => {
        if ((!isClient && !isFreelancer) || isLoading) return;

        console.log('navigate to portal', (isClient ? RouteClientPortal : RouteFreelancerPortal).default);
        navigate((isClient ? RouteClientPortal : RouteFreelancerPortal).default, {replace: true});
    }, [isClient, isFreelancer, isLoading, navigate]);

    useLayoutEffect(() => {
        if (!token) return;

        authService.clearToken();
        authService.saveToken({access_token: decodeURIComponent(token)} as ITokenResponse);

        setTimeout(() => {
            refreshUser();
            setIsLoading(false);
        }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    if (isLoading) {
        return (
            <>
                <LoadingMask/>
            </>
        );
    }

    if (!isClient && !isFreelancer && isImpersonal) {
        return (
            <Typography>USER UNVERIFIED</Typography>
        );
    }

    return (
        <Typography>Impersonal mode</Typography>
    );
};

export default Impersonal;
