import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {Box, IconButton, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {AccountNoOutlineIcon, CloseIcon, SearchIcon} from '../../assets/icons';
import {CLIENT_ROLES, GET_MATCHED, LG, MATCHES, MD, RouteClientPortal, RouteFreelancerPortal, XL} from '../../constants';
import {TwilioContext} from '../../contexts';
import {useAuth, useConversation, useTwilio} from '../../hooks';
import {EConversationStatus, ERoleStatusFilter, IConversationAttributes, IConversationMeta} from '../../models';
import theme from '../../theme';
import LoadingMask from '../LoadingMask';
import {ErrorBoundary, InputAdornmentFilled} from '..';

import {
    ActiveCallListItem,
    ArchiveListEmpty,
    ChatBottom,
    ChatDetails,
    ChatEmptyPlaceholder,
    ChatList,
    ChatMain,
    ChatMainMessages,
    ChatMainTop,
    IncomingListItem,
    SearchEmptyResult,
    StatusFilter,
    VideoRoom
} from './components';

const PageWrp = styled('div')`
  height: calc(100vh - 67px);
  margin: -5px -20px -24px;
  background-color: ${theme.palette.white.main};

  @media (min-width: ${MD}) {
    margin-top: -15px;
    margin-bottom: -84px;
  }

  @media (min-width: ${LG}) {
    height: calc(100vh - 170px);
    margin: 64px 0 -60px;
    background-color: ${theme.palette.white.main};
    border-radius: 24px;
  }

  @media (min-height: 968px) {
    margin-bottom: 0;
    height: calc(100vh - 278px);
  }

  @media (min-height: 968px) and (max-width: ${LG}) {
    height: calc(100vh - 67px);
  }
`;

const ChatLeftAside = styled('div')<{ isHidden?: boolean }>`
  padding: 0 6px;
  height: inherit;
  overflow-y: auto;
  display: ${props => props.isHidden ? 'none' : 'flex'};
  flex-direction: column;
  width: 100%;
  position: relative;

  @media (min-width: ${LG}) {
    flex-shrink: 0;
    border-right: 1px solid ${theme.palette.lightGray.main};
    display: flex;
    flex-direction: column;
    width: 316px;
    border-radius: 24px 0 0 0;
  }

  @media (min-width: ${XL}) {
    width: 430px;
  }
`;

export const ACTIVE_STATUSES = [EConversationStatus.Accepted, EConversationStatus.New, EConversationStatus.Proposed];
export const ARCHIVED_STATUSES = [EConversationStatus.Rejected];

const filterByStatus = (status: EConversationStatus, filterBy: ERoleStatusFilter): boolean => {
    switch (filterBy) {
        case ERoleStatusFilter.Active:
            return ACTIVE_STATUSES.includes(status);

        case ERoleStatusFilter.Archive:
            return ARCHIVED_STATUSES.includes(status);

        default:
            return false;
    }
};

const InboxPage = () => {
    const {conversationId, videoCallRoomId} = useParams();

    const {attributes: {matchId, status, roleId}, calls} = useConversation(conversationId || '');

    const [chatDetailsVisible, setChatDetailsVisible] = useState(false);
    const [filterBy, setFilterBy] = useState(ERoleStatusFilter.Active);
    const {isFreelancer, isClient} = useAuth();
    const [searchValue, setSearchValue] = useState<string>('');

    const {loading, finishConversation} = useTwilio();

    const {activeCall, allUnreadMessagesCount, conversations, setIsDragActive} = useContext(TwilioContext);

    const queryClient = useQueryClient();
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(1400));

    const {isDragActive, getRootProps} = useDropzone({});

    // waiting while conversation's participants, avatars and last message
    // are loaded and then useMemo will update array and re-render a list
    const metaHash = conversations?.length ? (conversations[0] as any).meta : '';

    // search by Role or Participant's name
    const filteredConversations = useMemo(() => {
        const value = searchValue.trim().toLowerCase();

        // Filter Active or Archived
        const items = conversations.filter(conversation => filterByStatus((conversation.attributes as unknown as IConversationAttributes).status, filterBy));

        if (!value) {
            return items;
        }

        // Filter by search text
        return items.filter(conversation => {
            const meta = (conversation as any)?.meta as IConversationMeta;

            return conversation.friendlyName?.toLowerCase().includes(value) || meta?.remoteParticipant?.name?.toLowerCase().includes(value);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUnreadMessagesCount, conversations, metaHash, filterBy, searchValue]);

    const handleChatDetailsOpen = useCallback((isDetailsOpen: boolean) => {
        if (xlUp) {
            setChatDetailsVisible(true);
        } else {
            setChatDetailsVisible(isDetailsOpen);
        }
    }, [xlUp]);

    const handleOpenDetails = useCallback(() => {
        setChatDetailsVisible(!chatDetailsVisible);
    }, [chatDetailsVisible]);

    // jump to current active call or open selected conversation
    const switchConversation = useCallback((conversationId: string) => {
        if (activeCall && activeCall.pathname.includes(conversationId)) {
            navigate(activeCall.pathname);
        } else if (isClient) {
            navigate(`${RouteClientPortal.inbox}/${conversationId}`);
        } else if (isFreelancer) {
            navigate(`${RouteFreelancerPortal.inbox}/${conversationId}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCall, isFreelancer, isClient]);

    useEffect(() => {
        return () => finishConversation();
    }, [finishConversation]);

    useEffect(() => {
        setIsDragActive(isDragActive);
    }, [isDragActive, setIsDragActive]);

    // check at the start do we need switch to Archibe tab or not
    useEffect(() => {
        if (!conversationId || !conversations?.length) return;

        const conversation = conversations.find(it => it.sid === conversationId);

        if (!conversation) return;

        const isArchived = filterByStatus((conversation.attributes as unknown as IConversationAttributes).status, ERoleStatusFilter.Archive);

        if (isArchived) {
            setFilterBy(ERoleStatusFilter.Archive);
        }
    }, [conversationId, conversations]);

    // detect if Proposal Accepted then
    // Role should be removed from the My Matches page and shown on the My Hires page.
    useEffect(() => {
        if (status === EConversationStatus.Accepted) {
            queryClient.invalidateQueries([MATCHES, Number(matchId)]);
            queryClient.invalidateQueries([CLIENT_ROLES, roleId, MATCHES]);
            queryClient.invalidateQueries([GET_MATCHED]);
        }
    }, [matchId, roleId, queryClient, status]);


    const isArchiveEmpty = filterBy === ERoleStatusFilter.Archive && !searchValue && !filteredConversations.length;
    const isSearchEmpty = searchValue && !filteredConversations.length;

    return (
        <PageWrp
            {...getRootProps()}
            onClick={event => event.stopPropagation()}
        >

            {loading && <LoadingMask/>}

            {!loading && !conversations.length && <ChatEmptyPlaceholder/>}

            {!loading && !!conversations.length && (
                <Box
                    sx={{
                        display: 'flex',
                        height: 'inherit',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <ChatLeftAside isHidden={!!conversationId}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '24px',
                                p: '24px 6px 16px 6px',
                                [theme.breakpoints.up('md')]: {
                                    p: '30px 17px 16px 18px'
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    lineHeight: 1.5,
                                    [theme.breakpoints.up('md')]: {
                                        fontSize: '24px',
                                        lineHeight: 1
                                    }
                                }}
                            >
                                Inbox
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                    color: theme.palette.gray.main
                                }}
                            >
                                {allUnreadMessagesCount ? `${allUnreadMessagesCount} new messages` : ''}
                            </Typography>
                        </Box>

                        <Box sx={{p: '0 16px 0 18px'}}>
                            <InputAdornmentFilled
                                name="search"
                                placeholder="Search..."
                                icon={<SearchIcon/>}
                                position="start"
                                onChange={event => setSearchValue(event.target.value)}
                            />
                        </Box>

                        <Box sx={{p: '16px 16px 0 18px'}}>
                            <StatusFilter filterBy={filterBy} onFilterChange={setFilterBy}/>
                        </Box>

                        <ErrorBoundary>
                            {/* Incomming Call */}
                            {!activeCall && calls?.map(call => <IncomingListItem data={call} key={call.conversationId}/>)}

                            {/* On Call but with minimized state */}
                            {activeCall && pathname !== activeCall?.pathname && <ActiveCallListItem/>}

                            {/* show No Records or list */}
                            {isArchiveEmpty
                                ? <ArchiveListEmpty/>
                                : isSearchEmpty
                                    ? <SearchEmptyResult/>
                                    : (
                                        <ChatList
                                            conversations={filteredConversations}
                                            onClick={switchConversation}
                                        />
                                    )
                            }

                        </ErrorBoundary>
                    </ChatLeftAside>

                    {conversationId && (
                        <>
                            <ChatMain>
                                <ErrorBoundary>
                                    {
                                        (activeCall || videoCallRoomId) && (
                                            <VideoRoom conversationId={conversationId}/>
                                        )
                                    }
                                    <ChatMainTop
                                        conversationId={conversationId || ''}
                                        onUserInfoClick={handleChatDetailsOpen}
                                    />
                                    <ChatMainMessages conversationId={conversationId || ''}/>
                                    <ChatBottom conversationId={conversationId || ''}/>
                                </ErrorBoundary>
                            </ChatMain>

                            <ErrorBoundary>
                                <ChatDetails
                                    isVisible={chatDetailsVisible || xlUp}
                                    onChatDetailsOpen={handleChatDetailsOpen}
                                />
                            </ErrorBoundary>

                            {
                                lgUp && !xlUp && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '0',
                                            right: chatDetailsVisible ? '455px' : '0',
                                            width: '76px',
                                            height: '87px',
                                            p: '24px 24px 24px 12px',
                                            backgroundColor: theme.palette.white.main,
                                            transition: 'right .3s linear',
                                            borderRadius: '0 24px 0 0',
                                            willChange: 'left'
                                        }}
                                    >
                                        <IconButton
                                            onClick={handleOpenDetails}
                                            sx={{
                                                border: '1px solid ' + theme.palette.lightGray.main,
                                                p: chatDetailsVisible ? '5px' : '10px',
                                                '.close-icon': {
                                                    width: '28px',
                                                    height: '28px',
                                                    'svg': {
                                                        width: 'inherit',
                                                        height: 'inherit'
                                                    },
                                                    'path': {
                                                        fill: theme.palette.textGray.dark
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                (chatDetailsVisible) ? (
                                                    <CloseIcon/>
                                                ) : (
                                                    <AccountNoOutlineIcon/>
                                                )
                                            }

                                        </IconButton>
                                    </Box>
                                )
                            }
                        </>
                    )}
                </Box>
            )}

        </PageWrp>
    );
};

export default InboxPage;
