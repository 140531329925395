import React from 'react';
import type {RouteObject} from 'react-router-dom';

import {Typography} from '@mui/material';

import {
    CaseStudy,
    CaseStudyPreviewFreelancer,
    ContactUsPage,
    DashboardPage,
    InboxPage,
    PublicFreelancerProfilePageForFreelancer,
    ReportsPage
} from '../components';
import {
    ACCOUNT,
    BILLING,
    CASE_STUDIES,
    CERTIFICATES,
    DETAILS,
    GENERAL,
    PAYMENT,
    PROCESS,
    REFERENCES,
    RouteFreelancerPortal,
    SETTINGS,
    SUPPORT
} from '../constants';
import {ECaseStudySteps, ERole} from '../models';
import {RequireUser} from '../modules/Auth';
import {
    AccountTab,
    AddCertificate,
    BillingTab,
    CaseStudiesTab,
    CaseStudyEditPage,
    CertificatesTab,
    FreelancerPortalLayout,
    GeneralTab,
    HelpPage,
    MyEarningsPage,
    MyMatchesDetailsPage,
    MyMatchesPage,
    MyWorkDetailsPage,
    MyWorkPage,
    PaymentTab,
    ProcessTab,
    ProfilePage,
    ProfileTab,
    SupportTab
} from '../modules/FreelancerPortal';

import {errorsRoutes} from './errors.router';

export const freelancerPortalRoutes: RouteObject = {
    path: RouteFreelancerPortal.default,
    element: <FreelancerPortalLayout/>,
    children: [
        {
            path: '',
            element: <RequireUser allowedRoles={[ERole.FREELANCER]}/>,
            children: [
                {
                    index: true,
                    element: <DashboardPage/>
                },
                {
                    path: RouteFreelancerPortal.myWorks,
                    element: <MyWorkPage/>
                },
                {
                    path: `${RouteFreelancerPortal.myWorkDetails}/:engagementId`,
                    element: <MyWorkDetailsPage/>
                },
                {
                    path: `${RouteFreelancerPortal.myWorkDetails}/:engagementId/:tab`,
                    element: <MyWorkDetailsPage/>
                },
                {
                    path: `${RouteFreelancerPortal.myWorkDetails}/:engagementId/:tab/view/:timesheetId`,
                    element: <MyWorkDetailsPage/>
                },
                {
                    path: `${RouteFreelancerPortal.myWorkDetails}/:engagementId/:tab/:videoCallRoomId`,
                    element: <MyWorkDetailsPage/>
                },
                {
                    path: `${RouteFreelancerPortal.myWorkDetails}/:engagementId/:tab/:videoCallRoomId/:isAudioOnly`,
                    element: <MyWorkDetailsPage/>
                },
                {
                    path: `${RouteFreelancerPortal.myWorks}/:sortBy/:filterBy`,
                    element: <MyWorkPage/>,
                },
                {
                    path: RouteFreelancerPortal.myMatches,
                    element: <MyMatchesPage/>,
                },
                {
                    path: `${RouteFreelancerPortal.myMatches}/${DETAILS}/:matchId`,
                    element: <MyMatchesDetailsPage/>,
                },
                {
                    path: `${RouteFreelancerPortal.myMatches}/:filterBy`,
                    element: <MyMatchesPage/>,
                },
                {
                    path: RouteFreelancerPortal.myProfile,
                    element: <Typography variant="h4">My Profile page</Typography>
                },
                {
                    path: RouteFreelancerPortal.profileSettings,
                    element: <ProfilePage/>,
                    children: [{
                        path: ACCOUNT,
                        element: <AccountTab/>
                    },
                    {
                        path: DETAILS,
                        element: <ProfileTab/>
                    },
                    {
                        path: CASE_STUDIES,
                        element: <CaseStudiesTab/>
                    },
                    {
                        path: REFERENCES,
                        element: <Typography variant="h4">References tab</Typography>
                    },
                    {
                        path: CERTIFICATES,
                        element: <CertificatesTab/>
                    },
                    {
                        path: BILLING,
                        element: <BillingTab/>
                    },
                    {
                        path: SETTINGS,
                        element: <Typography variant="h4">Settings tab</Typography>
                    }]
                },
                {
                    path: `${RouteFreelancerPortal.profileSettings}/${CERTIFICATES}/:certificateId`,
                    element: <AddCertificate/>
                },
                {
                    path: RouteFreelancerPortal.myEarnings,
                    element: <MyEarningsPage/>
                },
                {
                    path: RouteFreelancerPortal.inbox,
                    element: <InboxPage/>
                },
                {
                    path: `${RouteFreelancerPortal.inbox}/:conversationId`,
                    element: <InboxPage/>,
                },
                {
                    path: `${RouteFreelancerPortal.inbox}/:conversationId/:videoCallRoomId`, // audio/video call room name /call`,
                    element: <InboxPage/>,
                },
                {
                    path: `${RouteFreelancerPortal.inbox}/:conversationId/:videoCallRoomId/:isAudioOnly`,
                    element: <InboxPage/>,
                },
                {
                    path: RouteFreelancerPortal.publicFreelancerProfile,
                    element: <PublicFreelancerProfilePageForFreelancer/>,
                },
                {
                    path: RouteFreelancerPortal.reports,
                    element: <ReportsPage/>,
                },
                {
                    path: `${RouteFreelancerPortal.caseStudy}/edit/:caseStudyId`,
                    element: <CaseStudyEditPage/>,
                    children: [
                        {
                            path: ECaseStudySteps.description,
                            element: <CaseStudy.Step1Description/>
                        },
                        {
                            path: ECaseStudySteps.company,
                            element: <CaseStudy.Step2Company/>
                        },
                        {
                            path: ECaseStudySteps.challenge,
                            element: <CaseStudy.Step3Challenge/>
                        },
                        {
                            path: ECaseStudySteps.outcomes,
                            element: <CaseStudy.Step4Outcomes/>
                        },
                        {
                            path: ECaseStudySteps.testimonial,
                            element: <CaseStudy.Step5Testimonial/>
                        }
                    ]
                },
                {
                    path: `${RouteFreelancerPortal.caseStudy}/:caseStudyId`,
                    element: <CaseStudyPreviewFreelancer/>,
                },
                {
                    path: RouteFreelancerPortal.help,
                    element: <HelpPage/>,
                    children: [
                        {
                            path: GENERAL,
                            element: <GeneralTab/>
                        },
                        {
                            path: PAYMENT,
                            element: <PaymentTab/>
                        },
                        {
                            path: PROCESS,
                            element: <ProcessTab/>
                        },
                        {
                            path: SUPPORT,
                            element: <SupportTab/>
                        }
                    ]
                },
                {
                    path: RouteFreelancerPortal.contactUs,
                    element: <ContactUsPage/>
                },
                ...errorsRoutes
            ]
        }
    ]
};
