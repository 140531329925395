import React, {FC, ReactNode, useCallback, useMemo} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Autocomplete, Box, FormHelperText, TextField, Typography} from '@mui/material';

import {CheckboxIcon} from '../../../assets/icons';
import {ISelectOption} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight, ChipsList} from '../..';

interface IInputLanguagesProps {
    helperText?: string;
    label?: ReactNode;
    name?: string;
    options: ISelectOption[];
    subTitle?: string;
}

const Languages: FC<IInputLanguagesProps> = ({helperText, label, name = 'languages', subTitle, ...props}): JSX.Element => {
    const [searchValue, setSearchValue] = React.useState<ISelectOption | null>(null);

    const {
        control,
        formState: {errors}
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const languages: ISelectOption[] = value;

    const values: any = useMemo(() => {
        return languages ? languages.map(option => ({id: option.value, name: option.text})) : [];
    }, [languages]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: ISelectOption | null) => {
        if (newValue?.value) {
            const isExists = languages?.find(item => item.value === newValue.value);

            if (!isExists) {
                const result = languages?.length ? [...languages, newValue] : [newValue];

                onChange(result);
            }

            setSearchValue(null);
        }
    };

    const handleDelete = useCallback((item: any) => {   // FIXME remove any
        const result = languages ? languages.filter(option => option.value !== item.id) : [];

        onChange(result);
    }, [languages, onChange]);

    const handleOptionLabel = (option: ISelectOption) => {
        return option.text;
    };

    const isSelected = useCallback((id: string) => {
        return values.find((option: { id: string }) => option.id === id);
    }, [values]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <>
                    {label}

                    {subTitle && (
                        <Typography
                            sx={{
                                marginTop: '-8px',
                                marginBottom: '16px',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: theme.palette.gray.main,
                                [theme.breakpoints.up('lg')]: {
                                    textAlign: 'center',
                                    mt: '-20px',
                                    mb: '24px',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                }
                            }}
                            variant="body1"
                        >
                            {subTitle}
                        </Typography>
                    )}

                    <Autocomplete
                        clearOnBlur
                        blurOnSelect
                        handleHomeEndKeys
                        id="languages"
                        fullWidth
                        getOptionLabel={handleOptionLabel}
                        noOptionsText=""
                        options={props.options || []}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    fullWidth
                                    inputRef={ref}
                                    placeholder="Select a language"
                                    variant="standard"
                                    sx={{
                                        '.MuiInput-root': {
                                            '&::before': {
                                                borderBottom: '2px solid',
                                                borderBottomColor: theme.palette.inputDisabledBorderColor.main
                                            },
                                            '&:hover': {
                                                '&::before': {
                                                    borderBottomColor: theme.palette.primary.main
                                                }
                                            }
                                        }
                                    }}
                                />
                                {/* Show/Hide validation errors with animation */}
                                <AnimateHeight isVisible={!!(errors[name]?.message || helperText)}>
                                    <FormHelperText id={`helper-text-${name}`} sx={{color: errors[name] ? theme.palette.error.main : ''}}>
                                        {errors[name]?.message?.toString() || helperText}
                                    </FormHelperText>
                                </AnimateHeight>
                            </>
                        )}
                        renderOption={(props, option) => (
                            <Box
                                {...props}
                                component="li"
                                sx={{
                                    '& > span': {flexGrow: 1},
                                    color: isSelected(option.value) ? 'primary.main' : 'default',
                                    display: 'flex',
                                    width: '100%'
                                }}
                            >
                                <span>{option.text}</span>
                                {isSelected(option.value) ? <CheckboxIcon fill={theme.palette.primary.main} /> : null}
                            </Box>
                        )}
                        selectOnFocus
                        sx={{mb: 3}}
                        value={searchValue}
                        onChange={handleChange}
                    />

                    <ChipsList items={values} onDelete={handleDelete}/>
                </>
            )}
        />
    );
};

export const InputLanguages = React.memo(Languages);
