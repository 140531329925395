import React from 'react';
import {motion} from 'framer-motion';

import {Grid, Grow, Typography} from '@mui/material';

import {IChannel, ISubChannel} from '../../models';
import theme from '../../theme';
import {Chip} from '..';

interface IProps {
    channel: IChannel;
    selectedSubChannelIds: number[];
    onClick: (subChannel: ISubChannel) => void;
}

export const SubChannelsList: React.FC<IProps> = ({channel, selectedSubChannelIds, onClick}) => {
    const isSelected = (id: number) => selectedSubChannelIds.includes(id);

    return (
        <Grid
            container
            spacing={2}
            rowSpacing={4}
            sx={{
                margin: '-32px auto 48px',
                maxWidth: 660,
                [theme.breakpoints.up('md')]: {
                    m: '-32px auto 64px'
                },
                [theme.breakpoints.up('lg')]: {
                    maxWidth: 670
                }
            }}
        >

            <Grid item xs={12}>
                <Typography
                    sx={{
                        margin: '0 auto',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.black.dark,
                        textAlign: 'center',
                        'span': {
                            font: 'inherit',
                            lineHeight: 'inherit',
                            color: '#7E5FFF'
                        },
                    }}
                    textAlign="center"
                    variant="body2"
                >
                    Select sub-channels for <span>{channel.name}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} key={channel.id} sx={{ml: '-12px'}}>
                <Grid container spacing={1}>
                    {
                        channel.subchannels.map((subChannel, index) => (
                            <Grow
                                in={true}
                                key={`c${channel.id}-s${subChannel.id}`}
                                timeout={250 * index}
                            >
                                <Grid item component={motion.span} animate={{opacity: 1}}
                                    onClick={() => onClick(subChannel)}>
                                    <Chip
                                        clickable
                                        label={subChannel.name}
                                        variant={isSelected(subChannel.id) ? 'done' : 'default'}
                                        onDelete={() => onClick(subChannel)}
                                    />
                                </Grid>
                            </Grow>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};
