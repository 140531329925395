import React, {useCallback, useEffect, useRef, useState} from 'react';

import {styled, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import theme from '../../theme';
import {AnimateHeight} from '../AnimateHeight/AnimateHeight';
import {CardTitle} from '../Card/Card';

const Wrp = styled('div')`
  position: relative;
`;

const ExpandButton = styled('div')<{ isExpanded?: boolean }>`
  position: ${props => props.isExpanded ? 'static' : 'absolute'};
  bottom: 0;
  right: 0;
  background-color: ${theme.palette.white.main};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.gray.main};

  &::before {
    content: '';
    position: absolute;
    display: ${props => props.isExpanded ? 'none' : 'block'};
    top: 0;
    right: 100%;
    width: 6px;
    height: 100%;
    background-image: linear-gradient(to left, ${theme.palette.white.main}, transparent);
  }

  span {
    display: ${props => props.isExpanded ? 'none' : 'inline'};
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${theme.palette.textGray.dark};
  }

  svg {
    width: 21px;
    height: 21px;
    transform: ${props => props.isExpanded ? 'scaleY(-1)' : 'scaleY(1)'};
    transition: transform .3s;
  }

  path {
    fill: ${theme.palette.purple.main};
  }
`;

interface IProps {
    numberOfVisibleLines?: number;
    title?: React.ReactNode;
    text?: React.ReactNode;
    sx?: SxProps<Theme>;
}

const FoldingText: React.FC<IProps> = ({numberOfVisibleLines = 2, title, text, sx}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isVisibleBtn, setIsVisibleBtn] = useState(false);
    const textBoxRef = useRef<HTMLElement>(null);
    // const textHeight = textBoxRef.current?.getBoundingClientRect().height;
    const textLineHeight = textBoxRef.current && parseInt(textBoxRef.current.style.lineHeight, 10) || 22;


    const handleExpandToggle = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);

    useEffect(() => {
        if (typeof text === 'string') {
            setIsVisibleBtn(text.length > 105);
        }
    }, [text]);

    return (
        <Wrp
            sx={{
                ...sx
            }}
        >
            {title && <CardTitle>{title}</CardTitle>}
            {text && (
                <AnimateHeight
                    isVisible={isExpanded}
                    minHeight={numberOfVisibleLines * textLineHeight}
                >
                    <Typography
                        className="text"
                        ref={textBoxRef}
                        sx={{
                            maxWidth: '100%',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '1.6',
                            color: theme.palette.textGray.dark,
                            whiteSpace: 'pre-wrap',
                            transition: 'height .3s',
                        }}
                    >
                        {text}
                    </Typography>
                </AnimateHeight>
            )}
            {isVisibleBtn && (
                <ExpandButton
                    isExpanded={isExpanded}
                    onClick={handleExpandToggle}
                >
                    <span>...&nbsp;</span>{isExpanded ? 'less' : 'more'}
                </ExpandButton>
            )}
        </Wrp>
    );
};

export default React.memo(FoldingText);
