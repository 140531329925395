import React from 'react';

import {styled} from '@mui/material';

import {MD, XL} from '../../constants';
import theme from '../../theme';


const Wrp = styled('div')<{ isPinned?: boolean }>`
  padding: 24px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 386px;
  order: ${props => props.isPinned ? 1 : 2};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: 700px) {
    width: calc(50% - 8px);
  }

  @media (min-width: ${MD}) {
    width: calc(50% - 12px);
  }

  @media (min-width: 1100px) {
    width: calc(33% - 13px);
  }

  @media (min-width: ${XL}) {
    width: calc(24% - 3px);
    max-width: 390px;
    aspect-ratio: 390 / 386;
  }

  img {
    margin-bottom: 16px;
    width: 48px;
    height: 48px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: ${theme.palette.gray.main};
  }
`;

interface IProps {
    isWorkItem?: boolean;
}

const EmptyEngagementListItem: React.FC<IProps> = ({isWorkItem}) => {
    return (
        <Wrp>
            <img src="/assets/images/wink-emoji.png" width="48" height="48" alt="wink emoji"/>
            <p>Your next {isWorkItem ? 'work' : 'hire'} goes here</p>
        </Wrp>
    );
};

export default React.memo(EmptyEngagementListItem);
