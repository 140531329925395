import React, {useCallback, useMemo} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText, Grid} from '@mui/material';

import {useChannels} from '../../../hooks';
import {IChannel} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight, CheckButton, LoadingMask} from '../..';

interface InputChannelsProps {
    name: string;
}

export const InputChannels: React.FC<InputChannelsProps> = React.memo(({name}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const {channels: items, isChannelsLoading} = useChannels();
    const channels: IChannel[] = value;

    const selectedChannelIds = useMemo(() => channels ? channels.map(it => it.id) : [], [channels]);

    const handleClick = useCallback((id?: string) => {
        if (!id || !items) return;
        const channel = items.find(it => it.id === +id);

        if (!channel) return;
        const isExist = channels.find(it => it.id === channel.id);

        // unselect by second click
        if (isExist) {
            onChange(channels.filter(it => it.id !== channel.id));
        } else {
            const newValues = [...channels, {
                ...channel,
                subchannels: [],    // no one selected at the beginning
                hasSubChannels: !!channel.subchannels.length    // for validation - allow selection or it's empty?
            }];

            // is able to choose up to 2 marketing channels maximum
            if (newValues.length > 4) {
                newValues.shift();
            }
            onChange(newValues);
        }

        trigger(name);
        trigger('subchannels');
    }, [channels, items, name, trigger, onChange]);

    const error = errors[name];
    const content = useMemo(() => {
        const isSelected = (id: number) => selectedChannelIds.includes(id);

        return (
            <>
                <Grid container spacing={2}>
                    {items?.map((channel: IChannel) => (
                        <Grid item key={channel.id}>
                            <CheckButton
                                id={channel.id.toString()}
                                label={channel.name}
                                selected={isSelected(channel.id)}
                                size="small"
                                onClick={handleClick}
                            />
                        </Grid>
                    ))}
                </Grid>

                <AnimateHeight isVisible={!!(error?.message)}>
                    <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                        {error?.message?.toString()}
                    </FormHelperText>
                </AnimateHeight>
            </>
        );
    }, [error, items, name, selectedChannelIds, , handleClick]);

    if (isChannelsLoading) {
        return <LoadingMask/>;
    }

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <Box tabIndex={0} ref={ref}>
                    {content}
                </Box>
            )}
        />
    );
});
