import {createTheme, darken, responsiveFontSizes} from '@mui/material';

import {SLIDER_THUMB} from './constants/BackgroundImages';

import palette from './styles/palette.module.scss';

enum EColors {
    inputDisabledBorderColor = '#E3E3E3',
}


declare module '@mui/material/styles/createPalette' {
    interface Palette {
        labelColor: PaletteColor;
        secondaryDisabledColor: PaletteColor;
        inputDisabledBorderColor: PaletteColor;
        inputBorderColor: PaletteColor;
        white: PaletteColor;
        black: PaletteColor;
        gray: PaletteColor;
        lightGray: PaletteColor;
        green: PaletteColor;
        purple: PaletteColor;
        textGray: PaletteColor;
        progressBarBlack: PaletteColor;
        disabledBtnColor: PaletteColor;
        lilac: PaletteColor;
        blue: PaletteColor;
        lightRed: PaletteColor;
        darkGreen: PaletteColor;
    }

    interface PaletteOptions {
        labelColor: PaletteColorOptions;
        secondaryDisabledColor: PaletteColorOptions;
        inputDisabledBorderColor: PaletteColorOptions;
        inputBorderColor: PaletteColorOptions;
        white: PaletteColorOptions;
        black: PaletteColorOptions;
        gray: PaletteColorOptions;
        lightGray: PaletteColorOptions;
        green: PaletteColorOptions;
        purple: PaletteColorOptions;
        textGray: PaletteColorOptions;
        progressBarBlack: PaletteColorOptions;
        disabledBtnColor: PaletteColorOptions;
        lilac: PaletteColorOptions;
        blue: PaletteColorOptions;
        lightRed: PaletteColorOptions;
        darkGreen: PaletteColorOptions;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsVariantOverrides {
        active: true;
        default: true;
        done: true;
    }
}

const defaultTheme = createTheme();
const theme = createTheme({
    palette: {
        primary: {
            main: palette.purple,
            dark: palette.purple,
            light: palette.purple10,
        },
        secondary: {
            main: palette.lightGrey10
        },
        success: {
            main: palette.success
        },
        error: {
            main: palette.red
        },
        info: {
            main: palette.info
        },
        warning: {
            main: palette.warning
        },
        green: defaultTheme.palette.augmentColor({
            color: {
                dark: palette.green,
                main: palette.green30,
                light: palette.green10
            },
            name: 'active-green'
        }),
        labelColor: defaultTheme.palette.augmentColor({
            color: {
                main: palette.grey,
            },
            name: 'labelColor'
        }),
        secondaryDisabledColor: defaultTheme.palette.augmentColor({
            color: {
                main: '#ebedef'
            },
            name: 'secondaryDisabledColor'
        }),
        inputDisabledBorderColor: defaultTheme.palette.augmentColor({
            color: {
                main: EColors.inputDisabledBorderColor
            },
            name: 'inputDisabledBorderColor'
        }),
        inputBorderColor: defaultTheme.palette.augmentColor({
            color: {
                main: '#b1b7c1'
            },
            name: 'inputBorderColor'
        }),
        white: defaultTheme.palette.augmentColor({
            color: {
                main: '#fff',
                light: 'rgba(255, 255, 255, .5)'
            },
            name: 'white'
        }),
        black: defaultTheme.palette.augmentColor({
            color: {
                main: '#121416'
            },
            name: 'black'
        }),
        gray: defaultTheme.palette.augmentColor({
            color: {
                light: palette.disabledBtnColor,
                main: '#999999'
            },
            name: 'gray'
        }),
        purple: defaultTheme.palette.augmentColor({
            color: {
                main: palette.purple,
                light: palette.purple30
            },
            name: 'purple'
        }),
        lightGray: defaultTheme.palette.augmentColor({
            color: {
                main: palette.lightGrey,
                light: palette.lightGrey10
            },
            name: 'lightGray'
        }),
        textGray: defaultTheme.palette.augmentColor({
            color: {
                main: palette.textGray,
                dark: palette.textDarkGray
            },
            name: 'textGray'
        }),
        progressBarBlack: defaultTheme.palette.augmentColor({
            color: {
                main: palette.progressBarBlack
            },
            name: 'progressBarBlack'
        }),
        disabledBtnColor: defaultTheme.palette.augmentColor({
            color: {
                main: palette.disabledBtnColor
            },
            name: 'disabledBtnColor'
        }),
        lilac: defaultTheme.palette.augmentColor({
            color: {
                main: palette.purple10
            },
            name: 'lilac'
        }),
        blue: defaultTheme.palette.augmentColor({
            color: {
                dark: palette.blue,
                main: palette.blue30,
                light: palette.blue10
            },
            name: 'blue'
        }),
        lightRed: defaultTheme.palette.augmentColor({
            color: {
                main: palette.error10
            },
            name: 'lightRed'
        }),
        darkGreen: defaultTheme.palette.augmentColor({
            color: {
                main: palette.darkGreen
            },
            name: 'darkGreen'
        }),
    },
    typography: {
        fontFamily: [
            '"Poppins"',
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    spacing: [0, 4, 8, 16, 32, 64],
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
        easing: {
            // This is the most common easing curve.
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            // Objects enter the screen at full velocity from off-screen and
            // slowly decelerate to a resting point.
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            // Objects leave the screen at full velocity. They do not decelerate when off-screen.
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            // The sharp curve is used by objects that may return to the screen at any time.
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    '& .MuiPaper-root': {
                        background: 'transparent',
                    },
                    '& .MuiPaper-root .MuiAutocomplete-listbox': {
                        borderWidth: 1.5,
                        overflow: 'overlay'
                    },

                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '20px',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '21px',
                    borderRadius: '50px',
                    textTransform: 'none',
                    '@media (min-width: 600px)': {
                        padding: '20px 34px 19px 34px'
                    }
                },
                containedPrimary: {
                    backgroundColor: palette.primaryBtnBg,
                    '&:hover': {
                        backgroundColor: palette.progressBarBlack,
                    },
                    '&:disabled': {
                        color: palette.white,
                        backgroundColor: palette.disabledBtnColor,
                        // opacity: 0.65
                    }
                },
                containedSecondary: {
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.disabledBtnColor,
                    }
                },
                containedSuccess: {
                    '&:hover': {
                        backgroundColor: palette.successHoverBgColor,
                    },
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.success,
                        opacity: 0.65
                    }
                },
                containedError: {
                    color: palette.black,
                    '&:hover': {
                        backgroundColor: palette.errorHoverBgColor
                    },
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.error,
                        opacity: 0.65
                    }
                },
                containedWarning: {
                    '&:hover': {
                        backgroundColor: palette.warningHoverBgColor
                    },
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.warningDisabledBgColor,
                        opacity: 0.65
                    }
                },
                containedInfo: {
                    color: palette.black,
                    '&:hover': {
                        backgroundColor: palette.infoHoverBgColor,
                    },
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.info,
                        opacity: 0.65
                    }
                },
                outlinedPrimary: {
                    '&:hover': {
                        color: palette.white,
                        backgroundColor: palette.primaryOutlineHoverBg
                    },
                    '&:disabled': {
                        color: palette.primaryOutlineDisabledColor,
                        borderColor: palette.primaryOutlineDisabledColor,
                        opacity: 0.65
                    }
                },
                outlinedSecondary: {
                    '&:hover': {
                        color: palette.secondaryHoverColor,
                        backgroundColor: palette.secondaryOutlineHoverBg,
                        borderColor: palette.secondaryHoverBorderColor
                    }
                },
                outlinedSuccess: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.successOutlineHoverBg,
                        borderColor: palette.successOutlineHoverBorder
                    },
                    '&:disabled': {
                        color: palette.success,
                        borderColor: palette.success,
                        opacity: 0.65
                    }
                },
                outlinedError: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.errorOutlineHoverBg,
                        borderColor: palette.errorOutlineHoverBorder
                    },
                    '&:disabled': {
                        color: palette.error,
                        borderColor: palette.error,
                        opacity: 0.65
                    }
                },
                outlinedWarning: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.warningOutlineHoverBg,
                        borderColor: palette.warningOutlineHoverBorder
                    },
                    '&:disabled': {
                        color: palette.warning,
                        borderColor: palette.warning,
                        opacity: 0.65
                    }
                },
                outlinedInfo: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.infoOutlineHoverBg,
                        borderColor: palette.infoOutlineHoverBorder
                    },
                    '&:disabled': {
                        color: palette.info,
                        borderColor: palette.info,
                        opacity: 0.65
                    }
                },
                textPrimary: {
                    '&:hover': {
                        color: palette.white,
                        backgroundColor: palette.primaryHoverBgColor,
                        borderColor: palette.primaryTextColor
                    }
                },
                textSecondary: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.secondaryTextBg,
                        borderColor: palette.secondaryHoverBgColor
                    }
                },
                textSuccess: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.successHoverBgColor,
                        borderColor: palette.successOutlineHoverBg
                    }
                },
                textError: {
                    // padding: '6px 12px !important',
                    '&:hover': {
                        // color: palette.black,
                        backgroundColor: palette.error10,
                        borderColor: palette.error10
                    }
                },
                textWarning: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.warningHoverBgColor,
                        borderColor: palette.warningOutlineHoverBg
                    }
                },
                textInfo: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.infoHoverBgColor,
                        borderColor: palette.infoOutlineHoverBorder
                    }
                },
            },
            variants: [
                {
                    props: {size: 'small'},
                    style: {
                        padding: '10px 16px !important',
                        fontSize: '13px',
                        lineHeight: '20px',
                    }
                }
            ]
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarColor: `${EColors.inputDisabledBorderColor} transparent`,
                    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                        backgroundColor: 'transparent',
                        width: 4,
                        height: 4,
                    },
                    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                        borderRadius: 10,
                        backgroundColor: EColors.inputDisabledBorderColor,
                        minHeight: 24,
                    },
                    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                        backgroundColor: darken(EColors.inputDisabledBorderColor, 0.1),
                    },
                    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                        backgroundColor: darken(EColors.inputDisabledBorderColor, 0.1),
                    },
                    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: darken(EColors.inputDisabledBorderColor, 0.1),
                    },
                    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                        backgroundColor: 'white',
                    },
                    '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
                        backgroundColor: palette.lightGrey10,
                    },

                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 50,
                    height: 40,
                    padding: 12,
                },
                avatar: {
                    marginLeft: 0,
                    marginRight: 6,
                },
                label: {
                    padding: 'initial',
                }
            },
            variants: [
                {
                    props: {variant: 'active'},
                    style: {
                        backgroundColor: palette.green10,
                        gap: '5px',
                        '&:hover': {
                            backgroundColor: palette.green30,
                        },
                        '& .close-icon': {
                            lineHeight: '12px',
                        },
                        '& .close-icon > svg': {
                            height: 22,
                            width: 22
                        }
                    },
                },
                {
                    props: {variant: 'default'},
                    style: {
                        backgroundColor: palette.purple10,
                        border: '2px solid',
                        borderColor: palette.purple10,
                        transition: 'border-color .3s',
                        '&:hover, &.selected': {
                            backgroundColor: palette.purple10,
                            borderColor: palette.purple,
                        },
                        '& > svg': {
                            marginLeft: 8,
                            width: 22,
                        },
                    },
                },
                {
                    props: {variant: 'done'},
                    style: {
                        backgroundColor: palette.green10,
                        border: '2px solid',
                        borderColor: palette.green,
                        '&:hover': {
                            backgroundColor: palette.green30,
                        },
                        '& > svg.MuiChip-deleteIcon': {
                            fill: palette.green,
                            margin: 0,
                            marginLeft: 8,
                            height: 22,
                            width: 22,
                        },
                    },
                },
                {
                    props: {variant: 'filled'},
                    style: {
                        backgroundColor: palette.lightGrey10,
                        border: '2px solid',
                        borderColor: palette.lightGrey10,
                        '&:hover': {
                            backgroundColor: palette.lightGrey,
                            borderColor: palette.lightGrey,
                        },
                        '& .close-icon': {
                            lineHeight: '12px',
                        },
                        '& .close-icon > svg': {
                            height: 22,
                            width: 22
                        }
                    },
                },
            ],
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        MuiCalendarOrClockPicker: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important',
                },
            },
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    '& .Mui-selected:focus': {
                        color: 'white'
                    },
                },
            },
        },
        MuiClockPicker: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    '& .Mui-selected': {
                        backgroundColor: 'transparent'
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    position: 'static',
                    transform: 'none',
                    marginBottom: '0',
                    color: palette.labelColor,
                    '&:hover': {
                        '&::before': {
                            borderColor: palette.purple
                        }
                    },
                    legend: {
                        display: 'none'
                    },
                    fieldset: {
                        top: 0,
                        borderColor: palette.lightGrey,
                        borderWidth: '1px',
                    },
                    input: {
                        backgroundColor: palette.white,
                    },
                    '.Mui-focused': {
                        fieldset: {
                            borderColor: `${palette.inputFocusBorderColor} !important`,
                            borderWidth: '1px !important',
                            boxShadow: `0 0 0 0.25rem ${palette.inputFocusBoxshadow}`,
                        }
                    },
                    '.MuiOutlinedInput-root': {
                        backgroundColor: palette.white,
                        'input': {
                            padding: '14px'
                        },
                        '.MuiInputAdornment-root > *': {
                            paddingBottom: '0 !important',
                            display: 'inline-flex',
                            alignItems: 'center'
                        },
                    },
                    '.MuiInputLabel-outlined': {
                        marginBottom: '4px !important'
                    },
                    '.Mui-disabled': {
                        '&.MuiFormLabel-root': {
                            color: palette.labelColor
                        },
                        '&.MuiOutlinedInput-root, &.MuiOutlinedInput-root input': {
                            color: palette.inputDisabledTextColor,
                            backgroundColor: palette.secondaryDisabledBgColor
                        },
                        '&.MuiOutlinedInput-input': {
                            'WebkitTextFillColor': palette.inputDisabledTextColor
                        },
                        'fieldset.MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.lightGrey
                        }
                    },
                    '.MuiInputBase-input': {
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        paddingBottom: '10px',
                    },
                    '&.MuiInput-root': {
                        '&:hover': {
                            '&::before': {
                                borderColor: palette.purple
                            }
                        }
                    },
                    '.MuiInputAdornment-root .MuiTypography-root': {
                        fontSize: '14px',
                        color: palette.grey,
                        paddingBottom: '4px'
                    }
                },
            },
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        '.MuiFormLabel-root': {
                            fontWeight: 400,
                            fontSize: '13px',
                            // color: theme.palette.gray.main
                            color: palette.grey + '!important'
                        },
                        '& legend': {
                            visibility: 'visible',
                            overflow: 'visible',
                            'span': {
                                opacity: 1 + '!important'
                            }
                        }
                    }
                }
            ]
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginRight: 0,
                    marginLeft: 0,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    color: palette.textDarkGray,
                    'textarea::placeholder, textarea::-webkit-input-placeholder': {
                        color: palette.grey,
                        opacity: 1
                    },
                    'input': {
                        backgroundColor: palette.white,
                        borderRadius: 'inherit',
                    },
                    '&.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl': {
                        '& input::placeholder, & input::-webkit-input-placeholder': {
                            color: palette.grey,
                            opacity: 1 + '!important'
                        },
                    },
                    '.MuiSelect-select.MuiSelect-outlined': {
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        padding: '18px 14px 14px 14px',
                        color: palette.black
                    },
                    '&[class$=MuiOutlinedInput-root-MuiSelect-root]': {
                        marginTop: '21px',
                    },
                    '.MuiSelect-outlined': {
                        '& ~ fieldset': {
                            borderColor: palette.lightGrey,
                            'legend': {
                                visibility: 'visible',
                                overflow: 'visible',
                                fontWeight: 400,
                                fontSize: '13px',
                                lineHeight: '20px',
                                color: palette.grey,
                                backgroundColor: 'transparent',
                                height: 'unset',
                                position: 'relative',
                                top: '-21px',
                                left: '-7px',
                                width: 0,
                                'span': {
                                    opacity: 1 + '!important',
                                    padding: 0
                                }
                            }
                        },
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '.MuiSvgIcon-root': {
                        display: 'none'
                    },
                    '&::before': {
                        content: '""',
                        display: 'inline-block',
                        width: '24px',
                        height: '24px',
                        marginLeft: '2px',
                        marginRight: '7px',
                        backgroundImage: 'url(\'data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Crect x="0.75" y="0.75" width="22.5" height="22.5" rx="3.25" stroke="%23E3E3E3" stroke-width="1.5"/%3E%3C/svg%3E%0A\')',
                        backgroundSize: '100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    },
                    '&.Mui-checked': {
                        '.MuiSvgIcon-root': {
                            display: 'none'
                        },
                        '&::before': {
                            backgroundImage: 'url(\'data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Crect width="24" height="24" rx="4" fill="%237E5FFF"/%3E%3Cpath d="M17.0326 8.40752C16.9629 8.33722 16.8799 8.28143 16.7885 8.24335C16.6971 8.20527 16.5991 8.18567 16.5001 8.18567C16.4011 8.18567 16.303 8.20527 16.2117 8.24335C16.1203 8.28143 16.0373 8.33722 15.9676 8.40752L10.3801 14.0025L8.03258 11.6475C7.96019 11.5776 7.87474 11.5226 7.7811 11.4857C7.68745 11.4488 7.58746 11.4307 7.48683 11.4324C7.38619 11.4342 7.28688 11.4557 7.19457 11.4958C7.10227 11.536 7.01876 11.5939 6.94883 11.6663C6.87891 11.7387 6.82392 11.8241 6.78702 11.9178C6.75011 12.0114 6.73202 12.1114 6.73376 12.212C6.7355 12.3127 6.75704 12.412 6.79716 12.5043C6.83728 12.5966 6.89519 12.6801 6.96758 12.75L9.84758 15.63C9.91731 15.7003 10.0003 15.7561 10.0917 15.7942C10.183 15.8323 10.2811 15.8519 10.3801 15.8519C10.4791 15.8519 10.5771 15.8323 10.6685 15.7942C10.7599 15.7561 10.8429 15.7003 10.9126 15.63L17.0326 9.51002C17.1087 9.43979 17.1695 9.35455 17.211 9.25967C17.2526 9.1648 17.274 9.06234 17.274 8.95877C17.274 8.85519 17.2526 8.75274 17.211 8.65786C17.1695 8.56299 17.1087 8.47775 17.0326 8.40752Z" fill="white"/%3E%3C/svg%3E%0A\')'
                        }
                    },
                    '&.Mui-focusVisible': {
                        '&::before': {
                            backgroundImage: 'url(\'data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Crect x="0.75" y="0.75" width="22.5" height="22.5" rx="3.25" stroke="%237E5FFF" stroke-width="1.5"/%3E%3C/svg%3E%0A\')',
                        }
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.MuiFormLabel-root': {
                        position: 'static',
                        transform: 'none',
                        marginBottom: '.5rem',
                        color: palette.labelColor
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontWeight: '700',
                    fontSize: '45px !important', // todo: fix it later when doing the global theme setting
                    lineHeight: '130%',
                    color: palette.black,
                    margin: 0,
                },
                body2: {
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '130%',
                    color: 'rgba(0, 0, 0, 0.5)',
                    margin: 0,
                },

            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '.MuiSlider-markLabel': {
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '15px',
                        color: '#A6A6A6'
                    }
                },
                thumb: {
                    width: '44px',
                    height: '44px',
                    backgroundColor: palette.white,
                    borderRadius: '50%',
                    backgroundImage: SLIDER_THUMB,
                    backgroundSize: '100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    '@media (min-width: 600px)': {
                        width: '52px',
                        height: '52px',
                    },
                    '&::before': {
                        boxShadow: 'unset'
                    },
                    '.MuiSlider-valueLabel': {
                        transform: 'translateY(-100%)',
                        top: '-14px',
                        backgroundColor: 'transparent',
                        color: palette.black,
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '21px',
                        '&::before': {
                            height: '10px',
                            width: '2px',
                            borderRadius: '2px',
                            transform: 'translate(-50%, 50%)',
                            backgroundColor: palette.purple,
                            bottom: '-8px',
                            '@media (min-width: 600px)': {
                                bottom: '-4px'
                            },
                        },
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            height: '6px',
                            width: '2px',
                            borderRadius: '2px',
                            transform: 'translate(-50%, 50%)',
                            backgroundColor: palette.purple,
                            top: '85px',
                            left: '50%',
                            '@media (min-width: 600px)': {
                                top: '90px'
                            },
                        }
                    }
                },
                rail: {
                    opacity: 1,
                    backgroundColor: palette.lightGrey,
                },
                track: {
                    border: 'unset'
                },
                mark: {
                    color: palette.lightGrey,
                    height: 0,
                    width: '2px',
                    borderRadius: '2px',
                    '&:nth-of-type(4n + 1)': {
                        height: '6px',
                        top: 'calc(50% + 11px)'
                    },
                    '.MuiSlider-markLabel': {
                        top: '33px'
                    }
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    '.MuiList-root': {
                        padding: '12px 0',
                        margin: '8px 0',
                        backgroundColor: '#ffffff',
                        borderRadius: '12px',
                        border: '1px solid #F3F3F3',
                        maxHeight: 230,
                        overflowY: 'auto'
                    },
                    '.MuiMenuItem-root': {
                        padding: '12px 23px',
                        '&:hover': {
                            backgroundColor: '#F5F2FF'
                        },
                    },
                    '.Mui-selected': {
                        backgroundColor: '#ffffff',
                        color: palette.purple
                    },
                    '.Mui-focusVisible': {
                        backgroundColor: '#F5F2FF !important'
                    },
                    //autocomplete list styles
                    '.MuiAutocomplete-listbox': {
                        padding: '12px 0',
                        margin: '8px 0',
                        backgroundColor: '#ffffff',
                        borderRadius: '12px',
                        border: '2px solid #E3E3E3',
                        maxHeight: 230,
                        overflowY: 'auto'
                    },
                    '.MuiAutocomplete-option': {
                        padding: '12px 23px !important',
                        '&.Mui-focused': {
                            backgroundColor: '#F5F2FF !important'
                        }
                    }
                }
            }
        }
    }
});

export default responsiveFontSizes(theme);
