import React from 'react';

const EmailIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.3125 17.8906H4.6875C2.10281 17.8906 0 15.7878 0 13.2031V6.79688C0 4.21219 2.10281 2.10938 4.6875 2.10938H15.3125C17.8972 2.10938 20 4.21219 20 6.79688V13.2031C20 15.7878 17.8972 17.8906 15.3125 17.8906ZM4.6875 3.67188C2.96438 3.67188 1.5625 5.07375 1.5625 6.79688V13.2031C1.5625 14.9263 2.96438 16.3281 4.6875 16.3281H15.3125C17.0356 16.3281 18.4375 14.9263 18.4375 13.2031V6.79688C18.4375 5.07375 17.0356 3.67188 15.3125 3.67188H4.6875ZM12.8627 11.383L16.7243 8.43336C17.0671 8.17145 17.1328 7.68117 16.8709 7.33828C16.6089 6.99539 16.1187 6.92977 15.7758 7.19168L11.9147 10.141C10.7998 10.9914 9.24285 10.992 8.12723 10.1432L4.38578 7.23477C4.04512 6.96992 3.55426 7.03145 3.28949 7.37211C3.02469 7.71277 3.08617 8.20359 3.42684 8.4684L7.17117 11.379C7.17305 11.3805 7.17496 11.382 7.17688 11.3834C8.01375 12.0217 9.01648 12.3408 10.0195 12.3408C11.0224 12.3408 12.0256 12.0215 12.8627 11.383Z"
                fill="#121416"/>
        </svg>
    );
};

export default EmailIcon;
