import {useEffect} from 'react';

import {WIDGET_PAYLOAD} from '../../../api';
import {RouteWidget} from '../../../constants';
import {useNavigateUTM} from '../../../hooks';
import {utm} from '../../../utils';
import {getStep} from '../SidebarMenu/MenuSteps/nav.model';

const WidgetHome = () => {
    const widgetId = utm.campaign;
    const navigate = useNavigateUTM();

    useEffect(() => {
        const widgetPayload = localStorage.getItem(WIDGET_PAYLOAD + widgetId);

        if (widgetId && !widgetPayload) {
            localStorage.setItem(WIDGET_PAYLOAD + widgetId, JSON.stringify({}));
        }

        if (widgetPayload) {
            const currentStep = getStep(JSON.parse(widgetPayload));

            console.log('step', currentStep);
            console.log('step', currentStep + location.search);

            navigate(currentStep + location.search);
        } else {
            console.log('redirect channels');
            navigate(RouteWidget.channels + location.search);
        }
    }, [widgetId, navigate]);

    return null;
};

export default WidgetHome;
