import {EMailType} from '../../models';

export const subjects = [{
    groupName: 'Matching' // Group
}, {
    value: EMailType.WRONG_INACCURATE_MATCH_OR_MATCHING_ADJUSTMENTS,
    text: 'Wrong/inaccurate match'
}, {
    value: EMailType.OTHERS_MATCHING,
    text: 'Other'
}, {
    groupName: 'Billing' // Group
}, {
    value: EMailType.BILLING_INQUIRY_OR_QUESTION,
    text: 'Billing Inquiry'
}, {
    value: EMailType.INVOICE_DISCREPANCY,
    text: 'Invoice Discrepancy'
}, {
    value: EMailType.OTHERS_BILLING,
    text: 'Other'
}, {
    groupName: 'Payment' // Group
}, {
    value: EMailType.PAYMENT_STATUS_OR_CONFIRMATION,
    text: 'Payment Status or Confirmation'
}, {
    value: EMailType.PAYMENT_REFUND_REQUEST,
    text: 'Payment Refund Request'
}, {
    value: EMailType.PAYMENT_METHOD,
    text: 'Payment Method'
}, {
    value: EMailType.OTHERS_PAYMENT,
    text: 'Other'
}, {
    groupName: 'Support' // Group
}, {
    value: EMailType.BUG_REPORT,
    text: 'Bug Report'
}, {
    value: EMailType.FEEDBACK_AND_FEATURE_REQUEST,
    text: 'Feedback & Feature Request'
}, {
    value: EMailType.ACCOUNT_ISSUES,
    text: 'Account issues'
}, {
    value: EMailType.OTHERS_SUPPORT,
    text: 'Other'
}];
