import React from 'react';

const DuplicateIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2878_468)">
                <path d="M11.25 17.25H3.75C2.95435 17.25 2.19129 16.9339 1.62868 16.3713C1.06607 15.8087 0.75 15.0456 0.75 14.25V6.75C0.75 5.95435 1.06607 5.19129 1.62868 4.62868C2.19129 4.06607 2.95435 3.75 3.75 3.75H11.25C12.0456 3.75 12.8087 4.06607 13.3713 4.62868C13.9339 5.19129 14.25 5.95435 14.25 6.75V14.25C14.25 15.0456 13.9339 15.8087 13.3713 16.3713C12.8087 16.9339 12.0456 17.25 11.25 17.25ZM3.75 5.25C3.35218 5.25 2.97064 5.40804 2.68934 5.68934C2.40804 5.97064 2.25 6.35218 2.25 6.75V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H11.25C11.6478 15.75 12.0294 15.592 12.3107 15.3107C12.592 15.0294 12.75 14.6478 12.75 14.25V6.75C12.75 6.35218 12.592 5.97064 12.3107 5.68934C12.0294 5.40804 11.6478 5.25 11.25 5.25H3.75Z" fill="#7E5FFF"/>
                <path d="M14.25 14.25V12.75C14.6478 12.75 15.0294 12.592 15.3107 12.3107C15.592 12.0294 15.75 11.6478 15.75 11.25V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25H6.75C6.35218 2.25 5.97064 2.40804 5.68934 2.68934C5.40804 2.97064 5.25 3.35218 5.25 3.75H3.75C3.75 2.95435 4.06607 2.19129 4.62868 1.62868C5.19129 1.06607 5.95435 0.75 6.75 0.75H14.25C15.0456 0.75 15.8087 1.06607 16.3713 1.62868C16.9339 2.19129 17.25 2.95435 17.25 3.75V11.25C17.25 12.0456 16.9339 12.8087 16.3713 13.3713C15.8087 13.9339 15.0456 14.25 14.25 14.25Z" fill="#7E5FFF"/>
            </g>
            <defs>
                <clipPath id="clip0_2878_468">
                    <rect width="18" height="18" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default DuplicateIcon;
