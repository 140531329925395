import {ConnectOptions, LocalParticipant, RemoteParticipant, Room} from 'twilio-video';

import {Message, Participant} from '@twilio/conversations';

import {IMeeting} from './meeting.model';

// Set at the BE - readonly
export interface IConversationAttributes {
    call: ECallType;
    matchId: number;
    meetings: IMeeting[];
    roleId: number;
    status: EConversationStatus;
}

// Set at the FE some common usefull info
export interface IConversationMeta {
    isInitByFreelancer: boolean;        // allow only one message
    lastMessage?: {body: string | null; dateUpdated: Date | null};
    localParticipant: {avatar?: string; name: string; sid: string};
    remoteParticipant: {avatar?: string; name: string; sid: string};
    unreadMessagesCount: number;
}

export interface IConversationMessage extends Message {
    attributes: IMessageAttributes;
}

export enum EParticipantType {
    CLIENT = 'CLIENT',
    FREELANCER = 'FREELANCER'
}

export type IMessageAttributes = {
    author: EParticipantType;
    proposalId?: number;
}

export type ICallMessageAttributes = {
    author: EParticipantType;
    duration: string;
    isAudioOnly?: boolean;
    isInitiator: boolean;
    roomSid?: number;
}

export enum EConversationStatus {
    Accepted = 'ACCEPTED',
    New = 'NEW',
    Proposed = 'PROPOSED',
    Rejected = 'REJECTED',
}

export enum ECallType {
    NOTHING ='NOTHING',
    CALLING_AUDIO = 'CALLING_AUDIO',
    CALLING_VIDEO = 'CALLING_VIDEO',
}

export interface ICall {
    conversationId: string;
    type: ECallType;
}

export interface IActiveCallMeta extends IConversationMeta{
    pathname: string;
}

export interface IRoom {
    error: any;
    isCameraOn: boolean;
    isMicrophoneOn: boolean;
    localParticipant: LocalParticipant | null;
    remoteParticipants: RemoteParticipant[] | null;
    room: Room | null;
    connectRoom: (params: {token: string; options?: ConnectOptions}) => Promise<Room>;
    disconnectRoom: () => Promise<void>;
    toggleCamera: () => void;
    toggleMicrophone: () => void;
}

interface IMetaFunc {
    isInitByFreelancer: boolean;
    localParticipant?: Participant;
    remoteParticipant?: Participant;
    unreadMessagesCount: number;
}

export const formatMeta = ({
    isInitByFreelancer,
    localParticipant,
    remoteParticipant,
    unreadMessagesCount
}: IMetaFunc): IConversationMeta => {
    return {
        isInitByFreelancer,
        localParticipant: {
            avatar: (localParticipant?.attributes as any).avatar,
            name: (localParticipant?.attributes as any).name,
            sid: (localParticipant?.attributes as any).sid,
        },
        remoteParticipant: {
            avatar: (remoteParticipant?.attributes as any).avatar,
            name: (remoteParticipant?.attributes as any).name,
            sid: (remoteParticipant?.attributes as any).sid,
        },
        unreadMessagesCount
    };
};
