import React from 'react';

import {FaqList} from '../../../../components';

const supportQuestions = [
    {
        title: 'How do I send a client a proposal?',
        text: 'You can easily send a proposal to a client through the expert portal.'
    },
    {
        title: 'How does time tracking work?',
        text: 'For hourly engagements, you’ll submit time cards into our system once per week. Your clients will have full visibility into these weekly time cards within their client portal.\n' +
            '\n' +
            'In some cases, clients will also set up a shared sheet or tool directly with you, where you can provide more details on the time logged.'
    },
];

const SupportTab = () => {
    return (
        <>
            <FaqList list={supportQuestions}/>
        </>
    );
};

export default React.memo(SupportTab);
