import React, {useCallback} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {FormHelperText} from '@mui/material';

import {metaService} from '../../../api';
import {IBrand, ITool, OptionType} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight, AutocompleteAsync, ChipsList} from '../..';

interface InputBrandsProps {
    name: string;
}

export const InputBrands: React.FC<InputBrandsProps> = React.memo(({name}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const brands: IBrand[] = value;

    const handleChange = useCallback((newValue: OptionType | null) => {
        if (!newValue) {
            return;
        }

        // don't add duplicate
        if (!brands.find(brands => brands.name.toLowerCase() === newValue.name.toLowerCase())) {
            onChange([...brands, newValue]);
            trigger(name);
        }
    }, [name, brands, onChange, trigger]);

    const handleDelete = useCallback((tool: ITool) => {
        const newTools = brands.filter(t => t.name.toLowerCase() !== tool.name.toLowerCase());

        onChange(newTools);
        trigger(name);
    }, [name, brands, onChange, trigger]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <>
                    <AutocompleteAsync
                        apiMethod={metaService.searchBrands}
                        inputRef={ref}
                        freeSolo
                        id="free-solo-brands"
                        isLogo={true}
                        placeholder="Select brands"
                        value={brands}
                        onChange={handleChange}
                    />

                    <AnimateHeight isVisible={true}>
                        <ChipsList avatarProperty="logo" items={brands || []} onDelete={handleDelete}/>
                    </AnimateHeight>

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </>
            )}
        />
    );
});
