import React from 'react';

import {Box, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import theme from '../../theme';


interface IProps {
    isClient?: boolean;
    moneyAmount: string;
    order?: number | {
        Breakpoint: number;
    };
    sx?: SxProps<Theme>;
}

const MoneyEarnedSpent: React.FC<IProps> = ({isClient, moneyAmount, order, sx}) => {
    return (
        <Box
            sx={{
                flexShrink: 0,
                display: 'flex',
                alignItems: 'flex-end',
                gap: '8px',
                order: 3,
                'img': {
                    alignSelf: 'center'
                },
                [theme.breakpoints.up(850)]: {
                    order: order ? order : 2,
                },
                ...sx
            }}
        >
            {
                !isClient && <img src="/assets/images/confetti-icon.png" width="16" height="16" alt="confetti icon"/>
            }
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: 1.5,
                    color: theme.palette.purple.main
                }}
            >
                {moneyAmount}
            </Typography>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: 1.5,
                    color: theme.palette.gray.main
                }}
                variant="caption"
            >
                {isClient ? 'spent' : 'earned'}
            </Typography>
        </Box>
    );
};

export default React.memo(MoneyEarnedSpent);
