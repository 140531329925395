import React from 'react';
import isBoolean from 'lodash/isBoolean';

import {CloseIcon} from '../../../../assets/icons';
import {Pill, Tooltip} from '../../..';

interface RejectedPillProps {
    isAuthor?: boolean;
}

const RejectedPill: React.FC<RejectedPillProps> = ({isAuthor}) => {
    const isTitle = isBoolean(isAuthor);

    const content = (
        <Pill
            hasOutline
            variant="red"
        >
            <CloseIcon/>
            Rejected proposal
        </Pill>
    );

    if (isTitle) {
        return (
            <Tooltip title={isAuthor ? 'Your proposal has been rejected' : 'The proposal has been rejected'}>
                <span>
                    {content}
                </span>
            </Tooltip>
        );
    }

    return (
        content
    );
};

export default React.memo(RejectedPill);
