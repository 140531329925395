import React from 'react';

const BellIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2471_1638)">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M3.125 7.03125C3.125 3.17405 6.09104 0 10 0C13.909 0 16.875 3.17405 16.875 7.03125V11.4062C16.875 11.8378 17.2246 12.1875 17.6562 12.1875C18.9507 12.1875 20 13.2368 20 14.5312C20 15.8256 18.9507 16.875 17.6562 16.875H2.34375C1.04928 16.875 0 15.8256 0 14.5312C0 13.2368 1.04931 12.1875 2.34381 12.1875C2.77527 12.1875 3.125 11.8377 3.125 11.4062V7.03125ZM2.34375 13.75C3.63832 13.75 4.6875 12.7006 4.6875 11.4062V7.03125C4.6875 3.98478 7.00529 1.5625 10 1.5625C12.9947 1.5625 15.3125 3.98478 15.3125 7.03125V11.4062C15.3125 12.7007 16.3616 13.75 17.6562 13.75C18.0877 13.75 18.4375 14.0998 18.4375 14.5312C18.4375 14.9627 18.0877 15.3125 17.6562 15.3125H2.34375C1.91228 15.3125 1.5625 14.9627 1.5625 14.5312C1.5625 14.0998 1.91225 13.75 2.34375 13.75Z"
                    fill="#121416"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M8.4375 15.3125C8.86897 15.3125 9.21875 15.6623 9.21875 16.0938V17.6562C9.21875 18.0877 9.56854 18.4375 10 18.4375C10.4315 18.4375 10.7812 18.0877 10.7812 17.6562V16.0938C10.7812 15.6623 11.131 15.3125 11.5625 15.3125C11.994 15.3125 12.3438 15.6623 12.3438 16.0938V17.6562C12.3438 18.9507 11.2944 20 10 20C8.7056 20 7.65625 18.9507 7.65625 17.6562V16.0938C7.65625 15.6623 8.00603 15.3125 8.4375 15.3125Z"
                    fill="#121416"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M19.2188 4.6875C19.6502 4.6875 20 5.03728 20 5.46875V9.84375C20 10.2752 19.6502 10.625 19.2188 10.625C18.7873 10.625 18.4375 10.2752 18.4375 9.84375V5.46875C18.4375 5.03728 18.7873 4.6875 19.2188 4.6875Z"
                    fill="#121416"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M0.78125 4.6875C1.21272 4.6875 1.5625 5.03728 1.5625 5.46875V9.84375C1.5625 10.2752 1.21272 10.625 0.78125 10.625C0.349778 10.625 0 10.2752 0 9.84375V5.46875C0 5.03728 0.349778 4.6875 0.78125 4.6875Z"
                    fill="#121416"/>
            </g>
            <defs>
                <clipPath id="clip0_2471_1638">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default BellIcon;
