import React, {useState} from 'react';

import {Box, Typography} from '@mui/material';

import {PlusBiggerIcon, RemoveIcon} from '../../assets/icons';
import theme from '../../theme';

interface IProps {
    title: string;
    text: string;
}

const FaqItem: React.FC<IProps> = ({title, text}) => {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <Box
            sx={{
                borderTop: `1px solid ${theme.palette.black.main}`,
                '&:last-of-type': {
                    borderBottom: `1px solid ${theme.palette.black.main}`,
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '8px',
                    p: '24px 0',
                    cursor: 'pointer'
                }}
                onClick={() => setCollapsed(!collapsed)}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: '16px'
                    }}
                >
                    {title}
                </Typography>
                <Box
                    sx={{
                        width: '24px',
                        height: '24px',
                        'svg': {
                            width: 'inherit',
                            height: 'inherit',
                            animationName: 'rotateBtn',
                            animationDuration: '.12s',
                            animationTimingFunction: 'linear'
                        },
                        '@keyframes rotateBtn': {
                            'from': {
                                transform: 'rotate(0deg)'
                            },
                            'to': {
                                transform: 'rotate(180deg)'
                            }
                        }
                    }}
                >
                    {
                        collapsed ? (
                            <PlusBiggerIcon/>
                        ) : (
                            <RemoveIcon/>
                        )
                    }
                </Box>
            </Box>
            <Box
                aria-hidden={collapsed ? 'true' : 'false'}
                sx={{
                    display: 'grid',
                    gridTemplateRows: collapsed ? '0fr' : '1fr',
                    transition: 'grid-template-rows .12s linear',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        color: theme.palette.gray.main,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        '&::after': {
                            content: '""',
                            display: 'block',
                            pb: '24px'
                        }
                    }}
                >
                    {text}
                </Typography>
            </Box>
        </Box>
    );
};

export default React.memo(FaqItem);
