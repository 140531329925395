import React, {useCallback, useState} from 'react';
import {AxiosError} from 'axios';
import {useParams} from 'react-router';

import {useMutation, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {DialogConfirm, DialogSuccess, showToastError} from '../../../../../../components';
import {CLIENT_ENGAGEMENT, TIMESHEETS} from '../../../../../../constants';

type DialogApproveProps = {
    open: boolean;
    timesheetId: number;
    onClose: (isNeedUpdate: boolean) => void;
};

const DialogApprove: React.FC<DialogApproveProps> = ({open, timesheetId, onClose}) => {
    const {engagementId} = useParams();
    const [openSuccess, setOpenSuccess] = useState(false);

    const queryClient = useQueryClient();

    const {mutate, isLoading: isSubmitting} = useMutation(
        clientService.timesheetsApprove,
        {
            onSuccess: async () => {
                onClose(false);
                setTimeout(() => {
                    setOpenSuccess(true);
                }, 500);
            },
            onError(error: AxiosError) {
                showToastError(error);
            }
        }
    );

    const handleApprovedClose = useCallback(() => {
        setOpenSuccess(false);
        queryClient.invalidateQueries([CLIENT_ENGAGEMENT, Number(engagementId), TIMESHEETS]);
    }, [engagementId, queryClient]);

    const handleConfirmClose = useCallback((reason: boolean) => {
        if (reason) {
            mutate(timesheetId);
        } else {
            onClose(false);
        }
    }, [timesheetId, mutate, onClose]);

    return (
        <>
            <DialogConfirm
                disabled={isSubmitting}
                iconUrl="/assets/images/clock-red-icon.png"
                labelBtn="Yes, approve"
                open={open}
                subtitle="You are about to approve the timesheet. Are you sure?"
                title={<>
                    Approve this timesheet?
                </>}
                onClose={handleConfirmClose}
            />

            {/* Success approved */}
            <DialogSuccess
                open={openSuccess}
                title="You have successfully approved the timesheet"
                onClose={handleApprovedClose}
            />
        </>
    );
};

export default DialogApprove;
