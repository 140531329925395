import React from 'react';

import {Box, Typography} from '@mui/material';

import {useAuth} from '../../../hooks';
import {EWorkWeekStatusDisplay, IReportDate} from '../../../models';
import theme from '../../../theme';
import {formatCurrency, formatDate, formatHoursFromDuration} from '../../../utils';
import {CollapsibleBox, ImagePlaceholder, MoneyEarnedSpent} from '../../index';
import Pill from '../../Pill';
import {getVariantByStatus} from '../../Timesheets';

type ReportDateProps = {
    data: IReportDate;
};

const ReportDate: React.FC<ReportDateProps> = ({data}) => {
    const {isClient} = useAuth();

    return (
        <>
            {data.workDays.map(item => (
                <CollapsibleBox
                    key={item.date}
                    titleContent={<>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                gridArea: 'date',
                                width: 'calc(100% - 100px)',
                                order: 1,
                                [theme.breakpoints.up(850)]: {
                                    width: 'auto',
                                    mr: 'auto',
                                }
                            }}
                        >
                            {formatDate(item.date)}
                        </Typography>
                        {
                            item.money ? (
                                <Box
                                    sx={{
                                        order: 3,
                                        [theme.breakpoints.up(850)]: {
                                            order: 2
                                        }
                                    }}
                                >
                                    <MoneyEarnedSpent
                                        isClient={isClient}
                                        moneyAmount={formatCurrency(item.money, true)}
                                    />
                                </Box>
                            ) : null
                        }

                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                gridArea: 'time',
                                order: 4,
                                [theme.breakpoints.up(850)]: {
                                    order: 2
                                }
                            }}
                        >
                            {(item.workTime && item.workTime !== 'PT0S') ? formatHoursFromDuration(item.workTime) : '0'}h
                        </Typography>
                    </>}
                >
                    <Box sx={{pb: '16px'}}/>
                    {item.worklogs.map((worklog, index) =>
                        <Box
                            key={worklog.clientCompanyName + index}
                            sx={{
                                pt: '16px',
                                pb: '16px',
                                borderTop: `1px solid ${theme.palette.lightGray.main}`,
                                '&:last-of-type': {
                                    pb: 0
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px',
                                    order: 3,
                                    width: '100%',
                                    [theme.breakpoints.up(850)]: {
                                        order: 2,
                                        width: 'auto',
                                        ml: 'auto'
                                    }
                                }}
                            >
                                <ImagePlaceholder>
                                    {worklog.clientCompanyLogo && (
                                        <img
                                            src={worklog.clientCompanyLogo}
                                            width="32"
                                            height="32"
                                            alt={worklog.clientCompanyName}
                                        />
                                    )}
                                </ImagePlaceholder>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        color: theme.palette.gray.main
                                    }}
                                >
                                    {worklog.clientCompanyName}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    mt: '8px',
                                    display: 'grid',
                                    alignItems: 'center',
                                    gap: '16px',
                                    gridTemplateAreas: '"title title title"\n' +
                                        '"status rate time"',
                                    gridTemplateColumns: 'repeat(2, auto) 1fr',
                                    [theme.breakpoints.up('md')]: {
                                        display: 'flex'
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        gridArea: 'title',
                                        [theme.breakpoints.up('md')]: {
                                            mr: 'auto'
                                        }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            width: 'calc(100vw - 93px)',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            [theme.breakpoints.up('md')]: {
                                                width: 'auto',
                                                whiteSpace: 'unset',
                                                overflow: 'unset'
                                            }
                                        }}
                                    >
                                        {worklog.roleName}
                                    </Typography>
                                    {worklog.workDescription && (
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                color: theme.palette.gray.main
                                            }}
                                        >
                                            {worklog.workDescription}
                                        </Typography>
                                    )}
                                </Box>
                                <Box
                                    sx={{gridArea: 'status'}}
                                >
                                    <Pill variant={getVariantByStatus(worklog.status)}>
                                        {EWorkWeekStatusDisplay[worklog.status]}
                                    </Pill>
                                </Box>
                                <Typography
                                    sx={{
                                        gridArea: 'rate',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        color: theme.palette.gray.main
                                    }}
                                >
                                    {formatCurrency(worklog.rate)}/h
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        order: 5,
                                    }}
                                >
                                    {(worklog.workTime && worklog.workTime !== 'PT0S') ? formatHoursFromDuration(worklog.workTime) : '0'}h
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </CollapsibleBox>
            ))}
        </>
    );
};

export default React.memo(ReportDate);
