import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';

import {Avatar, Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {Message} from '@twilio/conversations';

import {freelancerService, matchesService} from '../../../../../api';
import {GET_PROPOSALS, LG} from '../../../../../constants';
import {useAuth, useConversation} from '../../../../../hooks';
import {
    EParticipantType,
    EProposalStatus,
    EWorkType,
    EWorkTypeDisplay,
    IConversationMessage
} from '../../../../../models';
import theme from '../../../../../theme';
import {formatCurrency, formatTime, getNameLetters, stringToColor} from '../../../../../utils';
import {Button, LoadingMask, Pill, showToastError} from '../../../../';
import ChatSendProposal from '../../ChatSendProposal';
import {AcceptedPill, PendingPill, RejectedPill} from '../../Pills';

const ProposalNotification = styled('div')`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${theme.palette.gray.main};
  }
`;

const ProposalInfo = styled('div')`
  padding: 20px;
  background-color: ${theme.palette.lilac.main};
  border: 1px solid;
  border-color: ${theme.palette.purple.main};
  border-radius: 16px;
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 24px;
  }

  @media (min-width: ${LG}) {
    flex-wrap: nowrap;
  }
`;

const ProposalContent = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  &:last-of-type {
    margin-bottom: 24px;
  }

  @media (min-width: ${LG}) {
    flex-wrap: nowrap;
  }
`;

const avatarStyles = {
    width: '24px',
    height: '24px',
    fontSize: '16px',
    lineHeight: 1.2,
    fontWeight: 500,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    }
};

interface ProposalRowProps {
    conversationId: string;
    data: Message;
}

const ProposalRow: React.FC<ProposalRowProps> = ({conversationId, data}) => {
    const {isClient, isFreelancer, user} = useAuth();
    const {attributes: {status}, meta} = useConversation(conversationId);
    const [open, setOpen] = useState(false);

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const {attributes: {author, proposalId}} = data as IConversationMessage;
    const {name} = meta?.remoteParticipant || {};

    const apiService = isClient ? matchesService : freelancerService;
    const isAuthor = (isClient && author === EParticipantType.CLIENT) || (isFreelancer && author === EParticipantType.FREELANCER);

    const {
        isLoading,
        data: proposalData,
        refetch
    } = useQuery([GET_PROPOSALS, proposalId], () => apiService.getProposal(proposalId || 0), {
        enabled: !!proposalId,
        select: ((response) => response.data),
        onError: (error) => showToastError(error as AxiosError)
    });

    const avatarProposal = isAuthor ? meta?.localParticipant?.avatar : meta?.remoteParticipant?.avatar;
    const nameProposal = isAuthor ? user?.name : meta?.remoteParticipant?.name;

    const handleClose = useCallback((isMutated: number | null) => {
        setOpen(false);

        if (isMutated) {
            refetch();
        }
    }, [refetch]);

    // refetch for all users by Accept/Reject
    useEffect(() => {
        if (proposalData && [EProposalStatus.CLIENT_SENT, EProposalStatus.FREELANCER_SENT].includes(proposalData.status)) {
            setTimeout(() => refetch(), 1000);  // looks like it is not sync and we should wait
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <>
            {!isAuthor && (
                <ProposalNotification>
                    <img src="/assets/images/confetti-icon.png" width="24" height="24" alt="confetti icon"/>
                    <p>{name} sent you a proposal!</p>
                </ProposalNotification>
            )}

            {isLoading && (
                <ProposalInfo>
                    <ProposalContent>
                        <LoadingMask hasNoSpace/>
                    </ProposalContent>
                </ProposalInfo>
            )}

            {!!proposalData && (
                <ProposalInfo>
                    <ProposalContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                gap: '8px',
                                [theme.breakpoints.up('sm')]: {
                                    maxWidth: 'calc(100% - 206px)'
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    width: '100%',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    color: theme.palette.black.main,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    flexShrink: 0,
                                    maxWidth: 'calc(100% - 24px)',
                                    'img': {
                                        display: 'inline-block',
                                        verticalAlign: 'middle',
                                        marginRight: '16px'
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        maxWidth: '95%'
                                    }
                                }}
                            >
                                <img
                                    src="/assets/images/proposal-icon.png"
                                    width="24" height="24"
                                    alt="proposal icon"
                                />
                                {proposalData.name}
                            </Typography>
                            {
                                mdUp ? (
                                    <>
                                        <Pill
                                            variant="purple"
                                        >
                                            {formatCurrency(proposalData.rate || 0)}{proposalData.workType === EWorkType.hourly ? '/hour' : ''}
                                        </Pill>
                                        <Pill
                                            variant="white"
                                        >
                                            {EWorkTypeDisplay[proposalData.workType]}
                                        </Pill>

                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            sx={{
                                                paddingRight: '12px',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                lineHeight: '20px',
                                                color: theme.palette.purple.main,
                                                position: 'relative',
                                                '&::after': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '2px',
                                                    transform: 'translateY(-50%)',
                                                    width: '4px',
                                                    height: '4px',
                                                    borderRadius: '50%',
                                                    backgroundColor: theme.palette.gray.main
                                                }
                                            }}
                                        >
                                            {formatCurrency(proposalData.rate || 0)}{proposalData.workType !== EWorkType.fixed ? '/hour' : ''}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                lineHeight: '20px',
                                                color: theme.palette.textGray.dark,
                                            }}
                                        >
                                            {EWorkTypeDisplay[proposalData.workType]}
                                        </Typography>
                                    </>
                                )
                            }
                        </Box>

                        <Box display="flex" flexWrap="nowrap" gap={2}>
                            {[EProposalStatus.CLIENT_SENT, EProposalStatus.FREELANCER_SENT].includes(proposalData.status) && (
                                <PendingPill isAuthor={isAuthor}/>
                            )}

                            {[EProposalStatus.CLIENT_ACCEPTED, EProposalStatus.FREELANCER_ACCEPTED].includes(proposalData.status) && (
                                <AcceptedPill isAuthor={isAuthor}/>
                            )}

                            {[EProposalStatus.CLIENT_REJECTED, EProposalStatus.FREELANCER_REJECTED].includes(proposalData.status) && (
                                <RejectedPill isAuthor={isAuthor}/>
                            )}

                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => setOpen(true)}
                            >
                                View
                            </Button>
                        </Box>
                    </ProposalContent>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: '16px',
                            mt: '16px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            <Avatar
                                alt={nameProposal}
                                src={avatarProposal}
                                sx={{
                                    ...avatarStyles,
                                    fontSize: 11,
                                    backgroundColor: stringToColor(nameProposal || ''),
                                }}
                            >
                                {getNameLetters(nameProposal || '')}
                            </Avatar>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 500
                                }}
                            >
                                {nameProposal}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: 400,
                                color: theme.palette.gray.main
                            }}
                        >
                            {data.dateUpdated && formatTime(data.dateUpdated)}
                        </Typography>
                    </Box>
                </ProposalInfo>
            )}

            <ChatSendProposal
                conversationId={conversationId}
                data={proposalData}
                isReadOnly={isAuthor
                    || proposalData?.status === EProposalStatus.CLIENT_ACCEPTED
                    || proposalData?.status === EProposalStatus.CLIENT_REJECTED
                    || proposalData?.status === EProposalStatus.FREELANCER_ACCEPTED
                    || proposalData?.status === EProposalStatus.FREELANCER_REJECTED
                }
                open={open}
                onClose={handleClose}
            />

        </>
    );
};

export default React.memo(ProposalRow);
