import React from 'react';

import {FaqList} from '../../../../components';

const generalQuestions = [
    {
        title: 'Is Growth Collective an Agency?',
        text: 'No, we are not a marketing agency. We’re a platform that connects companies directly with vetted, independent marketing talent. \n' +
            '\n' +
            'Hiring a marketer through Growth Collective has many advantages over a marketing agency. You\'re able to go straight to the talent source and avoid paying for agency overhead. You\'re also able to select the specific marketer who best fits your needs. Finally, we don’t require any long-term commitment, so you can scale up or down as your business needs change. \n' +
            '\n' +
            'We feel this is a more effective way to build a high-performing marketing team.'
    },
    {
        title: 'What kinds of marketers can I hire?',
        text: 'We have experts across every major channel, tool and vertical. Whether you need help with scaling paid social, setting up HubSpot, or branding your eCommerce store, we’ve got you covered.'
    },
    {
        title: 'Do you only have marketers?',
        text: 'We also have experts on marketing-related tools and no-code/low-code platforms. We’ve found that these skills are often complementary to your growth efforts.\n' +
            '\n' +
            'For example, if you hire one of our brand marketers for an upcoming product launch, you might also request one of our Webflow experts to build the marketing website.'
    },
    {
        title: 'How does GC ensure high-quality candidates?',
        text: 'We carefully vet every expert who joins our platform. Our rigorous evaluation process includes client-verified case studies, reference checks, and portfolio reviews.\n' +
            '\n' +
            'We incentivize clients and freelancers to refer great talent our way, so we receive a huge volume of inbound applications each week.'
    },
    {
        title: 'Where are marketers located?',
        text: 'Most of our marketers are based in the US and CA.'
    },
    {
        title: 'How does Growth Collective make money?',
        text: 'Growth Collective earns a 20% share of freelancer compensation for handling marketing, sales, back office, legal, time-tracking, 1099 compliance, billing and payments. This allows our experts to focus 100% on helping your business grow.\n' +
            '\n' +
            'Growth Collective is completely free for clients. However, clients are required to keep any hiring of GC experts on the platform.'
    }
];

const GeneralTab = () => {
    return (
        <>
            <FaqList list={generalQuestions}/>
        </>
    );
};

export default React.memo(GeneralTab);
