import React, {useEffect, useMemo, useRef} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {Box, Theme, useMediaQuery} from '@mui/material';

import theme from '../../theme';
import {TextButton} from '../Button';
import {Tab, Tabs} from '../Tabs/Tabs';

import StepIndicator from './StepIndicator';
import StepsNavBar, {IStepNav} from './StepsNavBar';
import StepsProgressBar from './StepsProgressBar';

type StepsLayoutProps = {
    currentStep: number;
    steps: IStepNav[];
    onCancel: () => void;
};

const StepsLayout: React.FC<StepsLayoutProps> = ({currentStep, steps, onCancel}) => {
    const tabRef = useRef<HTMLDivElement>(null);

    const location = useLocation();
    const navigate = useNavigate();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const currentStepPercent = useMemo(() => {
        return (100 / steps.length) * currentStep;
    }, [currentStep, steps]);

    // at mobile we will have tabs at the top
    // focus will scroll to the active tab
    useEffect(() => {
        if (!tabRef.current) return;
        tabRef.current?.focus();
    }, [location.pathname, steps.length]);

    return (
        <Box
            sx={{
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    gap: '24px',
                    justifyContent: 'space-between',
                    mt: '40px'
                },
                [theme.breakpoints.up('lg')]: {
                    display: 'grid',
                    gridTemplateColumns: 'minmax(auto, 365px) 728px 365px'
                }
            }}
        >
            <Box
                sx={{
                    mt: '40px',
                    mb: '32px',
                    [theme.breakpoints.up('md')]: {
                        display: 'none'
                    },
                    [theme.breakpoints.up('lg')]: {
                        display: 'block',
                        width: '100%',
                        maxWidth: '365px'
                    }
                }}
            >
                {mdDown && (
                    <Tabs hasScroll fullWidth>
                        {steps.map((item, index) => (
                            <Tab
                                disabled={item.disabled}
                                isActive={currentStep === index + 1}
                                key={item.link}
                                tabIndex={currentStep === index + 1 ? 0 : undefined}
                                ref={currentStep === index + 1 ? tabRef : null}
                                onClick={() => navigate(item.link)}
                            >
                                {item.text}
                            </Tab>
                        ))}
                    </Tabs>
                )}
            </Box>

            <Box
                sx={{
                    width: '100%',
                    flexShrink: .5
                }}
            >
                <Box
                    sx={{
                        maxWidth: '720px',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box
                        sx={{
                            minHeight: 'calc(100vh - 210px)',
                            m: '0 -20px',
                            padding: '0 20px 180px 20px',
                            backgroundColor: theme.palette.white.main,
                            borderRadius: '24px 24px 0 0',
                            overflowY: 'auto',
                            [theme.breakpoints.up('sm')]: {
                                minHeight: 'unset',
                                margin: 'auto',
                                width: '100%',
                                maxWidth: '1000px',
                                overflow: 'unset',
                                padding: '0 20px 64px',
                                borderRadius: '24px'
                            },
                            [theme.breakpoints.up('lg')]: {
                                p: '0 64px 64px'
                            }
                        }}
                    >
                        <StepsProgressBar progress={currentStepPercent} sx={{
                            [theme.breakpoints.up('lg')]: {
                                m: '0 -64px 40px'
                            }
                        }}/>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: '24px',
                                [theme.breakpoints.up('md')]: {
                                    mb: '40px'
                                }
                            }}
                        >
                            <Box width="50px"/>
                            <StepIndicator
                                total={steps.length}
                                value={currentStep > steps.length ? steps.length : currentStep}
                            />
                            <TextButton
                                sx={{
                                    fontSize: 14
                                }}
                                onClick={onCancel}
                            >
                                Cancel
                            </TextButton>
                        </Box>

                        {/* Route for current step */}
                        <Outlet/>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    },
                    width: '100%'
                }}
            >
                <StepsNavBar items={steps}/>
            </Box>
        </Box>
    );
};

export default StepsLayout;
