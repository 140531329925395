import React from 'react';
import {RouteObject} from 'react-router-dom';

import {CaseStudy, CaseStudyPreviewApplication} from '../components';
import {RouteApplication} from '../constants';
import {ECaseStudySteps, ERole} from '../models';
import {
    AddCertificate,
    AddReference,
    ApplicationCaseStudyPage,
    ApplicationContent,
    ApplicationLayout,
    ApplicationThankYou
} from '../modules/Application';
import {RequireUser} from '../modules/Auth';

export const applicationRoutes: RouteObject = {
    path: '/',
    element: <ApplicationLayout/>,
    children: [
        {
            path: '',
            element: <RequireUser allowedRoles={[ERole.APPLICANT]}/>,
            children: [
                {
                    path: RouteApplication.application,
                    element: <ApplicationContent/>
                },
                {
                    path: `${RouteApplication.caseStudy}/edit/:caseStudyId`,
                    element: <ApplicationCaseStudyPage/>,
                    children: [
                        {
                            path: ECaseStudySteps.description,
                            element: <CaseStudy.Step1Description/>
                        },
                        {
                            path: ECaseStudySteps.company,
                            element: <CaseStudy.Step2Company/>
                        },
                        {
                            path: ECaseStudySteps.challenge,
                            element: <CaseStudy.Step3Challenge/>
                        },
                        {
                            path: ECaseStudySteps.outcomes,
                            element: <CaseStudy.Step4Outcomes/>
                        },
                        {
                            path: ECaseStudySteps.testimonial,
                            element: <CaseStudy.Step5Testimonial/>
                        }
                    ]
                },
                {
                    path: `${RouteApplication.caseStudy}/:caseStudyId`,
                    element: <CaseStudyPreviewApplication/>,
                },
                {
                    path: `${RouteApplication.editCertificate}/:certificateId`,
                    element: <AddCertificate/>
                },
                {
                    path: `${RouteApplication.addCertificate}`,
                    element: <AddCertificate/>
                },
                {
                    path: `${RouteApplication.addReference}`,
                    element: <AddReference/>
                },
                {
                    path: `${RouteApplication.editReference}/:referenceId`,
                    element: <AddReference/>
                }
            ],
        },
    ],
};

export const applicationRouteThankYou: RouteObject = {
    path: RouteApplication.completed,
    element: <ApplicationThankYou/>
};
