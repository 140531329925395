import React from 'react';

const PencilOutlineIcon = ({fill = '#999999', fontSize = 16}) => {
    return (
        <svg width={fontSize} height={fontSize} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6666 3.82672C13.6671 3.73898 13.6503 3.65201 13.6171 3.57078C13.5839 3.48956 13.535 3.41568 13.4733 3.35339L10.6466 0.526718C10.5843 0.464931 10.5104 0.416047 10.4292 0.382871C10.348 0.349695 10.261 0.332878 10.1733 0.333385C10.0855 0.332878 9.99855 0.349695 9.91732 0.382871C9.8361 0.416047 9.76222 0.464931 9.69993 0.526718L7.81326 2.41338L0.526596 9.70005C0.464809 9.76234 0.415925 9.83622 0.382749 9.91745C0.349573 9.99867 0.332756 10.0856 0.333263 10.1734V13.0001C0.333263 13.1769 0.403501 13.3464 0.528525 13.4715C0.653549 13.5965 0.823119 13.6667 0.99993 13.6667H3.8266C3.91988 13.6718 4.01319 13.6572 4.10048 13.6239C4.18776 13.5906 4.26707 13.5393 4.33326 13.4734L11.5799 6.18672L13.4733 4.33338C13.5341 4.26877 13.5837 4.19441 13.6199 4.11338C13.6264 4.06025 13.6264 4.00652 13.6199 3.95338C13.623 3.92235 13.623 3.89108 13.6199 3.86005L13.6666 3.82672ZM3.55326 12.3334H1.6666V10.4467L8.2866 3.82672L10.1733 5.71338L3.55326 12.3334ZM11.1133 4.77338L9.2266 2.88672L10.1733 1.94672L12.0533 3.82672L11.1133 4.77338Z"/>
        </svg>
    );
};

export default PencilOutlineIcon;
