import React from 'react';

import {TextFieldProps} from '@mui/material';

import theme from '../../../theme';
import Input from '../../Forms/Input';

const ConfirmationCodeInput = React.forwardRef(({...props}: TextFieldProps, ref) => {
    return (
        <Input
            {...props}
            inputProps={{maxLength: 1}}
            ref={ref}
            sx={{
                '.MuiInputBase-root': {
                    width: '56px',
                    height: '56px',
                    borderRadius: '8px',
                    borderColor: '#E3E3E3',
                    [theme.breakpoints.up('lg')]: {
                        width: '64px',
                        height: '64px'
                    }
                },
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E3E3E3'
                },
                '.MuiInputBase-input': {
                    textAlign: 'center',
                    appearance: 'textfield !important',
                    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        appearance: 'none !important',
                        margin: 0
                    }
                }
            }}
            variant="outlined"
        />
    );
});

export default ConfirmationCodeInput;
