// https://react-dropzone.js.org/

import React, {ReactNode, useCallback, useMemo} from 'react';
import {Accept, FileRejection, useDropzone} from 'react-dropzone';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    styled,
    Theme,
    Typography,
    useMediaQuery
} from '@mui/material';

import {DocumentIcon, TrashIcon, UploadColorfulIcon} from '../../../assets/icons';
import {MAX_FILE_SIZE_MB, MD} from '../../../constants';
import theme from '../../../theme';
import {getFileName, getReadableFileSizeString} from '../../../utils';
import {AnimateHeight} from '../..';

export const DropArea = styled(Box)<{ disabled?: boolean; dragover?: string }>`
  background-color: ${props => props.disabled ? '#ededed' : (props.dragover === 'true' ? theme.palette.lilac.main : theme.palette.white.main)};
  padding: 16px;
  border-radius: 16px;
  border: ${props => props.disabled ? '2px solid #dddddd' : (props.dragover === 'true'
        ? '2px dashed ' + theme.palette.purple.main
        : '2px dashed ' + theme.palette.lightGray.main)};
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: ${props => props.dragover === 'true' ? 'pulsing .5s alternate-reverse infinite linear' : 'none'};
  transition: border-color .3s;

  &:hover {
    border-color: ${theme.palette.purple.main};
    animation: pulsing .5s alternate-reverse infinite linear;
  }

  @media (min-width: ${MD}) {
    padding: 40px;
  }

  @keyframes pulsing {
    from {
      background-color: ${theme.palette.lilac.main};
    }
    to {
      background-color: ${theme.palette.white.main};
    }
  }
`;

const thumb = {
    display: 'inline-flex',
    borderRadius: '10px',
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
} as any;

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

interface Props {
    accept: Accept;
    disabled?: boolean;
    isPreviewThumbs?: boolean;
    label?: string;
    max?: number;
    mode: 'append' | 'update';
    multiple: boolean;
    name: string;
    placeholder?: string | ReactNode;
}

const InputFile: React.FC<Props> = ({
    accept,
    disabled,
    isPreviewThumbs,
    max = MAX_FILE_SIZE_MB,
    name,
    label,
    mode = 'update',
    placeholder,
    ...props
}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const files = value;
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const onDrop = useCallback(
        (droppedFiles: File[]) => {
            let newFiles = mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles];

            if (mode === 'append') {
                newFiles = newFiles.reduce((prev, file) => {
                    const fo = Object.entries(file);

                    if (
                        prev.find((e: File) => {
                            const eo = Object.entries(e);

                            return eo.every(
                                ([key, value], index) =>
                                    key === fo[index][0] && value === fo[index][1]
                            );
                        })
                    ) {
                        return prev;
                    } else {
                        return [...prev, file];
                    }
                }, []);
            }
            onChange(newFiles);
            trigger(name);
        },
        [files, mode, name, onChange, trigger]
    );

    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        onChange([fileRejections[0].file]);
        trigger(name);
    }, [name, onChange, trigger]);

    const {isDragActive, getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        accept
    });

    const handleDelete = useCallback((event: React.SyntheticEvent, file: File) => {
        event.stopPropagation();
        onChange(files.filter((it: File) => it !== file));
        trigger(name);
    }, [files, name, onChange, trigger]);

    const thumbs = useMemo(() => files && files.map((file: File) =>
        isPreviewThumbs ? (
            <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                    <img
                        src={URL.createObjectURL(file)}
                        style={img}
                        alt={file.name}
                    />
                </div>
            </div>
        ) : (
            <Box key={file.name} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '8px',
                mt: '24px',
                p: '14px 12px',
                borderRadius: '8px',
                backgroundColor: theme.palette.blue.light,
                width: '100%',
                'svg': {
                    flexShrink: 0,
                    width: '24px',
                    height: '24px',
                }
            }}>
                <DocumentIcon/>
                <Box
                    sx={{
                        mr: 'auto',
                        maxWidth: 'calc(100% - 56px)',
                        [theme.breakpoints.up('md')]: {
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '21px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            // maxWidth: '80%',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {getFileName(file.name, smDown ? 30 : (mdDown ? 50 : (lgDown ? 100 : 150)))}
                    </Typography>
                    <Typography
                        sx={{
                            mr: 'auto',
                            flexShrink: 0,
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: file.size > max ? theme.palette.error.main : theme.palette.gray.main
                        }}
                    >
                        {file.size ? `${getReadableFileSizeString(file.size)}` : ''}
                    </Typography>
                </Box>
                <IconButton
                    sx={{
                        flexShrink: 0,
                        m: '-8px',
                        'div': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        'path': {
                            fill: file.size > max ? theme.palette.error.main : theme.palette.black.main,
                            transition: 'fill .3s'
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                            'path': {
                                fill: theme.palette.error.main
                            }
                        }
                    }}
                    title="Delete File"
                    onClick={(e) => handleDelete(e, file)}
                >
                    <TrashIcon/>
                </IconButton>
            </Box>

        )
    ), [files, isPreviewThumbs, max, smDown, mdDown, lgDown, handleDelete]);

    return (
        <Controller
            control={control}
            defaultValue={false}
            name={name}
            render={() => (
                <FormControl
                    error={!!errors[name]}
                    sx={{
                        width: '100%'
                    }}
                >
                    {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
                    <DropArea {...getRootProps()} dragover={isDragActive.toString()} disabled={disabled}>
                        <input
                            {...props}
                            id={name}
                            name={name}
                            {...getInputProps()}
                        />
                        <UploadColorfulIcon/>

                        {/*
                                {!dragover && 'Click here or drop a file to upload!'}
                                {dragover && !isDragReject && "Drop it like it's hot!"}
                                {isDragReject && "File type not accepted, sorry!"}
                            */}

                        {
                            thumbs?.length
                                ? thumbs
                                : (
                                    <Typography
                                        sx={{
                                            mt: '24px',
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            lineHeight: '21px',
                                            color: theme.palette.black.main,
                                            textAlign: 'center',
                                            'span': {
                                                display: 'block',
                                                mt: '2px',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: theme.palette.gray.main,
                                            }
                                        }}
                                    >
                                        {placeholder}
                                    </Typography>
                                )

                        }
                    </DropArea>
                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </FormControl>
            )}
        />
    );
};

export default React.memo(InputFile);
