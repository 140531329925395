import React from 'react';

import {Box, Typography} from '@mui/material';

import {useAuth} from '../../../hooks';
import {EWorkWeekStatus, EWorkWeekStatusDisplay} from '../../../models';
import theme from '../../../theme';
import {MoneyEarnedSpent, Pill} from '../../index';
import {getVariantByStatus} from '../helper';

interface ITimesheetHeadingProps {
    actions?: React.ReactNode;
    endDate: string;
    hoursSum?: string;
    isCollapsed?: boolean;
    moneyAmount?: string;
    startDate: string;
    status?: EWorkWeekStatus;
}

const TimesheetHeading: React.FC<ITimesheetHeadingProps> = ({
    actions,
    endDate,
    hoursSum = '0:00',
    isCollapsed,
    moneyAmount,
    startDate,
    status,
}) => {
    const {isClient} = useAuth();

    return (
        <>
            {
                startDate && (
                    <Typography
                        sx={{
                            mr: 'auto',
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: 1.5,
                            flexGrow: 1,
                            order: 1,
                            width: 'calc(100% - 101px)',
                            [theme.breakpoints.up(850)]: {
                                width: 'auto'
                            }
                        }}
                    >
                        {startDate} - {endDate}
                    </Typography>
                )
            }

            {moneyAmount && moneyAmount !== '$0' && (
                <MoneyEarnedSpent
                    isClient={isClient}
                    moneyAmount={moneyAmount}
                />
            )}

            {status && (
                <Box
                    sx={{
                        order: 3,
                        [theme.breakpoints.up(850)]: {
                            order: 2
                        }
                    }}
                >
                    <Pill variant={getVariantByStatus(status)}>{EWorkWeekStatusDisplay[status]}</Pill>
                </Box>
            )}

            {hoursSum && (
                <Typography
                    align='right'
                    sx={{
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: 1.5,
                        order: 4,
                        minWidth: '40px',
                        [theme.breakpoints.up(850)]: {
                            order: 3
                        }
                    }}
                >
                    {hoursSum}h
                </Typography>
            )}

            {!isCollapsed && actions && (
                <Box
                    sx={{
                        flexGrow: 1,
                        order: 5,
                        width: '100%',
                        mt: '8px',
                        [theme.breakpoints.up(850)]: {
                            order: 4,
                            width: 'auto',
                            flexGrow: 'unset',
                            mt: 0
                        }
                    }}
                >
                    {actions}
                </Box>
            )}
        </>
    );
};

export default React.memo(TimesheetHeading);
