import React, {FC} from 'react';
import {object} from 'zod';

import {Grid} from '@mui/material';
import {CardElement} from '@stripe/react-stripe-js';
import {StripeCardElement} from '@stripe/stripe-js';

import {ISelectOption} from '../../../../../../models';

import BillingAddressForm, {billingAddressSchema} from './BillingAddressForm';

export const cardSchema = object({
    ...billingAddressSchema
});

interface IProps {
    optionsCountry?: ISelectOption[];
    setCardElement: (element: StripeCardElement) => void;
    cardError?: string;
}

const PaymentCardForm: FC<IProps> = ({optionsCountry, setCardElement, cardError}) => {
    return (
        <>
            <Grid container spacing="24px" sx={{mt: '16px', mb: '40px'}}>
                <Grid
                    item
                    xs={12}
                >
                    <label
                        htmlFor="stripe-input-wrapper"
                        className="stripe-input-label"
                    >
                        Card details
                    </label>
                    <CardElement
                        className="stripe-input-wrapper"
                        id="stripe-input-wrapper"
                        options={{
                            style: {
                                base: {
                                    '::placeholder': {
                                        fontFamily: '"Poppins",Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        color: '#999999'
                                    },
                                    ':focus': {
                                        color: '#535A62'
                                    }
                                },
                                complete: {
                                    color: '#535A62',
                                    fontFamily: '"Poppins",Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    // color: '#999999'
                                }
                            }
                        }}
                        onReady={element => {
                            setCardElement(element);
                        }}
                    />
                </Grid>
            </Grid>
            {cardError && <div style={{color: '#EF462C'}}>{cardError}</div>}
            <BillingAddressForm optionsCountry={optionsCountry}/>
        </>
    );
};

export default React.memo(PaymentCardForm);
