import React from 'react';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';

import {styled} from '@mui/material';

import theme from '../../../../../theme';
import {showTimeOrDate, showTodayOrDate} from '../../../../../utils';

const DateRowWrapper = styled('p')`
  padding: 8px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${theme.palette.gray.main};
`;

interface DateRowProps {
    currentDate: Date | null;
    prevDate: Date | null;
}

const DateRow: React.FC<DateRowProps> = ({currentDate, prevDate}) => {
    // show a Date for the first old message
    if (!prevDate && currentDate && !isToday(currentDate)) {
        return (
            <DateRowWrapper>
                {showTimeOrDate(currentDate, false)}
            </DateRowWrapper>
        );
    }

    // we need to show a Date only for first message per day
    if (!prevDate || !currentDate || isSameDay(currentDate, prevDate)) {
        return null;
    }

    return (
        <DateRowWrapper>
            {showTodayOrDate(currentDate, false)}
        </DateRowWrapper>
    );
};

export default React.memo(DateRow);
