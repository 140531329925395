import React from 'react';

import {Box} from '@mui/material';

import {ClockIcon, NgIcon, PurseOutlineIcon} from '../../../assets/icons';
import {useAnimProgress, useAuth} from '../../../hooks';
import {IReportBase} from '../../../models';
import theme from '../../../theme';
import {formatCurrency, formatHoursFromDuration} from '../../../utils';

import HeaderItem from './HeaderItem';
import SelectRole from './SelectRole';


type HeaderTotalsProps = {
    data?: IReportBase;
    selectedRoleIds: string[];
    onSelectRole: (roleIds: string[]) => void;
};

const HeaderTotals: React.FC<HeaderTotalsProps> = ({data, selectedRoleIds, onSelectRole}) => {
    const {isClient} = useAuth();
    const progress = useAnimProgress(data?.money || 0);

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '32px',
                p: '24px',
                mb: '40px',
                background: theme.palette.white.main,
                borderRadius: '24px',
                [theme.breakpoints.up('md')]: {
                    gap: '40px'
                }
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    [theme.breakpoints.up('sm')]: {
                        width: 'auto'
                    }
                }}
            >
                <SelectRole values={selectedRoleIds} onSelect={onSelectRole}/>
            </Box>

            <HeaderItem
                icon={<ClockIcon/>}
                label="Total hours"
                value={data?.workTime ? formatHoursFromDuration(data.workTime, false) : '0h 00m'}
            />

            <HeaderItem
                icon={<PurseOutlineIcon/>}
                label={isClient ? 'Total spent' : 'Total earned'}
                value={data?.money ? formatCurrency(progress, true) : '$0'}
            />

            <HeaderItem
                icon={<NgIcon/>}
                label="Active roles"
                value={(data?.activeRoles || 0).toString()}
            />

        </Box>
    );
};

export default HeaderTotals;
