import React, {useCallback} from 'react';
import {AxiosError} from 'axios';
import uniqBy from 'lodash/uniqBy';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {metaService} from '../../../api';
import {GET_VERTICALS} from '../../../constants';
import {IVertical, IVerticalsResponse, OptionType} from '../../../models';
import theme from '../../../theme';
import {AnimateHeight, ChipsList, LoadingMask, SelectMulti, showToastError} from '../..';

interface InputVerticalsProps {
    name: string;
}

export const InputVerticals: React.FC<InputVerticalsProps> = React.memo(({name}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const verticals: IVertical[] = value;

    const {
        isLoading,
        data: verticalsAndRecommendedData
    } = useQuery([GET_VERTICALS], metaService.getVerticals, {
        select: ((response: IVerticalsResponse) => ({
            recommended: uniqBy(response.recommended, 'name'),
            verticals: uniqBy(response.verticals, 'name')
        })),
        onError: (error) => showToastError(error as AxiosError)
    });

    const verticalsData = verticalsAndRecommendedData?.verticals || [];
    const verticalsRecommendedData = verticalsAndRecommendedData?.recommended || [];

    const handleAddVertical = useCallback((option: OptionType) => {
        const isAlreadyExists = verticals.find(item => item.id === option.id);

        if (!isAlreadyExists) {
            onChange([...verticals, option as IVertical]);
        }
        trigger(name);
    }, [name, verticals, onChange, trigger]);

    const handleChangeVertical = useCallback((newValue: OptionType | null) => {
        const isAlreadyExists = verticals.find(item => item.name === newValue?.name);

        if (newValue?.name && !isAlreadyExists) {
            onChange([...verticals, newValue as IVertical]);
        }
        trigger(name);
    }, [name, verticals, onChange, trigger]);

    const handleDeleteVertical = useCallback((option: OptionType) => {
        const newVerticals = verticals.filter(v => v !== option);

        onChange(newVerticals);
        trigger(name);
    }, [name, verticals, onChange, trigger]);

    if (isLoading) {
        return (
            <LoadingMask/>
        );
    }

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <Box tabIndex={0} ref={ref}>
                    <SelectMulti
                        options={verticalsData}
                        placeholder="Ex. Health & Wellness, Campaigns, SaaS"
                        selected={verticals}
                        onChange={handleChangeVertical}
                        onDelete={handleDeleteVertical}
                    />
                    <Typography
                        sx={{
                            display: 'inline-block',
                            marginTop: '24px',
                            marginBottom: '12px',
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: theme.palette.gray.main
                        }}
                        variant="caption"
                    >
                        Suggested verticals
                    </Typography>

                    <AnimateHeight isVisible={true}>
                        <ChipsList
                            items={verticalsRecommendedData}
                            selectedVerticals={verticals}
                            onAdd={handleAddVertical}
                        />
                    </AnimateHeight>

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </Box>
            )}
        />
    );
});
