import {isValid} from 'date-fns';
import {any, object} from 'zod';

export const scheduleSchema = object({
    from: any(),
    to: any(),
    id: any(),
    status: any(),
    timezone: any(),
})
    .refine((data) => isValid(data.from), {
        path: ['from'],
        message: 'Date is required'
    })
    .refine((data) => isValid(data.to), {
        path: ['to'],
        message: 'To is required'
    })
    .refine((data) => data.timezone, {
        path: ['timezone'],
        message: 'Time zone is required'
    });
