import React, {useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import isEqual from 'lodash/isEqual';

import {Box} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {
    AnimatePageWrp,
    ContinueButton,
    Languages,
    LoadingMask,
    LocationsMulti,
    PageTopTitle,
    Portal,
    showToastError,
    showToastSuccess,
    TimeZone
} from '../../../components';
import {RouteCreateClientAccount} from '../../../constants';
import {useClientRole, useLocations, useNavigateUTM} from '../../../hooks';
import {ILocationRequest, ISelectOption} from '../../../models';
import theme from '../../../theme';
import {TIMEZONES_OPTIONS} from '../../../utils/timezones';

export const LocationTimezone = () => {
    const {clientRole, isClientLoading, refetchClient} = useClientRole();
    const {
        countries: dataCountries,
        isLocationLoading,
        languages: dataLanguages,
    } = useLocations();
    const [countries, setCountries] = useState<ISelectOption[]>([]);
    const [languages, setLanguages] = useState<ISelectOption[]>([]);
    const [timezone, setTimezone] = useState<ISelectOption | null>(null);
    const navigate = useNavigateUTM();

    const {mutate} = useMutation(
        (payload: ILocationRequest) => clientService.locationSubmit(clientRole?.id || 0, payload),
        {
            onSuccess() {
                showToastSuccess('Location was saved successfully!');
                navigate(RouteCreateClientAccount.budget);
                refetchClient();
            },
            onError(error: AxiosError) {
                showToastError(error);
            }
        }
    );

    const optionsCountry = useMemo(() => dataCountries?.map(country => ({code: country.code, text: country.name, value: country.code})), [dataCountries]);

    const optionsLanguage = useMemo(() => dataLanguages?.map(lang => ({text: lang, value: lang})), [dataLanguages]);

    // populate form
    useEffect(() => {
        if (!clientRole || !optionsCountry) {
            return;
        }

        const {countries, languages, timezone} = clientRole;

        if (countries?.length) {
            const values = optionsCountry?.filter(item => countries.includes(item.text));    // FIXME BE do not support CountryCode but should!!!

            setCountries(values);
        }

        if (languages) {
            const value = optionsLanguage?.filter(item => languages.includes(item.value)) || [];

            setLanguages(value);
        }

        if (timezone) {
            const value = TIMEZONES_OPTIONS.find(item => item.value === timezone) || null;

            setTimezone(value);
        }
    }, [clientRole, optionsCountry, optionsLanguage]);

    const handleSubmit = () => {
        if (!clientRole) return;

        const payload: ILocationRequest = {
            countries: countries.map((item: ISelectOption) => item.text),   // FIXME BE do not support CountryCode but should!!!
            languages: languages.map((item: ISelectOption) => item.text),
            timezone: timezone?.value || null
        };
        const prevValues = {countries: clientRole.countries, timezone: clientRole.timezone};

        // update if isDirty or first time there or redirect to the next step
        if (isEqual(payload, prevValues) && clientRole.view !== 'tools') {
            navigate(RouteCreateClientAccount.budget);
        } else {
            mutate(payload);
        }
    };

    return (
        <AnimatePageWrp maxWidth={{md: 700, lg: 800}}>
            <PageTopTitle
                title={<>
                    Do you require that the work is done in a specific <span>country</span> and <span>timezone</span>?
                </>}
                titleMaxWidth={{md: 700, lg: 800}}
            />

            {
                (isClientLoading || isLocationLoading) ? (
                    <LoadingMask/>
                ) : (
                    <>

                        <Box sx={{mb: 5}}>
                            <LocationsMulti
                                options={optionsCountry || []}
                                values={countries}
                                onChange={setCountries}
                            />
                        </Box>

                        <Box sx={{pb: 5}}>
                            <TimeZone
                                value={timezone}
                                onChange={setTimezone}
                            />
                        </Box>

                        <Languages
                            options={optionsLanguage || []}
                            values={languages}
                            onChange={setLanguages}
                        />

                    </>
                )
            }

            <Box
                textAlign="center"
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        mt: '40px'
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: '51px'
                    }
                }}
            >
                <ContinueButton
                    disabled={isClientLoading}
                    hasArrow
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Box>

            <Portal order={2}>
                <ContinueButton
                    disabled={isClientLoading}
                    hasArrow
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'none'
                        }
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Continue
                </ContinueButton>
            </Portal>
        </AnimatePageWrp>
    );
};
