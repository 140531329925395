import React from 'react';

const IdeaIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7348_21720)">
                <path
                    d="M24 5.625C23.2234 5.625 22.5938 6.25462 22.5938 7.03125C22.5938 7.80787 23.2234 8.4375 24 8.4375C28.6532 8.4375 32.4389 12.2226 32.4389 16.875C32.4389 17.6516 33.0685 18.2812 33.8452 18.2812C34.6218 18.2812 35.2514 17.6516 35.2514 16.875C35.2514 10.6718 30.2041 5.625 24 5.625Z"
                    fill="#7E5FFF"/>
                <path
                    d="M20.3743 0.378658C14.0208 1.70625 8.86925 6.8085 7.51372 13.2014C6.36913 18.5997 7.7796 24.0194 11.3834 28.0708C13.1451 30.0511 14.1553 32.6651 14.1553 35.2499V38.0624C14.1553 39.9337 15.3804 41.5234 17.0703 42.074C17.6261 45.3576 20.4761 47.9998 23.9991 47.9998C27.5212 47.9998 30.3719 45.3584 30.9279 42.074C32.6178 41.5234 33.8428 39.9337 33.8428 38.0623V35.2498C33.8428 32.66 34.8565 30.0773 36.6968 27.9777C39.3906 24.9046 40.8741 20.9615 40.8741 16.8749C40.8741 6.22819 31.1289 -1.86815 20.3743 0.378658ZM23.9991 45.1874C22.1914 45.1874 20.6065 43.9435 20.0212 42.2718H27.9769C27.3917 43.9435 25.8068 45.1874 23.9991 45.1874ZM31.0303 38.0624C31.0303 38.8378 30.3995 39.4687 29.6241 39.4687H18.3741C17.5987 39.4687 16.9678 38.8378 16.9678 38.0624V36.6562H31.0303V38.0624ZM34.5818 26.124C32.6205 28.3615 31.4139 31.0597 31.1077 33.8437H16.8908C16.585 31.0582 15.3814 28.3335 13.485 26.2015C10.4815 22.825 9.30782 18.2993 10.2651 13.7848C11.3825 8.51475 15.6763 4.23366 20.9496 3.13181C29.9518 1.25053 38.0616 8.01103 38.0616 16.8749C38.0616 20.2792 36.8258 23.5639 34.5818 26.124Z"
                    fill="#7E5FFF"/>
                <path
                    d="M4.21875 16.875H1.40625C0.629625 16.875 0 17.5046 0 18.2812C0 19.0579 0.629625 19.6875 1.40625 19.6875H4.21875C4.99538 19.6875 5.625 19.0579 5.625 18.2812C5.625 17.5046 4.99538 16.875 4.21875 16.875Z"
                    fill="#7E5FFF"/>
                <path
                    d="M4.80339 9.84202L2.81467 7.8533C2.26558 7.30411 1.37514 7.30411 0.825953 7.8533C0.276766 8.40248 0.276766 9.29283 0.825953 9.84202L2.81467 11.8307C3.36377 12.3799 4.2542 12.38 4.80339 11.8307C5.35258 11.2815 5.35258 10.3912 4.80339 9.84202Z"
                    fill="#7E5FFF"/>
                <path
                    d="M4.80339 24.7283C4.2542 24.1791 3.36377 24.1791 2.81467 24.7283L0.825953 26.717C0.276766 27.2662 0.276766 28.1565 0.825953 28.7057C1.37505 29.2549 2.26548 29.255 2.81467 28.7057L4.80339 26.717C5.35258 26.1678 5.35258 25.2775 4.80339 24.7283Z"
                    fill="#7E5FFF"/>
                <path
                    d="M46.5938 16.875H43.7812C43.0046 16.875 42.375 17.5046 42.375 18.2812C42.375 19.0579 43.0046 19.6875 43.7812 19.6875H46.5938C47.3704 19.6875 48 19.0579 48 18.2812C48 17.5046 47.3704 16.875 46.5938 16.875Z"
                    fill="#7E5FFF"/>
                <path
                    d="M47.1784 7.8533C46.6293 7.30411 45.7389 7.30411 45.1897 7.8533L43.201 9.84202C42.6518 10.3912 42.6518 11.2815 43.201 11.8307C43.75 12.3798 44.6405 12.3799 45.1897 11.8307L47.1784 9.84202C47.7276 9.29283 47.7276 8.40248 47.1784 7.8533Z"
                    fill="#7E5FFF"/>
                <path
                    d="M47.1784 26.717L45.1897 24.7283C44.6406 24.1791 43.7501 24.1791 43.201 24.7283C42.6518 25.2775 42.6518 26.1678 43.201 26.717L45.1897 28.7057C45.7388 29.2548 46.6292 29.2549 47.1784 28.7057C47.7276 28.1565 47.7276 27.2662 47.1784 26.717Z"
                    fill="#7E5FFF"/>
            </g>
            <defs>
                <clipPath id="clip0_7348_21720">
                    <rect width="48" height="48" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default IdeaIcon;
