import React from 'react';

import {useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../api';
import {CLIENT_ROLES} from '../../../constants';


type RolesContextProviderProps = { children: React.ReactNode };

const RolesContext = React.createContext<
{
    cancelRole: (roleId: number) => void;
    cloneRole: (roleId: number) => void;
    publishRole: (roleId: number) => void;
} | undefined>(undefined);

const RolesContextProvider = ({children}: RolesContextProviderProps) => {
    const queryClient = useQueryClient();

    const cancelRole = async (roleId: number) => {
        await clientService.cancelRole(roleId);
        await queryClient.invalidateQueries({queryKey: [CLIENT_ROLES]});
    };

    const cloneRole = async (roleId: number): Promise<number> => {
        const response = await clientService.cloneRole(roleId);

        await queryClient.invalidateQueries({queryKey: [CLIENT_ROLES]});

        return response.data.id;
    };

    const publishRole = async (roleId: number) => {
        await clientService.publishRole(roleId);
        await queryClient.invalidateQueries({queryKey: [CLIENT_ROLES]});
    };

    return (
        <RolesContext.Provider
            value={{
                cancelRole,
                cloneRole,
                publishRole
            }}
        >{children}</RolesContext.Provider>
    );
};

const useRoles = () => {
    const context = React.useContext(RolesContext);

    if (context) {
        return context;
    }

    throw new Error('useRoles must be used within a RolesContextProvider');
};

export {RolesContextProvider, useRoles};
