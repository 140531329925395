import React from 'react';

import {ButtonProps} from '@mui/material';

import theme from '../../../theme';
import {Button} from '../../index';

const BlackBorderButton = ({children, sx, ...props}: ButtonProps): JSX.Element => {
    return (
        <Button
            {...props}
            variant="contained"
            sx={{
                padding: props.size === 'small' ? '10px 14px !important' : '17px 20px 18px',
                backgroundColor: theme.palette.white.main,
                color: theme.palette.black.main,
                border: '2px solid',
                borderColor: theme.palette.black.main,
                gap: '8px !important',
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.black.main,
                    color: theme.palette.white.main,
                    'path': {
                        fill: theme.palette.white.main
                    }
                },
                '&[disabled]': {
                    borderColor: theme.palette.gray.light,
                },
                'svg': {
                    flexShrink: 0
                },
                'path': {
                    fill: 'currentColor',
                    transition: 'fill .3s',
                },
                ...sx
            }}
        >
            {children}
        </Button>
    );
};

export default React.memo(BlackBorderButton);
