import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm, UseFormReturn} from 'react-hook-form';
import {TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Theme, useMediaQuery} from '@mui/material';
import {Elements} from '@stripe/react-stripe-js';
import {StripeCardElement, TokenResult} from '@stripe/stripe-js';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import {clientService} from '../../../../../../api';
import {BlackBorderButton, ContinueButton, DialogEmoji, showToastError, showToastSuccess} from '../../../../../../components';
import {CLIENT_BILLING_ADDRESS} from '../../../../../../constants';
import {useLocations} from '../../../../../../hooks';
import {EPaymentMethod, IBillingAddress, IPaymentCard, IPaymentMethod} from '../../../../../../models';
import {getFieldErrors} from '../../../../../../utils';

import PaymentACHForm, {achSchema} from './PaymentACHForm';
import PaymentCardForm, {cardSchema} from './PaymentCardForm';
import PaymentSEPAForm, {sepaSchema} from './PaymentSEPAForm';
import SelectPaymentMethod from './SelectPaymentMethod';
import {stripePromise} from './stripe';


export const stylesMenuItem = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
};

export const stylesInput = {
    mb: '0 !important'
};

type PaymentACHInput = TypeOf<typeof achSchema>;
type PaymentCardInput = TypeOf<typeof cardSchema>;
type PaymentSEPAInput = TypeOf<typeof sepaSchema>;

type PaymentInput = PaymentACHInput | PaymentCardInput;

interface DialogPaymentMethodProps {
    open: boolean;
    onClose: (reason: boolean) => void;
}

const DialogPaymentMethod: React.FC<DialogPaymentMethodProps> = ({open, onClose}) => {
    const [method, setMethod] = useState(EPaymentMethod.ACH);

    const queryClient = useQueryClient();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const {
        countries: dataCountries,
    } = useLocations();

    const optionsCountry = useMemo(() => dataCountries?.map(country => ({
        code: country.code,
        text: country.name,
        value: country.code,
    })), [dataCountries]);

    const {
        data: billingAddress,
        isFetched: isFetchedBillingAddress
    } = useQuery([CLIENT_BILLING_ADDRESS], clientService.paymentBillingAddress, {
        enabled: open,
        staleTime: 15 * 60 * 1000,
        select: ((response) => response.data)
    });

    const methodsCard = useForm<PaymentCardInput>({
        resolver: zodResolver(cardSchema),
    });

    const methodsACH = useForm<PaymentACHInput>({
        resolver: zodResolver(achSchema),
    });

    const methodsSEPA = useForm<PaymentSEPAInput>({
        resolver: zodResolver(sepaSchema),
    });

    const methods = (method === EPaymentMethod.ACH && methodsACH)
        || (method === EPaymentMethod.CreditCard && methodsCard)
        || (method === EPaymentMethod.SEPA && methodsSEPA)
        || {} as UseFormReturn<any>;

    const {
        // formState,
        handleSubmit,
        getValues,
        reset,
        setError,
    } = methods as UseFormReturn<PaymentInput>;

    const {mutate, isLoading} = useMutation(clientService.paymentMethodSubmit,
        {
            onSuccess(response) {
                console.log('success');
                onClose(true);
                showToastSuccess('Payment method has been saved successfully!');

                if (response.data.verificationUrl) {
                    const target = method === EPaymentMethod.ACH ? '_blank' : '_self'; // Verification page has success message: 'You can close this page';

                    window.open(response.data.verificationUrl, target);
                }
            },
            onError(error: AxiosError) {
                const errors = getFieldErrors(error);

                if (errors) {
                    errors.forEach(error => setError(error.field as keyof PaymentCardInput, {type: 'custom', message: error.message}));
                    showToastError(errors[0] as any);
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const {mutate: mutateAddress /*, isLoading*/} = useMutation(clientService.paymentBillingAddressSubmit,
        {
            onSuccess() {
                console.log('Billing Address');
                // onClose(true);
                // showToastSuccess('Billing Address has been saved successfully!');
            },
            onError(error: AxiosError) {
                const errors = getFieldErrors(error);

                if (errors) {
                    errors.forEach(error => setError(error.field as keyof PaymentACHInput, {type: 'custom', message: error.message}));
                } else {
                    showToastError(error);
                }
            },
        }
    );
    const [cardElement, setCardElement] = useState<StripeCardElement>();
    const [cardError, setCardError] = useState<string>();

    const onSubmitHandler: SubmitHandler<PaymentInput> = async () => {
        const values = getValues();
        const {country, city, line1, line2, postalCode, state} = values as PaymentCardInput;
        const payloadAddress: IBillingAddress = {
            country: country?.value, // get select value
            city,
            line1,
            line2,
            postalCode,
            state
        };

        console.log('submit', values);

        queryClient.invalidateQueries({queryKey: ['dashboard']});

        mutateAddress(payloadAddress);

        if (method === EPaymentMethod.CreditCard) {
            const stripe = await stripePromise;

            if (cardElement) {
                let token: TokenResult;

                try {
                    token = await stripe.createToken(
                        cardElement,
                        {
                            address_line1: line1,
                            address_line2: line2,
                            address_city: city,
                            address_state: state,
                            address_zip: postalCode,
                            address_country: country.code
                        },
                    );
                } catch (e) {
                    console.error(e);
                    showToastError('Unable to access payment gateway');

                    return;
                }

                if (token.error) {
                    setCardError(token.error.message || 'Unable to process card');

                    return;
                }

                console.log('token', token);

                const payload: IPaymentCard = {
                    method,
                    number: token.token.id,
                };

                mutate(payload);
            }
        } else if (method === EPaymentMethod.ACH) {
            const {routingNumber, number} = values as PaymentACHInput; // 000123456789  test ACH, routing number 110000000
            const payload = {
                routingNumber,
                method,
                number
            } as unknown as IPaymentMethod;

            mutate(payload);
        } else if (method === EPaymentMethod.SEPA) {
            const {number} = values as PaymentACHInput; // DE89370400440532013000 test SEPA
            const payload = {
                method,
                number
            } as unknown as IPaymentMethod;

            mutate(payload);
        }
    };

    const resetForm = useCallback(() => {
        if (isFetchedBillingAddress && billingAddress) {
            const values = {...billingAddress};

            if (billingAddress?.country) {
                const country = optionsCountry?.find(item => item.value === billingAddress.country);    // FIXME BE do not support CountryCode but should!!!

                if (country) {
                    (values as PaymentInput).country = country;
                }
            }

            reset && reset(values);
        }
    }, [billingAddress, isFetchedBillingAddress, optionsCountry, reset]);

    const handleMethodChange = useCallback((method: EPaymentMethod) => {
        resetForm();
        setMethod(method);
    }, [resetForm]);

    // reset Form by close
    useEffect(() => {
        if (!open) {
            reset && reset({});
        }
    }, [open, reset]);

    // load Billing Address and populate a form
    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <DialogEmoji
            actions={(
                <>
                    <BlackBorderButton
                        sx={{
                            width: mdDown ? '50% !important' : 'auto !important'
                        }}
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </BlackBorderButton>
                    <ContinueButton
                        disabled={isLoading}
                        sx={{
                            width: mdDown ? '50% !important' : 'auto !important',
                            'div': {
                                justifyContent: 'center'
                            }
                        }}
                        variant="contained"
                        onClick={handleSubmit ? handleSubmit(onSubmitHandler) : undefined}
                    >
                        Add payment method
                    </ContinueButton>
                </>
            )}
            maxWidth="lg"
            maxWidthPx={764}
            open={open}
            title={(
                <>
                    <Box justifyContent="center">
                        <img
                            src="/assets/images/bank-card-icon.png"
                            width="48"
                            height="48"
                            alt="bank card icon"
                        />
                    </Box>
                    Add new payment method
                </>
            )}
            onClose={() => onClose(false)}
        >

            <SelectPaymentMethod selected={method} onChange={handleMethodChange}/>

            <FormProvider {...methods}>
                <form
                    noValidate
                    autoComplete="off"
                >
                    {method === EPaymentMethod.ACH && <PaymentACHForm optionsCountry={optionsCountry}/>}
                    {method === EPaymentMethod.CreditCard && <Elements stripe={stripePromise}>
                        <PaymentCardForm optionsCountry={optionsCountry} setCardElement={setCardElement} cardError={cardError}/>
                    </Elements>}
                    {method === EPaymentMethod.SEPA && <PaymentSEPAForm optionsCountry={optionsCountry}/>}

                </form>
            </FormProvider>

            <Box sx={{mb: 4}}/>

        </DialogEmoji>
    );
};

export default React.memo(DialogPaymentMethod);
