import React, {FC, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {DescriptionIcon} from '../../../../../assets/icons';
import {ContinueButton, EngagementActionsFrelancer, ImagePlaceholder, Pill, Portal, RoleStatusPill, TabContentWrapper} from '../../../../../components';
import {LG, RouteFreelancerPortal} from '../../../../../constants';
import {EEngagementStatus, IEngagementFreelancer} from '../../../../../models';
import theme from '../../../../../theme';
import {formatDate} from '../../../../../utils';
import {EDisplayMode} from '../../MyWorkDetailsPage';

import {
    CloseEngagmentBtn,
    CloseRequestBox,
    CloseRequestIncomingBox,
    CloseRequestRejectedBox,
    EngagementOnHold,
    InfoBox,
    PauseEngagementBtn,
    PauseIncoming,
    PauseRequestPending,
    PauseRequestRejected,
    ResumeCanceled,
    Resumed,
    ResumeEngagement,
    ResumeEngagementPending,
    ResumeRequest,
    ResumeRequestRejected,
    TrackYourTime
} from './components';

const Line = styled('div')`
  margin: 0 auto;
  width: 64px;
  height: 4px;
  border-radius: 50px;
  background-color: ${theme.palette.lightGray.main};

  @media (min-width: ${LG}) {
    display: none;
  }
`;

interface IOverviewProps {
    data: IEngagementFreelancer;
}

const TabOverview: FC<IOverviewProps> = ({data}) => {
    const navigate = useNavigate();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const handleMessageClick = useCallback(() => {
        navigate(`${RouteFreelancerPortal.myWorkDetails}/${data.id}/${EDisplayMode.Messages}`);
    }, [data.id, navigate]);

    const handleTimesheetClick = useCallback(() => {
        navigate(`${RouteFreelancerPortal.myWorkDetails}/${data.id}/${EDisplayMode.Timesheets}`);
    }, [data.id, navigate]);

    const renderBoxes = useMemo(() => {
        return (
            <>
                {/* Close requested by Client and need Confirm or Reject */}
                <CloseRequestIncomingBox data={data}/>

                {/* Close requested rejected by Client */}
                <CloseRequestRejectedBox data={data} onMessage={handleMessageClick}/>

                {/* You create Close request and need Confirm or Reject by Client */}
                <CloseRequestBox data={data}/>

                {/* Pause will start in the future */}
                <PauseIncoming data={data}/>

                {/* Pause requested by Freelancer and need Confirm or Reject by the Client */}
                <PauseRequestPending data={data}/>

                {/* Pause rejected by the Client */}
                <PauseRequestRejected data={data} onMessage={handleMessageClick}/>

                {/* Your Resume request rejected by Client */}
                <ResumeRequestRejected data={data} onMessage={handleMessageClick}/>

                {/* Initiated by the Client no actions from you */}
                <EngagementOnHold data={data}/>

                {/* Client send new Proposal to you */}
                <ResumeRequest data={data}/>

                {/* It is you pause Initiator. You can send new Resume request with new Proposal. */}
                <ResumeEngagement data={data}/>

                {/* Resume request by you - waiting for the Client Accept/Reject new Proposal */}
                <ResumeEngagementPending data={data}/>

                {/* Both side approve resume and waiting for start date */}
                <Resumed data={data}/>

                {/* future Resume canceled and Initiator can create new Resume */}
                <ResumeCanceled data={data} onMessage={handleMessageClick}/>

                <TrackYourTime
                    data={data}
                    onTimesheet={handleTimesheetClick}
                />
            </>
        );
    }, [data, handleMessageClick, handleTimesheetClick]);

    return (
        <TabContentWrapper>
            <Box
                sx={{
                    [theme.breakpoints.up('lg')]: {
                        flexGrow: 1,
                        p: '24px 24px 64px',
                        borderRadius: '24px',
                        backgroundColor: theme.palette.white.main
                    }
                }}
            >
                <Line/>

                <Box
                    sx={{

                        [theme.breakpoints.up('lg')]: {
                            mb: '40px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            m: '40px auto',
                            width: 'max-content',
                            [theme.breakpoints.up('lg')]: {
                                m: 0,
                                ml: 'auto'
                            }
                        }}
                    >
                        <Pill variant="gray">ID: {data.id}</Pill>
                        <RoleStatusPill
                            status={data.status}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px',
                            mb: '16px',
                            [theme.breakpoints.up('md')]: {
                                gap: '24px',
                                mb: '32px'
                            },
                            'img': {
                                width: '40px',
                                height: '40px',
                                objectFit: 'contain',
                                objectPosition: 'center',
                                aspectRatio: '1/1',
                                border: `1px solid ${theme.palette.lightGray.main}`,
                                borderRadius: '50%',
                                [theme.breakpoints.up('md')]: {
                                    width: '80px',
                                    height: '80px',
                                }
                            },
                        }}
                    >
                        {data.clientCompanyLogo ? (
                            <img src={data.clientCompanyLogo} width={mdUp ? '80' : '40'} height={mdUp ? '80' : '40'}
                                alt="logo"/>
                        ) : (
                            <ImagePlaceholder width={mdUp ? '80' : '40'} height={mdUp ? '80' : '40'}/>
                        )}

                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: 1.5,
                            }}
                        >
                            {data.clientCompanyName}
                        </Typography>
                    </Box>

                    <Typography
                        sx={{
                            m: '0 auto 8px',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: 1.5,
                            textAlign: 'center',
                            maxWidth: '920px',
                            [theme.breakpoints.up('md')]: {
                                fontSize: '24px',
                            }
                        }}
                    >
                        {data.name}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: 1.5,
                                color: theme.palette.gray.main,
                                textAlign: 'center',
                            }}
                        >
                            Start date: {formatDate(data.startDate)}
                        </Typography>
                        {data.status === EEngagementStatus.CLOSED && (
                            <Typography
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    lineHeight: 1.5,
                                    color: theme.palette.gray.main,
                                    textAlign: 'center',
                                    ml: '8px',
                                    '&::before': {
                                        content: '""',
                                        width: '4px',
                                        height: '4px',
                                        mr: '8px',
                                        backgroundColor: 'currentColor',
                                        borderRadius: '50%'
                                    }
                                }}
                            >
                                End date: {data.endDate ? formatDate(data.endDate) : 'n/a'}
                            </Typography>
                        )}
                    </Box>

                </Box>

                {
                    !lgUp && (
                        <>
                            <InfoBox
                                data={data}
                                isCompany={false}
                                onMessage={handleMessageClick}
                            />

                            <Box
                                sx={{
                                    mb: '40px'
                                }}
                            >

                                {renderBoxes}

                            </Box>

                        </>
                    )
                }

                <Box
                    sx={{
                        [theme.breakpoints.up('lg')]: {
                            border: `1px solid ${theme.palette.lightGray.main}`,
                            borderRadius: '24px',
                            padding: '40px 24px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            mb: '24px',
                        }}
                    >
                        <DescriptionIcon/>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: 1.5
                            }}
                        >
                            Role description
                        </Typography>
                    </Box>

                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: 1.5,
                            whiteSpace: 'pre-wrap',
                            [theme.breakpoints.up('lg')]: {
                                fontSize: '16px'
                            }
                        }}
                    >
                        {data.description}
                    </Typography>
                </Box>
            </Box>

            {
                !lgUp && (
                    <>
                        <CloseEngagmentBtn sx={{marginBottom: '160px'}} data={data}/>
                        <Portal
                            fullWidth
                            order={1}
                            visibleUntil={LG}
                        >
                            <Box
                                sx={{
                                    m: '-40px -20px',
                                    p: '16px 20px 48px',
                                    backgroundColor: theme.palette.white.main,
                                    borderTop: `1px solid ${theme.palette.lightGray.main}`,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    rowGap: '16px',
                                    columnGap: '8px',
                                }}
                            >
                                <PauseEngagementBtn
                                    data={data}
                                    sx={{
                                        width: '100%',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        'svg': {
                                            width: '16px',
                                            height: '16px'
                                        },
                                        'path': {
                                            fill: 'currentColor'
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            width: 'calc(33% - 3px) !important'
                                        }
                                    }}
                                />

                                <EngagementActionsFrelancer.ResumeEngagementBtn
                                    data={data}
                                    sx={{
                                        width: 'calc(50% - 4px) !important',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        'svg': {
                                            width: '16px',
                                            height: '16px'
                                        },
                                        'path': {
                                            fill: 'currentColor'
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            width: 'calc(33% - 3px) !important'
                                        }
                                    }}
                                />

                                {data.status !== EEngagementStatus.CLOSED && (
                                    <ContinueButton
                                        size="small"
                                        sx={{
                                            width: 'calc(50% - 4px) !important',
                                            'div': {
                                                justifyContent: 'center'
                                            },
                                            [theme.breakpoints.up('md')]: {
                                                width: 'calc(33% - 3px) !important'
                                            }
                                        }}
                                        variant="contained"
                                        onClick={handleMessageClick}
                                    >
                                        Message
                                    </ContinueButton>
                                )}

                            </Box>
                        </Portal>
                    </>
                )
            }


            {
                lgUp && (
                    <Box
                        sx={{
                            width: '454px',   // without Bio looks not good
                            flexShrink: 0
                        }}
                    >
                        <InfoBox
                            data={data}
                            isCompany={false}
                            onMessage={handleMessageClick}
                        />

                        <CloseEngagmentBtn data={data}/>

                        {renderBoxes}

                    </Box>
                )
            }
        </TabContentWrapper>
    );
};

export default React.memo(TabOverview);
