import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import {RouteAuth} from '../../../constants';
import {useAuth} from '../../../hooks';
import {ERole} from '../../../models';


const RequireUser = ({allowedRoles}: { allowedRoles: ERole[] }) => {
    const {user, findRole} = useAuth();
    const location = useLocation();

    const hasRole = findRole(allowedRoles);

    if (!user || !hasRole) {
        return <Navigate to={RouteAuth.login} state={{from: location}} replace/>;
    }

    // customer don't want to have this kind of page - simple relogin
    // if (!hasRole) {
    //     return <Navigate to={RouteAuth.unauthorized} state={{from: location}} replace />;
    // }

    return <Outlet/>;
};

export default RequireUser;
