import React from 'react';

import {Grid, Grow} from '@mui/material';

import theme from '../../theme';
import {BusinessModelItem} from '../index';

interface IBusinessModelsListProps {
    align?: 'center' | 'flex-end' | 'flex-start';
    items: string[];
    selectedIds: string[];
    onClick: (item: string) => void;
}

const BusinessModelsList: React.FC<IBusinessModelsListProps> = ({align = 'center', items, selectedIds, onClick}): JSX.Element => {
    const isSelected = (model: string) => selectedIds?.includes(model);

    return (
        <Grid
            container
            sx={{
                gap: '7px',
                [theme.breakpoints.up('lg')]: {
                    justifyContent: align
                }
            }}
        >
            {
                items?.map((item, index) => (
                    <Grow
                        in={true}
                        key={item}
                        timeout={350 * index}
                    >
                        <Grid item>
                            <BusinessModelItem
                                model={item}
                                selected={isSelected(item)}
                                onClick={() => onClick(item)}
                            />
                        </Grid>
                    </Grow>
                ))
            }
        </Grid>
    );
};

export default React.memo(BusinessModelsList);
