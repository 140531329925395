import React, {KeyboardEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import {init, SearchIndex} from 'emoji-mart';
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {TypeOf} from 'zod';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {zodResolver} from '@hookform/resolvers/zod';
import {Box, IconButton, Input, Popover} from '@mui/material';

import {AttachIcon, PaperPlaneIcon, SmileyFaceIcon} from '../../../../assets/icons';
import {ACCEPTED_FILES_TYPES} from '../../../../constants';
import {useAuth, useConversation} from '../../../../hooks';
import theme from '../../../../theme';
import {getCaretPosition} from '../../../../utils';
import {showToastError} from '../../../ToastError/toast.service';
import {ACTIVE_STATUSES} from '../../InboxPage';

import DragDropArea from './DragDropArea';
import FilesList from './FilesList';
import {iconBtnStyles, stylesEmojiPopover, stylesInput, Wrp} from './styles';
import {ACCEPTED_TYPES, messageSchema} from './validate';

// You can search without the Picker. Just like the emoji component, data needs to be initialized first in order to use the search index.
init({data});

type MessageInput = TypeOf<typeof messageSchema>;


const DEFAULT_VALUES = {
    files: [],
    message: ''
};

interface IProps {
    conversationId: string;
}

const ChatBottom: React.FC<IProps> = ({conversationId}) => {
    const {isImpersonal} = useAuth();
    const [anchorEmojiEl, setAnchorEmojiEl] = React.useState<HTMLButtonElement | null>(null);
    const {attributes, meta, messages, sendFiles, sendMessage} = useConversation(conversationId);
    const [suggestions, setSuggestions] = useState<{items: Array<{ native: string }>; position: {x: number; y: number}} | null>();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const wrapperRef = useRef<HTMLInputElement | null>(null);

    // If Freelancer initiates a conversation, he could only send one message
    const isDisabled = meta?.isInitByFreelancer && messages.length == 1;

    const methods = useForm<MessageInput>({
        resolver: zodResolver(messageSchema),
        defaultValues: DEFAULT_VALUES
    });

    const {
        formState: {isDirty, errors},
        handleSubmit,
        getValues,
        reset,
        register,
        setValue,
        watch,
    } = methods;

    const files = watch('files', []);

    const onDrop = useCallback(
        (droppedFiles: File[]) => {
            const newFiles = [...files, ...droppedFiles].reduce((prev: File[], file) => {
                const fo = Object.entries(file);

                if (
                    prev.find((e: File) => {
                        const eo = Object.entries(e);

                        return eo.every(
                            ([key, value], index) =>
                                key === fo[index][0] && value === fo[index][1]
                        );
                    })
                ) {
                    return prev;
                } else {
                    return [...prev, file];
                }
            }, []);

            setValue('files', newFiles, {shouldDirty: true, shouldValidate: true});
        },
        [files, setValue]
    );

    const onDropRejected = useCallback((fileRejections: FileRejection[], event: DropEvent) => {
        setValue('files', fileRejections.map(item => item.file), {shouldDirty: true, shouldValidate: true});
    }, [setValue]);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        accept: ACCEPTED_FILES_TYPES
    });

    const addMessage = () => {
        const values = getValues();
        const message = values.message.trim();
        const files = values.files;

        if (files.length) {
            sendFiles(files);
        }

        if (message) {
            sendMessage(message);
        }

        reset(DEFAULT_VALUES);
    };

    const placeholder = useMemo(() => {
        const name = meta?.remoteParticipant?.name?.split(' ')[0] || 'Client';

        if (isDisabled) {
            return `Conversation would be unblocked after ${name} answer you`;
        }

        return `Message ${name}...`;
    }, [isDisabled, meta]);

    const handleEmojiSelect = (emojiData: { native: string }, event: SyntheticEvent | null, isAutoReplace?: boolean) => {
        console.log(emojiData);
        if (!inputRef.current) return;

        const {selectionStart, selectionEnd} = inputRef.current;
        const caretPos = (selectionStart || 0) + emojiData.native.length;
        const value = inputRef.current?.value || '';
        let newValue = value.slice(0, selectionStart || 0) + emojiData.native + value.slice(selectionEnd || 0);

        // user type 'hi' + space we show suggestions
        // and user select emoji from the tooltip
        // then we should remove 'hi'
        if (isAutoReplace) {
            const indexes = [];

            for (let i = 0; i < newValue.length; i++) {
                if (newValue[i] === ' ') indexes.push(i);
            }

            const {length, [length - 1]: last, [length - 2]: start} = indexes;

            newValue = newValue.substring(0, start || 0) + newValue.substring(last, newValue.length) + ' ';
        }

        setValue('message', newValue, {shouldDirty: true, shouldValidate: true});

        // when new value will be assign we need to set right caret position,
        // because input will lose and new emojis will be added at the begining of the string
        setTimeout(() => {
            inputRef.current && inputRef.current.setSelectionRange(caretPos, caretPos);
        }, 25);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        setSuggestions(null);

        if (event.code === 'Enter') {
            // if CTRL - add new line
            if (event.ctrlKey) {
                const el = event.target as HTMLTextAreaElement;
                const position = el.selectionEnd;

                el.value = el.value.substring(0, position) + '\n' + el.value.substring(position);
                el.selectionEnd = position + 1;
                el.scrollTop = el.scrollHeight;
                // if (el.rows < 3) {
                //     el.rows++;
                // }

                // if only Ennter then send a message
            } else {
                handleSubmit(onSubmitHandler)(); // validate and then submit
            }
            event.preventDefault();

            // Emoji should be available from the list or by shortcut e.g.   :) -> 😀
        }
        else if (event.code === 'Space') {
            const target = event.target as HTMLTextAreaElement;
            const {length, [length - 1]: last} = target.value.split(' ');
            const searchEmoji = async (value: string) => {
                const emojis = await SearchIndex.search(value);
                const results = emojis?.map((emoji: any) => emoji.skins[0]);

                if (!inputRef.current || !wrapperRef.current || !results?.length) {
                    return;
                }
                const position = getCaretPosition(inputRef.current, wrapperRef.current);

                if (position) {
                    setSuggestions({items: results.slice(0, 15), position});
                }
            };

            searchEmoji(last);
        }
    };

    const handleRemoveFile = useCallback((file: File) => {
        const newFiles = files.filter((it: File) => it !== file);

        setValue('files', newFiles, {shouldDirty: true, shouldValidate: true});
    }, [files, setValue]);

    const onSubmitHandler: SubmitHandler<MessageInput> = (values) => {
        addMessage();
    };

    useEffect(() => {
        const error = Object.values(errors)?.[0];

        if (error) {
            showToastError(error.message as any);
        }
    }, [errors]);

    // this is read-only conversation
    if (!ACTIVE_STATUSES.includes(attributes.status) || isImpersonal) {
        return null;
    }

    return (
        <>
            <Wrp className={clsx({disabled: isDisabled})} ref={wrapperRef}>

                <Box {...getRootProps()}>
                    <input
                        accept={ACCEPTED_TYPES}
                        id="files"
                        name="files"
                        {...getInputProps()}
                    />
                    <IconButton
                        disabled={isDisabled}
                        sx={iconBtnStyles}
                    >
                        <AttachIcon/>
                    </IconButton>
                </Box>

                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                    >
                        {files?.length ? (
                            <FilesList items={files} onRemove={handleRemoveFile}/>
                        ) : (
                            <Input
                                {...register('message')}
                                disabled={isDisabled}
                                inputRef={inputRef}
                                maxRows={9}
                                multiline
                                placeholder={placeholder}
                                sx={stylesInput}
                                // variant="filled" // todo: check screen width and put "filled" for mobile screens
                                onKeyDown={handleKeyDown}
                            />
                        )}

                    </form>
                </FormProvider>

                <IconButton
                    aria-describedby="emoji-popover"
                    disabled={isDisabled}
                    sx={iconBtnStyles}
                    onClick={(event) => setAnchorEmojiEl(event.currentTarget.parentElement as HTMLButtonElement)}
                >
                    <SmileyFaceIcon/>
                </IconButton>

                <IconButton
                    disabled={isDisabled}
                    sx={{
                        ...iconBtnStyles,
                        'svg path': {
                            fill: isDirty ? theme.palette.blue.dark : theme.palette.textGray.dark
                        }
                    }}
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    <PaperPlaneIcon/>
                </IconButton>

                {/* Emoji picker */}
                <Popover
                    anchorEl={anchorEmojiEl}
                    id="emoji-popover"
                    open={Boolean(anchorEmojiEl)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    sx={stylesEmojiPopover}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={() => setAnchorEmojiEl(null)}
                >
                    <Picker
                        data={data}
                        exceptEmojis={['relaxed']}
                        set="native"
                        onEmojiSelect={handleEmojiSelect}
                    />
                </Popover>

                {/* When user type Smile - search emoji and show suggestions at the list */}
                <Box
                    id="tooltip-emoji"
                    top={suggestions?.position.y}
                    left={suggestions?.position.x}
                    sx={{
                        display: suggestions ? 'flex' : 'none',
                        position: 'absolute',
                        background: theme.palette.lightGray.light,
                        p: 1,
                        zIndex: 1
                    }}
                >
                    {suggestions?.items.map((it, index) => (
                        <Box
                            component="span"
                            key={index}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                            onClick={(e) => handleEmojiSelect(it, null, true)}
                        >
                            {it.native}
                        </Box>
                    ))}
                </Box>
            </Wrp>

            <DragDropArea
                onDrop={onDrop}
                onDropRejected={onDropRejected}
            />
        </>
    );
};

export default ChatBottom;
