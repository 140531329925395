import React, {FC} from 'react';

import RestoreIcon from '@mui/icons-material/Restore';
import UpdateIcon from '@mui/icons-material/Update';
import {Box, MenuItem, Select, Typography} from '@mui/material';

import {EEngagementSort} from '../../models';
import theme from '../../theme';

const stylesMenuItem = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    'svg': {
        width: '24px',
        height: '24px'
    },
    'path': {
        fill: 'currentColor'
    }
};

interface ISortProps {
    value: string;
    onChange: (value: string) => void;
}

const SelectSortBy: FC<ISortProps> = ({value, onChange}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                p: '8px',
                ml: '24px !important',
                backgroundColor: theme.palette.lightGray.main,
                borderRadius: '8px',
                '.MuiInputBase-root': {
                    '&::before, &::after': {
                        display: 'none'
                    }
                },
                '.MuiSelect-select': {
                    p: '0',
                    pr: '24px',
                    'svg': {
                        display: 'none'
                    },
                    '&:focus': {
                        backgroundColor: 'unset'
                    }
                }
            }}
        >
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: theme.palette.gray.main
                }}
            >
                Sort by:
            </Typography>
            <Select
                sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: theme.palette.black.main,
                    '.MuiSvgIcon-root': {
                        color: 'currentColor'
                    }
                }}
                value={value || ''}
                variant="standard"
                onChange={({target: {value}}) => onChange(value)}
            >
                <MenuItem
                    sx={stylesMenuItem}
                    value={EEngagementSort.DESC}
                >
                    <UpdateIcon/>Start date Newest
                </MenuItem>
                <MenuItem
                    sx={stylesMenuItem}
                    value={EEngagementSort.ASC}
                >
                    <RestoreIcon/>Start date Oldest
                </MenuItem>
            </Select>
        </Box>
    );
};

export default React.memo(SelectSortBy);
