import React from 'react';

import {metaService} from '../../../../api';
import {AnimateHeight, AutocompleteAsync, ChipsList, InputCaption} from '../../../../components';
import {EModerationStatus, IBrand} from '../../../../models';

interface OptionType extends IBrand {
    inputValue?: string;
    logo?: string;
    moderationStatus?: EModerationStatus;
}

interface IProps {
    placeholder: string;
    selected: OptionType[];
    onChange: (newValue: OptionType | null) => void;
    onDelete: (item: OptionType) => void;
}

const Brands: React.FC<IProps> = ({placeholder, selected, onChange, onDelete}) => {
    return (
        <>
            <InputCaption>
                Now, list any well-known <span>brands or start-ups</span> you have done work for
            </InputCaption>

            <AutocompleteAsync
                apiMethod={metaService.searchBrands}
                freeSolo
                id="free-solo-brands"
                isLogo={true}
                placeholder={placeholder}
                value={selected}
                onChange={onChange}
            />

            <AnimateHeight isVisible={true}>
                <ChipsList avatarProperty="logo" items={selected} onDelete={onDelete}/>
            </AnimateHeight>

        </>
    );
};

export default React.memo(Brands);
