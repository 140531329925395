import React, {useEffect, useLayoutEffect, useMemo} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm, useWatch} from 'react-hook-form';
import {useLocation} from 'react-router-dom';
import {any, object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../api';
import {ACCEPTED_FILES_TYPES, MAX_FILE_SIZE, MAX_FILE_SIZE_MB} from '../../constants';
import {useAuth} from '../../hooks';
import {EMailType} from '../../models';
import theme from '../../theme';
import {emailSchema, getAcceptedExtentions, getFieldErrors, getFileExtansion} from '../../utils';
import {ContinueButton, Input, InputCaption, InputFile, Select, showToastError, showToastSuccess} from '../';

import {subjects} from './subjects.model';

const inputStyles = {
    mb: '0 !important'
};

const labelStyles = {
    textAlign: 'left !important',
    fontSize: '14px !important'
};

const ACCEPTED_EXTENSIONS = getAcceptedExtentions(ACCEPTED_FILES_TYPES);

const isFileSizeValid = (file: File) => file.size <= MAX_FILE_SIZE_MB;
const isFileTypeValid = (file: File) => {
    const ext = '.' + getFileExtansion(file.name.toLowerCase());

    return ACCEPTED_EXTENSIONS.includes(ext || '');
};

const contactSchema = object({
    ...emailSchema,
    name: string()
        .trim()
        .min(1, 'Name is required')
        .max(100, 'Name is too long')
        .regex(/[ a-zA-Z]+/gi, 'Name can contain only latin characters'),
    subject: string()
        .min(1, 'Subject is required'),
    text: string()
        .trim()
        .min(1, 'Message is required')
        .max(1000, 'Message is too long'),
    attachments: any()
        .refine(
            (files: File[]) => files?.length ? !files.find(f => !isFileSizeValid(f)) : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        )
        .refine(
            (files: File[]) => files?.length ? files.reduce((total, it) => total += it.size, 0) < MAX_FILE_SIZE_MB : true,
            `Max all files size is ${MAX_FILE_SIZE}MB.`
        )
        .refine(
            (files: File[]) => files?.length ? !files.find(f => !isFileTypeValid(f)) : true,
            '.png, .jpg, .jpeg, .svg, .doc, .docx, .xsl, .xslx and .pdf files are accepted.'
        ),
});

type ContactInput = TypeOf<typeof contactSchema>;

const ContactUsPage = () => {
    const {isClient, user} = useAuth();
    const location = useLocation();

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const methods = useForm<ContactInput>({
        resolver: zodResolver(contactSchema),
    });

    const {
        control,
        formState: {errors, isDirty},
        // clearErrors,
        handleSubmit,
        setValue,
        reset,
        setError,
        // trigger,
    } = methods;

    const {text} = useWatch({control});
    const {subject} = useWatch({control});

    const {mutate, isLoading: isSubmitting} = useMutation(
        (isClient ? clientService : freelancerService).contactUs,
        {
            onSuccess() {
                showToastSuccess('Message has been sent successfully!');
            },
            onError(error: AxiosError) {
                const errors = getFieldErrors(error);

                if (errors) {
                    errors.forEach(error => setError(error.field as keyof ContactInput, {type: 'custom', message: error.message}));
                } else {
                    showToastError(error);
                }
            },
        }
    );

    const onSubmitHandler: SubmitHandler<ContactInput> = (values) => {
        console.log('submit', values);

        mutate(values);
    };

    const options = useMemo(() => {
        const onlyClient = [EMailType.PAYMENT_METHOD, EMailType.PAYMENT_REFUND_REQUEST];

        return isClient ? subjects : subjects.filter(it => !it.value || !onlyClient.includes(it.value));
    }, [isClient]);

    // Wrong screen position when opening freelancer detailed profile second time
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const subject = location.state?.data?.type;
        const {email, name} = user || {};
        const values = {
            email: '',
            name: '',
            subject: '',
        };

        if (email && name) {
            values.email = email;
            values.name = name;
        }

        if (subject) {
            values.subject = subject;
        }

        reset(values);
    }, [location.state, reset, user]);

    return (
        <>
            <Box
                sx={{
                    maxWidth: '766px',
                    m: '40px auto 24px',
                    textAlign: 'center',
                    [theme.breakpoints.up('md')]: {
                        mt: '64px',
                        mb: '64px'
                    },
                    'img': {
                        width: '48px',
                        height: '48px',
                        [theme.breakpoints.up('md')]: {
                            width: '64px',
                            height: '64px'
                        }
                    }
                }}
            >
                <img src="/assets/images/message-icon.png" width="64" height="64" alt="message icon"/>
                <Typography
                    sx={{
                        mt: '24px',
                        mb: '8px',
                        fontWeight: 600,
                        fontSize: '16px !important',
                        [theme.breakpoints.up('md')]: {
                            fontSize: '24px !important'
                        }
                    }}
                    variant="h1"
                >
                    Contact us
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        color: theme.palette.gray.main
                    }}
                >
                    Can’t find the answer you’re looking for? Please send our friendly team a message
                </Typography>
            </Box>

            <Box
                sx={{
                    p: '40px 24px',
                    maxWidth: '800px',
                    m: '0 auto',
                    backgroundColor: theme.palette.white.main,
                    borderRadius: '16px',
                    [theme.breakpoints.up('md')]: {
                        p: '64px'
                    }
                }}
            >
                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitHandler)}
                    >
                        <Grid container spacing={mdUp ? '48px' : '40px'}>
                            <Grid item xs={12} md={6}>
                                <InputCaption sx={labelStyles}>
                                    Name
                                </InputCaption>
                                <Input
                                    disabled={isSubmitting}
                                    name="name"
                                    sx={inputStyles}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputCaption sx={labelStyles}>
                                    Business email
                                </InputCaption>
                                <Input
                                    disabled={isSubmitting}
                                    name="email"
                                    type="email"
                                    sx={inputStyles}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    disabled={isSubmitting}
                                    error={!!errors.subject}
                                    helperText={errors.subject?.message}
                                    fullWidth
                                    label="I need help with..."
                                    labelStyles={{
                                        mb: '24px !important',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: 1.5,
                                        'span': {
                                            font: 'inherit',
                                            lineHeight: 'inherit',
                                            color: theme.palette.purple.main
                                        }
                                    }}
                                    listItems={options as Array<{ value: string; text: string } | {groupName: string}>}
                                    value={subject || ''}
                                    variant="standard"
                                    sx={{
                                        '&': {
                                            m: 0 + '!important',
                                        },
                                        '.MuiFormLabel-root': {
                                            display: 'none'
                                        },
                                        '.MuiInput-root::before': {
                                            borderWidth: '2px',
                                            borderColor: theme.palette.lightGray.main
                                        },
                                        '.MuiSelect-select:focus': {
                                            backgroundColor: 'unset'
                                        }
                                    }}
                                    onChange={event => setValue('subject', event.target.value as string, {shouldDirty: true, shouldTouch: true, shouldValidate: true})}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputCaption sx={labelStyles}>
                                    Leave a message
                                </InputCaption>
                                <Input
                                    autoFocus
                                    counter={1000 - (text?.length || 0)}
                                    disabled={isSubmitting}
                                    inputProps={{maxLength: 1000}}
                                    minRows={8}
                                    multiline
                                    name="text"
                                    placeholder="Enter message"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputCaption sx={labelStyles}>
                                    Attach files
                                </InputCaption>
                                <InputFile
                                    accept={ACCEPTED_FILES_TYPES}
                                    disabled={isSubmitting}
                                    isPreviewThumbs={false}
                                    placeholder={<>Drag your files here or click to browse <span>PDF, JPEG, PNG, DOCX, EXCEL files with max size of 5 MB</span></>}
                                    name="attachments"
                                    mode="update"
                                    multiple={true}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <ContinueButton
                                        disabled={!isDirty}
                                        sx={{
                                            width: '100% !important',
                                            'div': {
                                                justifyContent: 'center !important',
                                            },
                                            [theme.breakpoints.up('md')]: {
                                                width: 'auto !important'
                                            }
                                        }}
                                        type="submit"
                                        variant="contained"
                                    >
                                    Send message
                                    </ContinueButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Box>
        </>
    );
};

export default React.memo(ContactUsPage);
