import React from 'react';

import {styled, Tooltip as MUITooltip, tooltipClasses, TooltipProps} from '@mui/material';


const TooltipWrapper = styled(({className, ...props}: TooltipProps) => (
    <MUITooltip {...props} classes={{popper: className}} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: '12px',
        // maxWidth: 'none',
    },
});

const Tooltip: React.FC<TooltipProps> = ({children, ...props}) => {
    return (
        <TooltipWrapper
            {...props}
            arrow
            sx={{
                [`& .${tooltipClasses.tooltip}`]: {
                    maxWidth: 'none',
                }
            }}
        >
            {children}
        </TooltipWrapper>
    );
};

export default React.memo(Tooltip);
