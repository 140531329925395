import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';

import {freelancerService} from '../../../../../api';
import {CaseStudyContextProvider, CaseStudyEdit, LoadingMask, useCaseStudy} from '../../../../../components';
import {CASE_STUDIES, FREELANCER_PROFILE, RouteFreelancerPortal} from '../../../../../constants';

const CaseStudyWithContext = () => {
    const {setData, setCancelUrl} = useCaseStudy();
    const {caseStudyId} = useParams();

    const {
        isLoading,
        data,
    } = useQuery([FREELANCER_PROFILE], freelancerService.getProfile, {
        staleTime: 60 * 1000,
        select: ((data) => data)
    });

    useEffect(() => {
        setCancelUrl(`${RouteFreelancerPortal.profileSettings}/${CASE_STUDIES}`);
    }, [setCancelUrl]);

    useEffect(() => {
        const caseStudy = data?.caseStudies.find(it => it.id === Number(caseStudyId));

        if (caseStudy) {
            setData(caseStudy);
        }
    }, [caseStudyId, data, setData]);

    return (
        <>
            {isLoading ? <LoadingMask/> : <CaseStudyEdit/>}
        </>
    );
};

const CaseStudyEditPage = () => {
    return (
        <CaseStudyContextProvider>
            <CaseStudyWithContext/>
        </CaseStudyContextProvider>
    );
};

export default CaseStudyEditPage;
