// This Autocomplete for showing single value inside the textfield
// If you need multi select and showing items under textfield
// then please use <Autocomplete /> + <ChipsList />

import React, {useCallback} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Autocomplete as MUIAutocomplete, AutocompleteRenderOptionState, Box, FormHelperText, SxProps, TextField} from '@mui/material';

import {CheckboxIcon} from '../../assets/icons';
import {ISelectOption} from '../../models';
import theme from '../../theme';
import {AnimateHeight} from '..';

interface Props {
    fullWidth?: boolean;
    helperText?: string;
    isOptionEqualToValue?: (option: ISelectOption, value: ISelectOption) => boolean;
    label?: string | React.ReactNode;
    name: string;
    options: ISelectOption[];
    placeholder?: string;
    renderInput?: React.ReactNode;
    variant?: 'outlined' | 'filled' | 'standard' | undefined;
    sx?: SxProps;
    onChange?: (v: any) => void;
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode;
}

const Autocomplete = ({helperText, isOptionEqualToValue, label, name = '', options, sx, variant, ...props}: Props): JSX.Element => {
    const [inputValue, setInputValue] = React.useState('');

    const {
        control,
        formState: {errors},
        setValue
    } = useFormContext();

    const {field: {value: item}} = useController({control, name});

    const renderOption = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: ISelectOption) => (
        <Box
            {...props}
            component="li"
            sx={{
                '& > span': {flexGrow: 1},
                color: item?.value === option.value ? 'primary.main' : 'default',
                display: 'flex',
                width: '100%'
            }}
        >
            <span>{option.text}</span>
            {item?.value === option.value ? <CheckboxIcon/> : null}
        </Box>

    ), [item]);

    return (
        <Controller
            control={control}
            defaultValue={''}
            name={name}
            render={({field: {ref, ...field}}) => (
                <MUIAutocomplete
                    {...props}
                    {...field}
                    id={name + '-id'}
                    inputValue={inputValue}
                    isOptionEqualToValue={isOptionEqualToValue}
                    options={options}
                    getOptionLabel={option => option.text || ''}
                    filterSelectedOptions
                    renderOption={props.renderOption || renderOption}
                    renderInput={params => (
                        <>
                            <TextField
                                {...params}
                                error={!!errors[name]}
                                // helperText={errors[name]?.message as string}
                                inputRef={ref}
                                label={label}
                                placeholder={props.placeholder}
                                variant={variant || 'standard'}
                            />
                            {/* Show/Hide validation errors with animation */}
                            <AnimateHeight isVisible={!!(errors[name]?.message || helperText)}>
                                <FormHelperText id={`helper-text-${name}`} sx={{color: errors[name] ? theme.palette.error.main : ''}}>
                                    {errors[name]?.message?.toString() || helperText}
                                </FormHelperText>
                            </AnimateHeight>
                        </>
                    )}
                    sx={{
                        '.MuiInput-root': {
                            '&::before': {
                                borderBottom: '2px solid',
                                borderBottomColor: theme.palette.inputDisabledBorderColor.main
                            },
                            '&:hover': {
                                '&::before': {
                                    borderBottomColor: theme.palette.primary.main + '!important'
                                }
                            }
                        },
                        ...sx
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    onChange={(e, item) => setValue(name, item, {shouldDirty: true, shouldTouch: true, shouldValidate: true})}
                />
            )}
        />
    );
};

export default React.memo(Autocomplete);
