import React from 'react';

import {FaqList} from '../../../../components';

const processQuestions = [
    {
        title: 'How does matching work?',
        text: 'After you create a new role, our team of experienced marketers comb through our network of experts to find the perfect fit. Once we confirm their availability, you’ll receive a personalized note from the expert, our thoughts on the fit, a link to their Growth Collective profile, and a way to schedule an introductory call. If you decide to work together, you can start an project in just a few clicks!'
    },
    {
        title: 'If I like my marketer can I hire them in-house?',
        text: 'Yes! You\'re able to hire your expert full-time. In that case, Growth Collective serves as a recruiter. We charge 15% of the first year full-time salary, capped at $20,000.'
    },
    {
        title: 'What happens when I’m ready to hire?',
        text: 'Once you receive your first match, you’ll gain access to our client portal. In the client portal, you’ll be able to send a proposal to the expert or accept a proposal from them. Once a proposal is accepted, you can kick off the project!\n' +
            '\n' +
            'Growth Collective handles all the logistics regarding time-tracking, billing and payments. We’ve also eliminated legal back-and-forth by implementing standardized terms. Our terms are based on input from hundreds of clients and freelancers. They include the important provisions for a freelance engagement, including mutual confidentiality and client ownership of the work product.'
    }
];

const ProcessTab = () => {
    return (
        <>
            <FaqList list={processQuestions}/>
        </>
    );
};

export default React.memo(ProcessTab);
