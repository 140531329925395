import {RouteCreateApplicantAccount, RouteCreateClientAccount} from '../../constants';

export const applicantStepsList = [
    {
        title: 'Years of experience',
        link: RouteCreateApplicantAccount.experience
    },
    {
        title: 'Channels',
        link: RouteCreateApplicantAccount.channels
    },
    {
        title: 'Tools',
        link: RouteCreateApplicantAccount.expertise
    },
    {
        title: 'Verticals & Brands',
        link: RouteCreateApplicantAccount.verticalsAndBrands
    },
    {
        title: 'Work type',
        link: RouteCreateApplicantAccount.workTypes
    },
    {
        title: 'Other details',
        link: RouteCreateApplicantAccount.otherDetails
    },
];

export const clientStepsList = [
    {
        title: 'Channels',
        link: RouteCreateClientAccount.channels
    },
    {
        title: 'Verticals',
        link: RouteCreateClientAccount.verticals
    },
    {
        title: 'Tools',
        link: RouteCreateClientAccount.tools
    },
    {
        title: 'Country & Time Zone',
        link: RouteCreateClientAccount.location
    },
    {
        title: 'Engagement Type',
        link: RouteCreateClientAccount.budget
    },
    {
        title: 'Describe Your Project',
        link: RouteCreateClientAccount.project
    },
];
