import React, {useEffect, useState} from 'react';

import {Theme, useMediaQuery} from '@mui/material';

import {freelancerService} from '../../../../../api';
import {ContinueButton, DialogEmoji, LoadingMask, showToastError, showToastSuccess} from '../../../../../components';
import {LegalDocType} from '../../../../../models';

interface PandaDocProps {
    id?: string;
    type: LegalDocType | null;
    onClose: (id?: string) => void;
}

const DialogPandaDoc: React.FC<PandaDocProps> = ({id, type, onClose}) => {
    const [newDocId, setNewDocId] = useState<string | null>(null);

    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        const handleMessage = (event: any) => {
            const type = event.data && event.data.type;
            const payload = event.data && event.data.payload;

            switch (type) {
                case 'session_view.document.loaded':
                    console.log('Session view is loaded');
                    break;
                case 'session_view.document.completed':
                    showToastSuccess('Document is completed');
                    onClose(payload.uuid);
                    break;
                case 'session_view.document.exception':
                    showToastError('Exception during document finalization');
            } };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [onClose]);

    useEffect(() => {
        if (!type) return;

        let isMount = true;
        const fetch = async () => {
            const {id} = await freelancerService.legalDocNew(type, new Date().getFullYear());

            if (isMount) {
                setNewDocId(id);
            }
        };

        fetch();

        return () => {
            isMount = false;
        };
    }, [type]);

    return (
        <DialogEmoji
            actions={(
                <>
                    {id && (
                        <ContinueButton
                            variant="contained"
                            sx={{width: 'auto !important'}}
                            onClick={() => onClose(id)}
                        >
                            Finish
                        </ContinueButton>
                    )}
                </>
            )}
            maxWidth="lg"
            maxWidthPx={1300}
            open={!!type}
            sx={{
                '.MuiDialogContent-root': {
                    minHeight: id ? '60vh' : '70vh'
                }
            }}
            title={`Fill in a ${type} Form`}
            onClose={() => onClose()}
        >
            {newDocId ? (
                <iframe
                    src={`https://app.pandadoc.com/s/${newDocId}`}
                    style={{
                        width: '1px',
                        minWidth: mdDown ? 900 : '100%',    // at mobile don't show too small document.
                        minHeight: 'inherit',
                        border: 'none',
                    }}
                ></iframe>
            ) : <LoadingMask/>}

        </DialogEmoji>
    );
};

export default React.memo(DialogPandaDoc);
