import React, {FC, useContext, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {ConnectOptions} from 'twilio-video';

import CallEndIcon from '@mui/icons-material/CallEnd';
import {Box, IconButton, styled, Typography} from '@mui/material';

import {MicOffIcon, MicOnIcon, SubdirectoryArrowIcon, VideoCallIcon, VideoCamOffIcon} from '../../../../assets/icons';
import {MD, VIDEO_CALL} from '../../../../constants';
import {TwilioContext} from '../../../../contexts';
import {useConversation} from '../../../../hooks';
import theme from '../../../../theme';
import {Button, LoadingMask, showToastError} from '../../..';

import CallParticipant from './CallParticipant';

const mediaErrors = [
    'NotAllowedError',
    'NotFoundError',
    'NotReadableError',
    'OverconstrainedError',
    'TypeError'
];

const VideoRoomWrapper = styled('div')<{ isCallVisible?: boolean }>`
  display: ${props => props.isCallVisible ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);

  @media (min-width: ${MD}) {
    display: ${props => props.isCallVisible ? 'flex' : 'none'};
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
  background: rgba(18, 20, 22, 0.9);
  box-shadow: 0 40px 80px rgba(18, 20, 22, 0.1);
  backdrop-filter: blur(6px);
  margin: 0 auto;

  @media (min-width: ${MD}) {
    height: auto;
    margin: auto;
    padding: 24px 40px 64px;
    max-width: 584px;
    border-radius: 24px;
  }
`;

const InnerContentWrapper = styled('div')`
  margin: auto;
  width: 100%;
  height: 100%;
`;

interface IProps {
    conversationId: string;
}

const VideoRoom: FC<IProps> = ({conversationId}) => {
    const {isAudioOnly, videoCallRoomId} = useParams();
    const [isInit, setIsInit] = useState(true);
    const {meta} = useConversation(conversationId || '');
    const {
        duration,
        error,
        isCameraOn,
        isCompact,
        isMicrophoneOn,
        localParticipant,
        remoteParticipants,
        room,
        finishCall,
        setIsCompact,
        startCall,
        toggleCamera,
        toggleMicrophone,
    } = useContext(TwilioContext);

    const location = useLocation();
    const navigate = useNavigate();

    const roomName = videoCallRoomId === VIDEO_CALL
        ? `${isAudioOnly ? 'audio-' : 'video-'}${conversationId}`
        : `scheduled-${conversationId}-${videoCallRoomId}`;

    const handleDisconnectClick = async () => {
        await finishCall(conversationId || '');
        navigate(-1);
    };

    // ad-hoc new room
    useEffect(() => {
        if (room?.state !== 'connected' && roomName) {
            const options: ConnectOptions = {
                name: roomName,
                audio: true,
                video: !isAudioOnly   // user press 'Join without video' or it is Audio call
            };

            startCall(conversationId || '', options);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (room && isInit && location?.state?.isCameraOff && room.localParticipant?.videoTracks.size) {    // isCameraOn not working
            setIsInit(false);
            toggleCamera();
        }
    }, [room, isInit, location?.state?.isCameraOff, room?.localParticipant?.videoTracks.size, toggleCamera]);

    // if user don't allow Camera or Microphone
    // show an error
    useEffect(() => {
        const handleDisconnectClick = async () => {
            await finishCall(conversationId || '');
            navigate(location.pathname.replace('/video-call', '').replace('/audio', ''), {replace: true});
        };

        if (error) {
            handleDisconnectClick();

            if (mediaErrors.includes(error.name)) {
                showToastError(new Error(`Failed to acquire media: ${error.message}`) as AxiosError);
            } else {
                showToastError(error);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    if (error) {
        return (
            <VideoRoomWrapper isCallVisible={true}>
                {/* <Typography color="error">Error: {error.message} CODE: {error.code}</Typography> */}
            </VideoRoomWrapper>
        );
    }

    // connected
    if (room?.state === 'connected') {
        return (
            <VideoRoomWrapper isCallVisible={!isCompact}>
                <ContentWrapper>
                    <InnerContentWrapper>
                        <Button
                            color="secondary"
                            sx={{
                                display: 'inline-flex',
                                gap: '8px',
                                p: 0,
                                mb: '24px',
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '20px',
                                color: theme.palette.gray.main,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: theme.palette.lightGray.main
                                },
                                'path': {
                                    fill: 'currentColor'
                                },
                                [theme.breakpoints.up('sm')]: {
                                    p: 0
                                }
                            }}
                            variant="text"
                            onClick={() => setIsCompact(true)}
                        >
                            <SubdirectoryArrowIcon/>
                            Back to the chat
                        </Button>

                        <Typography
                            sx={{
                                mb: '6px',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: 1.5,
                                color: theme.palette.white.main,
                                textAlign: 'center'
                            }}
                        >
                            Video Call with {meta?.remoteParticipant?.name}
                        </Typography>
                        <Typography
                            sx={{
                                m: '0 auto 38px',
                                p: '4px 8px',
                                width: 'max-content',
                                borderRadius: '30px',
                                textAlign: 'center',
                                color: theme.palette.white.main,
                                backgroundColor: theme.palette.black.main
                            }}
                        >
                            {duration}
                        </Typography>

                        <Box
                            sx={{
                                display: 'grid',
                                gap: '16px',
                                justifyItems: 'center',
                                mb: '38px',
                                [theme.breakpoints.up('md')]: {
                                    display: 'flex',
                                    justifyContent: 'center'
                                }
                            }}
                        >
                            {remoteParticipants && <CallParticipant conversationId={conversationId} participant={remoteParticipants[0]}/>}
                            {localParticipant && <CallParticipant conversationId={conversationId} isAuthor participant={localParticipant}/>}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '12px'
                            }}
                        >

                            {!isAudioOnly && (
                                <IconButton
                                    sx={{
                                        p: '10px',
                                        color: theme.palette.white.main,
                                        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                                        backgroundColor: isCameraOn ? 'rgba(255, 255, 255, 0.1)' : theme.palette.error.main,
                                        'svg': {
                                            width: '20px',
                                            height: '20px',
                                        },
                                        'path': {
                                            fill: 'currentColor'
                                        },
                                        '&:hover': {
                                            backgroundColor: isCameraOn ? theme.palette.textGray.dark : theme.palette.error.light
                                        }
                                    }}
                                    onClick={() => toggleCamera()}
                                >
                                    {isCameraOn ? <VideoCallIcon/> : <VideoCamOffIcon/>}
                                </IconButton>
                            )}

                            <IconButton
                                sx={{
                                    p: '10px',
                                    color: theme.palette.white.main,
                                    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                                    backgroundColor: isMicrophoneOn ? 'rgba(255, 255, 255, 0.1)' : theme.palette.error.main,
                                    'svg': {
                                        width: '20px',
                                        height: '20px',
                                    },
                                    'path': {
                                        fill: 'currentColor'
                                    },
                                    '&:hover': {
                                        backgroundColor: isMicrophoneOn ? theme.palette.textGray.dark : theme.palette.error.light
                                    }
                                }}
                                onClick={() => toggleMicrophone()}
                            >
                                {isMicrophoneOn ? <MicOnIcon/> : <MicOffIcon/>}
                            </IconButton>
                            <IconButton
                                sx={{
                                    p: '10px',
                                    color: theme.palette.white.main,
                                    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.03)',
                                    backgroundColor: theme.palette.error.main,
                                    'svg': {
                                        width: '20px',
                                        height: '20px',
                                    },
                                    'path': {
                                        fill: 'currentColor'
                                    },
                                    '&:hover': {
                                        backgroundColor: theme.palette.error.light
                                    }
                                }}
                                onClick={handleDisconnectClick}
                            >
                                <CallEndIcon/>
                            </IconButton>
                        </Box>
                    </InnerContentWrapper>
                </ContentWrapper>
            </VideoRoomWrapper>
        );
    }


    return (
        <VideoRoomWrapper><LoadingMask/></VideoRoomWrapper>
    );
};

export default React.memo(VideoRoom);
