import React, {useCallback} from 'react';

import MUIChip, {ChipProps} from '@mui/material/Chip';

import {CheckboxIcon, CloseIcon, PlusIcon} from '../../assets/icons';

interface Props {
    deleteIcon?: React.ReactNode;
    isSelected?: boolean;
    variant: 'active' | 'default' | 'done' | 'filled';
}

const Chip: React.FC<ChipProps & Props> = ({variant, deleteIcon, isSelected, ...props}) => {
    const getIcon = useCallback((variant?: string) => {
        switch (variant) {
            case 'active':
            case 'filled':
                return <CloseIcon/>;
            case 'default':
                return <PlusIcon/>;
            case 'done':
                return <CheckboxIcon/>;
            default:
                return <CloseIcon/>;
        }
    }, []);

    return (
        <MUIChip
            {...props}
            className={isSelected ? 'selected' : ''}
            deleteIcon={deleteIcon || getIcon(variant)}
            variant={variant}
        />
    );
};

export default React.memo(Chip);
