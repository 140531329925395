import React, {SyntheticEvent} from 'react';
import clsx, {ClassValue} from 'clsx';
import {Controller, useFormContext} from 'react-hook-form';

import {FormControl, FormHelperText, TextField, TextFieldProps as MUITextFieldProps} from '@mui/material';

import {AnimateHeight} from '../..';

const DELIMITER = '/';
const initialSate = {
    maxLength: DELIMITER.length + 4,
    value: ''
};

type TextFieldProps = MUITextFieldProps & {
    classNames?: ClassValue[];
}

const InputDateMMYY = React.forwardRef(({classNames, fullWidth = true, name = '', placeholder, variant, ...props}: TextFieldProps, ref): JSX.Element => {
    const {
        control,
        formState: {errors},
    } = useFormContext();

    const correctInput = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const key = (event as any).key;
        const monthMin = '01';
        const monthMax = '12';

        let typeKey;
        // const yearMin = (new Date).getFullYear() - 2000;
        // const yearMax = (new Date).getFullYear() - 2000 + 25;

        if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
            if (/(\d)/.test(key)) {
                typeKey = 'number';
            } else {
                typeKey = 'specSymbol';
            }

            if (value.length == 0 && key > 1) { // 2 -> 02/
                target.value = ('0' + key + DELIMITER);
                event.preventDefault();
            }

            if (value.length == 1 && value[0] != '0' && key > 2) { // 16 -> 12/
                target.value = monthMax + DELIMITER;
                event.preventDefault();

                return;
            }

            if (value.length == 1 && typeKey !== 'specSymbol') { //12 backspace -> 1
                target.value = target.value + key + DELIMITER;
                event.preventDefault();
            }

            if (value.length == 2 && typeKey !== 'specSymbol') { // 2 -> 02/
                target.value = (target.value + DELIMITER + key);
                event.preventDefault();

                return;
            }

            if (value == '0' && key == '0') {  // 00 -> 01
                target.value = monthMin + DELIMITER;
                event.preventDefault();

                return;
            }
        } else {
            event.preventDefault();

            return;
        }
    };

    return (
        <Controller
            control={control}
            defaultValue=''
            name={name}
            render={({field}) => (
                <FormControl
                    error={!!errors[name]}
                    fullWidth={fullWidth}
                >
                    <TextField
                        {...props}
                        {...field}
                        className={clsx(classNames)}
                        error={!!errors[name]}
                        inputRef={ref}
                        inputProps={{maxLength: initialSate.maxLength}}
                        placeholder={placeholder || 'MM/YY'}
                        variant={variant || 'standard'}
                        onKeyDown={correctInput}
                        sx={{
                            'input.MuiInputBase-input.MuiInput-input': {
                                '&::placeholder, &::-webkit-input-placeholder': {
                                    opacity: '.5 !important'
                                },
                            },
                        }}
                    />
                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </FormControl>
            )}
        />
    );
});

export default React.memo(InputDateMMYY);
