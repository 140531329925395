import React, {EventHandler, FC} from 'react';

import {Box, styled, Typography} from '@mui/material';

import {DeleteIcon, EditIcon} from '../../../../assets/icons';
import {IconButton, LoadingMask} from '../../../../components';
import theme from '../../../../theme';

export type IconType = 'pdf' | 'link' | 'reference' | 'case study' | 'default';

const ItemWrp = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 8px 16px;
  background-color: ${theme.palette.blue.light};
  border-radius: 16px;
`;

const IconWrp = styled('div')`
  width: 24px;
  height: 24px;

  path {
    fill: ${theme.palette.purple.main};
  }
`;

const BtnWrp = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

interface IProps {
    icon: React.ReactNode;
    isDraft?: boolean;
    isSubmitting?: boolean;
    name: string;
    type: IconType;
    onEdit: EventHandler<any>;
    onDelete: EventHandler<any>;
}

const ApplicationAddedItem: FC<IProps> = ({icon, isDraft, isSubmitting, name, onEdit, onDelete}) => {
    return (
        <ItemWrp>
            <IconWrp>{icon}</IconWrp>
            {isDraft && (
                <Typography
                    sx={{
                        color: theme.palette.gray.main,
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '21px',
                    }}
                >
                    Draft
                </Typography>
            )}
            <Box
                sx={{
                    width: 'calc(100% - 132px)'
                }}
            >
                <Typography
                    sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '21px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                        'span': {
                            color: theme.palette.gray.main
                        }
                    }}
                >
                    {name}
                </Typography>
            </Box>
            <BtnWrp>
                {
                    isSubmitting ? (
                        <Box sx={{height: 40, p: 2}}>
                            <LoadingMask hasNoSpace size={22}/>
                        </Box>
                    ) : (
                        <>
                            <IconButton
                                icon={<EditIcon/>}
                                onClick={onEdit}
                            />

                            <IconButton
                                icon={<DeleteIcon/>}
                                colorActive={theme.palette.error.main}
                                onClick={onDelete}
                            />

                        </>
                    )
                }
            </BtnWrp>
        </ItemWrp>
    );
};

export default ApplicationAddedItem;
