import React from 'react';

import {Box} from '@mui/material';

import {EngagementActionsFrelancer, EngagementInfoBox} from '../../../../../../../components';
import {EEngagementStatus, EResumeStatus, IEngagementFreelancer} from '../../../../../../../models';


interface ResumeEngagementPendingProps {
    data: IEngagementFreelancer;
}

const ResumeEngagementPending: React.FC<ResumeEngagementPendingProps> = ({data}) => {
    if (data.status !== EEngagementStatus.PAUSED || data.resume?.resumeStatus !== EResumeStatus.FREELANCER_REQUESTED) {
        return null;
    }

    return (
        <>
            <EngagementInfoBox
                iconUrl="/assets/images/pending-icon.png"
                title="Resume engagement request pending"
            >
                {data.clientName.split(' ')[0]} has received your resume engagement request.
                They have up to 2 business days to approve or reject it.
                Otherwise it will be approved automatically.

                <Box
                    sx={{
                        mt: '16px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <EngagementActionsFrelancer.ResumeCancelBtn data={data}/>
                </Box>

            </EngagementInfoBox>
        </>
    );
};

export default React.memo(ResumeEngagementPending);
