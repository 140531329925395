import React from 'react';

import {Box, Typography} from '@mui/material';

import {useAuth} from '../../hooks';
import theme from '../../theme';

const EarningsSpendingsEmpty = () => {
    const {isClient} = useAuth();

    return (
        <Box
            sx={{
                width: '100%',
                mt: '48px',
                display: 'flex'
            }}
        >
            <Box
                sx={{
                    margin: 'auto',
                    textAlign: 'center',
                    'img': {
                        width: '64px',
                        height: '64px',
                        display: 'inline-block',
                        mb: '32px'
                    }
                }}
            >
                <img src="/assets/images/glancing-eyes-emoji.png" width="64" height="64" alt="glancing eyes emoji"/>
                <Typography
                    sx={{
                        mb: '2px',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    No {isClient ? 'spendings' : 'earnings'} during this time
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main
                    }}
                >
                    {
                        isClient
                            ? 'Once you make your first payment to a freelancer, it will appear here'
                            : 'Once you receive your first payment, it will appear here'
                    }
                </Typography>
            </Box>
        </Box>
    );
};

export default React.memo(EarningsSpendingsEmpty);
