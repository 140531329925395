import React from 'react';

const BlogIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.90625 12.625H6.25C6.68148 12.625 7.03125 12.2752 7.03125 11.8438C7.03125 11.4123 6.68148 11.0625 6.25 11.0625H3.90625C3.47477 11.0625 3.125 11.4123 3.125 11.8438C3.125 12.2752 3.47477 12.625 3.90625 12.625Z"
                fill="#121416"/>
            <path
                d="M3.90625 9.5H6.25C6.68148 9.5 7.03125 9.15023 7.03125 8.71875C7.03125 8.28727 6.68148 7.9375 6.25 7.9375H3.90625C3.47477 7.9375 3.125 8.28727 3.125 8.71875C3.125 9.15023 3.47477 9.5 3.90625 9.5Z"
                fill="#121416"/>
            <path
                d="M8.49377 11.8449C8.49377 12.2764 8.84354 12.6262 9.27502 12.6262C10.9087 12.6262 12.4445 11.99 13.5997 10.8349L19.313 5.12104C20.2289 4.20514 20.229 2.72252 19.313 1.80646C18.3992 0.892637 16.9123 0.892637 15.9984 1.80646L10.2851 7.52029C9.12999 8.67549 8.49377 10.2113 8.49377 11.8449ZM17.1033 2.91135C17.4078 2.60674 17.9035 2.60674 18.2081 2.91135C18.5134 3.21658 18.5135 3.71084 18.2081 4.01619L12.4948 9.73002C11.8417 10.3831 11.0251 10.8134 10.1373 10.9827C10.3065 10.0949 10.7369 9.2783 11.3899 8.62518L17.1033 2.91135Z"
                fill="#121416"/>
            <path
                d="M2.34375 18.875C2.92094 18.875 3.4757 18.6632 3.90594 18.2785L4.9859 17.3125H12.9688C15.1227 17.3125 16.875 15.5602 16.875 13.4062V11.6538C16.875 11.2223 16.5252 10.8725 16.0938 10.8725C15.6623 10.8725 15.3125 11.2223 15.3125 11.6538V13.4062C15.3125 14.6986 14.2611 15.75 12.9688 15.75H4.6875C4.49531 15.75 4.30988 15.8208 4.16664 15.9489L2.86438 17.1138C2.72105 17.242 2.53617 17.3125 2.34375 17.3125C1.91297 17.3125 1.5625 16.962 1.5625 16.5312V7.15625C1.5625 5.86391 2.61391 4.8125 3.90625 4.8125H10.4589C10.8904 4.8125 11.2402 4.46273 11.2402 4.03125C11.2402 3.59977 10.8904 3.25 10.4589 3.25H3.90625C1.7523 3.25 0 5.00234 0 7.15625V16.5312C0 17.8236 1.05141 18.875 2.34375 18.875Z"
                fill="#121416"/>
        </svg>
    );
};

export default BlogIcon;
