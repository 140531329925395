import React from 'react';

import {Box, styled, Typography} from '@mui/material';

import {HeartsIcon} from '../../assets/icons';
import {MD} from '../../constants';
import theme from '../../theme';

const Wrp = styled('div')`
  display: none;

  @media (min-width: ${MD}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;
    background-color: ${theme.palette.white.main};
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);
    border-radius: 24px;

    svg {
      margin-top: 5px;
      margin-right: 36px;
    }
  }
`;

interface ITopBox {
    icon?: React.ReactNode;
    subtitle: string;
    title: string;
}

const TopBox: React.FC<ITopBox> = ({title, subtitle, icon}) => {
    return (
        <Wrp>
            <Box
                sx={{
                    p: '24px'
                }}
            >
                <Typography
                    sx={{
                        mb: '4px',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: 1.5
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main
                    }}
                >
                    {subtitle}
                </Typography>
            </Box>
            {
                icon || <HeartsIcon/>
            }
        </Wrp>
    );
};

export default React.memo(TopBox);
