import React, {useCallback} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {FormHelperText} from '@mui/material';

import {MAX_SAFE_INTEGER} from '../../../constants';
import theme from '../../../theme';
import {AnimateHeight, InputAdornment} from '../..';

interface InputFixedRateProps {
    name: string;
}

export const InputFixedRate: React.FC<InputFixedRateProps> = React.memo(React.forwardRef(({name}, ref) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const fixedRate: number = value;

    const handleChangeFixedRate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);

        if (typeof value === 'number') {
            if (value > MAX_SAFE_INTEGER / 100) {
                const max = parseInt((MAX_SAFE_INTEGER / 100).toString(), 10);

                onChange(max);
            } else {
                onChange(value);
            }
        }

        trigger(name);
    }, [name, onChange, trigger]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <>
                    <InputAdornment
                        icon="$"
                        inputRef={ref}
                        placeholder="Type your hourly rate here"
                        position="start"
                        value={fixedRate || ''}
                        onChange={handleChangeFixedRate}
                    />

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </>
            )}
        />
    );
}));
