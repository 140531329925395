import {
    addDays,
    addMonths, addWeeks,
    addYears,
    endOfMonth,
    endOfWeek,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
} from 'date-fns';

type DefinedRange = {
    startDate?: Date;
    endDate?: Date;
    label: string;
}

// Last 7 Days
export const defaultDateRange = {
    startDate: addWeeks(new Date(), -1),
    endDate: new Date()
};

export const getDefaultRanges = (date: Date, locale?: Locale): DefinedRange[] => [
    {
        label: 'All time',
    },
    {
        label: 'Today',
        startDate: startOfDay(date),
        endDate: date,
    },
    {
        label: 'Yesterday',
        startDate: addDays(date, -1),
        endDate: addDays(date, -1),
    },
    {
        label: 'This Week',
        startDate: startOfWeek(date, {locale}),
        endDate: endOfWeek(date, {locale}),
    },
    {
        label: 'Last Week',
        startDate: startOfWeek(addWeeks(date, -1), {locale}),
        endDate: endOfWeek(addWeeks(date, -1), {locale}),
    },
    {
        label: 'Last 7 Days',
        startDate: addWeeks(date, -1),
        endDate: date,
    },
    {
        label: 'This Month',
        startDate: startOfMonth(date),
        endDate: endOfMonth(date),
    },
    {
        label: 'Last Month',
        startDate: startOfMonth(addMonths(date, -1)),
        endDate: endOfMonth(addMonths(date, -1)),
    },
    {
        label: 'Last 3 Months',
        startDate: startOfMonth(addMonths(date, -4)),
        endDate: endOfMonth(addMonths(date, -1)),
    },
    {
        label: 'Last 6 Months',
        startDate: startOfMonth(addMonths(date, -7)),
        endDate: endOfMonth(addMonths(date, -1)),
    },
    {
        label: 'This Year',
        startDate: startOfYear(date),
        endDate: endOfYear(date),
    },
    {
        label: 'Last Year',
        startDate: startOfYear(addYears(date, -1)),
        endDate: endOfYear(addYears(date, -1)),
    },
];
