import React, {SyntheticEvent, useState} from 'react';
import {motion} from 'framer-motion';

import {alpha, Box, FormHelperText, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {ApprovePrimaryIcon, EyeIcon} from '../../../../../assets/icons';
import {ICoverImage} from '../../../../../models';
import theme from '../../../../../theme';
import {getCoverImagePath} from '../../../../../utils';
import {AnimateHeight} from '../../../../AnimateHeight/AnimateHeight';
import {DialogPreviewImage} from '../../../../Dialog';
import {COVER_IMAGES} from '../../../config';

const ImageWrapper = styled(motion.span)(({variant, name, theme}: { name: string; theme: Theme; variant: number }) => ({
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: theme.palette.lightGray.light,
    backgroundImage: name ? `url(${getCoverImagePath(name, variant)})` : '',
    backgroundSize: 'cover',
    borderRadius: 2,
    opacity: 1,
    transition: 'opacity .3s',
    '&:hover': {
        opacity: 0.9,
    },
    [theme.breakpoints.up('xs')]: {
        height: 256,
        width: 256,
    },
    [theme.breakpoints.up('md')]: {
        height: 'auto',
        width: '100%',
        aspectRatio: '1/1'
    }
}));

const SubTitle = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin: 16px 0;
  color: ${theme.palette.black.main};
`;

type CoverImagePickerProps = {
    error: string;
    name: string;
    variant: number;
    onChange: (value: ICoverImage) => void;
};

const CoverImagePicker: React.FC<CoverImagePickerProps> = ({error, name, variant, onChange}) => {
    const [preview, setPreview] = useState<{ url: string; isOpen: boolean } | null>(null);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const xsUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));

    const handleZoomClick = (event: SyntheticEvent, value: ICoverImage) => {
        event.stopPropagation();

        const {name, variant} = value;

        if (!variant) return '';
        const url = getCoverImagePath(name, variant, {lgUp, mdUp, xsUp});

        setPreview({isOpen: true, url});
    };

    return (
        <>
            <SubTitle>Pick a color</SubTitle>

            <Box overflow="auto" padding="6px 2px 12px">
                <Box display="flex" gap="4px" width="fit-content">
                    {COVER_IMAGES.map(item => (
                        <Box
                            key={item.name}
                            sx={{
                                height: '56px',
                                width: '56px',
                                background: item.value,
                                border: '3px solid white',
                                borderRadius: '50%',
                                outline: name === item.name ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent',
                                cursor: 'pointer',
                                transition: 'outline .3s',
                                '&:hover': {
                                    outline: name === item.name ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.lightGray.main}`,
                                }
                            }}
                            onClick={() => onChange({name: item.name, variant: null})}
                        >

                            <Box
                                sx={{
                                    marginLeft: '30px',
                                    marginTop: '-11px',
                                    position: 'relative',
                                }}
                            >
                                <ApprovePrimaryIcon active={name === item.name} isAlwaysVisible={false}/>
                            </Box>


                        </Box>
                    ))}
                </Box>
            </Box>

            <SubTitle>Pick an image</SubTitle>

            <Box
                sx={{
                    p: '24px',
                    pr: 0,
                    mr: '-20px',
                    borderRadius: '16px 0 0 16px',
                    border: '1px solid',
                    borderColor: error ? theme.palette.error.main : theme.palette.lightGray.main,
                    [theme.breakpoints.up('md')]: {
                        pr: '24px',
                        mr: 0,
                        borderRadius: '16px'
                    }
                }}
            >
                <Box
                    sx={{
                        [theme.breakpoints.up('xs')]: {
                            overflow: 'auto',
                            paddingBottom: 2,
                        },
                        [theme.breakpoints.up('md')]: {
                            paddingBottom: 0,
                        }
                    }}
                >
                    <Box
                        display="flex"
                        gap="16px"
                        sx={{
                            [theme.breakpoints.up('xs')]: {
                                width: 'fit-content'
                            },
                            [theme.breakpoints.up('md')]: {
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                                gridTemplateRows: 'repeat(2, 1fr)'
                            }
                        }}
                        width="fit-content"
                    >
                        {[1, 2, 3, 4, 5, 6].map((item, index) => (
                            <ImageWrapper
                                initial={{opacity: 0, scale: 0.7}}
                                animate={{opacity: 1, scale: 1}}
                                transition={{
                                    delay: 0.05 * index,
                                    duration: 0.3,
                                    ease: 'easeInOut',
                                }}
                                key={`${name}-${item}`}
                                name={name}
                                theme={theme}
                                variant={item}
                                onClick={() => onChange({name, variant: item})}
                            >
                                <Box margin={2}>
                                    <ApprovePrimaryIcon active={item === variant} isAlwaysVisible={true}/>
                                </Box>

                                <Box
                                    sx={{
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bottom: '8px',
                                        right: '8px',
                                        height: '48px',
                                        width: '48px',
                                        padding: '4px',
                                        borderRadius: '50%',
                                        cursor: 'zoom-in',
                                        background: alpha(theme.palette.lightGray.main, 0.5),
                                        '&:hover': {
                                            background: alpha(theme.palette.lightGray.main, 0.7)
                                        },
                                        'svg': {
                                            width: '16px',
                                            height: '16px'
                                        },
                                        'path': {
                                            fill: theme.palette.black.main
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            width: '32px',
                                            height: '32px'
                                        }
                                    }}
                                    onClick={(event) => handleZoomClick(event, {name, variant: item})}
                                >
                                    <EyeIcon/>
                                </Box>

                            </ImageWrapper>
                        ))}
                    </Box>
                </Box>
            </Box>
            {/* at mobile conflict with buttons in the portal */}
            <Box/>

            <AnimateHeight isVisible={!!error}>
                <FormHelperText id="error-cover-image" sx={{color: theme.palette.error.main}}>
                    {error}
                </FormHelperText>
            </AnimateHeight>

            {/* Full screen preview image */}
            <DialogPreviewImage
                open={!!preview?.isOpen}
                url={preview?.url || ''}
                onClose={() => setPreview({isOpen: false, url: preview?.url || ''})}
            />
        </>
    );
};

export default CoverImagePicker;
