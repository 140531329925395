import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {any, object, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {matchesService} from '../../../api';
import {useAuth} from '../../../hooks';
import {
    AnimateHeight,
    BlackBorderButton,
    Button,
    Checkbox,
    ContinueButton,
    DialogEmoji,
    Input,
    RedFilledButton,
    showToastError,
    showToastSuccess
} from '../..';

const REASON_MAX_LENGTH = 400;
const QUESTION_PREFIX = 'question-';

const QUESTIONS = [
    'It is over my budget',
    'The conversation didn’t go well',
    'The freelancer doesn’t have the required skills',
    'The freelancer is not experienced enough for this role',
];

export const rejectSchema = object({
    isOther: any(),
    isRequired: any(),
    other: any(),
    [`${QUESTION_PREFIX}0`]: any(),
    [`${QUESTION_PREFIX}1`]: any(),
    [`${QUESTION_PREFIX}2`]: any(),
    [`${QUESTION_PREFIX}3`]: any(),
}).refine((data) => data.isOther ? data.other.length : true, {
    path: ['other'],
    message: 'If other, please specify...'
}).refine((data) => data.isOther ? data.other.length > 0 : true, {
    path: ['other'],
    message: `Reason must be at least ${REASON_MAX_LENGTH} characters`
}).refine((data) => data.isOther ? data.other.length <= REASON_MAX_LENGTH : true, {
    path: ['other'],
    message: `Reason must be less than ${REASON_MAX_LENGTH} characters`
});

type RejectInput = TypeOf<typeof rejectSchema>;

interface IProps {
    isAskForNewRole?: boolean;
    isRequired: boolean;
    firstName: string;
    matchId: number;
    onReject: () => void;
}

const ModalButtonReject: React.FC<IProps> = ({isAskForNewRole, isRequired, firstName, matchId, onReject}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);
    const [openAskNewRole, setOpenAskNewRole] = useState(false);
    const [openThankYou, setOpenThankYou] = useState(false);

    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const methods = useForm<RejectInput>({
        resolver: zodResolver(rejectSchema),
    });

    const {
        handleSubmit,
        formState: {isDirty, errors},
        getValues,
        reset,
        setError,
        trigger,
        watch
    } = methods;

    const isOther = watch('isOther');
    const other = watch('other', '');
    const q1 = watch(`${QUESTION_PREFIX}0`, '');
    const q2 = watch(`${QUESTION_PREFIX}1`, '');
    const q3 = watch(`${QUESTION_PREFIX}2`, '');
    const q4 = watch(`${QUESTION_PREFIX}3`, '');

    const {mutate, isLoading: isSubmitting} = useMutation(
        (reason: string) => matchesService.rejectMatch(matchId, reason),
        {
            onSuccess(response) {
                console.log('rejected', response);
                showToastSuccess('Freelancer was rejected successfully!');
                reset({});
                setOpen(false);
                setOpenThankYou(true);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleClose = useCallback(() => {
        reset({});
        setOpen(false);
    }, [reset]);

    const handleThankYouClose = useCallback(() => {
        setOpenThankYou(false);
        onReject();
    }, [onReject]);

    const onSubmitHandler: SubmitHandler<RejectInput> = () => {
        const values = getValues();

        delete values.isOther;
        delete values.other;

        const answers = Object.entries<string>(values).filter((it) => !!it[1]);
        const questions = answers.map(([key]) => QUESTIONS[Number(key.replace(QUESTION_PREFIX, ''))]);
        const payload = other.trim() ? [...questions, other.trim()] : questions;

        if (isRequired && !payload.length) {
            setError('isRequired', {
                type: 'custom',
                message: 'Feedback required'
            });
        } else {
            mutate(payload.join('; '));
        }
    };

    useEffect(() => {
        if (!isRequired || !errors.isRequired) return;

        trigger('isRequired');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [q1, q2, q3, q4, other]);

    if (!matchId) {
        return null;
    }

    return (
        <>
            <Button
                color="error"
                disabled={isImpersonal}
                variant="text"
                onClick={() => isAskForNewRole ? setOpenAskNewRole(true) : setOpen(true)}
            >
                Reject match
            </Button>

            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            disabled={isSubmitting}
                            fullWidth={mdDown}
                            onClick={handleClose}
                        >
                            Cancel
                        </BlackBorderButton>

                        <RedFilledButton
                            fullWidth={mdDown}
                            disabled={isSubmitting || !isDirty}
                            sx={{width: mdDown ? '100%' : 'auto !important'}}
                            onClick={handleSubmit(onSubmitHandler)}
                        >
                            Reject
                        </RedFilledButton>
                    </>
                )}
                maxWidth="sm"
                maxWidthPx={574}
                open={open}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img src="/assets/images/sad-emoji.png" width="48" height="48" alt="sad icon"/>
                        </Box>
                        We’re sorry you don’t like the match.<br/>
                        Why isn’t {firstName} a good fit?
                    </>
                )}
                onClose={handleClose}
            >
                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                    >
                        <Stack spacing={2}>
                            {QUESTIONS.map((question, index) => (
                                <Checkbox
                                    key={index}
                                    label={question}
                                    name={`${QUESTION_PREFIX}${index}`}
                                />
                            ))}
                            <Checkbox
                                label="Other"
                                name="isOther"
                            />

                            <AnimateHeight isVisible={!!isOther}>
                                <Input
                                    counter={REASON_MAX_LENGTH - (other?.length || 0)}
                                    disabled={isSubmitting}
                                    inputProps={{maxLength: REASON_MAX_LENGTH}}
                                    minRows={5}
                                    multiline
                                    name="other"
                                    placeholder="If other, please specify..."
                                    variant="outlined"
                                />
                            </AnimateHeight>

                            <AnimateHeight isVisible={!!errors.isRequired}>
                                <Typography
                                    color="error"
                                    sx={{
                                        display: 'inline-block',
                                        mb: '32px'
                                    }}
                                    variant="caption"
                                >
                                    Feedback is required
                                </Typography>
                            </AnimateHeight>
                        </Stack>
                    </form>
                </FormProvider>
            </DialogEmoji>

            <DialogEmoji
                actions={(
                    <ContinueButton
                        variant="contained"
                        sx={{
                            width: 'auto !important',
                            minWidth: mdDown ? '161px' : 'unset',
                            'div': {
                                justifyContent: 'center'
                            }
                        }}
                        onClick={handleThankYouClose}
                    >
                        Back
                    </ContinueButton>
                )}
                maxWidth="sm"
                open={openThankYou}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img src="/assets/images/ok-emoji.png" width="48" height="48" alt="ok icon"/>
                        </Box>
                        Got it, thanks for the feedback.<br/>
                        We’ll match another expert for the role soon!
                    </>
                )}
                onClose={handleThankYouClose}
            >
            </DialogEmoji>

            {/* Disable rejection more Freelancers, and ask for creating new Role */}
            <DialogEmoji
                actions={(
                    <ContinueButton
                        variant="contained"
                        sx={{
                            width: 'auto !important',
                            minWidth: mdDown ? '161px' : 'unset',
                            'div': {
                                justifyContent: 'center'
                            }
                        }}
                        onClick={() => setOpenAskNewRole(false)}
                    >
                        Back
                    </ContinueButton>
                )}
                maxWidth="sm"
                open={openAskNewRole}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img src="/assets/images/sad-emoji.png" width="48" height="48" alt="ok icon"/>
                        </Box>
                        Oops! It looks like we’ve got some incorrect details in the current role.<br/><br/>
                        Time for a fresh start!<br/><br/>
                        Please create a new role and let’s get things rolling!
                    </>
                )}
                onClose={() => setOpenAskNewRole(false)}
            >
            </DialogEmoji>
        </>
    );
};

export default React.memo(ModalButtonReject);
