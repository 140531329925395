import React from 'react';

import {Box, Grid} from '@mui/material';

import theme from '../../theme';
import {CheckButton} from '../Button';
import {InputCaption} from '../Forms';

const containerStyles = {
    gap: '12px',
    [theme.breakpoints.up('lg')]: {
        gap: '16px'
    }
};

const biggerItemStyles = {
    [theme.breakpoints.up('md')]: {
        width: 'calc(50% - 6px)',
        flexBasis: 'calc(50% - 6px)',
        maxWidth: 'calc(50% - 6px) !important',
    },
    [theme.breakpoints.up('lg')]: {
        width: 'calc(33% - 9px)',
        flexBasis: 'calc(33% - 9px)',
        maxWidth: 'calc(33% - 9px) !important'
    }
};

interface BudgetRateRangeProps {
    value?: number;
    onChange: (range: number[]) => void;
}

export const BudgetRateRange: React.FC<BudgetRateRangeProps> = React.memo(({value, onChange}) => {
    return (
        <Grid container sx={containerStyles}>
            <Grid
                item
                xs={12}
            >
                <Box
                    sx={{
                        mt: '40px',
                        mb: '-12px',
                        [theme.breakpoints.up('xl')]: {
                            mt: '64px',
                            mb: '-16px'
                        }
                    }}
                >
                    <InputCaption>Select desired <span>seniority level</span></InputCaption>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                sx={biggerItemStyles}
            >
                <CheckButton
                    label="Mid level"
                    selected={value === 50}
                    subtitle="$50 - $100"
                    onClick={() => onChange([50, 100])}
                />
            </Grid>

            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                sx={biggerItemStyles}
            >
                <CheckButton
                    label="Senior"
                    selected={value === 100}
                    subtitle="$100 - $150"
                    onClick={() => onChange([100, 150])}
                />
            </Grid>

            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                sx={biggerItemStyles}
            >
                <CheckButton
                    label="Executive"
                    selected={value === 150}
                    subtitle="$150 +"
                    onClick={() => onChange([150])}
                />
            </Grid>

        </Grid>
    );
});
