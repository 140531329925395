import React from 'react';
import {motion} from 'framer-motion';
import {useParams} from 'react-router-dom';

import {Card, CardContent, Typography} from '@mui/material';

import {animationFade} from '../../../../../components/Animations';
import {COMPLETED, PROJECT} from '../../../../../constants';
import {ERoleStatus, IClientRole} from '../../../../../models';
import theme from '../../../../../theme';
import {formatDate} from '../../../../../utils';

import ActionsMenu from './ActionsMenu';
import RoleStatus from './RoleStatus';


interface MatchedItemProps {
    index: number;
    item: IClientRole;
    onClick: (roleId: number) => void;
}

const RoleItem: React.FC<MatchedItemProps> = ({index, item, onClick}) => {
    const {roleId} = useParams();

    return (
        <motion.div
            {...animationFade(index)}
        >
            <Card
                sx={{
                    p: roleId && Number(roleId) === item.id ? '22px' : '24px',
                    position: 'relative',
                    borderRadius: '23px',
                    backgroundColor: theme.palette.white.main,
                    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.03)',
                    cursor: 'pointer',
                    border: roleId && Number(roleId) === item.id ? `2px solid ${theme.palette.purple.main}` : null,
                }}
                onClick={() => onClick(item.id)}
            >
                <CardContent
                    sx={{
                        p: 0,
                        '&:last-child': {
                            pb: 0
                        }
                    }}
                >
                    <ActionsMenu
                        isPublishBtn={item.status === ERoleStatus.Draft && [COMPLETED, PROJECT].includes(item.view)}
                        sx={{
                            position: 'absolute',
                            top: '20px',
                            right: '20px'
                        }}
                        status={item.status}
                        roleId={item.id}
                    />
                    <Typography
                        sx={{
                            mb: '4px',
                            pr: '32px',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: 1.5,
                            color: theme.palette.black.main,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {item.projectName}
                    </Typography>
                    <Typography
                        sx={{
                            mb: '8px',
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.gray.main
                        }}
                    >
                        {item.status === ERoleStatus.Draft ? (
                            <>Created: {item.created && formatDate(item.created) || 'n/a'}</>
                        ) : (
                            <>Submitted: {item.submitted && formatDate(item.submitted) || 'n/a'}</>
                        )}
                    </Typography>
                    <RoleStatus status={item.status} engagementId={item.engagementId}/>
                </CardContent>
            </Card>
        </motion.div>
    );
};

export default React.memo(RoleItem);
