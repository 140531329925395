import {useQuery} from '@tanstack/react-query';

import {OptionType} from '../../models';

type ApiCallFunc = (q: string) => Promise<OptionType[]>;

export const useOptions = (apiMethod: ApiCallFunc, id = 'items', search: string) => {
    return useQuery(
        [id + '-search', search],
        () => apiMethod(search),
        // the following can be used to avoid refetches on already fetched data
        {
            enabled: !!search.trim(),
            keepPreviousData: true,
            staleTime: 5 * 60 * 1000
        }
    );
};
