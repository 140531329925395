import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useQueryClient} from '@tanstack/react-query';

import {applicationService, authService, clientService} from '../api';
import {RouteApplication, RouteClientPortal, RouteCreateApplicantAccount, RouteCreateClientAccount, RouteFreelancerPortal} from '../constants';
import {ERole, ERoleStatus, IUser} from '../models';
import {urlByApplicationStep, urlByClientView} from '../routes/routes.utils';
import {isWidget} from '../utils';


type State = {
    isAuthenticated: boolean;
    user: IUser | null;

    isApplicant: boolean;
    isClientUnverified: boolean;

    isClient: boolean;
    isFreelancer: boolean;
    isImpersonal: boolean;      // used token from Admin panel for showing Portal in read-only mode
    isImpersonalBorder: boolean;      // show red border but allow everything

    findRole: (roles: ERole[]) => boolean;
    login: (username: string, password: string, redirect?: string) => Promise<void>;
    logout: () => void;
    refreshUser: () => void;
};

type AuthContextProviderProps = { children: React.ReactNode };

const AuthContext = React.createContext<State | null>(null);

const AuthContextProvider = ({children}: AuthContextProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<IUser | null>(authService.loadUser());
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const findRole = useCallback((roles: ERole[]): boolean => {
        if (!user) return false;

        return findSome(user.roles, roles);
    }, [user]);

    const redirect = async (path?: string) => {
        const user = authService.loadUser();

        if (!user) {
            return;
        // get current step for the new Applicant or CLient
        } else {
            const isApplicant = findSome(user.roles, [ERole.APPLICANT]);
            const isClient = findSome(user.roles, [ERole.CLIENT]);
            const isFreelancer = findSome(user.roles, [ERole.FREELANCER]);
            const isNewClient = findSome(user.roles, [ERole.CLIENT_UNVERIFIED]);

            let routeByState;

            // prevent redirect to prev step if Role was changed
            // Applicant -> Freelancer
            // CLIENT_UNVERIFIED -> Client
            if (path) {
                if (
                    (isApplicant && Object.values(RouteCreateApplicantAccount).includes(path)) ||
                    (isFreelancer && Object.values(RouteApplication).includes(path)) ||
                    (isNewClient && Object.values(RouteCreateClientAccount).includes(path)) ||
                    (isClient && Object.values(RouteClientPortal).includes(path))
                ) {
                    navigate(path, {replace: true});

                    return;
                }
            }


            if (isApplicant) {
                const {step} = await applicationService.getApplication();

                routeByState = urlByApplicationStep(step);

            // SignUp process not finished - redirect to the current step
            } else if (isNewClient) {
                if (isWidget()) {
                    routeByState = path; // go to ConfirmCode
                } else {
                    const response = await clientService.getRoles([ERoleStatus.Draft])({pageParam: 0});
                    const view = response.data.find(role => role.view !== 'confirmed')?.view;

                    routeByState = urlByClientView(view);
                }

            // redirect to the Client Portal
            } else if (isClient) {
                routeByState = RouteClientPortal.default;

            // redirect to the Freelancer Portal
            } else if (isFreelancer) {
                routeByState = RouteFreelancerPortal.default;
            }

            if (routeByState) {
                navigate(routeByState, {replace: true});
            } else {
                console.warn('default path unknown');
            }
        }
    };

    const login = useCallback(async (username: string, password: string, path?: string) => {
        try {
            const response = await authService.getTokenFn(username, password);

            authService.saveToken(response);
            refreshUser();
            redirect(path);
        } catch (error) {
            throw error;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshUser = useCallback(() => {
        setUser(authService.loadUser());
    }, []);

    const logout = useCallback(() => {
        authService.clearToken();
        queryClient.removeQueries();
        setUser(null);
    }, [queryClient]);

    useEffect(() => {
        setIsAuthenticated(!!user);
    }, [user]);

    return (
        <AuthContext.Provider
            value={{
                isApplicant: findRole([ERole.APPLICANT]),
                isAuthenticated,
                isClientUnverified: findRole([ERole.CLIENT_UNVERIFIED]),
                isClient: findRole([ERole.CLIENT]),
                isFreelancer: findRole([ERole.FREELANCER]),
                isImpersonal: false, // !!user?.impersonator?.username,  // HOTFIX - enabled everything for Admins
                isImpersonalBorder: !!user?.impersonator?.username,
                user,
                findRole,
                login,
                logout,
                refreshUser
            }}
        >{children}</AuthContext.Provider>
    );
};

const findSome = (arr1: string[], arr2: string[]) => arr1.some(item => arr2.includes(item));

export {AuthContext, AuthContextProvider};
