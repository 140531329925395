import React from 'react';

interface IPillIcon {
    fill?: string;
}

const NgIcon: React.FC<IPillIcon> = ({fill}) => {
    return (
        <svg width="22" height="21" viewBox="0 0 22 21" fill={fill || '#999999'} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6748 18.3724C12.1342 18.4871 11.5736 18.5475 10.9993 18.5475C6.552 18.5475 2.9417 14.9211 2.9417 10.4541C2.9417 9.77769 2.39476 9.22783 1.72085 9.22783C1.04694 9.22783 0.5 9.77769 0.5 10.4541C0.5 16.2749 5.20467 21 10.9993 21C11.747 21 12.4765 20.9215 13.1802 20.7719C13.8395 20.6316 14.2614 19.9802 14.1217 19.318C13.9826 18.6559 13.334 18.2321 12.6748 18.3724ZM12.1347 2.44068C16.0453 2.99495 19.0569 6.37455 19.0569 10.4541C19.0569 13.3402 17.5494 15.8757 15.283 17.3089C14.7126 17.6695 14.5412 18.4273 14.9007 18.9997C15.2596 19.5726 16.0141 19.7448 16.5839 19.3843C19.536 17.5169 21.4986 14.2138 21.4986 10.4541C21.4986 5.13749 17.5724 0.734699 12.476 0.0121815C11.809 -0.0824864 11.1907 0.384955 11.0965 1.05499C11.0022 1.72502 11.4676 2.34601 12.1347 2.44068ZM3.52136 7.43552C4.38475 5.28268 6.14375 3.58699 8.33347 2.81493C8.96929 2.59077 9.30378 1.89032 9.08061 1.25168C8.85744 0.613042 8.1601 0.276566 7.52477 0.500728C4.67238 1.50627 2.38158 3.71502 1.25644 6.51925C1.00495 7.14709 1.30772 7.86225 1.9328 8.11486C2.55787 8.36796 3.26987 8.06337 3.52136 7.43552Z"/>
        </svg>
    );
};

export default NgIcon;
