import React, {FC} from 'react';

import {Box} from '@mui/material';

import {useAnimProgress, useAuth} from '../../../hooks';
import {IDashboard} from '../../../models';
import theme from '../../../theme';
import {formatCurrency} from '../../../utils';
import {WhiteWrapperBox} from '../DashboardLayout';

import {OverviewBox} from './styles';

interface IProps {
    data?: IDashboard;
}

const Overview: FC<IProps> = ({data}) => {
    const {isClient} = useAuth();

    const activeEngagements = useAnimProgress(data?.activeEngagements || 0);
    const monthlyMoney = useAnimProgress(data?.monthlyMoney || 0);
    const totalMatches = useAnimProgress(data?.totalMatches || 0);
    const totalMoney = useAnimProgress(data?.totalMoney || 0);

    return (
        <WhiteWrapperBox>
            <p className="title">Overview</p>

            <Box
                sx={{
                    display: 'grid',
                    gap: '24px',
                    [theme.breakpoints.up('sm')]: {
                        gridTemplateColumns: 'repeat(2, 1fr)'
                    },
                    [theme.breakpoints.up(1350)]: {
                        gridTemplateColumns: 'repeat(4, 1fr)',
                    }
                }}
            >
                <OverviewBox className="purple">
                    <div className="icon"></div>
                    <p className="amount">
                        {formatCurrency(totalMoney)}
                    </p>
                    <p className="caption">
                        Total money {isClient ? 'spent' : 'earned'}
                    </p>
                </OverviewBox>
                <OverviewBox className="blue">
                    <div className="icon"></div>
                    <p className="amount">
                        {formatCurrency(monthlyMoney)}
                    </p>
                    <p className="caption">
                        Money {isClient ? 'spent' : 'earned'} this month
                    </p>
                </OverviewBox>
                <OverviewBox className="green">
                    <div className="icon"></div>
                    <p className="amount">
                        {totalMatches}
                    </p>
                    <p className="caption">
                        Total matches
                    </p>
                </OverviewBox>
                <OverviewBox className="gray">
                    <div className="icon"></div>
                    <p className="amount">
                        {activeEngagements}
                    </p>
                    <p className="caption">
                        Active engagements
                    </p>
                </OverviewBox>
            </Box>

        </WhiteWrapperBox>
    );
};

export default React.memo(Overview);
