import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Avatar, IconButton} from '@mui/material';

import {PhoneIcon, VideoCallIcon} from '../../../../assets/icons';
import {RouteClientPortal, RouteFreelancerPortal, VIDEO_CALL} from '../../../../constants';
import {useAuth, useTwilio} from '../../../../hooks';
import {ECallType, ICall, IConversationMeta} from '../../../../models';
import theme from '../../../../theme';
import {getNameLetters, stringToColor} from '../../../../utils';

import {ChatOngoingCallBox} from './ChatOngoingCallBox';


interface IncomingListItemProps {
    data: ICall;
}

const IncomingListItem: React.FC<IncomingListItemProps> = ({data}) => {
    const {isClient} = useAuth();
    const {videoCallRoomId} = useParams();
    const {conversations} = useTwilio();
    const conversation = conversations.find(it => it.sid === data.conversationId);
    const meta = (conversation as any)?.meta as IConversationMeta;
    const navigate = useNavigate();

    const handleCallStart = () => {
        const path = `${isClient ? RouteClientPortal.inbox : RouteFreelancerPortal.inbox}/${data.conversationId}`;
        const audio = data.type === ECallType.CALLING_VIDEO ? '' : '/audio';

        navigate(`${path}/${VIDEO_CALL}${audio}`);
    };

    return (
        <ChatOngoingCallBox
            // onClick={() => activeCall && navigate(activeCall)}
        >
            <Avatar
                alt={meta?.remoteParticipant.name}
                src={meta?.remoteParticipant.avatar}
                sx={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: stringToColor(meta?.remoteParticipant.name),
                    fontSize: '11px',
                    lineHeight: 1.2,
                    fontWeight: 500,
                    'img': {
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        aspectRatio: '1/1'
                    }
                }}
            >
                {getNameLetters(meta?.remoteParticipant.name)}
            </Avatar>
            <p>
                <span style={{fontWeight: 600}}>{meta.remoteParticipant.name}</span> &nbsp;&nbsp;
                Incoming {data.type === ECallType.CALLING_VIDEO ? 'video' : 'audio'} call
            </p>

            <IconButton
                disabled={!!videoCallRoomId}
                sx={{
                    p: '10px',
                    color: theme.palette.white.main,
                    backgroundColor: theme.palette.blue.dark,
                    'svg': {
                        width: '20px',
                        height: '20px',
                    },
                    'path': {
                        fill: 'currentColor'
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.blue.dark,
                        opacity: 0.9
                    }
                }}
                onClick={handleCallStart}
            >
                {data.type === ECallType.CALLING_VIDEO ? <VideoCallIcon/> : <PhoneIcon/>}
            </IconButton>

        </ChatOngoingCallBox>
    );
};

export default React.memo(IncomingListItem);
