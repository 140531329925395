import React, {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {any, date, object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Grid, SxProps, Theme, Typography, useMediaQuery} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {freelancerService} from '../../../../../../../api';
import {PausedWorkStatusIcon} from '../../../../../../../assets/icons';
import {
    AnimateHeight,
    BlackBorderButton,
    Button,
    ContinueButton,
    DialogEmoji,
    DialogSuccess,
    Input,
    InputCaption,
    InputDate,
    Radio,
    showToastError
} from '../../../../../../../components';
import {FREELANCER_ENGAGEMENT} from '../../../../../../../constants';
import {useAuth} from '../../../../../../../hooks';
import {EEngagementStatus, EPauseStatus, EWorkType, IEngagement, IPause} from '../../../../../../../models';
import theme from '../../../../../../../theme';
import {localToUTC} from '../../../../../../../utils';

const REASON_MAX_LENGTH = 255;

enum EReasons {
    Vacation = 'Vacation',
    SickLeave = 'Sick leave',
    MaternityLeave = 'Maternity leave',
    Other = 'Other',
}

export const pauseSchema = object({
    startDate: date({
        required_error: 'Start Date is required',
        invalid_type_error: 'Start Date is required',
    }),
    endDate: any(),

    reason: string()
        .min(1, 'Reason is required'),
    reasonOther: any()

})
    .refine((data) => !!data.startDate, {
        path: ['startDate'],
        message: 'Start Date is required'
    })
    .refine((data) => {
        const {startDate, endDate} = data;

        if (startDate && endDate) {
            return endDate.getTime() > startDate.getTime();
        }

        return true;
    }, {
        path: ['endDate'],
        message: 'End Date should be later than the Start Date'
    })
    .refine((data) => {
        const {reason, reasonOther} = data;

        if (reason === EReasons.Other && !reasonOther.length) {
            return false;
        }

        return true;
    }, {
        path: ['reasonOther'],
        message: 'Other is required'
    });

type PauseInput = TypeOf<typeof pauseSchema>;

interface PauseEngagementBtnProps {
    data: IEngagement;
    fullWidth?: boolean;
    sx?: SxProps;
}

const PauseEngagementBtn: React.FC<PauseEngagementBtnProps> = ({data, ...props}) => {
    const {isImpersonal} = useAuth();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const queryClient = useQueryClient();

    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const methods = useForm<PauseInput>({
        resolver: zodResolver(pauseSchema),
    });

    const {
        handleSubmit,
        formState: {isDirty, errors},
        getValues,
        reset,
        setValue,
        trigger,
        watch
    } = methods;

    const reason = watch('reason');
    const reasonOther = watch('reasonOther');
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const isOther = reason === EReasons.Other;

    const minEndDate = addDays(startDate, 1);
    const maxEndDate = addMonths(addDays(startDate, -1), 1);

    const {mutate, isLoading: isSubmitting} = useMutation(
        (payload: IPause) => freelancerService.pauseSubmit(data.id, payload),
        {
            onSuccess(response) {
                reset({});
                setOpen(false);
                setOpenSuccess(true);
            },
            onError(error: AxiosError) {
                showToastError(error);
            },
        }
    );

    const handleClose = useCallback(() => {
        reset({});
        setOpen(false);
    }, [reset]);

    const handleReasonChange = (value: EReasons) => {
        setValue('reason', value);
        trigger('reason');
    };

    const handleSuccessClose = useCallback((isNeedUpdate: boolean) => {
        setOpenSuccess(false);
        queryClient.invalidateQueries([FREELANCER_ENGAGEMENT, data.id]);
    }, [data.id, queryClient]);

    const onSubmitHandler: SubmitHandler<PauseInput> = () => {
        const {startDate, endDate, reason, reasonOther} = getValues();

        const payload: IPause = {
            startDate: localToUTC(startDate).toISOString().split('T')[0],
            reason: reasonOther || reason,
        };

        if (endDate) {
            payload.endDate = localToUTC(endDate).toISOString().split('T')[0];
        }

        mutate(payload);
    };

    // erase endDate if user clean startDate
    useEffect(() => {
        if (!startDate && endDate) {
            setValue('endDate', null, {shouldValidate: true});
        }
    }, [startDate, endDate, setValue]);

    // show only for the Active and if not waiting for accepting
    if (
        data.budgetType === EWorkType.fixed     // From a Billing perspective, it makes no sense to pause fixed-type engagements.
        || data.status !== EEngagementStatus.ACTIVE
        || (data.pause ? ![EPauseStatus.CANCELLED, EPauseStatus.CLIENT_REJECTED].includes(data.pause?.status as EPauseStatus) : false)
    ) {
        return null;
    }

    return (
        <>
            <Button
                {...props}
                color="secondary"
                disabled={isImpersonal}
                size="small"
                variant="contained"
                onClick={() => setOpen(true)}
            >
                <PausedWorkStatusIcon/>Pause engagement
            </Button>

            <DialogEmoji
                actions={(
                    <>
                        <BlackBorderButton
                            disabled={isSubmitting}
                            sx={{
                                width: mdDown ? '50%' : 'auto',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </BlackBorderButton>

                        <ContinueButton
                            disabled={isSubmitting || !isDirty}
                            sx={{
                                width: mdDown ? '50% !important' : 'auto !important',
                                'div': {
                                    justifyContent: 'center'
                                }
                            }}
                            variant="contained"
                            onClick={handleSubmit(onSubmitHandler)}
                        >
                            Confirm
                        </ContinueButton>
                    </>
                )}
                maxWidth="sm"
                maxWidthPx={574}
                open={open}
                title={(
                    <>
                        <Box justifyContent="center">
                            <img src="/assets/images/pause-icon.png" width="48" height="48" alt="sad icon"/>
                        </Box>
                        Pause engagement
                    </>
                )}
                onClose={handleClose}
            >
                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={mdDown ? 2 : 4}>

                            <Grid item xs={6}>
                                <InputCaption
                                    sx={{
                                        textAlign: 'left !important',
                                        mb: '16px !important'
                                    }}
                                >
                                    Start date
                                </InputCaption>
                                <InputDate
                                    disabled={isSubmitting}
                                    disablePast
                                    name="startDate"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputCaption
                                    sx={{
                                        textAlign: 'left !important',
                                        mb: '16px !important',
                                        opacity: startDate ? 1 : 0.5,
                                        '& span': {
                                            color: theme.palette.gray.main,
                                            fontSize: 12,
                                        }
                                    }}
                                >
                                    End date <span>(Max 1 month)</span>
                                </InputCaption>
                                <InputDate
                                    disabled={!startDate || isSubmitting}
                                    maxDate={maxEndDate}
                                    minDate={minEndDate}
                                    name="endDate"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Radio
                                        color={reason === EReasons.Vacation ? 'success' : 'primary'}
                                        checked={reason === EReasons.Vacation}
                                        label="Vacation"
                                        onClick={() => handleReasonChange(EReasons.Vacation)}
                                    />
                                    <Radio
                                        color={reason === EReasons.SickLeave ? 'success' : 'primary'}
                                        checked={reason === EReasons.SickLeave}
                                        label="Sick leave"
                                        onClick={() => handleReasonChange(EReasons.SickLeave)}
                                    />
                                    <Radio
                                        color={reason === EReasons.MaternityLeave ? 'success' : 'primary'}
                                        checked={reason === EReasons.MaternityLeave}
                                        label="Maternity leave"
                                        onClick={() => handleReasonChange(EReasons.MaternityLeave)}
                                    />
                                    <Radio
                                        color={reason === EReasons.Other ? 'success' : 'primary'}
                                        checked={reason === EReasons.Other}
                                        label="Other"
                                        onClick={() => handleReasonChange(EReasons.Other)}
                                    />
                                </Box>
                                <AnimateHeight isVisible={!!errors.reason}>
                                    <Typography color="error" variant="caption">Reason is required</Typography>
                                </AnimateHeight>
                            </Grid>

                            <Grid item xs={12} sx={{pt: isOther ? '32px' : '16px!important'}}>
                                <AnimateHeight isVisible={!!isOther}>
                                    <Input
                                        counter={REASON_MAX_LENGTH - (reasonOther?.length || 0)}
                                        disabled={isSubmitting}
                                        inputProps={{maxLength: REASON_MAX_LENGTH}}
                                        minRows={5}
                                        multiline
                                        name="reasonOther"
                                        placeholder="If other, please let us know..."
                                        variant="outlined"
                                    />
                                </AnimateHeight>
                            </Grid>


                            <Grid item xs={12} sx={{paddingTop: '0px !important'}}>
                                <Typography
                                    paragraph
                                    sx={{
                                        color: theme.palette.gray.main,
                                        mb: '24px',
                                        [theme.breakpoints.up('md')]: {
                                            mb: '40px'
                                        }
                                    }}
                                    variant="caption"
                                >
                                    Billing stops the day the client accepts your engagement pause request.
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </DialogEmoji>

            {/* Your pause engagement request has been successfully submitted */}
            <DialogSuccess
                open={openSuccess}
                subtitle={<>
                    It may take the client up to 2 business days to approve your request.<br/>
                    In other case, it will be approved automatically.
                </>}
                title="Your pause engagement request has been successfully submitted"
                onClose={handleSuccessClose}
            />
        </>
    );
};

export default React.memo(PauseEngagementBtn);
