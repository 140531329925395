import React, {useMemo} from 'react';
import capitalize from 'lodash/capitalize';
import {useNavigate} from 'react-router-dom';

import {Avatar, Box, styled, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {freelancerService} from '../../../../../api';
import {CheckboxIcon, CloseIcon, LocationIcon} from '../../../../../assets/icons';
import {BlackBorderButton, FoldingText, ImagePlaceholder} from '../../../../../components';
import Pill from '../../../../../components/Pill';
import {ACCOUNT, FREELANCER, FREELANCER_PROFILE, LG, RouteFreelancerPortal} from '../../../../../constants';
import {EModerationStatus, IBrand} from '../../../../../models';
import theme from '../../../../../theme';
import {formatCurrency, getNameLetters, stringToColor} from '../../../../../utils';

const Wrp = styled('div')`
  display: none;

  @media (min-width: ${LG}) {
    display: block;
    width: 528px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 24px;
    border-radius: 24px;
    overflow: hidden;
    background-color: ${theme.palette.white.main};

    &::before {
      content: '';
      display: block;
      height: 88px;
      background-image: linear-gradient(90deg, #CBDAEE 0%, #E2E6F5 26.04%, #E5E7F4 52.08%, #DEDCE8 71.87%, #E1D1E1 86.46%, #E2C7DC 100%);
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`;

const OpenWorkItem = styled('div')<{ isOpenToWork?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.isOpenToWork ? theme.palette.black.main : theme.palette.gray.main};

  &:last-of-type {
    margin-bottom: 0;
  }

  svg, .close-icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  path {
    fill: ${props => props.isOpenToWork ? theme.palette.purple.main : theme.palette.gray.main}
  }
`;

const RateBox = styled('div')`
  grid-area: rateRange;
  align-self: center;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 50px 0 0 50px;
  margin-right: -24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.purple.main};
  background-color: ${theme.palette.lilac.main};
`;

const Row = styled('div')<{ isFlex?: boolean }>`
  display: ${props => props.isFlex ? 'flex' : 'block'};
  padding: 16px 24px;
  border-top: 1px solid ${theme.palette.lightGray.main};
  justify-content: space-between;
  gap: 8px;
`;

const Title = styled('p')<{ mt?: boolean }>`
  margin-top: ${props => props.mt ? '10px' : 0};
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.black.main};
`;

const CompaniesList = styled('ul')`
  margin: 0;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  list-style: none;
  padding: 0;

  li {
    display: grid;
    gap: 8px;
    grid-auto-rows: 40px auto;
    justify-items: center;
    max-width: calc(25% - 17px);
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${theme.palette.black.main};
  }

  img {
    display: block;
    width: 40px;
    height: auto;
    align-self: center;
    justify-self: center;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
  }
`;

const PillsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  max-width: 322px;
`;

const avatarStyles = {
    mt: '-24px',
    mb: '11px',
    ml: '-5px',
    width: '90px',
    height: '90px',
    backgroundColor: stringToColor('You'),
    fontSize: '30px',
    lineHeight: 1.2,
    fontWeight: 500,
    border: '5px solid',
    borderColor: theme.palette.white.main,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    }
};

const MyProfileShort = () => {
    const navigate = useNavigate();
    const {
        data: info,
    } = useQuery([FREELANCER_PROFILE], freelancerService.getProfile, {
        staleTime: 60 * 1000,
        select: ((data) => data)
    });

    const {
        data: freelancer,
    } = useQuery([FREELANCER], freelancerService.getFleelancer, {
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const brandsApproved = useMemo(() => {
        return info?.brands?.filter(brand => brand.moderationStatus === EModerationStatus.APPROVED);
    }, [info?.brands]);

    const renderRate = useMemo(() => {
        if (info?.fixedRate) {
            return formatCurrency(info.fixedRate);
        } else {
            return info?.rateRange.map(it => formatCurrency(it)).join(' - ');
        }
    }, [info]);

    return (
        <Wrp>
            <Box
                sx={{
                    [theme.breakpoints.up('lg')]: {
                        p: '0 24px 28px',
                        display: 'grid',
                        gridTemplateAreas: "'avatar nameAndExperience rateRange'" +
                            "'avatar location rateRange'",
                        gridTemplateColumns: '93px auto auto',
                    }
                }}
            >
                <Avatar
                    alt={`${freelancer?.firstName} ${freelancer?.lastName}`}
                    className="avatar"
                    src={freelancer?.avatar}
                    sx={{
                        ...avatarStyles,
                        backgroundColor: stringToColor(`${freelancer?.firstName} ${freelancer?.lastName}`),
                        gridArea: 'avatar'
                    }}
                >
                    {getNameLetters(`${freelancer?.firstName} ${freelancer?.lastName}`)}
                </Avatar>
                <Box
                    sx={{
                        gridArea: 'nameAndExperience',
                        [theme.breakpoints.up('lg')]: {
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            mt: '24px',
                            mb: '2px'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: 1.5,
                            color: theme.palette.black.main,
                            pr: '8px',
                            mr: '8px',
                            [theme.breakpoints.up('sm')]: {
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    right: 0,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    width: '1px',
                                    height: '16px',
                                    backgroundColor: theme.palette.lightGray.main
                                }
                            }
                        }}
                    >
                        {freelancer?.firstName} {freelancer?.lastName}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '21px',
                            color: theme.palette.black.main,
                            'span': {
                                font: 'inherit',
                                lineHeight: 'inherit',
                                color: theme.palette.gray.main
                            }
                        }}
                    >
                        {info?.yearsOfExperience} year{info && info?.yearsOfExperience > 1 ? 's' : ''}
                        &nbsp;
                        <span>of experience in marketing</span>
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        gridArea: 'location',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: theme.palette.gray.main,
                    }}
                >
                    <LocationIcon/>
                    {info?.country}
                </Typography>
                <RateBox>
                    {renderRate}/hour
                </RateBox>
            </Box>
            <Box sx={{p: '0 24px 24px'}}>
                <BlackBorderButton
                    fullWidth
                    size="small"
                    onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(`${RouteFreelancerPortal.profileSettings}/${ACCOUNT}`);
                    }}
                >
                    Edit profile
                </BlackBorderButton>
            </Box>
            <Row>
                <FoldingText
                    text={info?.bio}
                />

                <Box sx={{mt: '24px'}}>
                    <OpenWorkItem isOpenToWork={info?.isAvailableForNewProject}>
                        {
                            info?.isAvailableForNewProject ? (
                                <><CheckboxIcon/>Open to working with agencies</>
                            ) : (
                                <><CloseIcon/>Not open to working with agencies</>
                            )
                        }
                    </OpenWorkItem>
                    <OpenWorkItem isOpenToWork={info?.isOpenToHireRole}>
                        {
                            info?.isOpenToHireRole ? (
                                <><CheckboxIcon/>Open to contract to hire roles</>
                            ) : (
                                <><CloseIcon/>Not open to contract to hire roles</>
                            )
                        }
                    </OpenWorkItem>
                </Box>
            </Row>
            <Row>
                <Title>I’ve worked in</Title>
                <CompaniesList>
                    {
                        brandsApproved?.map((brand: IBrand) => (
                            <li key={brand.id}
                            >
                                <ImagePlaceholder width="40" height="40">
                                    {brand.logo && (
                                        <img src={brand.logo} width="40" height="40" alt={brand.name}/>
                                    )}

                                </ImagePlaceholder>
                                {brand.name}
                            </li>
                        ))
                    }
                </CompaniesList>
            </Row>
            <Row isFlex>
                <Title mt>Channels</Title>
                <PillsWrapper>
                    {
                        info?.channels?.map(channel => (
                            <Pill
                                key={channel.id}
                                variant="gray"
                            >
                                {channel.name}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </Row>
            <Row isFlex>
                <Title mt>Verticals</Title>
                <PillsWrapper>
                    {
                        info?.verticals?.map(vertical => (
                            <Pill
                                key={vertical.id}
                                variant="gray"
                            >
                                {vertical.name}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </Row>
            <Row isFlex>
                <Title mt>Languages</Title>
                <PillsWrapper>
                    {
                        info?.languages?.map(language => (
                            <Pill
                                key={language}
                                variant="gray"
                            >
                                {language}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </Row>
            <Row isFlex>
                <Title mt>Tools</Title>
                <PillsWrapper>
                    {
                        info?.tools?.map(tool => (
                            <Pill
                                key={tool.id}
                                variant="gray"
                            >
                                {tool.name}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </Row>
            <Row isFlex>
                <Title mt>Budget type</Title>
                <PillsWrapper>
                    {
                        info?.workTypes?.map(workType => (
                            <Pill
                                key={workType}
                                variant="gray"
                            >
                                {capitalize(workType)}
                            </Pill>
                        ))
                    }
                </PillsWrapper>
            </Row>
        </Wrp>
    );
};

export default React.memo(MyProfileShort);
