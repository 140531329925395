import {any, object, string} from 'zod';

import {MAX_FILE_SIZE, MAX_FILE_SIZE_MB} from '../constants';
import {ECaseStudySteps, ICaseStudy, ICoverImage} from '../models';

import {getTextLength} from './utils';

export const CASE_STUDY_MAX_TEXT_LENGTH = 1000;
export const CASE_STUDY_SHORT_SUMMARY_MAX_TEXT_LENGTH = 500;
export const CASE_STUDY_MAX_REVIEW_LENGTH = 1000;

// STEP 1
export const descriptionSchema = object({
    name: string()
        .trim()
        .min(1, 'Name is required')
        .max(120, 'Name is too long'),
    brief: string()
        .trim()
        .min(1, 'Description is required')
        .refine((text) => text?.length ? getTextLength(text || '') <= 100 : true,
            'Description is too long'
        ),
    coverImage: string()
        .min(1, 'Cover image is required')
}).refine(({coverImage}) => {
    const json: ICoverImage = coverImage ? JSON.parse(coverImage) : {};

    return json.variant;
}, {
    path: ['coverImageVariant'],
    message: 'Image is required',
});

// STEP 2
export const companySchema = object({
    companyName: string()
        .trim()
        .min(1, 'Company Name is required')
        .max(100, 'Company Name is too long'),
    companyLogoUrl: any(),
    fileCompanyLogo: any()
        .refine((files) => files?.length ? (files?.[0]?.size <= MAX_FILE_SIZE_MB) : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        ),
});

// STEP 3
export const challengeSchema = object({
    challenge: string()
        .trim()
        .min(1, 'Challenge is required')
        .refine((text) => text?.length ? getTextLength(text || '') <= CASE_STUDY_MAX_TEXT_LENGTH : true,
            `Max length is ${CASE_STUDY_MAX_TEXT_LENGTH}.`
        ),
    solution: string()
        .trim()
        .min(1, 'Solution is required')
        .refine((text) => text?.length ? getTextLength(text || '') <= CASE_STUDY_MAX_TEXT_LENGTH : true,
            `Max length is ${CASE_STUDY_MAX_TEXT_LENGTH}.`
        ),
    summary: any()
        .refine((text) => text?.length ? getTextLength(text || '') <= CASE_STUDY_MAX_TEXT_LENGTH : true,
            `Max length is ${CASE_STUDY_MAX_TEXT_LENGTH}.`
        ),
});

// STEP 4
export const outcomesSchema = object({
    outcomes: object({
        description: string()
            .trim()
            .min(1, 'Title is required')
            .max(100, 'Title is too long'),
        value: string()
            .refine((val: string) => {
                const value = val.toLowerCase();

                if (value.length < 1) return false;

                return true;
            })
    }).array().min(2),
});

// STEP 5
export const testimonialSchema = object({
    clientsName: any()
        .refine(name => !name || name.length < 100, 'Name is too long'),
    clientsPosition: any()
        .refine(position => !position || position.length < 100, 'Position is too long'),
    clientsReview: any()
        .refine((text) => text?.length ? text.replaceAll('\r', '').length <= CASE_STUDY_MAX_REVIEW_LENGTH : true,
            `Max length is ${CASE_STUDY_MAX_REVIEW_LENGTH}.`
        ),
});

export const stepsOrder = [ECaseStudySteps.description, ECaseStudySteps.company, ECaseStudySteps.challenge, ECaseStudySteps.outcomes, ECaseStudySteps.testimonial];

// Return completed step
export const getCaseStudyStep = (caseStudy: ICaseStudy) => {
    const {brief, challenge, clientsReview, companyName, coverImage, name, outcomes, solution} = caseStudy;
    const {length, [length - 1]: last} = stepsOrder;

    if (!name || !brief || !coverImage) {
        return {
            completedIndex: stepsOrder.indexOf(ECaseStudySteps.description),
            view: ECaseStudySteps.description
        };
    } else if (!companyName) {
        return {
            completedIndex: stepsOrder.indexOf(ECaseStudySteps.company),
            view: ECaseStudySteps.company
        };
    } else if (!challenge || !solution) {
        return {
            completedIndex: stepsOrder.indexOf(ECaseStudySteps.challenge),
            view: ECaseStudySteps.challenge
        };
    } else if (outcomes.length < 2) {
        return {
            completedIndex: stepsOrder.indexOf(ECaseStudySteps.outcomes),
            view: ECaseStudySteps.outcomes
        };
    } else if (clientsReview && !clientsReview.trim()) {
        return {
            completedIndex: stepsOrder.indexOf(ECaseStudySteps.testimonial),
            view: ECaseStudySteps.testimonial
        };
    }

    return {
        completedIndex: stepsOrder.length - 1,
        view: last
    };
};
