import React, {useEffect, useMemo, useRef, useState} from 'react';

import {Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {Box} from '@mui/system';

import {useAuth} from '../../../hooks';
import {EWorkWeekStatus, IWorkWeek} from '../../../models';
import DialogApprove
    from '../../../modules/FreelancerPortal/MyWorkDetails/components/TabTimesheets/components/DialogApprove';
import DialogReject
    from '../../../modules/FreelancerPortal/MyWorkDetails/components/TabTimesheets/components/DialogReject';
import theme from '../../../theme';
import {formatCurrency, formatDate, formatHoursFromDuration} from '../../../utils';
import {CollapsibleBox, ContinueButton, RedFilledButton, TimesheetHeading, WorkDayGrid} from '../../index';

type WeekViewProps = {
    data: IWorkWeek;
    isAutoFocus?: boolean;
};

const WeekView: React.FC<WeekViewProps> = ({data, isAutoFocus}) => {
    const {isClient} = useAuth();
    const [collapsed, setCollapsed] = useState(isClient && data.status === EWorkWeekStatus.SENT ? false : true); // Collapse all by default, except required approving by Client
    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);

    const elementRef = useRef<HTMLDivElement | null>(null);
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const renderButtons = useMemo(() => {
        if (isClient && data.status === EWorkWeekStatus.SENT) {
            return (
                <Stack direction="row" spacing="8px">
                    <RedFilledButton
                        size="small"
                        sx={{
                            width: mdUp ? 'auto !important' : '50% !important'
                        }}
                        onClick={() => setOpenReject(true)}
                    >
                        Reject
                    </RedFilledButton>
                    <ContinueButton
                        size="small"
                        sx={{
                            width: mdUp ? 'auto !important' : '50% !important',
                            'div': {
                                justifyContent: 'center'
                            }
                        }}
                        variant="contained"
                        onClick={() => setOpenApprove(true)}
                    >
                        Approve
                    </ContinueButton>
                </Stack>
            );
        }

        return null;
    }, [data.status, isClient, mdUp]);

    useEffect(() => {
        if (!isAutoFocus || !elementRef.current) return;

        setCollapsed(false);

        setTimeout(() => {
            elementRef.current?.scrollIntoView
                ? elementRef.current.scrollIntoView({behavior: 'smooth'})
                : elementRef.current?.focus();
        }, 1000);
    }, [isAutoFocus]);

    return (
        <>
            <CollapsibleBox
                titleContent={
                    <TimesheetHeading
                        actions={renderButtons}
                        endDate={formatDate(data.toDate)}
                        hoursSum={formatHoursFromDuration(data.workTime)}
                        isCollapsed={collapsed}
                        moneyAmount={formatCurrency(data.money || 0, true)}
                        startDate={formatDate(data.fromDate)}
                        status={data.status as EWorkWeekStatus}
                    />
                }
                isCollapsed={collapsed}
                onCollapseClick={() => setCollapsed(!collapsed)}
            >

                <Box sx={{mt: '8px'}}>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '13px',
                            lineHeight: 1.5,
                            color: theme.palette.gray.main
                        }}
                    >
                        {data.workDescription}
                    </Typography>
                    <Stack
                        direction="column"
                        sx={{
                            mt: '8px',
                            p: '24px',
                            borderRadius: '24px',
                            backgroundColor: theme.palette.blue.light,
                        }}
                    >
                        {data.workDays.map((item, index) => (
                            <WorkDayGrid
                                ref={data.workDays.length === index + 1 ? elementRef : null}
                                key={item.id}
                                date={item.date}
                                workDescription={item.workDescription}
                                workTime={item.workTime}
                            />
                        ))}
                    </Stack>
                </Box>
            </CollapsibleBox>

            {/* Client should confirm approving a Timesheets */}
            <DialogApprove
                open={openApprove}
                timesheetId={data.id || 0}
                onClose={setOpenApprove}
            />

            {/* Client can reject Timesheets */}
            <DialogReject
                open={openReject}
                timesheetId={data.id || 0}
                onClose={setOpenReject}
            />
        </>
    );
};

export default React.memo(WeekView);
