import React, {useCallback, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {styled} from '@mui/material';

import {TabsStatusFilter} from '../../../../../components';
import {RouteClientPortal} from '../../../../../constants';
import {useConversation} from '../../../../../hooks';
import {EWorkType, IEngagementClient} from '../../../../../models';
import theme from '../../../../../theme';
import {EDisplayMode} from '../../MyHiresDetailsPage';

const BadgeCounter = styled('span')`
  display: inline-block;
  padding: 2px 6px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.palette.white.main};
  background-color: ${theme.palette.error.main};
  margin-left: 8px;
`;

interface TabsProps {
    data: IEngagementClient;
}

const Tabs: React.FC<TabsProps> = ({data}) => {
    const {engagementId, tab} = useParams();
    const {meta} = useConversation(data.conversationSid);
    const navigate = useNavigate();

    const tabs = useMemo(() => {
        const result = [
            {
                text: 'Overview',
                value: EDisplayMode.Overview
            },
            {
                badge: meta?.unreadMessagesCount ? <BadgeCounter>{meta?.unreadMessagesCount} new</BadgeCounter> : null,
                text: 'Messages',
                value: EDisplayMode.Messages,
            },
            // {
            //     text: 'Reports',
            //     value: EDisplayMode.Reports
            // }
        ];

        if (data?.budgetType === EWorkType.hourly) {
            result.splice(1, 0, {
                text: 'Timesheets',
                value: EDisplayMode.Timesheets
            });
        }

        result.push();

        return result;
    }, [data, meta?.unreadMessagesCount]);

    const handleTabChange = useCallback((tab: string) => {
        navigate(`${RouteClientPortal.myHireDetails}/${engagementId}/${tab}`);
    }, [engagementId, navigate]);

    return (
        <TabsStatusFilter
            items={tabs}
            value={tab || EDisplayMode.Overview}
            onChange={handleTabChange}
        />
    );
};

export default React.memo(Tabs);
