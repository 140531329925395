import React, {ReactNode} from 'react';

import {styled} from '@mui/material';

import {CheckboxIcon} from '../../assets/icons';
import theme from '../../theme';

const PillWrp = styled('div')<{ hasOutline?: boolean; iconPosition?: 'start' | 'end'; size?: 'small' | 'default' }>`
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: ${props => props.iconPosition === 'start' ? 'row-reverse' : 'row'};
  align-items: center;
  gap: 8px;
  padding: ${props => props.size === 'small' ? '6px' : '10px'};
  border-color: ${theme.palette.lightGray.light};
  border: 2px solid;
  border-radius: 50px;
  font-weight: 400;
  font-size: ${props => props.size === 'small' ? '10px' : '14px'};
  line-height: 16px;
  color: ${theme.palette.black.main};
  background-color: ${theme.palette.lightGray.light};

  &.green {
    background-color: ${theme.palette.green.light};
    border-color: ${props => props.hasOutline ? theme.palette.green.dark : theme.palette.green.light};
  }

  &.lilac, &.lilac-text {
    background-color: ${theme.palette.lilac.main};
    border-color: ${theme.palette.lilac.main};
  }

  &.lilac-text {
    color: ${theme.palette.purple.main};
  }

  &.blue {
    background-color: ${theme.palette.blue.light};
    border-color: ${theme.palette.blue.light};
  }

  &.light-blue {
    font-weight: 500;
    color: ${theme.palette.blue.dark};
    background-color: ${theme.palette.blue.light};
    border-color: ${theme.palette.blue.light};

    path {
      fill: currentColor;
    }
  }

  &.gray {
    background-color: ${theme.palette.lightGray.light};
    border-color: ${theme.palette.lightGray.light};

    svg {
      path {
        fill: ${theme.palette.black.main}
      }
    }
  }

  &.white {
    background-color: ${theme.palette.white.main};
    border-color: ${theme.palette.white.main};
    color: ${theme.palette.textGray.dark};
    font-weight: 500;
  }

  &.purple {
    background-color: ${theme.palette.purple.light};
    border-color: ${theme.palette.purple.light};
    color: ${theme.palette.purple.main};
    font-weight: 500;
  }

  &.red {
    background-color: ${theme.palette.lightRed.main};
    border-color: ${props => props.hasOutline ? theme.palette.error.main : theme.palette.lightRed.main};

    .close-icon {
      width: 16px;
      height: 16px;
    }

    svg {
      path {
        fill: ${theme.palette.error.main}
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    path {
      fill: ${theme.palette.green.dark}
    }
  }
`;

interface IPill {
    children: React.ReactNode;
    hasIcon?: boolean;
    hasOutline?: boolean;
    icon?: ReactNode;
    iconPosition?: 'start' | 'end';
    size?: 'small' | 'default';
    variant: 'green' | 'lilac' | 'lilac-text' | 'blue' | 'light-blue' | 'gray' | 'white' | 'purple' | 'red';
}

const Pill: React.FC<IPill> = ({children, hasIcon, hasOutline, icon, iconPosition = 'end', size, variant}) => {
    return (
        <PillWrp hasOutline={hasOutline} className={variant} iconPosition={iconPosition} size={size}>
            {children}
            {hasIcon && (icon || <CheckboxIcon/>)}
        </PillWrp>
    );
};

export default React.memo(Pill);
