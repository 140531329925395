import React, {useMemo} from 'react';

import {Box, styled, Typography} from '@mui/material';

import {MD, RouteClientPortal} from '../../../constants';
import {EWorkTypeDisplay, IFreelancerMatchList, IRoleWithMatching} from '../../../models';
import theme from '../../../theme';
import {formatCurrency} from '../../../utils';
import {ContinueButton, ImagePlaceholder} from '../../index';

const Wrapper = styled('div')`
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 24px;
  background-color: ${theme.palette.blue.light};

  @media (min-width: ${MD}) {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`;

const Title = styled('p')`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;

  @media (min-width: ${MD}) {
    white-space: normal;
    overflow: auto;
  }
`;

const Caption = styled('span')<{ bolder?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-weight: ${props => props.bolder ? '600' : '400'};
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.bolder ? theme.palette.black.main : theme.palette.gray.main};
  position: relative;

  &::after {
    content: '';
    width: 4px;
    height: 4px;
    margin-right: 4px;
    margin-left: 8px;
    border-radius: 50%;
    background-color: ${theme.palette.gray.main};
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }

  @media (min-width: ${MD}) {
    display: inline-flex;
    font-size: 13px;
  }
`;

export const ClientMatch = React.memo(({data}: { data: IRoleWithMatching }) => {
    return (
        <Wrapper>
            <Box>
                <Box sx={{minWidth: 0}}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: 1.5,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            wordBreak: 'break-word',
                            [theme.breakpoints.up('md')]: {
                                overflow: 'auto',
                                whiteSpace: 'unset'
                            }
                        }}
                    >
                        We found a great match for your role &quot;{data.projectName}&quot;. Don’t miss out - see your
                        matches now to get growing.
                    </Typography>
                </Box>

                <ContinueButton
                    href={`${RouteClientPortal.myMatches}/${data.id}`}
                    size="small"
                    sx={{
                        mt: '24px',
                        width: 'auto !important'
                    }}
                    variant="contained"
                >
                    See Matches
                </ContinueButton>
            </Box>
        </Wrapper>
    );
});

export const FreelancerMatch = React.memo(({data}: { data: IFreelancerMatchList }) => {
    const workTypes = useMemo(() => data.workTypes.map(workType => EWorkTypeDisplay[workType]).join(', '), [data]);

    return (
        <Wrapper>
            <ImagePlaceholder
                sx={{
                    width: '40px',
                    height: '40px',
                    mb: '16px',
                    flexShrink: 0,
                    border: `1px solid ${theme.palette.lightGray.main}`,
                    [theme.breakpoints.up('md')]: {
                        width: '64px',
                        height: '64px',
                        mb: 0
                    }
                }}
            >
                <img src={data.companyLogo} width="64" height="64" alt="company logo"/>
            </ImagePlaceholder>
            <Box>
                <Box sx={{minWidth: 0}}>
                    <Title>
                        {data.projectName}
                    </Title>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        mb: '8px',
                        columnGap: '8px',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                >
                    <Caption bolder>{data.companyName}</Caption>
                    {/* <Caption><LocationIcon/> Poland</Caption> */}
                    <Caption>{workTypes}</Caption>
                </Box>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: '16px',
                        color: theme.palette.purple.main,
                        'span': {
                            font: 'inherit',
                            fontWeight: 400
                        }
                    }}
                >
                    {data.rateRange.map(it => formatCurrency(it)).join(' - ')}<span>&nbsp;/hr</span>
                </Typography>
            </Box>
        </Wrapper>
    );
});

export const ShowMoreButton = ({onClick}: { onClick: () => void }) => {
    return (
        <Typography
            color="primary"
            sx={{
                cursor: 'pointer',
                fontSize: 14,
                fontWeight: 500,
                '&:hover': {
                    opacity: 0.9
                }
            }}
            onClick={onClick}
            variant="caption"
        >
            Show more
        </Typography>
    );
};
