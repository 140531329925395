import React, {useCallback} from 'react';
import {AxiosError} from 'axios';
import {Controller, useController, useFormContext} from 'react-hook-form';

import {Box, FormHelperText} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {metaService} from '../../../api';
import {GET_BUSINESS_MODELS} from '../../../constants';
import theme from '../../../theme';
import {AnimateHeight, BusinessModelsList, showToastError} from '../..';

interface InputBusinessModelsProps {
    name: string;
}

export const InputBusinessModels: React.FC<InputBusinessModelsProps> = React.memo(({name}) => {
    const {
        control,
        formState: {errors},
        trigger,
    } = useFormContext();

    const {field: {value, onChange}} = useController({control, name});
    const businessModels: string[] = value;

    const {
        data: businessModelsData
    } = useQuery([GET_BUSINESS_MODELS], metaService.getBusinessModels, {
        staleTime: 15 * 60 * 1000,
        select: (response: { businessModels: string[] }) => response.businessModels,
        onError: (error) => showToastError(error as AxiosError)
    });

    const handleClickBusinessModel = useCallback((model: string) => {
        const result = businessModels?.filter(str => str !== model);

        // unselect by second click
        if (businessModels && result.length !== businessModels?.length) {
            onChange(result);
        } else if (businessModels) {
            onChange([...businessModels, model]);
        } else {
            onChange([model]);
        }

        trigger(name);
    }, [businessModels, name, onChange, trigger]);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({field: {ref}}) => (
                <Box tabIndex={0} ref={ref}>
                    <BusinessModelsList
                        align="flex-start"
                        items={businessModelsData || []}
                        selectedIds={businessModels}
                        onClick={handleClickBusinessModel}
                    />

                    <AnimateHeight isVisible={!!(errors[name]?.message)}>
                        <FormHelperText id={`helper-text-${name}`} sx={{color: theme.palette.error.main}}>
                            {errors[name]?.message?.toString()}
                        </FormHelperText>
                    </AnimateHeight>
                </Box>
            )}
        />
    );
});
