export const APPLICATION: string = 'application';

export const BANNERS: string = 'banners';

export const REGISTRATION: string = `${APPLICATION}/registration`;
export const UPDATE_EMAIL: string = `${REGISTRATION}/email`;

export const CREATE_ACCOUNT_STEP_1: string = `${APPLICATION}/steps/1`;
export const CREATE_ACCOUNT_STEP_2: string = `${APPLICATION}/steps/2`;
export const CREATE_ACCOUNT_STEP_3: string = `${APPLICATION}/steps/3`;
export const CREATE_ACCOUNT_STEP_4: string = `${APPLICATION}/steps/4`;
export const CREATE_ACCOUNT_STEP_5: string = `${APPLICATION}/steps/5`;
export const CREATE_ACCOUNT_STEP_6: string = `${APPLICATION}/steps/6`;
export const CREATE_ACCOUNT_STEP_7: string = `${APPLICATION}/steps/7`;
export const CREATE_ACCOUNT_STEP_7_RESEND_CODE: string = `${APPLICATION}/steps/7/code`;
export const CREATE_ACCOUNT_STEP_8: string = `${APPLICATION}/steps/8`;
export const CREATE_ACCOUNT_STEP_8_CERTIFICATE: string = `${APPLICATION}/steps/8/certificates`;
export const CREATE_ACCOUNT_STEP_8_REFERENCE: string = `${APPLICATION}/steps/8/references`;
export const CREATE_ACCOUNT_STEP_8_STUDY: string = `${APPLICATION}/steps/8/case-studies`;

export const GET_APPLICATION: string = APPLICATION;
